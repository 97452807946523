var module = angular.module('meternet.chart.directives.prepaidGauge', ['meternet.charts.services']);

module.directive('prepaidGauge', function($q, $http, UnitScales, unitFilter, configService, dataService, prepaidService, i18nFilter) {
return {
    scope: {
         options : '=',
          data : '='
    },
    templateUrl: 'dashboard/prepaid-gauge.html',
    controller: function ($scope) {

        $scope.setEdited = function(i){
            $scope.editParam= i;
            $scope.isAuto = !isNaN($scope.editParam.limitValue);
        }

        $scope.setLimit = function(param, value){
            if (isNaN(value)){
                value = Number(value.replace(",", "."));
            }
            $http({
                method: 'POST',
                url: '/prepaidSet/',
                params: {
                    input: $scope.input.name,
                    device: $scope.device.id,
                    param: param.id,
                    value: value*Math.pow(10,param.scale)
                }
            }).then(function successCallback(response) {
                $scope.editParamLimit=""
                $scope.addToParamLimit=""
            }, function errorCallback(response) {
                $scope.editParamLimit=""
                $scope.addToParamLimit=""
            });
        }
        $scope.addToLimit = function(param, value){
            if (isNaN(value)){
                value = Number(value.replace(",", "."));
            }
            $http({
                method: 'POST',
                url: '/prepaidSet/',
                params: {
                    input: $scope.input.name,
                    device: $scope.device.id,
                    param: param.id,
                    value: param.limitValue + value*Math.pow(10,param.scale)
                }
            }).then(function successCallback(response) {
                $scope.editParamLimit=""
                $scope.addToParamLimit=""
            }, function errorCallback(response) {
                $scope.editParamLimit=""
                $scope.addToParamLimit=""
            });
        }
        $scope.setManual = function(param){
            $scope.isAuto=false;
            $http({
                method: 'POST',
                url: '/prepaidSet/',
                params: {
                    input: $scope.input.name,
                    device: $scope.device.id,
                    param: param.id,
                    value: Number.NaN
                }
            }).then(function successCallback(response) {
//console.log(response);
              }, function errorCallback(response) {
//console.log(response);
              });
        }
        $scope.setAuto = function(param){
            $scope.isAuto=true;
            $http({
                method: 'POST',
                url: '/prepaidSet/',
                params: {
                    input: $scope.input.name,
                    device: $scope.device.id,
                    param: param.id,
                    value: Number.POSITIVE_INFINITY
                }
            }).then(function successCallback(response) {
//console.log(response);
              }, function errorCallback(response) {
//console.log(response);
              });
        }
        $scope.changeOutput = function(param, value){
console.log(value)
            $http({
                method: 'POST',
                url: '/changeOutput/',
                params: {
                    param: param.outputParamId,
                    value: value
                }
            }).then(function successCallback(response) {
//console.log(response);
              }, function errorCallback(response) {
//console.log(response);
              });
        }
        $scope.unitFilterFun = function(d, p){
            if(p){
                return unitFilter(d, p.precision, p.unit, p.scale);
            }else{
                return "---"
            }
        }

        $scope.isNaN = function(d){
            return isNaN(d);
        }


        if (!$scope.options.prepaidId){
                return;
            }
        configService.get().then(function(meternetConfig){
            var prepaids = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices'));

            $scope.input = _.find(meternetConfig.engine.moduleInputs, function(prepaid){
               return prepaid.type === 'prepaid';
            });

            var prepaid = _.find(prepaids, function(prepaid){
               return prepaid.id === $scope.options.prepaidId;
            });
            $scope.device = prepaid;

            var promisesLimit = [];
            var promisesInput = [];
            var promisesOutput = [];
            if(!prepaid){
                return;
            }
            _.each(prepaid.params, function(serie) {
                var request = {};
                if (serie.inputParamId) {
                    request.count = Math.max(serie.average, 1);

                    var promise = dataService.requestParamLastData(serie.id);
                    promise.paramId = serie.id;
                    promisesLimit.push(promise);

                    var promise = dataService.requestParamLastData(serie.inputParamId);
                    promise.paramId = serie.inputParamId;
                    promisesInput.push(promise);

                    var promise = dataService.requestParamLastData(serie.outputParamId);
                    promise.paramId = serie.outputParamId;
                    promisesOutput.push(promise);
                }
            });
            $q.all(promisesLimit).then(function(moments) {
                _.forEach(promisesLimit, function(p, i) {
                    var d = [];
                    if (moments[i].previous){
                        d.push(moments[i].previous);
                    }
                    d.push(moments[i].current);
                    updateLimitValue(i,d);
                    var unsubscribeLimitFn = dataService.subscribeForParametersMeasurements(promisesLimit[i].paramId, function(moment) {
                        var data = [];
                        data.push(moment.current);
                        updateLimitValue(i,data);
                    });
                    $scope.$on('$destroy', unsubscribeLimitFn);
                });
            });
            $q.all(promisesInput).then(function(moments) {
                _.forEach(promisesInput, function(p, i) {
                    var d = [];
                    if (moments[i].previous){
                        d.push(moments[i].previous);
                    }
                    d.push(moments[i].current);
                    updateInputValue(i,d);
                    var unsubscribeInputFn = dataService.subscribeForParametersMeasurements(promisesInput[i].paramId, function(moment) {
                        var data = [];
                        data.push(moment.current);
                        updateInputValue(i,data);
                    });
                    $scope.$on('$destroy', unsubscribeInputFn);
                });
            });
            $q.all(promisesOutput).then(function(moments) {
                _.forEach(promisesOutput, function(p, i) {
                    var d = [];
                    if (moments[i].previous){
                        d.push(moments[i].previous);
                    }
                    d.push(moments[i].current);
                    updateOutputValue(i,d);
                    var unsubscribeOutputFn = dataService.subscribeForParametersMeasurements(promisesOutput[i].paramId, function(moment) {
                        var data = [];
                        data.push(moment.current);
                        updateOutputValue(i,data);
                    });
                    $scope.$on('$destroy', unsubscribeOutputFn);
                });
            });

            var updateLimitValue = function(i,data){
                if(data && data[0]){
                    prepaid.params[i].limitValue = data[0].value;
                    prepaid.params[i].limitTimestamp = data[0].timestamp;
                }
            }
            var updateInputValue = function(i,data){
                if(data && data[0]){
                    prepaid.params[i].inputValue = data[0].value;
                    prepaid.params[i].inputTimestamp = data[0].timestamp;
                }
            }
            var updateOutputValue = function(i,data){
                if(data && data[0]){
                    prepaid.params[i].outputValue = data[0].value;
                    prepaid.params[i].outputTimestamp = data[0].timestamp;
                }
            }

        $scope.prepaid = angular.extend({}, prepaid, $scope.options);

        function prepareSeries () {
            $scope.config.series = []
            if ($scope.report) {
                $scope.report.params.forEach(function(reportParameter) {
                    var data = {
                        deviceId: _.find($scope.devices, function (device) {
                            return _.find(device.params, function (param) {
                                    return param.id === reportParameter.id;
                                }) != null;
                        }).id,
                        paramId: reportParameter.id,
                        name: reportParameter.label || reportParameter.name,
                        enabled: true
                    };
                    var hasParam = _.find($scope.config.series, function(s) {
                        return s.paramId === data.paramId;
                    });
                    if (!hasParam) {
                        $scope.config.series.push(data);
                    }
                });
            }
        }

        //odtworzenie serii z configuracji
        $scope.config = $scope.options;
        configService.get().then(function(meternetConfig){
            $scope.reports = _.flatten(_.pluck(_.filter(meternetConfig.engine.moduleInputs, function (i) { return i.type==='prepaid'}), 'devices'));
            _.forEach($scope.reports, function(report){
                if (!report.label){
                    report.label = report.name;
                }
            });
            $scope.devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true));
            $scope.report = _.find($scope.reports, function(report){
                return report.id === $scope.config.prepaidId;
            });

            $scope.config.prepaidId = $scope.report ? $scope.report.id : null;
            prepareSeries();
        });

//        prepareSeries();
    });

    }
}
});

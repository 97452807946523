<div class="container-fluid device-calendar">
    <div class="col-xs-12 save-panel">
        <div x-ng-if="ui.message || errors.hasGlobalErrors('')" class="alert" x-ng-class="ui.css.class" role="alert">
            <div class="text-center">
                <h4>{{ui.message.text | i18n}}</h4>
                <div x-ng-repeat="e in errors.getGlobalErrors('')">
                    <h4>{{e.message | i18n:e.arguments}}</h4>
                </div>
            </div>
        </div>
    </div>
    <div class="row flex-row flex-row-device">
        <div class="flex-wrap">
            <div class="form-group">
                <label class="form-group-label" x-label-help="dashboard.widgets.config.series.lightingSetting"></label>
                <div class="input-group" style="display: flex;">
                    <!-- cannot use 'track by set.id' in ng-options because it changes selectedLightingSetting instance -->
                    <select class="form-control" x-ng-model="selectedLightingSetting" name="widgetsConfigSeriesDevice-{{$index}}"
                            x-ng-options="set as set.name for set in lightingSettings" x-ng-change="updateUrl()"
                            x-required="">
                    </select>
                </div>
            </div>
            <div x-ng-if="editable" class="edit-row" style="margin-left: 10px;">
                <div class="form-group">
                    <label class="form-group-label">{{::'mbsat.modal.settings.name' | i18n}}:</label>
                    <div class="input-group">
                        <input type="text" class="form-control" x-ng-model="selectedLightingSetting.name"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label style="padding-left: 3px;">{{'mbsat.menu.selectedDevices' | i18n}}: </label>
                <div class="input-group flex-wrap">
                    <span x-ng-repeat="device in ui.someDevices" class="label label-primary device-label">{{device.label}}</span>
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-primary" x-ng-click="updateLightingSettings()" x-ng-disabled="ui.processing || !ui.dirty">
                <i class="fa fa-save"></i>&nbsp;<span>{{'config.save' | i18n}}</span>
            </button>
            <button class="btn btn-default" x-ng-click="ui.backToMap()" x-ng-disabled="ui.processing">
                <i class="fa fa-globe"></i>&nbsp;<span>{{'config.back' | i18n}}</span>
            </button>
            <button class="btn btn-default" x-ng-click="cancel()" x-ng-disabled="ui.processing">
                <i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'config.cancel' | i18n}}</span>
            </button>
        </div>
    </div>
    <ul class="nav nav-tabs" style="margin-bottom: 10px;">
        <li role="tab" x-ng-class="{'active': ui.view==='calendar-schedule'}" x-ng-click="ui.view='calendar-schedule'">
            <a href="">
                <i class="fa fa-clipboard"></i>&nbsp;<span>{{'mbsat.menu.scheduler' | i18n}}</span>
            </a>
        </li>
        <li role="tab" x-ng-class="{'active': ui.view==='device-calendar'}" x-ng-click="ui.view='device-calendar'">
            <a href="">
                <i class="fa fa-calendar"></i>&nbsp;<span>{{'mbsat.menu.calendar' | i18n}}</span>
            </a>
        </li>
    </ul>
    <div x-ng-if="ui.view==='device-calendar'" style="overflow-y: auto;">
        <div ui-calendar config="uiConfig.calendar" ng-model="eventSources"></div>
        <div x-ng-if="ui.someDevices && ui.someDevices.length > 0">
            <h5><b>{{'mbsat.load' | i18n}}</b></h5>
            <div cost-report="" x-groups="ui.someDevices" x-month-name="ui.monthName" x-devices="options" x-on-total-week-time="ui.onTotalWeekTime" x-on-total-month-time="ui.onTotalMonthTime"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div x-ng-if="ui.view==='calendar-schedule'" style="overflow-y: auto;">
        <div ng-if="selectedLightingSetting">
            <div calendar-schedule="" x-options="options" x-lighting-setting="selectedLightingSetting" x-devices="devices" x-all-errors="errors"></div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
<div class="modal fade" id="exceptionSetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row" x-ng-repeat="exception in ui.newExceptions">
                    <div class="flex-row">
                        <div class="col-xs-11">
                            <div class="col-xs-12">
                                <div class="col-xs-8 col-sm-10">
                                    <div class="form-group">
                                        <label class="form-group-label">{{'widget.mbsat.event.description' | i18n}}</label>
                                        <div class="input-group" style="width: 100%;">
                                            <textarea style="resize: vertical;" rows="1" class="form-control" x-ng-model="exception.description"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xs-4 col-sm-2">
                                    <div class="radio">
                                        <label>
                                            <input type="radio" name="typesRadios{{$index}}" ng-model="exception.type" value="on" ng-checked="(exception.type == 'on')">
                                            {{'mbsat.lightingSetting.on' | i18n}}
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <label>
                                            <input type="radio" name="typesRadios{{$index}}" ng-model="exception.type" value="off" ng-checked="(exception.type == 'off')">
                                            {{'mbsat.lightingSetting.off' | i18n}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 width-fix">
                                <div class="col-xs-6">
                                    <label class="form-group-label">{{'widget.mbsat.morningEvent' | i18n}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" x-ng-model="exception.start" x-datetimepicker="{format:'YYYY-M-D HH:mm:ss'}"/>
                                        <span class="input-group-addon">
                                            <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-xs-6">
                                    <label class="form-group-label">{{'widget.mbsat.eveningEvent' | i18n}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" x-ng-model="exception.end" x-datetimepicker="{format:'YYYY-M-D HH:mm:ss'}"/>
                                        <span class="input-group-addon">
                                            <i class="fa fa-calendar"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-1">
                            <button type="button" class="btn btn-danger pull-right" x-ng-click="removeException($index)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <hr/>
                    </div>
                    <div class="col-xs-12" x-ng-if="$last">
                        <button type="button" class="btn btn-success pull-right" x-ng-click="addException()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" x-ng-click="saveException()">
                    <i class="fa fa-check"></i>{{'btn.OK' | i18n}}
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" x-ng-click="resetException()">
                    <i class="fa fa-long-arrow-left"></i>{{'btn.cancel' | i18n}}
                </button>
            </div>
        </div>
    </div>
</div>


<div class="modal-header"><i class="fa fa-fw fa-users"></i>&nbsp;
    <button type="button" class="close" x-ng-click="close()" aria-hidden="true">&times;</button>
    <h2 class="modal-title">{{ modalTitle | i18n }}</h2>
</div>

<div class="modal-body">
    <div x-file-browser="path" x-read-only="true" x-choosed-directory="choosedDirectory"
         style="height: 400px; margin-bottom: 22px;"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-lg btn-danger" x-ng-click="cancel()">{{ 'ui.cancel' | i18n }}</button>
    <button type="button" class="btn btn-lg btn-primary" x-ng-click="close()">{{ 'widget.edit.close' | i18n }}</button>
</div>
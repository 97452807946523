var module = angular.module('meternet.dashboard.controllers', [
    'meternet.services',
    'meternet.dashboard.constants',
    'meternet.dashboard.controllers.gauge',
    'meternet.dashboard.controllers.gaugeWidget',
    'meternet.dashboard.controllers.digitalGaugeWidget',
    'meternet.dashboard.controllers.halfGaugeWidget',
    'meternet.dashboard.controllers.lineChartWidget',
    'meternet.dashboard.controllers.shiftBarGraphWidget',
    'meternet.dashboard.controllers.heatmapWidget',
    'meternet.dashboard.controllers.visuWidget',
    'meternet.dashboard.controllers.tableGaugeWidget',
    'meternet.dashboard.controllers.termoGaugeWidget',
    'meternet.dashboard.controllers.controlGaugeWidget',
    'meternet.dashboard.controllers.indicatorGaugeWidget',
    'meternet.dashboard.controllers.energyReport',
    'meternet.dashboard.controllers.energyChart',
    'meternet.dashboard.controllers.fileManager',
    'meternet.dashboard.controllers.linkWidget',
    'meternet.dashboard.controllers.diffWidget',
    'meternet.dashboard.controllers.eventsWidget',
    'meternet.dashboard.controllers.eventsAckWidget',
    'meternet.dashboard.controllers.statusWidget',
    'meternet.dashboard.controllers.campingWidget',
    'meternet.dashboard.controllers.prepaidGaugeWidget',
    'meternet.dashboard.controllers.mbsatGaugeWidget',
    'meternet.dashboard.controllers.mbsat2GaugeWidget',
    'meternet.dashboard.controllers.barGraphWidget',
	'meternet.dashboard.controllers.mediaReportWidget'
]);


function DashboardCtrl($scope, $stateParams, dashboardService, configService, userService, DashboardEvents) {

    $scope.ui = {
        isEditable: function () {
            return $scope.dashboard ? userService.canEditDashboard($scope.dashboard.id) : false;
        }
    };

    if ($stateParams.dashboardName) {
        dashboardService.get($stateParams.dashboardName).then(function (dashboard) {
            $scope.dashboard = dashboard;
        });
    }

    $scope.dirty = false;

    configService.get().then(function (config) {
        $scope.config = config;
    });

    $scope.$on('dashboard.dirty', function (event, newElement) {
        $scope.dirty = true;
        $scope.config.dirty = true;
    });

    $scope.$on('meternetConfigUpdate', function (event, config) {
        $scope.$broadcast(DashboardEvents.RESIZE);
    });

    $scope.$on('adfDashboardChanged', function (event, name, model) {
        configService.save($scope.config).then(function (result) {
            $scope.config = result.config;
            dashboardService.get($stateParams.dashboardName).then(function (dashboard) {
                $scope.dashboard = dashboard;
                $scope.editable = userService.canEditDashboard($scope.dashboard.id);
                $scope.$broadcast(DashboardEvents.RESIZE);
            });
        });
    });

}

module.controller('dashboardCtrl', DashboardCtrl);

module.config(function (dashboardProvider) {
    dashboardProvider.structure('12', {
        rows: [{
            columns: [{
                styleClass: 'col-md-12',
                width: 12
            }]
        }]
    }).structure('2-10', {
        rows: [{
            columns: [{
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-10',
                    width: 10
            }]
        }]
    }).structure('6-6', {
        rows: [{
            columns: [{
                styleClass: 'col-md-6',
                width: 6
            }, {
                styleClass: 'col-md-6',
                width: 6
            }]
        }]
    }).structure('4-8', {
        rows: [{
            columns: [{
                styleClass: 'col-md-4',
                width: 4,
                widgets: []
            }, {
                styleClass: 'col-md-8',
                width: 8,
                widgets: []
            }]
        }]
    }).structure('2-10', {
        rows: [{
            columns: [{
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-10',
                    width: 10
            }]
        }]
    }).structure('4-4-4', {
        rows: [{
            columns: [{
                    styleClass: 'col-md-4',
                    width: 4
                }, {
                    styleClass: 'col-md-4',
                    width: 4
                }, {
                    styleClass: 'col-md-4',
                    width: 4
            }]
        }]
    }).structure('3-3-3-3', {
        rows: [{
            columns: [{
                    styleClass: 'col-md-3',
                    width: 3
                }, {
                    styleClass: 'col-md-3',
                    width: 3
                }, {
                    styleClass: 'col-md-3',
                    width: 3
                }, {
                    styleClass: 'col-md-3',
                    width: 3
            }]
        }]
    }).structure('2-2-2-2-2-2', {
        rows: [{
            columns: [{
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-2',
                    width: 2
                }, {
                    styleClass: 'col-md-2',
                    width: 2
            }]
        }]
    }).structure('6-6/3-3-3-3', {
        rows: [{
            columns: [{
                styleClass: 'col-md-6',
                width: 6
            }, {
                styleClass: 'col-md-6',
                width: 6
            }]
        }, {
            columns: [{
                styleClass: 'col-md-3',
                width: 3
            }, {
                styleClass: 'col-md-3',
                width: 3
            }, {
                styleClass: 'col-md-3',
                width: 3
            }, {
                styleClass: 'col-md-3',
                width: 3
            }]
        }]
    }).structure('12/6-6', {
        rows: [{
            columns: [{
                styleClass: 'col-md-12',
                width: 12
            }]
        }, {
            columns: [{
                styleClass: 'col-md-6',
                width: 6
            }, {
                styleClass: 'col-md-6',
                width: 6
            }]
        }]
    }).structure('12/4-4-4', {
        rows: [{
            columns: [{
                styleClass: 'col-md-12',
                width: 12
            }]
        }, {
            columns: [{
                styleClass: 'col-md-4',
                width: 4
            }, {
                styleClass: 'col-md-4',
                width: 4
            }, {
                styleClass: 'col-md-4',
                width: 4

            }]
        }]
    }).structure('12/3-3-3-3', {
        rows: [{
            columns: [{
                styleClass: 'col-md-12',
                width: 12
            }]
        }, {
            columns: [{
                styleClass: 'col-md-3',
                width: 3
            }, {
                styleClass: 'col-md-3',
                width: 3
            }, {
                styleClass: 'col-md-3',
                width: 3
            }, {
                styleClass: 'col-md-3',
                width: 3

            }]
        }]
    }).structure('12/2-2-2-2-2-2', {
        rows: [{
            columns: [{
                styleClass: 'col-md-12',
                width: 12
            }]
        }, {
            columns: [{
                styleClass: 'col-md-2',
                width: 2
            }, {
                styleClass: 'col-md-2',
                width: 2
            }, {
                styleClass: 'col-md-2',
                width: 2
            }, {
                styleClass: 'col-md-2',
                width: 2
            }, {
                styleClass: 'col-md-2',
                width: 2
            }, {
                styleClass: 'col-md-2',
                width: 2

            }]
        }]
    });
});

<form class="form-horizontal clearfix" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-help="config.output.name.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-help="config.output.label.help"
                           x-field-errors="" x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.output.desc.help"
                           x-field-errors=""></input>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.labeldb' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="db.label" x-field-help="config.labeldb.help"
                           x-field-errors="" x-field-default-value="db.name"/>
                </div>
            </div>
        </div>

        <div x-ng-switch="item.config.type">
            <div x-ng-switch-when="internal">
                <!--<div class="form-group">-->
                <!--<label class="control-label col-sm-3">{{'config.database' | i18n}}</label>-->
                <!--<div class="col-sm-8">-->
                <!--<div class="input-group">-->
                <!--<select class="form-control" x-ng-model="item.config.databaseId" x-field-errors=""-->
                <!--x-field-help="config.output.database.help"-->
                <!--x-ng-options="d.id as d.name for d in config.engine.databases"></select>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->

                <div class="form-group" x-ng-if="advanceView.value">
                    <label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.bufferedOutputsWarningLevel"
                                   x-field-help="config.output.enqueuedMeasurementsWarningLevel.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div x-ng-switch-when="db">-->
            <!--&lt;!&ndash;<div class="form-group">&ndash;&gt;-->
            <!--&lt;!&ndash;<label class="control-label col-sm-3">{{'config.database' | i18n}}</label>&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="col-sm-8">&ndash;&gt;-->
            <!--&lt;!&ndash;<div class="input-group">&ndash;&gt;-->
            <!--&lt;!&ndash;<select class="form-control" x-ng-model="item.config.databaseId" x-field-errors=""&ndash;&gt;-->
            <!--&lt;!&ndash;x-field-help="config.output.database.help"&ndash;&gt;-->
            <!--&lt;!&ndash;x-ng-options="d.id as d.name for d in config.engine.databases"></select>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->

            <!--<div class="form-group" x-ng-if="advanceView.value">-->
            <!--<label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>-->
            <!--<div class="col-sm-8">-->
            <!--<div class="input-group">-->
            <!--<input class="form-control" x-ng-model="item.config.bufferedOutputsWarningLevel"  x-field-help="config.output.enqueuedMeasurementsWarningLevel.help"x-field-errors="" />-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->

            <div x-ng-switch-when="remote">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.host' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.url"
                                   x-field-help="config.output.host.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.username"
                                   x-field-help="config.output.username.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input type="password" class="form-control" x-ng-model="item.config.password"
                                   x-field-help="config.output.pass.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.input' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.inputName"
                                   x-field-help="config.output.inputName.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.bufferedOutputsWarningLevel"
                                   x-field-help="config.output.enqueuedMeasurementsWarningLevel.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="anakonda">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.host' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.url"
                                   x-field-help="config.output.url.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.username"
                                   x-field-help="config.output.username.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input type="password" class="form-control" x-ng-model="item.config.password"
                                   x-field-help="config.output.pass.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.baseName' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.baseName"
                                   x-field-help="config.output.baseName.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.bufferedOutputsWarningLevel"
                                   x-field-help="config.output.enqueuedMeasurementsWarningLevel.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="csv">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementOutput.csv.rollingPeriodicity' |
                        i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control"
                                    x-ng-model="item.config.rollingPeriodicity"
                                    x-ng-options="p.type as p.label for p in ui.rollingPeriodicities"
                                    x-field-help="config.output.rollingPeriodicity.help"
                                    x-field-errors="">
                            </select>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementOutput.csv.storageDirectory' |
                        i18n}}</label>
                    <div class="col-sm-8">
                        <div x-directory-chooser="item.config.storageDirectory"
                             x-modal-title="config.measurementOutput.csv.storageDirectory"/>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementOutput.csv.filePrefix' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.filePrefix"
                                   x-field-help="config.output.filePrefix.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementOutput.csv.writeInterval' |
                        i18n}}</label>
                    <div class="col-sm-7">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.writeInterval" x-field-errors=""
                                   x-field-units="[['s',1000], ['m',60000], ['h',3600000]]"
                                   x-field-unit="1"/>
                        </div>
                    </div>
                    <div class="col-sm-1">
                        <button class="btn btn-primary pull-right" type="button" uib-popover={{'config.output.writeInterval.help'|i18n}} popover-trigger="mouseenter" popover-placement="top">?</button>
                    </div>
                </div>
            </div>
            <div x-ng-switch-when="postgres">
                <div x-config-form-database-new="item.config.databaseId" x-config="item"></div>
            </div>
            <div x-ng-switch-when="mssql">
                <div x-config-form-database-new="item.config.databaseId" x-config="item"></div>
            </div>
            <div x-ng-switch-when="mysql">
                <div x-config-form-database-new="item.config.databaseId" x-config="item"></div>
            </div>
            <div x-ng-switch-when="oracle">
                <div x-config-form-database-new="item.config.databaseId" x-config="item"></div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.measurementOutput.type.' + ui.type.type | i18n}}</span>
                </span>
				<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{ui.type.datasheetUrl}}"
				   x-ng-show="ui.type.datasheetUrl">
					{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
				</a>
            </div>
        </div>
    </div>
</form>

<!--<form class="form-inline clearfix">-->
<!--    <div class="panel panel-default">-->
<!--        <div class="panel-heading">-->
<!--            <div class="panel-title"><i class="fa fa-fw fa-tachometer"></i>&nbsp;{{'config.measurementOutput.parameters'-->
<!--                | i18n}}-->
<!--            </div>-->
<!--        </div>-->
<!--        <table class="table table-hover">-->
<!--            <thead>-->
<!--            <tr>-->
<!--                <th>{{'config.measurementOutput.device.name' | i18n}}</th>-->
<!--                <th>{{'config.measurementOutput.parameter.name' | i18n}}</th>-->
<!--                <th>{{'config.measurementOutput.rule' | i18n}}</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr x-ng-repeat="link in links" x-ng-click="ui.selectLink(link)" style="cursor: pointer;">-->
<!--                <td style="width: 30%;">-->
<!--                    <span>{{link.device.name}}</span>-->
<!--                </td>-->
<!--                <td style="width: 30%;">-->
<!--                    <span>{{link.param.name}}</span>-->
<!--                </td>-->
<!--                <td>-->
<!--                    <div x-ng-if="link.rule.type==='stateless'" title="{{'config.momentRule.type.stateless' | i18n}}">-->
<!--                        <i class="fa fa-fw fa-check-circle"></i>-->
<!--                        <span>{{link.rule.rule.expr}}</span>-->
<!--                    </div>-->
<!--                    <div x-ng-if="link.rule.type==='toggle'" title="{{'config.momentRule.type.toggle' | i18n}}">-->
<!--                        <div>-->
<!--                            <i class="fa fa-fw fa-toggle-on"></i>-->
<!--                            <span>{{link.rule.positive.expr}}</span>-->
<!--                        </div>-->
<!--                        <div style="margin-top: 3px;">-->
<!--                            <i class="fa fa-fw fa-toggle-off"></i>-->
<!--                            <span>{{link.rule.negative.expr}}</span>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--        </table>-->
<!--    </div>-->
<!--</form>-->

<div class="row mbsat-map" ng-if="menu!=3 || !menu">
    <div class="col-xs-12 mbsat-map" ng-show="menu==0 || !menu">
        <leaflet lf-center="mapZoom" markers="ui.locations" layers="layers" bounds="ui.bounds" events="events"  width="100%" height="100%" style="flex: 1 1 auto;"></leaflet>
    </div>
    <div class="col-xs-12 device-calendar-container" ng-if="menu==1">
        <div x-adf-dashboard="" x-adf-model="ui.template" x-editable="false" class="mbsat-map"></div>
    </div>
    <div class="col-xs-12 back-to-map-btn" x-ng-show="menu==1">
        <button class="btn btn-primary" x-ng-click="backToMap()">{{'dashboard.backToMap' | i18n}}</button>
    </div>
</div>
<div ng-if="(menu==0 || !menu) && menu!=3" class="row mbsat-table" style="max-height:300px">
    <div class="col-xs-12">
        <div class="alert alert-danger" role="alert" x-ng-if="ui.errors.hasGlobalErrors('')">
            <div x-ng-repeat="e in ui.errors.getGlobalErrors('')" class="text-center">
                <h4>{{e.message | i18n:e.arguments}}</h4>
            </div>
        </div>
        <div class="alert clearfix text-center" x-ng-if="ui.message" x-ng-class="ui.message.css">
            <h4 style="margin: 0;">{{ui.message.text | i18n}}</h4>
        </div>
    </div>
    <div class="col-xs-12">
        <div mbsat2-bottom="" x-menu-view="menu" x-markers-within-bounds="onlyMarkersWithinBounds ? markersWithinBounds : ui.markers" x-location-within-bounds="ui.onlyMarkersWithinBounds ? locationWithinBounds : ui.locations" x-no-map="false" x-cluster-layer="ui.clusterLayer" x-only-map-markers="ui.onlyMarkersWithinBounds" x-config="ui.config"></div>
    </div>
</div>
<div ng-if="menu==3" class="row mbsat-table" style="height:100%">
    <div class="col-xs-12">
        <div class="alert alert-danger" role="alert" x-ng-if="ui.errors.hasGlobalErrors('')">
            <div x-ng-repeat="e in ui.errors.getGlobalErrors('')" class="text-center">
                <h4>{{e.message | i18n:e.arguments}}</h4>
            </div>
        </div>
        <div class="alert clearfix text-center" x-ng-if="ui.message" x-ng-class="ui.message.css">
            <h4 style="margin: 0;">{{ui.message.text | i18n}}</h4>
        </div>
    </div>
    <div class="col-xs-12" style="height:100%">
        <div mbsat2-bottom="" x-menu-view="menu" x-markers-within-bounds="onlyMarkersWithinBounds ? markersWithinBounds : ui.markers" x-location-within-bounds="ui.locations" x-no-map="true" x-only-map-markers="ui.onlyMarkersWithinBounds" x-cluster-layer="ui.clusterLayer" x-config="ui.config" style="height:100%"></div>
    </div>
</div>

<!---->
<div x-ng-if="!limit">

    <form class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.time' | i18n}}</label>
            <div class="col-sm-8">
                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="ui.datetime" x-datetimepicker="ui.datetime"/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.timezone' | i18n}}</label>
            <div class="col-sm-8">
                <div class='input-group timezone'>
                    <input class="form-control" type="text"
                           ng-model="ui.timezone"
                           x-field-errors=""
                           x-field-help="config.timezone.help"
                           typeahead="timezone.name for timezone in timezones | filter:$viewValue"
                           typeahead-editable="false"
                           typeahead-on-select="onSelect($item, $model, $label)"/>
                </div>
            </div>
        </div>
        <div class="form-group" >
            <div class="col-sm-offset-3 col-sm-7">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" x-ng-model="ui.ntpEnable"
                               x-field-errors=""/>
                        {{'config.ntpEnable' | i18n}}
                    </label>
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" uib-popover="{{'config.ntpEnable.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="bottom" class="btn btn-primary pull-right">?
                </button>
            </div>
        </div>
    </form>

</div>

<div x-ng-if="limit">
    <center><h1>DEMO version</h1></center>
</div>
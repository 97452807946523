<form class="form-horizontal">
    <!--<div class="form-group">
        <label class="control-label col-sm-3">{{'config.engine.name' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="item.config.name"
                       x-field-errors=""
                       x-field-help="config.engine.name.help">
            </div>
        </div>
    </div>-->
    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.engine.label' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="item.config.label"
                       x-field-errors=""
                       x-field-help="config.engine.label.help">
            </div>
        </div>
    </div>
</form>
var module = angular.module('meternet.utils.form', [
    'meternet.filters'
]);

module.constant("Booleans", [{
    value: false,
    label: 'form.boolean.false'
}, {
    value: true,
    label: 'form.boolean.true'
}]);

function Error(data) {
    data = data || {};
    this.path = data.path;
    this.message = data.message || '';
    this.type = data.type || 'error';
    this.arguments = data.arguments;
}


function Errors(data) {
    var i;
    data = data || {};
    this.fields = data.fields || [];
    this.globals = data.globals || [];
    for (i = 0; i < this.fields.length; ++i) {
        this.fields[i] = new Error(this.fields[i]);
    }
    for (i = 0; i < this.globals.length; ++i) {
        this.globals[i] = new Error(this.globals[i]);
    }
}

Errors.prototype.getNestedErrors = function (path) {
    var f = [], g = [], i, t;
    for (i = 0; i < this.fields.length; ++i) {
        t = this.fields[i];
        if (t.path.indexOf(path) === 0 && t.path.length > path.length) {
            f.push({
                path: t.path[path.length] === '[' ? t.path.substring(t.path.lastIndexOf('.', path.length) + 1) : t.path.substring(path.length + 1),
                type: t.type,
                message: t.message
            });
        }
    }
    for (i = 0; i < this.globals.length; ++i) {
        t = this.globals[i];
        if (t.path.indexOf(path) === 0 && t.path.length >= path.length) {
            g.push({
                path: t.path === path ? path : t.path.substring(path.length + 1),
                type: t.type,
                message: t.message
            });
        }
    }
    return new Errors({
        fields: f,
        globals: g
    });
};

Errors.prototype.hasFieldErrors = function (path) {
    for (var i = 0; i < this.fields.length; ++i) {
        var f = this.fields[i];
        if (f.path === path) {
            return true;
        }
    }
    return false;
};

Errors.prototype.getFieldErrors = function (path) {
    var e = [];
    for (var i = 0; i < this.fields.length; ++i) {
        var f = this.fields[i];
        if (f.path === path) {
            e.push(f);
        }
    }
    return e;
};

Errors.prototype.hasGlobalErrors = function (path) {
    for (var i = 0; i < this.globals.length; ++i) {
        var f = this.globals[i];
        if (f.path === path) {
            return true;
        }
    }
    return false;
};

Errors.prototype.getGlobalErrors = function (path) {
    var e = [];
    for (var i = 0; i < this.globals.length; ++i) {
        var f = this.globals[i];
        if (f.path === path) {
            e.push(f);
        }
    }
    return e;
};

Errors.prototype.withPrefix = function (prefix) {
    for (var i = 0; i < this.fields.length; ++i) {
        var f = this.fields[i];
        f.path = prefix + '.' + f.path;
    }
    return this;
};

Errors.prototype.count = function () {
    return this.fields.length + this.globals.length;
};


var Objects = {
    traverse: function (root, func) {
        for (var prop in root) {
            var val = root[prop];
            if (func.apply(root, [prop, val]) !== false && val !== null && typeof(val)=="object") {
                Objects.traverse(val, func);
            }
        }
    },
    //works only when elem is an object
    path: function (root, elem) {
        var p = '';

        function iterate(e, elem, path) {
            var i, a;
            if (e === elem) {
                return path;
            } else {
                if (e instanceof Array) {
                    for (i = 0; i < e.length; ++i) {
                        a = iterate(e[i], elem, path + '[' + i + ']');
                        if (a) {
                            return a;
                        }
                    }
                } else if (typeof(e) === 'object') {
                    for (i in e) {
                        if (e.hasOwnProperty(i)) {
                            a = iterate(e[i], elem, path + '.' + i);
                            if (a) {
                                return a;
                            }
                        }
                    }
                }
                return null;
            }
        }

        p = iterate(root, elem, p);
        return p ? p.substring(1) : null;
    },
    remove: function (root, elem) {

        function iterate(e, elem) {
            var i;
            if (e) {
                if (e instanceof Array) {
                    for (i = 0; i < e.length; ++i) {
                        if (elem === e[i]) {
                            e.splice(i, 1);
                            return true;
                        } else if (iterate(e[i], elem)) {
                            return true;
                        }
                    }
                } else if (typeof(e) === 'object') {
                    for (i in e) {
                        if (e.hasOwnProperty(i)) {
                            if (elem === e[i]) {
                                delete e[i];
                                return true;
                            } else if (iterate(e[i], elem)) {
                                return true;
                            }
                        }
                    }
                }
            }
            return false;
        }

        return iterate(root, elem);
    }
};

module.constant('Errors', Errors);
module.constant('Objects', Objects);


module.directive('refreshable', function () {
    return {
        transclude: 'element',
        restrict: 'A',
        terminal: true,
        link: function (scope, elem, attr, ctrl, transclude) {
            var prevElem = null;
            var prevScope = null;

            function relink() {
                if (prevElem) {
                    prevScope.$destroy();
                    prevElem.remove();
                }
                prevScope = scope.$new();
                prevElem = transclude(prevScope, function (clone) {
                    elem.after(clone);
                });
            }

            relink();

            scope.$watch(attr.refreshable, function (nval, oval) {
                if (nval !== oval) {
                    relink();
                }
            });
        }
    };
});


var errorsScopePaths = [ 'errors' ];
var errorsRootPaths = [ null ];


module.directive('errors', function () {
    return {
        restrict: 'A',
        transclude: true,
        link: function (scope, elem, attr, ctrl, transclude) {
            errorsScopePaths.push(attr.errors || 'errors');
            errorsRootPaths.push(attr.errorsRoot || null);

            scope.$on('$destroy', function () {
                errorsScopePaths.pop();
                errorsRootPaths.pop();
            });

            transclude(function(clone) {
                elem.append(clone);
            });
        }
    };
});

module.directive('validate', function (i18nFilter) {
    var template = _.template(
        '<ul class="error-list">' +
        '<% _.each(errors, function(e) { %>' +
        '   <li class="<%- e.type %>"><%- i18n(e.message) %></li>' +
        '<% }); %>' +
        '</ul>'
    );

    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {

            var errors = errorsScopePaths[errorsScopePaths.length - 1];
            var root = errorsRootPaths[errorsRootPaths.length - 1];
            var path = attr.fieldErrors ? scope.$eval(attr.fieldErrors) : attr.ngModel;
            var g = elem.closest('div.form-group');
            var el = null;

            if (root && path.indexOf(root) == 0) {
                path = path.substring(root.length + 1);
            }

            var currentErrors = [];

            scope.$watch(function () {
                return scope.$eval(attr.validate);
            }, function (newVal, oldVal) {
                    if (newVal !== oldVal) {
                        findErrors();
                        validate(currentErrors);
                    }
            }, true);

            var findErrors = function(){
                currentErrors = [];
                var validationResult = scope.$eval(attr.validate);
                for (var prop in validationResult) {
                    if (validationResult[prop] === true){
                        currentErrors.push({
                            message: prop,
                            type:'error',
                            path:'path'
                        });
                    }
                }
            };

            scope.$watch(errors, function (errors) {
                findErrors();
                errors = errors || {fields:[]};
                for (var i = 0; i < currentErrors.length; ++i) {
                    var result = _.find(errors.fields, function(error) {
                        return error.message === currentErrors[i];
                    });
                    if (!result) {
                        errors.fields.push(new Error({
                            message:currentErrors[i],
                            path: path,
                            type: 'error'
                        }));
                    }

                }
            });

            function validate(e) {
                //var e = errors ? errors.getFieldErrors(path) : [];
                el = g.find('.form-control').filter(':visible').first();
                ctrl.$setValidity(null, e.length === 0);
                if (e.length === 0) {
                    g.removeClass('has-error has-warning');
                    if (ctrl.$pristine) {
                        g.removeClass('has-success');
                    }
                    el.popover('destroy');
                } else {
                    g.addClass('has-error');
                    var p = el.data('bs.popover');
                    var c = template({ errors: e, i18n: i18nFilter });
                    if (p) {
                        p.options.content = c;
                    } else {
                        el.popover({
                            placement: 'top',
                            trigger: 'focus hover',
                            content: c,
                            html: true
                        });
                    }
                    if (el.is(':focus')) {
                        el.popover('show');
                    }
                }
            }
        }
    }
});


module.directive('fieldErrors', function ($timeout, i18nFilter) {
    var template = _.template(
        '<ul class="error-list">' +
        '<% _.each(errors, function(e) { %>' +
        '   <li class="<%- e.type %>"><%- i18n(e.message) %></li>' +
        '<% }); %>' +
        '</ul>'
    );

    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var errors = errorsScopePaths[errorsScopePaths.length - 1];
            var root = errorsRootPaths[errorsRootPaths.length - 1];
            var path = attr.fieldErrors ? scope.$eval(attr.fieldErrors) : attr.ngModel;
            var g = elem.closest('div.form-group');
            var el = null;

            if (root && path.indexOf(root) == 0) {
                path = path.substring(root.length + 1);
            }

            function update(errors) {
                var e = errors ? errors.getFieldErrors(path) : [];

                el = elem.hasClass('form-control') ? elem : g.find('.form-control').filter(':visible').first();
                ctrl.$setValidity(null, e.length === 0);
                if (e.length === 0) {
                    g.removeClass('has-error has-warning');
                    if (ctrl.$pristine) {
                        g.removeClass('has-success');
                    }
                    el.popover('destroy');
                } else {
                    g.addClass('has-error');
                    var p = el.data('bs.popover');
                    var c = template({ errors: e, i18n: i18nFilter });
                    if (p) {
                        p.options.content = c;
                    } else {
                        var attr = el.attr('x-ng-disabled');
                        var popover = {
                            placement: 'top',
                            trigger: 'focus hover',
                            content: c,
                            html: true
                        };
                        if (typeof attr !== typeof undefined && attr !== false) {
                            el.parent().popover(popover);
                        } else {
                            el.popover(popover);
                        }
                    }
                    if (el.is(':focus')) {
                        el.popover('show');
                    }
                }
            }

            //(jc) workaround for multiple selects issue in angular (direct array comparison for equality)
            /*var prev;

            $timeout(function () {
                prev = ctrl.$modelValue;
                ctrl.$viewChangeListeners.push(function () {
                    if (!angular.equals(ctrl.$modelValue, prev)) {
                        prev = ctrl.$modelValue;
                        scope.$emit('modelErrors.update');
                    }
                });
            });*/

            scope.$watch(attr.ngModel, function (nval, oval) {
                if (nval !== oval) {
                    scope.$emit('modelErrors.update', false);
                }
            }, true);

            scope.$watch(errors, function (errors) {
                update(errors);
            });

            scope.$on('$destroy', function () {
                if (el) {
                    el.popover('destroy');
                }
            });

            //update(scope.errors);
        }
    };
});


module.directive('fieldDefaultValue', function ($compile) {
    var template =
        '<span class="input-group-addon default-value">' +
        '    <input type="checkbox" />' +
        '</span>';

    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {

            ctrl.fieldDefaultValueChangeListener = function () {
                format(ctrl.$modelValue);
            };

            var ui = {
                def: function () {
                    var i, f, def = scope.$eval(attr.fieldDefaultValue);
                    for (i = ctrl.$formatters.length - 1; i >= 0; --i) {
                        f = ctrl.$formatters[i];
                        if (f !== format) {
                            def = f(def);
                        }
                    }
                    return def;
                },
                checked: function () {
                    return ctrl.$modelValue != null;
                },
                toggle: function () {
                    ctrl.$setViewValue(ctrl.$modelValue == null ? ui.def() : null);
                    ctrl.$render();
                    //ctrl.fieldDefaultValueChangeListener();
                }
            };

            function render() {
                elem.prev('.default-value').remove();
                elem.before($compile(template)(scope));
                var e = elem.prev('.default-value').find('input[type=checkbox]');
                e.click(ui.toggle);
                ctrl.fieldDefaultValueChangeListener();
            }

            function format(value) {
                if(value === null) {
                    elem.attr('placeholder', ui.def());
                    elem.attr('disabled', true);
                    elem.prev('.default-value').find('input[type=checkbox]').prop("checked", false);
                }else if (ctrl.$isEmpty(value)) {
                    elem.attr('error', true)
                }else {
                    elem.attr('placeholder', null);
                    elem.attr('disabled', null);
                    elem.prev('.default-value').find('input[type=checkbox]').prop("checked", true);
                }
                return value;
            }

            scope.$watch(attr.fieldDefaultValue, function (nval, oval) {
                if (nval !== oval) {
                    format(ctrl.$modelValue);
                }
            });

            ctrl.$formatters.push(format);

            ctrl.$viewChangeListeners.push(ctrl.fieldDefaultValueChangeListener);

            render();
        }
    };
});


module.directive('scaleFormatter', function(unitFilter) {
    return {
        scope: {
            scale: "=formatScale",
            precision:"=formatPrecision"
        },
        require: 'ngModel',
        link: function(scope, elem, attr, ctrl) {

            function format(value) {
                if (angular.isDefined(scope.scale)) {
                    return unitFilter(value, scope.precision, "", scope.scale);
                    //return value / Math.pow(10, scope.scale)
                }
                return value;
            }

            function parse(value) {
                if (value && angular.isDefined(scope.scale)) {
                    return value.replace(',', '.').split(' ').join('') * Math.pow(10, scope.scale);
                }
                return value;
            }

            ctrl.$formatters.push(format);
            ctrl.$parsers.push(parse);

            scope.$watch('scale', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    var value = format(ctrl.$modelValue);
                    if (value) {
                        ctrl.$setViewValue(value);
                        ctrl.$render();
                    }
                }
            });
        }
    }
});

module.directive('unitAddon', function($compile, unitFilter) {
    var template = '<span class="input-group-addon">{{ unit }}</span>';
    return {
        scope: {
            unitAddonScale: '=',
            unitAddonUnit: '='
        },
        link: function(scope, elem, attr, ctrl) {
            var render = function() {
                if(!scope.unitAddonUnit) {
                    scope.unit = '-';
                } else {
                    scope.unit =  unitFilter(1, -1, scope.unitAddonUnit, scope.unitAddonScale);
                }

                elem.replaceWith($compile(template)(scope));
            };
            render();

            scope.$watch('unitAddonScale', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    render();
                }
            });

            scope.$watch('unitAddonUnit', function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    render();
                }
            });


        }
    };

});


module.directive('fieldUnits', function ($compile) {
    var template =
        '<div class="input-group-btn units">' +
        '   <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' +
        '       <span class="unit">{{ui.label(ui.unit()) | i18n}}</span>&nbsp;<span class="caret"></span>' +
        '   </button>' +
        '   <ul class="dropdown-menu">' +
        '       <li x-ng-repeat="u in units"><a x-ng-click="ui.select(u)">{{ui.label(u) | i18n}}</a></li>' +
        '   </ul>' +
        '</div>';

    return {
        scope: {
            units: '=fieldUnits',
            unit: '=fieldUnit'
        },
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            scope.ui = {
                label: function (unit) {
                    return 'unit.symbol.' + unit[0];
                },
                select: function (unit) {
                    scope.unit = scope.units.indexOf(unit);
                },
                unit: function () {
                    var unit = null;
                    if (scope.unit != null) {
                        if (typeof(scope.unit) === 'number') {
                            unit = scope.units[scope.unit];
                        } else if (typeof(scope.unit) === 'string') {
                            unit = _.find(scope.units, function (u) {
                                return u[0] === scope.unit;
                            });
                        }
                    }
                    if (!unit) {
                        unit = scope.units[0];
                    }
                    return unit;
                }
            };

            function render() {
                elem.next('.units').remove();
                elem.after($compile(template)(scope));
            }

            function format(value) {
                var unit = scope.ui.unit();
                if (value && unit) {
                    return value / unit[1];
                } else {
                    return value;
                }
            }

            function parse(value) {
                var unit = scope.ui.unit();
                value = value != null ? parseFloat(value) : NaN;
                if (isNaN(value)) {
                    return null;
                } else if (unit) {
                    return value * unit[1];
                } else {
                    return value;
                }
            }

            ctrl.$formatters.push(format);
            ctrl.$parsers.push(parse);

            render();

            scope.$watch('units', function (nval, oval) {
                if (nval !== oval) {
                    render();
                }
            });

            scope.$watch('unit', function (nval, oval) {
                if (nval !== oval) {
                    var value = format(ctrl.$modelValue);
                    if (value) {
                        ctrl.$setViewValue(value);
                        ctrl.$render();
                    } else if (ctrl.fieldDefaultValueChangeListener) {
                        ctrl.fieldDefaultValueChangeListener();
                    }
                }
            });
        }
    };
});

module.directive('fieldHelp', function ($compile, i18nFilter) {
    var template0=_.template('<button class="btn btn-primary" type="button" uib-popover="<%- i18n(help) %>" popover-trigger="mouseenter" popover-placement="left" >?</button>');
    var template =_.template(
        '<div class="input-group-btn">' +
            '<button class="btn btn-primary" type="button" uib-popover="<%- i18n(help) %>" popover-trigger="mouseenter" popover-placement="bottom">?</button>' +
        '</div>');

    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {

            function render() {
                scope.help = attr.fieldHelp;
                var e = elem.next('.input-group-btn')
                if(e.length > 0){
                    e.append($compile(template0({ help: attr.fieldHelp, i18n: i18nFilter }))(scope));
                }else{
                    elem.after($compile(template({ help: attr.fieldHelp, i18n: i18nFilter }))(scope));
                }
            }
            render();
        }
    };
});

module.directive('labelHelp', function ($compile, $filter, i18nFilter) {
    var template =_.template('<span uib-popover="<%- i18n(help) %>" popover-trigger="mouseenter" popover-placement="top" >'+
                              '<i class="fa fa-question-circle fa-fw text-primary" aria-hidden="true"></i>' +
                            '</span>');

    return {
        scope: false,
        restrict: 'A',
        link: function (scope, elem, attr, ctrl) {

            function render() {
                scope.label = attr.labelHelp;
                scope.help = scope.label + '.help';
                elem.append($filter('i18n')(scope.label));
                elem.append($compile(template({ help: attr.labelHelp + '.help', label: attr.labelHelp, i18n: i18nFilter }))(scope))
            }
            render();
        }
    };
});

module.directive('multiSelect', function ($timeout) {
    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            $timeout(function () {
                elem.multiselect({
                    numberDisplayed: 10,
                    nonSelectedText: null,
                    allSelectedText: null,
                    buttonContainer: '<div></div>',
                    buttonClass: '',
                    templates: {
                        button: '<button type="button" class="multiselect form-control left" data-toggle="dropdown"><span class="multiselect-selected-text"></span>&nbsp;<b class="caret caret-right"></b></button>'
                    },
                    onChange: function (options, checked, select) {

                    }
                });

                scope.$on('$destroy', function () {
                    elem.multiselect('destroy');
                });
            });
        }
    };
});

module.directive('datetimeFormat', function () {
    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var format = scope.$eval(attr.datetimeFormat);

            ctrl.$formatters.push(function (date) {
                return date ? moment(date).format(format) : null;
            });

            ctrl.$parsers.push(function (date) {
                return date ? moment(date, format).toDate() : null;
            });
        }
    };
});

module.directive('datetimepicker', function ($parse, Locale) {
    return {
        scope: false,
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var options;

            if (attr.datetimepicker) {
                options = $parse(attr.datetimepicker)(scope);
            }

            var MAIN = elem.closest('div.modal');
            if (!MAIN.length) {
                MAIN = $('div.main-container');
            }

            options = options || {};
            _.defaults(options, {
				moment: false,
                icons: {
                    time: 'fa fa-clock-o',
                    date: 'fa fa-calendar',
                    up: 'fa fa-chevron-up',
                    down: 'fa fa-chevron-down',
                    previous: 'fa fa-chevron-left',
                    next: 'fa fa-chevron-right',
                    today: 'fa fa-crosshairs',
                    clear: 'fa fa-trash',
                    close: 'fa fa-remove'
                },
                format: 'YYYY-MM-DD HH:mm',
                locale: Locale,
                sideBySide: false,
                //timeZone: null,
                calendarWeeks: false,
                useCurrent: false,
				widgetParent: MAIN,
            });

            var e = elem.parent();
            if (!e.is('div') || !e.hasClass('input-group')) {
                e = elem;
            }

            e.datetimepicker(_.omit(options, 'moment'));

            e.data("DateTimePicker").date(ctrl.$modelValue || null);

            e.on('dp.change', function () {
                ctrl.$setViewValue(elem.val());
            });

			e.on('dp.show', function () {
				var w = MAIN.find('.bootstrap-datetimepicker-widget:last')[0];
				var mr = MAIN[0].getBoundingClientRect();
				var ir = e[0].getBoundingClientRect();
				if (w.classList.contains('bottom')) {
					w.style.left = Math.round(ir.left - mr.left) + 'px';
					w.style.top = Math.round(ir.bottom - mr.top) + 'px';
				} else {
					w.style.left = Math.round(ir.left - mr.left) + 'px';
					w.style.bottom = Math.round(mr.bottom - ir.top) + 'px';
				}
			});

            ctrl.$formatters.push(function (date) {
                if (date) {
                    return moment.isMoment(date) ? date.format(options.format) : moment(date).format(options.format);
                } else {
                    return null;
                }
            });

            ctrl.$parsers.push(function (date) {
                var m = moment(date, options.format, true);
                if (m.isValid()) {
                    return m.toDate();
                } else {
                    return ctrl.$modelValue;
                }
            });
        }
    };
});

/**
 * Bootstrap-toggle Directive
 * @link https://github.com/minhur/bootstrap-toggle
 */

module.directive('toggleCheckbox', function() {

        /**
         * Directive
         */
        return {
            restrict: 'A',
            transclude: true,
            replace: false,
            require: 'ngModel',
            link: function ($scope, $element, $attr, require) {
                var ngModel = require;

                // update model from Element
                var updateModelFromElement = function() {
                    // If modified
                    var checked = $element.prop('checked');
                    if (checked != ngModel.$viewValue) {
                        // Update ngModel
                        ngModel.$setViewValue(checked);
                        $scope.$apply();
                    }
                };

                // Update input from Model
                var updateElementFromModel = function() {
                    // Update button state to match model
                    $element.trigger('change');
                };

                // Observe: Element changes affect Model
                $element.on('change', function() {
                    updateModelFromElement();
                });

                // Observe: ngModel for changes
                $scope.$watch(function() {
                    return ngModel.$viewValue;
                }, function() {
                    updateElementFromModel();
                });

                // Initialise BootstrapToggle
                $element.bootstrapToggle();
            }
        };
    });

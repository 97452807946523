/**
 * Created by Karol on 2015-12-01.
 */
var module = angular.module('meternet.dashboard.controllers.diffWidget', [ 'adf.provider', 'i18n',
    'meternet.dashboard.constants' ]);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl : 'dashboard/diff-widget.html',
        title : messages['dashboard.widgets.diffWidget.title'],
        description : messages['dashboard.widgets.diffWidget.title'],
        controller : 'DiffWidgetCtrl',
        resolve : {
        },
        config : {
            dashboardId: null
        },
        edit : {
            controller : 'DiffWidgetEditCtrl',
            templateUrl : 'dashboard/diff-widget-edit.html'
        }
    };

    dashboardProvider.widget('diff-widget', widget);

});

function DiffWidgetEditCtrl($scope,configService) {

    configService.get().then(function(config) {
       var gui = config.gui;
        $scope.dashboards = gui.dashboards;
    });




}

function DiffWidgetCtrl($scope, $location, configService, dataService, unitFilter, i18nFilter){
//    $scope.timestampFrom = new Date(new Date()-86400000);
    $scope.timestampFrom = new Date(new Date()-900000);
    $scope.timestampTo = new Date();
    $scope.measurementFrom = null;
    $scope.measurementTo = null;
    $scope.hideValue = true;
    $scope.options = {
        header:"header",
        title:"title"

    }
    $scope.ui = {
        unitFilterFun: function(d, p){
            if(p){
                return unitFilter(d, p.precision, p.unit, p.scale);
            }else{
                return "---"
            }
        },
        submit: function(){
            var reqTo = {
                deviceId: $scope.device.id,
                paramId: $scope.param.id,
                timeFrom: new Date($scope.timestampTo - 900000 ),
                timeTo: $scope.timestampTo
            }
            dataService.requestParamData(reqTo.paramId, reqTo).then(function (measurements) {
                $scope.measurementTo = measurements[measurements.length-1];
                $scope.hideValue = false;
            });

            var reqFrom = {
                deviceId: $scope.device.id,
                paramId: $scope.param.id,
                timeFrom: new Date($scope.timestampFrom - 900000 ),
                timeTo: $scope.timestampFrom
            }
            dataService.requestParamData(reqFrom.paramId, reqFrom).then(function (measurements) {
                $scope.measurementFrom = measurements[measurements.length-1];
                $scope.hideValue = false;
            });
        },
        updateSeriesDevice: function(d){
            $scope.device = d;
            $scope.hideValue = true;
        },
        updateSeriesParam: function(p){
            $scope.param = p;
            $scope.hideValue = true;
        },
        hideValueFun:function(){
            $scope.hideValue = true;
        },
        getStartPDF: function(){
        	var deviceLabel = !!$scope.device.label ? $scope.device.label : $scope.device.name;
        	var deviceDescr1 = !!$scope.device.desc ? $scope.device.desc : " "
        	var deviceDescr2 = !!$scope.device.desc2 ? $scope.device.desc2 : " "
        	var deviceDescr3 = !!$scope.device.desc3 ? $scope.device.desc3 : " "
            var docDefinition = {
                content: [
                    { text: moment(new Date()).format("YYYY-MM-DD HH:mm"), alignment: 'right' },
                    ' ',
                    ' ',
                    { text: i18nFilter('dashboard.widgets.diffWidget.report'), style: 'subtitle' },
                    { text: i18nFilter('config.device.deviceName') + ': ' + deviceLabel },
                    { text: i18nFilter('config.device.descr1') + ': ' + deviceDescr1 },
                    { text: i18nFilter('config.device.descr2') + ': ' + deviceDescr2 },
                    { text: i18nFilter('config.device.descr3') + ': ' + deviceDescr3 },
                    {
                        style: 'tableExample',
                        table: {
                                headerRows: 1,
                                // keepWithHeaderRows: 1,
                                // dontBreakRows: true,
                                widths: ['*', '*'],
                                body: [
                                    [
                                        { text: i18nFilter('dashboard.widgets.diffWidget.timestamp'), style: 'tableHeader' },
                                        { text: i18nFilter('dashboard.widgets.diffWidget.value'), style: 'tableHeader' }
                                    ],
                                    [
                                        moment($scope.measurementFrom.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                                        {text: unitFilter($scope.measurementFrom.value, $scope.param.precision, $scope.param.unit, $scope.param.scale), alignment: 'right' }
                                    ],
                                    [
                                        moment($scope.measurementTo.timestamp).format("YYYY-MM-DD HH:mm:ss"),
                                        {text: unitFilter($scope.measurementTo.value, $scope.param.precision, $scope.param.unit, $scope.param.scale), alignment: 'right' }
                                    ],
                                    [
                                        {text:i18nFilter('dashboard.widgets.diffWidget.diff')+":", alignment: 'right' },
                                        {text: unitFilter($scope.measurementTo.value - $scope.measurementFrom.value, $scope.param.precision, $scope.param.unit, $scope.param.scale), alignment: 'right' }
                                    ]
                                ]
                        }
                    }
                ],
                styles: {
                    header: {
                        fontSize: 12
                    },
                    title: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                        alignment:'center'
                    },
                    subtitle: {
                        fontSize: 16,
                        bold: true,
                        margin: [0, 10, 0, 5],
                        alignment:'center'
                    },
                    tableExample: {
                        margin: [0, 5, 0, 15]
                    },
                    tableHeader: {
                        bold: true,
                        fontSize: 13,
                        color: 'black'
                    }
                },
                defaultStyle: {
                    // alignment: 'justify'
                }
            };
            pdfMake.createPdf(docDefinition).download($scope.device.label || $scope.device.name + '.pdf');
        }
    }
    $scope.devices = [];
    $scope.device = null;
    $scope.params = [];
    $scope.param = null;
    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });
    });
}

module.controller('DiffWidgetEditCtrl', DiffWidgetEditCtrl);
module.controller('DiffWidgetCtrl', DiffWidgetCtrl);

<div class="alert alert-success" x-ng-if="status">
    <h1>
        <i class="fa fa-plug" aria-hidden="true"></i>
        {{'config.support.on' | i18n}}
    </h1>
</div>

<div class="alert alert-danger" x-ng-if="!status">
    <h1>
        <i class="fa fa-plug" aria-hidden="true"></i>
        {{'config.support.off' | i18n}}
    </h1>
</div>
<div class="col-md-1 col-md-offset-3">
    <button type="button" class="btn btn-success btn-lg" x-ng-click="turnOn()" x-ng-disabled="status">
        {{'config.support.turnon'| i18n}}
    </button>
</div>
<div class="col-md-1 col-md-offset-4">
    <button type="button" class="btn btn-danger btn-lg" x-ng-click="turnOff()" x-ng-disabled="!status">
        {{'config.support.turnoff'| i18n}}
    </button>
</div>
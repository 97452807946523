<!--{{reading}} {{readout}}-->
<div style="padding-top: 7px;" >
    <a href="readout" class="connection-status no-cursor">
        <span class="fa-stack text-primary" tooltip-placement="bottom" uib-tooltip="{{'readout.go' | i18n}}" x-ng-if="reading">
            <i class="fa fa-play-circle fa-stack-2x" aria-hidden="true"></i>
        </span>
        <span class="fa-stack text-primary" tooltip-placement="bottom" uib-tooltip="{{'readout.stop' | i18n}}" x-ng-if="!reading && !readout">
            <i class="fa fa-stop-circle fa-stack-2x" aria-hidden="true"></i>
        </span>
        <span class="fa-stack text-primary" tooltip-placement="bottom" uib-tooltip="{{'readout.one' | i18n}}" x-ng-if="!reading && readout">
            <i class="fa fa-play-circle-o fa-stack-2x" aria-hidden="true"></i>
        </span>
    </a>
    <a href="/config#8,6" class="connection-status no-cursor">

        <span class="fa-stack text-primary" tooltip-placement="bottom" uib-tooltip="{{'config.storage.ok' | i18n}}" x-ng-if="!databaseErr">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-database fa-stack-1x fa-inverse"></i>
        </span>
        <span class="fa-stack text-primary" tooltip-placement="bottom" uib-tooltip="{{databaseErrMsg}}" x-ng-if="databaseErr">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-database fa-stack-1x fa-inverse"></i>
            <i class="fa fa-exclamation fa-stack-2x text-danger fa-blink"></i>
        </span>


        <!--<span class="fa-stack">-->
            <!--<i class="fa fa-circle fa-stack-2x"></i>-->
            <!--<i class="fa fa-database fa-stack-1x fa-inverse"></i>-->
            <!--<i class="fa fa-exclamation fa-stack-2x text-danger" x-ng-show="databaseErr"></i>-->
        <!--</span>-->
    </a>
</div>


<div x-ng-show="!reading" style="    position: fixed;
                                     width: 100%;
                                     height: 100%;
                                     border-color: red;
                                     border-width: 2px;
                                     border-style: solid;
                                     left: 0px;
                                     top: 0px;
                                     z-index: -1000;
                                     pointer-events: none;">
</div>

<div style="    position: fixed;
                width: 300px;
                right: 20px;
                bottom: 0px;
                z-index: -1000;
                pointer-events: none;">

    <div class="alert" role="alert" x-ng-if="notification.level"
                        style="border-width:2px"
                        x-ng-class="{'alert-danger': (notification.level==2),
                                    'alert-warning': (notification.level==1),
                                    'alert-info': (notification.level==0) }">
        <dl>
            <dt>{{ notification.timestamp | date:'yyyy-MM-dd HH:mm:ss' }}</dt>
            <dd x-ng-bind-html="display(notification)"></dd>
        </dl>
    </div>
</div>


<!--<div id="loading-backgroud"></div>-->
<!--<div id="loading-modal">-->
    <!--<div id="loading-border">-->
        <!--<i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i>-->
    <!--</div>-->
<!--</div>-->
<div class="modal-header"><i class="fa fa-fw" x-ng-class="ui.mode"></i>&nbsp;{{ ui.modalTitle | i18n }}
    <button type="button" class="close" x-ng-click="cancel()" aria-hidden="true">×</button>
</div>
<div class="modal-body">
    <form name="frame" class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-sm-4">{{'timetable.copy.copyFrom' | i18n}}:</label>
            <div class="col-sm-5">
                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="copyFrom" x-datetimepicker=""/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-4">{{'timetable.copy.copyTo' | i18n}}:</label>
            <div class="col-sm-5">
                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="copyTo" x-datetimepicker=""/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>

            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-4">{{'timetable.copy.dateFrom' | i18n}}:</label>
            <div class="col-sm-5">
                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="dateFrom" x-datetimepicker="{moment:true, calendarWeeks: true, daysOfWeekDisabled: [0,2,3,4,5,6]}"/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-4">{{'timetable.copy.dateTo' | i18n}}:</label>
            <div class="col-sm-5">
                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="dateTo" x-datetimepicker="{moment:true, calendarWeeks: true, daysOfWeekDisabled: [0,2,3,4,5,6]}"/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>

            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-success" data-ng-click="ok()" ng-disabled="frame.$invalid"><i class="fa fa-save fa-lg"></i>&nbsp;&nbsp;{{'timetable.frame.add' | i18n}}</button>
    <button class="btn btn-danger" data-ng-click="cancel()"><i class="fa fa-undo fa-lg"></i>&nbsp;&nbsp;{{'timetable.frame.cancel' | i18n}}</button>
</div>
<form class="form-horizontal">
    <label class="control-label">{{'config.name' | i18n}}</label>
    <input class="form-control" x-ng-model="parameter.name"/>

    <label class="control-label">{{'config.type' | i18n}}</label>
    <div class="row">
        <div class="col-sm-3" x-ng-repeat="parameter in ui.parameters">
            <div class="thumbnail" x-ng-class="ui.active(parameter)" x-ng-click="ui.select(parameter)">
                <img alt="" src="" x-ng-src="{{parameter.iconUrl}}"/>
                <div class="caption">
                    <span class="caption-label">{{'config.parameter.type.' + parameter.type | i18n}}</span>
                </div>
            </div>
        </div>
    </div>
</form>

/*global tools */
var module = angular.module('meternet.chart.directives.shiftBar', [
    'meternet.dashboard.constants'
]);

module.directive('shiftBarGraph', function($window, $timeout, DashboardEvents, unitFilter, dashboardService, configService, $rootScope, $filter) {

    function ShiftBarGraph(svg, opt) {
        var options = {
            margin: {
                top: 25,
                right: 0,
                bottom: 50,
                left: 100
            },
            barSize: 20,
            barSpacing: 5,
            period: 'day',
            dateFormat: 'YYYY-MM-DD',
            tickLabelEveryXTicks: 1,
            unit: 'Wh',
            axis: {
                precision: 0
            },
            hiddenLegend: false
        };

        function getSeries() {
            var result = {};
            for (var i = 0; i < options.series.length; ++i) {
                result[options.series[i].paramId] = options.series[i];
            }
            return result;
        }

        function getBarColors() {
            var result = [];
            for (var i = 0; i < options.series.length; ++i) {
                if (options.series[i].barColors) {
                    for (var j = 0; j < options.series[i].barColors.length; ++j) {
                        if (options.series[i].barColors[j].name !== '') {
                            result.push(options.series[i].barColors[j]);
                        }
                    }
                }
            }
            return result;
        }


        this.options = function (o) {
            if (!arguments.length) {
                return tools.copy(options);
            } else {
                tools.copy(o, options);
                if (options.period === 'day') {
                    options.dateFormat = "YYYY-MM-DD (dd)";
                } else if (options.period === 'week') {
                    options.dateFormat = "YYYY [" + $filter('i18n')('dashboard.widgets.shiftBar.ui.week') + "] WW";
                } else if (options.period === "month") {
                    options.dateFormat = "YYYY-MM";
                }
                return this;
            }
        };

        //mock daily
        //var points = [];
        //for (var i = 0 ; i <3; ++i) {
        //    if (i !== 4) {
        //        points.push({
        //            "paramId": "f6347d60-8175-11e5-8671-cdde93d7da45",
        //            "value": (i+1)*20,
        //            "timestamp": moment().startOf('day').add(i*8, 'hours'),
        //            "timestampTest": moment().startOf('day').add(i*8, 'hours')
        //        });
        //        points.push({
        //            "paramId": "ffb2e8e0-8175-11e5-8671-cdde93d7da45",
        //            "value": (i+1)*10,
        //            "timestamp": moment().subtract(1,'days').startOf('day').add(i*8, 'hours'),
        //            "timestampTest": moment().subtract(1,'days').startOf('day').add(i*8, 'hours')
        //        });
        //        points.push({
        //            "paramId": "ffb2e8e0-8175-11e5-8671-cdde93d7da45",
        //            "value": (i+1)*40,
        //            "timestamp": moment().startOf('day').add(i*8, 'hours'),
        //            "timestampTest": moment().startOf('day').add(i*8, 'hours'),
        //           // "instant": true
        //        });
                //points.push({
                //    "paramId": "ffb2e8e0-8175-11e5-8671-cdde93d7da45",
                //    "value": (i+1)*10,
                //    "timestamp": moment().subtract(7,'days').startOf('day').add(i*3, 'hours'),
                //    "timestampTest": moment().subtract(7,'days').startOf('day').add(i*3, 'hours')
                //});
           // }

      //  }

        //for (var i =0; i < 7; ++i) {
        //    points.push({
        //        "paramId": "f6347d60-8175-11e5-8671-cdde93d7da45",
        //        "value": (i+1)*20,
        //        "timestamp": parseInt((moment().add(i, 'weeks').format('x')))
        //    });
        //}

        //for (var i =0; i < 7; ++i) {
        //    points.push({
        //        "paramId": "f6347d60-8175-11e5-8671-cdde93d7da45",
        //        "value": (i+1)*20,
        //        "timestamp": parseInt((moment().add(i, 'months').format('x')))
        //    });
        //}

        //this.data = points;




        // initialization
        this.options(opt);
        var series = getSeries();
        var barColors = getBarColors();
        this.autoscroll = options.enableScroll;
        this.destroyed = false;

        var width = options.width;
        var height = options.height;
        var groupWidth = 0;

        var chart = this;
        svg.attr("class", "shift-bar-graph");
        svg.attr("width", width);
        svg.attr("height", height);


        var x = d3.scale.linear();
        var y = d3.scale.linear().range([height, 0]);
        var xAxis = d3.svg.axis().scale(x).orient("bottom");
        var yAxis = d3.svg.axis().scale(y).orient("left");
        var zoom = null;

        svg.append("g")
            .attr("class", "y axis");
        //.attr("transform", "translate("+options.margin.left + "," + options.margin.top + ")");

        var canvas = svg.append("g").attr("class", "chart-canvas");
        canvas.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(" + options.margin.left + "," + (height) + ")")
            .call(xAxis);


        var chartId = _.uniqueId('shiftBar-graph');
        var defs = svg.append('defs').attr('class', 'chart-defs');
        var areaClip = defs.append("clipPath").attr("id", chartId + "-area-clip");
        areaClip.append("rect").attr("x", 0).attr("y", 0);


        this.destroy = function () {
            this.destroyed = true;
        };


        this.update = function (data) {
            this.data = data;
            this.render();
        };

        this.render = function () {
            var chart = this;
            if (this.destroyed) {
                return;
            }

            var barNumberPerPeriod = options.historyTime;
            if (!chart.dragged) {
                var data = prepareData(chart.data);
                this.filteredData = data;
            } else {
                var data = chart.filteredData;
            }

            //TODO (KB) Test case: jezeli mamy Cron co 1h i zwiekszymy na np 8h, na slupku pojawia sie x wartosci, ktore zapisaly sie, kiedy cron byl 1h
            //w tej chwili rozwiazane jest to poprzez wybranie najstarszej wartości dla danego paramId
            function prepareData(data) {
                var result = [];
                data = _.sortBy(data, function (obj) {
                    return obj.index + '_' + moment(obj.timestamp).format('x');
                });
                for (var i = 0; i < data.length; ++i) {
                    var d = {};
                    var skip = false;
                    d.paramId = data[i].paramId;
                    d.value = data[i].value;
                    if (i > 0) {
                        if (data[i-1].paramId === data[i].paramId) {
                            d.shiftedValue = data[i].value - data[i-1].value > 0 ? data[i].value - data[i-1].value : 0;
                        } else {
                            d.shiftedValue = NaN;
                        }
                    } else {
                       d.shiftedValue = NaN;
                    }
                    d.key = data[i].paramId + '_' + data[i].timestamp;
                    d.timestamp = parseInt(moment(data[i].timestamp).format('x'));
                    d.sequencedTimestamp = sequence(data[i].timestamp);
                    var sameBarMeasurements = _.filter(result, function (r) {
                        return r.sequencedTimestamp === d.sequencedTimestamp;
                    });
                    if (sameBarMeasurements.length > 0) {
                        var lastIndex = sameBarMeasurements.length - 1;
                        var lastMeasurement = sameBarMeasurements[lastIndex];
                        d.y0 = lastMeasurement.y1;
                        d.y1 = lastMeasurement.y1 + d.shiftedValue;
                        d.max = true;
                        for (var j = 0; j < sameBarMeasurements.length; j++) {
                            if (sameBarMeasurements[j].paramId === d.paramId) {
                                skip = true;
                            } else {
                                sameBarMeasurements[j].max = false;
                            }
                        }
                    } else {
                        d.y0 = 0;
                        d.y1 = d.shiftedValue;
                        d.max = true;
                    }

                    if (!isNaN(d.y1) && !skip) {
                        result.push(d);
                    }
                }
                //if (data.length > 0) {
                //    var cop = data[data.length-1];
                //    cop.timestamp = 1452864560000;
                //    cop.y0 = cop.y1;
                //    cop.y1 = 2222222222;
                //    cop.sequencedTimestamp = sequence(cop.timestamp);
                //    result.push(cop);
                //}

                return result;
            }

            var resized = false;
            var w = options.width - (options.margin.right + options.margin.left);
            var h = options.height - (options.margin.top + options.margin.bottom);
            var gw = options.barSize * 3;

            if (w !== width || h !== height || gw !== groupWidth) {
                resized = true;
            }
            width = w;
            height = h;
            groupWidth = gw;
            if (resized) {
                svg.attr({
                    width: options.width,
                    height: options.height
                });
            }

            areaClip.select("rect").attr({
                "width": width,
                "height": height + 20,
                "x": options.margin.left,
                "y": options.margin.top
            });

            canvas.attr("clip-path", "url(#" + chartId + "-area-clip)");
            canvas.attr({
                "width": width - 100,
                "height": height + 20,
                "x": options.margin.left,
                "y": options.margin.top
            });

            var visibleBars = width / (options.barSize + options.barSpacing * 2);

            var xMax = d3.max(data, function (d) {
                return d.timestamp;
            });
            var xMin = data.length ? d3.min(data, function (d) {
                return d.timestamp;
            }) : calculateXMin();

            function calculateXMin() {
                var visiblePeriod = visibleBars / barNumberPerPeriod;
                return {
                        "sequencedTimestamp": sequence(Date.now() - visiblePeriod * getPeriodEpoch())
                    }
            };

            if (!zoom) {
                x.domain([xMin.sequencedTimestamp, xMin.sequencedTimestamp + visibleBars]);
                $rootScope.$broadcast('shiftBarGraphDatesChanged', revertSequence(x.domain()[0]) - getPeriodEpoch(), revertSequence(x.domain()[1]) + getPeriodEpoch());
            }
            x.range([0, width]);

            y = d3.scale.linear().range([height, 0]);

            function sequence(timestamp) {
                if (options.period === 'week') {
                    return Math.round(barNumberPerPeriod * timestamp / (1000 * 60 * 60 * 24 * 7));
                } else if (options.period === 'month') {
                    return Math.round(barNumberPerPeriod * timestamp / (1000 * 60 * 60 * 24 * 31));
                }
                return Math.round(barNumberPerPeriod * timestamp / (1000 * 60 * 60 * 24));
            }

            function revertSequence(number) {
                if (options.period === 'week') {
                    return number * 1000 * 60 * 60 * 24 * 7 / barNumberPerPeriod;
                } else if (options.period === 'month') {
                    return number * 1000 * 60 * 60 * 24 * 31 / barNumberPerPeriod;
                }
                return number * 1000 * 60 * 60 * 24 / barNumberPerPeriod;
            }


            function getTickValues(domain) {
                var x1 = revertSequence(domain[0]);
                var x2 = revertSequence(domain[1]);
                var result = [];
                var startDate = moment(x1).startOf(options.period);
                var endDate = moment(x2).add(2, options.period).endOf(options.period);
                for (var m = moment(startDate); m <= endDate; m = moment(m.add(1, options.period))) {
                    result.push(sequence(m.format('x')));
                }
                return result;
            }

            xAxis = d3.svg.axis().scale(x).orient("bottom");
            yAxis = d3.svg.axis().scale(y).orient("left");


            if (!options.axis) {
                y.domain([0, 100]);
            } else if (options.axis && options.axis.rangeAuto) {
                if (data.length > 0) {
                    y.domain([0, d3.max(data, function (d) {
                        return d.y1 * 1.1;
                    })]);
                } else {
                    y.domain([0, 100]);
                }

            } else {
                y.domain([options.axis.rangeMin, options.axis.rangeMax]);
            }

            xAxis.tickValues(getTickValues(x.domain())).tickFormat(function (d, i) {
                if (d % options.tickLabelEveryXTicks === 0) {
                    return moment(revertSequence(d)).format(options.dateFormat);
                }
                return "";
            });

            var svgXAxis = canvas.select(".x.axis")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + (height + options.margin.top) + ")")
                .call(xAxis);

            var svgYAxis = svg.select(".y.axis")
                .attr("transform", "translate(" + options.margin.left + "," + options.margin.top + ")")
                .call(yAxis);

            svgXAxis.selectAll(".tick").select("line")
                .attr({
                    "x1": 0,
                    "x2": 0,
                    "y1": -height,
                    "y2": 10
                });
            svgXAxis.selectAll(".tick").select("text")
                .attr("x", function (d, i) {
                    return (options.barSize + options.barSpacing * 2) * barNumberPerPeriod / 2;
                });

            svgYAxis.selectAll(".tick").select("line")
                .attr({
                    "x2": width
                });
            svgYAxis.selectAll(".tick").select("text").text(function (d) {
                var result = unitFilter(d, options.axis.precision, "", options.axis.scale).replace('-', '−');
                return result;
            });
            var gAxisLabel = svgYAxis.select('g.axis-label');
            if (gAxisLabel.empty()) {
                gAxisLabel = svgYAxis.append('g').attr({
                    "class": 'axis-label'
                }).append('text').attr({
                    "y": -10
                }).style({
                    "text-anchor": "middle"
                });
            }
            gAxisLabel.select('text').text(function (d) {
                return unitFilter(1, -1, options.unit, options.axis.scale);
            });


            function drawBars() {
                var bar = canvas.selectAll("g.bar")
                    .data(data, function(d) {
                        return d.key;
                    });
                var barEnter = bar.enter();
                var enteredG = barEnter.append("g")
                    .attr("class", function (d) {
                        if (d.instant) {
                            return "bar instant";
                        }
                        return "bar";
                    });
                enteredG.append("rect");
                enteredG.append("text");
                bar.select("rect")
                    .attr("y", function (d) {
                        return y(d.y1);
                    })
                    .attr("height", function (d) {
                        return y(d.y0) - y(d.y1);
                    }
                )
                    .attr("width", options.barSize)
                    .attr("x", function (d, i) {
                        return x(sequence(d.timestamp)) + options.barSpacing;
                    })
                    .attr("transform", "translate(0," + (options.margin.top) + ")")
                    .style("fill", function (d, i) {
                        var sequenced = d.sequencedTimestamp;
                        var index = sequenced % barNumberPerPeriod;
                        return series[d.paramId] != null ? series[d.paramId].barColors[index].color : "#4682B4";
                    });
                bar.select("text")
                    .text(function (d) {
                        if (d.max === true) {
                            return unitFilter(d.y1, 0, "", options.axis.scale).replace('-', '−');
                        }
                        return '';

                    })
                    .attr({
                        "y": function (d, i) {
                            return y(d.y1) + 20;
                        },
                        "x": function (d, i) {
                            var bb = this.getBBox().width;
                            return x(d.sequencedTimestamp) - bb / 2 + options.barSpacing + options.barSize / 2;
                        }
                    })
                    .style("text-anchor", "start");

                bar.exit().remove();

                function prepareAnimation(selection, duration) {
                    if (selection.length > 0) {
                        if (!selection.attr("animated")) {
                            selection.attr("animated", true);
                            animate(selection, duration);
                        }
                    }
                }

                function animate(selection, duration) {
                    selection.transition().style("opacity", "1")
                        .transition().style("opacity", "0.5");
                    setTimeout(function () {
                        animate(selection, duration);
                    }, duration);
                }


                var barInstant = canvas.selectAll("g.bar.instant");
                barInstant.selectAll("rect")
                    .call(prepareAnimation, 1000);
            }

            drawBars();
            if (!options.hiddenLegend) {
                var legend = svg.selectAll("g.legend")
                    .data(barColors);
                var legendEnter = legend.enter();
                var legendG = legendEnter.append("g")
                    .attr("class", "legend");
                legendG.append("rect");
                legendG.append("text");
                var rectNum = 0;
                legend.selectAll("rect")
                    .attr("x", function (d, i) {
                        rectNum++;
                        return options.margin.left * (rectNum);
                    })
                    .attr("y", function (d, i) {
                        return height + options.margin.bottom;
                    })
                    .attr("width", 18)
                    .attr("height", 18)
                    .style("fill", function (d) {
                        return d.color
                    });
                rectNum = 0;
                legend.selectAll("text")
                    .attr("x", function (d, i) {
                        rectNum++;
                        return options.margin.left * (rectNum) + 20;
                    })
                    .attr("y", height + options.margin.bottom + 9)
                    .attr("dy", ".35em")
                    .style("text-anchor", "start")
                    .text(function (d) {
                        return d.name;
                    });
            }


            if (!zoom) {
                zoom = d3.behavior.zoom().size([width, height]).x(x).scaleExtent([1, 1])
                    .on("zoom", zoomed)
                    .on("zoomstart", function(){
                        chart.dragged = true;
                    })
                    .on("zoomend", function () {
                        chart.dragged = false;
                        $rootScope.$broadcast('shiftBarGraphDatesChanged', revertSequence(x.domain()[0]) - getPeriodEpoch(), revertSequence(x.domain()[1]) + getPeriodEpoch());
                    });
                svg.call(zoom);
            }

            function getPeriodEpoch() {
                if (options.period === 'day') {
                    return 1000 * 60 * 60 * 24;
                } else if (options.period === 'week') {
                    return 1000 * 60 * 60 * 24 * 7;
                } else if (options.period === 'month') {
                    return 1000 * 60 * 60 * 24 * 31;
                }
            }

            function zoomed() {
                svg.select('.x.axis').call(xAxis);
                chart.render();
            }
        };
    }

    return {
        restrict : 'A',
        scope : {
            options : '='
        },
        link : function(scope, elem, attrs) {
            function redraw() {
                if (!scope.redrawPromise) {
                    scope.redrawPromise = $timeout(function () {
                        scope.chart.options({
                            width: elem.parent().innerWidth(),
                            height: elem.parent().innerHeight()
                        });
                        scope.chart.update(scope.data);
                        delete scope.redrawPromise;
                    });
                }
            }

            elem.addClass("shift-bar-graph");
            scope.options.width = elem.parent().innerWidth();
            scope.options.height = elem.parent().innerHeight();
            configService.get().then(function(config) {
                for (var i = 0; i < scope.options.series.length; ++i) {
                    var paramId = scope.options.series[i].paramId;
                    var name;
                    var res = _.find(config.engine.measurementInputs, function(input) {
                        return _.find(input.devices, function(device) {
                            return _.find(device.params, function(param) {
                                if (param.id === paramId) {
                                    name = param.name;
                                }
                                return param.id === paramId;
                            })
                        })
                    });
                    scope.options.series[i].name = name;
                }
                scope.chart = new ShiftBarGraph(d3.select(elem[0]).append('svg').attr("class", "line-chart"), scope.options);
            });


            scope.$on(DashboardEvents.REDRAW, function (event, data) {
                if (data){
                    scope.data = data;
                }
                redraw();
            });

            scope.$on(DashboardEvents.RESIZE, function (event) {
                redraw();
            });

            scope.$on('$destroy', function () {
                scope.chart.destroy();
            });

            //scope.$on('generateCsv', function(event) {
            //    dashboardService.generateCsv(scope.data);
            //});

            redraw();
        }
    };
});

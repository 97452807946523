var module = angular.module('meternet.charts.gauge.services.gauge-serie', []);

function GaugeSerieFactory(gaugeUtils) {
    function GaugeSerie(body, serie) {

        this.scaleAngle = 270;
        this.scaleAngleOffset = (this.scaleAngle / 2) - 90;
        this.body = body;
        this.serie = serie;
        var self = this;

        this.valueToDegrees = function(value) {
            var serie = this.serie;
            return value / serie.range * self.scaleAngle
                    - (serie.min / serie.range * self.scaleAngle + self.scaleAngleOffset);
        };

        this.valueToRadians = function(value) {
//console.log(value + " " + this.valueToDegrees(value) + " " + this.valueToDegrees(value) * Math.PI / 180);
            return this.valueToDegrees(value) * Math.PI / 180;
        };

        this.valueToPoint = function(value, factor) {
            return {
                x : this.config.cx - this.config.radius * factor * Math.cos(this.valueToRadians(value)),
                y : this.config.cy - this.config.radius * factor * Math.sin(this.valueToRadians(value))
            };
        };
        this.drawTicks = function(ticks) {
            var serie = self.serie;
            var t = self.body.selectAll("g.tick").data(ticks, function(d) {
                return (d.major ? "major" : "minor") + d.value;
            });

            var nt = t.enter().append("g").attr("class", "tick");
            nt.classed("tick-minor", function(d) {
                return !d.major;
            }).classed("tick-major", function(d) {
                return d.major;
            });
            t.each(function(d) {
                var tick = d3.select(this);
                var line = tick.select("line");
                if (line.empty()) {
                    line = tick.append("line");
                }
                var point1, point2;
                if (d.major) {
                    point1 = self.valueToPoint(d.value, 0.74);
                    point2 = self.valueToPoint(d.value, 0.91);
                    line.attr("x1", point1.x).attr("y1", point1.y).attr("x2", point2.x).attr("y2", point2.y).style(
                            "stroke", "#333").style("stroke-width", "2px");
                } else {
                    point1 = self.valueToPoint(d.value, 0.76);
                    point2 = self.valueToPoint(d.value, 0.89);
                    line.attr("x1", point1.x).attr("y1", point1.y).attr("x2", point2.x).attr("y2", point2.y).style(
                            "stroke", "#666").style("stroke-width", "1px");
                }
            });
            t
                    .filter(function(d, i) {
                        return d.major;
                    })
                    .each(
                            function(d) {
                                var tick = d3.select(this);
                                var text = tick.select("text");
                                if (text.empty()) {
                                    text = tick.append("svg:text").attr("class", "tick-label");
                                }
                                var point1 = self.valueToPoint(d.value, 0.74);
                                var point2 = self.valueToPoint(d.value, 0.91);
                                var point = self.valueToPoint(d.value, 0.72);
                                var tickAngleTg = (point1.y - point2.y) / (point1.x - point2.x);
                                var alignmentBase = "baseline";
                                if (!isFinite(tickAngleTg) || Math.abs(tickAngleTg) > 0.7) {
                                    if (point1.y > point2.y) {
                                        alignmentBase = "central";
                                    } else {
                                        alignmentBase = "after-edge";
                                    }
                                }
                                var fontSize = Math.round(self.config.radius / 14);
                                text
                                        .attr("x", point.x)
                                        .attr("y", point.y)
                                        .attr("dy", fontSize / 3)
                                        .attr(
                                                "text-anchor",
                                                d.value !== ((serie.max - serie.min) / 2) + serie.min ? (d.value > ((serie.max - serie.min) / 2)
                                                        + serie.min ? "end" : "start")
                                                        : "middle").text(
                                                self.config.valueFormatter(d.value, serie.precision, "", serie.scale))
                                        .style("font-size", fontSize + "px").style("fill", "#333").style("font-weight",
                                                "bold").style("stroke-width", "0px").attr("alignment-baseline",
                                                alignmentBase);

                            });
            t.exit().remove();
        };
    }

    GaugeSerie.prototype.render = function(config) {

        if (config) {
            this.config = config;
            this.config.radius = config.size * 0.45;
            this.config.cx = config.width / 2;
            this.config.cy = config.height * 0.5;
        }
        var self = this;
        var serie = this.serie;
        // this.body.selectAll('*').remove();
        this.body.attr("class", "gauge").attr("width", this.config.width).attr("height", this.config.height);
        var outerCircle = this.body.select(".outer-circle");
        if (outerCircle.empty()) {
            outerCircle = this.body.append("svg:circle").attr("class", "outer-circle");
        }
        outerCircle.attr("cx", this.config.cx).attr("cy", this.config.cy).attr("r", this.config.radius).style("fill",
                "#999").style("stroke", "#000").style("stroke-width", "0.5px");
        var innerCircle = this.body.select(".inner-circle");
        if (innerCircle.empty()) {
            innerCircle = this.body.append("svg:circle").attr("class", "inner-circle");
        }
        innerCircle.attr("cx", this.config.cx).attr("cy", this.config.cy).attr("r", 0.95 * this.config.radius).style(
                "fill", "#fff").style("stroke", "#e0e0e0").style("stroke-width", "2px");

        var zones = this.body.selectAll("path.band").data(serie.zones);
        zones.enter().append("svg:path").attr("class", "band");
        zones.style("fill", function(zone) {
            return zone.color;
        }).attr(
                {
                    "d" : function(zone) {
                        return (d3.svg.arc().startAngle(self.valueToRadians(zone.from)).endAngle(
                                self.valueToRadians(zone.to)).innerRadius(0.75 * self.config.radius)
                                .outerRadius(0.9 * self.config.radius))();
                    },
                    "transform" : function() {
                        return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(270)";
                    }
                });
        zones.exit().remove();
        var fontSize = Math.round(this.config.radius / 6);
        if (undefined !== serie.name) {
            var labelText = this.body.select(".gauge-label");
            if (labelText.empty()) {
                labelText = this.body.append("svg:text").attr("class", "gauge-label");
            }
            labelText.attr("x", this.config.cx).attr("y", this.config.cy * 0.6).attr("dy", fontSize).attr(
                    "text-anchor", "middle").text(serie.name).style("font-size", fontSize + "px").style("fill", "#333")
                    .style("stroke-width", "0px");
        }
        fontSize = Math.round(this.config.radius / 16);
        var ticks = gaugeUtils.calculateTicks(serie);
        this.drawTicks(ticks);
        var pointerContainer = this.body.select(".pointerContainer");

        if (pointerContainer.empty()) {
            pointerContainer = this.body.append("svg:g").attr("class", "pointerContainer");
        }

        var midValue = (serie.min + serie.max) / 2;

        var pointerPath = this.buildPointerPath(midValue);

        var pointerLine = d3.svg.line().x(function(d) {
            return d.x;
        }).y(function(d) {
            return d.y;
        }).interpolate("basis");

        pointerContainer.selectAll("path").data([ pointerPath ]).enter().append("svg:path");
        pointerContainer.select("path").attr("d", pointerLine).attr("transform", function() {
            return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(225)";
        }).style("fill", "#dc3912").style("stroke", "#c63310").style("fill-opacity", 0.7);

        var pcOuterCircle = pointerContainer.select('.pc-outer-circle');
        if (pcOuterCircle.empty()) {
            pcOuterCircle = pointerContainer.append("svg:circle").attr("class", "pc-outer-circle");
        }
        pcOuterCircle.attr("cx", this.config.cx).attr("cy", this.config.cy).attr("r", 0.08 * this.config.radius).style(
                "fill", "#666666").style("stroke", "#666666").style("opacity", 1);
        var pcInnerCircle = pointerContainer.select('.pc-inner-circle');
        if (pcInnerCircle.empty()) {
            pcInnerCircle = pointerContainer.append("svg:circle").attr("class", "pc-inner-circle");
        }
        pcInnerCircle.attr("cx", this.config.cx).attr("cy", this.config.cy).attr("r", 0.06 * this.config.radius).style(
                "fill", "#000000").style("opacity", 1);

        fontSize = Math.round(this.config.radius / 10);
        pointerContainer.selectAll("text.gauge-value").data([ midValue ]).enter().append("svg:text").attr("class",
                "gauge-value");

        pointerContainer.select("text.gauge-value").attr("x", this.config.cx).attr("y",
                this.config.height / 2 + this.config.radius / 3 + fontSize).attr("dy", fontSize).attr("text-anchor",
                "middle").style("font-size", (fontSize * 2)-(fontSize / 3) + "px").style("fill", "#000").style("stroke-width", "0px");

        pointerContainer.selectAll("text.gauge-timestamp").data([ midValue ]).enter().append("svg:text").attr("class",
                "gauge-timestamp");
        pointerContainer.select("text.gauge-timestamp").attr("x", this.config.cx).attr("y",
                this.config.height / 2 + 2 * this.config.radius / 3 + fontSize / 2).attr("dy", fontSize / 30).attr(
                "text-anchor", "middle").style("font-size", fontSize + "px").style("fill", "#333").style(
                "stroke-width", "0px");
        this.rendered = true;
    };

    GaugeSerie.prototype.buildPointerPath = function(value) {
        var serie = this.serie;
        var self = this;
        var delta = serie.range / 13;

        function valueToPoint(value, factor) {
            var point = self.valueToPoint(value, factor);
            point.x -= self.config.cx;
            point.y -= self.config.cy;
            return point;
        }
        var head = valueToPoint(value, 0.85);
        var head1 = valueToPoint(value - delta, 0.12);
        var head2 = valueToPoint(value + delta, 0.12);

        var tailValue = value - (serie.range * (1 / (self.scaleAngle / 360)) / 2);
        var tail = valueToPoint(tailValue, 0.28);
        var tail1 = valueToPoint(tailValue - delta, 0.12);
        var tail2 = valueToPoint(tailValue + delta, 0.12);

        return [ head, head1, tail2, tail, tail1, head2, head ];
    };

    GaugeSerie.prototype.redraw = function(measurement, transitionDuration) {
        var format = d3.time.format("%x %X");
        var serie = this.serie;
        var self = this;
        if (!this.rendered) {
            return;
        }
        var pointerContainer = this.body.select(".pointerContainer");
        var value;

        if (measurement && typeof (measurement.timestamp) !== 'undefined') {
            var timestamp = this.config.dateFormatter(measurement.timestamp, 'yyyy-MM-dd HH:mm:ss');
            pointerContainer.selectAll("text.gauge-timestamp").text(timestamp);
            value = measurement.value;
            pointerContainer.selectAll("text.gauge-value").text(
                    this.config.valueFormatter(value, serie.precision, serie.unit, serie.scale));

        } else {
            pointerContainer.selectAll("text.gauge-value").text('?');
            pointerContainer.selectAll("text.gauge-timestamp").text('');
            return;
        }
        var pointer = pointerContainer.selectAll("path");
        pointer.transition().duration(transitionDuration || this.config.transitionDuration || 0).attrTween("transform",
                function() {
                    var pointerValue = value;
                    if (value > serie.max) {
                        pointerValue = serie.max + 0.02 * serie.range;
                    } else if (value < serie.min || isNaN(pointerValue)) {
                        pointerValue = serie.min - 0.02 * serie.range;
                    }
                    var targetRotation = (self.valueToDegrees(pointerValue) - 90);
                    var currentRotation = self._currentRotation || targetRotation;
                    self._currentRotation = targetRotation;

                    return function(step) {
                        if (!isFinite(step)) {
                            step = serie.min;
                        }
//                        var rotation = (currentRotation + (targetRotation - currentRotation) * step) || 180;
                        var rotation = (currentRotation + (targetRotation - currentRotation) * step);
                        return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(" + rotation + ")";
                    };
                });
    };
    return GaugeSerie;
}

module.service('GaugeSerie', GaugeSerieFactory);
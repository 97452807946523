var module = angular.module('login', [
    'ui.router',
    'meternet.templates',
    'meternet.inline-js',
    'i18n'
]);

module.config(function($stateProvider, $urlRouterProvider, $locationProvider) {
    $locationProvider.html5Mode({enabled: true, requireBase: false});

    $urlRouterProvider.otherwise('/login');

    $stateProvider.state('login', {
        url: '/login',
        views: {
            '@': {
                templateUrl: 'login.html',
                controller: 'loginController'
            }
        }
    });
});

module.controller('loginController', function($scope, LoginMessage, license){
    $scope.message = LoginMessage;
    $scope.license = license;
console.log(license.enabledModules)
    if(_.indexOf(license.enabledModules,"ETI_BASIC")>=0){
            $scope.mainLicense = "ETI";
    }else if(_.indexOf(license.enabledModules,"FINN_BASIC")>=0){
              $scope.mainLicense = "FINN";
    }else if(_.indexOf(license.enabledModules,"EFEN_BASIC")>=0){
             $scope.mainLicense = "EFEN";
    }else if(_.indexOf(license.enabledModules,"BSH_BASIC")>=0){
            $scope.mainLicense = "BSH";
    }else{
        $scope.mainLicense = "FIF";
    }
});

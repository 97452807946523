<div class="panel-body">
	<div x-expand-to-parent-bottom="" style="overflow-y: auto;">
		<form class="form-inline" style="margin-bottom: 10px;">
			<div class="form-group">
				<label class="form-group-label">{{'media.dateFrom' | i18n}}</label>
				<div class='input-group date'>
					<input type='text'
						   class="form-control"
						   x-ng-model="dateFrom"
						   x-ng-disabled="ui.loading"
						   x-datetimepicker=""/>
					<span class="input-group-addon">
						<span class="fa fa-calendar"></span>
					</span>
				</div>
			</div>
			<div class="form-group">
				<label class="form-group-label">{{'media.dateTo' | i18n}}</label>
				<div class='input-group date'>
					<input type='text'
						   class="form-control"
						   x-ng-model="dateTo"
						   x-ng-disabled="ui.loading"
						   x-datetimepicker=""/>
					<span class="input-group-addon">
						<span class="fa fa-calendar"></span>
					</span>
				</div>
			</div>
			<div class="form-group">
				<label class="form-group-label">&nbsp;</label>
				<button class="btn btn-primary"
						x-ng-disabled="ui.loading"
						x-ng-click="ui.loadReport()">{{'media.calc' | i18n}}</button>
			</div>
		</form>

		<table class="table table-bordered table-hover">
			<thead>
			<tr>
				<th>{{'camping.table.name'|i18n}}</th>
				<th>{{'camping.table.timestampStart'|i18n}}</th>
				<th>{{'camping.table.valueStart'|i18n}}</th>
				<th>{{'camping.table.timestamp'|i18n}}</th>
				<th>{{'camping.table.value'|i18n}}</th>
				<th>{{'camping.table.dailyUsage'|i18n}}</th>
				<th>{{'camping.table.valueDiff'|i18n}}</th>
				<th>{{'camping.table.price'|i18n}}</th>
				<th>{{'camping.table.cost'|i18n}}</th>
			</tr>
			</thead>
			<tbody>
			<tr x-ng-repeat="s in report.rows">
				<td>{{s.name}}</td>
				<td>{{s.timestampStart | date:"yyyy-MM-dd HH:mm:ss"}}</td>
				<td style="white-space: nowrap;">{{s.valueStart | unit:s.precision:s.unit:s.scale}}</td>
				<td>{{s.timestampEnd | date:"yyyy-MM-dd HH:mm:ss"}}</td>
				<td style="white-space: nowrap;">{{s.valueEnd | unit:s.precision:s.unit:s.scale}}</td>
				<td style="white-space: nowrap;" x-ng-class="{'danger': s.limit != null && s.dailyUsage > s.limit}">{{s.dailyUsage | unit:s.precision:s.unit:s.scale}}</td>
				<td style="white-space: nowrap;">{{s.usage | unit:s.precision:s.unit:s.scale}}</td>
				<td style="white-space: nowrap;">{{s.unitCost | unit:2:s.currency}}</td>
				<td style="white-space: nowrap;">{{s.cost | unit:2:s.currency}}</td>
			</tr>
			</tbody>
			<tfoot>
			<tr>
				<td colspan="8">&nbsp;</td>
				<td style="white-space: nowrap;">{{report.totalCost | unit:2:report.currency}}</td>
			</tr>
			</tfoot>
		</table>
	</div>
</div>

<form class="form-horizontal" name="licenseForm">
    <div class="form-group">
        <label class="btn btn-success" for="my-file-selector" x-ng-if="licenseValid">
            <input id="my-file-selector" type="file" valid-file upload="ui.uploadFile(file)" ng-model="file"
                   style="display:none;"/>
            <i class="fa fa-check" aria-hidden="true"></i>
            {{'config.license.serial' | i18n}}
        </label>
        <label class="btn btn-danger" for="my-file-selector" x-ng-if="!licenseValid">
            <input id="my-file-selector" type="file" valid-file upload="ui.uploadFile(file)" ng-model="file"
                   style="display:none;"/>
            <i class="fa fa-times" aria-hidden="true"></i>
            {{'config.license.serial' | i18n}}
        </label>
    </div>

    <table class="table table-hover">
        <tr x-ng-repeat="module in allModules | orderObjectBy : 'group'" x-ng-if="module.visible">
            <td class="col-sm-3" style="vertical-align: middle;">
                <b>{{'module.' + module.name + '.name' | i18n}}</b>
            </td>
            <td class="col-sm-8">
                <p><b>{{'module.' + module.name + '.desc' | i18n}}</b></p>
                <p data-ng-bind-html="'module.' + module.name + '.list' | i18n | htmlSafe"></p>
                <!--<b>{{'config.price' | i18n}}<span class="badge">{{module.cost}}</span></b>-->
                <div class="pull-right">
                    <span class="badge">
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        {{module.cost}}
                    </span>
                </div>
            </td>
            <td class="col-sm-3">
                <div ng-switch on="module.state">
                    <div ng-switch-when="2">
                        <button type="button" class="btn btn-primary" x-ng-disabled="true">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div ng-switch-when="1">
                        <button type="button" class="btn btn-danger" x-ng-click="ui.removeModule(module)">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div ng-switch-default>
                        <button type="button" class="btn btn-success" x-ng-click="ui.addModule(module)">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </td>
        </tr>
        <tr x-ng-if="ui.foregin">
            <td class="col-sm-3" style="vertical-align: middle;">
                <b>{{'module.foregin.name' | i18n}}</b>
            </td>
            <td class="col-sm-8">
                <p><b>{{'module.foregin.desc' | i18n}}</b></p>
                <p data-ng-bind-html="'module.foregin.list' | i18n | htmlSafe"></p>
                <!--<b>{{'config.price' | i18n}}<span class="badge">{{module.cost}}</span></b>-->
                <div class="pull-right">
                    <span class="badge">
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        {{module.cost}}
                    </span>
                </div>
            </td>
            <td class="col-sm-3">
                <button type="button" class="btn btn-primary" x-ng-disabled="true">
                    <i class="fa fa-check" aria-hidden="true"></i>
                </button>
            </td>
        </tr>


    </table>
    <div class="row">
        <div class="col-sm-12">
            <span>{{'license.usage.tokens' | i18n}}</span>
            <div class="progress">
                <div class="progress-bar"
                     role="progressbar"
                     style="min-width: 60px"
                     x-ng-style="{'width': (100 * usage.tokens.total / usage.tokens.max) + '%'}">
                    {{usage.tokens.total}}&nbsp;/&nbsp;{{usage.tokens.max}}
                </div>
                <div class="progress-bar progress-bar-success"
                     role="progressbar"
                     x-ng-style="{'width': (100 * usage.tokens.newTokens / usage.tokens.max) + '%'}">
                    {{usage.tokens.newTokens}}&nbsp;/&nbsp;{{usage.tokens.max}}
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="confirmLicenseModal" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title">{{'config.license.confirm.title' | i18n}}</h4>
                </div>
                <div class="modal-body">
                    <div x-ng-if="!licenseForm.licneseInput.$dirty && !licenseValid && usage.tokens.newTokens == 0">
                        {{'config.license.confirm.noChange' | i18n}}
                    </div>
                    <div x-ng-if="licenseForm.licneseInput.$dirty || licenseValid || usage.tokens.newTokens != 0">
                        <p>{{'config.license.confirm.msg' | i18n}}</p>
                        <p x-ng-if="licenseForm.licneseInput.$dirty || licenseValid">
                            {{'config.license.confirm.licenseChange' | i18n}}
                        </p>
                        <div x-ng-if="usage.tokens.newTokens != 0">
                            {{'config.license.confirm.newModules' | i18n}}
                            <ul x-ng-repeat="module in allModules | where : {state:1}">
                                <li><b>{{'module.' + module.name + '.name' | i18n}}</b></li>
                            </ul>
                            {{'config.license.confirm.newModulesCost1' | i18n}}
                            {{usage.tokens.newTokens}}
                            {{'config.license.confirm.newModulesCost2' | i18n}}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">{{'config.cancel' | i18n}}</button>
                    <button type="button" class="btn btn-primary" x-ng-click="ui.saveLicense()"
                            x-ng-disabled="!licenseForm.licenseInput.$dirty && !licenseValid && usage.tokens.newTokens == 0">
						{{'config.save' | i18n}}
                    </button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->


    <br/>
</form>

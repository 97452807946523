<div>
    <table class="table-condensed table table-striped width-max">
        <thead>
            <tr>
                <th rowspan="2" class="center-table" style="min-width: 50px;">{{ 'dashboard.name' | i18n }}</th>
                <th colspan = "2" class="center-table" style="border-right:2px solid #dddddd;">{{ 'config.read' | i18n }}</th>
                <th colspan = "2"class="center-table">{{ 'config.write' | i18n }}</th>
                <th rowspan="2" data-ng-if="calculateEffectivePermissions === true" class="center-table">{{ 'config.effectiveread' | i18n }}</th>
                <th rowspan="2" data-ng-if="calculateEffectivePermissions === true" class="center-table">{{ 'config.effectivewrite' | i18n }}</th>
            </tr>
            <tr>
                <th class="center-table">{{ 'config.allow' | i18n }}</th>
                <th class="center-table" style="border-right:2px solid #dddddd;">{{ 'config.deny' | i18n }}</th>
                <th class="center-table">{{ 'config.allow' | i18n }}</th>
                <th class="center-table">{{ 'config.deny' | i18n }}</th>
            </tr>
        </thead>

        <tbody>
            <tr ng-repeat="dashboard in dashboards">
                    <td>{{dashboard.title}}</td>
                    <td style="text-align: center">
                        <input type="checkbox" data-ng-checked="isChecked(dashboard.id, 'dashboardsAllowRead')" data-ng-click="onPropertyChange(dashboard.id, 'dashboardsAllowRead' , 'dashboardsDenyRead')" >
                    </td>
                    <td style="text-align: center; border-right:2px solid #dddddd; ">
                        <input type="checkbox" data-ng-checked="isChecked(dashboard.id, 'dashboardsDenyRead')"  data-ng-click="onPropertyChange(dashboard.id, 'dashboardsDenyRead' , 'dashboardsAllowRead')" >
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" data-ng-checked="isChecked(dashboard.id, 'dashboardsAllowWrite')" data-ng-click="onPropertyChange(dashboard.id, 'dashboardsAllowWrite', 'dashboardsDenyWrite')" >
                    </td>
                    <td style="text-align: center">
                        <input type="checkbox" data-ng-checked="isChecked(dashboard.id, 'dashboardsDenyWrite')"  data-ng-click="onPropertyChange(dashboard.id, 'dashboardsDenyWrite' , 'dashboardsAllowWrite')" >
                    </td>
                    <td style="text-align: center" data-ng-if="calculateEffectivePermissions === true">
                        <input type="checkbox" data-ng-checked="isChecked(dashboard.id, 'effectiveRead')" disabled>
                    </td>
                    <td style="text-align: center" data-ng-if="calculateEffectivePermissions === true">
                        <input type="checkbox" data-ng-checked="isChecked(dashboard.id, 'effectiveWrite')" disabled>
                    </td>
            </tr>
            <tr x-ng-repeat="other in ['readout', 'report', 'fileManager', 'status']">
                <td>{{'menu.' + other | i18n}}</td>
                <td style="text-align: center">
                    <input type="checkbox" data-ng-checked="isChecked(other, 'otherAllowRead')" data-ng-click="onPropertyChange(other, 'otherAllowRead' , 'otherDenyRead')" >
                </td>
                <td style="text-align: center; border-right:2px solid #dddddd; ">
                    <input type="checkbox" data-ng-checked="isChecked(other, 'otherDenyRead')"  data-ng-click="onPropertyChange(other, 'otherDenyRead' , 'otherAllowRead')" >
                </td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center" data-ng-if="calculateEffectivePermissions === true">
                    <input type="checkbox" data-ng-checked="isChecked(other, 'otherAllowRead')" disabled>
                </td>
                <td style="text-align: center" data-ng-if="calculateEffectivePermissions === true">
                    <input type="checkbox" data-ng-checked="isChecked(other, 'otherDenyRead')" disabled>
                </td>

            </tr>
        </tbody>

    </table>
</div>

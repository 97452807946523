/*global tools */
var module = angular.module('meternet.status.directives', [
    'meternet.services',
    'meternet.event.directives'
]);

function StatusDisplayCtrl($scope, statusService, dataService, contextPath, configService, licenseService) {

    configService.get().then(function(config) {
        $scope.config = config;
    });

    $scope.license = licenseService.getLicense();

    $scope.status = {
        clear : true
    };

    $scope.$on('statusUpdate', function($event, data) {

        tools.copy(data, $scope.status);
        $scope.status.clear = false;
    });

    $scope.ui = {
        start : function() {
            statusService.start().then(function(status) {
                tools.copy(status, $scope.status);
                $scope.status.clear = false;
            });
        },
        stop : function() {
            statusService.stop().then(function(status) {
                tools.copy(status, $scope.status);
                $scope.status.clear = false;
            });
        },
        restart : function() {
            var self = this;
            statusService.stop().then(function(status) {
                tools.copy(status, $scope.status);
                $scope.status.clear = false;
                self.start();
            });
        }
    };
}

module.directive('statusDisplay',  function(contextPath) {
    return {
        scope : {},
        controller : StatusDisplayCtrl,
        templateUrl : 'status/status-display.html',
        link : function($scope, $element) {

        }
    };
});

<div class="row">
	<div class="col-md-4 col-md-offset-4">
		<div class="panel panel-primary about-panel">
			<div class="panel-body">
				<div class="text-center" style="margin-top: 10px; margin-bottom: 10px;">
					<img src="/resources/css/img/logo.png" class="login-img"  x-ng-if="mainLicense=='FIF'"/>
					<img src="/resources/css/img/logo.png" class="login-img"  x-ng-if="mainLicense=='ETI'"/>
					<img src="/resources/css/img/logo.png" class="login-img"  x-ng-if="mainLicense=='EFEN'"/>
					<img src="/resources/css/img/logo.png" class="login-img"  x-ng-if="mainLicense=='BSH'"/>
					<img src="/resources/css/img/logo_finn.png" class="login-img"  x-ng-if="mainLicense=='FINN'"/>
				</div>
				<hr />
				<div style="margin-bottom: 10px;">{{version}}</div>
			</div>
			<div class="panel-footer">
				<div class="text-center" x-ng-if="mainLicense=='FIF'">
					<img name="logoMini" class="login-imgff logoMini"></img>
					<!--<img src="/resources/css/img/ff.png" class="login-imgff" />-->
				</div>
				<div class="text-center" x-ng-if="mainLicense=='FINN'">
					<img name="logoMini" class="login-imgff logoMini_finn"></img>
				</div>
				<div class="text-center" x-ng-if="mainLicense=='ETI'">
					<img name="logoMini" class="login-imgff logoMini_eti"></img>
					<!--<img src="/resources/css/img/ff.png" class="login-imgff" />-->
				</div>
				<div class="text-center" x-ng-if="mainLicense=='EFEN'">
					<img name="logoMini" class="login-imgff logoMini_efan"></img>
					<!--<img src="/resources/css/img/ff.png" class="login-imgff" />-->
				</div>
				<div class="text-center" x-ng-if="mainLicense=='BSH'">
					<img name="logoMini" class="login-imgff logoMini_bsh"></img>
					<!--<img src="/resources/css/img/ff.png" class="login-imgff" />-->
				</div>
				<div class="text-center" x-ng-if="mainLicense=='FINN'">
					<img name="logoMini" class="login-imgff logoMini_finn"></img>
					<!--<img src="/resources/css/img/ff.png" class="login-imgff" />-->
				</div>
			</div>
		</div>
	</div>
</div>
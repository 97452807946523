<div class="file-browser" style="height: 100%;">
    <div class="breadcumbs">
        <span style="margin-left: 5px; margin-right: 5px;">{{ 'file.browser.current.directory' | i18n }}</span>
        <span role="button" x-ng-repeat="pathElement in currentDirectory.path track by $index"
            x-ng-click="changeDirectory($index)" x-ng-class="{disabled: $last}">
            <i class="fa fa-folder-open"></i> {{ renderPathElement(pathElement) }}
        </span>
    </div>

    <div style="overflow:auto; height: 100%;">
    <table class="table file-browser">
        <thead>
            <tr>
                <th style="width: 20px;"></th>
                <th>{{ 'file.browser.file.name' | i18n }}</th>
                <th>{{ 'file.browser.file.date' | i18n }}</th>
                <th x-ng-if="!choosedDirectory">{{ 'file.browser.file.size' | i18n }}</th>
                <th style="text-align: right">



                    <button type="button" class="btn btn-success btn-xs" data-toggle="modal" data-target=".add-file-modal">
                        <i class="fa fa-plus fa-lg"></i> {{ 'file.browser.addFile' | i18n }}
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" data-toggle="modal" data-target=".delete-selected-modal" x-ng-disabled="!enableDeleteAll()">
                        <i class="fa fa-trash-o fa-lg"></i> {{ 'file.browser.delete.selected' | i18n }}
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr x-ng-repeat="file in currentDirectory.files track by $index">
                <td><input x-ng-if="!readOnly" type="checkbox" x-ng-model="selectedFiles[$index]" /></td>
                <td role="button" >
                    <a x-ng-if="!file.isDirectory" target="_self" href="/files/get?path={{ absoluteFilePath(file) }}">
                        <span class="fa fa-file-o file-icon"></span>
                        {{ file.name }}
                    </a>

                    <a x-ng-if="file.isDirectory" x-ng-click="enterDirectory(file)">
                        <span class="fa fa-folder-o file-icon"></span>
                        {{ file.name }}
                    </a>
                </td>
                <td>{{ file.lastModificationDate | date: 'yyyy-MM-dd HH:mm:ss' }}</td>
                <td x-ng-if="!choosedDirectory">{{ file.size }}</td>
                <td style="text-align: right">
                    <button x-ng-if="!readOnly" type="button" class="btn btn-danger btn-xs" x-ng-click="selectFileToDelete(file)" data-toggle="modal" data-target=".delete-file-modal">
                        <i class="fa fa-trash-o fa-lg"></i>
                    </button>
                </td>
            </tr>

            <tr x-ng-if="currentDirectory.files.length == 0">
                <td colspan="5" style="text-align: center;">{{ 'file.browser.no.elements' | i18n }}</td>
            </tr>
        </tbody>
    </table>
    </div>
</div>

<div class="modal fade delete-selected-modal" tabindex="-1" role="dialog" aria-labelledby="deletSelectedModal">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{ 'file.browser.title.deleteSelected' | i18n }}</h2>
            </div>
            <div class="modal-body">
                <p>{{ 'file.browser.content.deleteSelected' | i18n }}</p>
                <button x-ng-if="!readOnly" class="btn btn-danger" x-ng-click="deleteSelectedFiles()"  data-dismiss="modal">
                    <i class="fa fa-trash-o fa-lg"></i> {{ 'file.browser.delete' | i18n }}
                </button>
                <button type="button" class="btn btn-default" data-dismiss="modal">{{ 'file.browser.cancel' | i18n }}</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade delete-file-modal" tabindex="-1" role="dialog" aria-labelledby="deletFiledModal">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{ 'file.browser.title.deleteFile' | i18n }}</h2>
            </div>
            <div class="modal-body">
                <p>{{ 'file.browser.content.deleteFile' | i18n }} {{fileToDelete.name}}</p>
                <button x-ng-if="!readOnly" class="btn btn-danger"  x-ng-click="deleteFile(fileToDelete)"  data-dismiss="modal">
                    {{ 'file.browser.delete' | i18n }}
                </button>
                <button type="button" class="btn btn-default" data-dismiss="modal">{{ 'file.browser.cancel' | i18n }}</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade add-file-modal" tabindex="-1" role="dialog" aria-labelledby="addFiledModal">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{ 'file.browser.tile.addFile' | i18n }}</h2>
            </div>
            <div class="modal-body">
                <div x-flow-init="{target: '/storage', singleFile: true }" x-flow-files-submitted="$flow.files[0].name=$flow.files[0].name; $flow.upload()"
                     x-flow-file-success="setBackground($flow.files[0])" >
                    <div class="file-drop" x-flow-drop="" x-flow-btn=""
                         x-ng-show="!$flow.isUploading()" x-flow-drag-enter="cssClass = 'active'"
                         x-flow-drag-leave="cssClass = null"
                         x-ng-class="cssClass">
                        <span class="file-drop-placeholder" x-ng-show="backgroundUrl==null">
                            {{'file.browser.placeholder' | i18n}}
                        </span>
                        <span class="file-drop-preview" x-ng-show="backgroundUrl!=null">
                            {{'file.browser.fileUploaded' | i18n}}{{backgroundUrl}}<br/>
                        </span>
                    </div>
                    <div x-flow-transfers="" x-ng-if="$flow.isUploading()">
                        <div x-ng-repeat="file in transfers">
                            <div>{{file.name}}</div>
                            <div x-uib-progressbar="" x-value="file.progress()" x-max="1">{{file.size * file.progress() |
                                fileSize}}/{{file.size | fileSize}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


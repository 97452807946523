<div class="row">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <ul class="list-group">
            <li class="list-group-item">
                <form class="form form-inline" name="seriesConfigForm-{{$index}}">
                    <div class="form-group">
                        <label class="form-group-label" x-label-help="config.componentType.dashboard"></label>
                        <select class="form-control" style="width: 130px;" x-ng-model="config.dashboard" name="widgetsConfigSeriesInputs-{{$index}}"
                                x-ng-options="d.target as d.name for d in ui.dashboards" x-required="">
                        </select>
                    </div>
                </form>
            </li>

<!--            <li class="list-group-item" x-ng-repeat="serie in config.series">-->
<!--                <form class="form form-inline" name="seriesConfigForm-{{$index}}">-->
<!--                    <div class="form-group">-->
<!--                      <label class="form-group-label" x-label-help="config.componentType.measurementInput"></label>-->
<!--                      <select class="form-control" style="width: 130px;" x-ng-model="serie.deviceId" name="widgetsConfigSeriesInputs-{{$index}}"-->
<!--                              x-ng-options="input.id as input.label for input in ui.measurementInputs" x-required="">-->
<!--                      </select>-->
<!--                    </div>-->
<!--                    <div class="form-group">-->
<!--                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>-->
<!--                        <select class="form-control" style="width: 130px;" x-ng-model="serie.id" name="widgetsConfigSeriesDevice-{{$index}}"-->
<!--                                x-ng-options="report.id as report.label for report in ui.getDevice($index)"-->
<!--                                x-required="">-->
<!--                        </select>-->
<!--                    </div>-->
<!--                    <div class="form-group">-->
<!--                        <label class="form-group-label">&nbsp;</label>-->
<!--                        <button class="btn btn-danger" x-ng-click="ui.removeSerie($index);"-->
<!--                                data-ng-class="ui.isSingleSerie()"-->
<!--                                title="{{'ui.delete' | i18n}}">-->
<!--                            <i class="fa fa-trash-o fa-lg"></i>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </form>-->
<!--            </li>-->
<!--            <li class="list-group-item">-->
<!--                <div class="pull-right">-->
<!--                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">-->
<!--                        {{'ui.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>-->
<!--                    </button>-->
<!--                </div>-->
<!--                <div class="clearfix"></div>-->
<!--            </li>-->
        </ul>
    </div>
</div>

/*
* fullcalendar angular directive
* */

angular.module('ui.calendar', [])
    .controller('uiCalendarCtrl', ['$scope', '$locale',
        function ($scope, $locale) {
        }])
    .directive('uiCalendar', [
        function () {
            return {
                restrict: 'A',
                scope: {
                    eventSources: '=ngModel',
                    config: '=',
                    calendarObj: '='
                },
                controller: 'uiCalendarCtrl',
                link: function (scope, elm, attrs, controller) {
                    var calendar;

                    function wrapWithScopeApply(func) {
                        return function () {
                            var args = arguments;
                            var that = this;

                            if (scope.$root.$$phase) {
                                return func.apply(that, args)
                            } else {
                                scope.$apply(function () {
                                    return func.apply(that, args)
                                })
                            }
                        }
                    }

                    function initCalendar() {
                        var cfg = Object.assign({}, scope.config, {eventSources: scope.eventSources})
                        angular.forEach(cfg, function (value, key) {
                            if (typeof value === 'function') {
                                cfg[key] = wrapWithScopeApply(cfg[key]);
                            }
                        });
                        calendar = new FullCalendar.Calendar(elm.get(0), cfg);
                        calendar.render()
                        scope.calendarObj = calendar
                    }

                    function destroyCalendar() {
                        calendar.destroy()
                        scope.calendarObj = null
                    }

                    initCalendar();

                    scope.$on('$destroy', function () {
                        destroyCalendar()
                    });

                    scope.$watch('eventSources', function (newVal) {
                        if (newVal) {
                            calendar.removeAllEventSources()
                            newVal.forEach(function (source) {
                                calendar.addEventSource(source)
                            })
                        }
                        calendar.render()
                    }, true)
                }
            }
        }])
<div class="lighting-settings">
  <div class="row flex-row">
    <div class="dual-list list-left col-xs-8">
      <div x-ng-if="editable" class="btn-row">
        <button type="button" class="btn btn-primary" x-ng-disabled="selectedDevices.length < 1 || !data.selectedLightingSetting" x-ng-click="linkLightingSetting()">
          <i class="fa fa-arrows-h"></i>
        </button>
        <button type="button" class="btn btn-primary" x-ng-disabled="selectedDevices.length < 1 || !data.selectedLightingSetting" x-ng-click="unlinkLightingSetting()">
          <i class="fa fa-chain-broken"></i>
        </button>
        <button type="button" class="btn btn-primary" x-ng-click="changeView()">
          <i ng-show="menuView==0" class="fa fa-bar-chart"></i>
          <i ng-show="menuView==1" class="fa fa-globe"></i>
        </button>
      </div>
      <div class="table-responsive">
        <div class="row markers-search">
          <div class="col-sm-3 col-xs-6">
            <div class="form-group">
              <label class="">{{::'report.table.search' | i18n}}</label>
              <div class="input-group">
                <input x-ng-model="searchDevice" type="text" class="form-control" placeholder="{{'report.table.search' | i18n}}"/>
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-3 col-xs-6 markers-checkbox">
            <div class="checkbox">
              <label>
                <input type="checkbox" ng-model="onlyMapMarkers"> {{::'markers.table.show.checkbox' | i18n}}
              </label>
            </div>
          </div>
        </div>
        <div class="pre-scrollable">
          <table class="table table-condensed table-markers table-hover" style="position: relative;">
            <thead>
            <tr>
              <th>
                <div class="checkbox" style="margin: 0;">
                  <label><input type="checkbox" data-ng-checked="ui.allSelected" data-ng-click="ui.selectAll()"></label>
                </div>
              </th>
              <th ng-click='sortColumn("device.label", "devicesColumn", "devicesReverse")'><a>{{'config.label' | i18n}}<i class="{{sortIcon('device.label', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
              <th ng-click='sortColumn("device.desc", "devicesColumn", "devicesReverse")'><a>{{'config.desc1' | i18n}}<i class="{{sortIcon('device.desc', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
              <th ng-click='sortColumn("device.desc2", "devicesColumn", "devicesReverse")'><a>{{'config.desc2' | i18n}}<i class="{{sortIcon('device.desc2', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
              <th ng-click='sortColumn("device.desc3", "devicesColumn", "devicesReverse")'><a>{{'config.desc3' | i18n}}<i class="{{sortIcon('device.desc3', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
              <th><span>{{'config.device.geolocation' | i18n}}</span></th>
              <th><span>{{'config.device.status' | i18n}}</span></th>
              <th><span>{{'config.options' | i18n}}</span></th>
            </tr>
            </thead>
            <tbody>
            <tr data-ng-repeat="mark in markersWithinBounds | orderBy:devicesColumn:devicesReverse" ng-attr-id="{{mark.device.id}}" ng-mouseover="ui.overMarker(mark)" ng-mouseleave="ui.leaveMarker(mark)" ng-click="ui.checkboxClick(mark)">
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" data-ng-checked="ui.checked(mark.device)"></label>
                </div>
              </td>
              <td>{{mark.device.label}}</td>
              <td>{{mark.device.desc}}</td>
              <td>{{mark.device.desc2}}</td>
              <td>{{mark.device.desc3}}</td>
              <td>{{mark.lat + ", " + mark.lng}}</td>
              <td>{{ui.getCurrent(mark.device)}}</td>
              <td>
                <div x-ng-if="mark.device.lightingSettingId" class="lightingSetting-label">
                  <span class="label label-default device-label">{{ui.getLightSettingName(mark.device.lightingSettingId)}}</span>
                  <a href="" title="{{::'mbsat.menu.calendar' | i18n}}" x-ng-click="editLightingSetting(mark.device.lightingSettingId)" style="margin-right: 10px;">
                    <i class="fa fa-calendar fa-lg"></i>
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="dual-list list-right col-xs-4">
      <div x-ng-if="editable"  class="btn-row">
        <button type="button" class="btn btn-primary" x-ng-disabled="selectedDevices.length < 1 || !data.selectedLightingSetting" x-ng-click="linkLightingSetting()">
          <i class="fa fa-arrows-h"></i>
        </button>
        <button type="button" class="btn btn-success" x-ng-click="addLightingSetting()">
          <i class="fa fa-plus"></i>
        </button>
        <button type="button" class="btn btn-primary" x-ng-disabled="!data.selectedLightingSetting" x-ng-click="copyLightingSetting()">
          <i class="fa fa-clone"></i>
        </button>
        <button type="button" class="btn btn-danger" x-ng-disabled="!data.selectedLightingSetting" x-ng-click="removeLightingSetting()">
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <div class="table-responsive">
        <div class="row markers-search">
          <div class="col-sm-6 col-xs-8">
            <div class="form-group">
              <label class="">{{::'report.table.search' | i18n}}</label>
              <div class="input-group">
                <input x-ng-model="search.name" type="text" class="form-control" placeholder="{{'report.table.search' | i18n}}"/>
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="pre-scrollable">
          <table class="table table-condensed table-markers table-hover" style="position: relative;">
            <thead>
            <tr>
              <th style="height: 30px;"></th>
              <th ng-click='sortColumn("name", "lightingSettingsColumn", "lightingSettingsReverse")'><a>{{'config.name' | i18n}}<i class="{{sortIcon('name', 'lightingSettingsColumn', 'lightingSettingsReverse')}}"></i></a></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr data-ng-repeat="set in lightingSettings|orderBy:lightingSettingsColumn:lightingSettingsReverse|filter:search track by $index" ng-click="data.selectedLightingSetting=set.id">
              <td>
                <div class="radio">
                  <label>
                    <input type="radio" name="optionsRadios" ng-model="data.selectedLightingSetting" ng-value="set.id">
                  </label>
                </div>
              </td>
              <td>{{set.name}}</td>
              <td>
                <a href="" title="{{::'mbsat.menu.calendar' | i18n}}" x-ng-click="editLightingSetting(set.id)" style="margin-right: 10px;">
                  <i class="fa fa-calendar fa-lg"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="lightingSettingAdd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">{{::'dashboard.widgets.config.series.lightingSetting' | i18n}}</h2>
        </div>
        <div class="modal-body">
          <form role="form form-inline">
            <div class="form-group" style="width: 100%;     padding-bottom: 10px;" >
              <label class="form-group-label">{{::'mbsat.modal.settings.name' | i18n}}:</label>
              <input type="text" class="form-control" data-ng-model="ui.newLightingSetting.name" style="width: 100%"/>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" x-ng-click="saveNewLightingSetting()">
            <i class="fa fa-save"></i>{{'btn.save' | i18n}}
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" x-ng-click="resetLightingSetting()">
            <i class="fa fa-long-arrow-left"></i>{{'ui.cancel' | i18n}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

var module = angular.module('meternet.fileBrowser.services', []);

function FileBrowserService($q, $http, contextPath) {
    // API
    return {
        list: function(rootPath) {
            var deferred = $q.defer();
            $http.post(contextPath + '/files/list', '"' + rootPath + '"').success(function(data) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        delete: function(files) {
            var deferred = $q.defer();
            $http.post(contextPath + '/files/delete', files).success(function() {
                deferred.resolve();
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        }
    }
}

module.service('fileBrowserService', FileBrowserService);
var module = angular.module('meternet.dashboard.controllers.mbsat2GaugeWidget', ['adf.provider']);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/mbsat2-gauge-widget.html',
        title : messages['dashboard.widgets.mbsat2Gauge.title'],
        description : messages['dashboard.widgets.mbsat2Gauge.desc'],
        controller : 'mbsat2WidgetCtrl',
        screenshot: false,
        config : {
            type : "mbsat",
        },
        edit : {
            controller : 'mbsat2WidgetEditCtrl',
            templateUrl: 'dashboard/mbsat2-gauge-edit.html'
        }
    };
    dashboardProvider.widget('mbsat2', widget);
});


function Mbsat2EditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales, TimeDerivativeScales, ColorPickerOpts, dashboardService ) {
    $scope.ui = {
        addSeries : function() {
            $scope.config.series.push({});
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        getDevice: function (idx) {
            var devices = []
            var id = $scope.config.series[idx] ? $scope.config.series[idx].deviceId : null;
            if (id) {
                devices = _.findWhere($scope.ui.measurementInputs, {id: id}).devices
                _.forEach(devices, function(device){
                    if (!device.label){
                        device.label = device.name;
                    }
                });
            }
            return devices
        }
    };
    $scope.config = config;
    $scope.selectedInput = null;
    configService.get().then(function(meternetConfig){
//        var inputs = _.filter(meternetConfig.engine.measurementInputs, function (m) {
//            return m.type ==='mbsat';
//        });
        $scope.ui.measurementInputs = meternetConfig.engine.measurementInputs;
        _.forEach($scope.ui.measurementInputs, function(input){
            if (!input.label){
                input.label = input.name;
            }
        });
    });
    dashboardService.getDashboards().then(function(d){
        $scope.ui.dashboards = d;
    });
}


function Mbsat2Ctrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents, seriesService, $q) {
    var dashboardTag = $scope.$parent.model.title;
    $scope.options = {
        series : []
    };
    var options = $scope.options;
    options.name = dashboardTag;
    // placing this at widget config causes performance issues due to deep watching on entire config
    options.series = [];
    configService.get().then(function(meternetConfig){
        _.forEach(meternetConfig.engine.measurementInputs, function(input){
            _.forEach(input.devices, function(device){
                if(device.tags.indexOf(dashboardTag)>-1){
                    var serie = {
                        deviceId: device.id,
                    };
                    options.series.push(serie);
                }
            });
        });
    });
}

module.controller('mbsat2WidgetEditCtrl', Mbsat2EditCtrl);
module.controller('mbsat2WidgetCtrl', Mbsat2Ctrl);

var module = angular.module('meternet.scores.controllers', []);


module.controller('addParameterScoreCtrl', function($uibModalInstance, configService, model, $scope, parameters, scoresService, Errors) {
    $scope.parameters = parameters;
    $scope.model = model;


    configService.get().then(function(config) {
        var inputs = config.engine.measurementInputs;
        var result = [];
        for (var i = 0; i < inputs.length; ++i) {
            var devices = inputs[i].devices;
            for (var j = 0; j < devices.length; ++j) {
                result.push(devices[j]);
            }
        }
        $scope.devices = result;
        if ($scope.model.id) {
            $scope.ui = {
                modalTitle: 'parameterScore.editParam',
                mode: 'fa-gears',
            };
            $scope.selectedDevice = _.find($scope.devices, function(device) {
                return _.find(device.params, function(param) {
                    return param.id === $scope.model.paramId;
                })
            })
        }
    });


    $scope.intervals = [15, 30, 45, 60, 75, 90];

    $scope.addRange = function() {
        $scope.model.ranges.push({
            min: null,
            max:null,
            factory: null,
            offset:null
        });
    };

    $scope.ui = {
        modalTitle: 'parameterScore.addParam',
        mode: 'fa-plus',
        saving: false,
        getSaveClass: function() {
            if ($scope.ui.saving) {
                return "fa fa-save fa-spin";
            }
            return "fa fa-save";
        }
    };

    $scope.removeRange = function(index) {
        $scope.model.ranges.splice(index, 1);
    };

    var resultHandler = function(result) {
        if (result.status === 200) {
            $uibModalInstance.close($scope.model);
        } else {
            $scope.ui.saving = false;
            model.errors = new Errors(result.data);
        }
    };

    $scope.ok = function() {
        $scope.ui.saving = true;
        if ($scope.model.id) {
            scoresService.update($scope.model).then(function(result){
                resultHandler(result);
            });
        } else {
            scoresService.add($scope.model).then(function(result){
                resultHandler(result);
            });
        }

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
});


<fieldset>
    <div class="form-group">
        <div class="col-sm-offset-2 col-sm-9">
            <div class="checkbox">
                <label> <input type="checkbox" x-ng-model="user.enabled"/>{{'config.user.enabled' | i18n}}
                </label>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="col-sm-offset-2 col-sm-9">
            <div class="checkbox">
                <label> <input type="checkbox" x-ng-model="user.admin"/>{{'config.user.role.admin' | i18n}}
                </label>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-2" style="padding-right:20px;">{{'config.group.title' | i18n}}</label>
        <div class="col-sm-9">
            <select class="form-control"
                    x-ng-model="user.groups"
                    x-ng-options="o.id as o.name for o in groups"
                    x-field-errors=""
                    x-multi-select=""
                    multiple="multiple"></select>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-2" style="padding-right:20px;">{{'config.group.roles' | i18n}}</label>
        <div class="col-sm-9" style="margin-top:3px;">
            <permission-control permissions="user.permissions" calculate-effective-permissions="true" user-id="user.id"></permission-control>
        </div>
    </div>


</fieldset>

/* global tools */
var module = angular.module('meternet.config.services', []);

module.service('configService', function ($q, $http, $log, $filter, $rootScope, contextPath, dataService, meternetConfig) {
    var config = null;
    var configCopy = null;

    dataService.subscribe("/topic/configuration", function (data) {
        setConfig(data);
    });

    setConfig(meternetConfig);

    function getConfig() {
        return config;
    }

    function setConfig(newConfig) {
        config = newConfig;
        configCopy = JSON.parse(JSON.stringify(newConfig));
        $rootScope.$emit('changeMenuLayoutEnergyReport');
    }

    function resetConfig() {
        setConfig(configCopy);
    }

    /*function getConfigPatch(newConfig) {
        return jsonpatch.compare(configCopy, newConfig);
    }

    function patchData(data, config) {
        if (data && data.patch) {
            data.config = jsonpatch.applyPatch(config, data.patch, true, false, true).newDocument;
            data.patch = null;
        }
    }*/

    // API
    return {
        get: function (fetch) {
            var deferred = $q.defer();
            if (fetch) {
                var url = contextPath + '/data/config.json';
                $http({
                    method: 'GET',
                    url: url
                }).success(function(data, status, headers) {
                    setConfig(data);
                    deferred.resolve(getConfig());
                }).error(function() {
                    deferred.reject();
                });
            } else {
                deferred.resolve(getConfig());
            }
            return deferred.promise;
        },
        isDirty: function () {
            return !angular.equals(config, configCopy);
        },
        reset: function () {
            resetConfig();
            return this.get();
        },
        check: function (config) {
            var deferred = $q.defer();
            var url = contextPath + '/data/config.json?validate';
            //var patch = getConfigPatch(config);
            $http({
                method: 'POST',
                url: url,
                data: config
            }).success(function(data, status, headers) {
                // patchData(data, config);
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        save: function (config) {
            var deferred = $q.defer();
            var url = contextPath + '/data/config.json';
            // var patch = getConfigPatch(JSON.parse(angular.toJson(config)));
            //var patch = getConfigPatch(config);
            $http({
                method: 'POST',
                url: url,
                data: config
            }).success(function(data, status, headers) {
                //patchData(data, config);
                setConfig(data.config);
                $rootScope.$broadcast('meternetConfigUpdate', getConfig());
                deferred.resolve(data);
//                $rootScope.$broadcast('config.saveSuccess', data);
            }).error(function(data, status, headers) {
                //patchData(data, config);
                deferred.reject(data);
            });
            return deferred.promise;
        },
        restore: function (_config) {
            var deferred = $q.defer();
            var url = contextPath + '/data/meternet.backup';
            $http({
                method : 'PUT',
                url : url,
                data: _config
            }).success(function(data, status, headers) {
                config = data.config;
                deferred.resolve(data);
                $rootScope.$broadcast('config.saveSuccess', data);
            }).error(function(data, status, headers) {
                $rootScope.$broadcast('config.saveError', data);
                deferred.reject(data);
            });
            return deferred.promise;
        },
        getDevice: function (model, input) {
            var deferred = $q.defer();
            if (fetch) {
                var url = contextPath + '/getDeviceConfig/';
                $http({
                    method : 'GET',
                    url : url,
                    params : {
                        model : model.toUpperCase(),
                        input : input
                    }
                }).success(function(data, status, headers) {
                    config = data;
                    deferred.resolve(config);
                }).error(function() {
                    deferred.reject();
                });
            } else {
                deferred.resolve(config);
            }
            return deferred.promise;
        },
        detectMbsatDevices: function (inputId) {
            var deferred = $q.defer();
                var url = contextPath + '/mbsat/' + inputId +  '/detect';
                $http({
                    method: 'GET',
                    url: url
                }).success(function(data) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
            return deferred.promise;
        }
    };
});

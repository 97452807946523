/*global tools */
var module = angular.module('meternet.user.directives', [
    'meternet.services',
    'meternet.user.controllers'
]);

module.directive('user', [ 'userService',
    function (userService) {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
            },
            controller: function ($scope) {
                $scope.user = userService.getUser();
            }
        };
    }
]);
	
module.directive('logout', [ 'userService', 'contextPath',
    function (userService, contextPath) {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
            },
            templateUrl: 'user/logout.html',
            controller: function ($scope) {
                $scope.logoutUrl = contextPath + '/logout';
            }
        };
    }
]);

module.directive('permissionControl', ['configService', 'userService', function(configService, userService){
    return {
        replace: true,
        templateUrl: 'user/permission-control.html',
        scope: {
            permissions: '=',
            calculateEffectivePermissions: '=',
            userId:'='
        },
        link: function($scope) {
            configService.get().then(function(result){
                $scope.dashboards = result.gui.dashboards;
            });
            if (!$scope.permissions) {
                $scope.permissions = {};
            }

            userService.calculateEffectivePermissions({userId: $scope.userId, permissions: $scope.permissions}).then(function (result) {
                    $scope.permissions.effectiveRead = result.data.dashboardsAllowRead;
                    $scope.permissions.effectiveWrite = result.data.dashboardsAllowWrite;
            });

            $scope.onPropertyChange = function(dashboardId, arrayName, counterArray) { //counter array means opposite permission to be unchecked
                if (!(arrayName in $scope.permissions)) {
                    $scope.permissions[arrayName] = [];
                }
                var index = $scope.permissions[arrayName].indexOf(dashboardId);

                if (index > -1) {
                    $scope.permissions[arrayName].splice(index, 1);
                } else {
                    $scope.permissions[arrayName].push(dashboardId);
                    if ($scope.permissions[counterArray].indexOf(dashboardId) > -1) {
                        $scope.permissions[counterArray].splice($scope.permissions[counterArray].indexOf(dashboardId), 1);
                    }
                }
                $scope.getEffectivePermissions();
            };

            $scope.getEffectivePermissions = function(){
                if ($scope.calculateEffectivePermissions && $scope.userId) {
                    var dto = {
                        userId: $scope.userId,
                        permissions: $scope.permissions
                    };
                    userService.calculateEffectivePermissions(dto).then(function (result) {
                        $scope.permissions.effectiveRead = result.data.dashboardsAllowRead;
                        $scope.permissions.effectiveWrite = result.data.dashboardsAllowWrite;
                    });
                }

            }

            $scope.isChecked = function(dashboardId, arrayName) {
                if (!$scope.permissions){
                    return false;
                }
                if ($scope.permissions[arrayName] && $scope.permissions[arrayName].indexOf(dashboardId) > -1) {
                    return true;
                } else {
                    return false;
                }
            };
//TODO nie zmieniaja sie uprawnienia po zmienie grupy
//            $scope.$watch('$scope.permissions', function(newValue, oldValue) {
//                console.log(newValue);
//                $scope.getEffectivePermissions();
//            });

        }
    };
}]);

var module = angular.module('meternet.dashboard.controllers.heatmapWidget', [
    'adf.provider',
    'i18n',
    'meternet.dashboard.constants'
]);

module.config(function(dashboardProvider, contextPath, messages) {
    // template object for github widgets
    var widget = {
        templateUrl: 'dashboard/heatmap-widget.html',
        title : messages['dashboard.widgets.heatmap.title'],
        description : messages['dashboard.widgets.heatmap.desc'],
        controller : 'heatmapWidgetCtrl',
        screenshot: true,
        resolve : {

        },
        config : {
            series : [ {
                deviceId : null,
                paramId : null,
                minColor : "#00F",
                maxColor : "#F00",
                displayKind: "box",
                positive: "on",
                negative: "off",
                derivative : 0,
                tempImpact: true,
                dashboardId : null,
                fontSize : 12,
                zones : [ {
                    label:"",
                    from : -9.9999998E17,
                    to : 9.9999998E17,
                    color : "#ffffff",
                    fontColor : "#000000"
                } ]
            }],
            gridSizeX: 50,
            gridSizeY: 50,
            redrawDelay: 1000,
            type : 'heatmap'
        },
        edit : {
            controller : 'HeatmapWidgetEditCtrl',
            templateUrl: 'dashboard/heatmap-widget-edit.html',
            resolve : {
                maxSeries : [ 'licenseService', function(licenseService) {
                    var license = licenseService.getLicense();
                    return license.properties.MAX_SERIES_HEATMAP || 5;
                } ]
            }
        }
    };

    dashboardProvider.widget('heatmap', widget);

});

function HeatmapWidgetEditCtrl($scope, $filter, $timeout, $http, contextPath, dataService, configService,
config, ColorPickerOpts, TimeDerivativeScales, seriesService, maxSeries) {

    var i18n = $filter('i18n');
    var colors = [ '#000000' ];
    $scope.fontSizes=[
        {"name" : i18n("dashboard.widgets.config.small"), "value" :8},
        {"name" : i18n("dashboard.widgets.config.medium"), "value" :12},
        {"name" : i18n("dashboard.widgets.config.large"), "value" :16}
    ]

    configService.get().then(function(config) {
       var gui = config.gui;
        $scope.dashboards = [];
        $scope.dashboards.push({id: "", name: "", title: i18n("dashboard.widgets.config.none")});
        _.forEach(gui.dashboards, function(d){
            $scope.dashboards.push({id: d.id, name: d.name, title: d.title});
        })
    });

    $scope.ui = {
        colorPickerOpts: ColorPickerOpts,
        derivatives : TimeDerivativeScales,
        tempImpact : [{label: i18n('config.allow'), value:true},{label: i18n('config.deny'), value:false}],
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ],
        canAddSeries : function() {
            return $scope.config.series.length < maxSeries;
        },
        uuid : uuid.v1(),
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                    deviceId : null,
                    paramId : null,
                    minColor : "#00F",
                    maxColor : "#F00",
                    displayKind: "box",
                    positive: "on",
                    negative: "off",
                    dashboardId : null,
                    fontSize : 12,
                    derivative : 0,
                    zones : [ {
                        from : -9.9999998E17,
                        to : 9.9999998E17,
                        color : "#ffffff",
                        fontColor : "#000000"
                    } ]
                };
                $scope.config.series.push(series);
            }
        },
        removeSeries : function(series) {
            var index = $scope.config.series.indexOf(series);
            $scope.config.series.splice(index, 1);
        },
        updateSeriesParam : function(series) {
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.scale = series._param.scale || 0;
                series.precision = series._param.precision || 0;
                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                if (!series.derivative) {
                    series.unit=$filter('quantityUnit')(series._param.quantity);
                } else {
                    series.unit = seriesService.getTimeDerivativeUnit($filter('quantityUnit')(series._param.quantity), series.derivative);
                }
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
        },
        setBackground : function(filename) {
            var image = new Image(); // or document.createElement('img')
            var width, height;
            image.onload = function() {
              $scope.config.backgroundWidth = this.width;
              $scope.config.backgroundHeight = this.height;
            };
            image.src = '/file/' + filename.name;
            $scope.config.backgroundUrl = '/file/' + filename.name;
        },
        removeBackground: function ($event) {
            $http['delete']($scope.config.backgroundUrl);
             $scope.config.backgroundUrl = null;
             $event.preventDefault();
             $event.stopPropagation();
        },
        addZone : function(serieIndex) {

            var serie = $scope.config.series[serieIndex];
            if(!serie.zones){
                serie.zones = [];
            }
            var zonesLength = serie.zones.length;

            var min = zonesLength > 0 ? serie.zones[zonesLength - 1].to : serie.min;
            serie.zones.push({
                "color" : "#ffffff",
                "fontColor" : "#000000",
                "from" : min,
                "to" : min + 10
            });
        },
            removeZone : function(serieIndex, index) {
            $scope.config.series[serieIndex].zones.splice(index, 1);
        },
    };

    if (!$scope.config.series) {
        $scope.config.series = [];
    }

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });

        for ( var i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            series._device = _.find($scope.devices, function(device) {
                return device.id === series.deviceId;
            });
            if (series._device) {
                series._param = _.find(series._device.params, function(param) {
                    return param.id === series.paramId;
                });
            }
        }
    });
}

module.controller('HeatmapWidgetEditCtrl', HeatmapWidgetEditCtrl);

function HeatmapWidgetCtrl($scope, $filter, $q, contextPath, dataService, configService, config, DashboardEvents, seriesService) {

    $scope.config = config;
    $scope.data = [];
    $scope.dataRaw = [];
    $scope.dataRaw[0] = [];


    var currentTimeTo = function() {
        return new Date();
    };

    var currentTimeFrom = function() {
        return new Date(currentTimeTo().getTime() - 30 * 60 * 1000);
    };

    var updateData = function(index, data) {
        var maxData = config.series[index].average * 2;
        if (!$scope.dataRaw[index]) {
            $scope.dataRaw[index] = [];
        }
        $scope.dataRaw[index] = $scope.dataRaw[index].concat(data);
        if (maxData < $scope.dataRaw[index].length) {
            $scope.dataRaw[index].splice(0, $scope.dataRaw[index].length - maxData);
        }

        var outValues = seriesService.processData($scope.dataRaw[index], NaN, NaN, config.series[index].derivative,
            config.series[index].average);

        _.forEach(outValues, function(outValue) {
            if (outValue) {
                outValue.index = index;
            }
        });

        if (outValues.length > 0) {
            $scope.data[index] = outValues[outValues.length - 1];
            $scope.$broadcast(DashboardEvents.REDRAW, $scope.data);
        }
    };

    var promises = [];
    _.each(config.series, function(serie) {
        var request = {};
        if (serie.paramId) {
            request.count = Math.max(serie.average, 1);

            var promise = dataService.requestParamLastData(serie.paramId);
            promise.paramId = serie.paramId;
            promises.push(promise);
        }
    });
    $q.all(promises).then(function(moments) {

        _.forEach(promises, function(p, i) {
            var d = [];
            if (moments[i].previous){
                d.push(moments[i].previous);
            }
            d.push(moments[i].current);
            updateData(i, d);
            var unsubscribeFn = dataService.subscribeForParametersMeasurements(promises[i].paramId, function(moment) {
                var data = [];
                data.push(moment.current);
                updateData(i, data);
            });
            $scope.$on('$destroy', unsubscribeFn);
        });
    });
}
module.controller('heatmapWidgetCtrl', HeatmapWidgetCtrl);

<form class="form-horizontal" name="configForm" x-role="form">
    <h2>{{'led.config.lampSerie.title' | i18n}}</h2>

    <div class="row">
        <div class="col-lg-7 col-md-8">
            <div class="form-group" x-ng-class="{'has-error': configForm.configLampSerieAlias.$invalid}">
                <label for="configLampSerieAlias" class="col-sm-3 control-label">{{'led.config.lampSerie.alias' |
                    i18n}}</label>

                <div class="col-sm-8">
                    <input type="text" class="form-control" id="configLampSerieAlias" name="configLampSerieAlias"
                           placeholder="{{'led.config.lampSerie.alias' | i18n}}" data-ng-model="ui.alias"
                           ng-minlength="3" ng-maxlength="20" required=""/>

                    <div class="error-container" ng-show="configForm.configLampSerieAlias.$invalid ">
                        <small class="error">
                            {{'led.config.lampSerie.error.alias' | i18n}}
                        </small>
                    </div>
                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                            tooltip-placement="right" uib-tooltip="{{'led.config.lampSerie.question.alias' | i18n}}"
                            tooltip-animation="true"><span class="fa fa-question"/></button>
                </div>
            </div>
            <div class="form-group" x-ng-class="{'has-error': configForm.configLampSerieModelName.$invalid}">
                <label for="configLampSerieModelName" class="col-sm-3 control-label">{{'led.config.lampSerie.modelName'
                    | i18n}}</label>

                <div class="col-sm-8">
                    <input type="text" class="form-control" id="configLampSerieModelName"
                           name="configLampSerieModelName" placeholder="{{'led.config.lampSerie.modelName' | i18n}}"
                           data-ng-model="ui.modelName"
                           ng-minlength="3" ng-maxlength="20" required=""/>

                    <div class="error-container" ng-show="configForm.configLampSerieModelName.$invalid ">
                        <small class="error">
                            {{'led.config.lampSerie.error.modelName' | i18n}}
                        </small>
                    </div>
                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                            tooltip-placement="right" uib-tooltip="{{'led.config.lampSerie.question.modelName' | i18n}}"
                            tooltip-animation="true"><span class="fa fa-question"/></button>
                </div>
            </div>
            <div class="form-group" x-ng-class="{'has-error': configForm.configLampSerieManufacturer.$invalid}">
                <label for="configLampSerieManufacturer" class="col-sm-3 control-label">{{'led.config.lampSerie.manufacturer'
                    | i18n}}</label>

                <div class="col-sm-8">
                    <input type="text" class="form-control" id="configLampSerieManufacturer"
                           name="configLampSerieManufacturer"
                           placeholder="{{'led.config.lampSerie.manufacturer' | i18n}}" data-ng-model="ui.manufacturer"
                           ng-minlength="3" ng-maxlength="20" required=""/>

                    <div class="error-container" ng-show="configForm.configLampSerieManufacturer.$invalid ">
                        <small class="error">
                            {{'led.config.lampSerie.error.manufacturer' | i18n}}
                        </small>
                    </div>
                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                            tooltip-placement="right" uib-tooltip="{{'led.config.lampSerie.question.manufacturer' | i18n}}"
                            tooltip-animation="true"><span class="fa fa-question"/></button>
                </div>
            </div>
            <div class="form-group">
                <center>
                    <button class="btn btn-primary pull-right" data-ng-class="{'disabled' : !dataChanged()}"
                            type="button"
                            data-ng-click="ui.saveSerie()">{{'led.measurements.save' | i18n}}
                    </button>
                </center>
            </div>
        </div>
    </div>
    <!--<div class="row">
<div class="col-lg-12">
    <h2>{{'led.config.lampSerie.lamps'	| i18n}}</h2>
    <table data-ng-if="lampSerieConfig.lamps.length > 0" class="table table-hover">
        <tr>
            <th>
                <a href="" ng-click="predicate = 'serialNumber'; reverse=!reverse">{{'led.config.lamp.serialNumber' | i18n}}</a>
                <i class="fa" x-ng-show="predicate == 'temperature'" x-ng-class="{'fa-caret-up': reverse, 'fa-caret-down': !reverse}"></i>
            </th>
        </tr>
        <tr x-ng-repeat="lamp in lampSerieConfig.lamps | orderBy:predicate:reverse">
            <td class="text-right">{{lamp.serialNumber}}</td>
        </tr>
    </table>
    <div data-ng-if="lampSerieConfig.lamps.length == 0">
    {{'led.config.lampSerie.noLamps' | i18n}}
    </div>
</div>
</div>-->
    <div class="row" data-ng-if="lampSerieConfig.lamps.length > 0">
        <div class="col-lg-7 col-md-8">
            <h2>{{'led.config.lampSerie.simulation' | i18n}}</h2>

            <div class="form-group">
                <label for="configSimulationDateStart" class="col-sm-3 control-label">{{'led.config.lampSerie.simulationDateStart'
                    | i18n}}</label>

                <div class="col-sm-8">
                    <!--<div class="dropdown">-->
                        <!--<a class="dropdown-toggle" id="dropdownSimulationDateStart" role="button" data-toggle="dropdown"-->
                           <!--data-target="#" href="#">-->
                            <!--<div class="input-group">-->
                                <!--<input type="text" class="form-control" x-ng-disabled="false"-->
                                       <!--value="{{ui.simulationDateStart | date: 'y-MM-dd'}}"/>-->
								    <!--<span class="input-group-addon">-->
								    	<!--<i class="fa fa-calendar"></i>-->
								    <!--</span>-->

                            <!--</div>-->
                        <!--</a>-->
                        <!--<ul class="dropdown-menu" role="menu">-->
                            <!--<datetimepicker class="width-max" data-ng-model="ui.simulationDateStart"-->
                                            <!--data-datetimepicker-config="{ dropdownSelector: '#dropdownSimulationDateStart', minView: 'day' }"/>-->
                        <!--</ul>-->
                    <!--</div>-->

                    <div class='input-group date'>
                        <input type='text' class="form-control" x-ng-model="ui.simulationDateStart" x-datetimepicker=""/>
                            <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                    </div>

                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                            tooltip-placement="right"
                            uib-tooltip="{{'led.config.lampSerie.question.simulationDateStart' | i18n}}"
                            tooltip-animation="true"><span class="fa fa-question"/></button>
                </div>
            </div>
            <div class="form-group">
                <label for="configSimulationDate" class="col-sm-3 control-label">{{'led.config.lampSerie.simulationDateEnd'
                    | i18n}}</label>

                <div class="col-sm-8">
                    <!--<div class="dropdown">-->
                        <!--<a class="dropdown-toggle" id="dropdownSimulationDateEnd" role="button" data-toggle="dropdown"-->
                           <!--data-target="#" href="#">-->
                            <!--<div class="input-group">-->
                                <!--<input type="text" class="form-control" x-ng-disabled="false"-->
                                       <!--value="{{ui.simulationDateEnd | date: 'y-MM-dd'}}"/>-->
								    <!--<span class="input-group-addon">-->
								    	<!--<i class="fa fa-calendar"></i>-->
								    <!--</span>-->

                            <!--</div>-->
                        <!--</a>-->
                        <!--<ul class="dropdown-menu" role="menu">-->
                            <!--<datetimepicker class="width-max" data-ng-model="ui.simulationDateEnd"-->
                                            <!--data-datetimepicker-config="{ dropdownSelector: '#dropdownSimulationDateEnd', minView: 'day' }"/>-->
                        <!--</ul>-->
                    <!--</div>-->

                    <div class='input-group date'>
                        <input type='text' class="form-control" x-ng-model="ui.simulationDateEnd" x-datetimepicker=""/>
                                <span class="input-group-addon">
                                    <span class="fa fa-calendar"></span>
                                </span>
                    </div>

                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                            tooltip-placement="right"
                            uib-tooltip="{{'led.config.lampSerie.question.simulationDateEnd' | i18n}}"
                            tooltip-animation="true"><span class="fa fa-question"/></button>
                </div>
            </div>
            <div class="form-group" x-ng-class="{'has-error': configForm.configLampSerieAlias.$invalid}">
                <label for="configLampSerieSimulationTemperature" class="col-sm-3 control-label">{{'led.config.lampSerie.simulationTemperature'
                    | i18n}}</label>

                <div class="col-sm-8">
                    <input type="text" class="form-control" id="configLampSerieSimulationTemperature"
                           name="configLampSerieSimulationTemperature" data-ng-model="ui.simulationTemperature"
                           required=""/>

                    <div class="error-container" ng-show="configForm.configLampSerieSimulationTemperature.$invalid ">
                        <small class="error">
                            {{'led.config.lampSerie.error.simulationTemperature' | i18n}}
                        </small>
                    </div>
                </div>
                <div class="col-sm-1">
                    <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                            tooltip-placement="right"
                            uib-tooltip="{{'led.config.lampSerie.question.simulationTemperature' | i18n}}"
                            tooltip-animation="true"><span class="fa fa-question"/></button>
                </div>
            </div>
            <div class="form-group col-sm-12">
                <center>
                    <button class="btn btn-primary" type="button"
                            data-ng-click="ui.simulate()">{{'led.measurements.simulate' | i18n}}
                    </button>
                    <a class="btn btn-primary disabled"
                       target="_blank" href="/simulationFile" download="result.ldt">
                        {{'led.measurements.getResultFile' | i18n}}
                        <!--<i class="fa fa-download"></i>-->
                    </a>
                </center>
            </div>
        </div>


    </div>
    <div class="row" data-ng-if="ui.ready">
        <div class="form-group">
            <label class="col-sm-3 control-label">{{'led.measurements.simulate.parameter' | i18n}}</label>

            <div class="col-sm-4">
                <select class="form-control" data-ng-model="selectedParameter">
                    <option data-ng-repeat="value in simulatedParameters"
                            value="{{value}}"
                            >
                        {{'led.measurements.simulate.parameter.' + value | i18n}}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-lg-12">
            <div style="height:300px" data-ng-if="selectedParameter == 'simulated.ra8'">
                <div x-line-chart="" x-data="ra8dataSimulated" x-options="ra8optionsSimulated"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'simulated.colorTemperature'">
                <div x-line-chart="" x-data="colorTemperatureDataSimulated"
                     x-options="colorTemperatureOptionsSimulated"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'simulated.xk'">
                <div x-line-chart="" x-data="xkDataSimulated" x-options="xkOptionsSimulated"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'simulated.yk'">
                <div x-line-chart="" x-data="ykDataSimulated" x-options="ykOptionsSimulated"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'simulated.luminance'">
                <div x-line-chart="" x-data="luminanceDataSimulated" x-options="luminanceOptionsSimulated"/>
            </div>

            <div style="height:300px" data-ng-if="selectedParameter == 'observed.ra8'">
                <div x-line-chart="" x-data="ra8dataObserved" x-options="ra8optionsObserved"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'observed.colorTemperature'">
                <div x-line-chart="" x-data="colorTemperatureDataObserved" x-options="colorTemperatureOptionsObserved"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'observed.xk'">
                <div x-line-chart="" x-data="xkDataObserved" x-options="xkOptionsObserved"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'observed.yk'">
                <div x-line-chart="" x-data="ykDataObserved" x-options="ykOptionsObserved"/>
            </div>
            <div style="height:300px" data-ng-if="selectedParameter == 'observed.luminance'">
                <div x-line-chart="" x-data="luminanceDataObserved" x-options="luminanceOptionsObserved"/>
            </div>
        </div>
    </div>
</form>
<div style="overflow-y: auto;">
    <div x-indicator-gauge="" x-data="indicator" x-options="config"  x-ng-if="config.series[0].paramId" style="height: 100%;">
    </div>
    <div x-ng-show="!config.series[0].paramId">
        <div class="row" style="height: 100%;">
            <div class="col-sm-6 col-sm-offset-3" style="padding-top: 20px;">
                <button class="btn btn-info btn-lg" x-ng-class="{'disabled' : !$parent.$parent.editMode}"
                        style="width : 100%;" x-ng-click="$parent.$parent.editMode ? $parent.$parent.edit() : null"
                        title="{{$parent.$parent.editMode ? ('widget.configure' | i18n) : ('widget.enable.editMode' | i18n)}}">
                    {{'widget.configure'| i18n}} <i class="fa fa-cog"></i></button>
            </div>
        </div>
    </div>
</div>
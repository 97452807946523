<form class="form-horizontal row" x-errors="item.errors" x-errors-root="item.config" x-ng-switch="item.config.type">
    <div class="col-sm-8">
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.id' | i18n}}</label>
            <div class="col-sm-8">
                <input class="form-control" x-ng-model="item.config.id" x-field-errors="" x-ng-readonly="true"/>
            </div>
        </div>
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-errors=""
                           x-field-help="config.parameter.name.help"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'energy-monitor' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid' && item.config.type != 'math'">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-errors=""
                           x-field-default-value="item.config.name" x-field-help="config.param.label.help"/>
                </div>
            </div>
        </div>


        <div class="form-group" x-ng-if="item.config.type === 'mosquitto'">
            <label class="control-label col-sm-3">{{'config.param.mqttLabel' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.param.mqttLabel" x-field-errors=""
                           x-field-default-value="item.config.id" x-field-help="config.param.mqttLabel.help"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type == 'energy-report' || item.config.type == 'energy-monitor' || item.config.type == 'alarm' || item.config.type == 'control' || item.config.type == 'prepaid' || item.config.type == 'math'">
            <label class="control-label col-sm-3">{{'config.param.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-errors=""
                           x-field-default-value="item.config.name" x-field-help="config.param.label.help"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type !== 'energy-report'">
            <label class="control-label col-sm-3">{{'config.param.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-errors=""
                           x-field-help="config.param.desc.help"/>
                </div>
            </div>
        </div>
		<div class="form-group" x-ng-if="item.config.type === 'energy-report'">
			<label class="control-label col-sm-3">{{'config.comment' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.desc" x-field-errors=""
						   x-field-help="config.param.comment.help"/>
				</div>
			</div>
		</div>

        <div class="form-group" x-ng-switch-when="energy-report">
            <label class="control-label col-sm-3">{{'config.device.param.choice' | i18n}}</label>
            <div class="col-sm-8" x-config-form-parameter-chooser="item.config.paramId"></div>
        </div>

        <div class="form-group" x-ng-switch-when="alarm">
            <label class="control-label col-sm-3">{{'config.parameter.inputParam.data' | i18n}}</label>
            <div class="col-sm-8" x-config-form-parameter-chooser="item.config.inputParamId"></div>
        </div>

        <div class="form-group" x-ng-switch-when="control">
            <label class="control-label col-sm-3">{{'config.parameter.inputParam.data' | i18n}}</label>
            <div class="col-sm-8" x-config-form-parameter-chooser="item.config.inputParamId"></div>
        </div>

        <div class="form-group" x-ng-switch-when="prepaid">
            <label class="control-label col-sm-3">{{'config.parameter.inputParam.data' | i18n}}</label>
            <div class="col-sm-8" x-config-form-parameter-chooser="item.config.inputParamId"></div>
        </div>

        <div class="form-group" x-ng-switch-when="outputbinary">
            <label class="control-label col-sm-3">{{'config.param.inverted' | i18n}}</label>
            <div class="col-sm-7">
                <div class="input-group">
                    <select class="form-control" style="width: 200px;"
                            x-ng-model="item.config.inverted"
                            x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
                            x-field-errors=""></select>
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" uib-popover="{{'config.database.inverted.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
                </button>
            </div>
        </div>

        <div class="form-group" x-ng-switch-when="prepaid">
            <label class="control-label col-sm-3">{{'config.parameter.outParam.data'| i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control" x-ng-model="item.config.outputParamId"
                            x-field-help="config.parameter.outParam.help"
                            x-ng-options="p.id as p.name for p in ui.getWritableParams()"
                            x-field-errors=""></select>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-switch-when="dlms">
            <label class="control-label col-sm-3">{{'config.parameter.obis'| i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.obis" x-field-errors=""
                           x-field-help="config.parameter.obis.help"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type=='bacnet'">
            <label class="control-label col-sm-3">{{'config.param.bacnet.oid' | i18n}}</label>
            <div class="col-sm-8">
                <input class="form-control" x-ng-model="item.config.oid" x-field-errors=""/>
            </div>
        </div>

        <div class="form-group"
             x-ng-if="(advanceView.value|| item.config.type=='combined' || item.config.type=='aggregate' || item.config.type=='bacnet' || item.config.type=='mosquitto' || item.config.type=='dlms') && item.config.type != 'energy-report' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid'">
            <label class="control-label col-sm-3">{{'config.parameter.quantity' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control"
                            x-ng-model="item.config.quantity"
                            x-ng-options="q.type as q.label for q in ui.quantities"
                            x-field-help="config.param.quantity.help"
                            x-field-errors=""></select>
                </div>
            </div>
        </div>

        <div class="form-group"
             x-ng-if="(advanceView.value || item.config.type=='combined' || item.config.type=='aggregate' || item.config.type=='bacnet' || item.config.type=='mosquitto' || item.config.type=='dlms') && item.config.type != 'energy-report' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid'">
            <label class="control-label col-sm-3">{{'config.parameter.unit' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.unit" x-field-errors=""
                           x-field-help="config.param.unit.help" x-ng-change="checkScale()"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid'">
            <label class="control-label col-sm-3">{{'config.parameter.scale' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control" x-ng-model="item.config.scale" x-ng-disabled="item.config.unit==''"
                            x-field-errors="" x-field-help="config.parameter.scale.help"
                            x-ng-options="s.value as s.label for s in ui.scales()"></select>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid'">
            <label class="control-label col-sm-3">{{'config.parameter.precision' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.precision" x-field-errors=""
                           x-field-help="config.param.precision.help"/>
                </div>
            </div>
        </div>

<!--        <div class="form-group" x-ng-if="item.config.type == 'energy-report' ">-->
<!--            <label class="control-label col-sm-3">{{'config.device.increase' | i18n}}</label>-->
<!--            <div class="col-sm-8">-->
<!--                <div class="input-group">-->
<!--&lt;!&ndash;                    <input class="form-control" x-ng-model="item.config.increase" x-field-errors=""&ndash;&gt;-->
<!--&lt;!&ndash;                           x-field-help="config.param.increase.help"/>&ndash;&gt;-->
<!--                    <select class="form-control" x-ng-model="item.config.increase"-->
<!--                            x-field-errors="" x-field-help="config.param.increase.help"-->
<!--                            x-ng-options="s.value as s.label for s in ui.booleansIncrease"></select>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid'">
            <label class="control-label col-sm-3">{{'config.parameter.offset' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.offset"
                           x-field-help="config.parameter.offset.help" x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'alarm' && item.config.type != 'control' && item.config.type != 'prepaid'">
            <label class="control-label col-sm-3">{{'config.parameter.factor' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.factor"
                           x-field-help="config.parameter.factor.help" x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.tags' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.tags"
                           x-field-help="config.input.tags.help" x-field-errors=""/>
                </div>
            </div>
        </div>

        <!--<div class="form-group">-->
        <!--<label class="control-label col-sm-3">{{'config.parameter.minValue' | i18n}}</label>-->
        <!--<div class="col-sm-8">-->
        <!--<div class="input-group">-->
        <!--<input class="form-control" x-ng-model="item.config.minValue" x-field-help="config.param.minValue.help" x-field-errors="" />-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->


        <!--<div class="form-group">-->
        <!--<label class="control-label col-sm-3">{{'config.parameter.maxValue' | i18n}}</label>-->
        <!--<div class="col-sm-8">-->
        <!--<div class="input-group">-->
        <!--<input class="form-control" x-ng-model="item.config.maxValue" x-field-help="config.param.maxValue.help" x-field-errors="" />-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
    </div>
    <div class="col-sm-4"></div>
    <!--matematyka agregacja-->
    <div class="col-sm-12" x-ng-if="item.config.type==='aggregate'">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="panel-title">
                    <i class="fa fa-fw fa-calculator"></i>&nbsp;{{'config.parameter.expression.title' | i18n}}
                </div>
            </div>
            <div class="row" style="margin: 10px;">
                <div x-ng-if="item.config.paramId !== undefined" class="form-group">
                    <label class="control-label col-sm-2">{{::'config.device.param.choice' | i18n}}</label>
                    <div class="col-sm-10"
						 x-config-form-parameter-chooser="item.config.paramId"
						 x-config="config"
                         x-show-help="true"></div>
                </div>
                <div x-ng-if="item.config.length !== undefined" class="form-group">
                    <label class="control-label col-sm-2">{{::'config.parameter.length' | i18n}}</label>
                    <div class="col-sm-10">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.length"
                                   x-field-help="config.parameter.length.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-2">{{::'config.parameter.function' | i18n}}</label>
                    <div class="col-sm-10">
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#max(m)'">
                            {{'config.math.function.max' | i18n}}
                        </button>
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#min(m)'">
                            {{'config.math.function.min' | i18n}}
                        </button>
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#avg(m)'">
                            {{'config.math.function.avg' | i18n}}
                        </button>
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#med(m)'">
                            {{'config.math.function.med' | i18n}}
                        </button>
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#oldest(m)'">
                            {{'config.math.function.oldest' | i18n}}
                        </button>
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#newest(m)'">
                            {{'config.math.function.newest' | i18n}}
                        </button>
                        <button class="btn btn-primary" style="margin-bottom: 1px; "
                                x-ng-click="item.config.expr='#derivative(m)'">
                            {{'config.math.function.derivative' | i18n}}
                        </button>
                    </div>
                </div>

                <div x-ng-if="item.config.expr !== undefined" class="form-group">
                    <label class="control-label col-sm-2">{{::'config.parameter.expression' | i18n}}</label>
                    <div class="col-sm-9">
                        <textarea class="form-control" x-ng-model="item.config.expr" x-field-errors=""/>
                    </div>
                    <button class="btn btn-primary pull-right" type="button"
                            uib-popover="{{'config.parameter.expression.help'|i18n}}" popover-trigger="mouseenter"
                            popover-placement="left">?
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!--matematyka kombinacja-->
    <div class="col-sm-12" x-ng-if="item.config.paramIds !== undefined">
        <div class="panel panel-default">
            <div class="panel-heading">
                <div class="panel-title">
                    <i class="fa fa-fw fa-calculator"></i>&nbsp;{{'config.parameter.expression.title' | i18n}}
                </div>
            </div>
            <div class="row" style="margin: 10px;">
                <div class="form-group">
                    <label class="control-label col-sm-2">{{::'config.parameter.combination.data' | i18n}}</label>
                    <div class="col-sm-10">
                        <div x-ng-repeat="paramId in item.config.paramIds track by (paramId + $index)"
							 class="row"
							 style="display: flex; gap: 5px; margin-bottom: 5px;">
                                <div style="width: 30px; line-height: 28px;">m[{{$index}}]</div>
                                <div x-config-form-parameter-chooser="item.config.paramIds[$index]"
									 x-path="'item.config.paramIds[' + $index + ']'"
									 x-config="config"
									 x-show-help="false"
									 style="flex-grow: 1;">
                                </div>
                                <div style="display: inline-block;">
                                    <button class="btn btn-danger" x-ng-click="ui.removeParameter($index)">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                    <button class="btn btn-primary"
                                            x-ng-click="item.config.expr = item.config.expr + 'm[' + $index + '].value'">
                                        <i class="fa fa-share"></i>
                                    </button>
                                </div>
                        </div>
						<div class="row">
							<button class="btn btn-primary" x-ng-click="ui.sumAllMath()">
								<i class="fa fa-calculator"></i>&nbsp;{{::'config.parameter.sumAll' | i18n}}
							</button>
							<button class="btn btn-success pull-right" x-ng-click="ui.addParameter()">
								<i class="fa fa-plus"></i>&nbsp;{{::'config.parameter.add' | i18n}}
							</button>
						</div>
                    </div>
                </div>
                <div x-ng-if="item.config.expr !== undefined" class="form-group">
                    <label class="control-label col-sm-2">{{::'config.parameter.expression' | i18n}}</label>
                    <div class="col-sm-9">
                        <textarea class="form-control" x-ng-model="item.config.expr" x-field-errors=""/>
                    </div>
                    <button class="btn btn-primary pull-right" type="button"
                            uib-popover="{{'config.parameter.expression.help'|i18n}}" popover-trigger="mouseenter"
                            popover-placement="left">?
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
<form x-ng-if="item.config.type=='external'">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title"><i class="fa fa-fw fa-plus"></i>&nbsp;{{'config.extarnalUpdate' | i18n}}</div>
        </div>
        <div class="form-horizontal row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'report.table.deviceName' | i18n}}</label>
                    <div class="col-sm-8">
                        <input class="form-control" x-ng-model="measurement[0].device" x-field-errors=""
                               x-ng-readonly="true"/>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'report.table.paramName' | i18n}}</label>
                    <div class="col-sm-8">
                        <input class="form-control" x-ng-model="measurement[0].param" x-field-errors=""
                               x-ng-readonly="true"/>
                    </div>
                </div>
                <div class='form-group'>
                    <label class="control-label col-sm-3">{{'report.table.timestamp' | i18n}}</label>
                    <div class="col-sm-8 input-group date">
                        <input type='text' class="form-control" x-ng-model="measurement[0].timestamp"
                               x-datetimepicker=""/>
                            <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'report.table.value' | i18n}}</label>
                    <div class="col-sm-8">
                        <input class="form-control" x-ng-model="measurement[0].value" x-field-errors=""/>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="alert alert-success" role="alert" x-ng-show="succesVisible">Sukces</div>
                    <div class="alert alert-danger" role="alert" x-ng-show="errorVisible">Błąd</div>
                    <div class="pull-right">
                        <button class="btn btn-small btn-success" x-ng-click="sendMesurement()">
                            <i class="fa fa-plus"></i>&nbsp;{{'timetable.frame.add' | i18n}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<form class="form-inline" x-ng-if="item.config.type=='alarm' ">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title">
                <i class="fa fa-fw fa-filter"></i>&nbsp;{{'config.momentRule.alarm.hysteresis' | i18n}}
                <span class="badge">{{item.children.length}}</span>
            </div>
        </div>

        <!--nazwa reguły prog alarmu prog powrotu adresat-->
        <div class="row" style="font-weight: bold; padding:5px 0px 5px 0px;" x-ng-if="item.children.length">
            <div class="col-sm-1"></div>
            <div class="col-sm-3">
                {{'config.name' | i18n}}
            </div>
            <div class="col-sm-2">
                {{'config.momentRule.alarm.threshold' | i18n}}
            </div>
            <div class="col-sm-2">
                {{'config.momentRule.return.threshold' | i18n}}
            </div>
            <div class="col-sm-3">
                {{'config.momentRule.notifier.to' | i18n}}
            </div>
            <div class="col-sm-1"></div>
        </div>

        <ul class="list-group" style="margin: 0px 2px 0px 2px; border-style: solid; border-width: 0px 1px 0px 1px;">
            <li class="list-group-item row" x-ng-repeat="rule in item.children"
                x-ng-if="rule.config.positive.actions[0].type=='notifier'">


                <div class="panel" style="margin-bottom: -5px;">
                    <div class="list-group-item ng-scope visible"
                         style="padding:0px 5px 0px 5px; border:0px; cursor: pointer;" x-ng-click="rule.open=!rule.open"
                         role="tab" id="headingOne">
                        <!--<div class="col-sm-11" x-ng-click="rule.open=!rule.open">-->
                        <div class="col-sm-1">
                            <i class="fa fa-angle-right" x-ng-if="!rule.open" aria-hidden="true"></i>
                            <i class="fa fa-angle-down" x-ng-if="rule.open" aria-hidden="true"></i>
                        </div>
                        <div class="col-sm-3">
                            {{rule.config.name}}
                        </div>
                        <div class="col-sm-2">
                            {{ ui.format(ui.ruleActivationValue(rule.config)) }}
                        </div>
                        <div class="col-sm-2">
                            {{ ui.format(ui.ruleDeactivationValue(rule.config)) }}
                        </div>
                        <div class="col-sm-3">
                            {{rule.config.positive.actions[0].to}}
                        </div>
                        <div class="col-sm-1" style="padding-right: 10px;">
                            <button class="btn btn-danger btn-xs pull-right" x-ng-click="ui.removeMomentRule(rule)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div class="panel-collapse" role="tabpanel" x-ng-if="rule.open">
                        <div class="panel-body">
                            <div class="form-group config-rule col-xs-11" x-config-form-moment-rule-alarm="rule"
                                 x-config="config"></div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="panel-body">
            <div class="pull-right">
                <button class="btn btn-small btn-success" x-ng-click="ui.addMomentAlarmRule()">
                    <i class="fa fa-plus"></i>&nbsp;{{'config.momentRule.add' | i18n}}
                </button>
            </div>
        </div>
    </div>
 </form>


         <form class="form-inline" x-ng-if="item.config.type=='control' ">
             <div class="panel panel-default">
                 <div class="panel-heading">
                     <div class="panel-title">
                         <i class="fa fa-fw fa-filter"></i>&nbsp;{{'config.momentRules' | i18n}}
                         <span class="badge">{{item.children.length}}</span>
                     </div>
                 </div>
                 <ul class="list-group">
                     <li class="list-group-item row" x-ng-repeat="rule in item.children"
                         x-ng-if="rule.config.positive.actions[0].type=='parameter-write'">
                         <div class="panel panel-default" style="margin-bottom: -5px;">
                             <div class="panel-heading" role="tab" id="headingTwo">
                                 <h4 class="panel-title row">
                                     <div class="col-sm-11">
                                         <button class="btn btn-link collapsed btn-xs" type="button" data-toggle="collapse"
                                                 data-target="#control-{{$index}}" aria-expanded="false"
                                                 aria-controls="{{item.config.ruleId}}">
                                             <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                             {{$index +1}}
                                             <span x-ng-if="!rule.config.updown">{{'config.momentRule.direction.down.long' | i18n}}</span>
                                             <span x-ng-if="rule.config.updown">{{'config.momentRule.direction.up.long' | i18n}}</span>
                                             <span>{{ui.unitFilterFun(rule.config.value, item.config)}}</span>
                                             {{'config.momentRule.changevalue' | i18n}}
                                             <span x-ng-if="rule.config.outDevice">{{rule.config.outDevice}}</span>
                                             <span class="text-danger" x-ng-if="!rule.config.outDevice">{{'config.momentRule.notifier.noOutDevice' | i18n}}</span>
                                         </button>
                                     </div>
                                     <div class="col-sm-1">
                                         <button class="btn btn-danger btn-xs pull-right" x-ng-click="ui.removeMomentRule(rule)">
                                             <i class="fa fa-trash"></i>
                                         </button>
                                     </div>
                                 </h4>
                             </div>
                             <div id="control-{{$index}}" class="panel-collapse collapse" role="tabpanel"
                                  aria-labelledby="headingOne">
                                 <div class="panel-body">
                                     <div class="form-group config-rule col-xs-11" x-config-form-moment-rule-control="rule"
                                          x-config="config"></div>
                                 </div>
                             </div>
                         </div>
                     </li>
                 </ul>
                 <div class="panel-body">
                     <div class="pull-right">
                         <button class="btn btn-small btn-success" x-ng-click="ui.addMomentControlRule()">
                             <i class="fa fa-plus"></i>&nbsp;{{'config.momentRule.add' | i18n}}
                         </button>
                     </div>
                 </div>
             </div>
         </form>

         <!--========================Pierotne Reguly - ni usuwac=====================-->
<form class="form-inline" x-ng-if="advanceView.value">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title">
                <i class="fa fa-fw fa-filter"></i>&nbsp;{{'config.momentRules' | i18n}}
                <span class="badge">{{item.children.length}}</span>
            </div>
        </div>
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="rule in item.children">
                <div class="form-group config-rule" x-config-form-moment-rule="rule" x-config="config"></div>
                <div class="form-group" style="vertical-align: top;">
                    <label class="form-group-label">&nbsp;</label>
                    <button class="btn btn-danger" x-ng-click="ui.removeMomentRule(rule)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </li>
        </ul>
        <div class="panel-body">
            <div class="pull-right">
                <button class="btn btn-small btn-success" x-ng-click="ui.addMomentRule()">
                    <i class="fa fa-plus"></i>&nbsp;{{'config.momentRule.add' | i18n}}
                </button>
            </div>
        </div>
    </div>
</form>

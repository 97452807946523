<div class="container">
    <div class="row">
        <div class="panel panel-default">
            <div class="panel-heading"><i class="fa fa-fw fa-edit"></i>&nbsp;{{'parameterScore.title' | i18n}}</div>
            <div class="panel-body">
            <div class="input-group col-md-2" style="margin-top:10px;">
                <input type="text" data-ng-model="search.name" class="form-control">
                <span class="input-group-addon">
                    <i class="fa fa-search"></i>
                </span>
            </div>
            <table class="table table-condensed">
                <thead>
                <tr>
                    <th>{{'parameterScore.name' | i18n}}</th>
                    <th>{{'parameterScore.interval' | i18n}}</th>
                    <th>

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr data-ng-repeat="parameterScore in parameterScores | filter:search">
                    <td>
                        {{parameterScore.name}}
                    </td>
                    <td>
                        {{parameterScore.interval}}
                    </td>
                    <td>
                        <div class="pull-right">
                            <button class="btn btn-sm btn-warning" data-ng-click="ui.edit(parameterScore)" title="{{'ui.edit' | i18n}}"><i class="fa fa-edit fa-lg"></i> </button>
                            <button class="btn btn-sm btn-danger" data-ng-click="ui.delete(parameterScore)"
                                    title="{{'ui.delete' | i18n}}">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
            <div class="panel-footer clearfix"">
                <div class="pull-right">
                    <button class="btn btn-success" style="margin-right:5px;" data-ng-click="ui.addParameterScore()"><i class="fa fa-plus fa-lg"></i>  {{'parameterScore.add' | i18n}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

/* globals moment,_ */
var module = angular.module("meternet.report.energy.EnergyReportTableDirective", [
              'meternet.report.directives.EnergyReportChartWidgetDirective']);

module.directive("energyReportTable", function($http, $timeout, contextPath, configService, dataService, i18nFilter, unitFilter, quantityUnitFilter, dateFilter, seriesService) {
	function formatRange(t1, t2, cron) {
		function dayPart(day, month, year) {
			return day + "." + month + "." + year;
		}

		function hoursPart(minutes, hours) {
			return  hours + ":" + minutes;
		}

		function pad(value, n) {
			value = "" + value;
			while (value.length < n) {
				value = "0" + value;
			}
			return value;
		}

		if (!t1 || !t2) {
			return "-";
		}

		var parts = cron.split(/\s+/g);
		var y1 = t1.getFullYear();
		var y2 = t2.getFullYear();
		var m1 = pad(t1.getMonth() + 1, 2);
		var m2 = pad(t2.getMonth() + 1, 2);
		var d1 = pad(t1.getDate(), 2);
		var d2 = pad(t2.getDate(), 2);
		var hh1 = t1.getHours();
		var hh2 = t2.getHours();
		var mm1 = pad(t1.getMinutes(), 2);
		var mm2 = pad(t2.getMinutes(), 2);

		if (parts[3] === '*') {
			return dayPart(d2, m2, y2);
		} else if (parts[4] === '*' && parts[5] === '?') {
			return m2 + "." + y2;
		} else {
			if (y1 !== y2) {
				if (hh1 !== hh2 || mm1 !== mm2) {
					return hoursPart(mm1, hh1) + " " + dayPart(d1, m1, y1) + "-" + hoursPart(mm2, hh2) + " " + dayPart(d2, m2, y2);
				} else {
					return dayPart(d1, m1, y1) + "-" + dayPart(d2, m2, y2);
				}
			} else if (m1 !== m2) {
				if (hh1 !== hh2 || mm1 !== mm2) {
					return hoursPart(mm1, hh1) + " " + dayPart(d1, m1, y1) + "-" + hoursPart(mm2, hh2) + " " + dayPart(d2, m2, y2);
				} else {
					return d1 + "." + m1 + "-" + dayPart(d2, m2, y2);
				}
			} else if (d1 !== d2) {
				if (hh1 !== hh2 || mm1 !== mm2) {
					return hoursPart(mm1, hh1) + " " + dayPart(d1, m1, y1) + "-" + hoursPart(mm2, hh2) + " " + dayPart(d2, m2, y2);
				} else {
					return d1 + "-" + dayPart(d2, m2, y2);
				}
			} else {
				if (hh1 !== hh2 || mm1 !== mm2) {
					return hh1 + ":" + mm1 + "-" + hh2 + ":" + mm2 + " " + dayPart(d2, m2, y2);
				} else {
					return dayPart(d1, m1, y1);
				}
			}
		}
	}


	function PeriodData(row) {
		this._row = row;
		this._index = row.periods.length;
		this.timestamp = null;
		this.readout = null;
		this.delta = null;
		this.power = null;
		this.cost = null;
	}

	PeriodData.prototype.reset = function () {
		this.timestamp = null;
		this.readout = null;
		this.delta = null;
		this.power = null;
		this.cost = null;
	};

	PeriodData.prototype.update = function (m1, m0) {
		if (m1) {
			this.timestamp = m1.timestamp;
			this.readout = m1.value;
            this.cost = null;
            this.delta = null;
            this.power = null;
            if (m0) {
                this.delta = m1.value - m0.value;
                this.power = 3600000 * this.delta / (m1.timestamp.getTime() - m0.timestamp.getTime());
                if (this._row._series.cost != null) {
                    this.cost = this.delta * this._row._series.cost;
                }
            }
		} else {
			this.reset();
		}
	};

	function Stats() {
		this.unit = undefined;
		this.mixed = false;
		this.count = 0;
		this.sum = NaN;
		this.avg = NaN;
		this.min = NaN;
		this.max = NaN;
	}

	Stats.prototype.reset = function () {
		this.unit = undefined;
		this.mixed = false;
		this.count = 0;
		this.sum = 0;
		this.avg = NaN;
		this.min = Infinity;
		this.max = -Infinity;
	};

	Stats.prototype.update = function (value, unit) {
		if (typeof(value) === 'number' && isFinite(value)) {
			if (unit) {
				if (this.unit === undefined) {
					this.unit = unit;
				} else if (this.unit !== unit) {
					this.mixed = true;
				}
			}
			this.count++;
			this.sum += value;
			if (this.min > value) {
				this.min = value;
			}
			if (this.max < value) {
				this.max = value;
			}
		}
	};

	Stats.prototype.finish = function () {
		if (!this.mixed && this.count > 0) {
			this.avg = this.sum / this.count;
		} else {
			this.sum = NaN;
			this.avg = NaN;
			this.min = NaN;
			this.max = NaN;
		}
	};

	function Row(config, series, devices, params) {
		this._config = config;
		this._series = series;
		this._index = config.series.indexOf(series);

		var eparam = _.find(params, function (param) {
			return param.id === series.paramId;
		});
		var param = _.find(params, function (param) {
			return eparam && param.id === eparam.paramId;
		});
		var device = _.find(devices, function (device) {
			return _.find(device.params, function (param) {
				return eparam && param.id === eparam.paramId;
			});
		});

		if (param && eparam && device) {
            this._title = eparam.id + ',' + param.id;
			this.display = eparam.display && series.display;
			this.name = series.name ? series.name : device.label ? device.label : device.name;
			this.device = device.label ? device.label : device.name;
			this.descr1 = device.desc;
			this.descr2 = device.desc2;
			this.descr3 = device.desc3;
			this.param = param.label ? param.label : param.name;
			this.paramDesc = param.desc;
			this.precision = param.precision;
			this.scale = param.scale;
			this.quantity = param.quantity;
			this.unit = param.unit;
			this.cost = eparam.cost;
			this.costUnit = eparam.costUnit;
			this.powerUnit = seriesService.getTimeDerivativeUnit(this.unit || quantityUnitFilter(this.quantity), 3600000);
		} else {
			this.display = false;
		}

		this.deltaStats = new Stats();
		this.costStats = new Stats();
		this.powerStats = new Stats();

		this.periods = [];
		for (var i = 0; i < config.columns; ++i) {
			this.periods.push(new PeriodData(this));
		}
	}

	Row.prototype.reset = function () {
		for (var i = 0; i < this.periods.length; ++i) {
			var p = this.periods[i];
			p.reset();
		}
		this.deltaStats.reset();
		this.costStats.reset();
		this.powerStats.reset();
	};

	Row.prototype.update = function (p, m1, m0) {
		var period = this.periods[p];
		period.update(m1, m0);
		this.deltaStats.update(period.delta, this.unit);
		this.costStats.update(period.cost, this.costUnit);
		this.powerStats.update(period.power, this.powerUnit);
	};

	Row.prototype.finish = function () {
		this.deltaStats.finish();
		this.costStats.finish();
		this.powerStats.finish();
	};

	Row.prototype.deltaSum = function () {
		return this.deltaStats.sum;
	};

	Row.prototype.deltaAvg = function () {
		return this.deltaStats.avg;
	};

	Row.prototype.costSum = function () {
		return this.costStats.sum;
	};

	Row.prototype.costAvg = function () {
		return this.costStats.avg;
	};

	Row.prototype.powerAvg = function () {
		return this.powerStats.avg;
	};

	Row.prototype.format = function (field, value) {
		switch (field) {
			case 'timestamp':
				return dateFilter(value, 'yyyy-MM-dd HH:mm');
			case 'readout':
			case 'delta':
			case 'deltaSum':
			case 'deltaAvg':
				return unitFilter(value, this.precision, this.unit, this.scale);
			case 'cost':
			case 'costSum':
			case 'costAvg':
				return unitFilter(value, 2, this.costUnit);
			case 'power':
			case 'powerAvg':
				return unitFilter(value, this.precision, this.powerUnit, this.scale);
			default:
				return value;
		}
	};

	Row.prototype.color = function (field, value) {
		if (value == null) {
			return null;
		}
		var s = this._series;
		var zones;
		if (field === 'cost') {
			zones = s.zonesCost;
		} else if (field === 'power') {
			zones = s.zonesPower;
		} else {
			zones = s.zones;
		}
		var zone = _.find(zones, function (z) {
			return value >= z.from && value < z.to;
		});
		return zone ? zone.color : null;
	};

	function Column(label, field, align, period, stats) {
		this.label = label;
		this.field = field;
		this.align = align || 'left';
		this.period = period;
		this.periodic = period != null;
		this.stats = stats;
	}

	Column.prototype.value = function (row) {
		if (this.field == null) {
			return null;
		}

		var e = this.period != null ? row.periods[this.period] : row;
		var v = e[this.field];
		if (typeof(v) === 'function') {
			return v.apply(e);
		} else {
			return v;
		}
	};

	Column.prototype.valueFmt = function (row) {
		return row.format(this.field, this.value(row));
	};

	Column.prototype.hasBar = function (tab, row) {
		return this.stats != null && tab.mode(this.field) > 0;
	};

	Column.prototype.barWidth = function (tab, row) {
		var value = this.value(row);
		if (value != null && isFinite(value) && this.stats) {
			var mode = tab.mode(this.field);
			if (mode === 2) {
				return "100%";
			} else if (mode === 1 || mode === 3) {
				var stats = row[this.stats];
				if (stats && stats.min != null && stats.max != null) {
					return (Math.round(10000 * Math.abs(value) / Math.max(Math.abs(stats.max), Math.abs(stats.min))) / 100) + '%';
				}
			}
		}
		return null;
	};

	Column.prototype.barColor = function (tab, row) {
		var value = this.value(row);
		if (value != null) {
			var mode = tab.mode(this.field);
			if (mode === 1) {
				return '#80a9d1';
			} else if (mode > 1) {
				return row.color(this.field, value);
			}
		}
		return null;
	};

	function TotalColumn(label, field, stat, period, colspan) {
		this.label = label;
		this.field = field;
		this.stat = stat;
		this.align = 'right';
		this.period = period;
		this.colspan = colspan;
	}

	TotalColumn.prototype.value = function (tab) {
		if (this.field == null || this.stat == null) {
			return null;
		}

		return this.period != null
			? tab.columnStats[this.period][this.field][this.stat]
			: tab.totalStats[this.field][this.stat];
	};

	TotalColumn.prototype.valueFmt = function (tab) {
		return tab.format(this.field, this.value(tab));
	};

	function Table(config, meternetConfig) {
		var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true))
			.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
		var params = _.flatten(_.pluck(devices, 'params'), true);

		config = config || {};
		this._config = config;

		var rows = [];
		config.series.forEach(function (s) {
			var row = new Row(config, s, devices, params);
			if (row.display) {
				rows.push(row);
			}
		});
		this.rows = rows;
		this.periods = [];
		this.periodWidth = Math.floor(100 / config.columns) + '%';
		this.columns = [];
		this.totalColumns = [];

		this.columnStats = [];
		for (var i = 0; i < config.columns; ++i) {
			this.columnStats.push({
				delta: new Stats(),
				cost: new Stats(),
				power: new Stats(),
			});
		}

		this.totalStats = {
			delta: new Stats(),
			cost: new Stats(),
			power: new Stats(),
		};

		this.addColumn('energyReport.title', 'config.param.label', 'name');
		this.addColumn('energyReport.device', 'config.device.deviceName', 'device');
		this.addColumn('energyReport.descr1', 'config.device.descr1', 'descr1');
		this.addColumn('energyReport.descr2', 'config.device.descr2', 'descr2');
		this.addColumn('energyReport.descr3', 'config.device.descr3', 'descr3');
		this.addColumn('energyReport.param', 'report.table.param', 'param');
		this.addColumn('energyReport.paramDesc', 'report.table.paramDesc', 'paramDesc');
		this.addTotalColumn(null, null, null, this.columns.length);

		this.addColumn('energyReport.totalIncrease', 'report.table.totalIncrease', 'deltaSum', 'right');
		this.addColumn('energyReport.avgIncrease', 'report.table.avgIncrease', 'deltaAvg', 'right');
		this.addColumn('energyReport.avgPower', 'report.table.avgPower', 'powerAvg', 'right');
		this.addColumn('energyReport.totalCost', 'report.table.totalCost', 'costSum', 'right');
		this.addColumn('energyReport.avgCost', 'report.table.avgCost', 'costAvg', 'right');
		this.addTotalColumn('energyReport.totalIncrease', 'delta', 'sum', null, null);
		this.addTotalColumn('energyReport.avgIncrease', 'delta', 'avg', null, null);
		this.addTotalColumn('energyReport.avgPower', 'power', 'avg', null, null);
		this.addTotalColumn('energyReport.totalCost', 'cost', 'sum', null, null);
		this.addTotalColumn('energyReport.avgCost', 'cost', 'avg', null, null);

		this.staticColumnCount = this.columns.length;
		for (var c = 0; c < config.columns; ++c) {
			this.periods.push(null);
			this.addColumn('energyReport.date', 'energyReport.date', 'timestamp', 'center', c);
			this.addColumn('energyReport.value', 'energyReport.value', 'readout', 'right', c);
			if (c === 0) {
				this.periodicTotalColumnSpan = this.columns.length - this.staticColumnCount;
			}
			if (this.periodicTotalColumnSpan) {
				this.addTotalColumn(null, null, null, this.periodicTotalColumnSpan);
			}
			this.addColumn('energyReport.increase', 'energyReport.increase', 'delta', 'right', c, 'deltaStats');
			this.addColumn('energyReport.power', 'energyReport.power', 'power', 'right', c, 'powerStats');
			this.addColumn('energyReport.cost', 'energyReport.cost', 'cost', 'right', c, 'costStats');
			if (c === 0) {
				this.periodicColumnCount = this.columns.length - this.staticColumnCount;
			}
			this.addTotalColumn('energyReport.increase', 'delta', 'sum', null, c);
			this.addTotalColumn('energyReport.power', 'power', 'avg', null, c);
			this.addTotalColumn('energyReport.cost', 'cost', 'sum', null, c);
		}
	}

	Table.prototype.addColumn = function (id, label, field, align, period, stats) {
		var column = new Column(label, field, align, period, stats);
		if (this._config.visibleColumns.indexOf(id) >= 0) {
			this.columns.push(column);
		}
		return column;
	};

	Table.prototype.addTotalColumn = function (id, field, stat, colspan, period) {
		var column = new TotalColumn(null, field, stat, period, colspan);
		if (id == null || this._config.visibleColumns.indexOf(id) >= 0) {
			this.totalColumns.push(column);
		}
		return column;
	};

	Table.prototype.update = function (report) {
		var i, r, s, row;

		for (i = 0; i < this.columnStats.length; ++i) {
			s = this.columnStats[i];
			s.delta.reset();
			s.cost.reset();
			s.power.reset();
		}
		this.totalStats.delta.reset();
		this.totalStats.cost.reset();
		this.totalStats.power.reset();

		for (r = 0; r < this.rows.length; ++r) {
			this.rows[r].reset();
		}

		if (report && report.data) {
			for (i = 0; i < report.data.length - 1; ++i) {
				s = this.columnStats[i];
				var d0 = report.data[i];
				var d1 = report.data[i + 1];
				var t0 = new Date(d0.prevTimestamp);
				var t1 = new Date(d1.prevTimestamp);

				this.periods[i] = formatRange(t0, t1, this._config.cronExpression);

				for (r = 0; r < this.rows.length; ++r) {
					row = this.rows[r];
					var m1 = _.find(d1.values, function (m) {
						return m.paramId === row._series.paramId;
					});
					var m0 = _.find(d0.values, function (m) {
						return m.paramId === row._series.paramId;
					});
					row.update(i, m1, m0);
					s.delta.update(row.periods[i].delta, row.unit);
					s.cost.update(row.periods[i].cost, row.costUnit);
					s.power.update(row.periods[i].power, row.powerUnit);
					this.totalStats.delta.update(row.periods[i].delta, row.unit);
					this.totalStats.cost.update(row.periods[i].cost, row.costUnit);
					this.totalStats.power.update(row.periods[i].power, row.powerUnit);
				}
			}
		}

		for (r = 0; r < this.rows.length; ++r) {
			row = this.rows[r];
			row.finish();
		}

		for (i = 0; i < this.columnStats.length; ++i) {
			s = this.columnStats[i];
			s.delta.finish();
			s.cost.finish();
			s.power.finish();
		}
		this.totalStats.delta.finish();
		this.totalStats.cost.finish();
		this.totalStats.power.finish();
	};

	Table.prototype.format = function (field, value, row) {
		row = row || this.rows[0];
		return row ? row.format(field, value) : '';
	};

	Table.prototype.mode = function (field) {
		var c = this._config;
		if (field === 'cost') {
			return c.modeCost;
		} else if (field === 'power') {
			return c.modePower;
		} else {
			return c.mode;
		}
	};

    return {
        scope: {
            config: '=energyReportTable'
        },
        templateUrl: 'report/energy/energy-report-table.html',
        link : function(scope, elem, attr, ctrl) {
			function updateTable(step) {
				scope.ui.loading = true;

				dataService.getEnergyReportData(scope.config.reportId, scope.ui.date || new Date(), scope.config.columns + 1, step).then(function (resp){
					if (resp.data && resp.data.length) {
						scope.tab.update(resp);
						scope.ui.date = new Date(resp.data[resp.data.length - 1].timestamp);
					} else {
						scope.tab.update(null);
					}

					scope.ui.loading = false;
					if (scope.tab && scope.ui.chart) {
						scope.ui.showChart(scope.tab, scope.ui.chart._row, scope.ui.chart._column);
					}
				});
			}

			function createTable() {
				configService.get().then(function (meternetConfig) {
					scope.tab = new Table(scope.config, meternetConfig);
					updateTable();
				});
			}

			scope.ui = {
				date: null,
				loading: true,
				chart: null,
				step: function (step) {
					updateTable(step);
				},
				showChart: function (tab, row, column) {
					if (tab) {
						if (row != null && column != null) {
							this.chart = {
								title: row.name + ': ' + i18nFilter(column.label).toUpperCase(),
								data: _.map(_.filter(tab.columns, function (c) {
									return c.field === column.field;
								}), function (c) {
									var tc = _.find(tab.columns, function (col) {
										return col.field === 'timestamp' && col.period === c.period;
									});
									var value = c.value(row);
									value = typeof(value) === 'number' && isFinite(value) ? value : null;
									return {
										value: value,
										error: value != null ? 0 : 1,
										timestamp: dateFilter(tc.value(row), 'yyyy-MM-dd HH:mm'),
									};
								}),
								unit: {
									quantity: column.field === 'cost' ? row.costUnit : column.field === 'power' ? row.powerUnit : row.unit,
									precision: column.field === 'cost' ? 2 : row.precision,
									scale: column.field === 'cost' ? 0 : row.scale,
								},
								small: true,
								pagination: true,
								_row: row,
								_column: column,
							};
						} else if (column != null) {
							this.chart = {
								title: i18nFilter(column.label).toUpperCase(),
								data: _.map(tab.rows, function (r) {
									var value = column.value(r);
									value = typeof(value) === 'number' && isFinite(value) ? value : null;
									return {
										value: value,
										error: value != null ? 0 : 1,
										timestamp: r.device + " " + r.param + " (" + r.name + ")"
									};
								}),
								unit: {
									quantity: column.field === 'cost' ? tab.rows[0].costUnit : column.field === 'power' ? tab.rows[0].powerUnit : tab.rows[0].unit,
									precision: column.field === 'cost' ? 2 : tab.rows[0].precision,
									scale: column.field === 'cost' ? 0 : tab.rows[0].scale,
								},
								small: false,
								pagination: false,
								_row: null,
								_column: column,
							};
						}
					}
				}
            };

            scope.$watch("ui.date", function (nval, oval) {
                if (nval !== oval) {
                    updateTable(0);
                }
            }, true);

			scope.$watch("config", function () {
				createTable();
			}, true);

            scope.$on('generateCsv', function() {
                var dataToSend = {
                    "visibleColumns":scope.config.visibleColumns,
                    "series": scope.config.series,
                    "energyReportId": scope.config.reportId,
                    "count": scope.config.columns,
                    "endTime": scope.ui.date.toISOString(),
                    "timeZone" : encodeURIComponent(moment().format('ZZ'))
                };

                $http.post('/energyReport.csv', dataToSend).
                    then(function(data, status, headers, config) {
                        var anchor = angular.element('<a/>');
                        anchor.attr({
                            href: 'data:text/csv;charset=Windows-1250,' + encodeURI(data.data),
                            target: '_blank',
                            download: 'energy-report.csv',
                            id: 'testowanie'
                        });
                        anchor.appendTo('body');
                        anchor[0].click();
                    },function(data, status, headers, config) {
                    });
            });
            scope.$on('generateXlsx', function() {
                var dataToSend = {
                    "visibleColumns":scope.config.visibleColumns,
                    "series": scope.config.series,
                    "energyReportId": scope.config.reportId,
                    "count": scope.config.columns,
                    "endTime": scope.ui.date.toISOString(),
                    "timeZone" : encodeURIComponent(moment().format('ZZ'))
                };

                $http.post('/energyReport.xlsx', dataToSend, {responseType:'arraybuffer'}).
                    then(function(response, status, headers, config) {
                        var data = response.data; //from server
                        var blob = new Blob([data], { type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8"});
                        var link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = 'energy-report.xlsx';
                        link.click();
                    },function(data, status, headers, config) {
                    });
            });

            $('#myModal').on('hidden.bs.modal', function () {
              	scope.chart = null;
            });

			createTable();
        }
    };
});

module.directive("energyReportTablePagination", function() {
	return {
		scope: false,
		templateUrl: 'report/energy/energy-report-table-pagination.html',
		link: function(scope, elem, attr, ctrl) {
			var container = elem.parent().parent();
			elem.detach();
			container.append(elem);
		}
	};
});

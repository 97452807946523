var module = angular.module('meternet.timetable.controllers', [
]);



module.controller('timetableCtrl', function ($scope, timetableService) {



});

module.controller('addTimetableCtrl', function($scope, timetableService, $uibModal, $stateParams, configService){
});

module.controller("frameCtrl", function($scope, $uibModalInstance, model, userService, timetable, timetableService, Errors){
    $scope.model = model;
    $scope.users = [];
    $scope.frames = timetable.frames;
    $scope.timetable = timetable;

    $scope.ui = {
        modalTitle: 'timetable.frame.addTimetable',
        mode: 'fa-plus',
        shiftDates: function() {
            //console.log('aa');
        }
    };

    $scope.$watch('model.dateFrom', function(newVal, oldVal) {
        $scope.model.dateTo = moment(newVal + ($scope.model.dateTo - oldVal));
    });


    if ($scope.model.id) {
        $scope.ui = {
            modalTitle: 'timetable.frame.editTimetable',
            mode: 'fa-gears',
        };
        for (var i = 0; i <$scope.timetable.frames.length; ++i) {
            if ($scope.timetable.frames[i].id === $scope.model.id) {
                $scope.index = i;
            }
        }
    } else {
        $scope.index = $scope.timetable.frames.length;
    }

    userService.load().then(function(data) {
        $scope.users = data.users;
    });
    $scope.ok = function() {
        //validate();
        if (!$scope.model.id && !$scope.added){
            $scope.timetable.addFrame($scope.model);
            $scope.added = true;
        }

        timetableService.check($scope.timetable).then(function(result) {
            if (result.status === 400) {
                $scope.errors = new Errors(result.data);
                $scope.frameErrors = $scope.errors.getNestedErrors('frames[' + $scope.index + ']');
            } else {
                $uibModalInstance.close($scope.model);
            }

        });

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

});

module.controller('framesCopy', function($uibModalInstance, $scope, frames, timetableService) {
    $scope.frames = frames;

    function copyFrames() {
        var framesToCopy = [];
        for (var i = 0; i < $scope.frames.length; ++i) {
            if ($scope.frames[i].dateFrom.isAfter($scope.copyFrom) || $scope.frames[i].dateFrom.isSame($scope.copyFrom)) {
                framesToCopy.push($scope.frames[i]);
            }
        }
        for (i = 0; i < framesToCopy.length; ++i) {
            var frame = framesToCopy[i];
            var daysDiff = frame.dateFrom.diff(frame.dateTo, 'days');
            var weekDayFrom = frame.dateFrom.weekday();
            for (var m = moment($scope.dateFrom); m <= $scope.dateTo; m = moment(m).add('days', 7)) {
                var copy = timetableService.getFrameObject();
                copy.userId = frame.userId;
                copy.dateFrom = moment(frame.dateFrom);
                copy.dateTo = moment(frame.dateTo);
                copy.dateFrom.year(m.year());
                copy.dateFrom.month(m.month());
                copy.dateFrom.date(m.date());
                copy.dateFrom.weekday(weekDayFrom);
                copy.dateTo.year(m.year());
                copy.dateTo.month(m.month());
                copy.dateTo.date(m.date());
                copy.dateTo.weekday(weekDayFrom);
                if (daysDiff != 0) {
                    copy.dateTo.add(daysDiff, 'days');
                }
                $scope.frames.push(copy);
            }

        }
    }

    $scope.ui = {
        modalTitle: 'timetable.frame.copyTimetable',
        mode: 'fa-copy',
    };

    $scope.ok = function() {
        copyFrames();
        $uibModalInstance.close($scope.frames);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

});


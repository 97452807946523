var module = angular.module("meternet.dashboard.constants", []);

var DashboardEvents = {
        RESIZE: "dashboard.resize",
        REDRAW: "dashboard.redraw",
        SET_DATA_TIMESPAN: "dashboard.setDataTimespan",
        LOADING_DATA: "dashboard.loadingData"
};

module.constant("DashboardEvents", DashboardEvents);

var UnitScales = [{
        value: -9,
        label: "n"
    }, {
        value: -6,
        label: "µ"
    }, {
        value: -3,
        label: "m"
    }, {
        value: 0,
        label: "-"
    }, {
        value: 3,
        label: "k"
    }, {
        value: 6,
        label: "M"
    }, {
        value: 9,
        label: "G"
    }, {
        value: 12,
        label: "T"
}];

module.constant("UnitScales", UnitScales);

var TimeDerivativeScales = [{
        value: 0,
        label: "-"
    }, {
        value: 1000,
        label: "s"
    }, {
        value: 60 * 60 * 1000,
        label: "h"
    }];

module.constant("TimeDerivativeScales", TimeDerivativeScales);

var TimeUnits = [{
        value: 1000,
        label: "s",
        shortBundle: "timeUnit.short.second",
        bundle: "timeUnit.second"
    }, {
    	 value: 60*1000,
         label: "min",
         shortBundle: "timeUnit.short.minute",
         bundle: "timeUnit.minute"
    }, {
    	 value: 60*60*1000,
         label: "h",
         shortBundle: "timeUnit.short.hour",
         bundle: "timeUnit.hour"
    },
    {
   	 	value: 24*60*60*1000,
        label: "d",
        shortBundle: "timeUnit.short.day",
        bundle: "timeUnit.day"
   }];

module.constant("TimeUnits", TimeUnits);
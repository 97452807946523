<div class="form-group">
	<label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
	<ui-select ng-model="series._device" theme="bootstrap" ng-change="ui.updateSeriesParam(series)">
		<ui-select-match
			placeholder="&#xF002; {{'config.search'|i18n}}"
			popover-trigger="mouseenter"
			popover-placement="right"
			uib-popover="{{ui.deviceLabel($select.selected)}}">
			{{ui.deviceLabel($select.selected)}}
		</ui-select-match>
		<ui-select-choices repeat="device in devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}">
			<div ng-bind-html="ui.deviceLabel(device) | highlight: $select.search"></div>
			<div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
			<div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
			<div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
		</ui-select-choices>
	</ui-select>
</div>

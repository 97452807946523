<div class="container">

    <div class="panel panel-primary login-title">
        <div class="panel-body row login-center">
            <div class="col-xs-6 col-sm-8 text-center" x-ng-if="mainLicense=='FIF'" style="padding: 50px 0px">
                <img src="/resources/css/img/logo.png" class="login-img"/>
            </div>
            <div class="col-xs-6 col-sm-8 text-center" x-ng-if="mainLicense=='ETI'" style="padding: 50px 0px">
                <img src="/resources/css/img/logo.png" class="login-img"/>
            </div>
            <div class="col-xs-6 col-sm-8 text-center" x-ng-if="mainLicense=='EFEN'" style="padding: 50px 0px">
                <img src="/resources/css/img/logo.png" class="login-img"/>
            </div>
            <div class="col-xs-6 col-sm-8 text-center" x-ng-if="mainLicense=='BSH'" style="padding: 50px 0px">
                <img src="/resources/css/img/logo.png" class="login-img"/>
            </div>
            <div class="col-xs-6 col-sm-8 text-center" x-ng-if="mainLicense=='FINN'" style="padding: 50px 0px">
                <img src="/resources/css/img/logo_finn.png" class="login-img"/>
            </div>
            <div class="col-xs-6 col-sm-4" style="padding: 50px 50px 50px 0px">
				<div class="language-change" data-lang-selector="pl-PL,en-US,ru-RU">
				</div>
                <form method="post" action="/login">
                    <h2 class="form-heading login-h2">{{ 'menu.login.header' | i18n }}</h2>

                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-fw fa-user"></i></span>
                            <input type="text" name="username" class="form-control" placeholder="{{ 'menu.user' | i18n }}" required="required" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <span class="input-group-addon"><i class="fa fa-fw fa-key"></i></span>
                            <input type="password" name="password" class="form-control" placeholder="{{ 'menu.pass' | i18n }}" required="required" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox-inline">
                            <label>
                                <input name="remember-me" type="checkbox" value="1" checked="checked"/> {{ 'menu.rememberMe' | i18n }}
                            </label>
                        </div>
                        <button class="btn btn-large btn-primary pull-right" type="submit">{{ 'menu.login' | i18n }}</button>
                    </div>
                    <div class="form-group">
                        <div data-ng-if="message != null" class="alert alert-danger">{{message | i18n}}</div>
                    </div>
                </form>
            </div>
        </div>

        <div class="panel-footer" x-ng-if="mainLicense=='FIF'" style="text-align: center;">
            <img name="logoMini" class="login-imgff logoMini" src="/resources/css/img/ff.png"></img>
        </div>
        <div class="panel-footer" x-ng-if="mainLicense=='FINN'" style="text-align: center;">
            <img name="logoMini" class="login-imgff logoMini_finn" src="/resources/css/img/finn.png"></img>
        </div>
        <div class="panel-footer" x-ng-if="mainLicense=='ETI'" style="text-align: center;">
            <img name="logoMini" class="login-imgff logoMini_eti" src="/resources/css/img/eti.png"></img>
        </div>
        <div class="panel-footer" x-ng-if="mainLicense=='EFEN'" style="text-align: center;">
            <img name="logoMini" class="login-imgff logoMini_efen" src="/resources/css/img/efen.png"></img>
        </div>
        <div class="panel-footer" x-ng-if="mainLicense=='BSH'" style="text-align: center;">
            <img name="logoMini" class="login-imgff logoMini_bsh" src="/resources/css/img/bsh.png"></img>
        </div>
    </div>
</div>


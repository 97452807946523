var module = angular.module('meternet.chart.directives.costReport', ['meternet.charts.services']);

module.directive('costReport', function () {
  return {
    scope: {
      groups: '=',
      devices: '=',
      monthName: '=',
      onTotalWeekTime: '=',
      onTotalMonthTime: '='
    },
    templateUrl: 'dashboard/calendar/cost-report.html',
    controller: function ($scope) {
      $scope.groupDevices = [];
      $scope.groups.forEach(function (group) {
        $scope.devices.series.forEach(function (device) {
          var idx = _.findIndex(device.groups, function (o) {
            return o.id === group.id;
          });
          if (idx > -1) {
            $scope.groupDevices.push(device);
          }
          return false;
        })
      });
      $scope.ui = {
        getTotalWeek: function (group) {
          return group.load * $scope.onTotalWeekTime
        },
        getTotalMonth: function (group) {
          return group.load * $scope.onTotalMonthTime
        }
      };
    }
  }
});

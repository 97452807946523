var module = angular.module('meternet.charts.prepaid.services', []);

module.service('prepaidService', function($q, $http, contextPath){

    return {

    };

});


/*global tools */
var module = angular.module('meternet.license.services', []);

function LicenseService($rootScope, $q, $http, $log, contextPath, meternetConfig, license) {

    function LicenseConfig(data) {
        this.update(data);
    }

    LicenseConfig.prototype.update = function(data) {
        tools.copy(data, this);
        if (this.credits.current > this.credits.max) {
            this.alert = true;
            this.procent = 100;
        } else {
            this.alert = false;
            this.procent = (this.credits.current * 100) / this.credits.max;
        }
        $rootScope.$broadcast('meternet.license.update', this);
    };

    license = new LicenseConfig(license);

    return {

        getLicense : function() {
            return license;
        },

        test : function(config) {

            license.credits.current = 0;

            var devices = _.pluck(config.interfaces, 'deviceConfigs');
            devices = _.flatten(devices);

            _.forEach(devices, function(device) {
                license.credits.current += license.credits.costs[device.type];
            });

            license.credits.current += license.credits.costs.math * (config.maths ? config.maths.length : 0);

            license.credits.current += license.credits.costs.output * (config.outputs ? config.outputs.length : 0);
            if (license.credits.current > license.credits.max) {
                license.alert = true;
                license.procent = 100;
            } else {
                license.alert = false;
                license.procent = (license.credits.current * 100) / license.credits.max;
            }

        },
        get: function () {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: contextPath + '/data/license'
            }).success(function(data, status, headers) {
                license.update(data.features);
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        save: function (key, newModules) {
            var deferred = $q.defer();
            $http({
                method: 'POST',
                url: contextPath + '/data/license',
//                data: '"' + key + '"'
                data: '"' + key + ';' + newModules + '"'
//                data: {key: '"' + key + '"',
//                        newModules: newModules}
            }).success(function(data, status, headers) {
                license.update(data.features);
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        }
    };
}

module.service('licenseService', LicenseService);
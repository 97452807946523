<div class="row">
	<div x-device-selector="" class="col-md-3"></div>

	<div x-parameter-selector="" class="col-md-3"></div>

	<div class="form-group col-md-5">
		<label class="form-group-label" x-label-help="dashboard.widgets.config.series.name"></label>
		<input type="text"
			   class="form-control"
			   x-ng-model="series.name"/>
	</div>

	<div class="form-group col-md-1">
		<label class="form-group-label">&nbsp;</label>
		<button class="btn btn-danger"
				x-ng-click="ui.removeSerie($index);"
				title="{{'ui.delete' | i18n}}">
			<i class="fa fa-trash-o fa-lg"></i>
		</button>
	</div>
</div>

<div class="modal-header"><i class="fa fa-fw" x-ng-class="ui.mode"></i>&nbsp;{{ ui.modalTitle | i18n }}
    <button type="button" class="close" x-ng-click="cancel()" aria-hidden="true">×</button>
</div>
<div x-errors="frameErrors" x-errors-root="model">
    <div class="modal-body">
        <form name="frame" class="form-horizontal">
            <div class="form-group">
                <label class="control-label col-sm-4">{{'timetable.frame.user' | i18n}}:</label>
                <div class="col-sm-5">
                    <select class="form-control" data-ng-model="model.userId" data-ng-options="user.id as user.username for user in users" x-field-errors="">
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-4">{{'timetable.frame.dateFrom' | i18n}}:</label>
                <div class="col-sm-5">
                    <div class='input-group date'>
                        <input type='text' id="datetimepicker" class="form-control" x-ng-model="model.dateFrom" data-ng-change="ui.shiftDates()" x-datetimepicker="{moment:true, sideBySide:true, calendarWeeks: true}" x-field-errors=""/>
                        <span class="input-group-addon">
                            <span class="fa fa-calendar"></span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-4">{{'timetable.frame.dateTo' | i18n}}:</label>
                <div class="col-sm-5">
                    <div class='input-group date'>
                        <input type='text' class="form-control" x-ng-model="model.dateTo" x-datetimepicker="{moment:true, sideBySide:true, calendarWeeks: true}" x-field-errors=""/>
                        <span class="input-group-addon">
                            <span class="fa fa-calendar"></span>
                        </span>
                    </div>

                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" data-ng-click="ok()"><i class="fa fa-save fa-lg"></i>&nbsp;&nbsp;{{'timetable.frame.add' | i18n}}</button>
        <button class="btn btn-danger" data-ng-click="cancel()"><i class="fa fa-undo fa-lg"></i>&nbsp;&nbsp;{{'timetable.frame.cancel' | i18n}}</button>
    </div>
</div>
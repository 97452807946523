<form class="form-horizontal">
    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.network.ip' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="network.ip" name="networkip"
                       pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                       x-field-help="config.network.network.help">
            </div>
        </div>

    </div>
    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.network.mask' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="network.mask" name="mask"
                       pattern="^(((255\.){3}(255|254|252|248|240|224|192|128+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$"
                       x-field-help="config.network.mask.help">
            </div>
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.network.gateway' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="network.gateway" name="gateway"
                       pattern="^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
                       x-field-help="config.network.gateway.help">
            </div>
        </div>

    </div>
    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.network.dns' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="network.dns" name="dns"
                       x-field-help="config.network.dns.help">
            </div>
        </div>

    </div>

</form>
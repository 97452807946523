/* global tools, d3 */
var module = angular.module('meternet.reportTable.directives', [
    'meternet.services',
    'meternet.config.controllers',
    'meternet.filters',
    'ui.bootstrap.pagination',
    'meternet.report.directives.TableLineChartWidgetDirective'
]);

module.directive('reportTable', function ($timeout, $parse, i18nFilter, configService, dataService,
                                          localStorageService, Quantities, readoutService, dateFilter, csvService, unitFilter) {
    return {
        scope: {
            config: '='
        },
        templateUrl: 'report/table/report-table.html',
        controller: function ($scope, $element) {
            var lsKey = 'ui.report.table';

            function cleanup() {
                for (var id in $scope.unsubscribes) {
                    $scope.unsubscribes[id]();
                }
                $scope.unsubscribes = {};
            }

            function checkPhrases(phrases, row, columns) {
                var i, j, s, found;
                for (i = 0; i < phrases.length; ++i) {
                    found = false;
                    for (j = 0; j < columns.length; ++j) {
                        if (columns[j].searchable) {
                            s = row.values[j];
                            if (s && s.toLowerCase().indexOf(phrases[i]) >= 0) {
                                found = true;
                                break;
                            }
                        }
                    }
                    if (!found) {
                        return false;
                    }
                }
                return true;
            }

            function eachParam(config, handler) {

                function eachDevice(device, input, handler) {
                    _.each(device.params, function (param) {
                        handler(param, null, device, input, $scope.index++);
                    });
                    if (device.groups) {
                        _.each(device.groups, function (group) {
                            _.each(group.params, function (param) {
                                handler(param, group, device, input, $scope.index++);
                            });
                        });
                    }
                }

                _.each(config.engine.measurementInputs, function (input) {
                    _.each(input.devices, function (device) {
                        eachDevice(device, input, handler);
                    });
                });
                _.each(config.engine.moduleInputs, function (input) {
                    _.each(input.devices, function (device) {
                        if (device.type !== "energy-report"
                            && device.type !== "prepaid"
                            && device.type !== "alarm"
                            && device.type !== "control") {
                            eachDevice(device, input, handler);
                        }
                    });
                });
            }

            function buildTable(config, phrase, quantities, columns) {
                var table = {
                    rows: [],
                    map: {}
                };

                var phrases = phrase ? phrase.toLowerCase().match(/\S+/g) : null;

                $scope.index = 0;
                eachParam(config, function (param, group, device, input) {
                    var q = _.find(quantities, function (q) {
                        return q.type === param.quantity;
                    });

                    if (!q) {
                        return;
                    }
                    var row = {
                        index: $scope.index,
                        columns: columns,
                        device: device,
                        group: group,
                        param: param,
                        moment: null,
                        raw: null,
                        values: null,
                        update: function () {
                            this.values = [];
                            this.raw = [];
                            if (this.columns) {
                                for (var i = 0; i < this.columns.length; ++i) {
                                    var col = this.columns[i];
                                    var value = col.getter(this);
                                    this.values.push(value != null ? value : '');
                                    var rawValue = col.getterRaw(this);
                                    this.raw.push(rawValue != null ? rawValue : '');
                                }
                            }
                        }
                    };

                    row.update();

                    if (phrases && !checkPhrases(phrases, row, columns)) {
                        return;
                    }

                    table.rows.push(row);
                    table.map[row.param.id] = row;
                });

                return table;
            }

            function update() {
                cleanup();
                var table = buildTable($scope.config, $scope.search, $scope.quantities, $scope.columns);

                _.each(table.map, function (row, id) {
                    if ($scope.table && $scope.table.map[id] && $scope.table.map[id].moment) {
                        row.moment = $scope.table.map[id].moment;
                        row.update();
                    } else {
                        dataService.requestParamLastData(id).then(function (moment) {
                            row.moment = moment;
                            row.update();
                            renderTable();
                        });
                    }
                    if (!$scope.unsubscribes[id]) {
                        var unsubscribe = dataService.subscribeForParametersMeasurements(id, function (moment) {
                            row.updated = true;
                            row.moment = moment;
                            row.update();
                            if (row.updated) {
                                if (row.clearPromise) {
                                    $timeout.cancel(row.clearPromise);
                                }
                                row.clearPromise = $timeout(function () {
                                    row.updated = false;
                                    row.clearPromise = null;
									renderTable();
                                }, 1000);
                            }
                            renderTable();
                        });
                        $scope.unsubscribes[id]=unsubscribe;
                    }

                });

                $scope.table = table;
                renderTable();
            }
            function sortData(force) {
				var sort = Math.abs($scope.order) - 1;
				var dir = $scope.order > 0 ? 1 : -1;
				if (force || ($scope.columns[sort] && (!$scope.columns[sort].searchable && sort != 0))) {
					$scope.table.rows.sort(function (r1, r2) {
						var v1 = r1.raw[sort];
						var v2 = r2.raw[sort];
						if (v1 === v2) {
							return r2.index - r1.index;
						} else if (r1.columns[sort].numerical && !(!isNaN(parseFloat(v1)) && isFinite(v1))) {
							return dir;
						} else if (r1.columns[sort].numerical && !(!isNaN(parseFloat(v2)) && isFinite(v2))) {
							return -dir;
						} else if (v1 < v2) {
							return -dir;
						} else {
							return dir;
						}
					});
				}
			}

            function renderTable() {
                var i;
                var rows = [];
                var sort = Math.abs($scope.order) - 1;
                var dir = $scope.order > 0 ? 1 : -1;
                sortData();

                for (i = ($scope.page - 1) * $scope.limit; i < Math.min($scope.table.rows.length, $scope.page * $scope.limit); ++i) {
                    rows.push($scope.table.rows[i]);
                }

                var wsum = 0;
                for (i = 0; i < $scope.columns.length; ++i) {
                    wsum += $scope.columns[i].width;
                }

                var head = d3.select($element.find('table.report-table thead tr')[0]);
                head.style({
                    'background-color': 'rgb(219, 233, 246)'
                });

                var th = head.selectAll('th').data($scope.columns);

                th.enter().append('th').on('click.order', function (d, i) {
                    if ($scope.order === i + 1) {
                        $scope.order = -(i + 1);
                    } else {
                        $scope.order = i + 1;
                    }
                    $scope.page = 1;
                    $scope.$apply();
                    sortData(true);
                    renderTable();
                }).append('a');

                th.exit().remove();
                th.order();

                th.style({
                    'width': function (d) {
                        return (100 * d.width / wsum) + '%';
                    }
                }).classed({
                    'bold': function (d, i) {
                        return $scope.columns[i].bold ? true : null;
                    }
                }).select('a').html(function (d, i) {
                    if (i === sort) {
                        return d.label + (dir > 0 ? '&nbsp;<i class="fa fa-caret-down"></i>' : '&nbsp;<i class="fa fa-caret-up"></i>');
                    } else {
                        if ($scope.columns[i].btn) {
                            return "";
                        }else{
                            return d.label;
                        }
                    }
                });

                var body = d3.select($element.find('table.report-table tbody')[0]);
                var tr = body.selectAll('tr').data(rows, function (d) {
                    return d.param.id;
                });

                tr.enter().append('tr');
                tr.exit().remove();

                tr.classed({
                    'updated': function (d) {
                        return d.updated;
                    },
                    'error': function (d) {
						if (d.moment) {
							return d.moment.quality < 0;
						} else {
							return false;
						}
					},
                    'info': function (d) {
						if (d.moment) {
							return d.moment.quality > 0;
						} else {
							return false;
						}
					}
                });

                tr.order();

                var td = tr.selectAll('td').data(function (d) {
                    return d.values;
                });

                td.enter().append('td');
                td.exit().remove();

                td.style({
                    'text-align': function (d, i) {
                        return $scope.columns[i].numerical ? 'right' : null;
                    },
                    'white-space': function (d, i) {
                        return $scope.columns[i].numerical ? 'nowrap' : null;
                    }
                }).classed({
                    'bold': function (d, i) {
                        return $scope.columns[i].bold ? true : null;
                    }
                }).html(function (d, i) {
                    if ($scope.columns[i].btn) {
                        d3.select(this).on("click", function () { $scope.ui.setModalParam(d); });
                        return "<button type='button' class='btn btn-default btn-xs' data-toggle='modal' data-target='#myModal'><i class='fa fa-fw fa-bar-chart-o'></i></button>";
                    } else {
                        return d;
                    }
                });
            }

            function init() {
                configService.get().then(function (config) {
                    var used = {};
                    $scope.index = 0;
                    eachParam(config, function (param) {
                        used[param.quantity] = null;
                    });

                    $scope.ui.quantities = _.clone(_.filter(Quantities, function (quantity) {
                        return used.hasOwnProperty(quantity.type);
                    }));

                    _.each($scope.ui.quantities, function (q) {
                        q.label = i18nFilter('quantity.' + q.type);
                        q.selected = true;
                    });
                    $scope.ui.quantities = _.sortBy($scope.ui.quantities, 'label');

                    $scope.config = config;

                    load();
                    update();
                });
            }

            function store() {
                var i, c;
                var opt = {
                    quantities: [],
                    columns: [],
                    search: $scope.search
                };
                var quantities2 = [];
                _.each($scope.ui.quantities, function(q) {
					var qq = _.find($scope.quantities, function (q2) {
						return q.type === q2.type;
					});
					if (!qq) {
						quantities2.push(q);
					}
				});
                for (i = 0; i < quantities2.length; ++i) {
                    opt.quantities.push(quantities2[i].type);
                }
                for (i = 0; i < $scope.ui.columns.length; ++i) {
                    c = $scope.ui.columns[i];
                    if (c.selected) {
                        opt.columns.push(i);
                    }
                }
                localStorageService.set(lsKey, opt);
            }

            function load() {
                var i, a, b;
                var opt = localStorageService.get(lsKey);
                if (opt) {
                    $scope.search = opt.search;
                    for (i = 0; i < $scope.ui.quantities.length; ++i) {
                        $scope.ui.quantities[i].selected = true;
                    }
                    for (i = 0; i < opt.quantities.length; ++i) {
                        a = opt.quantities[i];
                        b = _.find($scope.ui.quantities, function (q) {
                            return q.type === a;
                        });
                        if (b) {
                            b.selected = false;
                        }
                    }
                    for (i = 0; i < $scope.ui.columns.length; ++i) {
                        $scope.ui.columns[i].selected = opt.columns.indexOf(i) >= 0;
                    }
                }
            }

            $scope.ui = {
				quantities: null,
				columns: [{
					label: i18nFilter('lp'),
					getter: $parse('index'),
					getterRaw: $parse('index'),
					searchable: false,
					selected: false,
					numerical: true,
					bold: false,
					width: 2
				}, {
					label: i18nFilter('report.table.deviceName'),
					getter: $parse('device.label || device.name'),
					getterRaw: $parse('device.label || device.name'),
					searchable: true,
					selected: true,
					numerical: false,
					bold: true,
					width: 10
				}, {
					label: i18nFilter('config.desc1'),
					getter: $parse('device.desc'),
					getterRaw: $parse('device.desc'),
					searchable: true,
					selected: false,
					numerical: false,
					bold: false,
					width: 10
				}, {
					label: i18nFilter('config.desc2'),
					getter: $parse('device.desc2'),
					getterRaw: $parse('device.desc2'),
					searchable: true,
					selected: false,
					numerical: false,
					bold: false,
					width: 10
				}, {
					label: i18nFilter('config.desc3'),
					getter: $parse('device.desc3'),
					getterRaw: $parse('device.desc3'),
					searchable: true,
					selected: false,
					numerical: false,
					bold: false,
					width: 10
				}, {
					label: i18nFilter('report.table.paramName'),
					getter: $parse('param.label || param.name'),
					getterRaw: $parse('param.label || param.name'),
					searchable: true,
					selected: true,
					numerical: false,
					bold: true,
					width: 10
				}, {
					label: i18nFilter('report.table.paramDesc'),
					getter: $parse('param.desc'),
					getterRaw: $parse('param.desc'),
					searchable: true,
					selected: false,
					numerical: false,
					bold: false,
					width: 10
				}, {
					id: 'value',
					label: i18nFilter('report.table.value'),
					getter: $parse('moment.good | measurement'),
					getterRaw: $parse('moment.good.value'),
					searchable: false,
					selected: true,
					numerical: true,
					bold: true,
					width: 5
				}, {
					label: i18nFilter('report.table.quality'),
					getter: $parse('moment.quality'),
					getterRaw: $parse('moment.quality'),
					searchable: false,
					selected: false,
					numerical: true,
					bold: false,
					width: 5
				}, {
					label: i18nFilter('report.table.errPercentage'),
					getter: $parse('moment.errorCount / moment.readCount | percent'),
					getterRaw: $parse('moment.errorCount / moment.readCount'),
					searchable: false,
					selected: false,
					numerical: true,
					bold: false,
					width: 5
				}, {
					id: 'timestamp',
					label: i18nFilter('report.table.timestamp'),
					getter: $parse('moment.good.timestamp | date:\'yyyy-MM-dd HH:mm:ss\''),
					getterRaw: $parse('moment.good.timestamp | date2number'),
					searchable: false,
					selected: true,
					numerical: true,
					bold: false,
					width: 5
				}, {
					label: i18nFilter('report.table.chart'),
					getter: function (row) {
						return row;
					},
					getterRaw: function (row) {
						return row;
					},
					searchable: false,
					selected: true,
					numerical: false,
					bold: false,
					btn: true,
					width: 0
				}],
				quantityTranslations: {
					selectAll: i18nFilter('ui.tick.all'),
					selectNone: i18nFilter('ui.tick.none'),
					reset: i18nFilter('ui.reset'),
					search: i18nFilter('ui.search'),
					nothingSelected: i18nFilter('report.table.select.type')
				},
				columnTranslations: {
					selectAll: i18nFilter('ui.tick.all'),
					selectNone: i18nFilter('ui.tick.none'),
					reset: i18nFilter('ui.reset'),
					search: i18nFilter('ui.search'),
					nothingSelected: i18nFilter('report.table.select.column')
				},
				isRunning: false,
				start: function () {
					$scope.ui.isStarting = true;
					readoutService.start().then(function (status) {
						if (status) {
							$scope.ui.isRunning = true;
						}
					});
				},
				stop: function () {
					readoutService.stop().then(function (status) {
						if (status) {
							$scope.ui.isRunning = false;
						}
					});
				},
				oneShoot: function () {
					$scope.ui.isOneShoot = true;
					_.forEach($scope.table.rows, function (row) {
						if (row.device.model !== "machine" && row.device.model !== "math") {
							row.moment.quality = 1;
						}
					});

					readoutService.oneShoot().then(function (status) {
						if (status) {
							$scope.ui.isRunning = false;
						}
					});
				},
				status: function () {
					readoutService.isRunning().then(function (status) {
						$scope.ui.isRunning = status;
					});
				},
				showModal: false,
				row: null,
				historyTime: 1,
				setModalParam: function (row) {
					$scope.ui.row = row;
					_.delay(function () {
						$scope.ui.showModal = true;
					}, 1000);
				},
				changeHistoryTime: function (ht) {
					$scope.ui.historyTime = ht;
					$scope.ui.showModal = false;
					_.delay(function () {
						$scope.ui.showModal = true;
					}, 1000);
				}
			};

            $('#myModal').on('hidden.bs.modal', function () {
                $scope.ui.showModal=false;
            });

            $scope.unsubscribes = {};
            $scope.columns = [];
            $scope.quantities = [];
            $scope.order = 1;
            $scope.page = 1;
            $scope.limit = 100;
            $scope.ui.status();

            $scope.$on('$destroy', cleanup);

            init();

            var updateDelayed = _.debounce(update, 100, true);
            //var renderDelayed = _.debounce(renderTable, 100);

            $scope.$watch('columns', function (nval, oval) {
                if (nval !== oval) {
                    updateDelayed();
                    store();
                    renderTable();
                }
            });
            $scope.$watch('quantities', function (nval, oval) {
                if (nval !== oval) {
                    $scope.page = 1;
                    updateDelayed();
                    store();
                }
            });
            $scope.$watch('search', function (nval, oval) {
                if (nval !== oval) {
                    $scope.page = 1;
                    updateDelayed();
                    store();
                }
            });
            $scope.$watch('page', function (nval, oval) {
                if (nval !== oval) {
                    updateDelayed();
                }
            });

            var generateCsv = function (rows) {
				var csv = csvService.csv();
				_.each($scope.ui.columns, function (c) {
					if (c.selected) {
						csv.field(c.label);
						if (c.id === 'value') {
							csv.field(i18nFilter('report.table.unit'));
						}
					}
				});

				csv.endLine();

				for (var r = 0; r < rows.length; ++r) {
					var row = rows[r];
					_.each(row.columns, function (c, i) {
						if (c.selected) {
							if (c.id === 'value') {
								if (!row.values && !(row.values instanceof Array)) {
									csv.field('');
								} else if (row.values[i] === "---") {
									csv.field('');
								} else {
									csv.field(unitFilter(row.raw[i], row.param.precision, '',  row.param.scale).replace(',', '.').replace(' ', ''));
								}
								csv.field(unitFilter(1, -1, row.param.unit, row.param.scale));
							} else {
								csv.field(row.values[i]);
							}
						}
					});

					csv.endLine();
				}
				return csv;
			};

            $scope.downloadCsv = function () {
                var filename = i18nFilter('report.history.csvFileName') + '-' + dateFilter(new Date(), 'yyyy-MM-dd') + ".csv";
                var csv = generateCsv($scope.table.rows);
                csv.download(filename);
            };

            $scope.generateXlsx = function(rows, table) {
				var data = [];
				for (var r = 0; r < rows.length; ++r) {
					var row = rows[r];
					var ro = {};
					var valueNum = 0;
					if ($scope.ui.columns[0].selected) {
						ro[i18nFilter('lp')] = r + 1;
						valueNum++;
					}
					if ($scope.ui.columns[1].selected) {
						ro[i18nFilter('report.table.deviceName')] = row.device.label || row.device.name;
						valueNum++;
					}
//                    if($scope.ui.columns[2].selected){
//                        ro[i18nFilter('report.table.groupName')] = row.group ? (row.group.label || row.group.name) : "---";
//                        valueNum++;
//                    }
					if ($scope.ui.columns[2].selected) {
						ro[i18nFilter('config.desc1')] = row.device.desc;
						valueNum++;
					}
					if ($scope.ui.columns[3].selected) {
						ro[i18nFilter('config.desc2')] = row.device.desc2;
						valueNum++;
					}
					if ($scope.ui.columns[4].selected) {
						ro[i18nFilter('config.desc3')] = row.device.desc3;
						valueNum++;
					}
					if ($scope.ui.columns[5].selected) {
						ro[i18nFilter('report.table.paramName')] = row.param.label || row.param.name;
						valueNum++;
					}
					if ($scope.ui.columns[6].selected) {
						ro[i18nFilter('report.table.paramDesc')] = row.param.desc;
						valueNum++;
					}
					if ($scope.ui.columns[7].selected) {
						if (!row.values && !(row.values instanceof Array)) {
							ro[i18nFilter('report.table.value')] = i18nFilter('report.table.nodata');
							ro[i18nFilter('report.table.unit')] = '';
						} else if (row.values[valueNum] == "---") {
							ro[i18nFilter('report.table.value')] = i18nFilter('report.table.error');
							ro[i18nFilter('report.table.unit')] = '';
						} else {
							ro[i18nFilter('report.table.value')] = row.raw[valueNum] / Math.pow(10, row.param.scale);
							ro[i18nFilter('report.table.unit')] = unitFilter(1, -1, row.param.unit, row.param.scale);
						}
						valueNum++;
					}
					if ($scope.ui.columns[8].selected) {
						ro[i18nFilter('report.table.quality')] = row.values[valueNum];//row.quality;
						valueNum++;
					}
					if ($scope.ui.columns[9].selected) {
						ro[i18nFilter('report.table.errPercentage')] = row.values[valueNum];
						valueNum++;
					}

					if ($scope.ui.columns[10].selected) {
						ro[i18nFilter('report.table.timestamp')] = row.values[valueNum];
						valueNum++;
					}
					data.push(ro);
				}
				return data;
			};

            $scope.downloadXlsx = function () {
                var filename = i18nFilter('report.history.csvFileName') + '-' + dateFilter(new Date(), 'yyyy-MM-dd') + ".xlsx";
                var data= $scope.generateXlsx($scope.table.rows, $scope.table);
                var ws = XLSX.utils.json_to_sheet(data);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, dateFilter($scope.timestamp, 'yyyy-MM-dd'));
                XLSX.writeFile(wb, filename);
            };
            $scope.$on('engine-started', function(event, args) {
                $scope.ui.isStarting = false;
            });
            $scope.$on('engine-oneshootfinish', function(event, args) {
                $scope.ui.isOneShoot = false;
            });
        }
    };
});


<div id="status-tooltip" class="popover right in fade">
    <div class="arrow"></div>
    <div class="popover-inner">
        <div class="popover-title">
            <i class="fa fa-fw" x-ng-class="item.icon()"></i>
            <span>{{item.name}}</span>
        </div>
        <div class="popover-content">
            <table class="table table-condensed">
                <tbody>
                    <tr><td>Stan:</td><td>Ok</td></tr>
                    <tr><td>Data odczytu:</td><td>2015-01-01 23:23:34.342</td></tr>
                    <tr><td>Data odczytu:</td><td>2015-01-01 23:23:34.342</td></tr>
                    <tr><td>Data odczytu:</td><td>2015-01-01 23:23:34.342</td></tr>
                    <tr><td>Liczba błędów:</td><td>0</td></tr>
                </tbody>
            </table>
            <div x-ng-switch="item.kind">
                <div x-ng-switch-when="input">
                    <div x-status-input="" x-stauts="item.status" x-config="item.config"></div>
                </div>
                <div x-ng-switch-when="device">
                    <div x-status-device="" x-status="item.status" x-config="item.config"></div>
                </div>
                <div x-ng-switch-when="parameter">
                </div>
            </div>
        </div>
    </div>
</div>
var module = angular.module('meternet.services', [
	'meternet.status.services',
	'meternet.config.services',
	'meternet.stomp.services',
	'meternet.data.services',
	'meternet.charts.services',
	'meternet.calendar.services',
	'meternet.dashboard.services',
	'meternet.license.services',
	'meternet.event.services',
	'meternet.eventack.services',
	'meternet.user.services',
	'meternet.about.services',
	'meternet.fileManager.services',
	'meternet.measurements.services',
	'meternet.report.services',
	'meternet.simulations.services',
	'meternet.csv.services',
    'meternet.timetable.services',
    'meternet.scores.services',
    'meternet.readout.services'
]);

module.service('Weekdays', function ($locale) {
	var weekedays = [];
	for (var i = 1; i < $locale.DATETIME_FORMATS.DAY.length; i++) {
		weekedays.push({value: i + 1, label: $locale.DATETIME_FORMATS.DAY[i]});
	}
	weekedays.push({value: 1, label: $locale.DATETIME_FORMATS.DAY[0]});
	return weekedays;
});

module.service('Months', function ($locale) {
	var months = [];
	for (var i = 0; i < $locale.DATETIME_FORMATS.MONTH.length; i++) {
		months.push({value: i + 1, label: $locale.DATETIME_FORMATS.MONTH[i]});
	}
	return months;
});


<div class="row" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-xs-12">
        <div class="form-group">
            <label class="form-group-label">{{'config.name' | i18n}}</label>
            <div class="col-sm-12 input-group">
                <input class="form-control"
                       x-ng-change="ui.updateNotifie(item.config)"
                       x-ng-model="item.config.name"
                       x-field-help="config.rule.name.help"
                       x-field-errors="'item.config.name'"/>
            </div>
        </div>
        <div class="form-group">
            <label class="form-group-label">{{'config.controlRules.outputName' | i18n}}</label>
            <div class="col-sm-12 input-group">
                <select class="form-control"
                        x-ng-change="ui.updateNotifie(item.config)"
                        x-ng-model="item.config.positive.actions[0].paramId"
                        x-field-help="config.parameter.outParam.help"
                        x-ng-options="p.id as p.name for p in getWritableParams()"
                        x-field-errors=""></select>
            </div>
        </div>
    </div>
    <div class="col-xs-12">
        <div class="col-xs-6">
            <svg width="200" height="100" x-ng-if="!item.config.updown">
                <defs>
                    <marker id="arrow" markerWidth="10" markerHeight="10" refX="4" refY="2" orient="auto-start-reverse"
                            markerUnits="strokeWidth">
                        <path d="M0,0 L0,4 L5,2" fill="#000"/>
                    </marker>
                </defs>
                <line x1="0" y1="50" x2="200" y2="50" stroke="#000" stroke-width="2" marker-end="url(#arrow)"/>

                <text x="50" y="90" fill="green">{{'config.momentRule.control.on' | i18n}}</text>
                <text x="50" y="60" fill="green">{{ui.unitFilterFun(item.config.value, item.config)}}</text>
                <line x1="0" y1="75" x2="130" y2="75" stroke="#0f0" stroke-width="2" marker-end="url(#arrow)"/>
                <line x1="70" y1="25" x2="70" y2="75" stroke="#0f0" stroke-width="2" marker-end="url(#arrow)"/>

                <text x="110" y="20" fill="red">{{'config.momentRule.control.off' | i18n}}</text>
                <text x="110" y="60" fill="red">{{ui.unitFilterFun(item.config.value+item.config.hysteresis, item.config)}}</text>
                <line x1="70" y1="25" x2="200" y2="25" stroke="#f00" stroke-width="2" marker-start="url(#arrow)"/>
                <line x1="130" y1="75" x2="130" y2="25" stroke="#f00" stroke-width="2" marker-end="url(#arrow)"/>
            </svg>
            <svg width="200" height="100" x-ng-if="item.config.updown">
                <defs>
                    <marker id="arrow2" markerWidth="10" markerHeight="10" refX="4" refY="2" orient="auto-start-reverse"
                            markerUnits="strokeWidth">
                        <path d="M0,0 L0,4 L5,2" fill="#000"/>
                    </marker>
                </defs>
                <line x1="0" y1="50" x2="200" y2="50" stroke="#000" stroke-width="2" marker-end="url(#arrow2)"/>

                <text x="50" y="90" fill="red">{{'config.momentRule.control.off' | i18n}}</text>
                <text x="50" y="60" fill="red">{{ui.unitFilterFun(item.config.value-item.config.hysteresis, item.config)}}</text>
                <line x1="0" y1="75" x2="130" y2="75" stroke="#f00" stroke-width="2" marker-end="url(#arrow2)"/>
                <line x1="70" y1="25" x2="70" y2="75" stroke="#f00" stroke-width="2" marker-end="url(#arrow2)"/>

                <text x="110" y="20" fill="green">{{'config.momentRule.control.on' | i18n}}</text>
                <text x="110" y="60" fill="green">{{ui.unitFilterFun(item.config.value, item.config)}}</text>
                <line x1="70" y1="25" x2="200" y2="25" stroke="#0f0" stroke-width="2" marker-start="url(#arrow2)"/>
                <line x1="130" y1="75" x2="130" y2="25" stroke="#0f0" stroke-width="2" marker-end="url(#arrow2)"/>
            </svg>
        </div>
        <div class="col-xs-6">
            <div class="form-group">
                <label class="form-group-label">{{'config.momentRule.direction' | i18n}}</label>
                <div class="col-sm-12 input-group">
                    <select class="form-control"
                            x-ng-change="ui.updateExpresion(item.config)"
                            x-ng-model="item.config.updown"
                            x-field-help="config.rule.direction.help"
                            x-ng-options="n.value as n.name for n in hysteresisDir"
                            x-field-errors="'item.config.actions[0].notifierId'">
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label class="form-group-label">{{'config.momentRule.alarm.value' | i18n}}</label>
                <div class="col-sm-10 input-group">
                    <input class="form-control"
                           placeholder="{{'config.momentRule.alarm.value' | i18n }}"
                           x-ng-model="item.config.value"
                           data-scale-formatter=""
                           data-format-scale="item.config.scale"
                           data-format-precision="item.config.precision"
                           x-required=""
                           x-ng-change="ui.updateExpresion(item.config)"
                           x-field-errors="'item.config.actions[0].message'"/>
                    <data-unit-addon data-unit-addon-scale="item.config.scale" data-unit-addon-unit = item.config.unit></data-unit-addon>

                </div>
                <button class="btn btn-primary col-sm-2 pull-right" type="button" uib-popover="{{'config.rule.notifier.value.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="bottom">?</button>
            </div>
            <div class="form-group">
                <label class="form-group-label">{{'config.momentRule.alarm.hysteresis' | i18n}}</label>
                <div class="col-sm-10 input-group">
                    <input class="form-control"
                           placeholder="{{'config.momentRule.alarm.hysteresis' | i18n }}"
                           x-ng-model="item.config.hysteresis"
                           data-scale-formatter=""
                           data-format-scale="item.config.scale"
                           data-format-precision="item.config.precision"
                           x-required=""
                           x-ng-change="ui.updateExpresion(item.config)"
                           x-field-errors="'item.config.actions[0].message'"/>
                    <data-unit-addon data-unit-addon-scale="item.config.scale" data-unit-addon-unit = item.config.unit></data-unit-addon>

                </div>
                <button class="btn btn-primary col-sm-2 pull-right" type="button" uib-popover="{{'config.rule.notifier.hysteresis.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="bottom">?</button>
            </div>
        </div>
    </div>
    <div class="col-xs-6 list-group-item-success" style="padding: 10px">
        <!--<h4>{{'config.momentRule.notifier.negative.title' | i18n}}</h4>-->
        <div class="form-group">
            <label class="form-group-label">{{'config.momentRule.notifier.paramValue.true' | i18n}}</label>
            <div class="col-sm-12 input-group">
                <input class="form-control"
                       x-ng-disabled="type!=='outputbinary'"
                       x-ng-change="ui.updateNotifie(item.config)"
                       x-ng-model="item.config.negative.actions[0].paramValue"
                       x-field-help="config.rule.notifier.paramValue.help"
                       x-field-errors="'item.config.negative.actions[0].paramValue'"/>
            </div>
        </div>
    </div>
    <div class="col-xs-6 list-group-item-danger" style="padding: 10px" x-ng-if="type!=='outputbinary'">
        <!--<h4>{{'config.momentRule.notifier.positive.title' | i18n}}</h4>-->
        <div class="form-group">
            <label class="form-group-label">{{'config.momentRule.notifier.paramValue.false' | i18n}}</label>
            <div class="col-sm-12 input-group">
                <input class="form-control"
                       x-ng-disabled="type!=='outputbinary'"
                       x-ng-change="ui.updateNotifie(item.config)"
                       x-ng-model="item.config.positive.actions[0].paramValue"
                       x-field-help="config.rule.notifier.paramValue.help"
                       x-field-errors="'item.config.positive.actions[0].paramValue'"/>
            </div>
        </div>
    </div>

</div>


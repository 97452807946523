<div class="modal-header">
    <button type="button" class="close" x-ng-click="closeDialog()" aria-hidden="true">
        &times;
    </button>
    <h4 class="modal-title">{{"widget.edit.addWidget" | i18n}}</h4>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-xs-4 col-md-2" x-ng-repeat="(key, widget) in (widgets | licensedWidgets)">
            <a href="#" x-ng-click="addWidget(key)" class="thumbnail">
                <div style="height: 80px;">
                    <img x-ng-src="{{widget.icon}}" style="width: 100%;" alt="{{widget.title}}"/>
                </div>
                <h5 style="height: 20px; text-align: center;">{{widget.title}}</h5>
            </a>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" ng-click="closeDialog()">{{"widget.edit.close" | i18n}}</button>
</div>
var module = angular.module('meternet.dashboard.controllers.digitalGaugeWidget', [
		'adf.provider',
		'i18n',
		'meternet.dashboard.controllers.gauge'
]);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/gauge-widget.html',
        reload : false,
        title : messages['dashboard.widgets.digital-gauge.title'],
        description : messages['dashboard.widgets.digital-gauge.desc'],
        controller : 'gaugeWidgetCtrl',
        screenshot: true,
        config : {
            type : "digital-gauge",
            label : "new",
            title : "new",
            series : [{
                paramId: null,
                average: 1,
                derivative: 0,
                unit: "",
                majorTicks: 5,
                minorTicks: 3,
                max: 100,
                min: 0,
                precision: 2,
                scale: 0,
                zones: [ {
                    "color" : "#5cb85c",
                    from : 0,
                    to : 50
                }, {
                    "color" : "#f0ad4e",
                    from : 50,
                    to : 75
                }, {
                    "color" : "#d9534f",
                    from : 75,
                    to : 100
                }]
            }]
        },
        edit : {
            controller : 'gaugeWidgetEditCtrl',
            templateUrl: 'dashboard/gauge-widget-edit.html',
            resolve: {
                maxSeries : function(){
                    return 1;
                }
            }
        }
    };
    dashboardProvider.widget('digital-gauge', widget);
});

/**
 * Created by Karol on 2015-04-29.
 */
/* globals tools */
var module = angular.module('meternet.chart.directives.tableGauge', []);

module.directive('tableGauge', function(DashboardEvents, $timeout, $parse, UnitScales, unitFilter, configService, i18nFilter, dataService, dateFilter, csvService) {
return {
    scope: {
         options : '=',
          data : '='
    },
    templateUrl: 'dashboard/table-gauge.html',
    controller: function ($scope, $element) {

    var renderDelayed = _.debounce(renderTable, 100);

    $scope.table = {
        rows: []
    }
    $scope.columns = [];
    $scope.page = 1;
    $scope.limit = 100;
    $scope.unsubscribes = []


    $scope.$on('generateCsv', function (event) {
        var filename = i18nFilter('report.history.csvFileName') + '-' + dateFilter($scope.timestamp, 'yyyy-MM-dd') + ".csv";
        var csv = generateCsv($scope.table.rows);
        csv.download(filename);
    });

    $scope.$on('generateXlsx', function (event) {
        var filename = i18nFilter('report.history.csvFileName') + '-' + dateFilter($scope.timestamp, 'yyyy-MM-dd') + ".xlsx";
        var data= $scope.generateXlsx($scope.table.rows, $scope.table);
        var ws = XLSX.utils.json_to_sheet(data);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, dateFilter($scope.timestamp, 'yyyy-MM-dd'));
        XLSX.writeFile(wb, filename);
    });

    var generateCsv = function (rows) {
        var csv = csvService.csv();
        if($scope.options.columns[0]){
            csv.field(i18nFilter('lp'));
        }
        if($scope.options.columns[1]){
            csv.field(i18nFilter('report.table.deviceName'));
        }
        if($scope.options.columns[2]){
            csv.field(i18nFilter('report.table.reportIdent'));
        }
        if($scope.options.columns[3]){
            csv.field(i18nFilter('config.desc1'));
        }
        if($scope.options.columns[4]){
            csv.field(i18nFilter('config.desc2'));
        }
        if($scope.options.columns[5]){
            csv.field(i18nFilter('config.desc3'));
        }
        if($scope.options.columns[6]){
            csv.field(i18nFilter('report.table.paramName'));
        }
        if($scope.options.columns[7]){
            csv.field(i18nFilter('report.table.paramDesc'));
        }
        if($scope.options.columns[8]){
            csv.field(i18nFilter('report.table.value'));
            csv.field(i18nFilter('report.table.unit'));
        }
        if($scope.options.columns[9]){
            csv.field(i18nFilter('report.table.quality'));
        }
        if($scope.options.columns[10]){
            csv.field(i18nFilter('report.table.errPercentage'));
        }
        if($scope.options.columns[11]){
            csv.field(i18nFilter('report.table.timestamp'));
        }

        csv.endLine();

        for (var r = 0; r < rows.length; ++r) {
            var row = rows[r];
            var valueNum = 0;
            if($scope.options.columns[0]){
                csv.field(r + 1);
                valueNum++;
            }
            if($scope.options.columns[1]){
                csv.field(row.device.label || row.device.name);
                valueNum++;
            }
            if($scope.options.columns[2]){
                csv.field(row.name);
            }
            if($scope.options.columns[3]){
                csv.field(row.device.desc);
                valueNum++;
            }
            if($scope.options.columns[4]){
                csv.field(row.device.desc2);
                valueNum++;
            }
            if($scope.options.columns[5]){
                csv.field(row.device.desc3);
                valueNum++;
            }
            if($scope.options.columns[6]){
                csv.field(row.param.label || row.param.name);
                valueNum++;
            }
            if($scope.options.columns[7]){
                csv.field(row.param.desc);
                valueNum++;
            }
            if($scope.options.columns[8]){
                if (!row.values && !(row.values instanceof Array) ) {
                    csv.field(i18nFilter('report.table.nodata'));
                    csv.field('');
                } else if (row.values[valueNum] == "---") {

                    csv.field(i18nFilter('report.table.error'));
                    csv.field('');
                } else {
                    csv.field(row.raw[valueNum] / Math.pow(10, row.param.scale));
                    csv.field(unitFilter(1, -1, row.param.unit, row.param.scale));
                }
                valueNum++;
            }
            if($scope.options.columns[9]){
                $parse('moment.quality | number:0')
                csv.field(row.quality);
                valueNum++;
            }
            if($scope.options.columns[10]){
                csv.field(row.values[valueNum]);
                valueNum++;
            }

            if($scope.options.columns[11]){
                csv.field(row.values[valueNum]);
                valueNum++;
            }
            csv.endLine();
        }
        return csv;
    };

    $scope.generateXlsx = function(rows, table){
        var data = [];
        for (var r = 0; r < rows.length; ++r) {
            var row = rows[r];
            var ro = {};
            var valueNum = 0;
            if($scope.options.columns[0]){
                ro[i18nFilter('lp')] = r + 1;
                valueNum++;
            }
            if($scope.options.columns[1]){
                ro[i18nFilter('report.table.deviceName')] = row.device.label || row.device.name;
                valueNum++;
            }
            if($scope.options.columns[2]){
                ro[i18nFilter('report.table.reportIdent')] = row.name;
                valueNum++;
            }
            if($scope.options.columns[3]){
                ro[i18nFilter('config.desc1')] = row.device.desc;
                valueNum++;
            }
            if($scope.options.columns[4]){
                ro[i18nFilter('config.desc2')] = row.device.desc2;
                valueNum++;
            }
            if($scope.options.columns[5]){
                ro[i18nFilter('config.desc3')] = row.device.desc3;
                valueNum++;
            }
            if($scope.options.columns[6]){
                ro[i18nFilter('report.table.paramName')] = row.param.label || row.param.name;
                valueNum++;
            }
            if($scope.options.columns[7]){
                ro[i18nFilter('report.table.paramDesc')] = row.param.desc;
                valueNum++;
            }
            if($scope.options.columns[8]){
                if (!row.values && !(row.values instanceof Array) ) {
                    ro[i18nFilter('report.table.value')] = i18nFilter('report.table.nodata');
                    ro[i18nFilter('report.table.unit')] = '';
                } else if (row.values[valueNum] == "---") {
                    ro[i18nFilter('report.table.value')] = i18nFilter('report.table.error');
                    ro[i18nFilter('report.table.unit')] = '';
                } else {
                    ro[i18nFilter('report.table.value')] = row.raw[valueNum] / Math.pow(10, row.param.scale);
                    ro[i18nFilter('report.table.unit')] = unitFilter(1, -1, row.param.unit, row.param.scale);
                }
                valueNum++;
            }
            if($scope.options.columns[9]){
                ro[i18nFilter('report.table.quality')] = row.values[valueNum];//row.quality;
                valueNum++;
            }
            if($scope.options.columns[10]){
                ro[i18nFilter('report.table.errPercentage')] = row.values[valueNum];
                valueNum++;
            }

            if($scope.options.columns[11]){
                ro[i18nFilter('report.table.timestamp')] = row.values[valueNum];
                valueNum++;
            }
            data.push(ro)
        }
        return data;
    }


    var index = 0;
    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));

        var params = _.flatten(_.pluck(devices, 'params', true));
        _.forEach($scope.options.series, function(serie){
            var param = _.findWhere(params, {id: serie.paramId})
            var device = _.findWhere(devices, {id: serie.deviceId})

            var row = {
                index: index++,
//                    columns: columns,
                name: serie.name,
                device: device,
                param: param,
                moment: null,
                values: null,
                raw: null,
                update: function () {
                    this.values = [];
                    this.raw = [];
                    if ($scope.columns) {
                        for (var i = 0; i < $scope.columns.length; ++i) {
                            var col = $scope.columns[i];
                            var value = col.getter(this);
                            this.values.push(value != null ? value : '');
                            var rawValue = col.getterRaw(this);
                            this.raw.push(rawValue != null ? rawValue : '');
                        }
                    }
                }
            };

            row.update();

            $scope.table.rows.push(row);
//            $scope.table.map[row.param.id] = row;
        });

        _.each($scope.table.rows, function (row, id) {
            if (row.param){
                if ($scope.table && $scope.table.rows[$scope.table.rows[id].param.id] && $scope.table.rows[id].moment) {
                    row.moment = $scope.table.rows[id].moment;
                    row.update();
                } else {
                    dataService.requestParamLastData($scope.table.rows[id].param.id).then(function (moment) {
                        row.moment = moment;
                        row.update();
                        renderTable();
                    });
                }
                if (!$scope.unsubscribes[id]) {
                    var unsubscribe = dataService.subscribeForParametersMeasurements($scope.table.rows[id].param.id, function (moment) {
                        row.updated = true;
                        row.moment = moment;
                        row.update();
                        if (row.updated) {
                            if (row.clearPromise) {
                                $timeout.cancel(row.clearPromise);
                            }
                            row.clearPromise = $timeout(function () {
                                row.updated = false;
                                row.clearPromise = null;
                                renderDelayed();
                            }, 1000);
                        }
                        renderTable();
                    });
                    $scope.unsubscribes[id]=unsubscribe;
                }
            }
        });

        renderTable();

    });

    function renderTable() {
        var i;
        var rows = [];

        var sort = Math.abs($scope.order) - 1;
        var dir = $scope.order > 0 ? 1 : -1;
        $scope.table.rows.sort(function (r1, r2) {
            var v1 = r1.values[sort];
            var v2 = r2.values[sort];
            if (v1 === v2) {
                return r2.index - r1.index;
            } else if (v1 < v2) {
                return -dir;
            } else {
                return dir;
            }
        });


        for (i = ($scope.page - 1) * $scope.limit; i < Math.min($scope.table.rows.length, $scope.page * $scope.limit); ++i) {
            rows.unshift($scope.table.rows[i]);
        }

        var wsum = 0;
        for (i = 0; i < $scope.columns.length; ++i) {
            wsum += $scope.columns[i].width;
        }

        var head = d3.select($element.find('table.report-table thead tr')[0]);
        head.style({
            'background-color': 'rgb(219, 233, 246)'
        })
        var th = head.selectAll('th').data($scope.columns);

        th.enter().append('th').on('click.order', function (d, i) {
            if ($scope.order === i + 1) {
                $scope.order = -(i + 1);
            } else {
                $scope.order = i + 1;
            }
            $scope.page = 1;
            $scope.$apply();
            renderTable();
        }).append('a');

        th.exit().remove();
        th.order();

        th.style({
            'width': function (d) {
                return (100 * d.width / wsum) + '%';
            }
        }).classed({
            'bold': function (d, i) {
                return $scope.columns[i].bold ? 'bold' : null;
            }
        }).select('a').html(function (d, i) {
            if (i === sort) {
                return d.label + (dir > 0 ? '&nbsp;<i class="fa fa-caret-down"></i>' : '&nbsp;<i class="fa fa-caret-up"></i>');
            } else {
                return d.label;
            }
        });

        var body = d3.select($element.find('table.report-table tbody')[0]);
        var tr = body.selectAll('tr').data(rows, function (d) {
            return d.param.id;
        });

        tr.enter().append('tr');
//                tr.exit().remove();

        tr.classed({
            'updated': function (d) {
                return d.updated;
            },
            'error': function (d) {
                if(d.moment){
                    return d.moment.quality<0;
                }else{
                    return false
                }
            },
            'info': function (d) {
                if(d.moment){
                    return d.moment.quality>0;
                }else{
                    return false
                }
            }
        });
        tr.order();

        var td = tr.selectAll('td').data(function (d) {
            return d.values;
        });

        td.enter().append('td');
        td.exit().remove();

        td.text(function (d) {
            return d;
        }).style({
            'text-align': function (d, i) {
                if($scope.columns[i]){
                    return $scope.columns[i].numerical ? 'right' : null;
                }else{
                    return null;
                }
            },
            'white-space': function (d, i) {
                if($scope.columns[i]){
                    return $scope.columns[i].numerical ? 'nowrap' : null;
                }else{
                    return null;
                }
            }
        }).classed({
            'bold': function (d, i) {
                if($scope.columns[i]){
                    return $scope.columns[i].bold ? true : null;
                }else{
                    return null;
                }
            }
        }).classed({
            'bold': function (d, i) {
                if($scope.columns[i]){
                    return $scope.columns[i].bold ? 'bold' : null;
                }else{
                    return null;
                }
            }
        });;
    }





    $scope.ui = {
        quantities: null,
        columns: [{
            label: i18nFilter('lp'),
            getter: $parse('index + 1'),
            getterRaw: $parse('index + 1'),
            searchable: false,
            selected: false,
            numerical: true,
            bold: false,
            width: 2
        }, {
            label: i18nFilter('report.table.reportIdent'),
            getter: $parse('name'),
            getterRaw: $parse('name'),
            searchable: true,
            selected: true,
            numerical: false,
            bold: true,
            width: 10
        }, {
            label: i18nFilter('report.table.deviceName'),
            getter: $parse('device.label || device.name'),
            getterRaw: $parse('device.label || device.name'),
            searchable: true,
            selected: true,
            numerical: false,
            bold: true,
            width: 10
        }, {
            label: i18nFilter('config.desc1'),
            getter: $parse('device.desc'),
            getterRaw: $parse('device.desc'),
            searchable: true,
            selected: false,
            numerical: false,
            bold: false,
            width: 10
        }, {
            label: i18nFilter('config.desc2'),
            getter: $parse('device.desc2'),
            getterRaw: $parse('device.desc2'),
            searchable: true,
            selected: false,
            numerical: false,
            bold: false,
            width: 10
        }, {
            label: i18nFilter('config.desc3'),
            getter: $parse('device.desc3'),
            getterRaw: $parse('device.desc3'),
            searchable: true,
            selected: false,
            numerical: false,
            bold: false,
            width: 10
        }, {
            label: i18nFilter('report.table.paramName'),
            getter: $parse('param.label || param.name'),
            getterRaw: $parse('param.label || param.name'),
            searchable: true,
            selected: true,
            numerical: false,
            bold: true,
            width: 10
        }, {
            label: i18nFilter('report.table.paramDesc'),
            getter: $parse('param.desc'),
            getterRaw: $parse('param.desc'),
            searchable: true,
            selected: false,
            numerical: false,
            bold: false,
            width: 10
        }, {
            label: i18nFilter('report.table.value'),
            getter: $parse('moment.good | measurement'),
            getterRaw: $parse('moment.good.value'),
            searchable: false,
            selected: true,
            numerical: true,
            bold: true,
            width: 5
        }, {
            label: i18nFilter('report.table.quality'),
            getter: $parse('moment.quality | number:0'),
            getterRaw: $parse('moment.quality | number:0'),
            searchable: false,
            selected: false,
            numerical: true,
            bold: false,
            width: 5
        }, {
            label: i18nFilter('report.table.errPercentage'),
            getter: $parse('moment.errorCount / moment.readCount | percent'),
            getterRaw: $parse('moment.errorCount / moment.readCount '),
            searchable: false,
            selected: false,
            numerical: true,
            bold: false,
            width: 5
        }, {
            label: i18nFilter('report.table.timestamp'),
            getter: $parse('moment.good.timestamp | date:\'yyyy-MM-dd HH:mm:ss\''),
            getterRaw: $parse('moment.good.timestamp | date2number'),
            searchable: false,
            selected: false,
            numerical: true,
            bold: false,
            width: 5
        }],
        status : function() {
            readoutService.isRunning().then(function(status) {
                $scope.ui.isRunning=status;
            });
        }
    };

    var colOptions = [true, true, false, false, false, false, false, false, true, true, true, true,]

    _.forEach($scope.ui.columns, function(col, i){
        if($scope.options.columns[i]){
            $scope.columns.push(col);
        }
    })

}
}
});

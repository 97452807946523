<div class="panel-body">
	<div x-expand-to-parent-bottom="" style="overflow-y: auto;">
		<table class="table table-bordered table-hover">
			<thead>
			<tr>
				<th>{{'camping.table.name' | i18n}}</th>
				<th>{{'camping.table.timestampStart' | i18n}}</th>
				<th>{{'camping.table.valueStart' | i18n}}</th>
				<th>{{'camping.table.timestamp' | i18n}}</th>
				<th>{{'camping.table.value' | i18n}}</th>
				<th>{{'camping.table.valueDiff' | i18n}}</th>
				<th>{{'camping.table.price' | i18n}}</th>
				<th>{{'camping.table.cost' | i18n}}</th>
				<th style="width: 1px; white-space: nowrap;">{{'camping.table.button' | i18n}}</th>
			</tr>
			</thead>
			<tbody>
			<tr x-ng-repeat="s in report.rows">
				<td>{{s.name}}</td>
				<td>{{s.timestampStart | date:"yyyy-MM-dd HH:mm:ss"}}</td>
				<td style="white-space: nowrap;">{{s.valueStart | unit:s.precision:s.unit:s.scale}}</td>
				<td>{{s.timestampEnd | date:"yyyy-MM-dd HH:mm:ss"}}</td>
				<td style="white-space: nowrap;">{{s.valueEnd | unit:s.precision:s.unit:s.scale}}</td>
				<td style="white-space: nowrap;">{{s.usage | unit:s.precision:s.unit:s.scale}}</td>
				<td style="white-space: nowrap;">{{s.unitCost | unit:2:s.currency}}</td>
				<td style="white-space: nowrap;">{{s.cost | unit:2:s.currency}}</td>
				<td>
					<button class="btn btn-sm btn-success" x-ng-if="!s.started" x-ng-click="ui.start(s)" x-ng-disabled="ui.loading || s.valueStart === null">
						{{'camping.button.start' | i18n}}
					</button>
					<button class="btn btn-sm btn-danger" x-ng-if="s.started" x-ng-click="ui.stop(s)" x-ng-disabled="ui.loading || s.valueStop === null">
						{{'camping.button.stop' | i18n}}
					</button>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

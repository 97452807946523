var module = angular.module('meternet.dashboard.controllers.gaugeWidget', [
	'adf.provider',
	'i18n',
	'meternet.dashboard.controllers.gauge'
]);

module.config(function(dashboardProvider, contextPath, messages) {
	var widget = {
		templateUrl: 'dashboard/gauge-widget.html',
		title : messages['dashboard.widgets.gauge.title'],
		description : messages['dashboard.widgets.gauge.desc'],
		controller : 'gaugeWidgetCtrl',
		screenshot: true,
		config: {
			type: "gauge",
			series : [{
				paramId: null,
				average: 1,
				derivative: 0,
				unit: "",
				majorTicks: 5,
				minorTicks: 3,
				max: 100,
				min: 0,
				precision: 2,
				scale: 0,
				zones: [{
					color: "#5cb85c",
					from: 0,
					to: 50
				},{
					color: "#f0ad4e",
					from: 50,
					to: 75
				},{
					color: "#d9534f",
					from: 75,
					to: 100
				}]
			}]
		},
		edit : {
			controller : 'gaugeWidgetEditCtrl',
			templateUrl: 'dashboard/gauge-widget-edit.html',
            resolve: {
                maxSeries : function(){
                    return 1;
                }
            }
		}
	};
	dashboardProvider.widget('gauge', widget);
});
<table class="table table-bordered table-hover energy-table">
	<thead>
	<tr>
		<th x-ng-repeat="col in tab.columns | filter: {periodic: false}"
			rowspan="2" style="">
			{{col.label | i18n}}
		</th>
		<th x-ng-repeat="p in tab.periods track by $index"
			x-ng-class="$index % 2 === 0 ? 'energy-table-header-color' : 'energy-table-header-no-color'"
			x-ng-attr-colspan="{{tab.periodicColumnCount}}"
			x-ng-style="{ 'width': tab.periodWidth }">
			{{p | i18n}}
		</th>
	</tr>
	<tr>
		<th x-ng-repeat="col in tab.columns | filter: {periodic: true}"
			x-ng-class="col.period % 2 === 0 ? 'energy-table-header-color' : 'energy-table-header-no-color'">
			<i class='fa fa-fw fa-bar-chart-o text-primary'
			   x-ng-if="col.label !== 'energyReport.date'"
			   x-ng-click="ui.showChart(tab, null, col)"
			   data-toggle='modal'
			   data-target='#myModal'></i>
			<br/>
			{{col.label | i18n}}
		</th>
	</tr>
	</thead>
	<tbody>
	<tr x-ng-repeat="row in tab.rows" x-ng-attr-title="{{row._title}}">
		<td x-ng-repeat="col in tab.columns"
			x-ng-class="{ 'energy-table-row': col.period % 2 === 0 }"
			x-ng-style="{ 'text-align': col.align, 'text-overflow': col.align === 'left' ? 'ellipsis' : null }">
			<div x-ng-if="col.periodic && col.field !== 'timestamp'"
				 x-ng-click="ui.showChart(tab, row, col)"
				 class="energy-table-cell"
				 data-toggle='modal'
				 data-target='#myModal'>
				<div class="cell-bar"
					 x-ng-if="col.hasBar(tab, row)"
					 x-ng-style="{ 'width': col.barWidth(tab, row), 'background-color': col.barColor(tab, row) }"></div>
				<span class="cell-value">{{col.valueFmt(row) || '&nbsp;'}}</span>
			</div>
			<span x-ng-if="!col.periodic || col.field === 'timestamp'">{{col.valueFmt(row)}}</span>
		</td>
	</tr>
	</tbody>
	<tfoot x-ng-if="tab.totalColumns.length">
	<tr>
		<td x-ng-repeat="col in tab.totalColumns"
			x-ng-attr-colspan="{{col.colspan}}"
			x-ng-class="{ 'energy-table-row': col.period != null && col.period % 2 === 0 }"
			x-ng-style="{ 'text-align': col.align }">
			{{col.valueFmt(tab)}}
		</td>
	</tr>
	</tfoot>
</table>

<div x-energy-report-table-pagination="" style="position: absolute; bottom: 15px; right: 15px; z-index: 100;"></div>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content" x-ng-if="ui.chart != null">
            <div class="modal-header">
                {{ui.chart.title}}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
            <div class="modal-body">
                <div x-energy-report-chart-widget="" x-data="ui.chart.data" x-unit="ui.chart.unit" x-title="" x-small="ui.chart.small"></div>
            </div>
            <div class="modal-footer" x-ng-if="ui.chart && ui.chart.pagination">
				<div x-energy-report-table-pagination=""></div>
            </div>
        </div>
    </div>
</div>

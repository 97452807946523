var module = angular.module('meternet.chart.directives.calendarSchedule', ['meternet.charts.services', 'meternet.utils.custom-time-picker']);

module.directive('calendarSchedule', function ($stateParams, dashboardService, configService, Errors) {
    return {
        scope: {
            options: '=',
            lightingSetting: '=',
            allErrors: '=',
            data: '=',
            devices: '='
        },
        templateUrl: 'dashboard/calendar/calendar-schedule.html',
        controller: function ($scope, $location, $compile) {
            $scope.events = [];
            $scope.dawnDuskEvents = [];
            $scope.eventSources = [$scope.events, $scope.dawnDuskEvents];
            $scope.selectedDay = null;
            $scope.selectedResource = null;
            $scope.twilightTypes = [ // TODO ws tłumacznenia dla name
                {
                    name: 'Cywilny',
                    value: 'civil'
                },
                {
                    name: 'Nautyczny',
                    value: 'nautical'
                },
                {
                    name: 'Astronomiczny',
                    value: 'astronomical'
                }
            ];
            $scope.ui = {
                processing: false,
                scheduler: null,
                errors: new Errors(),
                newScheduler: {
                    twilightType: 'civil'
                },
                calendar: null,
                selectedDayShifts: {
                    dawn: null,
                    dusk: null
                },
                isFirst: function () {
                    // TODO optimize
                    var idx = $scope.getSchedulerIdx();
                    return idx === 0;
                },
                isLast: function () {
                    // TODO optimize
                    var idx = $scope.getSchedulerIdx();
                    return idx === $scope.lightingSetting.schedulers.length - 1;
                },
                newEvents: []
            };
            $scope.uiConfig = {
                calendar: {
                    plugins: ['interaction', 'resourceTimeGrid'],
                    defaultView: 'resourceTimeGridDay',
                    themeSystem: 'bootstrap3',
                    header: {
                        left: '',
                        center: '',
                        right: ''
                    },
                    height: 'auto',
                    slotDuration: '01:00:00',
                    allDaySlot: false,
                    slotLabelFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                        // omitZeroMinute: true,
                        hour12: false
                    },
                    // wskaźnik switu/zmierzchu po prawej
                    eventOrder: 'start,-duration,allDay,title,dawnDusk',
                    eventTimeFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    },
                    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                    resources: [
                        {id: 'mon', title: 'Poniedziałek'},
                        {id: 'tue', title: 'Wtorek'},
                        {id: 'wed', title: 'Środa'},
                        {id: 'thu', title: 'Czwartek'},
                        {id: 'fri', title: 'Piątek'},
                        {id: 'sat', title: 'Sobota'},
                        {id: 'sun', title: 'Niedziela'},
                        {id: 'hol', title: 'Święto'}
                    ],
                    eventRender: function (info) {
                        if (info.event.extendedProps.dawnDusk) {
                            info.el.classList.add('fc-dawn-dusk')
                        }
                        if (info.event.extendedProps.dawnDuskShift) {
                            info.el.classList.add('fc-dawn-dusk')
                            info.el.classList.add('fc-dawn-dusk-shift')
                        }
                    },
                    datesRender: function (info) {
                        if ($scope.ui.scheduler) {
                            $scope.appendHeadShiftInfo(info.el)
                            $scope.renderDawnDuskIndicators(info);
                        }
                    },
                    eventClick: function (eventClickInfo) {
                        if ($scope.editable) {
                            $scope.eventOnClick(eventClickInfo);
                        }
                    },
                    selectable: true,
                    select: function (selectionInfo) {
                        if ($scope.editable) {
                            $scope.selectedDay = selectionInfo.resource.id;
                            _.forEach($scope.ui.scheduler[selectionInfo.resource.id].switchingTimes, function (event) {
                                addEventToEventList(event);
                            });
                            $scope.ui.newEvents.push({
                                start: selectionInfo.start,
                                end: selectionInfo.end
                            })
                            $scope.ui.newEvents = _.sortBy($scope.ui.newEvents, function (e) {
                                return e.start;
                            });
                            $('#eventSetModal').modal('show');
                        }
                    }
                }
            };

            dashboardService.isDashboardEditable($stateParams.dashboardName).then(function (editable) {
                $scope.editable = editable;
            });

            getActiveScheduler();

            $scope.getSchedulerIdx = function () {
                return $scope.lightingSetting.schedulers.indexOf($scope.ui.scheduler)
            };

            $scope.renderDawnDuskIndicators = function () {
                var lat = 51.7592;
                var lon = 19.4560;

                if ($scope.devices.length) {
                    var pos = tools.parseGeolocation($scope.devices[0]);
                    lat = pos[0];
                    lon = pos[1];
                }

                // on scheduler view calculate dawn/dusk for today
                var currStart = new Date();

                $scope.dawnDuskEvents.length = 0;
                var resources = $scope.uiConfig.calendar.resources;

                var times = tools.calculateSunTimes(currStart, lat, lon);
                times = tools.getTimesByKind(times, $scope.ui.scheduler.twilightType);
                var dawn = times.dawn;
                var dusk = times.dusk;

                var scheduler = $scope.ui.scheduler;
                resources.forEach(function (resource) {
                    if (!scheduler[resource.id].dawnShift || (scheduler[resource.id].dawnShift && scheduler[resource.id].dawnShift.dir !== 'none')) {
                        $scope.addDawnDuskIndicatorEvent({
                            start: dawn,
                            resourceId: resource.id,
                            color: '#e68a0f'
                        });
                    }
                    if (!scheduler[resource.id].duskShift || (scheduler[resource.id].duskShift && scheduler[resource.id].duskShift.dir !== 'none')) {
                        $scope.addDawnDuskIndicatorEvent({
                            start: dusk,
                            resourceId: resource.id,
                            color: '#224f77'
                        });
                    }

                    if (scheduler[resource.id].dawnShift && scheduler[resource.id].dawnShift.shift !== '00:00:00') {
                        $scope.addDawnDuskShiftIndicatorEvent({
                            start: dawn,
                            resourceId: resource.id,
                            color: '#e68a0f'
                        }, scheduler[resource.id].dawnShift);
                    }
                    if (scheduler[resource.id].duskShift && scheduler[resource.id].duskShift.shift !== '00:00:00') {
                        $scope.addDawnDuskShiftIndicatorEvent({
                            start: dusk,
                            resourceId: resource.id,
                            color: '#224f77'
                        }, scheduler[resource.id].duskShift);
                    }

                })
            };

            $scope.appendHeadShiftInfo = function (elem) {
                function getHeaderValues(scheduler, shiftKind) {
                    var values = [];
                    $scope.uiConfig.calendar.resources.forEach(function (res) {
                        var day = scheduler[res.id];
                        var shift = day[shiftKind];
                        var text;
                        if (shift) {
                            if (shift.dir === 'none') {
                                text = "-"
                            } else {
                                var dir = shift.dir === 'sub' ? '-' : '+';
                                text = dir + " " + shift.shift + " h"
                            }
                        } else {
                            text = "+ 00:00:00 h"
                        }

                        values.push({day: res.id, text: text})
                    });
                    return values;
                }

                function fillColumns(columns, values) {
                    columns.style = 'text-align: center';
                    values.forEach(function (val) {
                        var child = "<td x-ng-click=\"setDawnDusk('"+val.day+"')\" style=\"cursor: pointer;\"></td>";
                        var compiledChild = $compile(child)($scope);
                        compiledChild[0].innerHTML = val.text;
                        columns.appendChild(compiledChild[0]);
                    })
                }

                var head = elem.querySelector("table thead tr td div table thead");
                var dawnCol = head.children[0].children[0].cloneNode();
                var duskCol = dawnCol.cloneNode();
                dawnCol.classList.add('dawn');
                duskCol.classList.add('dusk');
                dawnCol.innerHTML = '<i class="fa fa-sun-o"></i>';
                duskCol.innerHTML = '<i class="fa fa-moon-o"></i>';


                var dawnCols = document.createElement("tr");
                var duskCols = document.createElement("tr");
                dawnCols.appendChild(dawnCol);
                duskCols.appendChild(duskCol);

                fillColumns(dawnCols, getHeaderValues($scope.ui.scheduler, "dawnShift"));
                fillColumns(duskCols, getHeaderValues($scope.ui.scheduler, "duskShift"));

                head.appendChild(dawnCols);
                head.appendChild(duskCols);
            };

            $scope.getSchedulerErrors = function () {
                configService.get().then(function (config) {
                    var lightingSettingIdx = config.engine.lightingSettings.indexOf($scope.lightingSetting);
                    var schedulerIdx = $scope.getSchedulerIdx();
                    var path = "engine.lightingSettings[" + lightingSettingIdx + "].schedulers[" + schedulerIdx + "]";
                    $scope.ui.errors = $scope.allErrors.getNestedErrors(path)
                })
            }

            $scope.convertEvent = function () {
                $scope.events.splice(0, $scope.events.length);

                if ($scope.ui.scheduler) {
                    var scheduler = $scope.ui.scheduler;
                    for (var prop in scheduler) {
                        if (scheduler.hasOwnProperty(prop) && scheduler[prop] && Array.isArray(scheduler[prop].switchingTimes)) {
                            _.forEach(scheduler[prop].switchingTimes, function (switchingTime) {
                                var event = {
                                    start: tools.parseTime(switchingTime.start),
                                    end: tools.parseTime(switchingTime.end),
                                    resourceId: prop,
                                    color: '#f2bd33',
                                    extendedProps: {
                                        event: switchingTime,
                                        day: prop
                                    },
                                    classNames: $scope.editable ? ['editable'] : []
                                };
                                if (event.end < event.start) {
                                    // (ak) eventy trwające do północy powinny mieć datę zakończenia ustawioną na kolejny dzień
                                    if (event.end.getHours() === 0 && event.end.getMinutes() === 0 && event.end.getSeconds() === 0) {
                                        event.end.setDate(event.end.getDate()+1);
                                    }
                                }
                                $scope.events.push(event);
                            });
                        }
                    }
                }
            };

            $scope.setDawnDusk = function (day) {
                $scope.selectedDay = day;
                $('#dawnDuskSetModal').modal('show');
            };

            $scope.addScheduler = function () {
                $('#schedulerAdd').modal('show');
            };

            $scope.removeScheduler = function () {
                var idx = $scope.getSchedulerIdx();

                $scope.lightingSetting.schedulers.splice(idx, 1);
                $scope.ui.scheduler = $scope.lightingSetting.schedulers[0];
            };

            $scope.saveNewScheduler = function () {
                $scope.ui.newScheduler.id = uuid.v4();
                $scope.ui.newScheduler.mon = {};
                $scope.ui.newScheduler.tue = {};
                $scope.ui.newScheduler.wed = {};
                $scope.ui.newScheduler.thu = {};
                $scope.ui.newScheduler.fri = {};
                $scope.ui.newScheduler.sat = {};
                $scope.ui.newScheduler.sun = {};
                $scope.ui.newScheduler.hol = {};
                var newScheduler = Object.assign({}, $scope.ui.newScheduler);
                $scope.lightingSetting.schedulers.push(newScheduler);
                $scope.resetNewScheduler();
                $scope.ui.scheduler = newScheduler;
                $scope.$emit('scheduler.dirty');
            };

            $scope.resetNewScheduler = function () {
                $scope.ui.newScheduler = {
                    twilightType: 'civil'
                };
            };

            $scope.previousNextScheduler = function (action) {
                var idx = $scope.getSchedulerIdx();
                var newIdx = action === "next" ? idx + 1 : idx - 1;
                $scope.ui.scheduler = $scope.lightingSetting.schedulers[newIdx]
                $scope.updateUrl();
            };

            $scope.saveDay = function () {
                $scope.ui.scheduler[$scope.selectedDay].switchingTimes = [];
                _.forEach($scope.ui.newEvents, function (event) {
                    // (ak) jeżeli godzina zakończenia jest mniejsza od godziny rozpoczęcia eventu, to koniec eventu przenosi się na następny dzień.
                    // (ak) event jest rozbijany na dwa mniejsze eventy
                    if (event.end < event.start) {
                        if (event.end.getHours() !== 0 || event.end.getMinutes() !== 0 || event.end.getSeconds() !== 0) {
                            var event2 = {}
                            var tomorrow = new Date(event.start);
                            tomorrow.setDate(tomorrow.getDate()+1);
                            tomorrow.setHours(event.end.getHours(), event.end.getMinutes(), event.end.getSeconds());
                            event2.start = new Date(tomorrow);
                            event2.start.setHours(0,0,0);
                            event2.end = tomorrow;
                            event.end.setDate(tomorrow.getDate());
                            event.end.setHours(0,0,0);
                            var index = $scope.uiConfig.calendar.resources.findIndex(function(res) {
                                return res.id === $scope.selectedDay
                            });
                            if (index > -1) {
                                if (index === 7) {
                                    index = -1;
                                }
                                event2.start = tools.formatTime(event2.start);
                                event2.end = tools.formatTime(event2.end);
                                var nextDay = $scope.uiConfig.calendar.resources[index +1 ].id;
                                if (!$scope.ui.scheduler[nextDay].hasOwnProperty("switchingTimes")) {
                                    $scope.ui.scheduler[nextDay].switchingTimes = [];
                                }
                                $scope.ui.scheduler[nextDay].switchingTimes.push(event2);
                            }
                        }
                    }
                    event.start = tools.formatTime(event.start);
                    event.end = tools.formatTime(event.end);
                    delete event.event;
                    $scope.ui.scheduler[$scope.selectedDay].switchingTimes.push(event);
                });
                $scope.resetDay();
                $scope.ui.calendar.render();
            };

            $scope.saveShifts = function () {
                var selectedDay = $scope.ui.scheduler[$scope.selectedDay];
                formatShift("dawn", selectedDay);
                formatShift("dusk", selectedDay);
                $scope.ui.calendar.render();
            };

            $scope.resetDay = function () {
                $scope.ui.newEvents = [];
            };

            $scope.resetShifts = function () {
                setSelectedDayShifts("dusk", $scope.selectedDay);
                setSelectedDayShifts("dawn", $scope.selectedDay);
            };

            $scope.addEvent = function () {
                $scope.ui.newEvents.push({
                    start: null,
                    end: null
                })
            };

            $scope.removeEvent = function (index) {
                $scope.ui.newEvents.splice(index, 1);
            }

            $scope.eventOnClick = function (eventClickInfo) {
                var edited = eventClickInfo.event.extendedProps;
                // do not edit dawn/dusk indicators
                if (edited.dawnDusk || edited.dawnDuskShift) {
                    return
                }
                $scope.selectedDay = edited.day;
                _.forEach($scope.ui.scheduler[$scope.selectedDay].switchingTimes, function (event) {
                    addEventToEventList(event);
                });
                $scope.ui.newEvents = _.sortBy($scope.ui.newEvents, function (e) {
                    return e.start;
                });
                $('#eventSetModal').modal('show');
            }

            $scope.addDawnDuskIndicatorEvent = function (evt) {
                var end = new Date(evt.start.getTime());
                end.setMinutes(end.getMinutes() + 1);
                var defaultEvent = {
                    end: end,
                    dawnDusk: true
                };
                Object.assign(defaultEvent, evt);

                $scope.dawnDuskEvents.push(defaultEvent);
            };

            $scope.addDawnDuskShiftIndicatorEvent = function (evt, shift) {
                if (shift.dir !== 'none') {
                    var duration = moment.duration(shift.shift, tools.TIME_FORMAT);

                    var start = moment(evt.start);
                    if (shift.dir === 'add') {
                        start.add(duration)
                    } else if (shift.dir === 'sub') {
                        start.subtract(duration)
                    } else {
                        throw new Error('Unexpected direction : ' + shift.dir)
                    }

                    evt.start = start.toDate();
                    var end = new Date(start.toDate().getTime());
                    end.setMinutes(end.getMinutes() + 1);
                    var defaultEvent = {
                        end: end,
                        dawnDuskShift: true
                    };
                    Object.assign(defaultEvent, evt);

                    $scope.dawnDuskEvents.push(defaultEvent);
                }
            };

            $scope.updateUrl = function () {
                $location.hash('lightingSetting=' + $scope.lightingSetting.id + '&scheduler=' + $scope.ui.scheduler.id);
            };

            $scope.$watch('ui.scheduler', function (newVal, oldVal) {
                if (newVal) {
                    $scope.convertEvent();
                    // recalculate field errors only when scheduler instance changed
                    if (!oldVal || newVal.id !== oldVal.id) {
                        $scope.getSchedulerErrors();
                    }
                }
            }, true);

            $scope.$watch('allErrors', function (newVal) {
                $scope.getSchedulerErrors()
            })

            $scope.$watch('ui.scheduler.twilightType', function (newVal, oldVal) {
                if ($scope.ui.calendar) {
                    $scope.ui.calendar.render()
                }
            });

            $scope.$watch('lightingSetting', function (newVal) {
                if (newVal) {
                    getActiveScheduler()
                }
            });

            $scope.$watch('selectedDay', function (newVal) {
                if (newVal) {
                    var resources = $scope.uiConfig.calendar.resources;
                    $scope.selectedResource = _.findWhere(resources, {id: newVal});
                    setSelectedDayShifts("dusk", newVal);
                    setSelectedDayShifts("dawn", newVal);
                } else {
                    $scope.selectedResource = null
                }
            });

            $('#eventSetModal').on('hide.bs.modal', function (e) {
                $scope.resetDay();
            });

            $scope.$on('restore settings', function (e, args) {
                $scope.ui.scheduler = _.findWhere($scope.lightingSetting.schedulers, {id: args.schedulerId});
            })

            $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
                getActiveScheduler();
            });

            function setSelectedDayShifts (kind, day) {
                var shiftName = kind + "Shift";
                if ($scope.ui.scheduler[day][shiftName]) {
                    $scope.ui.selectedDayShifts[kind] = JSON.parse(JSON.stringify($scope.ui.scheduler[day][shiftName]));
                    $scope.ui.selectedDayShifts[kind].shift = tools.parseTime($scope.ui.selectedDayShifts[kind].shift);
                } else {
                    $scope.ui.selectedDayShifts[kind] = {
                        dir: 'add',
                        shift: tools.parseTime("00:00:00")
                    }
                }
            }

            function formatShift(kind, selectedDay) {
                var shiftName = kind + "Shift";
                selectedDay[shiftName] = selectedDay[shiftName] || {};
                var shift = selectedDay[shiftName];
                shift.shift = tools.formatTime($scope.ui.selectedDayShifts[kind].shift);
                shift.dir = $scope.ui.selectedDayShifts[kind].dir;
            }

            function addEventToEventList(event) {
                $scope.ui.newEvents.push({
                    start: tools.parseTime(event.start),
                    end: tools.parseTime(event.end),
                    event: event
                })
            }

            function getActiveScheduler() {
                var hash = $location.hash().split('&');
                if (hash.length > 1) {
                    $scope.ui.scheduler = _.findWhere($scope.lightingSetting.schedulers, {id: hash[1].replace('scheduler=', '')});
                } else {
                    var foundIndex = tools.getActiveSchedulerForCurrent($scope.lightingSetting.schedulers);
                    $scope.ui.scheduler = $scope.lightingSetting.schedulers[foundIndex];
                }
            }
        }
    }
});

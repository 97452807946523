var module = angular.module('meternet.config.form.directives', [
    'meternet.utils.form',
    'meternet.inline-js'
]);


module.directive('configFormMeasurementInputs', function ($http, Errors, MeasurementInputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/measurement-inputs.html',
        controller: function ($scope) {
            $scope.input = {
                type: null,
                name: 'interface-' + shortid.gen(),
                label: i18nFilter('config.measurementInput.title') + ' ' + ($scope.item.config.measurementInputs.length + 1)
            };

            $scope.ui = {
                option: null,
                inputs: _.filter(MeasurementInputTypes, function (t) {
                    return t.addable;
                }),
                active: function (input) {
                    return input.type === $scope.input.type ? 'active' : '';
                },
                select: function (input) {
                    $scope.input.type = input.type;
                    var time = Date.now();
                    if (time - ($scope.time || 0) < 200) {
                        $scope.$parent.ui.add();
                    }
                    $scope.time = time;
                }
            };

            $scope.addui.element = function () {
                return $scope.input;
            };

            $scope.addui.valid = function () {
                return $scope.input.type && $scope.input.name && $scope.input.name.trim();
            };

            $scope.addui.append = function (element, config) {
                config.measurementInputs.push(element);
            };
        }
    };
});


module.directive('configFormModuleInputs', function (Errors, ModuleInputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/module-inputs.html',
        controller: function ($scope) {

        }
    };
});

module.directive('configFormMeasurementOutputs', function (Errors, MeasurementOutputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/measurement-outputs.html',
        controller: function ($scope, $rootScope) {
            $scope.output = {
                type: null,
                name: 'output-' + shortid.gen(),
                label: i18nFilter('config.measurementOutput.title') + ' ' + ($scope.item.config.measurementOutputs.length + 1)
            };

            var outputs = _.pluck($scope.config.engine.measurementOutputs, 'databaseId');
            $scope.config.engine.databases = _.filter($scope.config.engine.databases, function(db){
                return _.contains(outputs, db.id);
            });

            $scope.ui = {
                databases: $scope.config.engine.databases,
                option: null,
                outputs: MeasurementOutputTypes,
                active: function (output) {
                    return output.type === $scope.output.type ? 'active' : '';
                },
                select: function (output) {
                    $scope.output.type = output.type;
                    var time = Date.now();
                    if (time - ($scope.time || 0) < 200) {
                        $scope.$parent.ui.add();

                    }
                    $scope.time = time;
                },
                getMeasurementName:function(db){
                    var mo =_.findWhere($scope.config.engine.measurementOutputs, {databaseId: db.id})
                    if(mo){
                        return mo.label || mo.name;
                    }
                },
                getMeasurementIcon:function(db){
                    console.log($scope.ui.outputs)
                    var mo =_.findWhere($scope.ui.outputs, {type: db.type})
                    if(mo){
                        return mo.iconUrl;
                    }
                },
                setActiveRepo: function(db){
                    _.forEach($scope.ui.databases, function(o){
                        o.repository = false;
                    })
                    db.repository = true;
                }
            };

            $scope.addui.element = function () {
                return $scope.output;
            };

            $scope.addui.valid = function () {
                return $scope.output.type && $scope.output.name && $scope.output.name.trim();
            };

            $scope.addui.append = function (element, config) {
                for(var k=0; k<$scope.config.engine.measurementInputs.length; k++){
                    $scope.config.engine.measurementInputs[k].outputIds.push($scope.output.id)
                    for (var i=0; i<$scope.config.engine.measurementInputs[k].devices.length; i++){
                        var d = $scope.config.engine.measurementInputs[k].devices[i];
                        for (var j=0; j<d.params.length; j++){
                            var p = d.params[j];
                            var mr = _.find(p.momentRules, function(m){
                                if(m.rule && m.rule.actions[0]){
                                    return m.rule.actions[0].outputId == $scope.output.id;
                                }else{
                                    return false;
                                }
                            });
                            if(!mr){
                                var r = {
                                    id: uuid.v1(),
                                    name: 'r' + '-' + (p.momentRules.length + 1),
                                    type: 'stateless',
                                    rule: createDefaultMomentRuleDef($scope.config.engine)
                                };
                                r.rule.actions[0].outputId = $scope.output.id;
                                p.momentRules.push(r);
                            }
                        }
                    }
                }
                for(var k=0; k<$scope.config.engine.moduleInputs.length; k++){
                    $scope.config.engine.moduleInputs[k].outputIds.push($scope.output.id)
                    for (var i=0; i<$scope.config.engine.moduleInputs[k].devices.length; i++){
                        var d = $scope.config.engine.moduleInputs[k].devices[i];
                        for (var j=0; j<d.params.length; j++){
                            var p = d.params[j];
                            var mr = _.find(p.momentRules, function(m){
                                if(m.rule){
                                    return m.rule.actions[0].outputId == $scope.output.id;
                                }else{
                                    return false;
                                }
                            });
                            if(!mr){
                                var r = {
                                    id: uuid.v1(),
                                    name: 'r' + '-' + (p.momentRules.length + 1),
                                    type: 'stateless',
                                    rule: createDefaultMomentRuleDef($scope.config.engine)
                                };
                                r.rule.actions[0].outputId = $scope.output.id;
                                p.momentRules.push(r);
                            }
                        }
                    }
                }
                $scope.$emit('modelErrors.update', true);

                if (element.type != "csv" &&
                    element.type != "remote" &&
                    element.type != "anakonda"){
                    var db = {'id':  uuid.v1(),
                                'name': "db" + shortid.gen(),
                                'type': element.type};
                    element.databaseId = db.id;
                    config.measurementOutputs.push(element);
                    config.databases.push(db);
                }else{
                    config.measurementOutputs.push(element);
                }
            };
        }
    };
});

module.directive('configFormEventInputs', function (Errors, EventInputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/event-inputs.html',
        controller: function ($scope) {
            $scope.input = {
                type: null,
                name: 'input-' + shortid.gen(),
                label: i18nFilter('config.eventInput.title') + ' ' + ($scope.item.config.eventInputs.length + 1)
            };

            $scope.ui = {
                option: null,
                inputs: _.filter(EventInputTypes, function (t) {
                    return t.addable;
                }),
                active: function (input) {
                    return input.type === $scope.input.type ? 'active' : '';
                },
                select: function (input) {
                    $scope.input.type = input.type;
                    var time = Date.now();
                    if (time - ($scope.time || 0) < 200) {
                        $scope.$parent.ui.add();
                    }
                    $scope.time = time;
                }
            };

            $scope.addui.element = function () {
                return $scope.input;
            };

            $scope.addui.valid = function () {
                return $scope.input.type && $scope.input.name && $scope.input.name.trim();
            };

            $scope.addui.append = function (element, config) {
                config.eventInputs.push(element);
            };
        }
    };
});

module.directive('configFormEventOutputs', function (Errors, EventOutputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/event-outputs.html',
        controller: function ($scope) {
            $scope.output = {
                type: null,
                name: 'output-' + shortid.gen(),
                label: i18nFilter('config.eventOutput.title') + ' ' + ($scope.item.config.eventOutputs.length + 1)
            };
            $scope.ui = {
                option: null,
                outputs: EventOutputTypes,
                active: function (output) {
                    return output.type === $scope.output.type ? 'active' : '';
                },
                select: function (output) {
                    $scope.output.type = output.type;
                    var time = Date.now();
                    if (time - ($scope.time || 0) < 200) {
                        $scope.$parent.ui.add();
                    }
                    $scope.time = time;
                }
            };

            $scope.addui.element = function () {
                return $scope.output;
            };

            $scope.addui.valid = function () {
                return $scope.output.type && $scope.output.name && $scope.output.name.trim();
            };

            $scope.addui.append = function (element, config) {
                config.eventOutputs.push(element);
            };
        }
    };
});

module.directive('configFormDevices', function (Errors, DeviceModels, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/devices.html',
        controller: function ($scope, license, configService) {
            $scope.mainMeters = "FIF";
            if(_.indexOf(license.enabledModules, "ETI_BASIC")>=0){
                $scope.mainMeters = "ETI";
            }

            function deviceFilter(device) {
                return device.inputTypes.indexOf($scope.item.config.type) >= 0;
            }

            $scope.ui = {
                devices: $scope.item.config.type !== 'mbsat'
                    ? _.each(_.filter(DeviceModels, deviceFilter), function (device) {
                        device.label = i18nFilter('config.device.manufacturer.' + device.manufacturer) + ' ' + i18nFilter('config.device.model.' + device.model);
                    })
                    : [],
                active: function (device) {
                    if (device.type === 'mbsat') {
                        return device.config.id === $scope.device.id ? 'active' : '';
                    } else {
                        return device.model === $scope.device.model ? 'active' : '';
                    }
                },
                select: function (device) {
                    if (device.type === 'mbsat') {
                        $scope.device = device.config;
                    } else {
                        $scope.device.type = device.type;
                        $scope.device.model = device.model;
                        var time = Date.now();
                        if (time - ($scope.time || 0) < 200) {
                            configService.getDevice($scope.device.model, $scope.item.config.id).then(function (response) {
                                $scope.device = response;
                                $scope.device.name = 'device-' + shortid.gen();
                                $scope.device.label = i18nFilter('config.device') + ' ' + ($scope.item.config.devices.length + 1);
                                $scope.$parent.ui.addDevice();
                            });
                        }
                        $scope.time = time;
                    }
                }
            };

            $scope.addui.element = function () {
                return $scope.device;
            };

            $scope.addui.valid = function () {
                return $scope.device.model && $scope.device.name && $scope.device.name.trim();
            };

            $scope.addui.append = function (element, config, count) {
                config.devices.push(element);
                if (count > 1) {
                    for (var i = 1; i < count; i++) {
                        var e = _.clone(element);
                        e.id = uuid.v4();
                        e.name = 'device-' + shortid.gen();
                        e.label = i18nFilter('config.device') + ' ' + (config.devices.length + 1);
                        config.devices.push(e);
                    }
                }
            };

			$scope.device = {
				type: null,
				model: null,
				name: 'device-' + shortid.gen(),
				label: i18nFilter('config.device') + ' ' + ($scope.item.config.devices.length + 1),
				regenerated: true
			};

            $scope.$on('autodetect', function(event) {
                configService.detectMbsatDevices($scope.item.config.id).then(function (deviceConfigs) {
                    $scope.ui.devices = [];
                    _.forEach(deviceConfigs, function (deviceConfig) {
                        const device = {
                            type: "mbsat",
                            model: "mbsat",
                            cost: 0,
                            iconUrl: "/resources/info/devices/icons/none.jpg",
                            name: deviceConfig.name || deviceConfig.id,
                            label: deviceConfig.label || deviceConfig.name || deviceConfig.id,
                            config: deviceConfig,
                        };
                        $scope.ui.devices.push(device);
                    })
                });
            });
        }
    };
});

module.directive('configFormParameters', function (Errors, i18nFilter, ParameterTypes) {
    return {
        scope: true,
        templateUrl: 'config/form/parameters.html',
        controller: function ($scope) {
            function parameterFilter(parameter) {
                return parameter.deviceTypes.indexOf($scope.item.config.type) >= 0;
            }
            $scope.parameter = {
                type: null,
                name: 'parameter-' + shortid.gen(),
                label: i18nFilter('config.parameter') + ' ' + ($scope.item.config.params.length + 1),
                regenerated: true
            };
            $scope.ui = {
                parameters: _.filter(ParameterTypes, parameterFilter),
                active: function (parameter) {
                    return parameter.type === $scope.parameter.type ? 'active' : '';
                },
                select: function (parameter) {
                    $scope.parameter.type = parameter.type;
                    var time = Date.now();
                    if (time - ($scope.time || 0) < 200) {
                        $scope.$parent.ui.add();
                    }
                    $scope.time = time;
                }
            };

            $scope.addui.element = function () {
                return $scope.parameter;
            };

            $scope.addui.valid = function () {
                return $scope.parameter.type && $scope.parameter.name && $scope.parameter.name.trim();
            };

            $scope.addui.append = function (element, config, count) {
                if (count>1){
                    for (var i=0; i<count; i++){
                        var e = _.clone(element);
                        if(i>0){
                            e.id = uuid.v1();
                            e.name = 'parameter-' + shortid.gen();
                            e.label = i18nFilter('config.parameter') + ' ' + ($scope.item.config.params.length + 1);
                        }
                        config.params.push(e);
                    }
                }else{
                    config.params.push(element);
                }
            };
        }
    };
});

module.directive('configFormDatabases', function (Errors, DatabaseTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/databases.html',
        controller: function ($scope) {
            $scope.database = {
                type: null,
                name: 'db-' + shortid.gen(),
                label: 'db-' + ($scope.item.config.databases.length + 1)
            };

            $scope.ui = {
                databases: DatabaseTypes,
                active: function (database) {
                    return database.type === $scope.database.type ? 'active' : '';
                },
                select: function (database) {
                    $scope.database.type = database.type;
                    var time = Date.now();
                    if (time - ($scope.time || 0) < 200) {
                        $scope.$parent.ui.add();
                    }
                    $scope.time = time;
                }
            };

            $scope.addui.element = function () {
                return $scope.database;
            };

            $scope.addui.valid = function () {
                return $scope.database.type && $scope.database.name && $scope.database.name.trim();
            };

            $scope.addui.append = function (element, config) {
                config.databases.push(element);
            };
        }
    };
});

module.directive('configFormNotifiers', function (Errors, NotifierTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/notifiers.html',
        controller: function ($scope) {
            $scope.notifier = {
                type: NotifierTypes[0].type,
                name: 'mail-' + shortid.gen(),
                label: i18nFilter('config.notifier') + ' ' + ($scope.item.config.notifiers.length + 1)
            };
            $scope.notifiers = $scope.item.parent.parent.children[6].children;
            $scope.ui = {
                notifiers: NotifierTypes,
                active: function (notifier) {
                    return notifier.type === $scope.notifier.type ? 'active' : '';
                },
                add: function (notifier) {
                    if(!$scope.notifier.block){
                        $scope.notifier.block = true;
                        $scope.notifier.type = notifier.type;
                        $scope.notifier.name = $scope.notifier.type + '-' + shortid.gen();
                        $scope.notifier.id = uuid.v1();
                        $scope.notifier.name = $scope.notifier.name.trim();
                        var newNotifier  = { config: $scope.notifier}
                        $scope.addui.append(newNotifier.config, $scope.config.engine);
                        $scope.$emit('modelErrors.update', true);
                    }
                }
            };

            $scope.addui.element = function () {
                return $scope.notifier;
            };

            $scope.addui.valid = function () {
                return $scope.notifier.type && $scope.notifier.name && $scope.notifier.name.trim();
            };

            $scope.addui.append = function (element, config) {
                config.notifiers.push(element);
            };

            $scope.isMissing = function(n){
                var notif
                if($scope.notifiers){
                    _.forEach($scope.notifiers , function(notifier){
                        if(notifier.config.type == n.type){
                            notif = notifier;
                        }
                    });
                }
                return !notif;
            }
        }
    };
});


module.directive('configFormMeasurementInput', function ($timeout, MeasurementInputTypes, DeviceModels, i18nFilter, advanceView) {
    return {
        scope: true,
        templateUrl: 'config/form/measurement-input.html',
        controller: function ($scope, $rootScope) {

            if ($scope.item.config.port == null) {
                if ($scope.item.config.type === 'mbus-serial' || $scope.item.config.type === 'mbus-serial') {
                    $scope.item.config.port = 1;
                } else {
                    $scope.item.config.port = 0;
                }
            }

            $scope.advanceView=advanceView;
            $scope.ui = {
                booleans:[{"value":false,"label":"config.measurementInput.tcp.islocal.false"},{"value":true,"label":"config.measurementInput.tcp.islocal.true"}],
                booleansisclient:[{"value":false,"label":"config.param.isclient.false"},{"value":true,"label":"config.param.isclient.true"}],
                type: _.find(MeasurementInputTypes, function (t) {
                    return t.type === $scope.item.config.type;
                }),
                ports: [{
                    value: 0,
                    label: i18nFilter('config.measurementInput.serial.port.internal')
                }, {
                    value: 1,
                    label: i18nFilter('config.measurementInput.serial.port.usb1')
                }, {
                    value: 2,
                    label: i18nFilter('config.measurementInput.serial.port.usb2')
                }, {
                    value: 3,
                    label: i18nFilter('config.measurementInput.serial.port.usb3')
                }, {
                    value: 4,
                    label: i18nFilter('config.measurementInput.serial.port.usb4')
                }],
                baudrates: [1200, 2400, 4800, 9600, 19200, 38400, 57600, 115200, 230400],
                dataBits: [7, 8],
                stopBits: [1, 2],
                parities: [{
                    value: 0,
                    label: i18nFilter('config.measurementInput.serial.parity.none')
                }, {
                    value: 1,
                    label: i18nFilter('config.measurementInput.serial.parity.even')
                }, {
                    value: 2,
                    label: i18nFilter('config.measurementInput.serial.parity.odd')
                }],
                restoreDefaultRules: function () {
                    for (var i = 0; i < $scope.item.config.devices.length; ++i) {
                        var d = $scope.item.config.devices[i];
                        d.regenerated = true;
                        for (var j = 0; j < d.params.length; ++j) {
                            d.params[j].momentRules = null;
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                isOutputActive:function(output){
                    return $scope.item.config.outputIds && $scope.item.config.outputIds.indexOf(output.id) >= 0;
                },
                activateOutput: function(output){
                    if($scope.item.config.outputIds==null){
                        $scope.item.config.outputIds=[];
                    }
                    $scope.item.config.outputIds.push(output.id);
                },
                deactivateOutput: function(output){
                    if($scope.item.config.outputIds==null){
                        $scope.item.config.outputIds=[];
                    }
					var index = $scope.item.config.outputIds.indexOf(output.id);
					if (index >= 0) {
						$scope.item.config.outputIds.splice(index, 1);
					}
                },
                activateOutputInInput: function(output, all){
                    for (var i=0; i<$scope.item.config.devices.length; i++){
                        var d = $scope.item.config.devices[i];
                        for (var j=0; j<d.params.length; j++){
                            var p = d.params[j];

                            if(p.select || all){
                                var mr = _.find(p.momentRules, function(m){
                                    return m.rule.actions[0].outputId == output.id;
                                });

                                var deadband=Number(output.deadBand ? output.deadBand[0]:null)
                                var force=Number(output.deadBand ? output.deadBand[1] :null)
                                if(!mr){
                                    var r = {
                                                id: uuid.v1(),
                                                name: 'r' + '-' + (p.momentRules.length + 1),
                                                type: 'stateless',
                                                rule: createDefaultMomentRuleDef($scope.config.engine)
                                            };
                                    r.rule.actions[0].outputId = output.id;
                                    if(deadband || force){
                                        r.rule.expr = "deadband(" + deadband + "," + force + ")";
                                    }
                                    p.momentRules.push(r);
                                }
                                else{
                                    if(deadband || force){
                                        mr.rule.expr = "deadband(" + deadband + "," + force + ")";
                                    }
                                }
                            }
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                deactivateOutputInInput: function(output, all){
                    for (var i=0; i<$scope.item.config.devices.length; i++){
                        var d = $scope.item.config.devices[i];
                        for (var j=0; j<d.params.length; j++){
                            var p = d.params[j];
                            if(p.select || all){
                                var mr = _.find(p.momentRules, function(m){
                                    return m.rule.actions[0]? m.rule.actions[0].outputId == output.id: false;
                                });
                                p.momentRules = _.without(p.momentRules, mr);
                            }
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                clientServerMode: function(isClient){
                    if(isClient){
                        $scope.item.config.address=""
//                        $scope.item.config.port=""
                    }else{
                        $scope.item.config.address="127.0.0.1"
//                        $scope.item.config.port=502
                    }
                }
            };

            if ($scope.item.config.type === 'mbus-serial' || $scope.item.config.type === 'mbus-serial') {
                $scope.ui.ports.shift();
            }

            $scope.$watch('item.config.type', function (nval, oval) {
               if (nval !== oval) {
                   if (nval === 'modbus-gprs') {
                       $scope.item.config.timeout = 5000;
                   } else {
                       $scope.item.config.timeout = 1000;
                   }
               }
            });

            if ($scope.item.config.type === 'remote') {
                $scope.$emit('options', [{
                    css: 'btn-default',
                    icon: 'fa-refresh',
                    label: 'config.measurementInput.remote.getRemoteConfig',
                    enabled: true,
                    click: function () {
                        $scope.item.config.regenerated = true;
                        $scope.$emit('modelErrors.update', true);
                    }
                }]);
            }
            // $scope.dirty = false;
            if ($scope.item.config.type === 'mbsat') {
                // $scope.$emit('options', [{
                //     css: 'btn-default',
                //     icon: 'fa-refresh',
                //     label: 'config.measurementInput.remote.setMBsatConfig',
                //     enabled: function(){
                //         return !$scope.dirty;
                //     },
                //     click: function () {
                //         $scope.item.config.regenerated = true;
                //         $scope.$emit('modelErrors.update', true);
                //     }
                // }]);
            }

            // $rootScope.$on('config.dirty', function (event, dirty) {
            //     $scope.dirty=dirty;
            // });
        }
    };
});

module.directive('configFormModuleInput', function ($timeout, ModuleInputTypes, DeviceModels, i18nFilter, advanceView) {
    return {
        scope: true,
        templateUrl: 'config/form/module-input.html',
        controller: function ($scope) {
            $scope.advanceView=advanceView;
            $scope.ui = {
                type: _.find(ModuleInputTypes, function (t) {
                    return t.type === $scope.item.config.type;
                }),
                restoreDefaultRules: function () {
                    for (var i = 0; i < $scope.item.config.devices.length; ++i) {
                        var d = $scope.item.config.devices[i];
                        d.regenerated = true;
                        for (var j = 0; j < d.params.length; ++j) {
                            d.params[j].momentRules = null;
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
				isOutputActive:function(output){
					return $scope.item.config.outputIds && $scope.item.config.outputIds.indexOf(output.id) >= 0;
				},
				activateOutput: function(output){
					if($scope.item.config.outputIds==null){
						$scope.item.config.outputIds=[];
					}
					$scope.item.config.outputIds.push(output.id);
				},
				deactivateOutput: function(output){
					if($scope.item.config.outputIds==null){
						$scope.item.config.outputIds=[];
					}
					var index = $scope.item.config.outputIds.indexOf(output.id);
					if (index >= 0) {
						$scope.item.config.outputIds.splice(index, 1);
					}
				},
                activateOutputInInput: function(output){
                    for (var i=0; i<$scope.item.config.devices.length; i++){
                        var d = $scope.item.config.devices[i];
                        for (var j=0; j<d.params.length; j++){
                            var p = d.params[j];
                            var mr = _.find(p.momentRules, function(m){
                                return m.rule.actions[0].outputId == output.id;
                            });
                            if(!mr){
                                var r = {
                                            id: uuid.v1(),
                                            name: 'r' + '-' + (p.momentRules.length + 1),
                                            type: 'stateless',
                                            rule: createDefaultMomentRuleDef($scope.config.engine)
                                        };
                                r.rule.actions[0].outputId = output.id;
                                p.momentRules.push(r);
                            }
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                deactivateOutputInInput: function(output){
                    for (var i=0; i<$scope.item.config.devices.length; i++){
                        var d = $scope.item.config.devices[i];
                        for (var j=0; j<d.params.length; j++){
                            var p = d.params[j];
                            var mr = _.find(p.momentRules, function(m){
                                return m.rule.actions[0]? m.rule.actions[0].outputId == output.id: false;
                            });
                            p.momentRules = _.without(p.momentRules, mr);
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
            };

            var customAddDeviceButtonTypes = ['energy-report', 'energy-monitor', 'math', 'prepaid', 'alarm', 'control'];

            $scope.hasCustomAddDeviceButton = function(type){
                return customAddDeviceButtonTypes.indexOf(type) > -1;
            };

            if ($scope.hasCustomAddDeviceButton($scope.item.config.type)) {
                var type = $scope.item.config.type;
                var label = i18nFilter('config.device.model.' + type )+ ' ' + ($scope.item.config.devices.length + 1);

                var deviceModel = _.find(DeviceModels, function(dm){
                    return dm.type===type;
                });

                $scope.$emit('options', [{
                    css: 'btn-success',
                    icon: 'fa-plus',
                    label: 'config.moduleInput.' + type + '.addDevice',
                    enabled: true,
                    click: function () {
                        var device = {
                            type: type,
                            model: deviceModel.model,
                            name: type + '-' + shortid.gen(),
                            label: label,
                            regenerated: true
                        };
                        if (type ==='energy-report'){
                            device.cronExpression= '0 0 0 1 * ?';
                            device.startTime = new Date();
                            device.columns=4;
                            device.visibleColumns=['energyReport.title', 'energyReport.date', 'energyReport.value']
                        }
                        $scope.addui.element = function () {
                            return device;
                        };

                        $scope.addui.valid = function () {
                            return true;
                        };

                        $scope.addui.append = function (element, config) {
                            config.devices.push(element);
                        };
                        $scope.$parent.ui.add();
                    }
                }]);
            }
        }
    };
});

module.directive('configFormDevice', function (DeviceModels, advanceView, ColorPickerOpts, i18nFilter, unitFilter, $filter, Locale) {
    return {
        scope: true,
        templateUrl: 'config/form/device.html',
        controller: function ($scope) {
            $scope.advanceView=advanceView;
            $scope.timezones=tools.timezones
            $scope.onSelect = function ($item, $model, $label) {
                $scope.$item = $item;
                $scope.$model = $model;
                $scope.$label = $label;
            };

            $scope.ui = {
				langs: [{
					value: 'pl-PL',
					label: 'polski',
				}, {
					value: 'en-US',
					label: 'English',
				}, {
					value: 'ru-RU',
					label: 'русский',
				}],
                colorPickerOpts : ColorPickerOpts,
                booleans:[{"value":false,"label":"config.param.enabled.false"},{"value":true,"label":"config.param.enabled.true"}],
                readVariant:[{"value":0,"label":"config.device.dlms.readVariant.0"},{"value":1,"label":"config.device.dlms.readVariant.1"}],
                isEmailNotifier: _.find($scope.config.engine.notifiers, function(n){
                    return n.type==="email"
                }),
                model: _.find(DeviceModels, function (m) {
                    return m.model === $scope.item.config.model;
                }),
                restoreDefaultRules: function () {
                    $scope.item.config.regenerated = true;
                    for (var j = 0; j < $scope.item.config.params.length; ++j) {
                        $scope.item.config.params[j].momentRules = null;
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                modes : [
                    { value : 0, label : i18nFilter('energyreport.mode.none') },
                    { value : 1, label : i18nFilter('energyreport.mode.max') },
                    { value : 2, label : i18nFilter('energyreport.mode.serie') },
                    { value : 3, label : i18nFilter('energyreport.mode.progress') }
                ],
                visibleMode : 0,
                visibleModes : [
                    { value : 0, label : i18nFilter('energyReport.increaseColumn') },
                    { value : 1, label : i18nFilter('energyReport.powerColumn') },
                    { value : 2, label : i18nFilter('energyReport.costColumn') }
                ],

                visibilites : [
                    { value:true, label:i18nFilter('visibleOn')},
                    { value:false, label:i18nFilter('visibleOff')}
                ],
                setColumnVisibility: function(column, idx) {
                    if (!$scope.item.config.visibleColumns) {
                        $scope.item.config.visibleColumns = [];
                    }
                    //index of element which we want to remove. Idx means index which we want to put new element
                    var index = $scope.item.config.visibleColumns.indexOf(column);
                    if (index < 0) {
                        $scope.item.config.visibleColumns.splice(idx, 0, column);
                    } else {
                        $scope.item.config.visibleColumns.splice(index, 1);
                    }
                    if($scope.item.config.visibleColumns.length===0){
                        $scope.item.config.visibleColumns.push('energyReport.title');
                    }
                },
                isChecked: function(column) {

                    if (!$scope.item.config.visibleColumns) {
                        $scope.item.config.visibleColumns = [];
                    }
                    if ($scope.item.config.visibleColumns.indexOf(column) < 0) {
                        return false;
                    }
                    return true;
                },
                addZone : function(paramIndex) {
                    var param = $scope.item.config.params[paramIndex];
                    if(!param.zones){
                        param.zones = [];
                    }
                    var zonesLength = param.zones.length;

                    var min = zonesLength > 0 ? param.zones[zonesLength - 1].to : 0;
                    $scope.item.config.params[paramIndex].zones.push({
                        "color" : "#ffffff",
                        "from" : min,
                        "to" : min + 10
                    });
                },
                addZonePower : function(paramIndex) {
                    var param = $scope.item.config.params[paramIndex];
                    if(!param.zonesPower){
                        param.zonesPower = [];
                    }
                    var zonesLength = param.zonesPower.length;

                    var min = zonesLength > 0 ? param.zonesPower[zonesLength - 1].to : 0;
                    $scope.item.config.params[paramIndex].zonesPower.push({
                        "color" : "#ffffff",
                        "from" : min,
                        "to" : min + 10
                    });
                },
                addZoneCost : function(paramIndex) {
                    var param = $scope.item.config.params[paramIndex];
                    if(!param.zonesCost){
                        param.zonesCost = [];
                    }
                    var zonesLength = param.zonesCost.length;

                    var min = zonesLength > 0 ? param.zonesCost[zonesLength - 1].to : 0;
                    $scope.item.config.params[paramIndex].zonesCost.push({
                        "color" : "#ffffff",
                        "from" : min,
                        "to" : min + 10
                    });
                },
                removeZone : function(paramIndex, index) {
                    $scope.item.config.params[paramIndex].zones.splice(index, 1);
                },
                removeZonePower : function(paramIndex, index) {
                    $scope.item.config.params[paramIndex].zonesPower.splice(index, 1);
                },
                removeZoneCost : function(paramIndex, index) {
                    $scope.item.config.params[paramIndex].zonesCost.splice(index, 1);
                },
                copyZones : function(zones){
                    _.forEach($scope.item.config.params, function(param){
                        if(param.enabled){
                            param.zones = tools.copy(zones);
                        }
                    });
                },
                copyZonesPower : function(zones){
                    _.forEach($scope.item.config.params, function(param){
                        if(param.enabled){
                            param.zonesPower = tools.copy(zones);
                        }
                    });
                },
                copyZonesCost : function(zones){
                    _.forEach($scope.item.config.params, function(param){
                        if(param.enabled){
                            param.zonesCost = tools.copy(zones);
                        }
                    });
                },
				isOutputActive:function(output){
					return $scope.item.config.outputIds && $scope.item.config.outputIds.indexOf(output.id) >= 0;
				},
				activateOutput: function(output){
					if($scope.item.config.outputIds==null){
						$scope.item.config.outputIds=[];
					}
					$scope.item.config.outputIds.push(output.id);
				},
				deactivateOutput: function(output){
					if($scope.item.config.outputIds==null){
						$scope.item.config.outputIds=[];
					}
					var index = $scope.item.config.outputIds.indexOf(output.id);
					if (index >= 0) {
						$scope.item.config.outputIds.splice(index, 1);
					}
				},
                activateOutputInDevice: function(output, all){
                    for (var i=0; i<$scope.item.config.params.length; i++){
                        var p = $scope.item.config.params[i];
                        if(p.select || all){
                            var mr = _.find(p.momentRules, function(m){
                                return m.rule.actions[0].outputId == output.id;
                            });

                            var deadband=Number(output.deadBand ? output.deadBand[0] : null)
                            var force=Number(output.deadBand ? output.deadBand[1] : null)
                            if(!mr){
                                var r = {
                                            id: uuid.v1(),
                                            name: 'r' + '-' + (p.momentRules.length + 1),
                                            type: 'stateless',
                                            rule: createDefaultMomentRuleDef($scope.config.engine)
                                        };
                                r.rule.actions[0].outputId = output.id;
                                if(deadband || force){
                                    r.rule.expr = "deadband(" + deadband + "," + force + ")";
                                }
                                p.momentRules.push(r);
                            }else{
                                if(deadband || force){
                                    mr.rule.expr = "deadband(" + deadband + "," + force + ")";
                                }
                            }
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                deactivateOutputInDevice: function(output, all){
                    for (var i=0; i<$scope.item.config.params.length; i++){
                        var p = $scope.item.config.params[i];
                        if(p.select || all){
                            var mr = _.find(p.momentRules, function(m){
                                return m.rule.actions[0] ? m.rule.actions[0].outputId == output.id : false;
                            });
                            p.momentRules = _.without(p.momentRules, mr);
                        }
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                getParamUnit: function(param){
                    return unitFilter(1, 0, param.unit || $filter('quantityUnit')(param.quantity), param.scale).substring(2);
                },
                startCharackterHandler: function(){
                    $scope.item.config.startCharackter = $scope.item.config.startCharackter.replace(/\s/g,'').split(",")
                }
            };

            var measurementInputs = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
            var moduleInputs = _.flatten(_.pluck($scope.config.engine.moduleInputs, 'devices'));
            var devices = _.flatten([measurementInputs, moduleInputs]);
            var params = _.flatten(_.pluck(devices, 'params'))

            _.forEach($scope.item.config.params, function(p){
                var param = _.findWhere(params, {id: p.paramId})
                if (param) {
                    if (p.type === 'energy-report') {
						p.quantity = param.quantity;
						p.scale = param.scale;
						p.unit = param.unit;
                        _.forEach(devices, function(d){
                            if(_.findWhere(d.params, {id: param.id})){
                                p.fullLabel = (d.label || d.name) + " - " + (param.label || param.name)
                            }
                        })
                    }
                }
            })

            $scope.addui.element = function () {
                return {
                    type: 'external',
                    name: 'param-' + shortid.gen(),
                    label: 'param-' + ($scope.item.config.params.length + 1)
                };
            };

            $scope.addui.valid = function () {
                return $scope.item.config.paramsAddable;
            };

            $scope.addui.append = function (element, config) {
                config.params.push(element);
            };

            if ($scope.item.config.type === 'energy-report') {
                $scope.$emit('options', [{
                    css: 'btn-success',
                    icon: 'fa-plus',
                    label: 'config.moduleInput.energy-report.addParameter',
                    enabled: true,
                    input: true,
                    count: 1,
                    click: function (count) {
                        if(!count){
                            count=1;
                        }

                        var param = {
                            type: 'energy-report',
                            name: 'meter-' + shortid.gen(),
                            label: 'meter-' + ($scope.item.config.params.length/2 + 1),
                            quantity: 'energy',//FIXME tu powinien byc pobrana wartosc z prametru
                            count:  count,
                            outputIds: $scope.item.config.outputIds
                        };

                        $scope.addui.element = function () {
                            return param;
                        };

                        $scope.addui.valid = function () {
                            return true;
                        };

                        $scope.addui.append = function (element, config) {
                            if (element.count>1){
                                for (var i=0; i<element.count; i++){
                                    var e = _.clone(element);
                                    if(i>0){
                                        e.id = uuid.v1();
                                        e.name = 'meter-' + shortid.gen();
                                        e.label = 'meter-' + ($scope.item.config.params.length/2 + 1);
                                    }
                                    var e2 = _.clone(element);
                                    e2.id = uuid.v1();
                                    e2.name = 'meter-clone-' + shortid.gen();
                                    e2.label = 'meter-clone-' + ($scope.item.config.params.length/2 + 1);
                                    e2.increase = true;
                                    e2.display = false;
                                    e2.master = e.id;
                                    e2.paramId = e.id;

                                    e.slave = e2.id;

                                    config.params.push(e);
                                    config.params.push(e2);
                                }
                            }else{
                                var e = _.clone(element);
                                e.id = uuid.v1();
                                e.name = 'meter-clone-' + shortid.gen();
                                e.label = 'meter-clone-' + ($scope.item.config.params.length/2 + 1);
                                e.increase = true;
                                e.display = false;
                                e.master = element.id;
                                e.paramId = element.id;
                                element.slave = e.id;

                                config.params.push(element);
                                config.params.push(e);
                            }
                        };

                        $scope.item.config.regenerated = true;
                        $scope.$parent.ui.add();
                    }
                }]);
            }
            else if ($scope.item.config.type === 'prepaid') {
                $scope.$emit('options', [{
                    css: 'btn-success',
                    icon: 'fa-plus',
                    label: 'config.moduleInput.prepaid.addParameter',
                    enabled: true,
                    click: function () {
                        var param = {
                            type: 'prepaid',
                            name: 'meter-' + shortid.gen(),
                            label: i18nFilter('config.moduleInput.prepaid.prepaid') + '-' + ($scope.item.config.params.length + 1),
                            outputIds: $scope.item.config.outputIds
                        };

                        $scope.addui.element = function () {
                            return param;
                        };

                        $scope.addui.valid = function () {
                            return true;
                        };

                        $scope.addui.append = function (element, config) {
                            config.params.push(element);
                        };

                        $scope.item.config.regenerated = true;
                        $scope.$parent.ui.add();
                    }
                }]);
            }
            else if ($scope.item.config.type === 'alarm') {
                $scope.$emit('options', [{
                    css: 'btn-success',
                    icon: 'fa-plus',
                    label: 'config.moduleInput.alarm.addParameter',
                    enabled: true,
                    click: function () {
                        var param = {
                            type: 'alarm',
                            name: 'alarm-' + shortid.gen(),
                            label: i18nFilter('config.moduleInput.alarm.alarm') + '-' + ($scope.item.config.params.length + 1),
                            outputIds: $scope.item.config.outputIds
                        };

                        $scope.addui.element = function () {
                            return param;
                        };

                        $scope.addui.valid = function () {
                            return true;
                        };

                        $scope.addui.append = function (element, config) {
                            config.params.push(element);
                        };

                        $scope.item.config.regenerated = true;
                        $scope.$parent.ui.add();
                    }
                }]);
            }
            else if ($scope.item.config.type === 'control') {
                $scope.$emit('options', [{
                    css: 'btn-success',
                    icon: 'fa-plus',
                    label: 'config.moduleInput.control.addParameter',
                    enabled: true,
                    click: function () {
                        var param = {
                            type: 'control',
                            name: 'control-' + shortid.gen(),
                            label: i18nFilter('config.moduleInput.control.control') + '-' + ($scope.item.config.params.length + 1),
                            outputIds: $scope.item.config.outputIds
                        };

                        $scope.addui.element = function () {
                            return param;
                        };

                        $scope.addui.valid = function () {
                            return true;
                        };

                        $scope.addui.append = function (element, config) {
                            config.params.push(element);
                        };

                        $scope.item.config.regenerated = true;
                        $scope.$parent.ui.add();
                    }
                }]);
            }
            else if ($scope.item.config.type === 'dlms') {
                $scope.$emit('options', [{
                    css: 'btn-success',
                    icon: 'fa-plus',
                    label: 'config.moduleInput.dlms.addParameter',
                    enabled: true,
                    click: function () {
                        var param = {
                            type: 'dlms',
                            name: 'dlms-' + shortid.gen(),
                            label: i18nFilter('config.measurementInput.dlms.dlms') + '-' + ($scope.item.config.params.length + 1),
                            outputIds: $scope.item.config.outputIds
                        };

                        $scope.addui.element = function () {
                            return param;
                        };

                        $scope.addui.valid = function () {
                            return true;
                        };

                        $scope.addui.append = function (element, config) {
                            config.params.push(element);
                        };

                        $scope.item.config.regenerated = true;
                        $scope.$parent.ui.add();
                    }
                }]);
            }
        }
    };
});

module.directive('configFormGroup', function (i18nFilter, $http, advanceView) {
    return {
        scope: true,
        templateUrl: 'config/form/group.html',
        controller: function ($scope, $timeout) {
            $scope.advanceView = advanceView;

            $scope.ui = {}
        }
    };
});

//FIXME (jc) why is this here?
module.directive('configFormCronExpression', function ($filter, Weekdays, i18nFilter) {
    return {
        scope: {
            cronExpression: "=configFormCronExpression",
            secondsEnable: "=secondsEnable",
            minutesEnable: "=minutesEnable",
            nullEnable: "=nullEnable"
        },
        templateUrl: 'config/form/cron-expression.html',
        controller: function ($scope) {
            var i, j;

            $scope.ui = {
                period : null,
                day : null,
                weekday : null,
                hour : null,
                minute : null,
                hstep : null,
                periods : [ {
                    value : 0,
                    label : 'config.cronExpression.minutes'
                },{
                    value : 1,
                    label : 'config.cronExpression.hourly'
                }, {
                    value : 2,
                    label : 'config.cronExpression.daily'
                }, {
                    value : 3,
                    label : 'config.cronExpression.weekly'
                }, {
                    value : 4,
                    label : 'config.cronExpression.monthly'
                } ],
                cronTranslations: {
                    selectAll: i18nFilter('ui.tick.all'),
                    selectNone: i18nFilter('ui.tick.none'),
                    reset: i18nFilter('ui.reset'),
                    search: i18nFilter('ui.search'),
                    nothingSelected: i18nFilter('config.cronExpression.select.hours')
                },
                days : [],
                weekdays : Weekdays,
                hours : [],
                hoursall : [],
                hourssel : [],
                hsteps : [],
                minutesAll : [],
                minutes : [],
                minutesNoEnergy : [{
                        m : '*',
                        label : 'config.cronExpression.every1Minute'
                    }, {
                        m : '*/2',
                        label : 'config.cronExpression.every2Minutes'
                    }, {
                        m : '*/3',
                        label : 'config.cronExpression.every3Minutes'
                    }, {
                        m : '*/4',
                        label : 'config.cronExpression.every4Minutes'
                    }, {
                        m : '*/5',
                        label : 'config.cronExpression.every5Minutes'
                    }, {
                        m : '*/10',
                        label : 'config.cronExpression.every10Minutes'
                    }, {
                        m : '*/15',
                        label : 'config.cronExpression.every15Minutes'
                    }, {
                        m : '*/20',
                        label : 'config.cronExpression.every20Minutes'
                    }, {
                        m : '*/30',
                        label : 'config.cronExpression.every30Minutes'
                    } ],
                minutesEnergy : [{
                        m : '*/15',
                        label : 'config.cronExpression.every15Minutes'
                    } ],
                secondes : [{
                        s : '*',
                        label : 'config.cronExpression.every1Second'
                    }, {
                        s : '*/2',
                        label : 'config.cronExpression.every2Seconds'
                    }, {
                        s : '*/3',
                        label : 'config.cronExpression.every3Seconds'
                    }, {
                        s : '*/4',
                        label : 'config.cronExpression.every4Seconds'
                    }, {
                        s : '*/5',
                        label : 'config.cronExpression.every5Seconds'
                    }, {
                        s : '*/10',
                        label : 'config.cronExpression.every10Seconds'
                    }, {
                        s : '*/15',
                        label : 'config.cronExpression.every15Seconds'
                    }, {
                        s : '*/20',
                        label : 'config.cronExpression.every20Seconds'
                    }, {
                        s : '*/30',
                        label : 'config.cronExpression.every30Seconds'
                    } ],
                columns : [ 1, 2, 3, 4, 5, 6 ],
                parseCronExpression : function() {
                    $scope.ui.createMinutesForEnergyReport();

                    this.period = this.periods[4];
		            this.minute = this.minutes[0];
		            this.seconde = this.secondes[0];
                    this.hour = this.hours[0];
                    this.hstep = this.hsteps[7];
                    this.day = this.days[0];
                    this.weekday = this.weekdays[0];
                    $scope.ui.createHours();
                    $scope.ui.createMinutes();
                    if ($scope.cronExpression) {
                        var e = $scope.cronExpression.split(/\s+/);
                        if (e.length === 6) {
                            var s = parseInt(e[0]);
                            var m = parseInt(e[1]);
                            var h = parseInt(e[2]);
                            var hs = e[2].indexOf(",") > 0 ? e[2].split(",") : null;
                            this.hour = _.find(this.hours, function(hour) {
                                return hour.h === h;
                            });
                            this.minute = _.find(this.minutesAll, function(min) {
                                return min.m === m;
                            });
                            if (e[0] != 0) {
                                var s = e[0];
                                this.period = _.find(this.periods, function(period) {
                                    return period.value === 5;
                                });
                                this.seconde = _.find(this.secondes, function(seconde) {
                                    return seconde.s === s;
                                });
                            } else if (e[3] === "?") {
                                var w = parseInt(e[5]);
                                this.period = _.find(this.periods, function(period) {
                                    return period.value === 3;
                                });
                                this.weekday = _.find(this.weekdays, function(weekday) {
                                    return weekday.value === w;
                                });
                            } else if (e[3] !== "*") {
                                var d = e[3];
                                this.period = _.find(this.periods, function(period) {
                                    return period.value === 4;
                                });
                                this.day = _.find(this.days, function(day) {
                                    return day.value === d;
                                });
                            } else if (hs !== null) {
                                this.period = _.find(this.periods, function(period) {
                                    return period.value === 1;
                                });
                                for (i = 0; i < hs.length; i++) {
                                    $scope.ui.hoursall[parseInt(hs[i])].selected = true;
                                };
                            } else if (e[1].indexOf("*")==0) {
                                var m = e[1];
                                this.period = _.find(this.periods, function(period) {
                                    return period.value === 0;
                                });
                                this.minute = _.find(this.minutes, function(minute) {
                                    return minute.m === m;
                                });
                            } else {
                                this.period = _.find(this.periods, function(period) {
                                    return period.value === 2;
                                });
                            }
                        }
                    }else{
                        this.period = _.find(this.periods, function(period) {
                            return period.value === 6;
                        });
                    }
                },
                updateCronExpression : function() {
                    var expr="";

                        if(!this.minute){
                            this.minute= { m:0};
                        }
                    if (this.period.value == 1) {
                        var line = "";
                        if($scope.ui.hourssel.length==0){
                            $scope.ui.hourssel.push(this.hoursall[0]);
                            $scope.ui.hourssel[0].selected=true;
                        }
                        for (i = 0; i < $scope.ui.hourssel.length; i++) {
                            if($scope.ui.hourssel[i].selected){
                                line += $scope.ui.hourssel[i].h + ",";
                            }
                        }
                        line = line.substring(0, line.length - 1);
                        if(line==="") line ="0";
                        if(this.minute.m.toString().indexOf("*")>=0) this.minute = this.minutesAll[0];
                        expr = "0 " + this.minute.m + " " + line + " * * ?";
                    }
                    else if (this.period.value == 2) {
                        if(this.minute.m.toString().indexOf("*")>=0) this.minute = this.minutesAll[0];
                        if(!this.hour) this.hour = this.hours[0];
                        expr = "0 " + this.minute.m + " " + this.hour.h + " * * ?";
                    }
                    else if (this.period.value == 3) {
                        if(this.minute.m.toString().indexOf("*")>=0) this.minute = this.minutesAll[0];
                        if(!this.hour) this.hour = this.hours[0];
                        expr = "0 " + this.minute.m + " " + this.hour.h + " ? * " + this.weekday.value;
                    }
                    else if (this.period.value == 4) {
                        if(this.minute.m.toString().indexOf("*")>=0) this.minute = this.minutesAll[0];
                        if(!this.hour) this.hour = this.hours[0];
                        expr = "0 " + this.minute.m + " " + this.hour.h + " " + this.day.value + " * ?";
                    }
                    else if (this.period.value == 5) {
                        if(this.seconde.s.toString().indexOf("*")!=0) this.seconde = this.secondes[0];
                        expr = this.seconde.s + " * * * * ?";
                    }
                    else if (this.period.value == 0) {
                        if(this.minute.m.toString().indexOf("*")!=0) this.minute = this.minutes[0];
                        expr = "0 " + this.minute.m + " * * * ?";
                    }
                    $scope.cronExpression = expr;
                },
                createHours : function(){
                    for (i = 0; i < 24; i++) {
                        $scope.ui.hoursall.push({
                            selected : false,
                            label : i + ":00",
                            h : i
                        });
                    }
                },
                createMinutes : function(){
                    for (i = 0; i < 60; i+=5) {
                        $scope.ui.minutesAll.push({
                            selected : false,
                            label : ":" + i,
                            m : i
                        });
                    }
                },
                createMinutesForEnergyReport : function(){
                    if($scope.minutesEnable){
                        this.minutes = this.minutesNoEnergy;
                    }else{
                        this.minutes = this.minutesEnergy;
                    }
                }
            };
            if($scope.secondsEnable){
                $scope.ui.periods.unshift({
                    value : 5,
                    label : 'config.cronExpression.second'
                });
            }
            if($scope.nullEnable){
                $scope.ui.periods.push({
                    value : 6,
                    label : 'config.cronExpression.nully'
                });
            }

            for (i = 1; i < 29; ++i) {
                $scope.ui.days.push({
                    value : "" + i,
                    label : "" + i
                });
            }
            $scope.ui.days.push({
                value : "L",
                label : $filter('i18n')('ui.cron.last')
            });

            for (i = 0; i < 24; ++i) {
                for (j = 0; j < 60; j += 60) {
                    $scope.ui.hours.push({
                        h : i,
                        m : j,
                        label : (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j)
                    });
                }
            }

            for (i = 1; i < 24; ++i) {
                $scope.ui.hsteps.push({
                    value : i,
                    label : "" + i
                });
            }
            $scope.ui.parseCronExpression();
        }
    };
});


//FIXME (jc) move to configService
var createDefaultMomentRuleDef = function (config, empty) {
    return {
        expr: '',
        actions: empty ? [] : [{
            id: uuid.v1(),
            type: 'output',
            outputId: config.measurementOutputs.length ? config.measurementOutputs[0].id : null
        }]
    };
};

module.directive('configFormParameter', function (i18nFilter, Quantities, UnitScales, $http, advanceView, unitFilter) {
    var Scales = _.clone(UnitScales);
    Scales.unshift({
        value: null,
        label: "auto"
    });

    var EXPR_RE = /^current\.value\s*(<|>|<=|>=)\s*(-?\d+(:?\.\d+)?)/;

    return {
        scope: true,
        templateUrl: 'config/form/parameter.html',
        controller: function ($scope, $timeout) {

            $scope.advanceView=advanceView;
            $scope.succesVisible=false;
            $scope.errorVisible=false;
            $scope.measurement = [{
                device : $scope.item.parent.name,
                param : $scope.item.config.name,
                timestamp : Date.now(),
                value : 24.0,
                retryCount : 0
            }]

            $scope.sendMesurement = function(){
                $http({
                  method: 'POST',
                  url: '/input/ext/input-3',
                  data: $scope.measurement
                }).then(function successCallback(response) {
                    $scope.succesVisible=true;
                    $timeout(callAtTimeout, 3000);
                  }, function errorCallback(response) {
                    $scope.errorVisible=true;
                    $timeout(callAtTimeout, 3000);
                  });
            }

            $scope.checkScale = function(){
                if($scope.item.config.unit == ''){
                    $scope.item.config.scale = 0;
                }
            }

            function callAtTimeout() {
                $scope.succesVisible=false;
                $scope.errorVisible=false;
            }

            $scope.ui = {
                booleans:[{"value":false,"label":"config.param.inverted.false"},{"value":true,"label":"config.param.inverted.true"}],
                booleansIncrease:[{"value":false,"label":"config.param.increase.false"},{"value":true,"label":"config.param.increase.true"}],
                quantities: _.sortBy(_.each(Quantities, function (q) {
                    q.label = i18nFilter('quantity.' + q.type);
                    if (q.unit) {
                        q.label += ' (' + q.unit + ')';
                    }
                }), function (q) {
                    return q.label;
                }),
                scales: function () {
                    var q = _.findWhere(Quantities, { type: $scope.item.config.quantity });
                    return _.filter(Scales, function (scale) {
                        return scale.value == null || (q && !q.integer) || scale.value >= 0;
                    });
                },
                addMomentRule: function () {
                    $scope.item.config.momentRules.push({
                        id: uuid.v1(),
                        name: 'r' + '-' + ($scope.item.config.momentRules.length + 1),
                        type: 'stateless',
                        rule: createDefaultMomentRuleDef($scope.config.engine)
                    });
                    $scope.$emit('modelErrors.update', true);
                },
                addMomentAlarmRule: function(){
                    var notifier = $scope.config.engine.notifiers[0];
                    $scope.item.config.momentRules.push({
                        id: uuid.v1(),
                        name: 'r' + '-' + ($scope.item.config.momentRules.length + 1),
                        positive:{
                            expr: 'current.value<0',
                            actions:[{
                                type:'notifier',
                                notifierId: notifier ? notifier.id : null
                            }]
                        },
                        negative:{
                            expr: 'current.value>=0',
                            actions:[{
                                type:'notifier',
                                notifierId: notifier ? notifier.id : null
                            }]
                        },
                        type: "toggle",
                        value: 0,
                        hysteresis: 0,
                        updown: true
                    });
                    $scope.$emit('modelErrors.update', true);
                },
                addMomentControlRule: function(){
                    $scope.item.config.momentRules.push({
                        id: uuid.v1(),
                        name: 'r' + '-' + ($scope.item.config.momentRules.length + 1),
                        positive:{
                            expr: 'current.value<0',
                            actions:[{
                                type:'parameter-write'
                            }]
                        },
                        negative:{
                            expr: 'current.value>=0',
                            actions:[{
                                type:'parameter-write'
                            }]
                        },
                        type: "toggle",
                        value: 0,
                        hysteresis: 0,
                        edge: true
                    });
                    $scope.$emit('modelErrors.update', true);
                },
                removeMomentRule: function (rule) {
                    var i = $scope.item.children.indexOf(rule);
                    if (i >= 0) {
                        $scope.item.config.momentRules.splice(i, 1);
                        $scope.$emit('modelErrors.update', true);
                    }
                },
                setDefaultParamName: function() {
                    if (typeof($scope.item.config.paramId) !== 'undefined') {
                        var d = _.find($scope.devices, function (device) {
                            return _.contains(_.pluck(device.params, 'id'), $scope.item.config.paramId);
                        });

                        var p = _.find(d.params, function (param) {
                            return param.id === $scope.item.config.paramId;
                        });
                        $scope.item.config.name = d.name + '/' + p.name;
                    }
                },
                addParameter: function() {
                    $scope.item.config.paramIds.push(null);
                    $scope.$emit('modelErrors.update', true);
                },
                removeParameter: function(index) {
                    $scope.item.config.paramIds.splice(index, 1);
                    if (!$scope.item.config.paramIds.length) {
                        $scope.item.config.paramIds = [ null ];
                    }
                    $scope.$emit('modelErrors.update', true);
                },
                sumAllMath: function(){
                    var line = "m[0].value";
                    for(var i=1; i<$scope.item.config.paramIds.length; i++){
                        line=line + "+m[" + i + "].value";
                    }
                    $scope.item.config.expr = line;
                },
                getWritableParams: function () {
                    if (this.params == null) {
                        this.params = [];
                        for (var i = 0; i < $scope.config.engine.measurementInputs.length; ++i) {
                            var input = $scope.config.engine.measurementInputs[i];
                            for (var d = 0; d < input.devices.length; ++d) {
                                var device = input.devices[d];
                                for (var p = 0; p < device.params.length; ++p) {
                                    var param = device.params[p];
                                    if (param.writable) {
                                        this.params.push({
                                            id: param.id,
                                            name: (device.label?device.label:device.name) + ': ' + (param.label?param.label:param.name)
                                        });
                                    }
                                }
                            }
                        }
                    }
                    return this.params;
                },
                updateHysteresisRule: function (rule) {
                    if (rule.value === undefined) {
                        if (rule.positive && rule.negative) {
                            var m1 = EXPR_RE.exec(rule.positive.expr.trim());
                            var m2 = EXPR_RE.exec(rule.negative.expr.trim());
                            if (m1 && m2) {
                                rule.value1 = parseFloat(m1[2]);
                                rule.value2 = parseFloat(m2[2]);
                                rule.updown = m1[1] === '>' || m1[1] === '>=';
                                if (rule.updown) {
                                    rule.value = rule.value1;
                                    rule.hysteresis = Math.abs(rule.value2 - rule.value1);
                                } else {
                                    rule.value = rule.value1;
                                    rule.hysteresis = Math.abs(rule.value2 - rule.value1);
                                }
                                return;
                            }
                        }
                        rule.value = NaN;
                        rule.hysteresis = NaN;
                        rule.updown = null;
                        rule.value1 = NaN;
                        rule.value2 = NaN;
                    }
                },
                format: function (value) {
                    return unitFilter(value, $scope.ui.precision(), $scope.item.config.unit, $scope.ui.scale());
                },
                unit: function () {
                    return unitFilter(0, -1, $scope.item.config.unit || '-', $scope.item.config.scale);
                },
                scale: function () {
                    return $scope.item.config.scale || 0;
                },
                precision: function () {
                    return $scope.item.config.precision || 1;
                },
                ruleActivationValue: function (rule) {
                    this.updateHysteresisRule(rule);
                    return rule.value1;
                },
                ruleDeactivationValue: function (rule) {
                    this.updateHysteresisRule(rule);
                    return rule.value2;
                },
                setScaleAndUnit: function (val){
                    var devices = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
                    devices = devices.concat(_.flatten(_.pluck($scope.config.engine.moduleInputs, 'devices')));
                    var param = null;
                    var device = _.find(devices, function (device) {
                        param = _.find(device.params, function (param) {
                            return param.id === val;
                        });
                        return param != null;
                    });
                    if (param) {
                        $scope.item.config.quantity = param.quantity;
                        $scope.item.config.scale = param.scale;
                        $scope.item.config.precision = param.precision;
                        $scope.item.config.unit = param.unit;
                    }
                }
            };

            $scope.ui.setScaleAndUnit($scope.item.config.inputParamId);

            if ($scope.item.config.paramIds != null && !$scope.item.config.paramIds.length) {
                $scope.item.config.paramIds = [ null ];
                $scope.$emit('modelErrors.update', true);
            }

            $scope.$watch('item.config.quantity', function (nval, oval) {
                if (nval !== oval && $scope.item.config.type !== 'prepaid') {
                    var q = _.findWhere(Quantities, { type: nval });
                    $scope.item.config.scale = 0;
                    $scope.item.config.precision = q.integer ? 0 : 2;
                    $scope.item.config.unit = q.unit;
                }
            });

            $scope.$watch('item.config.inputParamId', function (nval, oval) {
                if (nval !== oval) {
                    $scope.ui.setScaleAndUnit(nval);
                    var devices = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
                    devices = devices.concat(_.flatten(_.pluck($scope.config.engine.moduleInputs, 'devices')));
                    var param = null;
                    var device = _.find(devices, function (device) {
                        param = _.find(device.params, function (param) {
                            return param.id === nval;
                        });
                        return param != null;
                    });
                    $scope.item.config.quantity = param.quantity;
                    $scope.item.config.scale = param.scale;
                    $scope.item.config.precision = param.precision;
                    $scope.item.config.unit = param.unit;
                }
            });
        }
    };
});

module.directive('configFormParameterChooser', function ($parse) {
    return {
        scope: true,
		replace: true,
        templateUrl: 'config/form/parameter-chooser.html',
        controller: function ($scope, $element, $attrs) {
            var getter = $parse($attrs.configFormParameterChooser);
            var setter = getter.assign;

            $scope._chooser = {
                path: $attrs.path ? $scope.$parent.$eval($attrs.path) : $attrs.configFormParameterChooser,
                device: null,
                param: null,
            };

			$scope.ui = {
				showHelp: $attrs.showHelp ? $scope.$parent.$eval($attrs.showHelp) : true,
				deviceLabel: function (device) {
					return device ? (device.label || device.name) : null;
				},
				paramLabel: function (param) {
					if (param) {
						if (param.unit) {
							return (param.label || param.name) + ' [' + param.unit + ']';
						} else {
							return param.label || param.name;
						}
					} else {
						return null;
					}
				}
			};

            function update() {
                var id = getter($scope.$parent);
                var _chooser = $scope._chooser;
                if (!_chooser.devices) {
//                    _chooser.devices = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
                    var measurementInputs = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
                    var moduleInputs = _.flatten(_.pluck($scope.config.engine.moduleInputs, 'devices'));
                    _chooser.devices = _.filter(_.flatten([measurementInputs, moduleInputs]), function(d){
                        return (d.type != "alarm" && d.type != "control")?true: false;
                    });
                }
                if (id) {
                    _chooser.device = _.find(_chooser.devices, function (device) {
                        var param = _.find(device.params, function (param) {
                            return param.id === id;
                        });
                        _chooser.param = param;
                        return param != null;
                    });
                }
            }

            $scope.$parent.$watch($attrs.configFormParameterChooser, function (nval, oval) {
                if (nval !== oval) {
                    update();
                }
            });

            $scope.$watch('_chooser.device', function (nval, oval) {
                if (nval !== oval) {
                    var p = nval.params && nval.params.length ? nval.params[0] : null;
                    $scope._chooser.param = p;
                    //setter($scope.$parent, p ? p.id : null);
                }
            });

            $scope.$watch('_chooser.param', function (nval, oval) {
                if (nval !== oval) {
                    setter($scope.$parent, nval ? nval.id : null);
                }
            });

            update();


            /*var w = $element.innerWidth() - 10 - ($scope.hideHelp ? 0: 35 );
            var w1 = Math.round(0.4 * w);
            var w2 = w - w1 - ($scope.hideHelp ? 0: 10 );
            var s = $element.find(".form-control");
            s.eq(0).css('width', w1);
            s.eq(1).css('width', w2);*/
        }
    }
});

module.directive('configFormMomentRule', function ($window, MomentRuleTypes) {
    return {
        scope: {
            item: '=configFormMomentRule',
            config: '=config'
        },
        templateUrl: 'config/form/moment-rule.html',
        controller: function ($scope) {
            $scope.ui = {
                ruleTypes: MomentRuleTypes,
                update: function () {
                    var config = $scope.item.config;
                    var engineConfig = $scope.item.getRoot().config;
                    if (config.type === 'stateless') {
                        if (!config.rule) {
                            config.rule = createDefaultMomentRuleDef(engineConfig);
                            delete config.positive;
                            delete config.negative;
                        }
                        if (!$scope.item._rule) {
                            $scope.item._rule = {}
                        }
                        $scope.item._rule.config = config.rule;
                        $scope.item._rule.engineConfig = engineConfig;
                        if($scope.item.error){
                            $scope.item._rule.errors = $scope.item.errors.getNestedErrors('rule');
                        }else{
                            $scope.item._rule.errors = null;
                        }
                        delete $scope.item._positive;
                        delete $scope.item._negative;
                    } else if (config.type === 'toggle') {
                        if (!config.positive || !config.negative) {
                            config.positive = createDefaultMomentRuleDef(engineConfig);
                            config.negative = createDefaultMomentRuleDef(engineConfig, true);
                            delete config.rule;
                        }
                        $scope.item._positive = {
                            config: config.positive,
                            engineConfig: engineConfig,
                            errors: $scope.item.errors.getNestedErrors('positive')
                        };
                        $scope.item._negative = {
                            config: config.negative,
                            engineConfig: engineConfig,
                            errors: $scope.item.errors.getNestedErrors('negative')
                        };
                        delete $scope.item._rule;
                    }
                }
            };

            $scope.ui.update();

            $scope.$watch('item.config', function (nval, oval) {
                if (nval !== oval) {
                    $scope.ui.update();
                }
            });
        },
        link: function (scope, element) {
            var $w = $($window);
            var panel = $("li.list-group-item");
            var $body = $("body");

            function resize() {
                element.outerWidth(element.parent().innerWidth() - element.next().outerWidth() - 30);
                if($body.width()<768){
                    element.width(panel.width()-50);
                }
            }

            $w.on('size', resize);

            element.on('$destroy', function () {
                $w.off('size', resize);
            });

            $(document).ready(function(){
                $(window).resize(function(){
                    resize();
                });
            });

            resize();
        }
    };
});

module.directive('configFormMomentRuleDef', function (MomentActionTypes) {
    return {
        scope: {
            item: '=configFormMomentRuleDef',
            config: '=config'
        },
        templateUrl: 'config/form/moment-rule-def.html',
        controller: function ($scope) {
            $scope.ui = {
                actionTypes: MomentActionTypes,
                getWritableParams: function () {
                    if (this.params == null) {
                        this.params = [];
                        for (var i = 0; i < $scope.config.engine.measurementInputs.length; ++i) {
                            var input = $scope.config.engine.measurementInputs[i];
                            for (var d = 0; d < input.devices.length; ++d) {
                                var device = input.devices[d];
                                for (var p = 0; p < device.params.length; ++p) {
                                    var param = device.params[p];
                                    if (param.writable) {
                                        this.params.push({
                                            id: param.id,
                                            name: (device.label?device.label:device.name) + ': ' + (param.label?param.label:param.name)
                                        });
                                    }
                                }
                            }
                        }
                    }
                    return this.params;
                },
                addAction: function () {
                    $scope.item.config.actions.push({
                        type: 'output'
                    });
                    $scope.$emit('modelErrors.update', true);
                },
                removeAction: function (action) {
                    var i = $scope.item.config.actions.indexOf(action);
                    if (i >= 0) {
                        $scope.item.config.actions.splice(i, 1);
                        $scope.$emit('modelErrors.update', true);
                    }
                }
            };
        }
    };
});

module.directive('configFormMomentRuleSimple', function ($window, MomentActionTypes, i18nFilter) {
    return {
        scope: {
            item: '=configFormMomentRuleSimple',
            config: '=config',
            hide: '=hide',
            param: '=param',
            params: '=params'
        },
        templateUrl: 'config/form/moment-rule-simple.html',
        controller: function ($scope) {
            $scope.actionTypes = MomentActionTypes,
            $scope.outputs = $scope.config.engine.measurementOutputs;
            $scope.simpleRulesStorage = [];
            $scope.ruleNotShow = 0;

            $scope.addMomentRule = function (output) {
                $scope.item.push({
                    id: uuid.v1(),
                    name: 'r' + '-' + ($scope.item.length + 1),
                    type: 'stateless',
                    rule: createDefaultMomentRuleDef($scope.config.engine)
                });

                $scope.item[$scope.item.length-1].rule.actions[0].outputId=output;
                $scope.item[$scope.item.length-1].rule.expr=true;
                $scope.update();
                $scope.$emit('modelErrors.update', true);
            };
            $scope.removeMomentRule = function (ruleId) {
                var rule = _.findWhere($scope.item, {'id' : ruleId})
                var i = $scope.item.indexOf(rule);
                if (i >= 0) {
                    $scope.item.splice(i, 1);
                    $scope.update();
                    $scope.$emit('modelErrors.update', true);
                }
            }
            $scope.update = function(){
                $scope.simpleRulesStorage=[];
                $scope.ruleNotShow=$scope.item ? $scope.item.length : 0;

                var itemsToRemove = [];
                _.forEach($scope.item, function(item){
                    if(item.type=="stateless" && item.rule.actions[0] && item.rule.actions[0].type=="output"){
                        var output = _.findWhere($scope.outputs, {id: item.rule.actions[0].outputId});
                        if (!output) {
                            itemsToRemove.push(item);
                        }
                    }
                })
                _.forEach(itemsToRemove, function(i){
                    $scope.item = _.without($scope.item, i);
                });

                _.forEach($scope.item, function(item){
                    if(item.type=="stateless" && item.rule.actions[0] && item.rule.actions[0].type=="output"){
                        if(item.rule.expr && item.rule.expr.indexOf && item.rule.expr.indexOf("deadband(")>=0){
                            var str = item.rule.expr;
                            str = str.replace("deadband(", "");
                            str = str.replace(")", "");
                            item.rule.deadBand = str.split(",")
                        }

                        var output = _.findWhere($scope.outputs, {id: item.rule.actions[0].outputId});
                        if (!output) {
                            output= {};
                            output.label="deleted";
                        }else{
                            $scope.simpleRulesStorage.push({'ruleId': item.id,
                                                'outputId': item.rule.actions[0].outputId,
                                                'outputName': output.label || output.name ,
                                                'deadBand': item.rule.deadBand || 0,
                                                'expr' : item.expr,
                                                'rule' : item.rule
                                                });
                        }
                        $scope.ruleNotShow--;
                    }
                });

                _.forEach($scope.outputs, function(output){
                    var is = _.findWhere($scope.simpleRulesStorage, {outputId: output.id});
                    if(!is){
                        $scope.simpleRulesStorage.push({'ruleId': null,
                                                'outputId': output.id,
                                                'outputName': output.label || output.name}
                                                );
                    }
                });
            };
            $scope.updateDeadBand = function(rule, i){
                var deadband=Number(rule.deadBand[0])
                var force=Number(rule.deadBand[1])

                _.forEach($scope.item, function(item){
                    if(item.id == rule.ruleId){
                        item.rule.expr = "deadband(" + deadband + "," + force + ")";
                    }
                });
            };

            $scope.selectAllFor = function(value){
                _.forEach($scope.params, function(param){
                    param.select=value
                });
//                if($scope.$parent.$parent.$parent.device){
//                    _.forEach($scope.$parent.$parent.$parent.device.params, function(param){
//                        param.select=value
//                    });
//                }else{
//                console.log($scope.$parent)
//                }
            }
            $scope.update();
        },
        link: function (scope, element) {}
    };
});


module.directive('configFormMomentRuleAlarm', function ($window, MomentRuleTypes, MomentActionTypes, i18nFilter, unitFilter) {
    return {
        scope: {
            item: '=configFormMomentRuleAlarm',
            config: '=config',
            hide: '=hide',
            paramName: '=paramName'
        },
        templateUrl: 'config/form/moment-rule-alarm.html',
        controller: function ($scope) {
            $scope.ui = {
                ruleTypes: MomentRuleTypes,
                setScaleAndUnit: function(val){
                    var devices = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
                    devices = devices.concat(_.flatten(_.pluck($scope.config.engine.moduleInputs, 'devices')));
                    var param = null;
                    var device = _.find(devices, function (device) {
                        param = _.find(device.params, function (param) {
                            return param.id === val;
                        });
                        return param != null;
                    });
                    if(param){
                        $scope.item.config.quantity = param.quantity;
                        $scope.item.config.scale = param.scale;
                        $scope.item.config.precision = param.precision;
                        $scope.item.config.unit = param.unit;
                    }
                },
                updateRule: function (rule){
                    var notifier = _.findWhere($scope.config.engine.notifiers, { id: rule.positive.actions[0].notifierId });
                    if (notifier && notifier.type !== rule.notifier) {
                        rule.notifier = notifier.type;
                    }
                    if (rule.negative.actions[0]) {
                        rule.negative.actions[0].type = rule.positive.actions[0].type;
                        rule.negative.actions[0].notifierId = rule.positive.actions[0].notifierId;
                        rule.negative.actions[0].to = rule.positive.actions[0].to;
                    }
                },
                updateExpression: function (rule){
                    rule.value1 = rule.value;
                    if (rule.updown) {
                        rule.value2 = rule.value - rule.hysteresis;
                        rule.positive.expr = "current.value>=" + (rule.value1 || 0);
                        rule.negative.expr = "current.value<" + (rule.value2 || 0);
                    } else {
                        rule.value2 = rule.value + rule.hysteresis;
                        rule.positive.expr = "current.value<=" + (rule.value1 || 0);
                        rule.negative.expr = "current.value>" + (rule.value2 || 0);
                    }
                    rule.value = undefined;
                },
                setNegativeAction :function(){
                    if (!$scope.item.config.negative.actions[0]) {
                        $scope.item.config.negative.actions = [{}];
                        $scope.item.config.negative.actions[0].type = $scope.item.config.positive.actions[0].type;
                        $scope.item.config.negative.actions[0].notifierId = $scope.item.config.positive.actions[0].notifierId;
                        $scope.item.config.negative.actions[0].to = $scope.item.config.positive.actions[0].to;
                    } else {
                        $scope.item.config.negative.actions = [];
                    }
                }
            };

            $scope.pui = $scope.$parent.$parent.$parent.ui;

            $scope.ui.updateRule($scope.item.config);

            $scope.$watch('item.config', function (nval, oval) {
                if (nval !== oval) {
                    $scope.ui.updateRule(nval);
                }
            });

            // $scope.ui.setScaleAndUnit($scope.item.parent.config.inputParamId);
            // $scope.$watch('item.parent.config.inputParamId', function (nval, oval) {
            //     if (nval !== oval) {
            //         $scope.ui.setScaleAndUnit(nval);
            //     }
            // });

            $scope.hysteresisDir = [
                {
                    value : false,
                    name: i18nFilter("config.momentRule.direction.down")
                },
                {
                    value : true,
                    name: i18nFilter("config.momentRule.direction.up")
                }
            ]
        },
        link: function (scope, element) {}
    };
});



module.directive('configFormMomentRuleControl', function ($window, MomentRuleTypes, MomentActionTypes, i18nFilter, unitFilter) {
    return {
        scope: {
            item: '=configFormMomentRuleControl',
            config: '=config',
            hide: '=hide',
            paramName: '=paramName'
        },
        templateUrl: 'config/form/moment-rule-control.html',
        controller: function ($scope) {
            $scope.ui = {
                ruleTypes: MomentRuleTypes,
                /*setScaleAndUnit: function(val){
                    var devices = _.flatten(_.pluck($scope.config.engine.measurementInputs, 'devices'));
                    devices = devices.concat(_.flatten(_.pluck($scope.config.engine.moduleInputs, 'devices')));
                    var param = null;
                    var device = _.find(devices, function (device) {
                        param = _.find(device.params, function (param) {
                            return param.id === val;
                        });
                        return param != null;
                    });
                    if(param){
                        $scope.item.config.quantity = param.quantity;
                        $scope.item.config.scale = param.scale;
                        $scope.item.config.precision = param.precision;
                        $scope.item.config.unit = param.unit;
                    }
                },*/
                unitFilterFun: function(d, p){
                    if (p != null) {
                        return unitFilter(d, p.precision, p.unit, p.scale);
                    } else {
                        return "---"
                    }
                },
                update: function(){
                    if($scope.item.config.type=="toggle" && $scope.item.config.positive.type==$scope.item.config.negative.type){
                        var str = $scope.item.config.positive.expr;
                        str = str.substring(14);
                        var arr = []
                        if(str.indexOf("+")>=0){
                            arr = str.split("+")
                        }else{
                            arr = str.split("-")
                        }
                        if($scope.item.config.negative.expr.indexOf(">")>=0){
                            $scope.item.config.updown=true;
                        }else{
                            $scope.item.config.updown=false;
                        }
                        $scope.item.config.value = parseFloat(arr[0])
                        $scope.item.config.hysteresis = parseFloat(arr[1])

                        var outDevice = _.findWhere($scope.getWritableParams(), {id: $scope.item.config.positive.actions[0].paramId});
                        $scope.item.config.outDevice = outDevice ? outDevice.name : null
                        if(outDevice && outDevice.type && outDevice.type=='outputbinary'){
                            $scope.item.config.positive.actions[0].paramValue = 0;
                            $scope.item.config.negative.actions[0].paramValue = 1;
                        }
                    }
                },
                updateNotifie: function(notifie){
                    notifie.negative.actions[0].type=notifie.positive.actions[0].type;
                    notifie.negative.actions[0].paramId=notifie.positive.actions[0].paramId;
                    notifie.negative.actions[0].to=notifie.positive.actions[0].to;
                    var outDevice = _.findWhere($scope.getWritableParams(), {id: notifie.positive.actions[0].paramId});
                    notifie.outDevice = outDevice ? outDevice.name : null
                    if(outDevice.type && outDevice.type=='outputbinary'){
                        $scope.item.config.positive.actions[0].paramValue = 1;
                        $scope.item.config.negative.actions[0].paramValue = 0;
                    }
                },
                updateExpresion: function(rule){
                    if(!rule.value){
                        rule.value=0;
                    }
                    if(!rule.hysteresis){
                        rule.hysteresis=0;
                    }
                    if(rule.updown){
                        rule.negative.expr = "current.value>=" + rule.value
                        rule.positive.expr = "current.value<" + rule.value + "-" + rule.hysteresis
                    }else{
                        rule.negative.expr = "current.value<=" + rule.value
                        rule.positive.expr = "current.value>" + rule.value  + "+" + rule.hysteresis
                    }
                }
            };


            $scope.$watch('item.config', function (nval, oval) {
                if (nval !== oval) {
                    $scope.ui.update();
                }
            });
            /*$scope.ui.setScaleAndUnit($scope.item.parent.config.inputParamId);
            $scope.$watch('item.parent.config.inputParamId', function (nval, oval) {
                if (nval !== oval) {
                    $scope.ui.setScaleAndUnit(nval);
                }
            });*/

            $scope.hysteresisDir = [
                {
                    value : false,
                    name: i18nFilter("config.momentRule.direction.down")
                },
                {
                    value : true,
                    name: i18nFilter("config.momentRule.direction.up")
                }
            ]

            $scope.getWritableParams = function () {
                if (this.params == null) {
                    this.params = [];
                    for (var i = 0; i < $scope.config.engine.measurementInputs.length; ++i) {
                        var input = $scope.config.engine.measurementInputs[i];
                        for (var d = 0; d < input.devices.length; ++d) {
                            var device = input.devices[d];
                            for (var p = 0; p < device.params.length; ++p) {
                                var param = device.params[p];
                                if (param.writable) {
                                    this.params.push({
                                        id: param.id,
                                        name: (device.label?device.label:device.name) + ': ' + (param.label?param.label:param.name),
                                        type: param.type
                                    });
                                }
                            }
                        }
                    }
                }
                return this.params;
            }
            $scope.ui.update();
        },
        link: function (scope, element) {}
    };
});

module.directive('configFormEventRules', function (EventRuleTypes, advanceView, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/event-rules.html',
        controller: function ($scope, $element) {

            $scope.advanceView=advanceView;
            $scope.outputs = $scope.config.engine.eventOutputs;
            $scope.simpleRules = []
            $scope.ruleNotShow = 0;
            $scope.hide = true;

            function update() {
                var eventRules = [];
                if ($scope.source.config.eventRules) {
                    for (var i = 0; i < $scope.source.config.eventRules.length; ++i) {
                        eventRules.push({
                            config: $scope.source.config.eventRules[i],
                            errors: $scope.source.errors.getNestedErrors('eventRules[' + i + ']')
                        });
                    }
                }
                $scope.eventRules = eventRules;

                $scope.ruleNotShow=$scope.item.length;

                $scope.simpleRules=[];
                _.forEach($scope.outputs, function(output){
                    var simpleRules = [];
                    _.forEach($scope.source.config.eventTypes, function(type){
                        var ruleId = null
                        _.forEach($scope.source.config.eventRules, function(item){
                            if(item.type=="stateless" &&
                            item.actions[0].outputId==output.id &&
                            item.eventType==type &&
                            item.actions[0].type=='output'){
//                                    var output = _.findWhere($scope.outputs, {id: item.actions[0].outputId});
                                    ruleId = item.id;
                                    $scope.ruleNotShow--;
                            }
                        });
                        simpleRules.push({'ruleId': ruleId,
                                             'outputId': output.id,
                                             'outputName': output.label || output.name,
                                             'type': type})
                    });
                    $scope.simpleRules.push(simpleRules);
                });
            }

            if ($scope.item.kind === 'eventSource') {
                $scope.source = $scope.item;
                for (var i=0; i<$scope.source.config.eventTypes.length; i++){
                    $scope.simpleRules.push([]);
                }
            } else {
                $scope.source = $scope.root.find(function (item) {
                    return item.kind === 'eventSource' && item.config.sourceId === $scope.item.config.id;
                });
            }

            $scope.ui = {
                addEventRule: function () {
                    if (!$scope.source.config.eventRules) {
                        $scope.source.config.eventRules = [];
                    }
                    $scope.source.config.eventRules.push({
                        name: 'r' + '-' + ($scope.source.config.eventRules.length + 1),
                        type: EventRuleTypes[0].type,
                        actions: []
                    });
                    $scope.$emit('modelErrors.update', true);
                },
                removeEventRule: function (index) {
                    if ($scope.source.config.eventRules && $scope.source.config.eventRules.length > index) {
                        $scope.source.config.eventRules.splice(index, 1);
                        $scope.$emit('modelErrors.update', true);
                    }
                }
            };

            $scope.$watchCollection('source.config.eventRules', function () {
                update();
            });
            update();

            $scope.addMomentRule = function (output, type) {
                    $scope.source.config.eventRules.push({
                        id: uuid.v1(),
                        name: 'r' + '-' + ($scope.source.config.eventRules.length + 1),
                        type: 'stateless',
                        rule: createDefaultMomentRuleDef($scope.config.engine),
                        eventType: type,
                        expr: true,
                        actions : [{
                            outputId: output,
                            type: 'output'
                        }]
                    });

                    $scope.source.config.eventRules[$scope.source.config.eventRules.length-1].rule.actions[0].outputId=output;
                    $scope.source.config.eventRules[$scope.source.config.eventRules.length-1].rule.expr=true;
                    update();
                    $scope.$emit('modelErrors.update', true);
            };
            $scope.removeMomentRule = function (ruleId) {
                var rule = _.findWhere($scope.source.config.eventRules, {'id' : ruleId})
                var i = $scope.source.config.eventRules.indexOf(rule);
                if (i >= 0) {
                    $scope.source.config.eventRules.splice(i, 1);
                    update();
                    $scope.$emit('modelErrors.update', true);
                }
            }
        }
    }
});

module.directive('configFormEventRule', function ($window, EventActionTypes) {
    return {
        scope: {
            item: '=configFormEventRule',
            sourceConfig: '=sourceConfig',
            engineConfig: '=engineConfig'
        },
        templateUrl: 'config/form/event-rule.html',
        controller: function ($scope) {
            $scope.ui = {
                actionTypes: EventActionTypes,
                getWritableParams: function () {
                    if (this.params == null) {
                        this.params = [];
                        for (var i = 0; i < $scope.engineConfig.measurementInputs.length; ++i) {
                            var input = $scope.engineConfig.measurementInputs[i];
                            for (var d = 0; d < input.devices.length; ++d) {
                                var device = input.devices[d];
                                for (var p = 0; p < device.params.length; ++p) {
                                    var param = device.params[p];
                                    if (param.writable) {
                                        this.params.push({
                                            id: param.id,
                                            name: (device.label?device.label:device.name) + ': ' + (param.label?param.label:param.name)
                                        });
                                    }
                                }
                            }
                        }
                    }
                    return this.params;
                },
                addAction: function () {
                    $scope.item.config.actions.push({
                        type: 'output'
                    });
                    $scope.$emit('modelErrors.update', true);
                },
                removeAction: function (action) {
                    var i = $scope.item.config.actions.indexOf(action);
                    if (i >= 0) {
                        $scope.item.config.actions.splice(i, 1);
                        $scope.$emit('modelErrors.update', true);
                    }
                }
            };
        },
        link: function (scope, element, attrs, ctrl) {
            var $w = $($window);

            function resize() {
                element.outerWidth(element.parent().innerWidth() - element.next().outerWidth() - 28);
            }

            $w.on('size', resize);

            element.on('$destroy', function () {
                $w.off('size', resize);
            });

            resize();
        }
    }
});


module.directive('configFormMeasurementOutput', function (MeasurementOutputTypes, RollingPeriodicities, i18nFilter, advanceView) {
    return {
        scope: true,
        templateUrl: 'config/form/measurement-output.html',
        controller: function ($scope, $rootScope) {
            $scope.advanceView=advanceView;
            var prepareLinks = function() {
                var links = $scope.item.config.links;
                var result = [];
                var config = $scope.config;
                $scope.db = _.findWhere($scope.config.engine.databases, {id: $scope.item.config.databaseId});

                _.each(links, function (link) {
                    var device = null;
                    _.find(config.engine.measurementInputs, function (input) {
                        device = _.find(input.devices, function (device){
                            return device.id === link.deviceId;
                        });
                        return device != null;
                    });
                    if(device){
                        var param = _.find(device.params, function (param) {
                            return param.id === link.paramId;
                        });
                        var rule = _.find(param.momentRules, function (rule) {
                            return rule.id === link.ruleId;
                        });
                        result.push({
                            device: device,
                            param: param,
                            rule: rule
                        });
                    }
                });

                return result;
            };

            $scope.links = prepareLinks();

            $scope.ui = {
                type: _.find(MeasurementOutputTypes, function (t) {
                    return t.type === $scope.item.config.type;
                }),
                rollingPeriodicities: _.map(RollingPeriodicities, function(periodicity) {
                    return {
                        type: periodicity,
                        label: i18nFilter('config.measurementOutput.csv.rollingPeriodicity.' + periodicity.toUpperCase())
                    }
                }),
                selectLink: function (link) {
                    var item = $scope.item.getRoot().find(function (item) {
                        return item.config.id === link.param.id;
                    });
                    if (item) {
                        $scope.$parent.ui.select(item);
                    }
                }
            };

            // $scope.dirty = false;
            // $rootScope.$on('config.dirty', function (event, dirty) {
            //     $scope.dirty=dirty;
            // });
        }
    };
});

module.directive('configFormEventInput', function ($timeout, EventInputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/event-input.html',
        controller: function ($scope) {
            $scope.ui = {
                type: _.find(EventInputTypes, function (t) {
                    return t.type === $scope.item.config.type;
                }),
                path: function (source) {
                    var p = '';
                    for (var i = 0; i < source.path.length; ++i) {
                        p += source.path[i];
                        if (i < source.path.length - 1) {
                            p += ' / ';
                        }
                    }
                    return p;
                },
                restoreDefaultRules: function () {
                    $scope.item.config.regenerated = true;
                    for (var j = 0; j < $scope.item.config.sources.length; ++j) {
                        $scope.item.config.sources[j].eventRules = null;
                    }
                    $scope.$emit('modelErrors.update', true);
                }
            };

            if ($scope.item.config.type === 'remote') {
                $scope.$emit('options', [{
                    css: 'btn-default',
                    icon: 'fa-refresh',
                    label: 'config.eventInput.remote.getRemoteConfig',
                    enabled: true,
                    click: function () {
                        $scope.item.config.regenerated = true;
                        $scope.$emit('modelErrors.update', true);
                    }
                }]);
            }
        }
    };
});

module.directive('configFormEventOutput', function (EventOutputTypes, i18nFilter) {
    return {
        scope: true,
        templateUrl: 'config/form/event-output.html',
        controller: function ($scope) {

            var prepareLinks = function() {
                var links = $scope.item.config.links;
                var result = [];
                var config = $scope.config;

                _.each(links, function (link) {
                    var source = null;
                    _.find(config.engine.eventInputs, function (input) {
                        source = _.find(input.sources, function (source){
                            return source.id === link.sourceId;
                        });
                        return source != null;
                    });
                    var rule = _.find(source.rules, function (rule) {
                        return rule.id === link.ruleId;
                    });
                    result.push({
                        source: source,
                        rule: rule
                    });
                });

                return result;
            };

            $scope.links = prepareLinks();


            $scope.ui = {
                type: _.find(EventOutputTypes, function (t) {
                    return t.type === $scope.item.config.type;
                }),
                selectLink: function (link) {
                    var item = $scope.item.getRoot().find(function (item) {
                        return item.config.id === link.source.id;
                    });
                    if (item) {
                        $scope.$parent.ui.select(item);
                    }
                }
            };
        }
    };
});

module.directive('configFormDatabase', function (DatabaseTypes, Booleans) {
    return {
        scope: true,
        templateUrl: 'config/form/database.html',
        controller: function ($scope) {
            $scope.ui = {
                booleans: Booleans,
                type: _.find(DatabaseTypes, function (t) {
                    return t.type === $scope.item.config.type;
                })
            };
        }
    };
});

module.directive('configFormDatabaseNew', function (DatabaseTypes, Booleans, advanceView) {
    return {
        scope: {
            configFormDatabaseNew: '=configFormDatabaseNew',
            config: '=config'
            },
        templateUrl: 'config/form/database-new.html',
        controller: function ($scope) {
            _.forEach($scope.config.parent.parent.children[5].children, function(item){
                if(item.config.id == $scope.configFormDatabaseNew){
                    $scope.item = item;
                }
            })
            $scope.ui={
                booleans: Booleans,
				advanceView: function () {
					return advanceView.value;
				}
            }

        }
    };
});

module.directive('configFormMediaCosts', function (MediaQuantities, UnitScales, ModuleInputTypes) {
	return {
		scope: true,
		templateUrl: 'config/form/media-costs.html',
		controller: function ($scope) {
			if (!$scope.item.config.costs) {
				$scope.item.config.costs = []
			}
			$scope.ui = {
				type: _.find(ModuleInputTypes, function (t) {
					return t.type === 'media-costs'
				}) || {},
				add: function () {
					$scope.item.config.costs.push({
						medium: '',
						cost: null,
						currency: '',
						unit: '',
					})
				},
				remove: function (cost) {
					var i = $scope.item.config.costs.indexOf(cost);
					if (i >= 0) {
						$scope.item.config.costs.splice(i, 1);
					}
				},
				unit: function (cost) {
					var u = '';
					if (cost.currency) {
						u += cost.currency;
					}
					u += '/';
					if (cost.scale) {
						var s = _.find(UnitScales, function (s) { return s.value === cost.scale; });
						u += s ? s.label : '';
					}
					if (cost.quantity) {
						var q = _.find(MediaQuantities, function (q) { return q.type === cost.quantity; });
						u += q ? q.unit : '';
					}
					return u;
				},
				quantities: MediaQuantities,
				scales: UnitScales,
			}
		}
	};
});

module.directive('configFormNtp', function(){
    return {
        scope: true,
        templateUrl: 'config/form/ntp.html',
        controller: function($scope, $http) {
            $http.get('/ntp').then(function(response) {
                $scope.ntp = response.data;
            });

            $scope.ui = {
                updateOption: {
                    css: 'btn-primary',
                    icon: 'fa-refresh',
                    label: 'config.ntp.applySettings',
                    enabled: true,
                    click: function () {
                        $http.post('/ntp', $scope.ntp).then(function(response){
                           $scope.$emit('message', 'config.ntp.save.success', 'alert-success');
                        });
                    }
                }
            };

            $scope.$emit('options', [ $scope.ui.updateOption ]);
        }
    }
});

module.directive('configFormApp', function(){
    return {
        scope: true,
        templateUrl: 'config/form/app.html',
        controller: function($scope, $http) {

        }
    }
});

module.directive('configFormNetwork', function(){
    return {
        scope: true,
        templateUrl: 'config/form/network.html',
        controller: function($scope, $http) {
            $http.get('/network').then(function(response) {
                $scope.network = response.data;
            });

            $scope.ui = {
                updateOption: {
                    css: 'btn-primary',
                    icon: 'fa-refresh',
                    label: 'config.network.applySettings',
                    enabled: true,
                    click: function () {
                        $http.post('/network', $scope.network).then(function(response){
                           $scope.$emit('message', 'config.network.save.success', 'alert-success');
                        });
                    }
                }
            };

            $scope.$emit('options', [ $scope.ui.updateOption ]);
        }
    }
});

module.directive('configFormTime', function(){
    return {
        scope: true,
        templateUrl: 'config/form/time.html',
        controller: function($scope, $http, license, contextPath) {
            $http.get('/clock').then(function(response) {
                $scope.ui.datetime = response.data.datetime;
                $scope.ui.timezone = response.data.timezone;
                $scope.ui.ntpEnable = response.data.ntpEnable;
            });

            $scope.timezones=tools.timezones
            $scope.limit = license.properties.TIMELIMITED;
            $scope.ui = {
                datetime: new Date(),
                updateOption: {
                    css: 'btn-primary',
                    icon: 'fa-refresh',
                    label: 'config.network.applySettings',
                    enabled: !$scope.limit,
                    click: function () {
                        if(!$scope.limit){
                            var url = contextPath + '/clock/';
                            $http({
                                method : 'POST',
                                url : url,
                                params : {
                                    'datetime' : $scope.ui.datetime,
                                    'timezone' : $scope.ui.timezone,
                                    'ntpenable' : $scope.ui.ntpEnable
                                }
                            })
                            .success(function(response, status, headers) {
                                $scope.datetime = response.data;
                                $http.get('/clock').then(function(response) {
                                    $scope.datetime = response.data;
                                });
                                $scope.$emit('message', 'config.time.save.success', 'alert-success');
                            }).error(function() {
                                $scope.$emit('message', 'config.time.save.error', 'alert-danger');
                            });
                        }
                    }
                }
            };
            $scope.$emit('options', [ $scope.ui.updateOption ]);
            console.log($scope)
        }
    }
});

module.directive('configFormNotifier', function (NotifierTypes, advanceView) {
    return {
        scope: {
            configFormNotifier: '=configFormNotifier',
            config: '=config'
        },
//        scope: true,
        templateUrl: 'config/form/notifier.html',
        controller: function ($scope, $http, contextPath) {
            $scope.advanceView=advanceView;

            $scope.item = $scope.configFormNotifier

            $scope.ui = {
                type: _.find(NotifierTypes, function (t) {
                    return t.type === $scope.item.config.type;
                })
            };

			if ($scope.item.config.type === 'sms' && !$scope.item.config.cronExpression) {
				$scope.item.config.cronExpression = '0 0 8 * * ?';
			}

            $scope.sendTestMail = function( host, port, ssl, user, pass, from, to){
				$scope.msg = null;
				$scope.mailOk = false;
				$scope.mailErr = false;
                var url = contextPath + '/tests/mailtest/';
                $http({
                    method : 'POST',
                    url : url,
                    params : {  'host' : host,
                                'port' : port,
                                'ssl' : ssl,
                                'user' : user,
                                'pass' : pass,
                                'from' : from,
                                'to' : to
                    }
                })
                .success(function(response, status, headers) {
                    $scope.msg = response;
                    if(response=="ok"){
                        $scope.mailOk = true;
                        $scope.mailErr = false;
                    }else{
                        $scope.mailOk = false;
                        $scope.mailErr = true;
                    }
                }).error(function(response) {
                    console.log(response);
                });
            }

			$scope.sendTestSms = function (dest) {
				$scope.msg = null;
				$scope.mailOk = false;
				$scope.mailErr = false;
				var url = contextPath + '/tests/smstest/';
				$http({
					method : 'POST',
					url : url,
					params : {
						'dest': dest,
					}
				}).success(function (response) {
					if (response === "ok") {
						$scope.msg = response;
						$scope.mailOk = true;
						$scope.mailErr = false;
					} else {
						$scope.msg = response;
						$scope.mailOk = false;
						$scope.mailErr = true;
					}
				}).error(function (response) {
					$scope.msg = response.error;
					$scope.mailOk = false;
					$scope.mailErr = true;
				});
			}
        }
    };
});


module.directive('configFormLicense', function ($window, licenseService, license) {
    return {
        scope: true,
        templateUrl: 'config/form/license.html',
        controller: function ($scope) {

            $scope.licenseValid = true;

            var update = function(){
                licenseService.get().then(function (data) {
                    $scope.key = data.key;

                    _.forEach(data.modules, function(m){
                        var mod = _.findWhere(data.allModules, {name: m})
                        if(mod){
                            mod.state = 2;
                        }
                    })

                    var mod = _.findWhere(data.allModules, {'foregin': true, 'state':2})

                    if (mod){
                        $scope.ui.foregin = true;
                    }
                    $scope.allModules = data.allModules;

                    $scope.usage = license.usage;
                    if (!$scope.usage.tokens){
                        $scope.usage.tokens={};
                    }
                    $scope.usage.tokens.newTokens = 0;

                    $scope.$emit('options', [ $scope.ui.updateOption ]);
                });
            }

            $scope.ui = {
                foregin : false,
                updateOption: {
                    css: 'btn-primary',
                    icon: 'fa-refresh',
                    label: 'config.license.save',
                    enabled: true,
                    click: function () {
                        $('#confirmLicenseModal').modal({show:true});
                    }
                },
                uploadFile: function(file){
                    if (file) {
                            var aReader = new FileReader();
                            aReader.readAsText(file, "UTF-8");
                            aReader.onload = function (evt) {
                                var fileContent = aReader.result;
                                var reg = /^(?:[A-Za-z0-9+\/]{11})*=-*(?:[A-Za-z0-9+\/]{200,})*==/;
                                var s = fileContent.trim();
                                $scope.licenseValid = ((s.length % 4 == 1) && reg.test(s));

                                if($scope.licenseValid){
                                    $scope.key = s
                                }
                            }
                            aReader.onerror = function (evt) {
                                $scope.licenseValid = false;
                            }
                          }
                },
                addModule: function(module){
                    module.state = 1;
                    $scope.usage.tokens.newTokens += module.cost;
                },
                removeModule: function(module){
                    module.state = 0;
                    $scope.usage.tokens.newTokens -= module.cost;
                },
                saveLicense: function(){
                    var newMod = _.where($scope.allModules, {state: 1})
                    var newModStr = [];
                    _.forEach(newMod, function(mod){
                        newModStr.push(mod.name);
                    });
                    licenseService.save($scope.key, newModStr).then(function () {
                        $scope.$emit('message', 'config.license.save.success', 'alert-success');
                    },function () {
                        $scope.$emit('message', 'config.license.save.error', 'alert-danger');
                    });
                    $('#confirmLicenseModal').modal('hide');
//                    update();
                    $window.location.reload();
                }
            };

            $scope.$on('meternet.license.update', function(event, license) {
                _.forEach(license.enabledModules, function(m){
                    var mod = _.findWhere($scope.allModules, {name: m})
                    if(mod){
                        mod.state = 2;
                    }
                })
            });

            update();
        }
    };
});

module.directive('validFile',[function() {
  return {
    require : 'ngModel',
    scope : {format: '@', upload : '&upload'},
    link : function(scope, el, attrs, ngModel) {
      el.bind('change', function(event) {
      var file = event.target.files[0];
        scope.upload({file:event.target.files[0]});
        scope.$apply(function() {
          ngModel.$setViewValue(el.val());
          ngModel.$render();
        });
      })
    }
  }
}]);


module.directive('configFormBackup', function ($http, flowFactory, contextPath, configService) {
    return {
        scope: {},
        templateUrl: 'config/form/backup.html',
        controller: function ($scope) {

            $scope.uploadedFile = function(element) {
                $scope.$apply(function($scope) {
                    $scope.files = element.files;
                });
            }

            $scope.addFile = function() {
                if ($scope.files.length) {
                    var r = new FileReader();
                    r.onload = function(e) {
                        var contents = e.target.result;
                        $scope.$apply(function () {
                            $scope.fileReader = contents;
                            configService.restore(contents)
                        });
                    };
                    r.readAsText($scope.files[0]);
                }
            }

        }
    };
});

module.directive('configFormFirmware', function ($http, flowFactory, contextPath, dataService) {
    return {
        scope: {},
        templateUrl: 'config/form/firmware.html',
        controller: function ($scope) {
            function initFlow() {
                $scope.flow = flowFactory.create({
                    target: contextPath + '/upload',
                    singleFile: true
                });
            }

            $scope.ui = {
                isFileUploaded: function () {
                    return $scope.flow.files.length === 1 && $scope.flow.files[0].isComplete();
                },
                isEmpty: function () {
                    return $scope.flow.files.length === 0 || (!$scope.flow.files[0].isUploading() && !$scope.flow.files[0].isComplete());
                },
                onFileSuccess: function () {
                    this.acceptOption.enabled = true;
                    this.cancelOption.enabled = true;
                },
                acceptOption: {
                    css: 'btn-primary',
                    icon: 'fa-refresh',
                    label: 'config.firmware.accept',
                    enabled: false,
                    click: function () {
                        dataService.updateMeternet();
                        $scope.ui.acceptOption.enabled = false;
                        $scope.ui.cancelOption.enabled = false;
                        $scope.$emit('message', 'config.firmware.save.success', 'alert-success');
                    }
                },
                cancelOption: {
                    css: 'btn-default',
                    icon: 'fa-refresh',
                    label: 'config.firmware.cancel',
                    enabled: false,
                    click: function () {
                        $http.delete(contextPath + "/file/" + $scope.flow.files[0].name).then(function () {
                            $scope.flow.cancel();
                            $scope.ui.cssClass = null;
                            $scope.ui.acceptOption.enabled = false;
                            $scope.ui.cancelOption.enabled = false;
                        });
                    }
                }
            };

            initFlow();

            $scope.$emit('options', [ $scope.ui.acceptOption, $scope.ui.cancelOption ]);
        }
    };
});

module.directive('configFormStorage', function ($http, flowFactory, contextPath, dataService) {
    return {
        scope: {},
        templateUrl: 'config/form/storage.html',
        controller: function ($scope) {
            function initFlow() {
                $http({
                    method: 'GET',
                    url: '/status'
                }).then(function successCallback(data) {
                    $scope.uj.data = data.data;

                }, function errorCallback(response) {
                });
            }
			var commandFailedDelay = 300000;
			var commandStartTime = null;
            $scope.uj = {
            	commandFailedMsg: null,
            	commandProgress: null,
                status:true,
                unplug:true,
                error:true,
                data:{},
                fix: function(){
                    $http({
                        method: 'GET',
                        url: '/fix'
                    }).then(function successCallback(data) {
                    	$scope.uj.showProgress("config.storage.fixFailed");
                    }, function errorCallback(response) {
                    	$scope.uj.commandFailedMsg = "config.storage.commandFailed"
                    });
                },
                format: function(){
                    $http({
                        method: 'GET',
                        url: '/format'
                    }).then(function successCallback(data) {
                    	$scope.uj.showProgress("config.storage.formatFailed");
                    }, function errorCallback(response) {
                    	$scope.uj.commandFailedMsg = "config.storage.commandFailed"
                    });
                },
                setdb: function(){
                    $http({
                        method: 'GET',
                        url: '/setdb'
                    }).then(function successCallback(data) {
                    	$scope.uj.showProgress("config.storage.setdbFailed");
                    }, function errorCallback(response) {
                    	$scope.uj.commandFailedMsg = "config.storage.commandFailed"
                    });
                },
                reboot: function(){
                    $http({
                        method: 'GET',
                        url: '/reboot'
                    }).then(function successCallback(data) {
                    	$scope.uj.showProgress("config.storage.rebootFailed");
                    }, function errorCallback(response) {
                    	$scope.uj.commandFailedMsg = "config.storage.commandFailed"
                    });
                },
                poweroff: function(){
                    $http({
                        method: 'GET',
                        url: '/poweroff'
                    }).then(function successCallback(data) {
                    	$scope.uj.showProgress("config.storage.poweroffFailed");
                    }, function errorCallback(response) {
                    	$scope.uj.commandFailedMsg = "config.storage.commandFailed"
                    });
                },
                showProgress : function(commandFailedMsg){
                	$('#modalFix').modal('hide')
                	$('#modalFormat').modal('hide')
                	$('#modalSetDB').modal('hide')
                	$('#modalReboot').modal('hide')
                	$('#modalPoweroff').modal('hide')
					commandStartTime  = new Date().getTime();
					var counter = setInterval(function() {
						var currentTime = new Date().getTime();
						$scope.uj.commandProgress = ((currentTime - commandStartTime)/commandFailedDelay)*100;
					}, 1000);
					setTimeout(function() {
						$scope.uj.commandFailedMsg = commandFailedMsg;
						$scope.uj.commandProgress = false;
						clearInterval(counter);
					}, commandFailedDelay);
                }
//                getProgressValue: function(){
//                	var currentTime = new Date().getTime();
//                	return currentTime - $scope.uj.commandStartTime;
//                }
            };

            initFlow();
        }
    };
});

module.directive('configFormSupport', function ($http, flowFactory, contextPath, dataService) {
    return {
        scope: {},
        templateUrl: 'config/form/support.html',
        controller: function ($scope) {
            $scope.dataService	= dataService;
            $scope.status = -1;

            $scope.dataService.serviceStatus(0).then(
                function(status) {
                    $scope.status  = status;
                }
            );

            $scope.turnOn = function(){
                $scope.dataService.serviceStatus(1).then(
                    function(status) {
                        $scope.status  = status;
                    }
                );
            };

            $scope.turnOff = function(){
                $scope.dataService.serviceStatus(2).then(
                    function(status) {
                        $scope.status  = status;
                    }
                );
            }
        }
    };
});

<div class="row" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-4">
        <div class="form-group">
            <label for="inputfield2" class="form-group-label   col-sm-12">{{ 'config.name' | i18n }}</label>
            <div class="input-group col-sm-12">
                <input id="inputfield2" class="form-control" x-ng-model="item.config.name"
                       x-field-help="config.rule.name.help" x-field-errors=""/>
            </div>
        </div>
        <div class="form-group">
            <label class="form-group-label">{{ 'config.desc' | i18n }}</label>
            <div class="col-sm-12 input-group">
                <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.rule.desc.help"
                       x-field-errors=""/>
            </div>
        </div>
        <div class="form-group">
            <label class="form-group-label">{{ 'config.type' | i18n }}</label>
            <div class="col-sm-12 input-group">
                <select class="form-control" x-ng-model="item.config.type"
                        x-ng-options="t.type as ('config.momentRule.type.' + t.type | i18n) for t in ui.ruleTypes"
                        x-field-errors=""
                        x-field-help="config.rule.type.help"
                        x-ng-change="ui.update()"></select>
            </div>
        </div>
    </div>

    <div class="col-sm-8" x-ng-if="item.config.type==='stateless'">
        <div x-config-form-moment-rule-def="item._rule" x-config="config">
        </div>
    </div>

    <div class="col-sm-8" x-ng-if="item.config.type==='toggle'">
        <div x-config-form-moment-rule-def="item._positive" x-config="config">
        </div>
        <div x-config-form-moment-rule-def="item._negative" x-config="config">
        </div>
    </div>
</div>
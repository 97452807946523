var module = angular.module('meternet.charts.directives', [
    'meternet.services',
    'meternet.chart.directives.gauge',
    'meternet.chart.directives.line',
    'meternet.chart.directives.shiftBar',
    'meternet.chart.directives.heatmap',
    'meternet.chart.directives.visu',
    'meternet.chart.directives.tableGauge',
    'meternet.chart.directives.termoGauge',
    'meternet.chart.directives.controlGauge',
    'meternet.chart.directives.indicatorGauge',
    'meternet.chart.directives.prepaidGauge',
    'meternet.chart.directives.mbsatGauge',
    'meternet.chart.directives.mbsat2Gauge',
    'meternet.chart.directives.calendarSchedule',
    'meternet.chart.directives.lightingSettings',
    'meternet.chart.directives.mbsat2Bottom',
    'meternet.chart.directives.deviceCalendar',
    'meternet.chart.directives.costReport',
    'meternet.chart.directives.currentValues',
    'meternet.chart.directives.mbsat2Report',
    'meternet.chart.directives.mbsat2Chart',
    'meternet.chart.directives.marker-popup',
    'meternet.chart.directives.mbsat2-marker-popup',
    'meternet.chart.directives.eventsAck'
]);

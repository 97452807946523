var module = angular.module('meternet.event.directives', [
    'meternet.services'
]);

module.directive('eventPanel', function ($sce, i18nFilter, eventService, configService, Objects) {
    return {
        scope: {},
        templateUrl: 'status/event-panel.html',
        controller: function ($scope) {
            var templates = {};

            function updateSources() {
                configService.get().then(function (config) {
                    $scope.sources = {};
                    _.each(config.engine.eventInputs, function (input) {
                        _.each(input.sources, function (source) {
                            var s = {
                                config: source
                            };
                            $scope.sources[source.sourceId] = s;
                        });
                    });
                    _.each($scope.sources, function (source) {
                        source.path = "";
                        _.each(source.config.path, function (id) {
                            var s = $scope.sources[id];
                            source.path += s.config.label || s.config.name;
                            if (id !== source.config.sourceId) {
                                source.path += " / ";
                            }
                        });
                    });
                });
            }

            function updateEvents() {
                if ($scope.current) {
                    eventService.currentLevel($scope.level);
                    $scope.events = eventService.currentEvents();
                } else {
                    console.log(moment.isMoment($scope.timestamp));
                    eventService.findEvents($scope.timestamp, $scope.level, $scope.offset).then(function (events) {
                        $scope.events = events;
                    })
                }
            }

            updateSources();


            $scope.ui = {
                levels: [ {
                    value: null,
                    label: 'event.level.all'
                }, {
                    value: 0,
                    label: 'event.level.info'
                }, {
                    value: 1,
                    label: 'event.level.warning'
                }, {
                    value: 2,
                    label: 'event.level.error'
                } ],
                source: function (event) {
                    var info = "";
                    var source = $scope.sources[event.sourceId];
                    if (source) {
                        info = i18nFilter('config.componentType.' + source.config.sourceType) + ' <b>' + source.path + '</b>';
                    } else {
                        info = "???";
                    }
                    return info;
                },
                message: function (event) {
                    var template = templates[event.type];
                    if (!template) {
                        var msg = i18nFilter('event.' + event.type);
                        template = _.template(msg);
                        templates[event.type] = template;
                    }
                    return template(event.data);
                },
                display: function (event) {
                    if (!event._msg) {
                        event._msg = $sce.trustAsHtml(this.source(event) + ': ' + this.message(event));
                    }
                    return event._msg;
                },
                stop: function () {
                    $scope.current = false;
                    updateEvents();
                },
                start: function () {
                    $scope.current = true;
                    $scope.timestamp = null;
                    updateEvents();
                },
                prev: function () {
                    $scope.offset += 100;
                    updateEvents();
                },
                next: function () {
                    var offset = $scope.offset >= 100 ? $scope.offset - 100 : 0;
                    if (offset !== $scope.offset) {
                        $scope.offset = offset;
                        updateEvents();
                    }
                }
            };

            $scope.current = true;
            $scope.timestamp = null;
            $scope.level = eventService.currentLevel();
            $scope.offset = 0;

            updateEvents();

            $scope.$watch('level', function (nval, oval) {
                if (nval !== oval) {
                    $scope.offset = 0;
                    updateEvents();
                }
            });

            $scope.$watch('timestamp', function (nval, oval) {
                if (nval !== oval) {
                    $scope.current = nval == null;
                    $scope.offset = 0;
                    updateEvents();
                }
            });
        }
    };
});

<form>
    <fieldset>
        <div class="form-group">
            <label for="username" class="col-sm-2 col-xs-12 control-label">{{'config.user.username' | i18n}}*</label>

            <div class="col-sm-9 col-xs-12">
                <input id="username" name="username" class="form-control" type="text" x-ng-model="user.username" x-ng-maxlength="45"
                       x-ng-minlength="2" x-ng-required=""/>
            </div>

        </div>
    </fieldset>
    <fieldset x-ng-include="'user/user-edit-form.html'"></fieldset>
    <fieldset x-ng-include="'user/user-set-password-form.html'"></fieldset>
</form>

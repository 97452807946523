<div class="marker-popup">
  <p><b>{{'config.label' | i18n}}: </b><span>{{ui.clickedMark.device.name}}</span></p>
  <p><b>{{'config.desc1' | i18n}}: </b><span>{{ui.clickedMark.device.desc}}</span></p>
  <p><b>{{'config.desc2' | i18n}}: </b><span>{{ui.clickedMark.device.desc2}}</span></p>
  <p><b>{{'config.desc3' | i18n}}: </b><span>{{ui.clickedMark.device.desc3}}</span></p>
  <p><b>{{'config.device.geolocation' | i18n}}: </b><span>{{ui.clickedMark.lat + ", " + ui.clickedMark.lng}}</span></p>
  <div class="checkIcon">
    <a href="" x-ng-click="checkBoxClick()">
      <i x-ng-if="ui.clickedMark.checked" class="fa fa-check-square-o fa-lg"></i>
      <i x-ng-if="!ui.clickedMark.checked" class="fa fa-square-o fa-lg"></i>
    </a>
  </div>
  <a x-ng-if="ui.clickedMark.device.lightingSettingId" href="" title="{{::'mbsat.menu.calendar' | i18n}}" x-ng-click="ui.goToSettings(ui.clickedMark.device.lightingSettingId)">
    <i class="fa fa-calendar fa-lg"></i>
  </a>
</div>

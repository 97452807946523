 <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
             <ul class="list-group">
                <li class="list-group-item" x-ng-repeat="series in config.series">
                    <form name="seriesConfig">
						<div class="row" style="display: flex; gap: 5px;">
						<div class="form-group">
							<label class="form-group-label">&nbsp;</label>
							<div style="width: 15px; height: 29px; line-height: 29px; cursor: pointer;">
								<i class="fa fa-lg"
								   x-ng-click="series._details = !series._details"
								   x-ng-class="series._details ? 'fa-minus' : 'fa-plus'"></i>
							</div>
						</div>

						<div class="form-group">
							<label class="form-group-label">&nbsp;</label>
							<svg width="30" height="29" class="series-icon">
								<path d="M 0 15 L 30 15"/>
								<circle cx="15" cy="15" r="0" style="stroke: none;"/>
							</svg>
						</div>

						<div x-device-selector="" style="width: 100px; flex-grow: 1;"></div>

						<div x-parameter-selector="" style="width: 100px; flex-grow: 1;"></div>

						<div class="form-group" style="width: 100px; flex-grow: 1;">
							<label class="form-group-label" x-label-help="dashboard.widgets.config.series.name"></label>
							<div class="input-group">
								<input type="text" class="form-control"  aria-label="..."
									   x-ng-model="series.name"
									   x-ng-change="ui.updateAxisIndices()"
									   name="widgetsConfigSeriesName-{{$index}}">
								<div class="input-group-btn">
									<button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="caret"></span></button>
									<ul class="dropdown-menu dropdown-menu-right" x-ng-if="series._device" style="width: 300px; min-width: 300px;">
										<li><a href="#" x-ng-click="series.name = (series._device.label||series._device.name)+'.'+(series._param.label||series._param.name)+$index">{{series._device.label||series._device.name}}.{{series._param.label||series._param.name}}</a></li>
										<li role="separator" class="divider"></li>
										<li><a href="#" x-ng-click="series.name = (series._param.label||series._param.name)">{{series._param.label||series._param.name}}</a></li>
										<li><a href="#" x-ng-click="series.name = ('quantity.'+series._param.quantity|i18n)+' '+$index">{{'quantity.'+series._param.quantity|i18n}} {{$index}}</a></li>
									</ul>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-group-label" x-label-help="dashboard.widgets.config.series.derivative"></label>
							<select class="form-control"
									style="width: 40px;"
									x-ng-model="series.derivative"
									x-ng-options="d.value as d.label for d in ui.derivatives"
									x-ng-change="ui.updateSeriesParam(series)">
							</select>
						</div>

						<div class="form-group">
							<label class="form-group-label">&nbsp;</label>
							<button class="btn btn-danger" x-ng-click="ui.removeSeries(series)"
									data-ng-class="ui.isSingleSerie()"
									title="{{'ui.delete' | i18n}}">
								<i class="fa fa-trash-o fa-lg"></i>
							</button>
						</div>
						</div>
						<div x-ng-show="series._details" style="margin-left: 51px;">

							<div class="form-group-sep"></div>
							<div class="form-group">
								<label class="form-group-label" x-label-help="dashboard.widgets.config.series.line.color"></label>
								<spectrum-colorpicker format="hex" x-ng-model="series.lineColor" name="widgetsConfigSeriesLineColor-{{$index}}"
													  options="ui.colorPickerOpts || config.unit || {showInput : true}"
													  x-ng-change="ui.updateSeriesIcon(series)"/>
							</div>

							<div class="form-group">
								<label class="form-group-label" x-label-help="dashboard.widgets.config.series.line.style"></label>
								<select class="form-control" style="width: 62px;" x-ng-model="series.lineWidth"
										x-ng-change="ui.updateSeriesIcon(series)"
										x-ng-options="w as (w + ' px') for w in ui.lineWidths">
								</select>
							</div>

							<div class="form-group">
								<label class="form-group-label" x-label-help="dashboard.widgets.config.series.marker.color"></label>
								<spectrum-colorpicker format="hex" x-ng-model="series.markerColor" name="widgetsConfigSeriesMarkerColor-{{$index}}"
													  options="ui.colorPickerOpts || {showInput : true}"
													  x-ng-change="ui.updateSeriesIcon(series)"/>
							</div>

							<div class="form-group">
								<label class="form-group-label" x-label-help="dashboard.widgets.config.series.marker.style"></label>
								<select class="form-control" style="width: 62px;" x-ng-model="series.markerSize"
										x-ng-change="ui.updateSeriesIcon(series)"
										x-ng-options="s as (s + ' px') for s in ui.markerSizes">
								</select>
							</div>

							<!-- Average	 -->
							<div class="form-group">
								<label class="form-group-label" x-label-help="dashboard.widgets.config.average"></label>
								<input type="number" class="form-control" name="widgetsConfigAverage-{{$index}}" placeholder="{{'dashboard.widgets.config.average' | i18n}}"
									   data-ng-model="series.average" x-min="1" x-required="" style="width: 140px"/>
							</div>

							<div class="form-group" x-ng-if="!series.used">
								<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.index"></label>
								<select class="form-control" style="width: 150px;" x-ng-model="series.axisIndex"
										x-ng-change="ui.updateAxisIndices()"
										ng-options="item.value as item.label disable when item.disabled[$parent.$index] for item in ui.getAxisIndices(series,$index)" >
								</select>
							</div>

							<ul x-ng-if="series.used">
								Podziałka użyta przez:
								<li x-ng-repeat="s in config.series" x-ng-if="s.axisIndex==$parent.$index">
									{{s.name}}
								</li>
							</ul>

							<div x-ng-if="series.axisIndex === null || series.axisIndex===''">

								<div class="form-group">
									<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.scale"></label>
									<select class="form-control" style="width: 94px;" x-ng-model="series.axis.scale"
											x-ng-options="s.value as s.label for s in ui.scales">
									</select>
								</div>

								<div class="form-group">
									<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.precision"></label>
									<select class="form-control" style="width: 94px;" x-ng-model="series.axis.precision"
											x-ng-options="p for p in ui.precisions">
									</select>
								</div>

								<div class="form-group">
									<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.label.color"></label>
									<spectrum-colorpicker format="hex" x-ng-model="series.axis.labelColor"
														  name="widgetsConfigSeriesAxisLabelColor-{{$index}}"
														  options="ui.colorPickerOpts || {showInput : true}"/>
								</div>

								<div class="form-group">
									<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.axis.color"></label>
									<spectrum-colorpicker format="hex" x-ng-model="series.axis.axisColor"
														  name="widgetsConfigSeriesAxisAxisColor-{{$index}}"
														  options="ui.colorPickerOpts || {showInput : true}"/>
								</div>

								<div class="form-group-sep"></div>
								<div class="form-group row">
									<div class="form-group col-md-4">
										<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.range.auto"></label>
										<select class="form-control" style="width: 125px;" x-ng-model="series.axis.rangeAuto"
												x-ng-options="r.value as r.label for r in ui.axisRanges">
										</select>
									</div>
									<div class="form-group col-md-4">
										<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.min"></label>
										<div class="input-group" style="width: 124px;">
											<input type="text" class="form-control"
												   name="widgetsConfigSeriesAxisMin-{{$index}}"
												   x-ng-model="series.axis.rangeMin"
												   x-ng-disabled="series.axis.rangeAuto"
												   data-scale-formatter=""
												   data-format-scale="series.axis.scale"
												   data-format-precision="series.axis.precision"
												   data-validate="{'dashboard.widgets.config.error.minMax': series.axis.rangeMin > series.axis.rangeMax}"/>
											<data-unit-addon data-unit-addon-scale="series.axis.scale" data-unit-addon-unit = series.unit></data-unit-addon>
										</div>
									</div>
									<div class="form-group col-md-4">
										<label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.max"></label>
										<div class="input-group" style="width: 124px;">
											<input type="text" class="form-control"
												   name="widgetsConfigSeriesAxisMax-{{$index}}"
												   x-ng-model="series.axis.rangeMax"
												   x-ng-disabled="series.axis.rangeAuto"
												   data-scale-formatter=""
												   data-format-scale="series.axis.scale"
												   data-format-precision="series.axis.precision"
												   data-validate="{'dashboard.widgets.config.error.maxMin': series.axis.rangeMin > series.axis.rangeMax}"/>
											<data-unit-addon data-unit-addon-scale="series.axis.scale" data-unit-addon-unit = series.unit></data-unit-addon>
										</div>
									</div>
								</div>
							</div>
							<br/>
							<div ng-click="showLines=!showLines" >
								<i class="fa fa-angle-right" ng-if="!showLines" style="cursor: pointer;"></i>
								<i class="fa fa-angle-down" ng-if="showLines"  style="cursor: pointer;"></i>
								<label class="form-group-label" x-label-help="dashboard.widgets.config.series.lines" style="cursor: pointer;"></label>
							</div>

							<div ng-if="showLines">
								<table style="width:500px; max-width: 500px; min-width: 500px;">
									<tr style="width:500px; max-width: 500px; min-width: 500px;">
										<td style="min-width:180px; max-width: 180px;  padding: 10px 0px 10px 20px;">
											<label class="control-label">{{'dashboard.widgets.config.series.maxLine' | i18n}}</label>
										</td>
										<td style="min-width:130px"></td>
										<td style="min-width:130px">
											<ui-select ng-model="series.maxOn" theme="bootstrap" style="stroke-width: 1px; stroke: {{series.lineColor}};">
												<ui-select-match>
													<svg width="90" height="18" style="stroke-dasharray: {{series.maxOn}}" class="series-icon" x-ng-if="series.maxOn">
														<path d="M 0 7 L 90 7"/>
													</svg>
													<div x-ng-if="!series.maxOn">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-match>
												<ui-select-choices repeat="s in ui.markStyle">
													<svg width="198" height="20" class="series-icon" style="stroke-dasharray: {{s}}" x-ng-if="s!=null">
														<path d="M 0 15 L 198 15"/>
													</svg>
													<div x-ng-if="!s">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-choices>
											</ui-select>
										</td>
										<td style="min-width:42px"></td>
									</tr>
									<tr style="width:500px; max-width: 500px; min-width: 500px;">
										<td style="min-width:180px; max-width: 180px; padding: 10px 0px 10px 20px;">
											<label class="control-label">{{'dashboard.widgets.config.series.minLine' | i18n}}</label>
										</td>
										<td style="min-width:130px"></td>
										<td style="min-width:130px">
											<ui-select ng-model="series.minOn" theme="bootstrap" style="stroke-width: 1px; stroke: {{series.lineColor}};">
												<ui-select-match>
													<svg width="90" height="18" style="stroke-dasharray: {{series.minOn}}" class="series-icon" ng-if="series.minOn">
														<path d="M 0 7 L 90 7"/>
													</svg>
													<div x-ng-if="!series.minOn">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-match>
												<ui-select-choices repeat="s in ui.markStyle">
													<svg width="198" height="20" class="series-icon" style="stroke-dasharray: {{s}}"   x-ng-if="s!=null">
														<path d="M 0 15 L 198 15"/>
													</svg>
													<div x-ng-if="!s">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-choices>
											</ui-select>
										</td>
										<td style="min-width:42px"></td>
									</tr>
									<tr style="width:500px; max-width: 500px; min-width: 500px;">
										<td style="min-width:180px; max-width: 180px; padding: 10px 0px 10px 20px;">
											<label class="control-label">{{'dashboard.widgets.config.series.avgLine' | i18n}}</label>
										</td>
										<td style="min-width:130px"></td>
										<td style="min-width:130px">
											<ui-select ng-model="series.avgOn" theme="bootstrap" style="stroke-width: 1px; stroke: {{series.lineColor}};">
												<ui-select-match>
													<svg width="90" height="18" style="stroke-dasharray: {{series.avgOn}}" class="series-icon" ng-if="series.avgOn">
														<path d="M 0 7 L 90 7"/>
													</svg>
													<div  x-ng-if="!series.avgOn">Off</div>
												</ui-select-match>
												<ui-select-choices repeat="s in ui.markStyle">
													<svg width="198" height="20" class="series-icon" style="stroke-dasharray: {{s}}"  x-ng-if="s!=null">
														<path d="M 0 15 L 198 15"></path>
													</svg>
													<div x-ng-if="!s">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-choices>
											</ui-select>
										</td>
										<td style="min-width:42px"></td>
									</tr>
								</table>
								<table>
									<tr x-ng-repeat="m in series.markStyle track by $index" class="form-group row">
										<td style="min-width:150px; padding: 10px 0px 10px 20px;">
											<!--<label class="control-label">{{'dashboard.widgets.config.series.helpLine' | i18n}} {{$index+1}}</label>-->
											<input type="text" class="form-control" style="width:100%" x-ng-model="series.markName[$index]"/>
										</td>
										<td style="min-width:100px; padding: 5px;">
											<div class="input-group">
												<input type="text" class="form-control"
													   name="widgetsConfigSeriesMark-{{$index}}"
													   x-ng-model="series.mark[$index]"
													   data-scale-formatter=""
													   data-format-scale="series.axis.scale"
													   data-format-precision="series.axis.precision"/>
												<data-unit-addon data-unit-addon-scale="series.axis.scale" data-unit-addon-unit = series.unit></data-unit-addon>
											</div>
										</td>
										<td style="min-width:50px">
											<div class="form-group" style="min-width:30px">
												<spectrum-colorpicker format="hex" x-ng-model="series.markColor[$index]"
																	  name="widgetsConfigSeriesAxisAxisColor-{{$index}}"
																	  options="ui.colorPickerOpts || {showInput : true}"/>
											</div>
										</td>

										<td style="min-width:100px">
											<ui-select ng-model="series.markStyle[$index]" theme="bootstrap" style="stroke-width: 1px; stroke: {{series.lineColor}};">
												<ui-select-match>
													<svg width="90" height="18" style="stroke-dasharray: {{series.markStyle[$index]}}" class="series-icon" ng-if="series.markStyle[$index]">
														<path d="M 0 7 L 90 7"/>
													</svg>
													<div x-ng-if="!series.markStyle[$index]">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-match>
												<ui-select-choices repeat="s in ui.markStyle">
													<svg width="198" height="20" class="series-icon" style="stroke-dasharray: {{s}}" x-ng-if="s!=null">
														<path d="M 0 15 L 198 15"/>
													</svg>
													<div x-ng-if="!s">{{'dashboard.widgets.config.none'| i18n}}</div>
												</ui-select-choices>
											</ui-select>
										</td>
										<td style="min-width:42px">
											<button class="btn btn-danger" x-ng-click="ui.removeMark(series, $index);"
													title="{{'ui.delete' | i18n}}">
												<i class="fa fa-trash-o fa-lg"></i>
											</button>
										</td>
									</tr>
								</table>
								<table>
									<tr>
										<td style="min-width:180px; padding: 10px 0px 10px 20px;">
											<label class="form-group-label">&nbsp;</label>
										</td>
										<td style="min-width:142px"></td>
										<td style="min-width:130px"></td>
										<td style="min-width:42px">
											<button class="btn btn-success" x-ng-click="ui.addMark(series);">
												<i class="fa fa-plus fa-lg"></i>
											</button>
										</td>
									</tr>
								</table>
							</div>
						</div>
                    </form>
                </li>

                <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                    <div class="pull-right">
                        <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                            {{'dashboard.widgets.config.series.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                        </button>
                    </div>
                    <div class="clearfix"></div>
                </li>
            </ul>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.timespan' | i18n}}</div>
        <div class="panel-body">

            <form name="widgetConfig" class="form form-inline">
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.mode"></label>
                    <select class="form-control" name="widgetsConfigMode" data-ng-model="config.mode"
                            ng-options="('dashboard.widgets.config.mode.'+item | i18n) for item in linechartModes"
                            style="width: 205px" x-ng-change="changeMode()">
                    </select>
                </div>
                <!-- historyTime -->
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.history.time"></label>
                    <div class="input-group" x-ng-init="config.historyTime=config.historyTime || 3" style="width: 200px">
                        <input type="text" data-num-format="" class="form-control" x-ng-model="config.historyTime"
                               x-ng-disabled="config.mode !== 'current'" min="1" x-required="" name="widgetsConfigHistoryTime"
                               ng-model-options="{ allowInvalid: true }"  x-show-error="showErrors"
                               data-validate="{'dashboard.widgets.config.history.time.error.historyTimeValidate': (config.historyTime*timeUnit.value > config.maxHistoryTime)}"/>
                        <input type="hidden" x-ng-model="timeUnit.value" name="widgetsConfigHistoryTimeUnitValue"
                               min="1" ng-model-options="{ allowInvalid: true }" x-ng-disabled="!config.mode"/>
                        <span class="input-group-btn" x-uib-dropdown="" x-is-open="ui.openTimeUnit">
							<button class="btn btn-default dropdown-toggle" x-ng-disabled="config.mode !== 'current'"
                                    type="button" x-uib-dropdown-toggle="">
                                {{timeUnit.shortBundle | i18n}}
                                <span class="caret"></span>
                            </button>
							<ul class="dropdown-menu" role="menu">
                                <li x-ng-repeat="tu in timeUnits">
                                    <a href="#" x-ng-click="ui.setTimeUnit(tu)">{{tu.shortBundle| i18n}}</a>
                                </li>
                            </ul>
						</span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.timeSpan"></label>
                    <div class="input-group" style="width: 195px" x-ng-if="config.mode === 'current'">
                        <input type="text" class="form-control" x-ng-disabled="config.mode"
                               value="{{config.timeFrom | date: 'y-MM-dd HH:mm'}}"/>
                        <span x-ng-disabled="config.mode"class="input-group-addon">
                            <i class="fa fa-calendar"></i>
						</span>
                    </div>
                    <div class="form-group input-group" style="width: 195px" x-ng-if="config.mode !== 'current'">
                        <div class='input-group date'>
                            <input name="timeFrom" x-for-input="inputName" x-show-error="showErrors"
                                   x-show-help="true" type='text' class="form-control" x-ng-model="config.timeFrom"
                                   x-datetimepicker=""
                                   data-validate="{'dashboard.widgets.config.time.from.error': config.timeFrom > config.timeTo,
                                                    'dashboard.widgets.config.history.time.error.historyTimeValidate': (config.timeTo-config.timeFrom > config.maxHistoryTime)
                                   }"/>
                            <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                        </div>
                    </div>
                    <label class="control-label"><i class="fa fa-arrows-h"></i> </label>

                    <div class="input-group" style="width: 195px" x-ng-if="config.mode === 'current'">
                        <input type="text" class="form-control" x-ng-disabled="config.mode" value="{{config.timeTo | date: 'y-MM-dd HH:mm'}}"/>
                            <span class="input-group-addon" x-ng-disabled="config.mode">
							<i class="fa fa-calendar"></i>
						</span>
                    </div>
                    <div class="form-group input-group" style="width: 195px" x-ng-if="config.mode !== 'current'">
                        <div class='input-group date'>
                            <input name="timeTo" x-for-input="inputName" x-show-error="showErrors"
                                   x-show-help="true" type='text' class="form-control" x-ng-model="config.timeTo"
                                   x-datetimepicker=""
                                   data-validate="{'dashboard.widgets.config.time.from.error': config.timeFrom > config.timeTo,
                                                    'dashboard.widgets.config.history.time.error.historyTimeValidate': (config.timeTo-config.timeFrom > config.maxHistoryTime)
                                   }"/>
                            <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- {{time scale}} -->
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.grid.formatting' | i18n}}</div>
        <div class="panel-body">
            <label>{{'dashboard.widgets.config.grid.axisX.title' | i18n}}</label>
            <form class="form form-inline">
                <div class="form-group" style="width: 162px;">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.main.grid.color"></label>
                    <spectrum-colorpicker format="hex" x-ng-model="config.mainGridColor" name="widgetsConfigMainGridColor"
                                          options="ui.colorPickerOpts || {showInput : true}"/>
                </div>
            </form>
            <br/>
            <hr/>
            <label>{{'dashboard.widgets.config.grid.axisY.title' | i18n}}</label>
            <form class="form form-inline">
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.grid.axis.index"></label>
                    <select class="form-control" style="width: 140px;" x-ng-model="config.gridSerieIndex"
                            x-ng-options="i.value as i.label for i in ui.getAxisIndices() track by i.value"
                            x-ng-change="ui.setGridSerie()">
                        <option value="">-</option>
                    </select>
                </div>
                <div class="form-group" x-ng-if="gridSerie">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.grid"></label>
                    <select class="form-control" style="width: 74px;" x-ng-model="gridSerie.axis.grid" data-ng-options="grid.value as grid.label for grid in ui.grids">
                    </select>
                </div>
                <div class="form-group" style="width: 100px;" x-ng-if="gridSerie">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.main.grid.color"></label>
                    <spectrum-colorpicker format="hex" x-ng-model="gridSerie.axis.mainGridColor" name="widgetsConfigSeriesAxisMainGridColor"
                                          options="ui.colorPickerOpts || {showInput : true}"/>
                </div>
                <div class="form-group" style="width: 100px;" x-ng-if="gridSerie &amp;&amp; gridSerie.axis.grid > 1">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.sub.grid.color"></label>
                    <spectrum-colorpicker format="hex" x-ng-model="gridSerie.axis.subGridColor" name="widgetsConfigSeriesAxisSubGridColor"
                                          options="ui.colorPickerOpts || {showInput : true}"/>
                </div>
            </form>
        </div>
    </div>

<div class="row media-report-edit">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.media.settings' | i18n}}</div>
        <div class="panel-body form-horizontal">
			<div class="form-group">
				<label class="form-group-label" x-label-help="dashboard.widgets.config.media.title"></label>
				<input type="text" class="form-control" x-ng-model="config.title"/>
			</div>
			<div class="form-group">
				<label class="form-group-label" x-label-help="dashboard.widgets.config.media.header"></label>
				<textarea class="form-control" x-ng-model="config.header"  x-ng-minlength="1" x-ng-maxlength="1000" style="min-height: 80px;"></textarea>
			</div>
        </div>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="series in config.series">
                {{JSON.stringify(series)}}
				<div x-series-parameter-selector=""></div>
				<div class="row">
					<div class="form-group col-md-4">
						<label class="form-group-label" x-label-help="dashboard.widgets.config.series.cost"></label>
						<div class="input-group">
							<span class="input-group-addon">
								<input type="checkbox"
									   x-ng-model="series.sync"
									   x-ng-true-value="false"
									   x-ng-false-value="true"
									   x-ng-change="ui.updateSeriesMediaCost(series)"/>
							</span>
							<input type="text"
								   class="form-control"
								   x-ng-model="series.cost"
							       x-ng-disabled="series.sync"
                                   x-num-format="2"/>
							<span class="input-group-addon">{{ui.unitCost(series)}}</span>
						</div>
					</div>
					<div class="form-group col-md-2">
						<label class="form-group-label" x-label-help="dashboard.widgets.config.series.currency"></label>
						<input type="text" class="form-control"
							   x-ng-model="series.currency"
							   x-ng-disabled="series.sync"/>
					</div>
					<div class="form-group col-md-3">
						<label class="form-group-label" x-label-help="dashboard.widgets.config.series.limit"></label>
						<div class="input-group">
							<input type="text"
								   class="form-control"
								   x-ng-model="series.limit"/>
							<span class="input-group-addon">{{ui.unitLimit(series)}}</span>
						</div>
					</div>
				</div>
            </li>
            <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                <div class="pull-right">
                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                        {{'ui.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="clearfix"></div>
            </li>
        </ul>
    </div>
</div>

<div class="form-group">
	<label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
	<ui-select ng-model="series._param" theme="bootstrap" x-ng-change="ui.updateSeriesParam(series)">
		<ui-select-match
			placeholder="&#xF002; {{'config.search' | i18n}}"
			popover-trigger="mouseenter"
			popover-placement="right"
			uib-popover="{{ui.paramLabel($select.selected)}}">
			{{ui.paramLabel($select.selected)}}
		</ui-select-match>
		<ui-select-choices repeat="param in series._device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}">
			<div ng-bind-html="ui.paramLabel(param) | highlight: $select.search"></div>
			<div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
		</ui-select-choices>
	</ui-select>
</div>

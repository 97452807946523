var module = angular.module('meternet.eventack.services', []);

module.service('eventAckService', function ($rootScope, $q, $http, contextPath, dataService) {

    var buffer = {
        events: [],
        indicator: [],
        level: null,
        ack: true,
        acked: false,
        limit: 100,
        add: function (event) {
            if ( (this.level == null || event.level >= this.level) && event.ack ) {
                this.events.unshift(event);
                while (this.events.length > this.limit) {
                    this.events.pop();
                }
            }
            this.indicator.unshift(event);
        },
        filter: function (level, ack, acked) {
            if (this.level !== level || this.ack !== ack || this.acked !== acked) {
                this.level = level;
                this.ack = ack;
                this.acked = acked;
                this.events.length = 0;
                this.load();
            }
        },
        load: function () {
            var self = this;
            $http({
                method: 'GET',
                url: contextPath + '/event',
                params: {
                    level: self.level,
                    limit: self.limit,
                    ack: self.ack,
                    acked: self.acked
                }
            }).then(function (response) {
                if (response.data) {
                    for (var i = 0; i < response.data.length; ++i) {
                        self.events.push(new Event(response.data[i]));
                    }

                    self.events.sort(function (a, b) {
                        return b.timestamp.getTime() - a.timestamp.getTime();
                    });
                    while (self.events.length > self.limit) {
                        self.events.pop();
                    }
                }
            });
        }
    };

    function Event(data) {
        this.update(data);
    }

    Event.prototype.update = function (data) {
        if (data instanceof Array) {
            this.sourceId = data[0];
            this.timestamp = new Date(data[1]);
            this.type = data[2];
            this.level = data[3];
            this.data = data[4];
            this.ack = data[5];
            this.ack_timestamp = data[6];
            this.comment = data[7];
        } else {
            this.sourceId = data.sourceId;
            this.timestamp = new Date(data.timestamp);
            this.type = data.type;
            this.level = data.level || 0;
            this.data = data.data;
            this.ack = data.ack;
            this.ack_timestamp = ack_timestamp;
            this.comment = comment;
        }
    };

    Event.prototype.levelCssClass = function () {
        switch (this.level) {
            case 0:
                return "info";
            case 1:
                return "warning";
            case 2:
                return "danger";
            default:
                return "active";
        }
    };
    var unsubscribeFn = dataService.subscribe("/user/topic/event", function (data) {
        buffer.add(new Event(data));
    });

    $rootScope.$on('$destroy', unsubscribeFn);

    buffer.load();

    return {
        currentEvents: function () {
            return buffer.events;
        },
        indicatorEvents: function () {
            return buffer.indicator;
        },
        currentLevel: function (level, ack, acked) {
            if (!arguments.length) {
                return buffer.level;
            } else {
                buffer.filter(level, ack, acked);
            }
        },
        findEvents: function (to, level, offset, limit, ack, acked) {
            var deferred = $q.defer();
            if (limit == null) {
                limit = buffer.limit;
            }
            if (offset == null) {
                offset = 0;
            }
            if (ack == null) {
                ack = false;
            }
            $http({
                method: 'GET',
                url: contextPath + '/event',
                params: {
                    to: to ? to.toISOString() : null,
                    level: level,
                    offset: offset,
                    limit: limit,
                    ack: ack,
                    acked: acked
                }
            }).then(function (response) {
                var i, e = [];
                if (response.data) {
                    for (i = 0; i < response.data.length; ++i) {
                        e.push(new Event(response.data[i]));
                    }
                }
                deferred.resolve(e);
            });
            return deferred.promise;
        },
        ackEvent: function (timestamp, sourceId, comment) {
            var deferred = $q.defer();
            $http({
                method: 'GET',
                url: contextPath + '/ackevent',
                params: {
                    timestamp: timestamp ? timestamp.toISOString() : null,
                    sourceid: sourceId,
                    comment: comment
                }
            }).then(function (response) {
                deferred.resolve(new Event(response.data[0]));
            });
            return deferred.promise;
        }
    };
});
var module = angular.module('i18n', []);

module.run(function (Locale) {
    moment.locale(Locale);
});

module.filter('i18n', function (messages) {
	var i18n = function(key, replacement) {
        var m;
        if (key == null) {
            return '';
        }
        key = key.toString();
        if (messages.hasOwnProperty(key)) {
            m = messages[key];
            if (replacement !== undefined) {
                if (!angular.isArray(replacement) && !angular.isObject(replacement)) {
                    replacement = [replacement];
                }
                if (angular.isArray(replacement)) {
                    var rlen = replacement.length;
                    var rfx = function (str, i) {
                        i = parseInt(i, 10);
                        return (i >= 0 && i < rlen) ? replacement[i] : str;
                    };
                    m = m.replace(/\{([0-9]+)}/g, rfx);
                }
            }
            return m;
        } else {
            return key;
        }
	};

    i18n.hasKey = function (key) {
        return messages.hasOwnProperty(key);
    };

    return i18n;
});

module.directive('langSelector', function ($window, $rootScope, i18nFilter, Locale) {
    return {
        scope: false,
        link: function (scope, elem, attr, ctrl) {
            function render() {
                var langs = attr.langSelector.split(',');
                var lang = Locale;
                elem.empty();
                _.each(langs, function (l) {
                    var a = $('<a></a>');
                    a.text(i18nFilter('Language_' + l.replace('-', '_')));
                    a.attr('href', '?lang=' + l.split('-')[0]);
                    a.attr('target', '_self');
                    a.toggleClass('active-lang', l.split('-')[0] === lang);
                    elem.append(a);
                });
            }

            render();

            $rootScope.$on('$stateChangeSuccess', render);
        }
    }
});
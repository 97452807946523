var module = angular.module('meternet.charts.gauge.services', ['meternet.charts.gauge.services.digital-gauge-serie', 'meternet.charts.gauge.services.half-gauge-serie', 'meternet.charts.gauge.services.gauge-serie']);

function GaugeUtils() {

    return {
        calculateTicks : function(serie) {
            var ticks = [];
            var majorDelta = serie.range / (serie.majorTicks - 1);
            if (isFinite(majorDelta) && majorDelta > 0) {
                for ( var i = 0; i < serie.majorTicks; i ++) {
                    var major = serie.min + i * majorDelta;
                    var minorDelta = majorDelta / serie.minorTicks;
                    if (isFinite(minorDelta)) {
                        for ( var minor = major + minorDelta; minor < Math.min(major + majorDelta, serie.max); minor += minorDelta) {
                            ticks.push({
                                value : minor
                            });
                        }
                    }
                    ticks.push({
                        value : major,
                        major : true
                    });
                }

            }
            return ticks;
        },
        sanitizeSerie : function(serie, id) {
            serie._id = id;
            serie.min = parseFloat(serie.min);
            serie.min = isFinite(serie.min) ? serie.min : 0;
            serie.max = parseFloat(serie.max);
            serie.max = isFinite(serie.max) ? serie.max : 100;
            if (serie.max < serie.min) {
                var min = serie.max;
                serie.max = serie.min;
                serie.min = min;
            }
            serie.majorTicks = isFinite(serie.majorTicks) ? serie.majorTicks : 5;
            serie.minorTicks = isFinite(serie.minorTicks) ? serie.minorTicks : 2;
            serie.range = serie.max - serie.min;
            serie.precision = isFinite(serie.precision) ? serie.precision : 2;
            serie.average = isFinite(serie.average) ? serie.average : 1;
            serie.scale = isFinite(serie.scale) ? serie.scale : 0;
            _.each(serie.zones, function(zone) {
                zone.from = Math.max(zone.from, serie.min);
                zone.from = Math.min(zone.from, serie.max);
                zone.to = Math.min(zone.to, serie.max);
                zone.to = Math.max(zone.to, serie.min);
            });
        }

    };
}

module.service('gaugeUtils', GaugeUtils);

function GaugeSerieFactory(gaugeUtils, GaugeSerie, HalfGaugeSerie, DigitalGaugeSerie) {
    return {
        get : function(body, serie, type) {
            if (type === 'gauge') {
                return new GaugeSerie(body, serie);
            } else if (type === 'half-gauge') {
                return new HalfGaugeSerie(body, serie);
            } else if (type === 'digital-gauge') {
                return new DigitalGaugeSerie(body, serie);
            }
        }
    };
}
module.service('gaugeSerieFactory', GaugeSerieFactory);
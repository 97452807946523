<div class="container">
    <div class="row">
        <div class="panel panel-default">
            <div class="panel-heading"><i class="fa fa-fw fa-edit"></i>&nbsp;{{'parameterScore.title' | i18n}}</div>
            <div class="panel-body">
                <div class="form-inline">
                    <div class='input-group date'>
                        <input type='text' class="form-control" x-ng-model="dateFrom" x-datetimepicker=""/>
                            <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                    </div>
                    <div class='input-group date'>
                        <input type='text' class="form-control" x-ng-model="dateTo" x-datetimepicker=""/>
                            <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                    </div>
                    <div class="form-group">
                        <button class="btn btn-success"><i class="fa fa-search" data-ng-click="ui.getReportData()"></i></button>
                    </div>
                </div>
                <div class="col-sm-3">
                    <table class="table table-condensed">
                        <thead>
                            <tr>
                                <th>
                                   <span data-ng-click="ui.order('name')">{{'userScore.name' | i18n}}</span>
                                </th>
                                <th>
                                    <span data-ng-click="ui.order('score')">{{'userScore.score' | i18n}}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-ng-repeat="score in scores | orderBy:predicate:reverse">
                                <td>
                                    {{score.name}}
                                </td>
                                <td>
                                    {{score.score}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="panel-footer clearfix">
                <button class="btn btn-primary" data-ng-click="ui.getCsv()"><i class="fa fa-save"></i> {{'userScore.csv' | i18n}}</button>
            </div>
        </div>
    </div>
</div>
var module = angular.module('meternet.dashboard.controllers.shiftBarGraphWidget', [ 'adf.provider', 'i18n',
        'meternet.dashboard.constants' ]);

module.config(function(dashboardProvider, contextPath, messages) {
    // template object for github widgets
    var widget = {
        templateUrl: 'dashboard/shift-bar-graph-widget.html',
        title : messages['dashboard.widgets.barGraph.title'],
        description : messages['dashboard.widgets.barGraph.desc'],
        controller : 'ShiftBarGraphWidgetCtrl',
        screenshot: true,
        resolve : {

        },
        config : {
            series : [ {} ],
            type : "shift-bar-graph"
        },
        edit : {
            controller : 'ShiftBarGraphWidgetEditCtrl',
            templateUrl: 'dashboard/shift-bar-graph-widget-edit.html'
        }
    };

    dashboardProvider.widget('shift-bar-graph', widget);

});

module.controller('ShiftBarGraphWidgetCtrl', function($scope, $timeout, dataService, config, DashboardEvents, $q) {

    //var currentTimeTo = function() {
    //    return moment();
    //};
    //
    //var currentTimeFrom = function() {
    //    var from = moment().subtract(7, config.period);
    //    return from;
    //};

    $scope.data = [];

    $scope.$on('shiftBarGraphDatesChanged', function($event, dateFrom, dateTo){
        if (!isNaN(dateFrom) && !isNaN(dateTo)) {
            dateFrom = moment(dateFrom);
            dateTo = moment(dateTo);
            getData(dateFrom, dateTo);
        }

    });

    function updateData(index, data, concat) {
        if (!concat) {
            $scope.data = [];
            for (var i = 0; i < data.length; i++) {
                for (var j = 0; j < data[i].length; j ++) {
                    data[i][j].index = i;
                    $scope.data.push(data[i][j]);
                }

            }
        } else {
            for (i = 0; i < data.length; i++) {
                data[i].index = index;
                $scope.data.push(data[i]);
            }
        }

        $scope.$broadcast(DashboardEvents.REDRAW, $scope.data);
    }
   // getData(currentTimeFrom(), currentTimeTo());
    function getData(dateFrom, dateTo) {
        var promises = [];
        _.each(config.series, function(series, index) {
            var request = {};
            request.deviceId = series.deviceId;
            request.paramId = series.paramId;
            request.timeTo = dateTo;
            request.timeFrom = dateFrom;

            var promise = dataService.requestParamData(series.paramId, request);
            promise.deviceId = series.deviceId;
            promise.paramId = series.paramId;
            promises.push(promise);
        });
        $q.all(promises).then(
            function(measurements) {
                updateData(0, measurements, false);
                _.forEach(promises, function(p, i) {

                    var unsubscribeFn = dataService.subscribeForParametersMeasurements(promises[i].deviceId,
                        promises[i].paramId, function(data) {
                            updateData(i, data, true);
                        });
                    $scope.$on('$destroy', unsubscribeFn);
                });
            });
    }

});


function ShiftBarGraphWidgetEditCtrl($scope, $filter, contextPath, dataService, configService, config, ColorPickerOpts, UnitScales, Weekdays, Months) {

    var colors = ColorPickerOpts.palette[0];
    var i18n = $filter('i18n');
    var modalDialog = $('.modal')[0];
    $scope.config = config;
    var getParamsForDevice = function() {
        if ($scope.config.deviceId) {
            $scope.selectedDevice = _.find($scope.devices, function(device) {
                return $scope.config.deviceId === device.id;
            });
            $scope.ui.parseCronExpression($scope.selectedDevice.cronExpression);
            $scope.config.period = $scope.ui.period ? ($scope.ui.period.value || 'day') : 'day';
            $scope.config.historyTime = $scope.ui.getHistoryTime();
            $scope.config.barSize = $scope.config.barSize || 20;
            $scope.config.barSpacing = $scope.config.barSpacing || 5;
            $scope.config.tickLabelEveryXTicks = $scope.config.tickLabelEveryXTicks || 1;
            if (!$scope.config.axis) {
                $scope.config.axis = {};
            }
            $scope.config.axis.scale = $scope.config.axis.scale || 0;
            $scope.config.axis.precision = $scope.config.axis.precision || 0;
            $scope.config.unit = $scope.config.unit || 'Wh';
            $scope.config.axis.rangeAuto = !angular.isDefined($scope.config.axis.rangeAuto) ? true : $scope.config.axis.rangeAuto;
        }
    };

    function serie(from, to, step, pad) {
        var d = [];
        for (var i = from; i <= to; i += step) {
            d.push({
                value : "" + i,
                label : zeropad(i, pad)
            });
        }
        return d;
    }

    function zeropad(str, n) {
        if (str != null) {
            str = "" + str;
            while (str.length < n) {
                str = "0" + str;
            }
        }
        return str;
    }

    function days() {
        var d = [];
        for (var i = 1; i <= 31; ++i) {
            d.push({
                value : "" + i,
                label : "" + i
            });
        }
        d.push({
            value : "L",
            label : $filter('i18n')('ui.cron.last')
        });
        return d;
    }

    $scope.ui = {
        scales : UnitScales,
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ],
        axisRanges : [ {
            value : true,
            label : i18n("dashboard.widgets.config.series.axis.range.options.auto")
        }, {
            value : false,
            label : i18n("dashboard.widgets.config.series.axis.range.options.manual")
        } ],
        //periods: [{
        //    label:  i18n("dashboard.widgets.shiftBar.period.day"),
        //    value: "day"
        //}, {
        //    label: i18n("dashboard.widgets.shiftBar.period.week"),
        //    value: "week"
        //}, {
        //    label: i18n("dashboard.widgets.shiftBar.period.month"),
        //    value: "month"
        //}],
        period : null,
        step : null,
        value : [],
        periods : [ {
            value : "s",
            pos : 0,
            show : "s",
            steps : serie(1, 120, 1),
            label : $filter('i18n')('ui.cron.periods.seconds')
        }, {
            value : "m",
            pos : 1,
            show : "ms",
            steps : serie(1, 120, 1),
            label : $filter('i18n')('ui.cron.periods.minutes')
        }, {
            value : "day",
            pos : 2,
            show : "hms",
            steps : serie(1, 48, 1),
            label : $filter('i18n')('ui.cron.periods.hours')
        }, {
            value : "week",
            pos : 3,
            show : "dhms",
            steps : serie(1, 60, 1),
            label : $filter('i18n')('ui.cron.periods.days')
        }, {
            value : "week",
            pos : 5,
            show : "whms",
            steps : serie(1, 10, 1),
            label : $filter('i18n')('ui.cron.periods.weeks')
        }, {
            value : "month",
            pos : 4,
            show : "Mdhms",
            steps : serie(1, 24, 1),
            label : $filter('i18n')('ui.cron.periods.months')
        } ],
        values : [ serie(0, 59, 1, 2), serie(0, 59, 1, 2), serie(0, 23, 1, 2), days(), Months, Weekdays ],
        init : function() {
            this.period = this.periods[0];
            this.step = this.period.steps[0];
            this.value[0] = this.values[0][0];
            this.value[1] = this.values[1][0];
            this.value[2] = this.values[2][0];
            this.value[3] = this.values[3][0];
            this.value[4] = this.values[4][0];
            this.value[5] = this.values[5][0];
        },
        getHistoryTime: function () {
            if ($scope.ui.period.pos === 5 || $scope.ui.period.pos === 4) {
                return 1;
            } else if ($scope.ui.period.pos === 3) {
                return 7;
            }
            return Math.floor(24/$scope.ui.step.value);
        },
        parseCronExpression: function (cronExpression) {
            var expr, i, e, tval, tstep, tperiod, valid = true;

            if (cronExpression) {
                expr = cronExpression.split(" ");
                if (expr.length === 5) {
                    expr[5] = "*";
                }
                if (expr.length === 6) {
                    expr[6] = "*";
                }
                if (expr.length === 7) {
                    tval = [];
                    tstep = null;
                    tperiod = null;
                    for (i = 0; i < expr.length; ++i) {
                        e = expr[i].split("/");
                        if (e.length > 2) {
                            valid = false;
                            break;
                        }
                        tval[i] = _.findWhere(this.values[i], {
                            value : e[0]
                        });
                        if ((tperiod === null && e[0] === "*" && e.length === 1) || e.length === 2) {
                            tperiod = _.findWhere(this.periods, {
                                pos : i
                            });
                            if (tperiod == null) {
                                valid = false;
                                break;
                            }
                            tstep = _.findWhere(tperiod.steps, {
                                value : e[1] || "1"
                            });
                            if (tstep == null) {
                                valid = false;
                                break;
                            }
                        }
                    }
                }
            }

            if (valid) {
                valid = false;
                for (i = 0; i < tval.length; ++i) {
                    if (tval[i] != null) {
                        this.value[i] = tval[i];
                        valid = true;
                    }
                }
                if (valid) {
                    this.period = tperiod;
                    this.step = tstep;
                }
            }
        },
        canAddSeries : function() {
            return true;
        },
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                    deviceId : $scope.selectedDevice.id,
                    paramId : null
                };
                $scope.config.series.push(series);
            }
        },
        getBarColors: function(series) {
            var barColors = series.barColors || [];
            var barNumber = config.historyTime;
            if (barColors.length > barNumber) {
                barColors.length = barNumber;
            } else if (barColors.length < barNumber) {
                for (var i = barColors.length; i < barNumber; ++i) {
                    barColors.push({
                        "name": "serie" + (i + 1),
                        "color": "#4682B4"
                    });
                }
            }

            series.barColors = barColors;

            return series.barColors;
        },
        initBarColors: function() {
            for (var i = 0; i < config.series.length; ++i) {
                $scope.ui.getBarColors(config.series[i]);
            }
        },
        removeSeries : function(series) {
            var index = $scope.config.series.indexOf(series);
            $scope.config.series.splice(index, 1);
        },
        updateSeriesParam : function(series) {
console.log(series);
            if (series.deviceId !== series._device.id) {
                series._param = series._device.params[0];
            }
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.axis.scale = series._param.scale || 0;
                series.axis.precision = series._param.precision || 0;

                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                series.unit = series._param.type.unit;
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
        },
        deviceSelected: function() {
            getParamsForDevice();
        },
        colorPickerOpts : angular.extend({
            appendTo : modalDialog
        }, ColorPickerOpts)
    };

    if (!$scope.config.series) {
        $scope.config.series = [];
    }

    $scope.ui.init();

    configService.get().then(function(config) {
        $scope.devices = [];
        var energyReportsMain = _.filter(config.engine.moduleInputs, function(input) {
            return input.type === 'energy-report';
        });

        for (var i = 0; i < energyReportsMain.length; ++i) {
            for(var j = 0; j < energyReportsMain[i].devices.length; j++) {
                $scope.devices.push(energyReportsMain[i].devices[j]);
            }
        }
        getParamsForDevice();

    });
}

module.controller('ShiftBarGraphWidgetEditCtrl', ShiftBarGraphWidgetEditCtrl);

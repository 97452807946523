<div class="modal-header modal-title"><i class="fa fa-fw" x-ng-class="{'fa-plus': ui.editMode==false, 'fa-gears': ui.editMode==true}"></i>&nbsp;{{ ui.modalTitle | i18n }}{{' ' + username.username}}
    <button type="button" class="close" x-ng-click="ui.cancel()" aria-hidden="true">×</button>
</div>
<div class="modal-body">
    <div class="alert alert-danger" role="alert" x-ng-if="ui.errors.length > 0">
        <ul>
            <li x-ng-repeat="error in ui.errors">{{ error | i18n }}</li>
        </ul>
    </div>
    <div class="alert alert-success" role="alert" x-ng-if="ui.success">
        <span> {{ ui.successMsg | i18n }} </span>
    </div>
    <div class="form form-horizontal">
        <div class="form-group">
            <label class="control-label col-sm-2" style="padding-right:20px;">{{ 'config.groupName' | i18n }}</label>
            <div class="col-sm-9">
                <input class="form-control" type="text" data-ng-model="group.name"> </input>
            </div>

        </div>

        <div class="form-group">
            <label class="control-label col-sm-2"style="padding-right:20px;">{{ 'config.group.roles' | i18n }}</label>
            <div class="col-sm-9" style="margin-top:3px;">
                <permission-control permissions="group.permissions"></permission-control>
            </div>
        </div>

    </div>



</div>
<div class="modal-footer" x-ng-hide="ui.success">
    <button class="btn btn-success" x-ng-click="ui.save()" x-ng-disable="ui.locked">
        <i class="fa fa-save fa-lg"></i>&nbsp;&nbsp;{{'ui.save'| i18n }}
    </button>
    <button class="btn btn-danger" x-ng-click="ui.cancel()">
        <i class="fa fa-undo fa-lg"></i>&nbsp;&nbsp;{{'ui.cancel'| i18n }}
    </button>
</div>
var module = angular.module('meternet.scores.directives', []);

module.directive('parameterScoresList', function(){
    return {
        scope:true,
        templateUrl:'scores/parameterScores.html',
        controller: function($scope, scoresService, configService, $uibModal, $q) {
            $scope.parameterScores = [];
            var reloadData = function(){
                scoresService.get().then(function(data) {
                    $scope.parameterScores = data;
                });
            };
            reloadData();
            var filterParameters = function (parameterScore) {
                var deferred = $q.defer();
                configService.get().then(function(config) {
                    var inputs = config.engine.measurementInputs;
                    var result = [];
                    for (var i = 0; i < inputs.length; ++i) {
                        var devices = inputs[i].devices;
                        for (var j = 0; j < devices.length; ++j) {
                            var params = devices[j].params;
                            for(var k = 0; k < params.length; k++) {
                                var parameter = _.find($scope.parameterScores, function(parameterScore) {
                                   return parameterScore.paramId === params[k].id;
                                });
                                if (!parameter || (parameterScore && parameterScore.paramId === params[k].id)) {
                                    result.push(params[k]);
                                }
                            }
                        }
                    }
                    deferred.resolve(result);
                });
                return deferred.promise;
            };

            $scope.ui = {

                addParameterScore: function(){
                    var model = {
                        paramId: null,
                        ranges: [],
                        interval: null
                    };
                    model.ranges.push({
                        min: null,
                        max:null,
                        score: null
                    });
                    filterParameters().then(function(parameters) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: "scores/addParameterScore.html",
                            controller: "addParameterScoreCtrl",
                            size: 'lg',
                            resolve: {
                                model: function() {
                                    return model;
                                },
                                parameters: function() {
                                    return parameters;
                                }
                            }
                        });

                        modalInstance.result.then(function(result) {
                            reloadData();
                            //$scope.parameterScores.push(result);
                        });
                    });

                },
                edit: function(parameterScore) {

                    filterParameters(parameterScore).then(function(parameters) {
                        var modalInstance = $uibModal.open({
                            animation: true,
                            templateUrl: "scores/addParameterScore.html",
                            controller: "addParameterScoreCtrl",
                            size: 'lg',
                            resolve: {
                                model: function() {
                                    return parameterScore;
                                },
                                parameters: function() {
                                    return parameters;
                                }
                            }
                        });

                        modalInstance.result.then(function(result) {
                            reloadData();
                        });
                    });

                },
                delete: function(parameterScore) {
                    scoresService.remove(parameterScore).then(function(result) {
                        reloadData();
                    })
                }
            }
        }
    }
});

module.directive('userScoresReport', function() {
    return {
        scope: true,
        templateUrl: 'scores/userScoresReport.html',
        controller: function($scope, scoresService, userService, $filter) {
            $scope.dateFrom = moment().startOf('month');
            $scope.dateTo = moment().endOf('month');
            $scope.scores = [];
            $scope.predicate = '';
            $scope.reverse = false;
            $scope.ui = {
                getReportData: function() {
                    scoresService.getScores($scope.dateFrom, $scope.dateTo).then(function(result) {
                        $scope.scores = result;
                        userService.load().then(function(result) {
                            for (var i = 0; i <$scope.scores.length; ++i) {
                                var userId = $scope.scores[i].userId;
                                for (var j = 0; j < result.users.length; ++j) {
                                    if (result.users[j].id === userId) {
                                        $scope.scores[i].name = result.users[j].username;
                                    }
                                }
                            }


                        });

                    })
                },
                getCsv: function() {
                    var csvContent = "data:text/csv;charset=utf-8,name, score, \n";
                    $scope.scores = $filter('orderBy')($scope.scores, $scope.predicate, $scope.reverse);
                    for (var i = 0; i < $scope.scores.length; ++i) {
                        csvContent += $scope.scores[i].name + ",";
                        csvContent += $scope.scores[i].score + "\n";
                    }
                    var encodedUri = encodeURI(csvContent);
                    var dateFrom = $scope.dateFrom.format("YYYY-MM-DD");
                    var dateTo =  $scope.dateTo.format("YYYY-MM-DD");
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "scores from "+ dateFrom+" to "+dateTo+".csv");
                    link.click();
                },
                order: function(predicate) {
                    $scope.reverse = ($scope.predicate === predicate) ? !$scope.reverse : false;
                    $scope.predicate = predicate;
                }
            }
        }
    }
});


<table class="table table-bordered table-condensed" style="margin-bottom: 0px;">
    <thead>
    <tr>
        <th x-ng-if='paramName!=null'>{{'config.momentRules.paramName' | i18n}}</th>
        <th>{{'config.momentRules.outputName' | i18n}}</th>
        <th x-ng-repeat="type in source.config.eventTypes">{{'config.event.type.' + type | i18n}}</th>
    </tr>
    </thead>
    <tbody x-ng-repeat="rules in simpleRules track by $index">
    <tr>
        <td style="vertical-align: middle;" class="col-xs-2" x-ng-if='$index==0 && paramName!=null'
            rowspan="{{simpleRules.length}}">
            {{paramName}}
        </td>
        <td class="col-xs-19">{{rules[0].outputName}}</td>
        <td style="text-align: center;" class="col-xs-1" x-ng-repeat="rule in rules">
            <div x-ng-if="rule.ruleId" x-ng-click="removeMomentRule(rule.ruleId)">
                <i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
            </div>
            <div x-ng-if="!rule.ruleId" x-ng-click="addMomentRule(rule.outputId, rule.type)">
                <i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
            </div>
        </td>
    </tr>
    </tbody>
</table>

<form class="form-inline" x-ng-if="advanceView.value">
    <div class="panel panel-default">
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="eventRule in eventRules track by $index">
                <div class="form-group config-rule" x-config-form-event-rule="eventRule" x-source-config="source.config"
                     x-engine-config="config.engine"></div>
                <div class="form-group" style="vertical-align: top;">
                    <label class="form-group-label">&nbsp;</label>
                    <button class="btn btn-danger" x-ng-click="ui.removeEventRule($index)">
                        <i class="fa fa-trash"></i>
                    </button>
                </div>
            </li>
        </ul>
        <div class="panel-body">
            <div class="pull-right">
                <button class="btn btn-success" x-ng-click="ui.addEventRule()">
                    <i class="fa fa-plus"></i>&nbsp;{{'config.eventRule.add' | i18n}}
                </button>
            </div>
        </div>
    </div>
</form>
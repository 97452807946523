<div x-expand-to-parent-bottom="" style="overflow-y: auto;">
    <center>
        <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax({{minWidth_short}}, 1fr)); grid-gap: 10px; padding: 10px;" x-ng-if="options.layout==false">
            <div x-ng-class="{'error': serie.error}" x-ng-repeat="serie in options.series" style="border-width: 1px; border-style: solid; border-color: #ccc; border-radius: 5px;">
                <table style="min-width:{{minWidth_short}}; margin: 15px 0px;">
                    <tbody>
                        <tr>
                            <td colspan="2" style="text-align: center; padding: 5px;">
                                <h3 style="margin-bottom:2px; margin-top:0px">{{serie.name}}</h3>
                                <font style="color:#ccc">{{serie.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</font>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div x-ng-if="serie.value" style="margin: 0 auto; width: 65px; text-align: center; font-size: 12px; font-weight: bold; color: white; background-color: #5cb85c; border-color: #4cae4c; border-radius: 5px; line-height: 32px;">
                                    {{serie.onName}}
                                </div>
                                <div x-ng-if="!serie.value" style="margin: 0 auto; width: 65px; text-align: center; font-size: 12px; font-weight: bold; color: white; background-color: #d9534f; border-color: #d43f3a; border-radius: 5px; line-height: 32px;">
                                    {{serie.offName}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax({{minWidth_long}}, 1fr)); grid-gap: 10px; padding: 10px;" x-ng-if="options.layout==true">
            <div x-ng-class="{'error': serie.error}" x-ng-repeat="serie in options.series" style="border-width: 1px; border-style: solid; border-color: #ccc; border-radius: 5px;">
                <table style=" margin: 15px 0px;">
                    <tbody>
                    <tr>
                        <td colspan="2" style="text-align: center; padding: 0px; width:{{minWidth_short}}">
                            <h3 style="margin-bottom:2px; margin-top:0px">{{serie.name}}</h3>
                            <font style="color:#ccc">{{serie.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</font>
                        </td>
                        <td style="text-align: right; width:140px">
                            <div x-ng-if="serie.value" style="margin: 0 auto; width: 65px; text-align: center; font-size: 12px; font-weight: bold; color: white; background-color: #5cb85c; border-color: #4cae4c; border-radius: 5px; line-height: 32px;">
                                {{serie.onName}}
                            </div>
                            <div x-ng-if="!serie.value" style="margin: 0 auto; width: 65px; text-align: center; font-size: 12px; font-weight: bold; color: white; background-color: #d9534f; border-color: #d43f3a; border-radius: 5px; line-height: 32px;">
                                {{serie.offName}}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </center>
</div>
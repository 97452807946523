<form>
    <div class="form-inline">
        <div class="form-group">
            <label class="form-group-label">{{'config.cronExpression.period' | i18n }}</label>
            <select class="form-control" style="width: 100px;"
                    x-ng-model="ui.period" x-ng-options="p as (p.label|i18n) for p in ui.periods"
                    x-ng-change="ui.updateCronExpression()"></select>
        </div>
    </div>
    <div class="form-inline">
        <div class="form-group" x-ng-show="ui.period.value === 3">
            <label class="form-group-label">{{'config.cronExpression.day' | i18n }}</label>
            <select class="form-control" style="width: 100px;"
                    x-ng-model="ui.weekday" x-ng-options="w as w.label for w in ui.weekdays"
                    x-ng-change="ui.updateCronExpression()"></select>
        </div>

        <div class="form-group" x-ng-show="ui.period.value === 4">
            <label class="form-group-label">{{'config.cronExpression.day' | i18n }}</label>
            <select class="form-control" style="width: 100px;" x-ng-model="ui.day"
                    x-ng-options="d as d.label for d in ui.days" x-ng-change="ui.updateCronExpression()"></select>
        </div>

        <div class="form-group"
             x-ng-hide="ui.period.value === 1 || ui.period.value === 0 || ui.period.value === 5 || ui.period.value === 6">
            <label class="form-group-label">{{'config.cronExpression.hour' | i18n }}</label>
            <select class="form-control" style="width: 100px;"
                    x-ng-model="ui.hour" x-ng-options="h as h.label for h in ui.hours"
                    x-ng-change="ui.updateCronExpression()"></select>
        </div>

        <div class="form-group" x-ng-show="ui.period.value === 1">
            <label class="form-group-label">{{'config.cronExpression.hour' | i18n }}</label>
            <div class="input-group">
                <div id="cronHours" class="danger" style="width: 100px;" x-isteven-multi-select=""
                     x-input-model="ui.hoursall" x-output-model="ui.hourssel" x-max-labels="4"
                     x-button-label="label" x-item-label="label" x-tick-property="selected"
                     x-translation="ui.cronTranslations" x-helper-elements=""
                     x-on-item-click="ui.updateCronExpression()"
                     x-on-close="ui.updateCronExpression()"></div>
            </div>
        </div>
        <div class="form-group" x-ng-hide="ui.period.value === 0 || ui.period.value === 5 || ui.period.value === 6">
            <label class="form-group-label">{{'config.cronExpression.minute' | i18n }}</label>
            <select class="form-control" style="width: 100px;"
                    x-ng-model="ui.minute" x-ng-options="m as m.label for m in ui.minutesAll"
                    x-ng-change="ui.updateCronExpression()"></select>
        </div>
        <div class="form-group" x-ng-show="ui.period.value === 0">
            <label class="form-group-label">{{'config.cronExpression.everyXminutes' | i18n }}</label>
            <select class="form-control" style="width: 100px;"
                    x-ng-model="ui.minute" x-ng-options="m as (m.label|i18n) for m in ui.minutes"
                    x-ng-change="ui.updateCronExpression()"></select>
        </div>
        <div class="form-group" x-ng-show="ui.period.value === 5">
            <label class="form-group-label">{{'config.cronExpression.seconds' | i18n }}</label>
            <select class="form-control" style="width: 100px;"
                    x-ng-model="ui.seconde" x-ng-options="s as (s.label|i18n) for s in ui.secondes"
                    x-ng-change="ui.updateCronExpression()"></select>
        </div>
        <div class="form-group" x-ng-show="ui.period.value === 6">
        </div>
    </div>
</form>

var module = angular.module('meternet.chart.directives.mbsat2Bottom', ['meternet.charts.services']);

module.directive('mbsat2Bottom', function ($q, $http, $stateParams, dashboardService, paramService) {
  return {
    scope: {
      menuView: '=',
      locationWithinBounds: '=',
      markersWithinBounds: '=',
      onlyMapMarkers: '=',
      clusterLayer: '=',
      noMap: '=',
      config: '='
    },
    templateUrl: 'dashboard/mbsat2/mbsat2-bottom.html',
    controller: function ($scope) {
      $scope.selectedDevices = [];
      $scope.data = {selectedLightingSetting: null};
      $scope.lightingSettings = null;
      $scope.devicesColumn = 'device.label';
      $scope.devicesReverse = false;
      $scope.lightingSettingsColumn = 'name';
      $scope.lightingSettingsReverse = false;
      $scope.searchDevice = null;
      $scope.params = [];
      $scope.ui = {
        processing: false,
        allSelected: false,
        selectAll: function () {
          if (!$scope.ui.allSelected) {
            $scope.selectedDevices.length = 0;
            $scope.markersWithinBounds.forEach(function (val) {
              val.checked = true;
              $scope.selectedDevices.push(val.device);
            })
          } else {
            $scope.markersWithinBounds.forEach(function (val) {
              val.checked = false;
            })
            $scope.selectedDevices.length = 0;
          }
          $scope.$emit('selectAllMarkers');
        },
        checked: function (marker) {
          return _.findIndex($scope.selectedDevices, function (o) {
            return o.id === marker.deviceId;
          }) > -1
        },
        checkboxClick: function (marker) {
          if (!this.checked(marker)) {
            $scope.selectedDevices.push(marker);
            marker.checked = true;
          } else {
            var idx = _.findIndex($scope.selectedDevices, function (o) {
              return o.id === marker.deviceId;
            });
            $scope.selectedDevices.splice(idx, 1);
            marker.checked = false;
          }
          $scope.$emit('selectMarker', marker);
        },
        overMarker: function (marker) {
          $scope.$emit('overMarker', marker);
        },
        leaveMarker: function (marker) {
          $scope.$emit('leaveMarker', marker);
        },
        getCurrent: function (marker) {
          var value;
          _.forEach(marker.statusParams, function (param) {
            if (param.tags.indexOf("main")>=0 && param.moment && param.moment.current) {
              value = param;
              return false;
            }
          });
          return value;
        }
      };

      dashboardService.isDashboardEditable($stateParams.dashboardName).then(function (editable) {
        $scope.editable = editable;
      });

      $scope.sortColumn = function (col, colName, revName) {
        $scope[colName] = col;
        $scope[revName] = !$scope[revName];
      };
      $scope.sortIcon = function (col, colName, revName) {
        if ($scope[colName] === col) {
          return $scope[revName] ? 'fa fa-caret-down' : 'fa fa-caret-up';
        } else {
          return '';
        }
      }

      $scope.changeView = function (mark) {
//        $scope.$emit('adfDashboardChanged', null);
//        $scope.$emit('meternetConfigUpdate', null);
        $scope.$emit('changeView', mark);

      };

      $scope.hideMap = function(){
        $scope.$emit('hideMap');
      }

      $scope.$on('clickMarker', function (event, marker) {
        var mark = _.findWhere($scope.markersWithinBounds, {lat: marker.lat, lng: marker.lng});
        if (mark) {
          $scope.$emit('clickMarkerOnMap', $scope.ui.checked(mark));
        }
      });
      $scope.$on('checkMarker', function (event, marker) {
        var mark = _.findWhere($scope.markersWithinBounds, {lat: marker.lat, lng: marker.lng});
        $scope.ui.checkboxClick(mark);
      });

      $scope.$watchCollection('selectedDevices', function (newVal) {
        if (newVal) {
          $scope.ui.allSelected = ($scope.markersWithinBounds.length === $scope.selectedDevices.length) && $scope.markersWithinBounds.length !== 0;
        }
      });

      $scope.$watch('searchDevice', function (newVal) {
        if (typeof newVal !== 'undefined' && newVal !== null) {
          $scope.$emit('search', newVal);
        }
      });

      $scope.$watch('config', function (config) {
        if (!config) {
          return
        }
        $scope.selectedDevices.length = 0;
        config.engine.lightingSettings = config.engine.lightingSettings || [];
        $scope.lightingSettings = config.engine.lightingSettings;
        if ($scope.lightingSettings.length) {
          $scope.data.selectedLightingSetting = $scope.lightingSettings[0].id;
          $scope.ui.processing = false;
        }
      });

      $scope.$watchCollection('markersWithinBounds', function (newVal) {
        $scope.params = paramService.prepareParamsForMarkers($scope.markersWithinBounds);
        paramService.unsubscribeUnchecked($scope.params);
        paramService.subscribeParams($scope.params);
      });
      $scope.$on('$destroy', function() {
        paramService.unsubscribeUnchecked([]);
      });
    }
  }
});

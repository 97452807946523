var module = angular.module('meternet.timetable.directives', ['meternet.filters'
]);

module.filter("framesFilter", function(){
    return function(items, dateFrom, dateTo) {
        var result = [];
        _.forEach(items, function(frame) {
            if (frame.dateFrom.isBetween(dateFrom, dateTo) || frame.dateTo.isBetween(dateFrom, dateTo) || frame.dateFrom.isSame(dateFrom) || frame.dateTo.isSame(dateTo)) {
                result.push(frame);
            }
        });
        return result;
    }
});

module.directive("timetableList", function(timetableService){

    return {
        scope: true,
        templateUrl: 'timetable/timetableList.html',
        controller: function($scope) {
            var reloadData = function() {
                timetableService.get().then(function(result){
                    $scope.timetables = result;
                });
            };
            reloadData();

            $scope.ui = {
                "delete": function(timetable) {
                    timetableService.remove(timetable).then(function(result) {
                        reloadData();
                    });
                },
                search: function(item) {
                    if (!$scope.query || $scope.query === '') {
                        return true;
                    }
                    if (item.weekFrom.toString().indexOf($scope.query) > -1 ||
                        item.weekTo.toString().indexOf($scope.query) > -1 ||
                        item.year.toString().indexOf($scope.query) > -1 ||
                        item.parameterNames.toLowerCase().indexOf($scope.query.toLowerCase()) > -1) {
                        return true;
                    }
                    return false;
                }
            }
        }
    }
});

module.directive('timetableFrame', function(userService, $uibModal){
    return {
        scope: {
            frame: '=',
            timetable:'=',
            viewDateFrom:'@',
            viewDateTo:'@'
        },
        templateUrl: 'timetable/frame.html',
        link: function($scope, element, attrs) {

            $scope.mainPartVisible=true;

            var getColor = function(frame){
                var userId = frame.userId;
                var splitted = userId.split('-');
                return hexToRgb(splitted[0]);

            };


            $scope.ui = {
                removeFrame: function(id) {
                    var frames = $scope.timetable.frames;
                    var index = $scope.timetable.frames.indexOf($scope.frame);
                    for (var i = 0; i < frames.length; ++i) {
                        if (frames[i].id === id) {
                            frames.splice(i, 1);
                            break;
                        }
                    }
                    $scope.$emit('redrawFrames', true);
                },
                editFrame: function() {
                    var model = $scope.frame;
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: "timetable/addFrame.html",
                        controller: "frameCtrl",
                        size: 'lg',
                        resolve: {
                            model: function(){
                                return model;
                            },
                            timetable: function() {
                                return $scope.timetable;
                            }
                        }
                    });

                    modalInstance.result.then(function(result) {
                        var index = $scope.timetable.frames.indexOf($scope.frame);
                        $scope.timetable.frames[index] = result;
                        $scope.frame = result;
                        drawFrame();
                    })
                }
            };

            function hexToRgb(hex) {
                var bigint = parseInt(hex, 16);
                var r = (bigint >> 16) & 255;
                var g = (bigint >> 8) & 255;
                var b = bigint & 255;
                return r + "," + g + "," + b;
            }

            userService.load().then(function(data) {
                $scope.users = data.users;
                drawFrame();
            });

            var getProperties = function(elem, dateFrom, dateTo) {
                var cellHeight = elem.outerHeight();
                var cellWidth = elem.outerWidth();
                var cellCoordinates = elem.offset();
                var top = cellCoordinates.top;
                var left = cellCoordinates.left;
                var rgb = getColor($scope.frame);

                var minutesFrom = dateFrom.minutes();
                var shiftTop = 0;
                var rgb = getColor($scope.frame);
                if (minutesFrom == 15) {
                    shiftTop = cellHeight / 4;
                } else if (minutesFrom == 30) {
                    shiftTop = cellHeight / 2;
                } else if (minutesFrom == 45) {
                    shiftTop = cellHeight * 3 / 4
                }
                var minutesTo = dateTo.minutes();
                var shiftBottom = 0;
                if (minutesTo == 15) {
                    shiftBottom = cellHeight / 4;
                } else if (minutesTo == 30) {
                    shiftBottom = cellHeight / 2;
                } else if (minutesTo == 45) {
                    shiftBottom = cellHeight * 3 / 4
                }

                var dateFromMinutes0 = moment(dateFrom).minutes(0);
                var diffHours = dateTo.diff(dateFromMinutes0, 'hours');
                var borderWidth = elem.css("border-top-width");
                borderWidth = borderWidth.substring(0, borderWidth.indexOf('p'));
                return {
                    cellHeight: cellHeight,
                    diffHours: diffHours,
                    shiftTop: shiftTop,
                    shiftBottom: shiftBottom,
                    rgb: rgb,
                    borderWidth: borderWidth,
                    cellWidth: cellWidth,
                    left: left,
                    top: top
                }

            };

            var drawFrame = function() {
                var frame = $scope.frame;
                var id = frame.dateFrom.format("YYYY-MM-DD-H");
                var dateFromCopy = moment(frame.dateFrom);
                var dateToCopy = moment(frame.dateTo);
                var frameDivided = false;
                if (dateFromCopy.days() !== dateToCopy.days()) {
                    dateToCopy.hour(0);
                    frameDivided = true;
                    var dividedDateFrom = dateToCopy;
                    var dividedDateTo = moment(frame.dateTo);
                }
                var elem = $('#' + id);
                if (elem.length > 0) {
                    var displayDateFrom = frame.dateFrom.format("HH:mm");
                    var displayDateTo = frame.dateTo.format("HH:mm");
                    $scope.frameHours = displayDateFrom + ' - ' + displayDateTo;

                    var user = _.find($scope.users, function (user) {
                        return user.id === frame.userId;
                    });
                    $scope.username = user.username;

                    var properties = getProperties(elem, dateFromCopy, dateToCopy);

                    element.css("height", properties.cellHeight * properties.diffHours - properties.shiftTop + properties.shiftBottom)
                        .css("background-color", "rgba(" + properties.rgb + "," + "0.5)")
                        .css("position", "absolute")
                        .css("top", properties.top + properties.shiftTop - properties.borderWidth)
                        .css("width", properties.cellWidth)
                        .css("left", properties.left);

                } else {
                    $scope.mainPartVisible = false;
                }
                if (frameDivided) {
                    id = dividedDateFrom.format("YYYY-MM-DD-H");
                    elem = $('#'+id);
                    if (elem.length > 0) {
                        properties = getProperties(elem, dividedDateFrom, dividedDateTo);
                        var divToAppend = $('<div></div>').css("height", properties.cellHeight * properties.diffHours - properties.shiftTop + properties.shiftBottom)
                            .css("background-color", "rgba(" + properties.rgb + "," + "0.5)")
                            .css("position", "absolute")
                            .css("top", properties.top + properties.shiftTop - properties.borderWidth)
                            .css("width", properties.cellWidth)
                            .css("left", properties.left);
                        elem.append(divToAppend);
                    }
                }
            }
        }
    }
});

module.directive("addTimetable", function(){
   return {
       scope: true,
       templateUrl: 'timetable/addTimetable.html',
       controller: function($scope, $filter, timetableService, $uibModal, $stateParams, configService, userService, $compile, Errors, $state) {
           $scope.timetable = timetableService.getTimetableObject();
           $scope.isEditing = false;
           $scope.dataLoaded = true;
           $scope.framesForView = [];
           $scope.errors = {};
           $scope.typeMultiselectTranslations = {
                       selectAll       : $filter('i18n')('ui.tick.all'),
                       selectNone      : $filter('i18n')('ui.tick.none'),
                       reset           : $filter('i18n')('ui.reset'),
                       search          : $filter('i18n')('ui.search')
                   };
           $scope.typeMultiselectTranslations.nothingSelected = $filter('i18n')('timetable.view.table.select.param');
           var extendParameters = function(){
               configService.get().then(function(config) {
                   var inputs = config.engine.measurementInputs;
                   var inputParams = [];
                   var outputParams = [];
                   for (var i = 0; i < inputs.length; ++i) {
                       var devices = inputs[i].devices;
                       for (var j = 0; j < devices.length; ++j) {
                           var params = devices[j].params;
                           for(var k = 0; k < params.length; k++) {
                               if ($scope.isEditing) {
                                   var param = _.find($scope.timetable.parameters, function(param) {
                                       return param===params[k].id;
                                   });
                               }
                               if (param) {
                                   params[k].ticked = true;
                                   outputParams.push(params[k]);
                               } else {
                                   params[k].ticked = false;
                               }
                               inputParams.push(params[k]);
                           }
                       }
                   }
                   $scope.ui.inputParams = inputParams;
                   $scope.ui.outputParams = outputParams;
               });
           };

           if ($stateParams.id) {
               $scope.isEditing = true;
               timetableService.get().then(function(timetables){
                   var timetable = _.find(timetables, function(timetable) {
                       return timetable.id === $stateParams.id;
                   });
                   if (timetable) {
                       $scope.timetable = timetable;
                       extendParameters();
                       $scope.ui.getFramesForView();
                   }
               })
           } else {
               $scope.dataLoaded = true;
               extendParameters();
           }

           $scope.$watch('ui.outputParams', function(newVal, oldVal) {
               var result = [];
               for (var prop in newVal) {
                   var param = newVal[prop];
                   result.push(param.id);
               }
               $scope.timetable.parameters = result;
           });

           $scope.$on('redrawFrames', function(){
               $scope.ui.getFramesForView();
           });

           $scope.dateFrom = moment().startOf('week');
           $scope.dateTo = moment().endOf('week');

           $scope.ui = {
               inputParams:[],
               outputParams:[],
               save: function(){
                   $scope.ui.saving = true;
                   if ($scope.isEditing) {
                       timetableService.update($scope.timetable).then(function(result) {
                           if (result.status !== 200) {
                               $scope.timetable.errors = new Errors(result.data);
                               $scope.ui.saving = false;
                           } else {
                               $scope.timetable.errors = null;
                               $scope.ui.saving = false;

                               $state.go('main.timetable');
                           }
                       })
                   } else {
                       timetableService.save($scope.timetable).then(function(result) {
                           if (result.status !== 200) {
                               $scope.timetable.errors = new Errors(result.data);
                               $scope.ui.saving = false;
                           } else {
                               $scope.timetable.errors = null;
                               $scope.ui.saving = false;
                               $state.go('main.timetable');
                           }
                       });
                   }
               },
               addFrame: function(day, hour){
                   var date = day.add(hour, 'hours');
                   var model = timetableService.getFrameObject();
                   model.dateFrom = date;
                   model.dateTo = moment(date).add(8, 'hours');
                   //hack zwiazany z bledem podczas otwierania modala - przesuwa o scroll size
                   // http://stackoverflow.com/questions/29481323/angular-ui-modal-moves-the-page-when-is-open
                   var body = angular.element(document).find('body');
                   body.css({
                       overflow: 'inherit',
                       "padding-right": 'inherit !important'
               });
                   var modalInstance = $uibModal.open({
                       animation: true,
                       templateUrl: "timetable/addFrame.html",
                       controller: "frameCtrl",
                       size: 'lg',
                       resolve: {
                           model: function(){
                               return model;
                           },
                           timetable: function() {
                               return $scope.timetable;
                           }
                       }
                   });

                   modalInstance.result.then(function(result) {
                       //$scope.timetable.addFrame(result);
                       $scope.ui.getFramesForView();
                   });

               },
               copy: function(){
                    var modalInstance = $uibModal.open({
                        templateUrl: "timetable/framesCopy.html",
                        controller: "framesCopy",
                        size: 'lg',
                        resolve: {
                            frames: function() {
                                return $scope.timetable.frames;
                            }
                        }
                    });
                   modalInstance.result.then(function(result) {
                       $scope.timetable.frames = result;
                       $scope.ui.getFramesForView();
                   })
               },
               nextWeek: function(){
                   $scope.dateFrom.add(7, 'days');
                   $scope.dateTo.add(7, 'days');
                   $scope.days = getDays();
               },
               previousWeek: function() {
                   $scope.dateFrom.subtract(7, 'days');
                   $scope.dateTo.subtract(7, 'days');
                   $scope.days = getDays();
               },
               getFramesForView: function() {
                   var result = [];
                   _.forEach($scope.timetable.frames, function(frame) {
                       if (frame.dateFrom.days() !== frame.dateTo.days()) {
                           var splitted1 = _.clone(frame);
                           splitted1.dateFrom = moment(frame.dateFrom);
                           splitted1.dateTo = moment(frame.dateFrom).add(1, 'days').startOf('day');
                           result.push(splitted1);
                           var splitted2 = _.clone(frame);
                           splitted2.dateFrom = moment(frame.dateTo).startOf('day');
                           splitted2.dateTo = moment(frame.dateTo);
                           result.push(splitted2);

                       } else {
                           result.push(frame);
                       }
                   });
                   $scope.framesForView = result;
               },
               saving: false,
               getSaveClass: function() {
                   if ($scope.ui.saving) {
                       return "fa fa-save fa-spin";
                   }
                   return "fa fa-save";
               }
           };


           var getHours = function() {
               var result = [];
               for (var i = 0; i <=23; ++i) {
                   result.push(i);
               }
               return result;
           };
           $scope.hours = getHours();

           var getDays = function() {
               var result = [];
               for (var i = moment($scope.dateFrom); i <= $scope.dateTo; i = moment(i).add(1, 'days')) {
                   result.push(i);
               }
               return result;
           };
           $scope.days = getDays();

       }
   }
});

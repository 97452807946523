<div class="container">
    <div class="row">
        <div class="panel panel-default">
            <div class="panel-heading"><i class="fa fa-fw fa-calendar"></i>&nbsp;{{'timetable.title' | i18n}}</div>
            <div class="panel-body">
                <div class="input-group col-md-2" style="margin-top:10px;">
                    <input type="text" data-ng-model="query" class="form-control">
                    <span class="input-group-addon">
                        <i class="fa fa-search"></i>
                    </span>
                </div>

                <table class="table table-condensed table-stripped table-hover">
                    <thead>
                        <tr>
                            <th>
                                {{'timetable.weekFrom' | i18n}}
                            </th>
                            <th>
                                {{'timetable.weekTo' | i18n}}
                            </th>
                            <th>
                                {{'timetable.year' | i18n}}
                            </th>
                            <th>
                                {{'timetable.parameters' | i18n}}
                            </th>
                            <th>

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr data-ng-repeat="timetable in timetables | filter:ui.search" >
                            <td>
                                {{timetable.weekFrom}}
                            </td>
                            <td>
                                {{timetable.weekTo}}
                            </td>
                            <td>
                                {{timetable.year}}
                            </td>
                            <td>
                                {{timetable.parameterNames}}
                            </td>
                            <td>
                                <div class="pull-right">
                                    <button class="btn btn-sm btn-warning" data-ui-sref="main.editTimetable({id: timetable.id})" title="{{'ui.edit' | i18n}}"><i class="fa fa-edit fa-lg"></i> </button>
                                    <button class="btn btn-sm btn-danger" x-ng-click="ui.delete(timetable)"
                                            title="{{'ui.delete' | i18n}}">
                                        <i class="fa fa-trash-o fa-lg"></i>
                                    </button>
                                </div>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="panel-footer clearfix">
                <div class="pull-right">
                    <button class="btn btn-success" style="margin-right:5px;" data-ui-sref="main.addTimetable"><i class="fa fa-plus fa-lg"></i>  {{'timetable.add' | i18n}} </button>
                </div>
            </div>
        </div>
    </div>
</div>
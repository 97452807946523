<div flow-init="" flow-object="flow" x-flow-files-submitted="$flow.upload()" x-flow-file-success="ui.onFileSuccess()"
     style="margin-bottom: 10px;">
    <div class="file-drop" x-ng-if="ui.isEmpty()" x-flow-drop="" x-flow-btn=""
         x-flow-drag-enter="ui.cssClass = 'active'" x-flow-drag-leave="ui.cssClass = null" x-ng-class="ui.cssClass">
        <span class="file-drop-placeholder">{{'config.firmware.drop.placeholder' | i18n}}</span>
    </div>

    <div x-flow-transfers="" x-ng-if="$flow.isUploading()">
        <div x-ng-repeat="file in transfers">
            <div>{{file.name}}</div>
            <div x-uib-progressbar="" x-value="file.progress()" x-max="1">{{file.size * file.progress() |
                fileSize}}/{{file.size | fileSize}}
            </div>
        </div>
    </div>

    <div class="file-drop-preview" style="text-align: center;" x-ng-if="ui.isFileUploaded()">
        <span class="fa-stack fa-5x">
            <i class="fa fa-square fa-stack-2x"></i>
            <i class="fa fa-download fa-stack-1x fa-inverse"></i>
        </span>
    </div>

    <div class="col-sm-12 ">
        <button type="button" uib-popover="{{'config.update.update.help' | i18n}}" popover-trigger="mouseenter"
                popover-placement="right" class="btn btn-primary pull-right">?
        </button>
    </div>
</div>


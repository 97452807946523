var module = angular.module('meternet',[
    'ui.router',
    'ui.bootstrap' ,
    'ngAnimate',
    'angular-underscore',
    'checklist-model',
    'angular-loading-bar',
    'FBAngular',
    'indexedDB',
    'LocalStorageModule',
    'dcbImgFallback',
    'isteven-multi-select',
    'flow',
    'i18n',
    'leaflet-directive',
    'ui.calendar',
    'ui.select',
    'ngSanitize',
    'meternet.templates',
    'meternet.inline-js',
    'meternet.menu',
    'meternet.footer',
    'meternet.services',
    'meternet.directives',
    'meternet.filters',
    'meternet.utils.numFormat',
    'meternet.utils.custom-time-picker',
    'meternet.report.services'
]);

module.config(function($provide, contextPath) {
//             $provide.decorator('ngModelDirective', ['$delegate', function($delegate) {
//                   //$delegate is array of all ng-src directive
//                   //in this case first one is angular buildin ng-src
//                   //so we remove it.
//                   $delegate.shift();
//                   return $delegate;
//             }]);
});

// https://github.com/tombatossals/angular-leaflet-directive/issues/1026
module.config(function($logProvider){
    $logProvider.debugEnabled(false);
});

module.config(function(cfpLoadingBarProvider) {
//    cfpLoadingBarProvider.includeSpinner = true;
//    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.spinnerTemplate = '<div id="loading-backgroud"></div><div id="loading-modal"><div id="loading-border"><i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i></div></div>';
    cfpLoadingBarProvider.includeBar = true;
    cfpLoadingBarProvider.latencyThreshold = 500;
});


module.config(['$compileProvider', function ($compileProvider) {
	  $compileProvider.debugInfoEnabled(true);
}]);

module.config(function($httpProvider,contextPath) {
    var interceptor = ['$q', function($q) {
        function success(response) {
            return response;
        }
        function error(response) {
            if (console){
                console.log(response.status);
            }
            if (response.status === 401) {
                var config = response.config;
                var url = config.url;
                if(url === contextPath + '/j_spring_security_check'){
                    return $q.reject(response);
                }
                window.location = "./login";
                return;
            }
            if (response.status  === 403){
                $q.reject(response);
            }
            return $q.reject(response);
        }

        return function(promise) {
            return promise.then(success, error);
        };
    }];
    $httpProvider.interceptors.push(interceptor);
});

//module.config(['$provide', function($provide) {
////     var DEFAULT_TIMEZONE = 'EST';
//
//     $provide.decorator('dateFilter', ['$delegate', '$injector', function($delegate, $injector) {
//     var DEFAULT_TIMEZONE = '"+02:00"';
//       var oldDelegate = $delegate;
//
//console.log("testowanie");
//       var standardDateFilterInterceptor = function(date, format, timezone) {
//         if(angular.isUndefined(timezone)) {
//           timezone = DEFAULT_TIMEZONE;
//         }
//         return oldDelegate.apply(this, [date, format, timezone]);
//       };
//
//       return standardDateFilterInterceptor;
//     }]);
//}]);


module.config(function ($stateProvider, $urlRouterProvider, $locationProvider, contextPath, Locale) {

        $locationProvider.html5Mode({enabled: true, requireBase: false});

        $urlRouterProvider
                .otherwise('/');
        $stateProvider
            .state('login', {
                url: '/login',
                views: {
                    '@': {
                        templateUrl: 'login.html'
                    }
                }
            })
            .state('main', {
                abstract: true,
                views: {
                    'menu':{
                        template: '<div x-menu=""></div>',
                    }
                }
            })
             .state('footer', {
                abstract: true,
                views: {
                    'footer':{
                        template: '<div x-footer=""></div>',
                    }
                }
            }).state('main.home',{
                url: '/',
                views: {
                    '@': {
                        templateUrl: 'status/status-content.html'
                    }
                },
                data: {
                    isStatusAllowed: true
                }
            }).state('main.readout',{
                url: '/readout',
                views: {
                    '@': {
                        templateUrl: 'report/table/view.html'
                    }
                },
                data: {
                    isReadoutAllowed: true
                }
            }).state('main.users',{
                url: '/users',
                views: {
                    '@': {
                        templateUrl: 'user/users-table.html',
                        controller: 'usersTableCtrl'
                    }
                }
            }).state('main.dashboard',{
                url: '/dashboard/:dashboardName',
                views: {
                    '@': {
                        templateUrl: 'dashboard/dashboard-panel.html',
                        controller: 'dashboardCtrl'
                    }
                }
            }).state('main.reportChart',{
                url: '/report/chart',
                views: {
                    '@': {
                        templateUrl: 'report/chart/view.html',
                        controller: 'reportChartCtrl'
                    }
                },
                data: {
                    isReportAllowed: true
                }
            }).state('main.reportHistory',{
                url: '/report/history',
                views: {
                    '@': {
                        templateUrl: 'report/history/view.html'
                    }
                },
                data: {
                    isReportAllowed: true
                }
            }).state('main.reportEnergy',{
                url: '/report/:reportEnergyId',
                views: {
                    '@': {
                        templateUrl: 'report/energy/view.html',
                        controller: 'reportEnergyCtrl'
                    }
                },
                data: {
                    isReportAllowed: true
                }
            }).state('main.fileManager',{
                url: '/fileManager',
                views: {
                    '@': {
                        templateUrl: 'report/file-manager.html'
                    }
                },
                data: {
                    isfileManagerAllowed: true
                }
            }).state('main.config', {
            	abstract : false,
                url: '/config',
                views: {
                    '@': {
                        templateUrl: 'config/config-content.html'
                    }
                },
                data: {
                	isAdmin: true
                }
            })/*.state('main.config.engine', {
                url: '/engine',
                data: {
                	accordionGroup : 'engine'
                }
            }).state('main.config.maths', {
                url: '/maths',
                data: {
                	accordionGroup : 'maths'
                }
            }).state('main.config.outputs', {
                url: '/outputs',
                data: {
                    accordionGroup: 'outputs'
                }
            }).state('main.config.alerts', {
                url: '/alerts',
                data:{
                    accordionGroup: 'alerts'
                }
            }).state('main.config.license',{
                url: '/license',
                data: {
                	editedOptions : 'license'
                }
            }).state('main.config.settings',{
                url: '/settings',
                data: {
                	editedOptions : 'settings'
                }
            }).state('main.config.update',{
                url: '/update',
                data: {
                	editedOptions : 'update'
                }
            })*/.state('main.log',{
            	url: '/log',
                views: {
                    '@': {
                        templateUrl: 'log/log.html'
                    }
                }
            }).state('main.about',{
                url: '/about',
                views: {
                    '@': {
                        templateUrl: 'about/about.html'
                    }
                }
            }).state('main.measurements',{
                url: '/measurements',
                views: {
                    '@': {
                        templateUrl: 'measurements/measurementsContent.html'
                    }
                }
            }).state('main.timetable', {
               url: '/timetable',
                views: {
                    '@': {
                        templateUrl: 'timetable/timetable.html',
                        controller: 'timetableCtrl'
                    }
                }
            }). state('main.addTimetable', {
                url:'/addTimetable',
                views: {
                    '@': {
                        templateUrl:'timetable/addTimetable-view.html',
                        controller: 'addTimetableCtrl'
                    }
                }
            }).state('main.editTimetable', {
                url: '/editTimetable/:id',
                views: {
                    '@': {
                        templateUrl: 'timetable/addTimetable-view.html',
                        controller: 'addTimetableCtrl'
                    }
                }
            }).state('main.parameterScoresList', {
                url: '/parameterScoresList',
                views: {
                    '@': {
                        templateUrl: 'scores/parameterScores-view.html'
                    }
                }
            }).state('main.addParameterScore', {
                url: '/addParameterScore',
                views: {
                    '@': {
                        templateUrl: 'scores/addParameterScore.html',
                        controller: 'addParameterScoreCtrl'
                    }
                }
            }).state('main.userScoresReport', {
                url: '/userScoresReport',
                views: {
                    '@': {
                        templateUrl: 'scores/userScoresReport-view.html'
                    }
                }
            });
        moment.locale('pl', {
            months : "styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień".split("_"),
            monthsShort : "sty_lut_mar_kwi_maj_cze_lip_sie_wrz_paź_lis_gru".split("_"),
            weekdays : "Niedziela_Poniedziałek_Wtorek_Środa_Czwartek_Piątek_Sobota".split("_"),
            weekdaysShort : "pon._wto._śro._czw._pią._sob._nie.".split("_"),
            weekdaysMin : "N_Pn_Wt_Śr_Cz_Pi_So".split("_"),
            longDateFormat : {
                LT : "HH:mm",
                LTS : "HH:mm:ss",
                L : "DD/MM/YYYY",
                LL : "D MMMM YYYY",
                LLL : "D MMMM YYYY LT",
                LLLL : "dddd D MMMM YYYY LT"
            },
//            calendar : {
//                sameDay: "[Aujourd'hui à] LT",
//                nextDay: '[Demain à] LT',
//                nextWeek: 'dddd [à] LT',
//                lastDay: '[Hier à] LT',
//                lastWeek: 'dddd [dernier à] LT',
//                sameElse: 'L'
//            },
            relativeTime : {
                future : "za %s",
                past : "%s temu",
                s : "kilka sekund",
                m : "une minute",
                mm : "%d minut",
                h : "godzina",
                hh : "%d godzin",
                d : "dzień",
                dd : "%d dni",
                M : "miesiąc",
                MM : "%d miesięcy",
                y : "rok",
                yy : "%d lata"
            },
            ordinal : function (number) {
                return number + (number === 1 ? 'er' : 'ème');
            },
            week : {
                dow : 1, // Monday is the first day of the week.
                doy : 4  // The week that contains Jan 4th is the first week of the year.
            }
        });

//        console.log(new Date().getTimezoneOffset())
//        moment.locale(Locale);
//        moment.tz.setDefault("America/New_York");
//        console.log(moment);
//        console.log(moment.tz)
//        console.log(new Date().getTimezoneOffset())
    });

module.run(function($rootScope, $state, $stateParams, userService) {

    var user = userService.getUser();
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){

        if (toState.data && toState.data.isAdmin && !user.isAdmin()){
            event.preventDefault();
            $state.go('main.home');
        }
        var isReportAllowed = _.find(user.permissions.otherDenyRead, function(link){ return link ==="report" })?false: true;
        if (toState.data && toState.data.isReportAllowed && !isReportAllowed){
            event.preventDefault();
            $state.go('main.home');
        }
        var isfileManagerAllowed = _.find(user.permissions.otherDenyRead, function(link){ return link ==="fileManager" })?false: true;
        if (toState.data && toState.data.isfileManagerAllowed && !isfileManagerAllowed){
            event.preventDefault();
            $state.go('main.home');
        }
        var isReadoutAllowed = _.find(user.permissions.otherDenyRead, function(link){ return link ==="readout" })?false: true;
        if (toState.data && toState.data.isReadoutAllowed && !isReadoutAllowed){
            event.preventDefault();
            $state.go('main.home');
        }
        var isStatusAllowed = _.find(user.permissions.otherDenyRead, function(link){ return link =="status" })?false: true;
        if (toState.data && toState.data.isStatusAllowed && !isStatusAllowed){
            event.preventDefault();
            $state.go('main.users');
        }


    });

    $rootScope.$on('$stateChangeError',
            function(event, toState, toParams, fromState, fromParams, error){

    });
    $rootScope.$on('$stateNotFound',
            function(event, toState, toParams, fromState, fromParams, error){
        event.preventDefault();
        $state.go('main.home');
    });
});

module.value('advanceView', {   value: false,
                                toggle: function(){
                                    this.value = !this.value;
                                }
});

module.factory('SunCalc', function() {
    return window.SunCalc;
});

var module = angular.module('meternet.report.directives.TableLineChartWidgetDirective', [
                                                                                        'meternet.services',
                                                                                        'meternet.config.controllers',
                                                                                        'meternet.filters',
                                                                                        'meternet.dashboard.constants',
                                                                                        'meternet.dashboard.directives' ]);

module.directive('tableLineChartWidget', function () {
    return {
        scope: {
            row: "=",
            historyTime: "="
        },
        templateUrl: 'report/table/table-line-chart-widget.html',
        controller: function ($scope, $filter, contextPath, dataService, configService, seriesService,
                                     DashboardEvents, $q, linechartModes, $timeout, licenseService) {
             var license = licenseService.getLicense();
             $scope.config = {
                 series: [{
                     paramId: $scope.row.param.id,
                     deviceId: $scope.row.device.id,
                     name: ($scope.row.device.label || $scope.row.device.name)
                         + ($scope.row.group ? ":" + ($scope.row.group.label || $scope.row.group.name) : "")
                         + ":" + ($scope.row.param.label || $scope.row.param.name),
                     quantity: $scope.row.param.quantity,
                     unit: $scope.row.param.unit,
                     derivative: 0.0,
                     average: 1,
                     lineColor: "#865BB7",
                     lineWidth: 1,
                     markerColor: "#865BB7",
                     markerSize: 1,
                     axisIndex: null,
                     axis: {
                         scale: $scope.row.param.scale,
                         precision: $scope.row.param.precision,
                         rangeAuto: true,
                         rangeMin: null,
                         rangeMax: null,
                         labelColor: "#865BB7",
                         axisColor: "#865BB7",
                         grid: 1,
                         mainGridColor: "#c0c0c0",
                         subGridColor: "#d3d3d3"
                     },
                     markName: [],
                     mark: [],
                     markStyle: [],
                     maxOn: null,
                     minOn: null,
                     avgOn: null
                 }],
                 timeTo: "2019-02-28T07:49:00.000Z",
                 timeFrom: "2019-02-28T04:49:00.000Z",
                 historyTime: $scope.historyTime,
                 defaultScaleSize: 0,
                 timeUnit: 60,
                 grid: 0,
                 mainGridColor: "#c0c0c0",
                 subGridColor: "#d3d3d3",
                 showOnlyTime: true,
                 enableZoom: false,
                 enableScroll: false,
                 mode: "current",
                 gridSerieIndex: null
             };

             $scope.config.maxHistoryTime = license.properties['MAX_HISTORY_TIME'];
             $scope.data = [];
             $scope.dataRaw = [];

             var historyLength = ($scope.config.timeUnit || 60) * ($scope.config.historyTime || 24) * 60 * 1000;

             var currentTimeTo = function() {
                 var timeTo = new Date();
                 timeTo.setMilliseconds(0);
                 timeTo.setSeconds(0);
                 return timeTo;
             };

             var currentTimeFrom = function() {
                 return new Date(currentTimeTo().getTime() - historyLength);
             };

             if ($scope.config.timeFrom) {
                 $scope.config.timeFrom = new Date($scope.config.timeFrom);
             }
             if ($scope.config.timeTo) {
                 $scope.config.timeTo = new Date($scope.config.timeTo);
             }

             var setScopeTimeSpan = function(timeFrom, timeTo) {
                 var tt, tf;
                 if (timeFrom && timeTo) {
                     if ($scope.timeFrom <= timeFrom && $scope.timeTo >= timeTo) {
                         return false;
                     }
                     var diff = timeTo.getTime() - timeFrom.getTime();
                     tf = new Date(timeFrom.getTime() - 0.25 * diff);
                     tt = new Date(timeTo.getTime() + 0.25 * diff);
                     var now = new Date();
                     $scope.timeFrom = (!$scope.timeFrom) || $scope.timeFrom > tf ? tf : $scope.timeFrom;
                     if ($scope.timeFrom > now) {
                         $scope.timeFrom = now;
                     }
                     $scope.timeTo = (!$scope.timeTo) || ($scope.timeTo < tt) ? tt : $scope.timeTo;
                     if ($scope.timeTo > now) {
                         $scope.timeTo = now;
                     }
                     return true;
                 }
                 return false;
             };
             if ($scope.config.mode === 'historical') {
                 setScopeTimeSpan($scope.config.timeFrom, $scope.config.timeTo);
                 $scope.subscribe = false;
             } else {
                 $scope.timeTo = currentTimeTo();
                 $scope.timeFrom = currentTimeFrom();
                 $scope.subscribe = true;
                 $scope.config.timeTo = $scope.timeTo;
                 $scope.config.timeFrom = $scope.timeFrom;
             }

             var broadcastRedraw = _.throttle(function() {
                 $scope.$broadcast(DashboardEvents.REDRAW, $scope.data);
             }, 1200);

             var updateDataFactory = function(index) {
                 var updateData = function(data, concat) {
                     if (index < $scope.config.series.length) {

                         if (typeof (data) !== 'undefined') {
                             if (concat && data.length > 0) {
                                 var i;
                                 for (i = $scope.dataRaw[index].length - 1; i >= 0; i--) {
                                     if ($scope.dataRaw[index][i].timestamp < data[0].timestamp) {
                                         break;
                                     }
                                 }
                                 Array.prototype.splice.apply($scope.dataRaw[index], [i+1, 0].concat(data));
                             } else {
                                 $scope.dataRaw[index] = data;
                             }
                         }
                         if (typeof ($scope.dataRaw[index]) !== 'undefined') {
                             $scope.data[index] = seriesService
                                     .processData($scope.dataRaw[index], $scope.timeFrom, $scope.timeTo || currentTimeTo(),
                                         $scope.config.series[index].derivative, $scope.config.series[index].average);
                         }
                         broadcastRedraw();
                     }
                 };
                 return _.throttle(updateData, 500);
             };

             $scope.unsubscribeFns = {};
             $scope.$on('$destroy', function() {
                 _.each($scope.unsubscribeFns, function(fn) {
                    fn();
                 });
             });

             $scope.promises = [];
             var initData = function() {
                 $scope.$broadcast(DashboardEvents.LOADING_DATA, true);
                 _.each($scope.promises, function(p) {
                     p.cancel();
                 });
                 $scope.promises = [];

                 _.each($scope.config.series, function(series, index) {
                     var request = {};
                     $scope.dataRaw[index] = [];
                     // $scope.data[index] = [];
                     request.deviceId = series.deviceId;
                     request.paramId = series.paramId;
                     request.timeTo = $scope.timeTo || currentTimeTo();
                     request.timeFrom = $scope.timeFrom || currentTimeFrom();

                     if(series.paramId){
                         var promise = dataService.requestParamData(series.paramId, request);
                         promise.deviceId = series.deviceId;
                         promise.paramId = series.paramId;
                         var updateDataFn = updateDataFactory(index);
                         promise.then(function(measurements) {
                             updateDataFn(measurements, false);
                         }, angular.noop, function(measurements) {
                             updateDataFn(measurements, false);
                         });
                         $scope.promises.push(promise);
                     }
                 });
                 $scope.$on('$destroy', function() {
                     _.each($scope.promises, function(p) {
                         p.cancel();
                     });
                 });
                 $q.all($scope.promises).then(function(measurements) {
                     if (!$scope.subscribe) {
                         _.each($scope.unsubscribeFns, function(fn) {
                             fn();
                         });
                         $scope.unsubscribeFns = {};

                     } else {
                         _.forEach($scope.promises, function(p, i) {
                             if ($scope.subscribe && typeof ($scope.unsubscribeFns[p.paramId]) === 'undefined') {
                                 var unsubscribeFn = dataService.subscribeForParametersMeasurements(p.paramId, function(moment) {
                                    var data = [];
                                    data.push(moment.current);
                                    updateDataFactory(i)(data, true);
                                 });
                                 $scope.unsubscribeFns[p.paramId] = unsubscribeFn;
                             }

                         });
                     }
                     $timeout(function() {
                         $scope.$broadcast(DashboardEvents.LOADING_DATA, false);
                     }, 0);
                 });
             };
             initData();
             $scope.$broadcast(DashboardEvents.RESIZE, $scope.data);
             $scope.$on(DashboardEvents.SET_DATA_TIMESPAN, function($event, timeFrom, timeTo) {
                 if ($scope.config.mode !== 'current') {
                     $scope.subscribe = false;
                 }
                 if (setScopeTimeSpan(timeFrom, timeTo)) {
                     initData();
                 }
             });

            $scope.$watch('$scope.param', function() {
                initData();
            });
         }
    };
});

/*global tools */
var app = angular.module('meternet.fileManager.services', []);

function FileManagerService($q, $http, $log, contextPath) {

		function CsvFile(data){
			this.update(data);
		}
		CsvFile.prototype.update = function(data){
			tools.copy(data, this);
		};

		return {

			get : function() {
				var deferred = $q.defer();
				var url = contextPath +'/data/files.json';
				$http({
					method : 'GET',
					url : url
				}).success(
						function(data, status, headers) {
							for (var d in data){
								data[d]	= new CsvFile(data[d]);
							}
							deferred.resolve(data);
				}).error(function(){
                	deferred.reject();
                });
				return deferred.promise;
			},
			delFile : function(file) {
				var deferred = $q.defer();
				var url = contextPath +'/delete/' + file;
				$http({
					method : 'GET',
					url : url
				}).success(
						function(data, status, headers) {
							deferred.resolve(data);
				}).error(function(){
                	deferred.reject();
                });
				return deferred.promise;
			},
		};
}
app.service('fileManagerService', FileManagerService);
<div class="row">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <div class="panel-body">
            <form class="form form-horizontal">
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.prepaid"></label>
                    <select class="form-control" x-ng-model="ui.report" name="widgetsConfigReport" x-ng-options="report as report.label for report in reports"
                            x-ng-change="ui.updateReport()" x-required="">
                    </select>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-xs-12">
        <div class="alert alert-success" x-ng-if="uj.data.errSpace">
            <h1>
                <i class="fa fa-plug" aria-hidden="true"></i>
                {{'config.storage.ok' | i18n}}
            </h1>
        </div>
        <div class="alert alert-danger" x-ng-if="!uj.data.errSpace">
            <h1>
                <i class="fa fa-plug" aria-hidden="true"></i>
                {{'config.storage.error' | i18n}}
            </h1>
        </div>
		<div class="alert alert-default" x-ng-if="uj.commandProgress">
			<h1>
				<i class="fa fa-hourglass" aria-hidden="true"></i>
				{{'config.storage.commandProgress' | i18n}}
				<div class="progress">
					<div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style="width: {{uj.commandProgress}}%;">
						<span class="sr-only">60% Complete</span>
					</div>
				</div>
			</h1>
		</div>
		<div class="alert alert-danger" x-ng-if="uj.commandFailedMsg">
			<h1>
				<i class="fa fa-plug" aria-hidden="true"></i>
				{{uj.commandFailedMsg | i18n}}
			</h1>
		</div>
    </div>
    <div class="col-xs-12" style="margin-bottom: 15px;">
        <uib-progressbar class="progress-bar-margin0" max="100" value="uj.data.procentSpace">
            <span>{{uj.data.usableSpace | number:0}}MB / {{uj.data.totalSpace | number:0}}MB</span>
        </uib-progressbar>
    </div>
    <div class="col-xs-6 col-xs-offset-3" style="margin-bottom: 15px;">
        <button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#modalFix" style="width:100%"
                x-ng-disabled="!uj.data.isRaspberry">
            <i class="fa fa-heartbeat" aria-hidden="true"></i>&nbsp{{'config.storage.fix' | i18n}}
        </button>
    </div>
    <div class="col-xs-6 col-xs-offset-3" style="margin-bottom: 15px;">
        <button type="button" class="btn btn-warning btn-lg" data-toggle="modal" data-target="#modalFormat"
                style="width:100%" x-ng-disabled="!uj.data.isRaspberry">
            <i class="fa fa-recycle" aria-hidden="true"></i>&nbsp{{'config.storage.format' | i18n}}
        </button>
    </div>
    <div class="col-xs-6 col-xs-offset-3" style="margin-bottom: 15px;">
        <button type="button" class="btn btn-warning btn-lg" data-toggle="modal" data-target="#modalSetDB"
                style="width:100%" x-ng-disabled="!uj.data.isRaspberry">
            <i class="fa fa-recycle" aria-hidden="true"></i>&nbsp{{'config.storage.setdb' | i18n}}
        </button>
    </div>
    <div class="col-xs-6 col-xs-offset-3" style="margin-bottom: 15px;">
        <button type="button" class="btn btn-danger btn-lg" data-toggle="modal" data-target="#modalReboot"
                style="width:100%" x-ng-disabled="!uj.data.isRaspberry">
            <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp{{'config.storage.reboot' | i18n}}
        </button>
    </div>
    <div class="col-xs-6 col-xs-offset-3" style="margin-bottom: 15px;">
        <button type="button" class="btn btn-danger btn-lg" data-toggle="modal" data-target="#modalPoweroff"
                style="width:100%" x-ng-disabled="!uj.data.isRaspberry">
            <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp{{'config.storage.poweroff' | i18n}}
        </button>
    </div>


    <div id="modalFix" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header bg-info" style="border-radius: 4px 4px 0px 0px;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-heartbeat" aria-hidden="true"></i>&nbsp{{'config.storage.fix' | i18n}}
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{'config.storage.fix.msg' | i18n}}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-large btn-info" type="button" x-ng-click="uj.fix()"
                            x-ng-disabled="!uj.data.isRaspberry">
                        <i class="fa fa-heartbeat" aria-hidden="true"></i>&nbsp{{'config.storage.fix' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="modalFormat" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header bg-warning" style="border-radius: 4px 4px 0px 0px;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-recycle" aria-hidden="true"></i>&nbsp{{'config.storage.format' | i18n}}
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{'config.storage.format.msg' | i18n}}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-large btn-warning" type="button" x-ng-click="uj.format()"
                            x-ng-disabled="!uj.data.isRaspberry">
                        <i class="fa fa-recycle" aria-hidden="true"></i>&nbsp{{'config.storage.format' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="modalSetDB" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header bg-warning" style="border-radius: 4px 4px 0px 0px;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-recycle" aria-hidden="true"></i>&nbsp{{'config.storage.setdb' | i18n}}
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{'config.storage.setdb.msg' | i18n | htmlSafe}}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-large btn-warning" type="button" x-ng-click="uj.setdb()"
                            x-ng-disabled="!uj.data.isRaspberry">
                        <i class="fa fa-recycle" aria-hidden="true"></i>&nbsp{{'config.storage.setdb' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="modalReboot" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header bg-danger" style="border-radius: 4px 4px 0px 0px;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp{{'config.storage.reboot' | i18n}}
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{'config.storage.reboot.msg' | i18n}}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-large btn-danger" type="button" x-ng-click="uj.reboot()"
                            x-ng-disabled="!uj.data.isRaspberry">
                        <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp{{'config.storage.reboot' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div id="modalPoweroff" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header bg-danger" style="border-radius: 4px 4px 0px 0px;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp{{'config.storage.poweroff' | i18n}}
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{'config.storage.poweroff.msg' | i18n}}</p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-large btn-danger" type="button" x-ng-click="uj.poweroff()"
                            x-ng-disabled="!uj.data.isRaspberry">
                        <i class="fa fa-power-off" aria-hidden="true"></i>&nbsp{{'config.storage.poweroff' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>

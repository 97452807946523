<div adf-id="{{column.cid}}" class="column full-height" x-ng-class="'col-md-' + column.width" style="display: flex;
    flex-direction: column; justify-content: space-between;">
    <div x-ng-repeat="definition in column.widgets | filter : definition.config.hide"
         x-adf-widget=""
         x-definition="definition"
         x-column="column"
         x-edit-mode="editMode"
         x-collapsible="collapsible"
         x-options="{}"
         x-widget-state="{}"
         x-ng-attr-style="{{ !definition.withoutCountingHeight ? 'height:' + 100 / column.widgets.length + '%' : '' }}">
    </div>
</div>

var module = angular.module('meternet.timetable.services',[]);

module.service("timetableService", function($http, $q, configService) {

    var config = {};
    configService.get().then(function(config2) {
        config = config2;
    });

    function Timetable(obj) {
            this.dateFrom = null;
            this.dateTo = null;
            this.parameters = [];
            this.frames = [];
            this.update(obj);
            this.parameterNames = this.getParameterNames();
        this.weekFrom = this.getWeekFrom();
        this.weekTo = this.getWeekTo();
        this.year = this.getYear();
    }

    Timetable.prototype.update = function(obj) {
        tools.copy(obj, this);
        for (var i = 0; i < this.frames.length; ++i) {
            this.frames[i] = new Frame(this.frames[i]);
        }
    };

    Timetable.prototype.getWeekFrom = function(){
        return moment(this.dateFrom).week();
    };

    Timetable.prototype.getWeekTo = function() {
        return moment(this.dateTo).week();
    };

    Timetable.prototype.getYear = function() {
        return moment(this.dateFrom).year();
    };

    Timetable.prototype.getParameters = function() {
        return this.parameters.toString();
    };

    Timetable.prototype.addFrame = function(frame) {
        this.frames.push(frame);

    };

    Timetable.prototype.getParameterNames = function() {
        var result = [];
        var inputs = config.engine.measurementInputs;
        for (var i = 0; i < this.parameters.length; ++i) {
            for (var j = 0; j < inputs.length; ++j) {
                var devices = inputs[j].devices;
                for (var k = 0; k < devices.length; ++k) {
                    var params = devices[k].params;
                    for(var l = 0; l < params.length; l++) {
                        if (this.parameters[i] === params[l].id) {
                            result.push(params[l].name);
                        }
                    }
                }
            }
        }
        return result.toString();
    };

    Timetable.prototype.calculateRange = function() {
      if (this.frames) {
          var startDate;
          var endDate;
          this.frames.forEach(function(frame) {
              frame.dateFrom = moment(frame.dateFrom);
              frame.dateTo = moment(frame.dateTo);
              if (!startDate || frame.dateFrom.isBefore(startDate)) {
                  startDate = frame.dateFrom;
              }

              if (!endDate || frame.dateTo.isAfter(endDate)) {
                  endDate = frame.dateTo;
              }
          });
          this.dateFrom = startDate;
          this.dateTo = endDate;
      }
    };


    function Frame(obj) {
            this.id = null;
            this.userId = null;
            this.dateFrom = null;
            this.dateTo = null;
            this.update(obj);
        if (this.dateFrom && this.dateTo) {
            this.dateFrom = moment(this.dateFrom);
            this.dateTo = moment(this.dateTo);
        }
    }

    Frame.prototype.update = function(obj){
        tools.copy(obj, this);
    };

    return {
        getTimetableObject: function(){
            return new Timetable();
        },
        getFrameObject: function(obj) {
            return new Frame(obj);
        },
        get: function(){
           var deferred = $q.defer();
            $http.get('/timetables').then(function(result){
                result.data.forEach(function(value, index, array) {
                    array[index] = new Timetable(value);
                });
                deferred.resolve(result.data);
            });
            return deferred.promise;
        },
        getById: function(id) {
          var deferred = $q.defer();
            $http({
                url: '/timetable',
                method: 'GET',
                params: {
                    id: id
                }
            }).then(function(result) {
                deferred.resolve(new Timetable(result.data));
            });
            return deferred.promise
        },
        check: function(timetable) {
            var deferred = $q.defer();
            $http.post('/timetables/check', timetable).then(function(result) {
                deferred.resolve(result);
            }, function(result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        },
        save: function(timetable) {
            var deferred = $q.defer();
            timetable.calculateRange();
            $http.post('timetables/add', timetable).then(function(result) {
                deferred.resolve(result);
            }, function(result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        },
        update: function(timetable) {
            var deferred = $q.defer();
            timetable.calculateRange();
            $http.post('update', timetable).then(function(result) {
                deferred.resolve(result);
            }, function(result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        },
        remove: function(timetable) {
            var deferred = $q.defer();

            $http.post('/timetables/remove', timetable).then(function(result) {
                deferred.resolve(result.data);
            });
            return deferred.promise;
        }

    }
});
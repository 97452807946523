/* globals _,moment,pdfMake */
var module = angular.module('meternet.dashboard.controllers.mediaReportWidget', []);

module.config(function(dashboardProvider, contextPath, messages) {
	var widget = {
		templateUrl: 'dashboard/media/media-report-widget.html',
		title: messages['dashboard.widgets.media.title'],
		description: messages['dashboard.widgets.media.desc'],
		controller: 'mediaWidgetCtrl',
		screenshot: false,
		csv: false,
		xlsx: true,
		pdf: true,
		config: {
			series: []
		},
		edit: {
			templateUrl: 'dashboard/media/media-report-edit.html',
			controller: 'mediaWidgetEditCtrl',
		}
	};
	dashboardProvider.widget('media-report', widget);
});

module.controller('mediaWidgetEditCtrl', function ($scope, $filter, contextPath, dataService, config, configService,
												   UnitScales) {
	$scope.ui = {
		addSeries: function () {
			if (this.canAddSeries()) {
				var series = {
					paramId: null,
					sync: true,
				};
				$scope.config.series.push(series);
			}
		},
		removeSerie: function (index) {
			$scope.config.series.splice(index, 1);
		},
		canAddSeries: function () {
			return true;
		},
		updateSeriesParam: function (series) {
			if (series.deviceId !== series._device.id) {
				series._param = series._device.params[0];
			}
			if (series.paramId !== series._param.id) {
				series.cost = null;
				series.currency = null;
				series.limit = null;
                series.name = null;
				series.sync = true;
			}
			if (series._device && series._param) {
				series.deviceId = series._device.id;
				series.paramId = series._param.id;
				series.scale = series._param.scale || 0;
				series.precision = series._param.precision || 0;

                if (!series.name) {
                    var deviceName = (series._device.label || series._device.name);
                    var paramName = (series._param.label || series._param.name);
                    series.name = deviceName + '.' + paramName;
                }

				series.unit = $filter('quantityUnit')(series._param.quantity);

				this.updateSeriesMediaCost(series);
			}
		},
		updateSeriesMediaCost: function (series) {
			if (series.sync) {
                if ($scope.mediaCosts) {
                    for (var i = 0; i < $scope.mediaCosts.length; ++i) {
                        var mc = $scope.mediaCosts[i];
                        var mtags = _.map(mc.tag ? mc.tag.split(/\s+|\s*[,;]\s*/) : [], function (tag) {
                            return tag.trim().toLowerCase();
                        });
                        var ptags = _.map(series._param.tags ? series._param.tags.split(/\s+|\s*[,;]\s*/) : [], function (tag) {
                            return tag.trim().toLowerCase();
                        });
                        if (_.intersection(mtags, ptags).length > 0) {
                            series.cost = mc.cost;
                            series.currency = mc.currency;
                            if (series._param.scale !== mc.scale) {
                                series.cost *= Math.pow(10, mc.scale - series._param.scale);
                            }
                            break;
                        }
                    }
                }
			} else if (typeof series.cost === "string") {
                series.cost = series.cost.replaceAll(',', '.');
            }
		},
		unitCost: function (series) {
			var u = '';
			if (series.currency) {
				u += series.currency;
			}
			if (series.unit) {
				u += '/';
				if (series.scale) {
					var s = _.find(UnitScales, function (s) { return s.value === series.scale; });
					u += s ? s.label : '';
				}
				u += series.unit;
			}
			return u;
		},
		unitLimit: function (series) {
			var u = '';
			if (series.scale) {
				var s = _.find(UnitScales, function (s) { return s.value === series.scale; });
				u += s ? s.label : '';
			}
			if (series.unit) {
				u += series.unit;
			}
			return u;
		}
	};

	configService.get().then(function (meternetConfig) {
		var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
		devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
		$scope.devices = _.without(devices, _.findWhere(devices, {model: "energy_report"}));
		$scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model: "prepaid"}));
		$scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model: "alarm"}));
		$scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model: "control"}));

		$scope.devices = _.sortBy($scope.devices, function (d) {
			return d.label || d.name;
		});

		$scope.mediaCosts = meternetConfig.engine.mediaCosts.costs;

		var checkParam = function (param) {
			return this.paramId === param.id;
		};

		var checkDevice = function (device) {
			return this.deviceId === device.id;
		};

		for (var i = 0; i < $scope.config.series.length; ++i) {
			var series = $scope.config.series[i];
			series._device = _.find($scope.devices, checkDevice, series);
			if (series._device) {
				series._param = _.find(series._device.params, checkParam, series);
			}
			$scope.ui.updateSeriesParam(series);
		}
	});
});

module.controller('mediaWidgetCtrl', function ($scope, $filter, $http,
											   contextPath, i18nFilter, dateFilter, unitFilter,
											   dataService, config) {
	$scope.ui = {
		loading: false,
		loadReport: function () {
			this.loading = true;
			var req = {
				dateFrom: $scope.dateFrom,
				dateTo: $scope.dateTo,
				series: _.map(config.series, function (s) {
					return {
						deviceId: s.deviceId,
						paramId: s.paramId,
						name: s.name,
						limit: s.limit,
						sync: s.sync,
						cost: s.cost,
						currency: s.currency,
					};
				})
			};

			dataService.getMediaReportData(req).then(function (report) {
				$scope.report = report;
				$scope.ui.loading = false;
			});
		}
	};

	$scope.report = {
		rows: _.map(config.series, function (s) {
			return {
				name: s.name,
			};
		})
	};

	$scope.dateTo = new Date();
	$scope.dateTo.setDate(0);
	$scope.dateTo.setHours(0, 0, 0);
	$scope.dateFrom = new Date($scope.dateTo);
	$scope.dateFrom.setDate(1);

	function exportPdf(report, config) {
		function fmtd(date) {
			return dateFilter(date, 'yyyy-MM-dd');
		}

		var table = [
			[
				{ text: i18nFilter('camping.table.name'), style: 'table-header' },
				{ text: i18nFilter('camping.table.dailyUsage'), style: 'table-header' },
				{ text: i18nFilter('camping.table.valueDiff'), style: 'table-header' },
				{ text: i18nFilter('camping.table.price'), style: 'table-header' },
				{ text: i18nFilter('camping.table.cost'), style: 'table-header' },
			],
		];
		for (var r = 0; r < report.rows.length; ++r) {
			var s = report.rows[r];
			table.push([
				{ text: s.name },
				{ text: unitFilter(s.dailyUsage, s.precision, s.unit, s.scale), style: s.limit != null && s.dailyUsage > s.limit ? 'danger' : null, alignment: 'right' },
				{ text: unitFilter(s.usage, s.precision, s.unit, s.scale), alignment: 'right' },
				{ text: unitFilter(s.unitCost, 2, s.currency), alignment: 'right' },
				{ text: unitFilter(s.cost, 2, s.currency), alignment: 'right' },
			]);
		}
		table.push([
			{ text: '', colSpan: 4 },
			{ text: '' },
			{ text: '' },
			{ text: '' },
			{ text: unitFilter(report.totalCost, 2, report.currency), style: 'total', alignment: 'right' },
		]);

		var doc = {
			content: [
				{ text: fmtd(new Date()), alignment: 'right' },
				{ text: config.header || "", style: 'header' },
				' ',
				' ',
				{ text: config.title || "", style: 'title' },
				{ text: fmtd(report.dateFrom) + ' - ' + fmtd(report.dateTo), style: 'subtitle' },
				{
					style: 'table',
					table: {
						headerRows: 1,
						// keepWithHeaderRows: 1,
						// dontBreakRows: true,
						widths: ['40%', '*', '*', '*', '*'],
						body: table,
					}
				},
				/*' ',
				' ',
				' ',
				' ',
				' ',
				' ',
				{
					columns: [
						{
							stack: [
								'podpis wystawiającego',
								' ',
								' ',
								'...........................',
							],
							fontSize: 15,
							alignment: 'center'
						},
						{
							stack: [
								'podpis klienta',
								' ',
								' ',
								'...........................',
							],
							fontSize: 15,
							alignment: 'center'
						}
					]
				}*/
			],
			styles: {
				'header': {
					fontSize: 10
				},
				'title': {
					fontSize: 14,
					bold: true,
					margin: [0, 0, 0, 10],
					alignment: 'center'
				},
				'subtitle': {
					fontSize: 12,
					bold: true,
					margin: [0, 10, 0, 5],
					alignment: 'center'
				},
				'table': {
					margin: [0, 5, 0, 15]
				},
				'table-header': {
					bold: true,
					color: 'black',
					alignment: 'center'
				},
				'danger': {
					color: 'red'
				},
				'total': {
					bold: true
				}
			},
			defaultStyle: {
				fontSize: 10,
			}
		};
		pdfMake.createPdf(doc).download('media-report.pdf');
	}

	// $scope.$on('generateCsv', function () {
	// 	download('csv');
	// });
	$scope.$on('generateXlsx', function () {
		if ($scope.report) {
			$http.post(contextPath + '/media/report.xlsx', $scope.report, { responseType: 'arraybuffer' })
				.then(function(resp) {
					var data = resp.data;
					var blob = new Blob([data], { type: resp.headers()['content-type'] });
					var link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = /.*?filename=(.+?)$/.exec(resp.headers()['content-disposition'])[1];
					link.click();
				}, function(resp) {

				});
		}
	});
	$scope.$on('generatePdf', function () {
		if ($scope.report) {
			exportPdf($scope.report, $scope.config);
		}
	});
});

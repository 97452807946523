<div class="row mbsat-map">
    <div class="col-xs-12 mbsat-map" ng-if="menu==0 || !menu">
        <leaflet lf-center="mapZoom" markers="ui.markers" layers="layers" bounds="ui.bounds" events="events" style="flex: 1 1 auto;"></leaflet>
    </div>
    <div class="col-xs-12" ng-if="menu==1">
        <div current-values="" x-options="options" x-groups="checkedGroups"></div>
    </div>
    <div class="col-xs-12 device-calendar-container" ng-if="menu==2">
        <div device-calendar="" x-options="options" class="mbsat-map"></div>
    </div>
</div>
<div ng-if="menu==0 || menu==1 || !menu" class="row mbsat-table">
    <div class="col-xs-12">
        <div class="alert alert-danger" role="alert" x-ng-if="ui.errors.hasGlobalErrors('')">
            <div x-ng-repeat="e in ui.errors.getGlobalErrors('')" class="text-center">
                <h4>{{e.message | i18n:e.arguments}}</h4>
            </div>
        </div>
        <div class="alert clearfix text-center" x-ng-if="ui.message" x-ng-class="ui.message.css">
            <h4 style="margin: 0;">{{ui.message.text | i18n}}</h4>
        </div>
    </div>
    <div class="col-xs-12">
        <div lighting-settings="" x-menu-view="menu" x-markers-within-bounds="onlyMarkersWithinBounds ? markersWithinBounds : ui.markers" x-only-map-markers="onlyMarkersWithinBounds" x-config="ui.config"></div>
    </div>
</div>

var module = angular.module('meternet.license.directives', [
			'meternet.services']);

module.directive('widgetlimit', function(licenseService) {
    return {
        restrict : 'A',
        link : function($scope, $element) {
        },
        controller : function($scope) {
            $scope.license = licenseService.getLicense();
            $scope.widgetMax = $scope.license.maxWidgets;

            $scope.$on('meternet.license.update', function(event, license) {
                $scope.widgetMax = $scope.license.maxWidgets;
            });
        }
    };
});

module.filter('licensedWidgets', function($filter, WidgetTypes) {
    function camelToDash(str) {
        return str.replace(/\W+/g, '-').replace(/([a-z\d])([A-Z])/g, '$1-$2').toLowerCase();
    }

    return function(widgets) {
        var result = {};
        function check(t) {
            return t.type === key;
        }
        for (var key in widgets) {
            //var skey = camelToDash(key.slice(0, key.length - 6));
            var type = _.find(WidgetTypes, check);
            if (type) {
                result[key] = widgets[key];
                result[key].icon = type.iconUrl;
            }
        }
        return result;
    };
});

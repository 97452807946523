<form class="form-horizontal">
    <!--<label class="control-label">{{'config.name' | i18n}}</label>-->
    <!--<input class="form-control" x-ng-model="input.name" />-->

    <label class="control-label">{{'config.choose' | i18n}}</label>
    <div class="row">
        <div class="col-sm-3" x-ng-repeat="i in ui.inputs | orderBy : order">
            <div class="thumbnail" x-ng-class="ui.active(i)" x-ng-click="ui.select(i)">
                <img class="picture" alt="" src="" x-ng-src="{{i.iconUrl}}"/>
                <div class="caption">
                    <span class="caption-label">{{'config.measurementInput.type.' + i.type | i18n}}</span>
                </div>
            </div>
        </div>
    </div>
</form>

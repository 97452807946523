<div class="row" x-errors="item.errors" x-errors-root="item.config">
    <div x-ng-if="item.config.type==='toggle'">
        <div class="col-xs-12">
            <div class="form-group">
                <label class="form-group-label">{{'config.name' | i18n}}</label>
                <div class="col-sm-12 input-group">
                    <input class="form-control"
                           x-ng-model="item.config.name"
                           x-field-help="config.rule.name.help"
                           x-field-errors="'item.config.name'"/>
                </div>
            </div>
            <label class="form-group-label">{{'config.momentRule.notifiers' | i18n}}</label>

            <div class="form-group">
                <div class="input-group">
                    <select class="form-control"
                            x-ng-disabled="!config.engine.notifiers.length"
                            x-ng-model="item.config.positive.actions[0].notifierId"
                            x-ng-options="n.id as (n.label || n.name) for n in config.engine.notifiers"
                            x-ng-change="ui.updateRule(item.config)"
                            x-field-help="config.item.config.notifiers.help"
                            x-field-errors="'item.config.positive.actions[0].notifierId'"></select>
                </div>
            </div>
            <div class="form-group">
                <label class="form-group-label">{{'config.momentRule.notifier.to' | i18n}}</label>
                <div class="col-sm-12 input-group">
                    <input class="form-control"
                           x-ng-model="item.config.positive.actions[0].to"
                           x-field-help="config.rule.notifier.to.help"
                           x-field-errors="'item.config.positive.actions[0].to'"/>
                </div>
            </div>
        </div>
        <div class="col-xs-12">
            <div class="col-xs-6">
                <svg width="200" height="100">
                    <defs>
                        <marker id="arrow" markerWidth="10" markerHeight="10"
                                refX="4" refY="2" orient="auto-start-reverse"
                                markerUnits="strokeWidth">
                            <path d="M0,0 L0,4 L5,2" fill="#000" />
                        </marker>
                        <marker id="arrow-red" markerWidth="10" markerHeight="10"
                                refX="4" refY="2" orient="auto-start-reverse"
                                markerUnits="strokeWidth">
                            <path d="M0,0 L0,4 L5,2" fill="#f00" />
                        </marker>
                        <marker id="arrow-green" markerWidth="10" markerHeight="10"
                                refX="4" refY="2" orient="auto-start-reverse"
                                markerUnits="strokeWidth">
                            <path d="M0,0 L0,4 L5,2" fill="#0f0" />
                        </marker>
                    </defs>
                    <line x1="0" y1="50" x2="200" y2="50" stroke="#000" stroke-width="2" marker-end="url(#arrow)"/>

                    <line x1="0" y1="75" x2="130" y2="75" stroke-width="2" x-ng-style="{ 'stroke': item.config.updown ? '#0f0' : '#f00', 'marker-end': item.config.updown ? 'url(#arrow-green)' : 'url(#arrow-red)' }"/>
                    <line x1="70" y1="25" x2="70" y2="75" stroke-width="2" x-ng-style="{ 'stroke': item.config.updown ? '#0f0' : '#f00', 'marker-end': item.config.updown ? 'url(#arrow-green)' : 'url(#arrow-red)' }"/>

                    <line x1="200" y1="25" x2="70" y2="25" stroke-width="2" x-ng-style="{ 'stroke': item.config.updown ? '#f00' : '#0f0', 'marker-end': item.config.updown ? 'url(#arrow-red)' : 'url(#arrow-green)' }"/>
                    <line x1="130" y1="75" x2="130" y2="25" stroke-width="2" x-ng-style="{ 'stroke': item.config.updown ? '#f00' : '#0f0', 'marker-end': item.config.updown ? 'url(#arrow-red)' : 'url(#arrow-green)' }"/>

                    <text x="50" y="90" x-ng-style="{fill: item.config.updown ? '#0f0' : '#f00'}">{{ (item.config.updown ? 'config.momentRule.notifier.endalarm' : 'config.momentRule.notifier.alarm') | i18n }}</text>
                    <text x="50" y="60">{{ pui.format(item.config.updown ? pui.ruleDeactivationValue(item.config) : pui.ruleActivationValue(item.config)) }}</text>

                    <text x="110" y="20" x-ng-style="{fill: item.config.updown ? '#f00' : '#0f0'}">{{ (item.config.updown ? 'config.momentRule.notifier.alarm' : 'config.momentRule.notifier.endalarm') | i18n }}</text>
                    <text x="110" y="60">{{ pui.format(item.config.updown ? pui.ruleActivationValue(item.config) : pui.ruleDeactivationValue(item.config)) }}</text>
                </svg>
			</div>
			<div class="col-xs-6">
				<div class="form-group">
					<label class="form-group-label">{{'config.momentRule.direction' | i18n}}</label>
					<div class="col-sm-12 input-group">
						<select class="form-control"
                                x-ng-model="item.config.updown"
                                x-ng-change="ui.updateExpression(item.config)"
                                x-field-help="config.rule.direction.help"
								x-ng-options="n.value as n.name for n in hysteresisDir">
						</select>
					</div>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.momentRule.alarm.value' | i18n}}</label>
					<div class="input-group">
						<input class="form-control"
							   placeholder="{{'config.momentRule.alarm.value' | i18n }}"
							   x-ng-model="item.config.value"
                               x-ng-model-options="{updateOn: 'blur'}"
                               x-scale-formatter=""
                               x-format-scale="pui.scale()"
                               x-format-precision="pui.precision()"
                               x-ng-change="ui.updateExpression(item.config)"/>
						<span class="input-group-btn">
                            <button type="button" class="btn btn-default" disabled="disabled">{{ pui.unit() }}</button>
                            <button type="button" class="btn btn-primary"
                                    uib-popover="{{'config.rule.notifier.value.help' | i18n}}"
                                    popover-trigger="mouseenter" popover-placement="bottom">?</button>
                        </span>
                    </div>
					<!--<button class="btn btn-primary col-sm-2 pull-right" type="button" uib-popover="{{'config.rule.notifier.value.help' | i18n}}"
							popover-trigger="mouseenter" popover-placement="bottom">?</button>-->
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.momentRule.alarm.hysteresis' | i18n}}</label>
					<div class="input-group">
						<input class="form-control"
							   placeholder="{{'config.momentRule.alarm.hysteresis' | i18n }}"
							   x-ng-model="item.config.hysteresis"
                               x-ng-model-options="{updateOn: 'blur'}"
                               x-scale-formatter=""
                               x-format-scale="pui.scale()"
                               x-format-precision="pui.precision()"
							   x-ng-change="ui.updateExpression(item.config)"/>
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default" disabled="disabled">{{ pui.unit() }}</button>
                            <button type="button" class="btn btn-primary"
                                    uib-popover="{{'config.rule.notifier.hysteresis.help' | i18n}}"
                                    popover-trigger="mouseenter" popover-placement="bottom">?</button>
                        </span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-xs-12 list-group-item-danger" style="padding: 10px; margin-top: 10px;">
			<h4>{{'config.momentRule.notifier.positive.title' | i18n}}</h4>

			<div class="form-group" x-ng-if="item.config.notifier !== 'sms'">
				<label class="form-group-label">{{'config.momentRule.notifier.subject' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<input class="form-control"
						   x-ng-model="item.config.positive.actions[0].subject"
						   x-field-help="config.rule.notifier.subject.help"
						   x-field-errors="'item.config.positive.actions[0].subject'"/>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].subject = (item.config.positive.actions[0].subject || '') + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].subject = (item.config.positive.actions[0].subject || '') + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>

			<div class="form-group">
				<label class="form-group-label">{{'config.momentRule.notifier.message' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<textarea class="form-control span6"
                              rows="3"
							  x-ng-model="item.config.positive.actions[0].message"
                              x-field-errors="'item.config.positive.actions[0].message'"></textarea>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].message = (item.config.positive.actions[0].message || '') + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.positive.actions[0].message = (item.config.positive.actions[0].message || '') + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>
		</div>

		<div class="col-xs-12 list-group-item-success" style="padding: 10px; margin-top: 10px;">
			<button type="button" class="btn btn-link pull-right" x-ng-click="ui.setNegativeAction()"
					style="background-image: none; outline: 0; -webkit-box-shadow: none; box-shadow: none; text-decoration: none;">
				<i class="fa fa-check-square-o" aria-hidden="true" x-ng-if="item.config.negative.actions[0]"></i>
				<i class="fa fa-square-o" aria-hidden="true" x-ng-if="!item.config.negative.actions[0]"></i>
				{{'config.momentAction.type.notifier' | i18n}}
			</button>
			<h4>
				{{'config.momentRule.notifier.negative.title' | i18n}}
			</h4>

			<div class="form-group" x-ng-if="item.config.notifier !== 'sms'">
				<label class="form-group-label">{{'config.momentRule.notifier.subject' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<input class="form-control"
						   x-ng-disabled="!item.config.negative.actions[0]"
						   x-ng-model="item.config.negative.actions[0].subject"
						   x-field-help="config.rule.notifier.subject.help"
						   x-field-errors="'item.config.negative.actions[0].subject'"/>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].subject = (item.config.negative.actions[0].subject || '') + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].subject = (item.config.negative.actions[0].subject || '') + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>

			<div class="form-group">
				<label class="form-group-label">{{'config.momentRule.notifier.message' | i18n}}</label>
				<div class="col-sm-12 input-group">
					<textarea class="form-control span6" rows="3"
							  x-ng-disabled="!item.config.negative.actions[0]"
							  x-ng-model="item.config.negative.actions[0].message"
                              x-field-errors="'item.config.negative.actions[0].message'"></textarea>
				</div>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].message = (item.config.negative.actions[0].message || '') + '${current.valueStr}'">
					{{'config.momentRule.notifier.message.btn-value' | i18n}}
				</button>
				<button type="button" class="btn btn-primary btn-xs"
						x-ng-click="item.config.negative.actions[0].message = (item.config.negative.actions[0].message || '') + '${current.timestampStr}'">
					{{'config.momentRule.notifier.message.btn-time' | i18n}}
				</button>
			</div>
		</div>
	</div>
</div>


<form class="form-horizontal">
    <label class="control-label">{{'config.choose' | i18n}}</label>
    <div class="row">
        <div class="col-sm-3" x-ng-repeat="d in ui.devices| filter:{manufacturer:mainMeters}">
            <div class="thumbnail" x-ng-class="ui.active(d)" x-ng-click="ui.select(d)">
                <div class="thumbnail-price"><i class="fa fa-shopping-cart"></i> {{::d.cost}}</div>
                <img alt="" src="" x-ng-src="{{::d.iconUrl}}"/>
                <div class="caption">
                    <span class="caption-label">{{::d.label}}</span>
                </div>
            </div>
        </div>
    </div>
    <h3>{{'config.foreignimplementation'|i18n}}</h3>
    <hr>
    <label class="control-label">{{'config.choose' | i18n}}</label>
    <div class="row">
        <div class="col-sm-3" x-ng-repeat="d in ui.devices| filter:{manufacturer:'!'+mainMeters, type:'!machine'}">
            <div class="thumbnail" x-ng-class="ui.active(d)" x-ng-click="ui.select(d)">
                <div class="thumbnail-price"><i class="fa fa-shopping-cart"></i> {{::d.cost}}</div>
                <img alt="" src="" x-ng-src="{{::d.iconUrl}}"/>
                <div class="caption">
                    <span class="caption-label">{{::d.label}}</span>
                </div>
            </div>
        </div>
    </div>
</form>

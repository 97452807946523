var module = angular
        .module('meternet.measurements.directives', [ 'meternet.services', 'meternet.dashboard.constants' ]);

function MeasurementsDisplayCtrl($scope, measurementsService, contextPath) {
    $scope.series = [];

    var init = function() {
        measurementsService.getSeries().then(function(data) {
            $scope.series = data;

        });
    };
    $scope.ui = {
        edit : function(element) {
            $scope.$emit('show.measurements.form', element);
        },
        addChild : function(parentElement, elementType) {
            if ($scope.configForm.$invalid) {
                // $scope.alerts.push({type:'danger', icon:'fa
                // fa-exclamation-circle fa-5x', msg: "invalid"});
                // $timeout(function(){$scope.alerts.shift();}, 5000);
            } else {
                var newElement;
                if ('lampSerie' === elementType) {
                    newElement = {
                        "alias" : "",
                        "manufacturer" : "",
                        "modelName" : "",
                        "lamps" : []
                    };
                    $scope.ui.edit(measurementsService.createSerie(newElement));
                } else if ('lamp' === elementType) {
                    newElement = {
                        "modelId" : parentElement.modelId,
                        "serialNumber" : "",
                        "voltage" : 0,
                        "current" : 0,
                        "temperature" : 0,
                        "timeStart" : new Date(),
                        "timeEnd" : null
                    };
                    var lamp = parentElement.addLamp(newElement);
                    $scope.ui.edit(lamp);
                }
            }
        },
        deleteChild : function(editedElement) {
            var type = editedElement.getClassName();
            if (type === "LampSerie") {
                measurementsService.deleteSerie(editedElement).then(function() {
                    $scope.editedElement = null;
                    init();
                });

            } else if (type === "Lamp") {
                measurementsService.deleteLamp(editedElement).then(function() {
                    $scope.editedElement = null;
                    init();
                });
            }
        }
    };

    $scope.$on('led.serie.saved', function() {
        init();
    });

    $scope.$on('led.lamp.saved', function() {
        init();
    });

    $scope.$on('show.measurements.form', function($event, newElement) {
        // if($scope.configForm.$invalid){
        // $scope.alerts.push({type:'danger', icon:'fa fa-exclamation-circle
        // fa-5x', msg: "invalid"});
        // $timeout(function(){$scope.alerts.shift();}, 5000);
        // }else{
        $scope.editedElement = newElement;
        // }
    });

    init();
}


function LampSerieConfigCtrl($scope, $filter, measurementsService, dataService, DashboardEvents, simulationsService,
        contextPath) {
    $scope.contextPath = contextPath;

    var drawChart = function(dataVariable, optionsVariable, propertyName, receivedData) {
        var dateDiff = $scope.ui.simulationDateEnd - $scope.ui.simulationDateStart;
        dateDiff = dateDiff / (3600 * 1000);

        var dataCopy = [];
        if (propertyName.indexOf("simulated") === 0) {
            dataCopy = _.where(receivedData, {
                heading : "simulation"
            });
        } else {

            dataCopy = _.reject(receivedData, function(obj) {
                return obj.heading === "simulation";
            });
        }
        var serieNames = [];
        for ( var int = 0; int < dataCopy.length; int++) {
            if (!_.contains(serieNames, dataCopy[int].heading)) {

                serieNames.push(dataCopy[int].heading);
            }
        }
        // $scope[optionsVariable] = {};
        var options = {
            series : [],
            "timeTo" : $scope.ui.simulationDateEnd,
            "historyTime" : dateDiff,
            "timeUnit" : 60,
            "grid" : 0,
            "mainGridColor" : "#BBBBBB",
            "subGridColor" : "#999999",
            "showOnlyTime" : false,
            "enableZoom" : true,
            "enableScroll" : false
        };

        var axis1 = {
            scale : 0,
            precision : 2,
            rangeAuto : true,
            rangeMin : null,
            rangeMax : null,
            labelColor : "#c0c0c0",
            axisColor : "#c0c0c0",
            grid : 1,
            mainGridColor : "#c0c0c0",
            subGridColor : "#d3d3d3"
        };
        for ( var int2 = 0; int2 < serieNames.length; int2++) {
            var color = "#" + ((1 << 24) * Math.random() | 0).toString(16);
            if (int2 === 0) {
                options.series.push({
                    name : serieNames[int2],
                    lineColor : color,
                    lineWidth : 2,
                    markerColor : color,
                    markerSize : 2,
                    axisIndex : null,
                    axis : axis1,
                });
            } else {
                options.series.push({
                    name : serieNames[int2],
                    lineColor : color,
                    lineWidth : 2,
                    markerColor : color,
                    markerSize : 2,
                    axisIndex : 0,
                    axis : axis1
                });
            }
            switch (propertyName) {
            case "simulated.luminance":
                options.series[int2].unit = 'Lm';
                break;
            case "simulated.ra8":
                options.series[int2].unit = 'Ra';
                break;
            case "simulated.xk":
                options.series[int2].unit = '-';
                break;
            case "simulated.yk":
                options.series[int2].unit = '-';
                break;
            case "simulated.colorTemperature":
                options.series[int2].unit = 'K';
                break;
            case "observed.luminance":
                options.series[int2].unit = 'Lm';
                break;
            case "observed.ra8":
                options.series[int2].unit = 'Ra';
                break;
            case "observed.xk":
                options.series[int2].unit = '-';
                break;
            case "observed.yk":
                options.series[int2].unit = '-';
                break;
            case "observed.colorTemperature":
                options.series[int2].unit = 'K';
                break;
            default:
                break;
            }
        }

        var data = [ [] ];

        var propName = propertyName.substring(propertyName.lastIndexOf('.') + 1);
        for ( var index in dataCopy) {
            if (!angular.isDefined(data[serieNames.indexOf(dataCopy[index].heading)])) {
                data[serieNames.indexOf(dataCopy[index].heading)] = [];
            }
            data[serieNames.indexOf(dataCopy[index].heading)].push({
                timestamp : new Date(dataCopy[index].millis),
                value : dataCopy[index][propName]
            });
        }
        $scope[optionsVariable] = options;
        $scope[dataVariable] = data;
        $scope.$broadcast(DashboardEvents.REDRAW, $scope[dataVariable]);
    };

    var init = function() {
        $scope.ui = {
            manufacturer : $scope.lampSerieConfig.manufacturer,
            modelName : $scope.lampSerieConfig.modelName,
            alias : $scope.lampSerieConfig.alias,
            simulationDateStart : new Date(),
            simulationDateEnd : new Date(new Date().getTime() + 1000 * 3600 * 24 * 30 * 6),
            simulationTemperature : 30,
            saveSerie : function() {
                $scope.lampSerieConfig.modelName = $scope.ui.modelName;
                $scope.lampSerieConfig.manufacturer = $scope.ui.manufacturer;
                $scope.lampSerieConfig.alias = $scope.ui.alias;
                measurementsService.saveSerie($scope.lampSerieConfig).then(function(data) {
                    $scope.$emit('led.serie.saved');
                    init();
                });
            },
            ready : false,
            simulate : function() {
                $scope.ui.ready = false;
                simulationsService.getSimulationResult($scope.lampSerieConfig.modelId,
                        $scope.ui.simulationDateStart.getTime(), $scope.ui.simulationDateEnd.getTime(),
                        $scope.ui.simulationTemperature).then(
                        function(data) {
                            $scope.ui.ready = false;
                            drawChart("ra8dataObserved", "ra8optionsObserved", "observed.ra8", data);
                            drawChart("colorTemperatureDataObserved", "colorTemperatureOptionsObserved",
                                    "observed.colorTemperature", data);
                            drawChart("xkDataObserved", "xkOptionsObserved", "observed.xk", data);
                            drawChart("ykDataObserved", "ykOptionsObserved", "observed.yk", data);
                            drawChart("luminanceDataObserved", "luminanceOptionsObserved", "observed.luminance", data);

                            drawChart("ra8dataSimulated", "ra8optionsSimulated", "simulated.ra8", data);
                            drawChart("colorTemperatureDataSimulated", "colorTemperatureOptionsSimulated",
                                    "simulated.colorTemperature", data);
                            drawChart("xkDataSimulated", "xkOptionsSimulated", "simulated.xk", data);
                            drawChart("ykDataSimulated", "ykOptionsSimulated", "simulated.yk", data);
                            drawChart("luminanceDataSimulated", "luminanceOptionsSimulated", "simulated.luminance",
                                    data);
                            $scope.ui.ready = true;
                        });
            }
        };
    };

    $scope.$watch('lampSerieConfig', function() {
        init();
    });

    simulationsService.getAvailableParameters().then(function(data) {
        $scope.simulatedParameters = [];
        for ( var index in data) {
            $scope.simulatedParameters.push("simulated." + data[index]);
            $scope.simulatedParameters.push("observed." + data[index]);
        }
        $scope.selectedParameter = $scope.simulatedParameters[0];
    });

    $scope.dataChanged = function() {
        if (angular.isDefined($scope.ui.manufacturer) && ($scope.ui.manufacturer !== $scope.lampSerieConfig.manufacturer)) {
            return true;
        }
        if (angular.isDefined($scope.ui.modelName) && ($scope.ui.modelName !== $scope.lampSerieConfig.modelName)) {
            return true;
        }
        if (angular.isDefined($scope.ui.alias) && ($scope.ui.alias !== $scope.lampSerieConfig.alias)) {
            return true;
        }
        return false;
    };

    init();
}

function LampConfigCtrl($scope, $filter, unitFilter, configService, measurementsService, contextPath) {
    $scope.contextPath = contextPath;
    var selectDevice = function(allDevices, uiDevice, uiParam, lampParam) {
        var checkParam = function(data) {
            return data.id === lampParam;
        };
        for ( var index in allDevices) {
            var device = allDevices[index];
            var paramConfigs = device.paramConfigs;
            var selectedConfig = _.find(paramConfigs, checkParam);
            if (angular.isDefined(selectedConfig)) {
                $scope.ui[uiDevice] = device;
                $scope.ui[uiParam] = selectedConfig;
                break;
            }
        }
    };

    $scope.dataChanged = function() {
        if (angular.isDefined($scope.ui.voltageParam) && ($scope.ui.voltageParam.id !== $scope.lampConfig.voltage)) {
            return true;
        }
        if (angular.isDefined($scope.ui.currentParam) && ($scope.ui.currentParam.id !== $scope.lampConfig.current)) {
            return true;
        }
        if (angular.isDefined($scope.ui.temperatureParam) && ($scope.ui.temperatureParam.id !== $scope.lampConfig.temperature)) {
            return true;
        }
        if (angular.isDefined($scope.ui.serialNumber) && ($scope.ui.serialNumber !== $scope.lampConfig.serialNumber)) {
            return true;
        }
        if (angular.isDefined($scope.ui.timeStart) && ($scope.ui.timeStart !== $scope.lampConfig.timeStart)) {
            return true;
        }
        if (angular.isDefined($scope.ui.timeEnd) && ($scope.ui.timeEnd !== $scope.lampConfig.timeEnd)) {
            return true;
        }
        return false;
    };

    var init = function() {
        $scope.ui = {
            saveLamp : function() {
                $scope.lampConfig.serialNumber = $scope.ui.serialNumber;
                $scope.lampConfig.voltage = $scope.ui.voltageParam != null ? $scope.ui.voltageParam.id : 0;
                $scope.lampConfig.temperature = $scope.ui.temperatureParam != null ? $scope.ui.temperatureParam.id : 0;
                $scope.lampConfig.current = $scope.ui.currentParam != null ? $scope.ui.currentParam.id : 0;
                $scope.lampConfig.timeStart = $scope.ui.timeStart;
                $scope.lampConfig.timeEnd = $scope.ui.timeEnd;
                measurementsService.saveLamp($scope.lampConfig).then(function(data) {
                    $scope.lampConfig.itemId = data.itemId;
                    $scope.$emit('led.lamp.saved');
                    init();
                });
            },
            setAccordion : function(where) {
                $scope.ui.editedOptions = null;
                $scope.ui.accordionOpts.open[where] = true;
            },
            accordionOpts : {
                oneAtATime : true,
                isFirstOpen : true,
                open : {}
            },
            serialNumber : $scope.lampConfig.serialNumber,
            timeStart : $scope.lampConfig.timeStart,
            timeEnd : $scope.lampConfig.timeEnd,
            listFiles : function() {
                if (angular.isDefined($scope.lampConfig.itemId)) {
                    measurementsService.getReports($scope.lampConfig).then(function(data) {
                        $scope.reportFiles = data;
                    });
                } else {
                    $scope.reportFiles = [];
                }
            },
            getFile : function(path) {
                measurementsService.getReport(path).then(function(data) {

                });
            },
            deleteFile : function(file) {
                measurementsService.deleteReport(file).then(function(data) {
                    $scope.ui.listFiles();
                });
            }
        };
        $scope.ui.setAccordion("basicInformation");

        configService.get().then(
                function(meternetConfig) {

                    var interfaces = meternetConfig.interfaces;
                    $scope.allDevicesTemperature = $filter('flatten')($filter('pluck')(interfaces, 'deviceConfigs'),
                            true);
                    $scope.allDevicesTemperature = _.filter($scope.allDevicesTemperature.concat(meternetConfig.maths),
                            function(d) {
                                return d.paramConfigs.length > 0;
                            });

                    $scope.allDevicesVoltage = $filter('flatten')($filter('pluck')(interfaces, 'deviceConfigs'), true);
                    $scope.allDevicesVoltage = _.filter($scope.allDevicesVoltage.concat(meternetConfig.maths),
                            function(d) {
                                return d.paramConfigs.length > 0;
                            });
                    $scope.allDevicesCurrent = $filter('flatten')($filter('pluck')(interfaces, 'deviceConfigs'), true);
                    $scope.allDevicesCurrent = _.filter($scope.allDevicesCurrent.concat(meternetConfig.maths),
                            function(d) {
                                return d.paramConfigs.length > 0;
                            });
                    selectDevice($scope.allDevicesCurrent, "temperatureDevice", "temperatureParam",
                            $scope.lampConfig.temperature);
                    selectDevice($scope.allDevicesCurrent, "voltageDevice", "voltageParam", $scope.lampConfig.voltage);
                    selectDevice($scope.allDevicesCurrent, "currentDevice", "currentParam", $scope.lampConfig.current);
                });
        if (angular.isDefined($scope.lampConfig.itemId)) {
            measurementsService.getMeasuredValues($scope.lampConfig.itemId).then(function(measured) {
                $scope.measuredValues = {
                    time : measured[0] != null ? moment.duration(measured[0], "seconds").humanize() : "---",
                    current : measured[1] != null ? unitFilter(measured[1], 3, "A") : "---",
                    voltage : measured[2] != null ? unitFilter(measured[2], 3, "V") : "---",
                    temperature : measured[3] != null ? unitFilter(measured[3], 1, "°C") : "---"
                };
            });
        } else {
            $scope.measuredValues = {
                time : 0,
                current : 0,
                voltage : 0,
                temperature : 0
            };
        }
        $scope.ui.listFiles();
    };

    $scope.$watch('lampConfig', function() {
        init();
    });
}

module.directive('lampSerieConfig', 
        function(contextPath, $filter) {
            return {
                scope : {
                    lampSerieConfig : '='
                },
                controller : LampSerieConfigCtrl,
                templateUrl: 'measurements/lamp-serie-config.html'
            };
});

module.directive('lampConfig', function(contextPath, $filter) {

            return {
                scope : {
                    lampConfig : '='
                },
                controller : [ '$scope', '$filter', 'unitFilter', 'configService', 'measurementsService',
                        'contextPath', LampConfigCtrl ],
                templateUrl: 'measurements/lamp-config.html',
                link : function($scope, $element) {

                }
            };
});
module.directive('measurementsDisplay', function(contextPath) {
    return {
        scope : {

        },
        controller : [ '$scope', 'measurementsService', 'contextPath', MeasurementsDisplayCtrl ],
        templateUrl: 'measurements/measurements-display.html'
    };
});

<div x-mbsat-gauge="" x-data="mbsat" x-options="config"  x-ng-if="config.series.length>0" class="mbsat-container"></div>
<div x-ng-show="config.series.length==0">
    <div class="row" style="height: 100%;">
        <div class="col-sm-6 col-sm-offset-3" style="padding-top: 20px;">
            <button class="btn btn-info btn-lg" x-ng-class="{'disabled' : !$parent.$parent.editMode}"
                    style="width : 100%;" x-ng-click="$parent.$parent.editMode ? $parent.$parent.edit() : null"
                    title="{{$parent.$parent.editMode ? ('widget.configure' | i18n) : ('widget.enable.editMode' | i18n)}}">
                {{'widget.configure'| i18n}} <i class="fa fa-cog"></i></button>
        </div>
    </div>
</div>

<div class="panel panel-primary">
    <div class="panel-heading">{{'menu.report.energy' | i18n}}</div>
    <div class="panel-body">
        <form class="form-inline">
            <div class="form-group">
                <select class="form-control" style="width: 300px;" placeholder="{{'energyReport.chooseReport' | i18n}}"
                        x-ng-model="report" x-ng-options="r.name for r in config.energyReports"></select>
            </div>

            <div class="pull-right">
                <button class="btn btn-success" x-ng-click="ui.add()" x-ng-show="ui.canAdd()">
                    <i class="fa fa-plus fa-lg"></i>&nbsp;&nbsp;{{'ui.new' | i18n}}
                </button>
                <button class="btn btn-primary" x-ng-click="ui.edit()" x-ng-show="ui.canEdit()">
                    <i class="fa fa-edit fa-lg"></i>&nbsp;&nbsp;{{'ui.edit' | i18n}}
                </button>
                <button class="btn btn-danger" x-ng-click="ui.remove()" x-ng-show="ui.canRemove()">
                    <i class="fa fa-trash fa-lg"></i>
                </button>
            </div>

        </form>
    </div>
    <div class="panel-body">
        <div x-energy-report-table="report" x-ng-if="report != null"></div>
    </div>
</div>

var module = angular.module('meternet.fileManager.directives', [
	'meternet.services',
	'meternet.config.controllers',
	'meternet.filters'
]);

module.directive('fileManager', function() {
	return {
		scope: {
			cos: '=',
			config: '='
		},
		templateUrl: 'report/file-manager.html',
		controller: function ($scope, $filter, fileManagerService) {
			$scope.files = [];
			fileManagerService.get().then(function (files) {
				$scope.files = files;
			});
		}
	};
});



/* globals Stomp, Mimeparse, SockJS */

Stomp.WebSocketClass = SockJS;

var module = angular.module('meternet.stomp.services', []);
module.factory('stompClient', function($rootScope, $q, $timeout, $log, $window, $http, contextPath, loginPage) {

    function createClient(url) {
        return Stomp.client(url, 'v11.stomp');
    }

    function StompClient(url) {
        this.url = window.location.protocol + '//' + window.location.host + url;
		this.stompConnectionChangeEventName = 'STOMP' + url;
        this.isConnected = false;

        this.stompClient = createClient(this.url);

        this.stompClient.debug = function(text) {
            //console.log(text);
        };
    }

    StompClient.prototype.subscribe = function(queue, callback) {
        var result = this.stompClient.subscribe(queue, function() {
            var args = arguments;
            $rootScope.$apply(function() {
                callback(args[0]);
            });
        });
        return result.id;
    };

    StompClient.prototype.send = function(queue, headers, data) {
        this.stompClient.send(queue, headers, data);
    };

    StompClient.prototype.connect = function(user, password, vhost) {
        var deferred = $q.defer();
        var self = this;
        this.stompClient.connect(user, password, function(frame) {
            deferred.resolve(frame);
            self.changeConnectionStatus(true);
        }, function(frame) {
            if (!frame.command) {
                self.changeConnectionStatus(false);

                $timeout(function() {
                    /*$http.get(self.url).error(function(data, status, headers, config) {
                        if (status === 401 || status === 403) {
                            window.location.href = contextPath + loginPage;
                        }
                    });*/
                    if (!self.isConnected) {
                        self.stompClient = createClient(self.url);
						$log.info("Lost connection. Reconnecting");
                        self.connect(user, password, vhost).then(function() {
                            $log.info('Reconnected, refreshing page');
                            $window.location.reload();
                        });
                    }
                }, 1000);

            }
            deferred.reject(frame);
        }, vhost);
        return deferred.promise;
    };

    StompClient.prototype.disconnect = function() {
        var deferred = $q.defer();
        var self = this;
        this.stompClient.disconnect(function() {
            var args = arguments;
            self.changeConnectionStatus(false);
            deferred.resolve(args);
        });
        return deferred.promise;
    };

    StompClient.prototype.unsubscribe = function(id) {
        this.stompClient.unsubscribe(id);
    };

    StompClient.prototype.onConnectionStatusChange = function($scope, callback) {
        $scope.$on(this.stompConnectionChangeEventName, callback);
    };

    StompClient.prototype.changeConnectionStatus = function(status) {
        this.isConnected = status;
        var self = this;
        $rootScope.$apply(function() {
            $rootScope.$broadcast(self.stompConnectionChangeEventName, status);
        });

    };

    function MessageConverter(supportedMediaTypes, serializeFn, deserializeFn) {
        this.serialize = serializeFn;
        this.deserialize = deserializeFn;
        this.supportedMediaTypes = supportedMediaTypes;
        this.parsedMediaTypes = [];
        for ( var i in supportedMediaTypes) {
            this.parsedMediaTypes.push(Mimeparse.parseMediaRange(this.supportedMediaTypes[i]));
        }
    }

    MessageConverter.prototype.match = function(contentType) {
        return Mimeparse.qualityParsed(contentType, this.parsedMediaTypes);
    };

    //      var BSON = bson().BSON;
    var APPLICATION_BSON = "application/bson";
    var APPLICATION_JSON = "application/json";
    var msgConverters = [ new MessageConverter([ APPLICATION_JSON ], function(data) {
        return JSON.stringify(data);
    }, function(data) {
        return JSON.parse(data);
    })
    //        		,
    //        		new MessageConverter([APPLICATION_BSON],
    //    			function(data){
    //        			var serialized = BSON.serialize(data)
    //        			console.log(serialized.toString);
    //    				return serialized;
    //    			},
    //    			function(data){
    //    				return BSON.deserialize(data);}
    //    			)

    ];

    StompClient.prototype.ajax = function(options, payload) {

        var deferred = $q.defer();
        var requestTimeout = options.timeout || 15000;

        var self = this;
        var topic = options.topic;
        options.headers = options.headers || {};

        var subscriptionId = self.subscribe(topic, function(frame) {
            var contentType = frame.headers["content-type"] || frame.headers["Content-Type"];
            var bestConverter = _.max(msgConverters, function(converter) {
                return converter.match(contentType);
            });
            var parsedData = bestConverter.deserialize(frame.body);

            deferred.resolve(parsedData);
            self.unsubscribe(subscriptionId);
        });

        $timeout(function() {
            self.unsubscribe(subscriptionId);
            deferred.reject();

        }, requestTimeout);
        var contentType = options.headers["content-type"] || options.headers["Content-Type"] || APPLICATION_JSON;
        var bestConverter = _.max(msgConverters, function(converter) {
            return converter.match(contentType);
        });
        var serializedData = bestConverter.serialize(payload);
        self.send(options.dest, options.headers, serializedData);
        //			$.ajax({
        //        		type: "POST",
        //        		url: "/energy/",
        //        		data: serializedData.buffer,
        //        		success: function(data){
        //        			console.log('$ajax success');
        //        			console.log(typeof data);
        //        			console.log(data);
        //        		},
        //        		contentType: APPLICATION_BSON,
        //        		accept: APPLICATION_BSON
        //        		});
        return deferred.promise;

    };

    return function(url) {
        return new StompClient(url);
    };
});

/*
module.service('dataStompClient', function($q, $timeout, stompClient) {
    var url = 'ws://data';
    var deferred = $q.defer();
    var client = stompClient(url);

    $timeout(function() {
        client.connect().then(function() {
            deferred.resolve(client);
        });
    }, 0);

    return deferred.promise;

});*/

<div x-expand-to-parent-bottom="" style="overflow-y: auto;">
    <table class="report-table table table-hover table-bordered table-condensed">
        <thead style="background-color: rgb(219, 233, 246);">
            <!--<tr>-->
                <!--<th colspan="2"><a></a></th>-->
                <!--<th colspan="2" class="text-center"><a>{{'prepaid.table.input'|i18n}}</a></th>-->
                <!--&lt;!&ndash;<th colspan="2" class="text-center"><a>{{'prepaid.table.output'|i18n}}</a></th>&ndash;&gt;-->
                <!--<th><a></a></th>-->
                <!--<th colspan="2" class="text-center"><a>{{'prepaid.table.limit'|i18n}}</a></th>-->
                <!--<th><a></a></th>-->
            <!--</tr>-->
            <tr>
                <th style="width:20%"><a>{{'prepaid.table.name'|i18n}}</a></th>
                <th><a>{{'prepaid.table.descr'|i18n}}</a></th>
                <th style="width:10%"><a>{{'prepaid.table.inputTimestamp'|i18n}}</a></th>
                <th style="width:10%"><a>{{'prepaid.table.inputValue'|i18n}}</a></th>
                <!--<th class="col-sm1"><a>{{'prepaid.table.outputTimestamp'|i18n}}</a></th>-->
                <th style="width:10%"><a>{{'prepaid.table.limitValue'|i18n}}</a></th>
                <th style="width:10%"><a>{{'prepaid.table.outputValue'|i18n}}</a></th>
                <th style="width:10%"><a>{{'prepaid.table.limitTimestamp'|i18n}}</a></th>
                <th style="width:50px"><a>{{'prepaid.table.manage'|i18n}}</a></th>
            </tr>
        </thead>
        <tbody>
            <tr x-ng-repeat="serie in options.series">
                <td class="bold">{{serie.name}}</td>
                <td>{{prepaid.params[$index].desc}}</td>
                <td x-ng-class="{'danger':isNaN(prepaid.params[$index].inputValue)}" class="text-right">
                    {{prepaid.params[$index].inputTimestamp| date : "yyyy-MM-dd HH:mm:ss"}}
                </td>
                <td x-ng-class="{'danger':isNaN(prepaid.params[$index].inputValue)}" class="text-right bold" style="border-left-width: 2px;">
                    {{unitFilterFun(prepaid.params[$index].inputValue, prepaid.params[$index])}}
                </td>
                <td class="text-right bold">
                    <span x-ng-if="isNaN(prepaid.params[$index].limitValue)" class="text-muted">
                        {{'prepaid.table.inactive' | i18n}}
                    </span>
                    <span x-ng-if="!isNaN(prepaid.params[$index].limitValue)">
                        {{unitFilterFun(prepaid.params[$index].limitValue, prepaid.params[$index])}}
                    </span>
                </td>
                <td x-ng-class="{'danger':isNaN(prepaid.params[$index].outputValue)}" class="bold text-right">
                    <span x-ng-if="isNaN(prepaid.params[$index].outputValue)">
                        ---
                    </span>
                    <span x-ng-if="!isNaN(prepaid.params[$index].outputValue)">
                        <span x-ng-if="prepaid.params[$index].inputValue < prepaid.params[$index].limitValue" class="text-success">{{'prepaid.table.open' | i18n}}</span>
                        <span x-ng-if="prepaid.params[$index].inputValue >= prepaid.params[$index].limitValue" class="text-danger">{{'prepaid.table.close' | i18n}}</span>
                    </span>
                    <!--<span x-ng-if="!isNaN(prepaid.params[$index].outputValue)">-->
                        <!--<span x-ng-if="!prepaid.params[$index].outputValue" class="text-success">{{'prepaid.table.open' | i18n}}</span>-->
                        <!--<span x-ng-if="prepaid.params[$index].outputValue" class="text-danger">{{'prepaid.table.close' | i18n}}</span>-->
                    <!--</span>-->
                </td>
                <!--<td x-ng-class="{'danger':isNaN(prepaid.params[$index].outputValue)}" class="text-right">-->
                    <!--{{prepaid.params[$index].outputTimestamp| date : "yyyy-MM-dd HH:mm:ss"}}-->
                <!--</td>-->
                <td class="text-right">
                    {{prepaid.params[$index].limitTimestamp| date : "yyyy-MM-dd HH:mm:ss"}}
                </td>
                <td>
                    <center>
                        <button type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target="#prepaidModal" x-ng-click="setEdited(prepaid.params[$index])">
                            <i class="fa fa-wrench" aria-hidden="true"></i>
                        </button>
                    </center>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal fade" id="prepaidModal" tabindex="-1" role="dialog" aria-labelledby="prepaidModalLabel">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="prepaidModalLabel">{{'prepaid.table.manage'|i18n}}</h4>
            </div>
            <div class="modal-body">
                <div style="padding-top: 10px; padding-bottom: 15px;">
                    <div class="row">
                        <label class="control-label col-sm-4">{{'prepaid.table.inputValue' | i18n}}:</label>
                        {{unitFilterFun(editParam.inputValue, editParam)}}
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-4">{{'prepaid.table.outputValue' | i18n}}:</label>
                                <span x-ng-if="isNaN(editParam.outputValue)">
                                     ---
                                </span>
                                <span x-ng-if="!isNaN(editParam.outputValue)">
                                    <span x-ng-if="editParam.inputValue < editParam.limitValue" class="text-success">{{'prepaid.table.open' | i18n}}</span>
                                    <span x-ng-if="editParam.inputValue >= editParam.limitValue" class="text-danger">{{'prepaid.table.close' | i18n}}</span>
                                </span>
                                <!--<span x-ng-if="!isNaN(editParam.outputValue)">-->
                                    <!--<span x-ng-if="!editParam.outputValue" class="text-success">{{'prepaid.table.open' | i18n}}</span>-->
                                    <!--<span x-ng-if="editParam.outputValue" class="text-danger">{{'prepaid.table.close' | i18n}}</span>-->
                                <!--</span>-->
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-4">{{'prepaid.table.limitValue' | i18n}}:</label>
                        <span x-ng-if="isNaN(editParam.limitValue)" class="text-muted">
                            {{'prepaid.table.inactive' | i18n}}
                        </span>
                        <span x-ng-if="!isNaN(editParam.limitValue)">
                            {{unitFilterFun(editParam.limitValue, editParam)}}
                        </span>
                    </div>
                </div>
                <div class="well well-sm row">
                    <div class="row">
                        <div class="col-sm-4">
                            <a x-ng-click="setAuto(editParam)"><i class="fa fa-lg" x-ng-class="{'fa-dot-circle-o': isAuto, 'fa-circle-o': !isAuto}" aria-hidden="true"></i></a>
                            {{'prepaid.automatic' | i18n}}
                        </div>
                        <div class="form-group">
                            <label class="control-label col-sm-2 poll-right">{{'prepaid.setLimit' | i18n}}</label>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <input class="form-control" x-ng-model="editParamLimit" x-ng-disabled="!isAuto"/>
                                    <data-unit-addon data-unit-addon-scale="editParam.scale" data-unit-addon-unit = editParam.unit></data-unit-addon>
                                    <!--<span class="input-group-addon">@</span>-->
                                </div>
                            </div>
                            <div class="col-sm-2 pull-right">
                                <button type="button" class="btn btn-primary" style="width: 100%;" x-ng-click="setLimit(editParam, editParamLimit)" x-ng-disabled="!isAuto">
                                    {{'prepaid.setLimitBtn' | i18n}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="form-group">
                            <label class="control-label col-sm-2 poll-right">{{'prepaid.addToLimit' | i18n}}</label>
                            <div class="col-sm-4">
                                <div class="input-group">
                                    <input class="form-control" x-ng-model="addToParamLimit" x-ng-disabled="!isAuto"/>
                                    <data-unit-addon data-unit-addon-scale="editParam.scale" data-unit-addon-unit = editParam.unit></data-unit-addon>
                                    <!--<span class="input-group-addon">@</span>-->
                                </div>
                            </div>
                            <div class="col-sm-2 pull-right">
                                <button type="button" class="btn btn-primary" style="width: 100%;" x-ng-click="addToLimit(editParam, addToParamLimit)" x-ng-disabled="!isAuto">
                                    {{'prepaid.addLimitBtn' | i18n}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<hr/>-->
                <div class="well well-sm row">
                    <div class="col-sm-4">
                        <a x-ng-click="setManual(editParam)"><i class="fa fa-lg" x-ng-class="{'fa-circle-o': isAuto, 'fa-dot-circle-o': !isAuto}" aria-hidden="true"></i></a>
                        {{'prepaid.manual' | i18n}}
                    </div>
                    <!--<div class="btn-group col-sm-8" role="group" aria-label="...">-->
                        <!--<button type="button" class="btn btn-success" x-ng-click="changeOutput(editParam, 0)" x-ng-disabled="!editParam.outputValue || isAuto">-->
                            <!--{{'prepaid.openBtn' | i18n}}-->
                        <!--</button>-->
                        <!--<button type="button" class="btn btn-danger" x-ng-click="changeOutput(editParam, 1)" x-ng-disabled="editParam.outputValue || isAuto">-->
                            <!--{{'prepaid.closeBtn' | i18n}}-->
                        <!--</button>-->
                    <!--</div>-->

                    <div class="col-sm-8">
                        <div class="can-toggle">
                            <input id="onoff" type="checkbox"  x-ng-click="changeOutput(editParam, editParam.outputValue?0:1)" x-ng-checked="editParam.outputValue" x-ng-disabled="isAuto">
                            <label for="onoff">
                                <div class="can-toggle__switch" data-checked="{{'prepaid.openBtn' | i18n}}" data-unchecked="{{'prepaid.closeBtn' | i18n}}"></div>
                            </label>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
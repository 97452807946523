<!-- Zostawiam rezte kodu bo a noz cos sie jeszcze umiesci w stopce -->
<!-- <div class="container" id="footer">
	<hr/>
	<div class="row">
		<div class="col-xs-4">
		</div>	
		<div class="col-xs-4 text-center">
			MeterNET Powered by IDANET<br/>
			<a href="http://www.idanet.pl">www.idanet.pl</a>
		</div>
		<div class="col-xs-4 text-right">
			
		</div>
		
	</div>
</div> -->
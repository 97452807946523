var module = angular.module('meternet.simulations.services', []);

function SimulationsService($q, $http, contextPath) {
    //API
    return {
        getSimulationResult : function(lampSerieId, millisStart, millisEnd, temperature) {
            var deferred = $q.defer();
            var url = contextPath + "/lamps/simulations?lampSerieId=" + lampSerieId + "&timeStart=" + millisStart + "&timeEnd=" + millisEnd + "&temperature=" + temperature;
            $http({
                method : 'GET',
                url : url
            }).success(function(data, status, headers) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        getAvailableParameters : function() {
            var deferred = $q.defer();
            var url = contextPath + "/lamps/parameters";
            $http({
                method : 'GET',
                url : url
            }).success(function(data, status, headers) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        }
    };
}
module.service('simulationsService', SimulationsService );
<div x-uib-accordion="" x-close-others="true">
    <div x-uib-accordion-group="" x-heading="{{'led.config.lamp.basicInformation' | i18n}}"
         x-ng-click="ui.setAccordion('basicInformation');"
         x-is-open="ui.accordionOpts.open.basicInformation"
         x-ng-class="{'accordion-active': ui.accordionOpts.open.basicInformation}" class="panelHeadingTree">
        <div>
            <div class="row">
                <div class="col-lg-7 col-md-8">
                    <div class="form-group">
                        <label for="configLampSerialNumber" class="col-sm-4 control-label">{{'led.config.lamp.serialNumber'
                            | i18n}}</label>

                        <div class="col-sm-7">
                            <input type="text" class="form-control" id="configLampSerialNumber"
                                   name="configLampSerialNumber" placeholder="{{'led.config.lamp.serialNumber' | i18n}}"
                                   data-ng-model="ui.serialNumber"
                                    />
                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                                    tooltip-placement="right"
                                    uib-tooltip="{{'led.config.lamp.question.serialNumber' | i18n}}"
                                    tooltip-animation="true"><span class="fa fa-question"/></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="configLampTemperature" class="col-sm-4 control-label">{{'led.config.lamp.temperature'
                            | i18n}}</label>

                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <div class="form-group"
                                 x-ng-class="{'has-error': widgetConfigForm.widgetConfigDeviceId.$invalid}">
                                <select class="form-control" data-ng-model="ui.temperatureDevice"
                                        x-ng-options="device as device.name for device in allDevicesTemperature">
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                            <div class="form-group"
                                 x-ng-class="{'has-error': widgetConfigForm.widgetConfigDeviceId.$invalid}">
                                <select class="form-control" data-ng-model="ui.temperatureParam"
                                        x-ng-options="param as param.name for param in ui.temperatureDevice.paramConfigs">
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                                    tooltip-placement="right"
                                    uib-tooltip="{{'led.config.lamp.question.temperature' | i18n}}"
                                    tooltip-animation="true"><span class="fa fa-question"/></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="configLampVoltage" class="col-sm-4 control-label">{{'led.config.lamp.voltage' |
                            i18n}}</label>

                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <div class="form-group"
                                 x-ng-class="{'has-error': widgetConfigForm.widgetConfigDeviceId.$invalid}">
                                <select class="form-control" data-ng-model="ui.voltageDevice"
                                        x-ng-options="device as device.name for device in allDevicesVoltage">
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                            <div class="form-group"
                                 x-ng-class="{'has-error': widgetConfigForm.widgetConfigDeviceId.$invalid}">
                                <select class="form-control" data-ng-model="ui.voltageParam"
                                        x-ng-options="param as param.name for param in ui.voltageDevice.paramConfigs">
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                                    tooltip-placement="right" uib-tooltip="{{'led.config.lamp.question.voltage' | i18n}}"
                                    tooltip-animation="true"><span class="fa fa-question"/></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="configLampCurrent" class="col-sm-4 control-label">{{'led.config.lamp.current' |
                            i18n}}</label>

                        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                            <div class="form-group"
                                 x-ng-class="{'has-error': widgetConfigForm.widgetConfigDeviceId.$invalid}">
                                <select class="form-control" data-ng-model="ui.currentDevice"
                                        x-ng-options="device as device.name for device in allDevicesCurrent">
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                            <div class="form-group"
                                 x-ng-class="{'has-error': widgetConfigForm.widgetConfigDeviceId.$invalid}">
                                <select class="form-control" data-ng-model="ui.currentParam"
                                        x-ng-options="param as param.name for param in ui.currentDevice.paramConfigs">
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                                    tooltip-placement="right" uib-tooltip="{{'led.config.lamp.question.current' | i18n}}"
                                    tooltip-animation="true"><span class="fa fa-question"/></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="configLampCurrent" class="col-sm-4 control-label">{{'led.config.lamp.dateStart' |
                            i18n}}</label>

                        <div class="col-sm-7">
                            <!--<div class="dropdown">-->
                                <!--<a class="dropdown-toggle" id="dropdownFrom" role="button" data-toggle="dropdown"-->
                                   <!--data-target="#" href="#">-->
                                    <!--<div class="input-group">-->
                                        <!--<input type="text" class="form-control" x-ng-disabled="false"-->
                                               <!--value="{{ui.timeStart | date: 'y-MM-dd'}}"/>-->
												    <!--<span class="input-group-addon">-->
												    	<!--<i class="fa fa-calendar"></i>-->
												    <!--</span>-->

                                    <!--</div>-->
                                <!--</a>-->
                                <!--<ul class="dropdown-menu" role="menu">-->
                                    <!--<datetimepicker class="width-max" data-ng-model="ui.timeStart"-->
                                                    <!--data-datetimepicker-config="{ dropdownSelector: '#dropdownFrom', minView: 'day' }"/>-->
                                <!--</ul>-->
                            <!--</div>-->

                            <div class='input-group date'>
                                <input type='text' class="form-control" x-ng-model="ui.timeStart" x-datetimepicker=""/>
                                <span class="input-group-addon">
                                    <span class="fa fa-calendar"></span>
                                </span>
                            </div>

                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                                    tooltip-placement="right" uib-tooltip="{{'led.config.lamp.question.timeStart' | i18n}}"
                                    tooltip-animation="true"><span class="fa fa-question"/></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="configLampCurrent" class="col-sm-4 control-label">{{'led.config.lamp.dateEnd' |
                            i18n}}</label>

                        <div class="col-sm-7">
                            <!--<div class="dropdown">-->
                                <!--<a class="dropdown-toggle" id="dropdownTo" role="button" data-toggle="dropdown"-->
                                   <!--data-target="#" href="#">-->
                                    <!--<div class="input-group">-->
                                        <!--<input type="text" class="form-control" x-ng-disabled="false"-->
                                               <!--value="{{ui.timeEnd | date: 'y-MM-dd'}}"/>-->
												    <!--<span class="input-group-addon">-->
												    	<!--<i class="fa fa-calendar"></i>-->
												    <!--</span>-->

                                    <!--</div>-->
                                <!--</a>-->
                                <!--<ul class="dropdown-menu" role="menu">-->
                                    <!--<datetimepicker class="width-max" data-ng-model="ui.timeEnd"-->
                                                    <!--data-datetimepicker-config="{ dropdownSelector: '#dropdownTo', minView: 'day' }"/>-->
                                <!--</ul>-->
                            <!--</div>-->

                            <div class='input-group date'>
                                <input type='text' class="form-control" x-ng-model="ui.timeEnd" x-datetimepicker=""/>
                                <span class="input-group-addon">
                                    <span class="fa fa-calendar"></span>
                                </span>
                            </div>

                        </div>
                        <div class="col-sm-1">
                            <button type="button" class="btn btn-primary pull-right" tooltip-trigger="mouseenter"
                                    tooltip-placement="right" uib-tooltip="{{'led.config.lamp.question.timeEnd' | i18n}}"
                                    tooltip-animation="true"><span class="fa fa-question"/></button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label">{{'led.config.lamp.value.time' | i18n}}</label>
                        <label class="col-sm-4 control-label">{{measuredValues.time}}</label>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 col-sm-offset-4">{{'led.config.lamp.value.info' | i18n}}</label>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label">{{'led.config.lamp.value.current' | i18n}}</label>
                        <label data-ng-if="measuredValues.current != -1" class="col-sm-4 control-label">{{measuredValues.current}}</label>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label">{{'led.config.lamp.value.voltage' | i18n}}</label>
                        <label data-ng-if="measuredValues.voltage != -1" class="col-sm-4 control-label">{{measuredValues.voltage}}</label>
                    </div>
                    <div class="form-group">
                        <label class="col-sm-4 control-label">{{'led.config.lamp.value.temperature' | i18n}}</label>
                        <label data-ng-if="measuredValues.temperature != -1" class="col-sm-4 control-label">{{measuredValues.temperature}}</label>
                    </div>
                    <div class="form-group col-sm-8">
                        <button class="btn btn-primary pull-right" data-ng-class="{'disabled' : !dataChanged()}"
                                type="button"
                                data-ng-click="ui.saveLamp()">{{'led.measurements.save' | i18n}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div x-uib-accordion-group="" x-heading="{{'led.config.lamp.inputFiles' | i18n}}"
         x-ng-click="ui.setAccordion('inputFiles');"
         x-is-open="ui.accordionOpts.open.inputFiles"
         x-ng-class="{'accordion-active': ui.accordionOpts.open.inputFiles}" class="panelHeadingTree">
        <div data-ng-if="ui.accordionOpts.open.inputFiles">
            <div x-flow-init="{target: '/lampUpload', singleFile: false, query:lampConfig}"
                 x-flow-files-submitted="$flow.upload()" x-flow-file-success="ui.listFiles()">
                <div style="height:100px" class="file-drop" x-flow-drop="" x-flow-btn=""
                     x-flow-drag-enter="cssClass = 'active'" x-flow-drag-leave="cssClass = null" x-ng-class="cssClass">
								<span class="file-drop-placeholder" style="font-size:24px; line-height:84px">
									{{'led.config.lamp.dropFile' | i18n}}
								</span>
                    <!--<span class="file-drop-preview" x-ng-show="config.backgroundUrl!=null">
                        <img x-ng-src="{{config.backgroundUrl}}"/><br/>
                        <button class="btn btn-danger" x-ng-show="config.backgroundUrl!=null" x-ng-click="ui.removeBackground($event)">Usuń tło</button>
                    </span>-->
                </div>
            </div>
            <div class="panel panel-primary">
                <div class="panel-heading">{{ "led.lamp.files.ldt" | i18n }}</div>
                <table class="table table-striped table-hiver">
                    <tr>
                        <th class="col-xs-1">{{"led.lamp.files.no" | i18n}}</th>
                        <th class="col-xs-9">{{"led.lamp.files.name" | i18n}}</th>
                        <th class="col-xs-1">{{"led.lamp.files.download" | i18n}}</th>
                        <!-- 	<th class="col-xs-1">{{"report.files.delete" | i18n}}</th> -->
                        <th class="col-xs-1"></th>
                    </tr>
                    <tr data-ng-repeat="file in reportFiles | filter:{fileType:'ldt'}">
                        <td>{{$index+1}}</td>
                        <td>{{file.realFilename}}</td>
                        <td>
                            <a target="_blank" href="/reportFile?reportId={{file.id}}" download="{{file.realFilename}}">
                                <i class="fa fa-download center"></i>
                            </a>
                        </td>
                        <td>
                            <button class="btn btn-danger" x-ng-click="ui.deleteFile(file)">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="panel panel-primary">
                <div class="panel-heading">{{ "led.lamp.files.lum" | i18n }}</div>
                <table class="table table-striped table-hiver">
                    <tr>
                        <th class="col-xs-1">{{"led.lamp.files.no" | i18n}}</th>
                        <th class="col-xs-9">{{"led.lamp.files.name" | i18n}}</th>
                        <th class="col-xs-1">{{"led.lamp.files.download" | i18n}}</th>
                        <!-- 	<th class="col-xs-1">{{"report.files.delete" | i18n}}</th> -->
                        <th class="col-xs-1"></th>
                    </tr>
                    <tr data-ng-repeat="file in reportFiles | filter:{fileType:'lum'}">
                        <td>{{$index+1}}</td>
                        <td>{{file.realFilename}}</td>
                        <td>
                            <a target="_blank" href="/reportFile?reportId={{file.id}}" download="{{file.realFilename}}">
                                <i class="fa fa-download"></i>
                            </a>
                        </td>
                        <td>
                            <button class="btn btn-danger" x-ng-click="ui.deleteFile(file)">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="panel panel-primary">
                <div class="panel-heading">{{ "led.lamp.files.txt" | i18n }}</div>
                <table class="table table-striped table-hiver">
                    <tr>
                        <th class="col-xs-1">{{"led.lamp.files.no" | i18n}}</th>
                        <th class="col-xs-9">{{"led.lamp.files.name" | i18n}}</th>
                        <th class="col-xs-1">{{"led.lamp.files.download" | i18n}}</th>
                        <th class="col-xs-1"></th>
                        <!-- 	<th class="col-xs-1">{{"report.files.delete" | i18n}}</th> -->
                    </tr>
                    <tr data-ng-repeat="file in reportFiles | filter:{fileType:'txt'}">
                        <td>{{$index+1}}</td>
                        <td>{{file.realFilename}}</td>
                        <td>
                            <a target="_blank" href="/reportFile?reportId={{file.id}}" download="{{file.realFilename}}">
                                <i class="fa fa-download"></i>
                            </a>
                        </td>
                        <td>
                            <button class="btn btn-danger" x-ng-click="ui.deleteFile(file)">
                                <i class="fa fa-trash-o fa-lg"></i>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <!--<div x-uib-accordion-group="" x-heading="{{'config.lamp.simulationResult' | i18n}}"  x-ng-click="ui.setAccordion('simulationResult');"
    x-is-open="ui.accordionOpts.open.simulationResult" x-ng-class="{'accordion-active': ui.accordionOpts.open.simulationResult}" class="panelHeadingTree">
        <div>
            Wykres i przycisk do pobrania LDT
        </div>
    </div>-->
</div>
		
<div x-ng-if="dashboard">
    <!-- Dirty -->
    <div class="alert alert-info" role="alert" x-ng-if="editMode">
        <div class="row">
            <div class="col-xs-12">
                <i class="fa fa-comment-o fa-5x fa-fw pull-left"></i>

                <p>{{::'config.message.licenseCount' | i18n}}</p>

                <div class="progress">
                    <div class="progress-bar"
                         role="progressbar"
                         aria-valuenow="{{ui.license.credits}}"
                         aria-valuemin="0"
                         aria-valuemax="{{ui.license.maxCredits}}"
                         x-ng-class="{'progress-bar-danger': ui.license.alert}"
                         x-ng-style="{'width': ui.license.procent}">
                        {{ui.license.credits}}/{{ui.license.maxCredits}}&nbsp;{{'config.license.credits' | i18n}}
                    </div>
                </div>
                <div class="error-container" x-ng-show="ui.license.alert">
                    <small class="error"> {{'config.license.overload' | i18n}}</small>
                </div>
                <div x-ng-show="dirty">
                    <p class="text-danger">{{'config.message.formIsDirty' | i18n}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Dashboard -->
    <div x-adf-dashboard="" x-adf-model="dashboard" x-editable="{{ui.isEditable()}}"></div>
</div>
<div class="panel panel-default config-tree"
     x-ng-repeat="branch in root.children"
     x-ng-class="{'opened': branch.opened, 'active': branch.active}"
     x-ng-if="branch.visible && ( branch.basic || ui.advanceView )"
    style="border-left-width: 0px; border-bottom-width: 0px; box-shadow: 0 0px 0px;">
    <div class="panel-heading" x-ng-click="ui.select(branch)" style="
         border-left-width: 1px;
         border-left-color: rgb(221, 221, 221);
         border-left-style: solid;
         border-bottom-width: 1px;
         border-bottom-color: rgb(221, 221, 221);
         border-bottom-style: solid;">
        <!--<div class="pull-right" x-ng-show="branch.addable">
            <button class="btn btn-xs btn-success" x-ng-click="ui.view='add'; $type.stopPropagation();"><i class="fa fa-fw fa-trash"></i></button>
        </div>-->
        <div class="panel-title" style="cursor: pointer;">
            <span>
                <i class="fa fa-fw" x-ng-class="branch.icon()"></i>&nbsp;{{branch.name | i18n}}
                <span class="label label-danger" x-ng-show="ui.hasErrors(branch)">{{ui.errorCount(branch)}}</span>
            </span>
        </div>
    </div>
    <div class="panel-collapse collapse" x-ng-class="{'in': branch.opened}">
        <div class="panel-body list-group">
            <!--x-ng-style="{'padding-left': item.getPaddingLeft()}"-->
            <a class="list-group-item menu-item"
                x-ng-if="item.visible"
                x-ng-style="{'margin-left': item.getPaddingLeft()}"
                x-ng-class="{'active': item.active, 'visible': item.visible}"
                x-ng-repeat="item in branch.items()">
<!--                <div class="config-tree-label">-->
<!--                    <div class="config-tree-row">-->
<!--                        <div class="config-tree-cell config-tree-cell-icon">-->
<!--                            <i class="toggle fa fa-fw" x-ng-class="item.getToggleIcon()" x-ng-click="item.toggle(); $event.stopPropagation();"></i>-->
<!--                        </div>-->
<!--                        <div class="config-tree-cell config-tree-cell-icon">-->
<!--                            <i class="fa fa-fw" x-ng-class="item.icon()"></i>-->
<!--                        </div>-->
<!--                        <div class="config-tree-cell item-label" data-toggle="tooltip" data-placement="right" title="{{item.name}}">-->
<!--                            {{item.name}}-->
<!--                        </div>-->
<!--                        <div class="config-tree-cell config-tree-cell-icon label label-danger" x-ng-show="ui.hasErrors(item)">-->
<!--                            {{ui.errorCount(item)}}-->
<!--                        </div>-->
<!--                        <div class="config-tree-cell config-tree-cell-icon config-tree-options pull-right">-->
<!--                            <button class="btn btn-xs btn-danger" x-ng-show="item.removable" x-ng-click="ui.remove(item)"><i class="fa fa-trash"></i></button>-->
<!--                            &lt;!&ndash;<button class="btn btn-xs btn-default"><i class="fa fa-refresh"></i></button>-->
<!--                            <span class="label label-status label-success" x-ng-mouseenter="ui.showStatus(item, $type)" x-ng-mouseleave="ui.hideStatus()">-->
<!--                                <i class="fa fa-check"></i>-->
<!--                            </span>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="config-tree-cell config-tree-cell-icon config-tree-options pull-right" ng-if="item.kind === 'device'" x-ng-click="item.config.enabled=!item.config.enabled" style="margin-right: 5px;">-->
<!--                            <i class="fa fa-lg fa-stop-circle-o" x-ng-show="!item.config.enabled"></i>-->
<!--                            <i class="fa fa-lg fa-play-circle-o" x-ng-show="item.config.enabled"></i>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->


                <table class="config-tree-label">
                    <colgroup>
                        <col width="0%" />
                        <col width="0%" />
                        <col width="100%" />
                        <col width="0%" />
                        <col width="0%" />
                    </colgroup>
                    <tr>
                        <td style="white-space: nowrap;" x-ng-click="ui.select(item)">
                            <i class="toggle fa fa-fw" x-ng-class="item.getToggleIcon()" x-ng-click="item.toggle(); $event.stopPropagation();"></i>
                        </td>
                        <td style="white-space: nowrap;" x-ng-click="ui.select(item)">
                            <i class="fa fa-fw" x-ng-class="item.icon()"></i>
                        </td>
                        <td style="white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px;" x-ng-click="ui.select(item)">
                            {{item.name}}
                        </td>
                        <td style="white-space: nowrap;" x-ng-click="ui.select(item)">
                            <span class="config-tree-cell config-tree-cell-icon label label-danger" x-ng-show="ui.hasErrors(item)">
                                {{ui.errorCount(item)}}
                            </span>
                        </td>
                        <td style="white-space: nowrap;" x-ng-click="ui.select(item)">
                            <span class="config-tree-cell config-tree-cell-icon config-tree-options" x-ng-if="item.kind === 'device'" x-ng-click="ui.enable(item)" style="margin-right: 5px;">
                                <i class="fa fa-lg fa-stop-circle-o" x-ng-show="!item.config.enabled"></i>
                                <i class="fa fa-lg fa-play-circle-o" x-ng-show="item.config.enabled"></i>
                            </span>
                        </td>
<!--                        <td style="white-space: nowrap;">-->
<!--                            <span class="config-tree-cell config-tree-cell-icon config-tree-options">-->
<!--                                <button class="btn btn-xs btn-danger" x-ng-show="item.removable" x-ng-click="ui.remove(item)"><i class="fa fa-trash"></i></button>-->
<!--                                &lt;!&ndash;<button class="btn btn-xs btn-default"><i class="fa fa-refresh"></i></button>-->
<!--                                <span class="label label-status label-success" x-ng-mouseenter="ui.showStatus(item, $type)" x-ng-mouseleave="ui.hideStatus()">-->
<!--                                    <i class="fa fa-check"></i>-->
<!--                                </span>&ndash;&gt;-->
<!--                            </span>-->
<!--                        </td>-->
                    </tr>
                </table>

            </a>
        </div>
    </div>
</div>
<div class="btn-group col-xs-12" role="group" aria-label="..." style="padding:0px">
    <button type="button" style="border-bottom-left-radius: 0px;" class="btn btn-primary col-xs-3 col-sm-2 col-ms-1" x-ng-click="ui.advanceViewControl()">
        <i class="fa fa-check-square-o" aria-hidden="true" x-ng-if="ui.advanceView"></i>
        <i class="fa fa-square-o" aria-hidden="true" x-ng-if="!ui.advanceView"></i>
    </button>
    <button type="button" style="border-bottom-right-radius: 0px;" class="btn btn-default col-xs-9 col-sm-10 col-ms-11" x-ng-click="ui.advanceViewControl()">
        {{'config.advancedView' | i18n}}
    </button>
</div>
<div style="margin-top: -1px; padding:0px" class="btn-group col-xs-12" role="group" aria-label="...">
    <button style="border-top-left-radius: 0px;" type="button" class="btn btn-primary col-xs-3 col-sm-2 col-ms-1" x-ng-click="ui.fluidViewControl()">
        <i class="fa fa-check-square-o" aria-hidden="true" x-ng-if="ui.fluidView"></i>
        <i class="fa fa-square-o" aria-hidden="true" x-ng-if="!ui.fluidView"></i>
    </button>
    <button type="button" style="border-top-right-radius: 0px;" class="btn btn-default col-xs-9 col-sm-10 col-ms-11" x-ng-click="ui.fluidViewControl()">
        {{'config.fluidView' | i18n}}
    </button>
</div>

var module = angular.module('meternet.chart.directives.currentValues', ['meternet.charts.services']);

module.directive('currentValues', function (paramService) {
    return {
        scope: {
            options: '=',
            groups: '='
        },
        templateUrl: 'dashboard/calendar/current-values.html',
        controller: function ($scope) {

            $scope.params = [];

            var quantities = {
                'active_energy': true
            };

            function prepareParams(newParams) {
                $scope.params.length = 0;
                _.forEach(newParams, function (group) {
                    _.forEach(group.params, function (param) {
                        if (quantities[param.quantity]) {
                            $scope.params.push(param);
                        }
                    });
                });
            }

            $scope.$watch('groups', function (newVal) {
                prepareParams(newVal);
                paramService.unsubscribeUnchecked($scope.params);
                paramService.subscribeParams($scope.params);
            })
            $scope.$on('$destroy', function() {
                paramService.unsubscribeUnchecked([]);
            });
        }
    }
});

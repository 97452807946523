/*global tools */
var app = angular.module('meternet.user.services', []);
	
function UserService($q, $http, $log, contextPath, principal, dataService) {

		this.contextPath = contextPath;
		
	
		function User(data){
			this.update(data);
		}
		
		User.prototype.update = function(data){
			tools.copy(data, this);
            this.isAdmin();
		};
		
		User.prototype.isAdmin = function(){
			if (!angular.isDefined(this.admin)){
				var admin = _.find(this.authorities, function(role){
					if (role === 'admin'){
						return true;
					}
					return false;
				});
				this.admin = angular.isDefined(admin);
			}	
			return this.admin;
		};
		
		var user = new User(principal);
		
		// API
		return {		
			getUser : function(){
				return user;
			},
			changePassword : function(user){
				var deferred = $q.defer();
				$http.post('/u/a/setPassword', user).then(function(response){
					user.update(response.data);
					deferred.resolve(user);
				},
				function(response){
					deferred.reject(response);
				});
				return deferred.promise;
			},
			update : function(user){
				var deferred = $q.defer();
				$http.put('/u/a/', user).then(function(response){
					user.update(response.data);
					deferred.resolve(user);
				},
				function(response){
					deferred.reject(response);
				});
				return deferred.promise;
			},
			create : function(data){
				var deferred = $q.defer();
				var user = new User(data);
				$http.post('/u/a/', user).then(function(response){
					user.update(response.data);
					deferred.resolve(user);
				},
				function(response){
					deferred.reject(response);
				});
				return deferred.promise;
			},
			"delete": function(user) {
				var deferred = $q.defer();
				$http["delete"]('/u/a/'+user.username).then(function(response){
					deferred.resolve();
				},
				function(response){
					deferred.reject(response);
				});
				return deferred.promise;
			},
            addGroup: function(group) {
                var deferred = $q.defer();
                $http.post('/u/cg/', group).then(function(response){
                        deferred.resolve(response);
                    },
                    function(response){
                        deferred.reject(response);
                    });
                return deferred.promise;
            },
            deleteGroup: function(group) {
                var deferred = $q.defer();
                $http.post('/u/dg/', group).then(function(response){
                        deferred.resolve(response);
                    },
                    function(response){
                        deferred.reject(response);
                    });
                return deferred.promise;
            },
            updateGroup: function(group) {
                var deferred = $q.defer();
                $http.post('/u/ug/', group).then(function(response){
                        deferred.resolve(response);
                    },
                    function(response){
                        deferred.reject(response);
                    });
                return deferred.promise;
            },
            updateUserGroupDetails: function(user, groups) {
                user.groupsWithDetails = [];
                _.each(user.groups, function(groupId){
                    var result = _.find(groups, function(group){
                        if (group.id===groupId) {
                            return true;
                        }
                        return false;
                    });
                    if (result) {
                        user.groupsWithDetails.push(result);
                    }
                });
            },
            load: function(){
                var deferred = $q.defer();
                dataService.requestUserData().then(
                    function(data) {
                        var securityDto = {
                            "users":data.users,
                            "groups": data.groups
                        };
                        _.each(securityDto.users, function(user, index, list) {
                            list[index] = new User(user);
                            _.each(user.groups, function(groupId){
                                var result = _.find(securityDto.groups, function(group){
                                    if (group.id===groupId) {
                                        return true;
                                    }
                                    return false;
                                });
                                if (result) {
                                    user.groupsWithDetails = user.groupsWithDetails || [];
                                    user.groupsWithDetails.push(result);
                                }
                            });
                        });
                        deferred.resolve(securityDto);
                    });
                return deferred.promise;
            },
            calculateEffectivePermissions: function(dto) {
                var deferred = $q.defer();
                $http.post('/u/cp/', dto).then(function(response){
                        deferred.resolve(response);
                    },
                    function(response){
                        deferred.reject(response);
                    });
                return deferred.promise;
            },
            canEditDashboard: function(dashboardId) {
                if (user.isAdmin()) {
                    return true;
                }
                if (user.permissions) {
                    for (var i = 0; i < user.permissions.dashboardsAllowWrite.length; ++i) {
                        if (user.permissions.dashboardsAllowWrite[i] === dashboardId) {
                            return true;
                        }
                    }
                }
                return false;
            }
				
		};	
}
app.service('userService', UserService);
<div class="row">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="series in config.series">
                <form class="form form-inline" name="seriesConfigForm-{{$index}}">
                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <div style="width: 15px; cursor: pointer;">
                            <i class="fa fa-lg" x-ng-click="series._details = !series._details"
                               x-ng-class="series._details ? 'fa-minus' : 'fa-plus'"></i>
                        </div>
                    </div>

                    <div class="form-group" style="width: 130px; min-width: 130px;">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
                        <ui-select ng-model="series._device" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam(series)" style="width: 130px; min-width: 130px;" >
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                                {{$select.selected.label || $select.selected.name}}
                            </ui-select-match>
                            <ui-select-choices repeat="device in devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}" style="width: 300px; min-width: 300px;">
                                <div ng-bind-html="((device.label||device.name)) | highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>


                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
                        <ui-select ng-model="series._param" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam(series)" style="width: 130px; min-width: 130px;" >
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                                {{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
                            </ui-select-match>
                            <ui-select-choices repeat="param in series._device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}" style="width: 300px; min-width: 300px;">
                                <div ng-bind-html="((param.label||param.name) + ' [' + param.unit + ']'  )| highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.name"></label>
                        <div class="input-group" style="width: 145px">
                            <input type="text" class="form-control"  aria-label="..."
                                   x-ng-model="series.name"
                                   x-ng-change="ui.updateAxisIndices()"
                                   name="widgetsConfigSeriesName-{{$index}}">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="caret"></span></button>
                                <ul class="dropdown-menu dropdown-menu-right" x-ng-if="series._device" style="width: 300px; min-width: 300px;">
                                    <li><a href="#" x-ng-click="series.name = (series._device.label||series._device.name)+'.'+(series._param.label||series._param.name)+$index">{{series._device.label||series._device.name}}.{{series._param.label||series._param.name}}</a></li>
                                    <li role="separator" class="divider"></li>
                                    <li><a href="#" x-ng-click="series.name = (series._param.label||series._param.name)">{{series._param.label||series._param.name}}</a></li>
                                    <li><a href="#" x-ng-click="series.name = ('quantity.'+series._param.quantity|i18n)+' '+$index">{{'quantity.'+series._param.quantity|i18n}} {{$index}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <button class="btn btn-danger" x-ng-click="ui.removeSerie($index);"
                                data-ng-class="ui.isSingleSerie()"
                                title="{{'ui.delete' | i18n}}">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </button>
                    </div>

                    <div x-ng-show="series._details" style="margin-left: 18px;">
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.series.onname"></label>
                            <input class="form-control" style="width: 145px" x-ng-model="series.onName"
                                   name="widgetsConfigSeriesName-{{$index}}"/>
                        </div>
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.series.offname"></label>
                            <input class="form-indicator" style="width: 145px" x-ng-model="series.offName"
                                   name="widgetsConfigSeriesName-{{$index}}"/>
                        </div>
                    </div>
                </form>
            </li>
            <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                <div class="pull-right">
                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                        {{'ui.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="clearfix"></div>
            </li>
        </ul>
    </div>
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.timespan' | i18n}}</div>
        <div class="panel-body">
            <div class="form-group">
                <label class="form-group-label" x-label-help="dashboard.widgets.config.layout"></label>
                <select class="form-control" name="widgetsConfigMode" data-ng-model="config.layout" style="width: 205px"
                        ng-options="o.v as (o.n | i18n) for o in [{ n:'dashboard.widgets.config.layout.vertical', v: false }, { n:'dashboard.widgets.config.layout.horizontal', v: true }]">
                </select>
            </div>
        </div>
    </div>
</div>

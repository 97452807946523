<div class="pull-left" style="margin-right: 10px;">
    <div class="form-group">
        <label class="form-group-label">okres</label>
        <select class="form-control" style="width: 80px;"
                x-ng-model="ui.period"
                x-ng-options="p as p.label for p in ui.getPeriods()"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>

    <div class="form-group">
        <label class="form-group-label">co ile</label>
        <select class="form-control" style="width: 50px;"
                x-ng-model="ui.step"
                x-ng-options="s as s.label for s in ui.period.steps"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>
</div>

<div class="pull-left">
    <div class="form-group" x-ng-show="ui.show('M')">
        <label class="form-group-label">miesiąc</label>
        <select class="form-control" style="width: 100px;"
                x-ng-model="ui.value[4]"
                x-ng-options="m as m.label for m in ui.values[4]"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>

    <div class="form-group" x-ng-show="ui.show('w')">
        <label class="form-group-label">dzień</label>
        <select class="form-control" style="width: 100px;"
                x-ng-model="ui.value[5]"
                x-ng-options="w as w.label for w in ui.values[5]"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>

    <div class="form-group" x-ng-show="ui.show('d')">
        <label class="form-group-label">dzień</label>
        <select class="form-control" style="width: 70px;"
                x-ng-model="ui.value[3]"
                x-ng-options="d as d.label for d in ui.values[3]"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>

    <div class="form-group" x-ng-show="ui.show('h')">
        <label class="form-group-label">godz</label>
        <select class="form-control" style="width: 50px;"
                x-ng-model="ui.value[2]"
                x-ng-options="h as h.label for h in ui.values[2]"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>

    <div class="form-group" x-ng-show="ui.show('m')">
        <label class="form-group-label">min</label>
        <select class="form-control" style="width: 50px;"
                x-ng-model="ui.value[1]"
                x-ng-options="m as m.label for m in ui.values[1]"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>

    <div class="form-group" x-ng-show="ui.show('s')">
        <label class="form-group-label">sek</label>
        <select class="form-control" style="width: 50px;"
                x-ng-model="ui.value[0]"
                x-ng-options="s as s.label for s in ui.values[0]"
                x-ng-change="ui.updateCronExpression()"></select>
    </div>
</div>
/* global saveAs */
var module = angular.module('meternet.csv.services', []);

var FIELD_SEPARATOR = ';';

var LINE_SEPARATOR = "\n";

function Csv() {
    this.buffer = [];
    this.buffer.push("\uFEFF");
//    this.buffer.push("sep=,")
//    this.buffer.push(LINE_SEPARATOR);
}

Csv.prototype.field = function(value) {
    if (value == null) {
        this.buffer.push("");
    } else {
        this.buffer.push("\"" + value + "\"");
    }
    this.buffer.push(FIELD_SEPARATOR);
    return this;
};

Csv.prototype.endLine = function() {
    this.buffer.push(LINE_SEPARATOR);
    return this;
}; 

Csv.prototype.download = function(filename) {
    var blob = new Blob(this.buffer, {
        type : 'data:text/csv;charset=utf-8'
    });
    saveAs(blob, filename);
};

module.service('csvService', function() {
    return {
        csv : function() {
            return new Csv();
        }
    };
});

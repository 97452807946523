var module = angular.module('meternet.readout.services', []);

module.service('readoutService', function ($q, $http, $log, contextPath, $rootScope, $timeout, dataService) {

	// API
	return {
		start: function () {
			var deferred = $q.defer();
			var startUrl = contextPath + '/control/start';
			$http({
				method: 'GET',
				url: startUrl
			}).success(function (data, status, headers, config) {
				deferred.resolve(true);
			});
			return deferred.promise;
		},
		stop: function () {
			var deferred = $q.defer();
			var stopUrl = contextPath + '/control/stop';
			$http({
				method: 'GET',
				url: stopUrl
			}).success(function (data, status, headers, config) {
				deferred.resolve(true);
			});
			return deferred.promise;
		},
		oneShoot: function () {
			var deferred = $q.defer();
			var stopUrl = contextPath + '/control/shoot';
			$http({
				method: 'GET',
				url: stopUrl
			}).success(function (data, status, headers, config) {
				deferred.resolve(true);
			});
			return deferred.promise;
		},
		isRunning: function () {
            var deferred = $q.defer();
            var statusUrl = contextPath + '/control/status';
            $http({
                method: 'GET',
                url: statusUrl
            }).success(function (data, status, headers, config) {
                deferred.resolve(data);
            });
            return deferred.promise;
        }
	};
});


angular.module('meternet.utils.custom-time-picker', [])
    .directive('customTimePicker', function() {
        return {
            templateUrl: 'utils/custom-time-picker.html',
            controller: 'CustomTimePickerController',
            scope: {
                value: '=ngModel',
                label: '=',
                onlyHoursType: '=',
                format: '=',
                icon: '='
            }
        };
    })
    .controller('CustomTimePickerController', ['$scope', function($scope) {
        $scope.value.dir = $scope.value.dir || 'add';
        $scope.format = $scope.format || {format: 'HH:mm:ss'};
        $scope.icon = $scope.icon || "fa fa-clock-o";
        $scope.ui = {
            toggleDir : function () {
                $scope.value.dir === 'sub' ? $scope.value.dir = 'none' : $scope.value.dir === 'add' ? $scope.value.dir = 'sub' : $scope.value.dir = 'add';
            },
            getIcon: function () {
                var icon;
                $scope.value.dir === 'sub' ? icon = 'fa fa-minus' : $scope.value.dir === 'add' ? icon = 'fa fa-plus' : icon = 'fa fa-ban';
                return icon;
            }
        }
    }]);

<form class="form-horizontal" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-errors=""
                           x-field-help="config.notifier.name.help"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-errors=""
                           x-field-help="config.notifier.label.help" x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.notifier.desc.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div x-ng-if="item.config.type === 'email'">
            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.notifier.host' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" x-ng-model="item.config.host" x-field-errors=""
                               x-field-help="config.notifier.host.help"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.port' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" x-ng-model="item.config.port" x-field-errors=""
                               x-field-help="config.notifier.port.help"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="col-sm-offset-3 col-sm-7">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" x-ng-model="item.config.ssl" x-field-errors=""/>
                            {{'config.ssl' | i18n}}
                        </label>
                    </div>
                </div>
                <div class="col-sm-1">
                    <button type="button" uib-popover="{{'config.notifier.ssl.help' | i18n}}"
                            popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
                    </button>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" x-ng-model="item.config.username" x-field-errors=""
                               x-field-help="config.notifier.username.help"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input type="password" class="form-control" x-ng-model="item.config.password" x-field-errors=""
                               x-field-help="config.notifier.password.help"/>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.notifier.email.from' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" x-ng-model="item.config.from" x-field-errors=""
                               x-field-help="config.notifier.email.help"/>
                    </div>
                </div>
            </div>
            <hr>
            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.notifier.email.test' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input type="text" class="form-control"
                               placeholder="{{'config.notifier.email.emailfortest' | i18n}}"
                               x-ng-model="item.config.to">
						<span class="input-group-btn">
                            <button class="btn btn-success" type="button"
                                    x-ng-click="sendTestMail(item.config.host, item.config.port, item.config.ssl, item.config.username, item.config.password, item.config.from, item.config.to)">
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                {{'config.notifier.email.maketest' | i18n}}
                            </button>
						</span>
                    </div>
                </div>
            </div>
            <div class="alert alert-success" role="alert" x-ng-if="mailOk">
                {{msg}}
            </div>
            <div class="alert alert-danger" role="alert" x-ng-if="mailErr">
                {{msg}}
            </div>
        </div>

		<div x-ng-if="item.config.type === 'sms'">

			<div class="form-group">
				<div class="col-sm-offset-3 col-sm-8">
					<div class="checkbox">
						<label>
							<input type="checkbox" x-ng-model="item.config.cronEnabled" x-field-errors="">
							{{'config.notifier.sms.schedule' | i18n}}
						</label>
					</div>
				</div>
			</div>

			<div class="form-group" x-ng-if="item.config.cronEnabled">
				<label class="control-label col-sm-3" style="margin-top: 16px;">{{'config.notifier.sms.cronExpression' | i18n}}</label>
				<div class="col-sm-8" x-config-form-cron-expression="item.config.cronExpression"
					 x-seconds-enable="false"
					 x-minutes-enable="true"></div>
			</div>

			<div class="form-group" x-ng-if="item.config.cronEnabled">
				<label class="control-label col-sm-3">{{'config.notifier.sms.destination' | i18n}}</label>
				<div class="col-sm-8">
					<div class="input-group">
						<input type="text" class="form-control"
							   x-ng-model="item.config.cronDest"
							   x-field-help="config.notifier.cronDest.help">
					</div>
				</div>
			</div>

			<hr>

			<div class="form-group">
				<label class="control-label col-sm-3">{{'config.notifier.sms.test' | i18n}}</label>
				<div class="col-sm-8">
					<div class="input-group">
						<input type="text" class="form-control"
							   placeholder="{{'config.notifier.sms.destination' | i18n}}"
							   x-ng-model="item.config.dest">
						<span class="input-group-btn">
							<button class="btn btn-success" type="button"
									x-ng-click="sendTestSms(item.config.dest)">
								<i class="fa fa-paper-plane" aria-hidden="true"></i>
								{{'config.notifier.sms.maketest' | i18n}}
							</button>
						</span>
					</div>
				</div>
			</div>
			<div class="alert alert-success" role="alert" x-ng-if="mailOk" style="white-space: pre-line;">{{msg}}</div>
			<div class="alert alert-danger" role="alert" x-ng-if="mailErr" style="white-space: pre-line;">{{msg}}</div>
		</div>
    </div>
    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.notifier.type.' + item.config.type | i18n}}
                </span>
				<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{'/resources/info/notifier/datasheets/' + item.config.type + '.pdf'}}">
					{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
				</a>
            </div>
        </div>
    </div>
</form>

<form class="form-horizontal" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-help="config.input.name.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-help="config.input.label.help"
                           x-field-errors="" x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.input.desc.help"
                           x-field-errors=""></input>
                </div>
            </div>
        </div>


        <div x-ng-switch="item.config.type">
        </div>

        <!--<div class="form-group" x-ng-if="advanceView.value">-->
        <!--<label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>-->
        <!--<div class="col-sm-8">-->
        <!--<div class="input-group">-->
        <!--<input class="form-control" x-ng-model="item.config.enqueuedMeasurementsWarningLevel" x-field-help="config.input.enqueuedMeasurementsWarningLevel.help" x-field-errors="" />-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="form-group" x-ng-if="advanceView.value">-->
        <!--<label class="control-label col-sm-3">{{'config.measurementOutputs' | i18n}}</label>-->
        <!--<div class="col-sm-5">-->
        <!--<select class="form-control"-->
        <!--x-ng-model="item.config.outputIds"-->
        <!--x-ng-options="o.id as o.name for o in config.engine.measurementOutputs"-->
        <!--x-field-errors=""-->
        <!--x-multi-select=""-->
        <!--multiple="multiple"></select>-->
        <!--</div>-->
        <!--<div class="col-sm-2">-->
        <!--<button class="btn btn-default" style="width: 100%;" x-ng-click="ui.restoreDefaultRules()">-->
        <!--<i class="fa fa-chain"></i>&nbsp;{{'config.measurementOutputs.set' | i18n}}-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="col-sm-1">-->
        <!--<button type="button" uib-popover="{{'config.input.measurementOutputs.help' | i18n}}" popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?</button>-->
        <!--</div>-->
        <!--</div>-->

        <div class="form-group" x-ng-if="item.config.cronExpression !== undefined && item.config.type=='math'">
            <label class="control-label col-sm-3" style="margin-top: 16px;">{{'config.device.math.cronExpression' |
                i18n}}</label>
            <div class="col-sm-8" x-config-form-cron-expression="item.config.cronExpression" x-seconds-enable="false"
                 x-minutes-enable="true"></div>
        </div>
    </div>

    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.measurementInput.type.' + ui.type.type | i18n}}</span>
                </span>
				<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{ui.type.datasheetUrl}}"
				   x-ng-show="ui.type.datasheetUrl">
					{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
				</a>
            </div>
        </div>
    </div>
</form>

<form class="form-inline"
      x-ng-if="advanceView.value && (item.config.type!='prepaid' && item.config.type!='alarm' && item.config.type!='control')">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title">
                <i class="fa fa-fw fa-filter"></i>&nbsp;{{'config.momentRules.simple' | i18n}}
            </div>
        </div>
        <div>
            <table class="table table-bordered table-condensed" style="margin-bottom: 0px;">
                <thead>
                <tr>
                    <th>{{'config.momentRules.outputName' | i18n}}</th>
                    <th>{{'config.momentRules.default' | i18n}}</th>
                    <th>{{'config.momentRules.activateAll' | i18n}}</th>
                    <th>{{'config.momentRules.deactivateAll' | i18n}}</th>
                </tr>
                </thead>
                <tbody>
                <tr x-ng-repeat="o in config.engine.measurementOutputs | orderBy : 'name'">
                    <td style="vertical-align: middle;" class="col-xs-2">
                        {{o.label||o.name}}
                    </td>
                    <td style="text-align: center;" x-ng-if="ui.isOutputActive(o)" class="col-xs-1"
                        x-ng-click="ui.deactivateOutput(o)">
                        <i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
                    </td>
                    <td style="text-align: center;" x-ng-if="!ui.isOutputActive(o)" class="col-xs-1"
                        x-ng-click="ui.activateOutput(o)">
                        <i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
                    </td>
                    <td class="col-xs-2">
                        <button class="btn btn-xs btn-justborder btn-success" style="width: 100%;"
                                x-ng-click="ui.activateOutputInInput(o)">
                            <i class="fa fa-plus"></i>&nbsp;{{'config.momentRules.activateAll' | i18n}}
                        </button>
                    </td>
                    <td class="col-xs-2">
                        <button class="btn btn-xs btn-justborder btn-danger" style="width: 100%;"
                                x-ng-click="ui.deactivateOutputInInput(o)">
                            <i class="fa fa-times"></i>&nbsp;{{'config.momentRules.deactivateAll' | i18n}}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div x-ng-repeat="device in item.config.devices">
            <table style="width:100%">
                <tr>
                    <td style="vertical-align: middle;" x-ng-style="{'background-color: #f5f5f5;': device.open }">
                        <div class="list-group-item menu-item" x-ng-click="device.open=!device.open"
                             style="height:40px; border-radius: 0px; line-height: 30px;">
                            <i class="fa fa-fw fa-lg fa-angle-right" x-ng-if="!device.open"></i>
                            <i class="fa fa-fw fa-lg fa-angle-down" x-ng-if="device.open"></i>
                            <i class="fa fa-fw fa-lg fa-cube"></i>
                            {{device.label||device.name}}
                        </div>
                        <div class="row" x-ng-if="device.open" x-ng-repeat="param in device.params">
                            <div class="col-xs-offset-1 col-xs-11" x-config-form-moment-rule-simple="param.momentRules"
                                 x-config="config" x-hide="$index==0"
                                 x-param-name="param.label? param.label : param.name"></div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</form>


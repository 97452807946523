var module = angular.module("meternet.report.energy.EnergyReportConfigDirective", []);
module.controller("energyReportConfigCtrl", function($scope, $uibModalInstance, config) {
    $scope.config = config;

    $scope.ui = {
        save : function() {
            $uibModalInstance.close();
        },
        cancel : function() {
            $uibModalInstance.dismiss();
        }
    };
});

module.directive("energyReportConfig", function($locale, $filter, contextPath, Weekdays, configService) {
    return {
        scope : {
            config : "=energyReportConfig"
        },
        templateUrl: 'report/energy/energy-report-config.html',
        link : function(scope, elem, attr, ctrl) {
            var i, j;
            scope.ui = {
                period : null,
                day : null,
                weekday : null,
                hour : null,
                hstep : null,
                periods : [ {
                    value : 0,
                    label : "Godzinowy"
                }, {
                    value : 1,
                    label : "Dzienny"
                }, {
                    value : 2,
                    label : "Tygodniowy"
                }, {
                    value : 3,
                    label : "Miesięczny"
                } ],
                days : [],
                weekdays : Weekdays,
                hours : [],
                hsteps : [],
                columns : [ 1, 2, 3, 4, 5, 6 ],
                parseCronExpression : function() {
                    this.period = this.periods[3];
                    this.hour = this.hours[0];
                    this.hstep = this.hsteps[7];
                    this.day = this.days[0];
                    this.weekday = this.weekdays[0];

                    if (scope.config && scope.config.cronExpression) {
                        var e = scope.config.cronExpression.split(/\s+/);
                        if (e.length === 6) {
                            var h = parseInt(e[2]);
                            var hs = e[2].indexOf("/") > 0 ? parseInt(e[2].substr(e[2].indexOf("/") + 1)) : null;

                            this.hour = _.find(this.hours, function(hour) {
                                return hour.h === h;
                            });

                            if (e[3] === "?") {
                                var w = parseInt(e[5]);
                                this.period = this.periods[2];
                                this.weekday = _.find(this.weekdays, function(weekday) {
                                    return weekday.value === w;
                                });
                            } else if (e[3] !== "*") {
                                var d = e[3];
                                this.period = this.periods[3];
                                this.day = _.find(this.days, function(day) {
                                    return day.value === d;
                                });
                            } else if (hs !== null) {
                                this.period = this.periods[0];
                                this.hstep = _.find(this.hsteps, function(hstep) {
                                    return hstep.value === hs;
                                });
                            } else {
                                this.period = this.periods[1];
                            }
                        }
                    }
                },
                updateCronExpression : function() {
                    var expr;
                    if (this.period === this.periods[0]) {
                        expr = "0 0 " + this.hour.h + "/" + this.hstep.value + " * * ?";
                    } else if (this.period === this.periods[1]) {
                        expr = "0 0 " + this.hour.h + " * * ?";
                    } else if (this.period === this.periods[2]) {
                        expr = "0 0 " + this.hour.h + " ? * " + this.weekday.value;
                    } else if (this.period === this.periods[3]) {
                        expr = "0 0 " + this.hour.h + " " + this.day.value + " * ?";
                    }
                    scope.config.cronExpression = expr;
                },
                scales : [ {
                    value : -9,
                    label : "p"
                }, {
                    value : -6,
                    label : "m"
                }, {
                    value : -6,
                    label : "µ"
                }, {
                    value : 0,
                    label : "-"
                }, {
                    value : 3,
                    label : "k"
                }, {
                    value : 6,
                    label : "M"
                }, {
                    value : 9,
                    label : "G"
                } ],
                precisions : [ 0, 1, 2, 3, 4, 5, 6 ],
                canAddSeries : function() {
                    return scope.config.series.length < 100;
                },
                addSeries : function() {
                    scope.config.series.push({
                        deviceId : null,
                        paramId : null,
                        name : null,
                        quantity : null,
                        unit : null,
                        scale : 0,
                        precision : 0
                    });
                },
                removeSeries : function(series) {
                    var idx = scope.config.series.indexOf(series);
                    if (idx >= 0) {
                        scope.config.series.splice(idx, 1);
                    }
                },
                updateSeriesParam : function(series) {
                    if (series._device && series._param) {
                        series.deviceId = series._device.id;
                        series.paramId = series._param.id;
                                
                        var deviceName = (series._device.label||series._device.name);
                        var paramName = (series._param.label||series._param.name);
                        series.name = deviceName + '.' + paramName;

                        series.quantity = series._param.quantity;
                        series.unit = series._param.type.unit;
                        series.descr1 = series._device.descr1;
                        series.descr2 = series._device.descr2;
                        series.descr3 = series._device.descr3;
                    } else {
                        series.deviceId = null;
                        series.paramId = null;
                        series.name = null;
                        series.quantity = null;
                        series.unit = null;
                        series.descr1 = null;
                        series.descr2 = null;
                        series.descr3 = null;
                    }
                }
            };

            for (i = 1; i < 29; ++i) {
                scope.ui.days.push({
                    value : "" + i,
                    label : "" + i
                });
            }
            scope.ui.days.push({
                value : "L",
                label : $filter('i18n')('ui.cron.last')
            });

            for (i = 0; i < 24; ++i) {
                for (j = 0; j < 60; j += 60) {
                    scope.ui.hours.push({
                        h : i,
                        m : j,
                        label : (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j)
                    });
                }
            }

            for (i = 1; i < 24; ++i) {
                scope.ui.hsteps.push({
                    value : i,
                    label : "" + i
                });
            }

            scope.ui.parseCronExpression();
            if (!scope.config.series) {
                scope.config.series = [];
            }

            configService.get().then(function(meternetConfig) {
                scope.devices = _.pluck(meternetConfig.interfaces, 'deviceConfigs');
                scope.devices = _.flatten(scope.devices, true);
                scope.devices = scope.devices.concat(meternetConfig.maths);

                var checkDevice = function(device) {
                    return device.id === this.deviceId;
                };

                var checkParam = function(param) {
                    return param.id === this.paramId;
                };

                for ( var i = 0; i < scope.config.series.length; ++i) {
                    var series = scope.config.series[i];
                    series._device = _.find(scope.devices, checkDevice, series);
                    if (series._device) {
                        series._param = _.find(series._device.paramConfigs, checkParam, series);
                    }
                }
            });
        }
    };
});

<form class="form-horizontal">
	<label class="control-label">{{'config.choose' | i18n}}</label>
	<div class="row">
		<div class="col-sm-3" x-ng-repeat="o in ui.outputs| filter: {type:'internal'}">
			<div class="thumbnail" >
				<div class="thumbnail-price"><i class="fa fa-shopping-cart"></i> {{o.cost}}</div>
				<img alt="" src="" x-ng-src="{{o.iconUrl}}"/>
				<div class="caption">
					<span class="caption-label">{{'config.measurementOutput.type.' + o.type | i18n}}</span>
					<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{o.datasheetUrl}}"
					   x-ng-if="o.datasheetUrl">
						{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
					</a>
					<div style="height: 30px;" x-ng-if="!o.datasheetUrl"></div>
				</div>
			</div>
		</div>
		<div class="col-sm-3" x-ng-repeat="o in ui.outputs | filter: {type:'!internal'}">
			<div class="thumbnail" x-ng-class="ui.active(o)" x-ng-click="ui.select(o)">
				<div class="thumbnail-price"><i class="fa fa-shopping-cart"></i> {{o.cost}}</div>
				<img alt="" src="" x-ng-src="{{o.iconUrl}}"/>
				<div class="caption">
					<span class="caption-label">{{'config.measurementOutput.type.' + o.type | i18n}}</span>
					<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{o.datasheetUrl}}"
					   x-ng-if="o.datasheetUrl">
						{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
					</a>
					<div style="height: 30px;" x-ng-if="!o.datasheetUrl"></div>
				</div>
			</div>
		</div>
	</div>

	<div class="panel panel-default">
		<table class="table table-bordered table-condensed"
			   style="margin-bottom: 20px; border-bottom-color: #dddddd; border-bottom-style: solid; border-bottom-width: 1px;">
			<thead>
			<tr>
				<th>{{'config.database.repository' | i18n}}</th>
				<th>{{'config.label' | i18n}}</th>
				<th>{{'config.database.type' | i18n}}</th>
				<!--<th>{{'config.database.credentials' | i18n}}</th>-->
			</tr>
			</thead>
			<tbody>
			<tr x-ng-repeat="db in ui.databases">
				<td style="text-align: center; vertical-align: middle;" x-ng-if="db.repository" class="col-xs-2"
					x-ng-click="ui.setActiveRepo(db)">
					<i class="fa fa-dot-circle-o fa-lg text-success" aria-hidden="true"></i>
				</td>
				<td style="text-align: center; vertical-align: middle;" x-ng-if="!db.repository" class="col-xs-2"
					x-ng-click="ui.setActiveRepo(db)">
					<i class="fa fa-circle-o fa-lg text-danger" aria-hidden="true"></i>
				</td>
				<td class="col-xs-5">
					{{ui.getMeasurementName(db)}}
				</td>
				<td class="col-xs-5">
					{{'config.measurementOutput.type.' + db.type | i18n}}
				</td>
				<!--<td class="col-xs-4" >-->
				<!--{{db.username}}@{{db.host}}:{{db.port}}-->
				<!--</td>-->
			</tr>
			</tbody>
		</table>
	</div>
</form>

<div class="panel panel-default" style="margin-bottom: 0; height: 100%;">
    <div class="panel-body" style="margin-right:20px">
        <form class="form-inline">
            <div class="form-group">
                <select class="form-control" x-ng-model="level" x-ng-options="l.value as (l.label | i18n) for l in ui.levels"></select>
            </div>
            <div class="form-group">
                <div class='input-group date'>
                    <input type='text' class="form-control" x-ng-model="timestamp" x-datetimepicker="{ moment: true }"/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
            <div class="form-group" x-ng-show="!current && timestamp">
                <button type="submit" class="btn btn-default" x-ng-click="ui.prev()">
                    <i class="fa fa-long-arrow-left"></i> {{'event.search.previous'| i18n}}
                </button>
                <button type="submit" class="btn btn-default" x-ng-click="ui.next()">
                    {{'event.search.next'| i18n}} <i class="fa fa-long-arrow-right"></i>
                </button>
            </div>
            <div class="form-group">
                <input type="checkbox" x-ng-model="acked">{{'event.search.acked'| i18n}}</input>
            </div>
            <div class="pull-right">
                <button type="submit" class="btn btn-danger" x-ng-if="current" x-ng-click="ui.stop()">
                    <i class="fa fa-pause fa-fw"></i> {{'event.stop'| i18n}}
                </button>
                <button type="submit" class="btn btn-success" x-ng-if="!current" x-ng-click="ui.start()">
                    <i class="fa fa-play fa-fw"></i> {{'event.start'| i18n}}
                </button>
            </div>
        </form>
    </div>
    <div x-expand-to-parent-bottom="" style="overflow-y: scroll;">
        <table class="table table-hover table-condendsed" style="margin: 0; table-layout: fixed; margin-right:20px">
            <thead>
            <tr>
                <th style="width: 120px;">{{ 'status.date' | i18n }}</th>
                <!--<th style="width: 250px;">{{ 'status.source' | i18n }}</th>-->
                <th>{{ 'status.statement' | i18n }}</th>
                <th>{{ 'status.ack' | i18n }}</th>
            </tr>
            </thead>
            <tbody>
            <tr ng-repeat="event in events" x-ng-class="event.levelCssClass()">
                <td>{{ event.timestamp | date:'yyyy-MM-dd HH:mm:ss' }}</td>
                <td x-ng-bind-html="ui.display(event)"></td>
                <td>
                    <button class="btn btn-default" x-ng-if="!event.ack_timestamp && event.comment===null" x-ng-click="event.comment=''">{{'event.ackBtn'|i18n}}</button>
                    <div class="input-group" x-ng-if="!event.ack_timestamp && event.comment!==null">
                        <input type="text" class="form-control" placeholder="{{'event.ackcomment.placeholder' | i18n}}" x-ng-model="event.comment">
                        <span class="input-group-btn">
                            <button class="btn btn-default" type="button" x-ng-click="ui.ackEvent(event)">{{'event.ackBtn'|i18n}}</button>
                        </span>
                    </div>
                    <div x-ng-if="event.ack_timestamp">Ack at:{{ event.ack_timestamp | date:'yyyy-MM-dd HH:mm:ss' }} - <b>{{event.comment}}</b></div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

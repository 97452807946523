var module = angular.module('meternet.dashboard.controllers.prepaidGaugeWidget', ['adf.provider']);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/prepaid-gauge-widget.html',
        title : messages['dashboard.widgets.prepaidGauge.title'],
        description : messages['dashboard.widgets.prepaidGauge.desc'],
        controller : 'prepaidWidgetCtrl',
        screenshot: true,
        config : {
            type : "prepaid",
            series : [ {
                paramId : null,
                average : 1,
                derivative : 0,
                unit : "",
                majorTicks : 5,
                minorTicks : 3,
                max : 100,
                min : 0,
                precision : 2,
                scale : 0
            } ]
        },
        edit : {
            controller : 'prepaidWidgetEditCtrl',
            templateUrl: 'dashboard/prepaid-gauge-edit.html'
        }
    };
    dashboardProvider.widget('prepaid', widget);
});


function PrepaidEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts) {

    $scope.ui = {

        updateReport : function() {
            $scope.config.prepaidId = $scope.ui.report ? $scope.ui.report.id : null;
            prepareSeries();
        }
    };

    $scope.config = config;
    configService.get().then(function(meternetConfig){
        $scope.reports = _.flatten(_.pluck(_.filter(meternetConfig.engine.moduleInputs, function (i) { return i.type==='prepaid'}), 'devices'));
        _.forEach($scope.reports, function(report){
            if (!report.label){
                report.label = report.name;
            }
        });
        $scope.devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true));
        $scope.ui.report = _.find($scope.reports, function(report){
            return report.id === $scope.config.prepaidId;
        });

        $scope.ui.updateReport();
    });



    function prepareSeries () {
        $scope.config.series = []
        if ($scope.ui.report) {
            $scope.ui.report.params.forEach(function(reportParameter) {
                var data = {
                    deviceId: _.find($scope.devices, function (device) {
                        return _.find(device.params, function (param) {
                                return param.id === reportParameter.id;
                            }) != null;
                    }).id,
                    paramId: reportParameter.id,
                    name: reportParameter.label || reportParameter.name,
                    enabled: true
                };
                var hasParam = _.find($scope.config.series, function(s) {
                    return s.paramId === data.paramId;
                });
                if (!hasParam) {
                    $scope.config.series.push(data);
                }
            });
        }
    }
}


function PrepaidCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
        $scope.ui = {};
//    if (!config.prepaidId){
//        return;
//    }

}

module.controller('prepaidWidgetEditCtrl', PrepaidEditCtrl);
module.controller('prepaidWidgetCtrl', PrepaidCtrl);
<form class="form-horizontal row" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
        <div class="form-group"
             x-ng-if="advanceView.value && (item.config.type=='mosquitto' || item.config.type=='mbsat')">
            <label class="control-label col-sm-3">{{'config.id' | i18n}}</label>
            <div class="col-sm-8">
                <input class="form-control" x-ng-model="item.config.id" x-field-errors="" x-ng-readonly="true"/>
            </div>
        </div>

        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-help="config.input.name.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-help="config.input.label.help"
                           x-field-errors="" x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.input.desc.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group"
             x-ng-if="item.config.type=='modbus-serial' || item.config.type=='modbus-tcp' || item.config.type=='modbus-gprs' || item.config.type=='modbustcp-gateway'">
            <label class="control-label col-sm-3">{{'config.protocol' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control"
                            x-ng-model="item.config.type"
                            x-field-help="config.input.protocol.help">
                        <option value="modbus-serial">{{'config.measurementInput.type.modbus-serial'| i18n}}</option>
                        <option value="modbus-tcp">{{'config.measurementInput.type.modbus-tcp'| i18n}}</option>
                        <option value="modbus-gprs">{{'config.measurementInput.type.modbus-gprs'| i18n}}</option>
                        <option value="modbustcp-gateway">{{'config.measurementInput.type.modbustcp-gateway'| i18n}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type=='mbus-serial' || item.config.type=='mbus-tcp'">
            <label class="control-label col-sm-3">{{'config.protocol' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control" x-ng-model="item.config.type" x-field-help="config.input.protocol.help">
                        <option value="mbus-serial">{{'config.measurementInput.type.mbus-serial'| i18n}}</option>
                        <option value="mbus-tcp">{{'config.measurementInput.type.mbus-tcp'| i18n}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type=='wmbus-serial' || item.config.type=='wmbus-tcp'">
            <label class="control-label col-sm-3">{{'config.protocol' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control" x-ng-model="item.config.type" x-field-help="config.input.protocol.help">
                        <option value="wmbus-serial">{{'config.measurementInput.type.wmbus-serial'| i18n}}</option>
                        <option value="wmbus-tcp">{{'config.measurementInput.type.wmbus-tcp'| i18n}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type=='dlms-serial' || item.config.type=='dlms-tcp'">
            <label class="control-label col-sm-3">{{'config.protocol' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <select class="form-control" x-ng-model="item.config.type" x-field-help="config.input.protocol.help">
                        <option value="dlms-serial">{{'config.measurementInput.type.dlms-serial'| i18n}}</option>
                        <option value="dlms-tcp">{{'config.measurementInput.type.dlms-tcp'| i18n}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div x-ng-switch="item.config.type">

            <div x-ng-switch-when="modbus-serial">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.port"
                                    x-field-help="config.input.serialPort.help"
                                    x-ng-options="p.value as p.label for p in ui.ports" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.baudrate' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.baudrate"
                                    x-field-help="config.input.serialBaud.help" x-ng-options="b for b in ui.baudrates"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.dataBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.dataBits"
                                    x-field-help="config.input.serialData.help" x-ng-options="d for d in ui.dataBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.stopBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.stopBits"
                                    x-field-help="config.input.serialStop.help" x-ng-options="s for s in ui.stopBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.parity' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.parity"
                                    x-field-help="config.input.serialParity.help"
                                    x-ng-options="p.value as p.label for p in ui.parities" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div x-ng-switch-when="modbus-tcp">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.param.isClient' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                        <select class="form-control"
                                x-ng-model="item.config.client"
                                x-ng-options="o.value as (o.label | i18n) for o in ui.booleansisclient"
                                x-ng-click="ui.clientServerMode(item.config.client)"
                                x-field-help="config.param.isClient.help"
                                x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group" x-ng-if="item.config.client">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-field-help="config.input.tcpAddress.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-field-help="config.input.tcpPort.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div x-ng-switch-when="modbus-gprs">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.param.isClient' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control"
                                    x-ng-model="item.config.client"
                                    x-ng-options="o.value as (o.label | i18n) for o in ui.booleansisclient"
                                    x-ng-click="ui.clientServerMode(item.config.client)"
                                    x-field-help="config.param.isClient.help"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group" x-ng-if="item.config.client">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-field-help="config.input.tcpAddress.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-field-help="config.input.tcpPort.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div x-ng-switch-when="xml-tcp">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div x-ng-switch-when="mbus-serial">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.port"
                                    x-field-help="config.input.serialPort.help"
                                    x-ng-options="p.value as p.label for p in ui.ports" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.baudrate' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.baudrate"
                                    x-field-help="config.input.serialBaud.help" x-ng-options="b for b in ui.baudrates"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.dataBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.dataBits"
                                    x-field-help="config.input.serialData.help" x-ng-options="d for d in ui.dataBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.stopBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.stopBits"
                                    x-field-help="config.input.serialStop.help" x-ng-options="s for s in ui.stopBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.parity' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.parity"
                                    x-field-help="config.input.serialParity.help"
                                    x-ng-options="p.value as p.label for p in ui.parities" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="mbus-tcp">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-field-help="config.input.tcpAddress.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-field-help="config.input.tcpPort.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="wmbus-tcp">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-field-help="config.input.tcpAddress.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-field-help="config.input.tcpPort.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="wmbus-serial">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.port"
                                    x-field-help="config.input.serialPort.help"
                                    x-ng-options="p.value as p.label for p in ui.ports" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.baudrate' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.baudrate"
                                    x-field-help="config.input.serialBaud.help" x-ng-options="b for b in ui.baudrates"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.dataBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.dataBits"
                                    x-field-help="config.input.serialData.help" x-ng-options="d for d in ui.dataBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.stopBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.stopBits"
                                    x-field-help="config.input.serialStop.help" x-ng-options="s for s in ui.stopBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.parity' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.parity"
                                    x-field-help="config.input.serialParity.help"
                                    x-ng-options="p.value as p.label for p in ui.parities" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
            </div>
            <div x-ng-switch-when="dlms-serial">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.port"
                                    x-field-help="config.input.serialPort.help"
                                    x-ng-options="p.value as p.label for p in ui.ports" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.baudrate' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.baudrate"
                                    x-field-help="config.input.serialBaud.help" x-ng-options="b for b in ui.baudrates"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.dataBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.dataBits"
                                    x-field-help="config.input.serialData.help" x-ng-options="d for d in ui.dataBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.stopBits' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.stopBits"
                                    x-field-help="config.input.serialStop.help" x-ng-options="s for s in ui.stopBits"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.serial.parity' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.parity"
                                    x-field-help="config.input.serialParity.help"
                                    x-ng-options="p.value as p.label for p in ui.parities" x-field-errors=""></select>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="dlms-tcp">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.param.isClient' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                        <select class="form-control"
                                x-ng-model="item.config.client"
                                x-ng-options="o.value as (o.label | i18n) for o in ui.booleansisclient"
                                x-ng-click="ui.clientServerMode(item.config.client)"
                                x-field-help="config.param.isClient.help"
                                x-field-errors=""></select>
                        </div>
                    </div>
<!--                    <div class="col-sm-1">-->
<!--                        <button type="button" uib-popover="{{'config.device.isClient.help' | i18n}}"-->
<!--                                popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?-->
<!--                        </button>-->
<!--                    </div>-->
                </div>
                <div class="form-group" x-ng-if="item.config.client">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-field-help="config.input.tcpAddress.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-field-help="config.input.tcpPort.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="modbustcp-gateway">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-field-help="config.input.tcpAddress.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.port"
                                   x-field-help="config.input.tcpPort.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="remote">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.host' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.url"
                                   x-field-help="config.input.remoteHost.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.username"
                                   x-field-help="config.input.remoteUsername.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input type="password" class="form-control" x-field-help="config.input.remotePass.help"
                                   x-ng-model="item.config.password" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.output' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.outputName"
                                   x-field-help="config.input.remoteOutputname.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="mosquitto">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.islocal' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control"
                                    x-ng-model="item.config.local"
                                    x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
                                    x-field-help="config.database.repository.help"
                                    x-field-errors=""></select>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-ng-disabled="item.config.local" x-field-help="config.input.tcpAddress.help"
                                   x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-ng-disabled="item.config.local" x-field-help="config.input.tcpPort.help"
                                   x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="mbsat">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.islocal' | i18n}}</label>
                    <div class="col-sm-7">
                        <!--<div class="input-group">-->
                        <select class="form-control"
                                x-ng-model="item.config.local"
                                x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
                                x-field-errors=""></select>
                        <!--</div>-->
                    </div>
                    <div class="col-sm-1">
                        <button type="button" uib-popover="{{'config.database.repository.help' | i18n}}"
                                popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
                        </button>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.address' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.address"
                                   x-ng-disabled="item.config.local" x-field-help="config.input.tcpAddress.help"
                                   x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.tcp.port' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.port"
                                   x-ng-disabled="item.config.local" x-field-help="config.input.tcpPort.help"
                                   x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="bacnet-ip">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.bacnet.broadcast' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.broadcast"
                                   x-field-help="config.measurementInput.bacnet.broadcast.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.measurementInput.bacnet.mask' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" type="number" x-ng-model="item.config.mask"
                                   x-field-help="config.measurementinput.bacnet.mask.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.timeout"
                                   x-field-help="config.input.timeout.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="form-group" x-ng-if="advanceView.value">-->
        <!--<label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>-->
        <!--<div class="col-sm-8">-->
        <!--<div class="input-group">-->
        <!--<input class="form-control" x-ng-model="item.config.enqueuedMeasurementsWarningLevel" x-field-help="config.input.enqueuedMeasurementsWarningLevel.help" x-field-errors="" />-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <!--<div class="form-group" x-ng-if="advanceView.value">-->
        <!--<label class="control-label col-sm-3">{{'config.measurementOutputs' | i18n}}</label>-->
        <!--<div class="col-sm-5">-->
        <!--<select class="form-control"-->
        <!--x-ng-model="item.config.outputIds"-->
        <!--x-ng-options="o.id as o.name for o in config.engine.measurementOutputs"-->
        <!--x-field-errors=""-->
        <!--x-multi-select=""-->
        <!--multiple="multiple"></select>-->
        <!--</div>-->
        <!--<div class="col-sm-2">-->
        <!--<button class="btn btn-default" style="width: 100%;" x-ng-click="ui.restoreDefaultRules()">-->
        <!--<i class="fa fa-chain"></i>&nbsp;{{'config.measurementOutputs.set' | i18n}}-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="col-sm-1">-->
        <!--<button type="button" uib-popover="{{'config.input.measurementOutputs.help' | i18n}}" popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?</button>-->
        <!--</div>-->
        <!--</div>-->
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.localisation' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.localisation"
                           x-field-help="config.input.localisation.help" x-field-errors=""/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.device.latitude' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" type="number" x-ng-model="item.config.lat"
                           x-field-help="config.input.lat.help" x-field-errors=""/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.device.lon' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" type="number" x-ng-model="item.config.lon"
                           x-field-help="config.input.lon.help" x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.cronExpression !== undefined">
            <label class="control-label col-sm-3" style="margin-top: 16px;">{{'config.device.cronExpression' |
                i18n}}</label>
            <div class="col-sm-8" x-config-form-cron-expression="item.config.cronExpression" x-seconds-enable="item.config.type === 'machine'"
                 x-minutes-enable="true"></div>
        </div>

    </div>

    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.measurementInput.type.' + ui.type.type | i18n}}</span>
                </span>
				<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{ui.type.datasheetUrl}}"
				   x-ng-show="ui.type.datasheetUrl">
					{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
				</a>
            </div>
        </div>
    </div>
</form>

<form class="form-inline"
      x-ng-if="advanceView.value && (item.config.type!='prepaid' && item.config.type!='alarm' && item.config.type!='control')">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title">
                <i class="fa fa-fw fa-filter"></i>&nbsp;{{'config.momentRules.simple' | i18n}}
            </div>
        </div>
        <div>
            <table class="table table-bordered table-condensed" style="margin-bottom: 0px;">
                <thead>
                <tr>
                    <th>{{'config.momentRules.outputName' | i18n}}</th>
                    <th>{{'config.momentRules.default' | i18n}}</th>
                    <th>{{'config.momentRules.deadband' | i18n}}</th>
                    <th>{{'config.momentRules.force' | i18n}}</th>
                    <th>{{'config.momentRules.activateAll' | i18n}}</th>
                    <th>{{'config.momentRules.deactivateAll' | i18n}}</th>
                </tr>
                </thead>
                <tbody>
                <tr x-ng-repeat="o in config.engine.measurementOutputs | orderBy : 'name'">
                    <td style="vertical-align: middle;" class="col-xs-2">
                        {{o.label||o.name}}
                    </td>
                    <td style="text-align: center;" x-ng-if="ui.isOutputActive(o)" class="col-xs-1"
                        x-ng-click="ui.deactivateOutput(o)">
                        <i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
                    </td>
                    <td style="text-align: center;" x-ng-if="!ui.isOutputActive(o)" class="col-xs-1"
                        x-ng-click="ui.activateOutput(o)">
                        <i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
                    </td>
                    <td class="col-xs-1">
                        <input class="form-control" x-ng-model="o.deadBand[0]"/>
                    </td>
                    <td class="col-xs-1">
                        <input class="form-control" x-ng-model="o.deadBand[1]"/>
                    </td>
                    <td class="col-xs-3">
                        <div class="btn-group" role="group" style="width: 100%;">
                            <button class="btn btn-xs btn-justborder btn-success"
                                    x-ng-click="ui.activateOutputInInput(o, true)">
                                <i class="fa fa-plus"></i>&nbsp;{{'config.momentRules.activateAll.all' | i18n}}
                            </button>
                            <button class="btn btn-xs btn-justborder btn-success"
                                    x-ng-click="ui.activateOutputInInput(o, false)">
                                <i class="fa fa-plus"></i>&nbsp;{{'config.momentRules.activateAll.selected' | i18n}}
                            </button>
                        </div>
                    </td>
                    <td class="col-xs-3">
                        <div class="btn-group" role="group" style="width: 100%;">
                            <button class="btn btn-xs btn-justborder btn-danger"
                                    x-ng-click="ui.deactivateOutputInInput(o, true)">
                                <i class="fa fa-times"></i>&nbsp;{{'config.momentRules.deactivateAll.all' | i18n}}
                            </button>
                            <button class="btn btn-xs btn-justborder btn-danger"
                                    x-ng-click="ui.deactivateOutputInInput(o, false)">
                                <i class="fa fa-times"></i>&nbsp;{{'config.momentRules.deactivateAll.selected' | i18n}}
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div x-ng-repeat="device in item.config.devices">
            <table style="width:100%">
                <tr>
                    <td style="vertical-align: middle;" x-ng-style="{'background-color: #f5f5f5;': device.open }">
                        <div class="list-group-item menu-item" x-ng-click="device.open=!device.open"
                             style="height:40px; border-radius: 0px; line-height: 30px;">
                            <i class="fa fa-fw fa-lg fa-angle-right" x-ng-if="!device.open"></i>
                            <i class="fa fa-fw fa-lg fa-angle-down" x-ng-if="device.open"></i>
                            <i class="fa fa-fw fa-lg fa-cube"></i>
                            {{device.label||device.name}}
                        </div>
                        <div class="row" x-ng-if="device.open" x-ng-repeat="param in device.params">
                            <div class="col-xs-offset-1 col-xs-11" x-config-form-moment-rule-simple="param.momentRules"
                                 x-config="config" x-hide="$index==0" x-param="param" x-params="device.params"></div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</form>

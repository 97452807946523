var module = angular.module('meternet.chart.directives.eventsAck', ['meternet.dashboard.constants']);

module.directive('eventsAck', function(DashboardEvents, $timeout, $parse, UnitScales, unitFilter, configService, i18nFilter, dataService, dateFilter, csvService) {
    return {
        scope: {
             options : '=',
              data : '='
        },
        templateUrl: 'dashboard/events-ack-widget.html',
        controller: 'EventsAckWidgetCtrl'
    }
});
/*globals tools*/

var module = angular.module('meternet.charts.gauge.services.half-gauge-serie', []);

module.service('HalfGaugeSerie', function(gaugeUtils){
     function HalfGaugeSerie(body, serie) {
        this.body = body;
        var self = this; // for internal d3 functions
        this.serie = tools.copy(serie);
        gaugeUtils.sanitizeSerie(this.serie);
        var _currentArc2 = 0;
        var _indicator = d3.svg.arc().startAngle(0 * (Math.PI / 180)).endAngle(0); // just

        this.render = function(config) { 

            if (config) {
                this.config = config;
                this.config.size = Math.min(this.config.width *0.9, this.config.height * 1.5);
                this.config.radius = this.config.size * 0.4;
                this.config.cx = this.config.width / 2;
                this.config.cy = this.config.height / 2 + this.config.radius * 0.45;

                this.config.gradient = config.gradient || -3;
                this.config.transitionDuration = config.transitionDuration || 500;
            }
            // this.body.selectAll('*').remove();
            this.body.attr("class", "gauge").attr("width", this.config.width).attr("height", this.config.height);

            var serie = this.serie;

            this.drawZones(serie);
            this.drawBackground(serie.min, serie.max, "#000", 0.65, 1.00, null, "tlo", null, serie);
            var fontSize;
            if (undefined !== serie.name) {
                fontSize = Math.round(this.config.radius / 9);
                var labelText = this.body.select(".gauge-label");
                if (labelText.empty()) {
                    labelText = this.body.append("svg:text").attr("class", "gauge-label");
                }
                labelText.attr("x", this.config.cx).attr("y", this.config.cy + this.config.radius / 3 - fontSize * 5)
                        .attr("dy", fontSize / 2).attr("text-anchor", "middle").text(serie.name).style("font-size",
                                fontSize * 1.5 + "px").style("fill", "#999").style("stroke-width", "0px");
            }

            var ticks = gaugeUtils.calculateTicks(serie);
            this.drawTicks(ticks);
            // var midValue = this.config.min; //jak jest
            // wartość poniżej zakresu lub równa min to
            // wskazówka przestaje działać.
            var midValue = serie.min + (serie.max - serie.min) / 1000;
            var pointerContainer = this.body.select("g.pointerContainer");
            if (pointerContainer.empty()) {
                pointerContainer = this.body.append("svg:g").attr("class", "pointerContainer");
            }

            fontSize = Math.round(this.config.radius / 10);
            var gaugeValue = pointerContainer.selectAll("text.gauge-value").data([ midValue ]);
            gaugeValue.enter().append("svg:text").attr("class", "gauge-value");
            gaugeValue.attr("x", this.config.cx).attr("y", this.config.height / 2 + this.config.radius / 3 + fontSize)
                    .attr("dy", fontSize / 2).attr("text-anchor", "middle").style("font-size", (fontSize * 2)-(fontSize / 3)  + "px")
                    .style("fill", "#000").style("stroke-width", "0px");

            var gaugeTimestamp = pointerContainer.selectAll("text.gauge-timestamp").data([ midValue ]);
            gaugeTimestamp.enter().append("svg:text").attr("class", "gauge-timestamp");
            gaugeTimestamp.attr("x", this.config.cx).attr("y",
                    this.config.height / 2 + this.config.radius / 3 + fontSize * 3).attr("dy", fontSize / 30).attr(
                    "text-anchor", "middle").style("font-size", fontSize + "px").style("fill", "#333").style(
                    "stroke-width", "0px");
            // this.redraw(serie.min, 0, serie);

            _indicator = this.drawBand(serie.min, midValue, "#000", 0.7, 0.80, 0.90, pointerContainer,
                    "gauge-indicator", _indicator);

        };
        this.drawTicks = function(ticks) {

            var serie = this.serie;
            var t = self.body.selectAll("g.tick").data(ticks, function(d) {
                return (d.major ? "major" : "minor") + d.value;
            });

            var nt = t.enter().append("g").attr("class", "tick");
            nt.classed("tick-minor", function(d) {
                return !d.major;
            }).classed("tick-major", function(d) {
                return d.major;
            });
            t.each(function(d) {
                var tick = d3.select(this);
                var line = tick.select("line");
                if (line.empty()) {
                    line = tick.append("line");
                }
                var point1, point2;
                if (d.major) {
                    point1 = self.valueToPoint(d.value, 0.85);
                    point2 = self.valueToPoint(d.value);
                    line.attr("x1", point1.x).attr("y1", point1.y).attr("x2", point2.x).attr("y2", point2.y).style(
                            "stroke", "#333").style("stroke-width", "2px");
                } else {
                    point1 = self.valueToPoint(d.value, 0.90);
                    point2 = self.valueToPoint(d.value);
                    line.attr("x1", point1.x).attr("y1", point1.y).attr("x2", point2.x).attr("y2", point2.y).style(
                            "stroke", "#666").style("stroke-width", "1px");
                }

            });
            t
                    .filter(function(d, i) {
                        return d.major;
                    })
                    .each(
                            function(d) {
                                var tick = d3.select(this);
                                var text = tick.select("text");
                                if (text.empty()) {
                                    text = tick.append("svg:text").attr("class", "tick-label");
                                }
                                var point = self.valueToPoint(d.value, 1.05);

                                var point1 = self.valueToPoint(d.value, 0.74);
                                var point2 = self.valueToPoint(d.value, 0.91);

                                var tickAngleTg = (point1.y - point2.y) / (point1.x - point2.x);
                                var alignmentBase = "baseline";
                                if (!isFinite(tickAngleTg) || Math.abs(tickAngleTg) > 0.7) {
                                    if (point1.y > point2.y) {
                                        alignmentBase = "central";
                                    } else {
                                        alignmentBase = "after-edge";
                                    }
                                }
                                var fontSize = Math.round(self.config.radius / 14);
                                text
                                        .attr("x", point.x)
                                        .attr("y", point.y)
                                        .attr("dy", fontSize / 3)
                                        .attr(
                                                "text-anchor",
                                                d.value !== ((serie.max - serie.min) / 2) + serie.min ? (d.value > ((serie.max - serie.min) / 2)
                                                        + serie.min ? "start" : "end")
                                                        : "middle").text(
                                                self.config.valueFormatter(d.value, serie.precision, serie.unit,
                                                        serie.scale)).style("font-size", fontSize + "px").style("fill",
                                                "#333").style("stroke-width", "0px");
                            });
            t.exit().remove();
        };
        this.drawZones = function(serie) {
            var self = this;
            var zones = this.body.selectAll("g.zone").data(serie.zones, function(zone) {
                return zone.from + ':' + zone.to;
            });
            var gradient = zones.enter().append("svg:g").attr("class", "zone").append("svg:defs").append(
                    "svg:radialGradient").attr("class", "radialgradient").attr({
                "id" : function(d) {
                    return _.uniqueId('zone');
                },
                "gradientUnits" : "userSpaceOnUse"
            }).attr("cx", 0).attr("cy", 0);
            gradient.append("svg:stop").attr("class", "svg-stop-from").attr("offset", "60%").attr("stop-opacity", 1);
            gradient.append("svg:stop").attr("offset", "100%").attr("stop-opacity", 1).attr("class", "svg-stop-to");
            zones.exit().remove();
            zones.each(function(zone) {
                var d3Zone = d3.select(this);
                self.drawBand(zone.from, zone.to, zone.color, 1.00, 0.65, 1.0, d3Zone, "zones", null);
            });

        };
        // ------------------------drawBand------------------
        this.drawBand = function(start, end, color, opacity, innerRadius, outerRadius, parent, name, ind) {
            if (!isFinite(start)) {
                return;
            }
            if (!isFinite(end)) {
                return;
            }
            if (end < start) {
                return;
            }
            var self = this;
            parent = parent || this.body;
            ind = ind || d3.svg.arc();

            var zoneId = _.uniqueId('zone');

            var gradient = parent.select(".radialgradient");

            ind.startAngle(this.valueToRadians(start)).endAngle(this.valueToRadians(end)).innerRadius(
                    innerRadius * this.config.radius).outerRadius(outerRadius * this.config.radius);
            var band = parent.select("path." + name);
            if (band.empty()) {
                band = parent.append("svg:path").attr("class", name);
            }
            if (!gradient.empty()) {
                gradient.attr("r", outerRadius * this.config.radius);
                gradient.select("stop.svg-stop-from").attr("stop-color", d3.rgb(color).brighter(this.config.gradient));
                gradient.select("stop.svg-stop-to").attr("stop-color", d3.rgb(color));
                band.style("fill", "url(#" + gradient.attr("id") + ")");
            }
            band.style("opacity", opacity).attr("d", ind).attr("transform", function() {
                return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(270)";
            });
            return ind;
        };
        // -------------drawBackground-----------------------
        this.drawBackground = function(start, end, color, innerRadius, outerRadius, parent, name, ind, serie) {
            if (0 >= end - start){
                return;
            }
            if (!parent) {
                parent = this.body;
            }
            if (!ind){
                ind = d3.svg.arc();
            }
            ind.startAngle(this.valueToRadians(start)).endAngle(this.valueToRadians(end)).innerRadius(
                    innerRadius * this.config.radius).outerRadius(outerRadius * this.config.radius);

            var band = parent.select("path." + name);
            if (band.empty()) {
                band = parent.append("svg:path").attr("class", name);
            }
            band.style("fill", "none").style("stroke", "#000000").style("stroke-width", '1px').attr("d", ind).attr(
                    "transform", function() {
                        return "translate(" + self.config.cx + ", " + self.config.cy + ") rotate(270)";
                    });
            return ind;
        };

        // =====================redraw=============================
        this.redraw = function(measurement, transitionDuration) {
            var serie = this.serie;
            var pointerContainer = this.body.select(".pointerContainer");

            if (!measurement) {
                pointerContainer.selectAll("text.gauge-value").text('?');
                pointerContainer.selectAll("text.gauge-timestamp").text('');
                return;
            }
            var timestamp = this.config.dateFormatter(measurement.timestamp, 'yyyy-MM-dd HH:mm:ss');
            var value = measurement.value;

            pointerContainer.selectAll("text.gauge-value").text(
                    this.config.valueFormatter(value, serie.precision, serie.unit, serie.scale));
            pointerContainer.selectAll("text.gauge-timestamp").text(timestamp);

            var pointer = pointerContainer.selectAll("path.gauge-indicator");
            value = Math.max(serie.min, value);
            value = Math.min(serie.max, value);

            pointer.datum(this.valueToRadians(isNaN(value) ? serie.min : value));
            pointer.transition().delay(10).duration(1000).attrTween("d", function(a) {
                var i = d3.interpolate(_currentArc2, a);
                _currentArc2 = a;
                return function(t) {
                    return _indicator.endAngle(i(t))();
                };
            });
        };

        this.valueToDegrees = function(value) {
            var serie = this.serie;
            return value / serie.range * 200 - (serie.min / serie.range * 200 + 10);
        };

        this.valueToRadians = function(value) {
            var serie = this.serie;
            return this.valueToDegrees(value) * Math.PI / 180;
        };

        this.valueToPoint = function(value, factor) {
            var serie = this.serie;

            if (!isFinite(factor)) {
                factor = 1.0;
            }
            return {
                x : this.config.cx - this.config.radius * factor * Math.cos(this.valueToRadians(value)),
                y : this.config.cy - this.config.radius * factor * Math.sin(this.valueToRadians(value))
            };
        };

        // initialization
        // this.configure(configuration);
    }
     return HalfGaugeSerie;
});

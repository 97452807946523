<div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-5">
            <div>
                <div>
                    <ul>
                        <li>
                            <!-- <span ><i class="fa fa-gear fa-fw" ></i>{{'measurements.series' | i18n}}</span>-->
                            <div class="tree">
                                <ul>
                                    <li data-ng-repeat="serie in series">

                                        <!--<button type="button" class="btn btn-default"  data-ng-click="ui.toggle(interface)" x-ng-class="{'btn-primary': hoverdown}"
                                            ng-mouseenter="hoverdown = true" ng-mouseleave="hoverdown = false">
                                            <i class="fa fa-chevron-up" x-ng-class="{'fa-chevron-down': !ui.isOpen(interface)}"></i>
                                        </button>-->
                                        <button type="button" class="btn btn-default" data-ng-click="ui.edit(serie)"
                                                ng-class="{'btn-primary': hover}"
                                                ng-mouseenter="hover = true" ng-mouseleave="hover = false">
                                            <i class="fa fa-sitemap"></i>
                                            {{serie.alias}}
                                        </button>
                                        <ul>
                                            <li data-ng-repeat="lamp in serie.lamps">
                                                <button type="button" class="btn btn-default"
                                                        data-ng-click="ui.edit(lamp)"
                                                        x-ng-class="{'btn-primary': hoverdevice}"
                                                        ng-mouseenter="hoverdevice = true"
                                                        ng-mouseleave="hoverdevice = false">
                                                    <i class="fa fa-tachometer"></i>
                                                    {{lamp.serialNumber}}
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" class="btn btn-default"
                                                        data-ng-click="ui.addChild(serie, 'lamp')"
                                                        x-ng-class="{'btn-primary': hoveradddevice}"
                                                        ng-mouseenter="hoveradddevice = true"
                                                        ng-mouseleave="hoveradddevice = false">
                                                    <i class="fa fa-plus"></i>
                                                </button>
                                            </li>
                                        </ul>

                                    </li>
                                    <li>
                                        <button type="button" class="btn btn-default"
                                                data-ng-click="ui.addChild(null,  'lampSerie')"
                                                x-ng-class="{'btn-primary': hoveradd}"
                                                ng-mouseenter="hoveradd = true" ng-mouseleave="hoveradd = false">
                                            <i class="fa fa-plus"/>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-7">
            <!-- BODY -->
            <div x-ng-show="editedElement.getClassName() || ui.editedOptions">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="panel panel-default panel-primary">
                            <div class="panel-heading">
                                <div class="pull-right">
                                    <button class="btn btn-danger" x-ng-click="ui.deleteChild(editedElement)"
                                            x-ng-show="editedElement.getClassName()">
                                        <i class="fa fa-trash-o fa-lg"></i>
                                    </button>
                                </div>
                                <div data-ng-switch="editedElement.getClassName()">
                                    <div data-ng-switch-when="LampSerie">
                                        <h2 class="panel-title clearfix">
                                            {{'led.' + editedElement.getClassName() | i18n}} {{editedElement.alias}}
                                        </h2>
                                    </div>
                                    <div data-ng-switch-when="Lamp">
                                        <h2 class="panel-title clearfix">
                                            {{'led.' + editedElement.getClassName() | i18n}}
                                            {{editedElement.serialNumber}}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <form class="form-horizontal" name="configForm" x-role="form">
                                <div class="panel-body" data-ng-if="editedElement">
                                    <div data-ng-switch="editedElement.getClassName()">

                                        <div data-ng-switch-when="LampSerie">
                                            <div data-lamp-serie-config="editedElement"></div>
                                        </div>
                                        <div data-ng-switch-when="Lamp">
                                            <div data-lamp-config="editedElement"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
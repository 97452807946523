/*global tools */

var module = angular.module('meternet.reportChart.directives', [ 'meternet.services', 'meternet.config.controllers',
        'meternet.filters', 'meternet.dashboard.constants', 'meternet.dashboard.directives' ]);



module.controller('reportChartCtrl', function ReportChartCtrl($scope, $stateParams, $timeout, contextPath, dashboardService, configService, dataService,
        $state, userService, localStorageService, i18nFilter, DashboardEvents) {

    var lsKey = 'ui.report.chart';
    
    var DEFAULT_DASHBOARD = {
        "id": null,
        "name": "main",
        "structure": "12",
        "lockAddingWidgets": true,
        "lockEditingDashboard": true,
        "lockRemovingWidgets": true,
        "enableClearingModel": true,
        "hideLicenseStatus": true,
        "title": " ",
        "rows": [{
            "columns": [{
                "width": 12,
                "widgets": [{
                    "type": "line-chart",
                    "locked": true,
                    "title": "",
                    "config": {
                        "series": [],
                        "mode": 'current',
                        "historyTime": 3,
                        "defaultScaleSize": 0,
                        "timeUnit": 60,
                        "grid": 0,
                        "showOnlyTime": false,
                        "enableZoom": false,
                        "enableScroll": true
                    }
                }]
            }]
        }]
    };

    $scope.dashboard = tools.copy(DEFAULT_DASHBOARD);
    var config = localStorageService.get(lsKey);
    if (config) {
        $scope.dashboard.rows[0].columns[0].widgets[0].config = config;
    }

    $scope.$on('dashboard.dirty', function($event, newElement) {
        $scope.dirty = true;
    });
    
    $scope.$on('dashboard.clear', function($event, newElement) {
        $scope.dashboard = tools.copy(DEFAULT_DASHBOARD);
    });

    $scope.$on('meternetConfigUpdate', function($event, config) {
        $scope.$broadcast(DashboardEvents.RESIZE);
    });

    $scope.$on('adfDashboardChanged', function(event, name, model) {
        localStorageService.set(lsKey, $scope.dashboard.rows[0].columns[0].widgets[0].config);
    });
});

<form class="form-horizontal row" x-errors="item.errors" x-errors-root="item.config">
	<div class="col-sm-8">
		<div class="form-group" x-ng-if="advanceView.value && item.config.type=='mosquitto'">
			<label class="control-label col-sm-3">{{'config.id' | i18n}}</label>
			<div class="col-sm-8">
				<input class="form-control" x-ng-model="item.config.id" x-field-errors="" x-ng-readonly="true"/>
			</div>
		</div>

		<div class="form-group" x-ng-if="advanceView.value">
			<label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.name" x-field-errors=""
						   x-field-help="config.device.name.help"/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.label" x-field-help="config.device.label.help"
						   x-field-errors="" x-field-default-value="item.config.name"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'energy-monitor' && item.config.type != 'alarm' && item.config.type != 'control'">
			<label class="control-label col-sm-3">{{'config.desc1' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.desc" x-field-errors=""
						   x-field-help="config.device.desc1.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'energy-monitor' && item.config.type != 'alarm' && item.config.type != 'control'">
			<label class="control-label col-sm-3">{{'config.desc2' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.desc2" x-field-errors=""
						   x-field-help="config.device.desc2.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type != 'energy-report' && item.config.type != 'energy-monitor' && item.config.type != 'alarm' && item.config.type != 'control'">
			<label class="control-label col-sm-3">{{'config.desc3' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.desc3" x-field-errors=""
						   x-field-help="config.device.desc3.help"/>
				</div>
			</div>
		</div>

		<div class="form-group"  x-ng-if="item.config.type === 'mosquitto'">
			<label class="control-label col-sm-3">{{'config.topic' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.topic" x-field-help="config.device.topic.help"
						   x-field-errors="" x-field-default-value="item.config.id"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type == 'energy-monitor'">
			<label class="control-label col-sm-3">{{'config.powerContracted' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.powerContracted" x-field-errors=""
						   x-field-help="config.device.powerContracted.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type == 'energy-monitor'">
			<label class="control-label col-sm-3">{{'config.powerConnected' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.powerConnected" x-field-errors=""
						   x-field-help="config.device.powerContracted.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'modbus'">
			<label class="control-label col-sm-3">{{'config.device.modbus.address' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.address"
						   x-field-help="config.device.modbus.address.help" x-field-errors=""/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'xml'">
			<label class="control-label col-sm-3">{{'config.device.xml.url' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="text" x-ng-model="item.config.url"
						   x-field-help="config.device.xml.url.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'mbus'">
			<label class="control-label col-sm-3">{{'config.device.mbus.address' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.address"
						   x-field-help="config.device.mbus.address.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'wmbus'">
			<label class="control-label col-sm-3">{{'config.device.wmbus.address' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="text" x-ng-model="item.config.address"
						   x-field-help="config.device.wmbus.address.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'wmbus'">
			<label class="control-label col-sm-3">{{'config.device.wmbus.key' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="text" x-ng-model="item.config.key"
						   x-field-help="config.device.wmbus.key.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'modbustcp'">
			<label class="control-label col-sm-3">{{'config.device.modbustcp.unitID' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.unitID"
						   x-field-help="config.device.modbustcp.unitID.help"x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'modbustcp'">
			<label class="control-label col-sm-3">{{'config.device.modbustcp.address' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.address"
						   x-field-help="config.device.modbustcp.address.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'modbustcp'">
			<label class="control-label col-sm-3">{{'config.device.modbustcp.port' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.port"
						   x-field-help="config.device.modbustcp.port.help" x-field-errors=""/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type != 'machine'">
			<label class="control-label col-sm-3">{{'config.param.enabled' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<select class="form-control"
							x-ng-model="item.config.enabled"
							x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
							x-field-errors=""></select>
					<div class="input-group-btn">
						<button type="button" uib-popover="{{'config.database.enabled.help' | i18n}}"
								popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'dlms'">
			<label class="control-label col-sm-3">{{'config.device.dlms.address' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.dlmsAddress"
						   x-field-help="config.device.dlms.address.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'dlms'">
			<label class="control-label col-sm-3">{{'config.device.dlms.startCharackter' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.startCharackter" x-ng-change="ui.startCharackterHandler()"
						   x-field-help="config.device.dlms.startCharackter.help" x-field-errors=""/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'dlms'">
			<label class="control-label col-sm-3">{{'config.device.dlms.endCharackter' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.endCharackter"
						   x-field-help="config.device.dlms.endCharackter.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'dlms'">
			<label class="control-label col-sm-3">{{'config.device.dlms.acknowledgeMode' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.acknowledgeMode"
						   x-field-help="config.device.dlms.acknowledgeMode.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'dlms'">
			<label class="control-label col-sm-3">{{'config.device.dlms.readVariant' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<select class="form-control"
							x-ng-model="item.config.readVariant"
							x-ng-options="o.value as (o.label | i18n) for o in ui.readVariant"
							x-field-errors=""></select>
					<div class="input-group-btn">
						<button type="button" uib-popover="{{'config.device.dlms.readVariant.help' | i18n}}"
								popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'bacnet'">
			<label class="control-label col-sm-3">{{'config.device.bacnet.address' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.bacnetAddress"
						   x-field-help="config.device.bacnet.address.help" x-field-errors=""/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'modbustcp'">
			<label class="control-label col-sm-3">{{'config.timeout' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.timeout"
						   x-field-help="config.device.timeout.help" x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'sun' || item.config.type === 'moon'">
			<label class="control-label col-sm-3">{{'config.device.longitude' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-field-help="config.device.longitude.help"
						   x-ng-model="item.config.longitude"
						   x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'sun' || item.config.type === 'moon'">
			<label class="control-label col-sm-3">{{'config.device.latitude' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-field-help="config.device.latitude.help"
						   x-ng-model="item.config.latitude"
						   x-field-errors=""/>
				</div>
			</div>
		</div>
		<div class="form-group"
			 x-ng-if="item.config.type === 'sun' || item.config.type === 'moon' || item.config.type === 'time'">
			<label class="control-label col-sm-3">{{'config.device.timezone' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="text"
						   ng-model="item.config.timezone"
						   x-field-errors=""
						   x-field-help="config.device.timezone.help"
						   typeahead="timezone.name for timezone in timezones | filter:$viewValue"
						   typeahead-editable="false"
						   typeahead-on-select="onSelect($item, $model, $label)"/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-sm-3">{{'config.localisation' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="text" x-ng-model="item.config.localisation" x-field-help="config.device.localisation.help"
						   x-field-errors="" x-field-default-value="item.parent.config.localisation"/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-sm-3">{{'config.device.latitude' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.lat" x-field-help="config.device.lat.help"
						   x-field-errors="" x-field-default-value="item.parent.config.lat"/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-sm-3">{{'config.device.lon' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.lon" x-field-help="config.device.lon.help"
						   x-field-errors="" x-field-default-value="item.parent.config.lon"/>
				</div>
			</div>
		</div>

		<div class="form-group">
			<label class="control-label col-sm-3">{{'config.tags' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="text" x-ng-model="item.config.tags"
						   x-field-help="config.input.tags.help" x-field-errors=""/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type==='energy-report'">
			<label class="control-label col-sm-3">{{'config.device.lang' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<select class="form-control"
							x-ng-model="item.config.lang"
							x-ng-options="o.value as (o.label | i18n) for o in ui.langs"
							x-field-errors=""
							x-field-help="config.device.lang.help"></select>
				</div>
			</div>
		</div>

		<div class="form-group"
			 x-ng-if="item.config.cronExpression !== undefined && item.config.type!='alarm' && item.config.type!='control' && item.config.type!='energy-monitor'">
			<div ng-switch on="item.config.type">
				<label class="control-label col-sm-3" style="margin-top: 16px;" x-ng-switch-when="energy-report">{{'config.device.energy-report.cronExpression'
				| i18n}}</label>
				<label class="control-label col-sm-3" style="margin-top: 16px;" x-ng-switch-when="math">{{'config.device.math.cronExpression'
				| i18n}}</label>
				<label class="control-label col-sm-3" style="margin-top: 16px;" x-ng-switch-when="prepaid">{{'config.device.prepaid.cronExpression'
				| i18n}}</label>
				<label class="control-label col-sm-3" style="margin-top: 16px;" x-ng-switch-default>&nbsp</label>
			</div>
			<div class="col-sm-8" x-config-form-cron-expression="item.config.cronExpression"
				 x-seconds-enable="item.config.type !== 'energy-report' && item.config.type !== 'math' && item.config.type !== 'prepaid'"
				 x-minutes-enable="item.config.type !== 'energy-report'"
				 x-null-enable="item.config.type !== 'energy-report'"></div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'energy-report' || item.config.type === 'energy-monitor'">
			<div class="col-sm-offset-3 col-sm-7">
				<div class="checkbox">
					<label>
						<input type="checkbox" x-ng-model="item.config.fileEnable"
							   x-field-errors=""/>
						{{'config.energy-report.fileEnable' | i18n}}
					</label>
				</div>
			</div>
			<div class="col-sm-1">
				<button type="button" uib-popover="{{'config.energy-report.fileEnable.help' | i18n}}"
						popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
				</button>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.fileEnable">
			<div ng-switch on="item.config.type">
				<label class="control-label col-sm-3" style="margin-top: 16px;">{{'config.device.energy-report.fileCronExpression' | i18n}}</label>
			</div>
			<div class="col-sm-8" x-config-form-cron-expression="item.config.fileCronExpression"
				 x-seconds-enable="item.config.type !== 'energy-report' && item.config.type !== 'math' && item.config.type !== 'prepaid'"
				 x-minutes-enable="item.config.type !== 'energy-report'"
				 x-null-enable="item.config.type !== 'energy-report'"></div>
		</div>


		<div class="form-group" x-ng-if="item.config.type === 'energy-report' || item.config.type === 'energy-monitor'">
			<div class="col-sm-offset-3 col-sm-7">
				<div class="checkbox">
					<label>
						<input type="checkbox" x-ng-disabled="!ui.isEmailNotifier" x-ng-model="item.config.emailEnable"
							   x-field-errors=""/>
						{{'config.energy-report.emailEnable' | i18n}}
					</label>
					<small class="text-danger" x-ng-if="!ui.isEmailNotifier">
						<br/>{{'config.momentRule.notifier.noNotifier' | i18n}}
					</small>
				</div>
			</div>
			<div class="col-sm-1">
				<button type="button" uib-popover="{{'config.energy-report.emailEnable.help' | i18n}}"
						popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
				</button>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.emailEnable">
			<div ng-switch on="item.config.type">
				<label class="control-label col-sm-3" style="margin-top: 16px;">{{'config.device.energy-report.emailCronExpression' | i18n}}</label>
			</div>
			<div class="col-sm-8" x-config-form-cron-expression="item.config.emailCronExpression"
				 x-seconds-enable="item.config.type !== 'energy-report' && item.config.type !== 'math' && item.config.type !== 'prepaid'"
				 x-minutes-enable="item.config.type !== 'energy-report'"
				 x-null-enable="item.config.type !== 'energy-report'"></div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'energy-report' || item.config.type === 'energy-monitor'">
			<label class="control-label col-sm-3">{{'config.energy-report.recivers' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-disabled="!ui.isEmailNotifier || !item.config.emailEnable"
						   x-ng-model="item.config.recivers" x-field-help="config.energy-report.recivers.help"
						   x-field-errors="" x-min="1" x-required=""/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'mbsat'">
			<label class="control-label col-sm-3">{{'config.device.lat' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" x-ng-model="item.config.lat" x-field-errors=""
						   x-field-help="config.device.lat.help"/>
				</div>
			</div>
		</div>
		<div class="form-group" x-ng-if="item.config.type === 'mbsat'">
			<label class="control-label col-sm-3">{{'config.device.lat' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.lat" x-field-errors=""
						   x-field-help="config.device.lat.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'mbsat'">
			<label class="control-label col-sm-3">{{'config.device.lon' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.lon" x-field-errors="" x-field-help="config.device.lon.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'mbsat'">
			<label class="control-label col-sm-3">{{'config.device.fixedCost' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" step="0.0001" type="number" x-ng-model="item.config.fixedCost" x-field-errors="" x-field-help="config.device.fixedCost.help"/>
				</div>
			</div>
		</div>

		<div class="form-group" x-ng-if="item.config.type === 'mbsat'">
			<label class="control-label col-sm-3">{{'config.device.variableCost' | i18n}}</label>
			<div class="col-sm-8">
				<div class="input-group">
					<input class="form-control" type="number" x-ng-model="item.config.variableCost" x-field-errors="" x-field-help="config.device.variableCost.help"/>
				</div>
			</div>
		</div>
	</div>

	<div class="col-sm-4">
		<div class="thumbnail">
			<img alt="" src="" x-ng-src="{{ui.model.iconUrl}}"/>
			<div class="caption">
				<span class="caption-label">
					{{'config.device.manufacturer.' + ui.model.manufacturer | i18n}} {{'config.device.model.' + ui.model.model | i18n}}
				</span>
				<a class="btn btn-default" href="#" target="_blank" x-ng-href="{{ui.model.datasheetUrl}}"
				   x-ng-show="ui.model.datasheetUrl">
					{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
				</a>
			</div>
		</div>
	</div>
</form>

<form class="form-inline" x-ng-if="item.config.type==='energy-report'">
	<div class="panel panel-default">
		<div class="panel-heading">
			<div class="panel-title">
				<i class="fa fa-fw fa-filter"></i>&nbsp;{{'dashboard.widgets.config.widget.settings' | i18n}}
			</div>
		</div>
		<div class="panel-body">
			<div class="form-group">
				<label class="form-group-label" x-label-help="dashboard.widgets.config.columns"></label>
				<input type="number" class="form-control"
					   placeholder="{{'dashboard.widgets.config.columns' | i18n }}"
					   x-ng-model="item.config.columns" x-min="1" x-required=""/>
			</div>
			<div class="form-group">
				<label class="form-group-label" x-label-help="dashboard.widgets.config.totals"></label>
				<select class="form-control"
						x-ng-model="item.config.totals" x-ng-options="b.value as (b.label | i18n) for b in ui.booleans"
						x-required="">
				</select>
			</div>
		</div>
	</div>
	<div class="panel panel-default">
		<div class="panel-heading">{{'report.table.visibleColumns' | i18n}}</div>
		<div class="panel-body">
			<div class="col-xs-6">
				<ul class="list-group" style="margin-bottom: 0;">
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.title')"
									   x-ng-click="ui.setColumnVisibility('energyReport.title', 0)"/>
								&nbsp;{{'config.param.label' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.device')"
									   x-ng-click="ui.setColumnVisibility('energyReport.device', 0)"/>
								&nbsp;{{'config.device.deviceName' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.descr1')"
									   x-ng-click="ui.setColumnVisibility('energyReport.descr1', 0)"/>
								&nbsp;{{'config.device.descr1' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.descr2')"
									   x-ng-click="ui.setColumnVisibility('energyReport.descr2', 0)"/>
								&nbsp;{{'config.device.descr2' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.descr3')"
									   x-ng-click="ui.setColumnVisibility('energyReport.descr3', 0)"/>
								&nbsp;{{'config.device.descr3' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.param')"
									   x-ng-click="ui.setColumnVisibility('energyReport.param', 0)"/>
								&nbsp;{{'report.table.param' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.paramDesc')"
									   x-ng-click="ui.setColumnVisibility('energyReport.paramDesc', 0)"/>
								&nbsp;{{'report.table.paramDesc' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.totalIncrease')"
									   x-ng-click="ui.setColumnVisibility('energyReport.totalIncrease', 0)"/>
								&nbsp;{{'report.table.totalIncrease' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.avgIncrease')"
									   x-ng-click="ui.setColumnVisibility('energyReport.avgIncrease', 0)"/>
								&nbsp;{{'report.table.avgIncrease' | i18n}}
							</label>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-xs-6">
				<ul class="list-group" style="margin-bottom: 0;">
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.avgPower')"
									   x-ng-click="ui.setColumnVisibility('energyReport.avgPower', 0)"/>
								&nbsp;{{'report.table.avgPower' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.totalCost')"
									   x-ng-click="ui.setColumnVisibility('energyReport.totalCost', 0)"/>
								&nbsp;{{'report.table.totalCost' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.avgCost')"
									   x-ng-click="ui.setColumnVisibility('energyReport.avgCost', 0)"/>
								&nbsp;{{'report.table.avgCost' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.date')"
									   x-ng-click="ui.setColumnVisibility('energyReport.date', 0)"/>
								&nbsp;{{'report.table.timestamp' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.value')"
									   x-ng-click="ui.setColumnVisibility('energyReport.value', 1)"/>
								&nbsp;{{'energyReport.valueColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.increase')"
									   x-ng-click="ui.setColumnVisibility('energyReport.increase', 2)"/>
								&nbsp;{{'energyReport.increaseColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.power')"
									   x-ng-click="ui.setColumnVisibility('energyReport.power', 2)"/>
								&nbsp;{{'energyReport.powerColumn' | i18n}}
							</label>
						</div>
					</li>
					<li class="list-group-item">
						<div class="checkbox">
							<label>
								<input type="checkbox"
									   x-ng-checked="ui.isChecked('energyReport.cost')"
									   x-ng-click="ui.setColumnVisibility('energyReport.cost', 2)"/>
								&nbsp;{{'energyReport.costColumn' | i18n}}
							</label>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="panel panel-default">
		<div class="panel-heading">{{'dashboard.widgets.config.widget.series.settings' | i18n}}</div>
		<div class="panel-body">

			<ul class="nav nav-tabs" style="cursor: pointer">
				<li role="presentation" x-ng-class="{'active' : ui.visibleMode===0}" x-ng-click="ui.visibleMode=0"><a>{{'energyReport.increaseColumn' | i18n}}</a></li>
				<li role="presentation" x-ng-class="{'active' : ui.visibleMode===1}" x-ng-click="ui.visibleMode=1"><a>{{'energyReport.powerColumn' | i18n}}</a></li>
				<li role="presentation" x-ng-class="{'active' : ui.visibleMode===2}" x-ng-click="ui.visibleMode=2"><a>{{'energyReport.costColumn' | i18n}}</a></li>
			</ul>

			<br/>

			<div class="form-group">
				<label class="form-group-label" x-label-help="config.energyReport.renderMode"></label>
				<select x-ng-if="ui.visibleMode === 0"
						class="form-control"
						x-ng-model="item.config.mode"
						x-ng-options="mode.value as mode.label for mode in ui.modes"
						x-required="">
				</select>
				<select x-ng-if="ui.visibleMode === 1"
						class="form-control"
						x-ng-model="item.config.modePower"
						x-ng-options="mode.value as mode.label for mode in ui.modes"
						x-required="">
				</select>
				<select x-ng-if="ui.visibleMode === 2"
						class="form-control"
						x-ng-model="item.config.modeCost"
						x-ng-options="mode.value as mode.label for mode in ui.modes"
						x-required="">
				</select>
			</div>

			<div>
				<div x-ng-repeat="param in item.config.params" x-ng-if="!param.master" style="border-width:0px; padding:10px 0px">
					<hr class="divider" style="margin-top: 10px;"></hr>
					<form class="form form-inline">
						<label class="form-group-label">&nbsp;{{param.fullLabel}} ({{param.label||param.name}})</label>
						<div class="form-group" style="width:100%">
							<div class="form-group">
								<label class="form-group-label" x-label-help="config.param.display"></label>
								<select class="form-control"
										x-ng-model="param.display"
										x-ng-options="o.value as (o.label | i18n) for o in ui.visibilites"
										x-field-errors=""></select>
							</div>
							<div class="form-group">
								<label class="form-group-label" x-label-help="config.param.enabled"></label>
								<select class="form-control"
										x-ng-model="param.enabled"
										x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
										x-field-errors=""></select>
							</div>
							<div class="form-group">
								<label class="form-group-label"
									   x-label-help="dashboard.widgets.config.cost"></label>
								<div class="input-group">
									<input class="form-control" style="width: 80px"
										   x-ng-model="param.cost"
										   data-scale-formatter="param.scale"
										   data-format-scale="-param.scale"
										   data-format-precision="4"
										   x-ng-disabled="!ui.isChecked('energyReport.cost')"></input>
									<data-unit-addon data-unit-addon-scale="0"
													 data-unit-addon-unit="(param.costUnit?param.costUnit:'') + '/' + ui.getParamUnit(param)"></data-unit-addon>
								</div>
							</div>
							<div class="form-group">
								<label class="form-group-label"
									   x-label-help="dashboard.widgets.config.costUnit"></label>
								<input type="text" class="form-control" style="width: 50px"
									   x-ng-model="param.costUnit"
									   x-ng-disabled="!ui.isChecked('energyReport.cost')"></input>
							</div>
						</div>

						<!--                                //Increse-->
						<div x-ng-if="item.config.mode>=2 && ui.visibleMode===0" style="padding-top:10px">
							<label class="form-group-label" x-label-help="dashboard.widgets.gauge.colorConfig">
                                    <span uib-popover="{{'dashboard.widgets.config.zone.copy' | i18n }}"
										  popover-trigger="mouseenter" popover-placement="top">
                                        <i class="fa fa-files-o text-primary" x-ng-click="ui.copyZones(param.zones)"
										   aria-hidden="true"></i>
                                    </span>
							</label>

							<div class="row" x-ng-repeat="zone in param.zones"
								 ng-init="serieIndex = $parent.$index">
								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.min"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.min' | i18n
										}}</label>
									<div class="input-group">
										<input class="form-control"
											   name="widgetsConfigZoneMin-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.min' | i18n }}"
											   x-ng-model="zone.from"
											   x-required=""
											   data-scale-formatter=""
											   data-format-precision="param.precision"
											   data-format-scale="param.scale"
											   data-validate="{'dashboard.widgets.config.error.minMax': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="param.scale"
														 data-unit-addon-unit="param.quantity | quantityUnit"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.max"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.max' | i18n
										}}</label>
									<div class="input-group">
										<input class="form-control"
											   name="widgetsConfigZoneMax-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.max' | i18n }}"
											   x-ng-model="zone.to"
											   data-scale-formatter=""
											   data-format-scale="param.scale"
											   data-format-precision="param.precision"
											   x-required=""
											   data-validate="{'dashboard.widgets.config.error.maxMin': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="param.scale"
														 data-unit-addon-unit="param.quantity | quantityUnit"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-2">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.color"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.color' |
									i18n }}</label>
									<spectrum-colorpicker format="hex" x-ng-model="zone.color"
														  options="ui.colorPickerOpts || param.unit || {showInput : true} "/>
								</div>

								<div class="form-group col-sm-1">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-danger"
											x-ng-click="ui.removeZone(serieIndex, $index);"
											title="{{'ui.delete' | i18n}}">
										<i class="fa fa-trash-o fa-lg"></i>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-sm-1 col-sm-offset-10">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-success" x-ng-click="ui.addZone($index);">
										<i class="fa fa-plus fa-lg"></i>
									</button>
								</div>
							</div>
						</div>
						<!--                                /*Power*/-->
						<div x-ng-if="item.config.modePower>=2 && ui.visibleMode===1" style="padding-top:10px">
							<label class="form-group-label" x-label-help="dashboard.widgets.gauge.colorConfig">
                                    <span uib-popover="{{'dashboard.widgets.config.zone.copy' | i18n }}"
										  popover-trigger="mouseenter" popover-placement="top">
                                        <i class="fa fa-files-o text-primary" x-ng-click="ui.copyZonesPower(param.zonesPower)"
										   aria-hidden="true"></i>
                                    </span>
							</label>

							<div class="row" x-ng-repeat="zone in param.zonesPower"
								 ng-init="serieIndex = $parent.$index">
								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.min"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.min' | i18n
										}}</label>
									<div class="input-group">
										<input class="form-control"
											   name="widgetsConfigZoneMin-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.min' | i18n }}"
											   x-ng-model="zone.from"
											   x-required=""
											   data-scale-formatter=""
											   data-format-precision="param.precision"
											   data-format-scale="param.scale"
											   data-validate="{'dashboard.widgets.config.error.minMax': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="param.scale"
														 data-unit-addon-unit="param.quantity | quantityUnit"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.max"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.max' | i18n
										}}</label>
									<div class="input-group">
										<input class="form-control"
											   name="widgetsConfigZoneMax-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.max' | i18n }}"
											   x-ng-model="zone.to"
											   data-scale-formatter=""
											   data-format-scale="param.scale"
											   data-format-precision="param.precision"
											   x-required=""
											   data-validate="{'dashboard.widgets.config.error.maxMin': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="param.scale"
														 data-unit-addon-unit="param.quantity | quantityUnit"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-2">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.color"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.color' |
									i18n }}</label>
									<spectrum-colorpicker format="hex" x-ng-model="zone.color"
														  options="ui.colorPickerOpts || param.unit || {showInput : true} "/>
								</div>

								<div class="form-group col-sm-1">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-danger"
											x-ng-click="ui.removeZonePower(serieIndex, $index);"
											title="{{'ui.delete' | i18n}}">
										<i class="fa fa-trash-o fa-lg"></i>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-sm-1 col-sm-offset-10">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-success" x-ng-click="ui.addZonePower($index);">
										<i class="fa fa-plus fa-lg"></i>
									</button>
								</div>
							</div>
						</div>

						<!--                                /*Cost*/-->
						<div x-ng-if="item.config.modeCost>=2 && ui.visibleMode===2" style="padding-top:10px">
							<label class="form-group-label" x-label-help="dashboard.widgets.gauge.colorConfig">
                                    <span uib-popover="{{'dashboard.widgets.config.zone.copy' | i18n }}"
										  popover-trigger="mouseenter" popover-placement="top">
                                        <i class="fa fa-files-o text-primary" x-ng-click="ui.copyZonesCost(param.zonesCost)"
										   aria-hidden="true"></i>
                                    </span>
							</label>

							<div class="row" x-ng-repeat="zone in param.zonesCost"
								 ng-init="serieIndex = $parent.$index">
								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.min"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.min' | i18n
										}}</label>
									<div class="input-group">
										<input class="form-control"
											   name="widgetsConfigZoneMin-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.min' | i18n }}"
											   x-ng-model="zone.from"
											   x-required=""
											   data-scale-formatter=""
											   data-format-precision="param.precision"
											   data-format-scale="param.scale"
											   data-validate="{'dashboard.widgets.config.error.minMax': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="param.scale"
														 data-unit-addon-unit="param.quantity | quantityUnit"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-4">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.max"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.max' | i18n
										}}</label>
									<div class="input-group">
										<input class="form-control"
											   name="widgetsConfigZoneMax-{{serieIndex}}-{{$index}}"
											   placeholder="{{'dashboard.widgets.config.zone.max' | i18n }}"
											   x-ng-model="zone.to"
											   data-scale-formatter=""
											   data-format-scale="param.scale"
											   data-format-precision="param.precision"
											   x-required=""
											   data-validate="{'dashboard.widgets.config.error.maxMin': zone.from > zone.to}"/>
										<data-unit-addon data-unit-addon-scale="param.scale"
														 data-unit-addon-unit="param.quantity | quantityUnit"></data-unit-addon>
									</div>
								</div>

								<div class="form-group col-sm-2">
									<!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.color"></label>-->
									<label class="form-group-label">{{'dashboard.widgets.config.zone.color' |
									i18n }}</label>
									<spectrum-colorpicker format="hex" x-ng-model="zone.color"
														  options="ui.colorPickerOpts || param.unit || {showInput : true} "/>
								</div>

								<div class="form-group col-sm-1">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-danger"
											x-ng-click="ui.removeZoneCost(serieIndex, $index);"
											title="{{'ui.delete' | i18n}}">
										<i class="fa fa-trash-o fa-lg"></i>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="form-group col-sm-1 col-sm-offset-10">
									<label class="form-group-label">&nbsp;</label>
									<button class="btn btn-success" x-ng-click="ui.addZoneCost($index);">
										<i class="fa fa-plus fa-lg"></i>
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	</div>
</form>

<form class="form-inline"
	  x-ng-if="advanceView.value && (item.config.type!='prepaid' && item.config.type!='alarm' && item.config.type!='control')">
	<div class="panel panel-default">
		<div class="panel-heading">
			<div class="panel-title">
				<i class="fa fa-fw fa-filter"></i>&nbsp;{{'config.momentRules.simple' | i18n}}
			</div>
		</div>
		<div>
			<table class="table table-bordered table-condensed" style="margin-bottom: 0px;">
				<thead>
				<tr>
					<th>{{'config.momentRules.outputName' | i18n}}</th>
					<th>{{'config.momentRules.default' | i18n}}</th>
					<th>{{'config.momentRules.deadband' | i18n}}</th>
					<th>{{'config.momentRules.force' | i18n}}</th>
					<th>{{'config.momentRules.activateAll' | i18n}}</th>
					<th>{{'config.momentRules.deactivateAll' | i18n}}</th>
				</tr>
				</thead>
				<tbody>
				<tr x-ng-repeat="o in config.engine.measurementOutputs | orderBy : 'name'">
					<td style="vertical-align: middle;" class="col-xs-2">
						{{o.label||o.name}}
					</td>
					<td style="text-align: center;" x-ng-if="ui.isOutputActive(o)" class="col-xs-1"
						x-ng-click="ui.deactivateOutput(o)">
						<i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
					</td>
					<td style="text-align: center;" x-ng-if="!ui.isOutputActive(o)" class="col-xs-1"
						x-ng-click="ui.activateOutput(o)">
						<i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
					</td>
					<td class="col-xs-1">
						<input class="form-control" x-ng-model="o.deadBand[0]"/>
					</td>
					<td class="col-xs-1">
						<input class="form-control" x-ng-model="o.deadBand[1]"/>
					</td>
					<td class="col-xs-3">
						<div class="btn-group" role="group" style="width: 100%;">
							<button class="btn btn-xs btn-justborder btn-success"
									x-ng-click="ui.activateOutputInDevice(o,true)">
								<i class="fa fa-plus"></i>&nbsp;{{'config.momentRules.activateAll.all' | i18n}}
							</button>
							<button class="btn btn-xs btn-justborder btn-success"
									x-ng-click="ui.activateOutputInDevice(o,false)">
								<i class="fa fa-plus"></i>&nbsp;{{'config.momentRules.activateAll.selected' | i18n}}
							</button>
						</div>
					</td>
					<td class="col-xs-3">
						<div class="btn-group" role="group" style="width: 100%;">
							<button class="btn btn-xs btn-justborder btn-danger"
									x-ng-click="ui.deactivateOutputInDevice(o, true)">
								<i class="fa fa-times"></i>&nbsp;{{'config.momentRules.deactivateAll.all' | i18n}}
							</button>
							<button class="btn btn-xs btn-justborder btn-danger"
									x-ng-click="ui.deactivateOutputInDevice(o, false)">
								<i class="fa fa-times"></i>&nbsp;{{'config.momentRules.deactivateAll.selected' | i18n}}
							</button>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
		<div x-ng-repeat="param in item.config.params">
			<div x-config-form-moment-rule-simple="param.momentRules" x-config="config" x-hide="$index==0"
				 x-param="param"
				 x-params="item.config.params"></div>
		</div>
	</div>
</form>

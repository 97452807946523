var module = angular.module('meternet.scores.services', []);

module.service('scoresService', function($http, $q, configService) {
    var self = this;
    configService.get().then(function(config) {
        var inputs = config.engine.measurementInputs;
        var result = [];
        for (var i = 0; i < inputs.length; ++i) {
            var devices = inputs[i].devices;
            for (var j = 0; j < devices.length; ++j) {
                var params = devices[j].params;
                for(var k = 0; k < params.length; k++) {
                    result.push(params[k]);
                }
            }
        }
        self.parameters = result;
    });

    return {
        get: function() {
            var deferred = $q.defer();
            $http.get('/parameterScores').then(function(result) {
                var data = result.data;
                for (var i = 0; i < data.length; ++i) {
                    var param = _.find(self.parameters, function(parameter) {
                        return parameter.id === data[i].paramId;
                    });
                    if (param) {
                        data[i].name = param.name;
                    }
                }
               deferred.resolve(data);
            });
            return deferred.promise;
        },
        add: function(score) {
            var deferred = $q.defer();
            $http.post('/addParameterScore', score).then(function(result) {
                deferred.resolve(result);
            }, function(result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        },
        update: function(score) {
            var deferred = $q.defer();
            $http.post('/editParameterScore', score).then(function(result) {
                deferred.resolve(result);
            }, function(result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        },
        remove: function(score) {
            var deferred = $q.defer();
            $http.post('/removeParameterScore', score).then(function(result) {
                deferred.resolve(result);
            });
            return deferred.promise;
        },
        getScores: function(dateFrom, dateTo) {
            var deferred = $q.defer();
            $http({
                url: '/getScores',
                method:'GET',
                params: {
                    dateFrom: dateFrom.toISOString(),
                    dateTo: dateTo.toISOString()
                }
            }).success(function(result) {
                return deferred.resolve(result);
            });
            return deferred.promise;
        }
    }

});
<div class="panel panel-default config-form" x-refreshable="item">
    <div class="panel-heading">
        <div class="panel-title">
            <i class="fa fa-fw" x-ng-class="item.icon()"></i>
            <span>{{item.name | i18n}}</span>
        </div>
    </div>
    <div class="panel-body main">
        <ul class="nav nav-tabs" x-ng-if="item.kind != 'eventSource' && !item.external && ui.view!='add'" style="margin-bottom: 10px;">
            <li role="tab" x-ng-class="{'active': ui.view==='edit'}" x-ng-click="ui.view='edit'">
                <a href="">
                    <i class="fa fa-wrench"></i>&nbsp;<span>{{'config.tab.settings' | i18n}}</span>
                </a>
            </li>
            <li role="tab" x-ng-class="{'active': ui.view==='events'}" x-ng-click="ui.view='events'" x-ng-if="item.config.hasEvents && license.properties.AUDIT_ENABLED">
                <a href="">
                    <i class="fa fa-bell"></i>&nbsp;<span>{{'config.tab.events' | i18n}}</span>
                </a>
            </li>
            <!--<li role="tab" x-ng-class="{'active': ui.view==='status'}" x-ng-click="ui.view='status'">-->
                <!--<a href="">-->
                    <!--<i class="fa fa-check"></i>&nbsp;<span>{{'config.tab.status' | i18n}}</span>-->
                <!--</a>-->
            <!--</li>-->
        </ul>

        <div x-ng-switch="item.kind" x-ng-if="ui.view==='edit'">
            <div x-ng-switch-when="measurementInput" x-config-form-measurement-input=""></div>
            <div x-ng-switch-when="moduleInput" x-config-form-module-input=""></div>
			<div x-ng-switch-when="mediaCosts" x-config-form-media-costs=""></div>
            <div x-ng-switch-when="device" x-config-form-device=""></div>
            <div x-ng-switch-when="parameter" x-config-form-parameter=""></div>
            <div x-ng-switch-when="group" x-config-form-group=""></div>
            <div x-ng-switch-when="measurementOutput" x-config-form-measurement-output=""></div>
            <div x-ng-switch-when="eventInput" x-config-form-event-input=""></div>
            <div x-ng-switch-when="eventOutput" x-config-form-event-output=""></div>
            <div x-ng-switch-when="notifier" x-config-form-notifier=""></div>
            <div x-ng-switch-when="database" x-config-form-database=""></div>
            <div x-ng-switch-when="network" x-config-form-network=""></div>
            <div x-ng-switch-when="ntp" x-config-form-ntp=""></div>
            <div x-ng-switch-when="time" x-config-form-time=""></div>
            <div x-ng-switch-when="license" x-config-form-license=""></div>
            <div x-ng-switch-when="backup" x-config-form-backup=""></div>
            <div x-ng-switch-when="firmware" x-config-form-firmware=""></div>
            <div x-ng-switch-when="storage" x-config-form-storage=""></div>
            <div x-ng-switch-when="support" x-config-form-support=""></div>
            <div x-ng-switch-when="eventSource" x-config-form-event-rules=""></div>
            <div x-ng-switch-when="app" x-config-form-app=""></div>

            <div class="clearfix"></div>
        </div>

        <div x-ng-if="ui.view==='events' && item.config.hasEvents">
            <div x-config-form-event-rules=""></div>

            <div class="clearfix"></div>
        </div>

        <div x-ng-switch="item.kind" x-ng-if="ui.view==='status'">
            <div>status</div>

            <div class="clearfix"></div>
        </div>

        <div x-ng-switch="item.kind" x-ng-if="ui.view==='add'">
            <div x-ng-switch-when="measurementInputs" x-config-form-measurement-inputs=""></div>
            <div x-ng-switch-when="moduleInputs" x-config-form-module-inputs=""></div>
            <div x-ng-switch-when="measurementOutputs" x-config-form-measurement-outputs=""></div>
            <div x-ng-switch-when="eventInputs" x-config-form-event-inputs=""></div>
            <div x-ng-switch-when="eventOutputs" x-config-form-event-outputs=""></div>
            <div x-ng-switch-when="databases" x-config-form-databases=""></div>
            <div x-ng-switch-when="notifiers" x-config-form-notifiers=""></div>
            <div x-ng-switch-when="network" x-config-form-network=""></div>
            <div x-ng-switch-when="ntp" x-config-form-ntp=""></div>
            <div x-ng-switch-when="time" x-config-form-time=""></div>
            <div x-ng-switch-when="measurementInput" x-config-form-devices=""></div>
            <div x-ng-switch-when="moduleInput" x-config-form-devices=""></div>
            <div x-ng-switch-when="device" x-config-form-parameters=""></div>
            <div x-ng-switch-when="astralClock" x-config-astral-clock=""></div>

            <div class="clearfix"></div>
        </div>
    </div>

    <div class="panel-footer clearfix" style="min-height: 41px;">
        <button class="btn btn-danger pull-right" x-ng-show="item.config.id" x-ng-disabled="!item.removable" x-ng-click="ui.remove(item)" x-ng-if="ui.view!='add'">
            <i class="fa fa-trash"></i>&nbsp;<span>{{'config.remove' | i18n}}</span>
        </button>

        <span x-ng-switch="item.kind" x-ng-if="ui.view!='add'">
            <span x-ng-switch-when="measurementInput">
                <button class="btn btn-success" x-ng-disabled="!item.config.devicesAddable" x-ng-click="ui.view='add'" x-ng-if="item.config.type!='energy-report' && item.config.type!='energy-monitor' && item.config.type!='math'">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.device.add' | i18n}}</span>
                </button>
            </span>

            <span x-ng-switch-when="device">
                <button class="btn btn-success" x-ng-disabled="!ui.canAdd()" x-ng-show="ui.canAdd()" x-ng-click="ui.view='add'" x-ng-if="item.config.type!='energy-report' && item.config.type!='energy-monitor' && item.config.type!='prepaid' && item.config.type!='alarm' && item.config.type!='control' && item.config.type!='dlms'">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.parameter.add' | i18n}}</span>
                </button>
            </span>
        </span>
        <span x-ng-switch="item.kind" x-ng-if="ui.view==='add'">
            <span x-ng-switch-when="measurementInputs">
                <button class="btn btn-success" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.measurementInput.add' | i18n}}</span>
                </button>
            </span>
            <span x-ng-switch-when="moduleInputs">
                <button class="btn btn-success" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.moduleInput.add' | i18n}}</span>
                </button>
            </span>
            <span x-ng-switch-when="measurementOutputs">
                <button class="btn btn-success" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.measurementOutput.add' | i18n}}</span>
                </button>
            </span>
            <span x-ng-switch-when="databases">
                <button class="btn btn-success" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.database.add' | i18n}}</span>
                </button>
            </span>
            <span x-ng-switch-when="notifiers">
                <!--<button class="btn btn-success" x-ng-disabled="!ui.canAdd()" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">-->
                    <!--<i class="fa fa-plus"></i>&nbsp;<span>{{'config.notifier.add' | i18n}}</span>-->
                <!--</button>-->
            </span>
            <span x-ng-switch-when="measurementInput">
                <button class="btn btn-success" x-ng-disabled="!ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.device.add' | i18n}}</span>
                </button>
                <input class="form-control" type="number" x-ng-model="ui.count" x-ng-if="ui.canAddMultiple()" style="width:50px; display: inline;">
                <button class="btn btn-default pull-right" x-ng-click="ui.view='edit'">
                    <i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'config.cancel' | i18n}}</span>
                </button>
                <span x-ng-if="item.config.type==='mbsat'">
                    <button class="btn btn-default"  x-ng-disabled="!ui.canDetect()" x-ng-click="ui.detect()" data-toggle="tooltip" data-placement="top" ng-attr-title="{{'config.measurementInput.mbsat.autodetect.unsavedConfig' | i18n}}">
                        <i class="fa fa-refresh"></i> <span>{{'config.measurementInput.mbsat.autodetect' | i18n}}</span>
                    </button>
                </span>
            </span>
            <span x-ng-switch-when="device">
                <button class="btn btn-success" x-ng-disabled="!ui.canAdd()" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.parameter.add' | i18n}}</span>
                </button>
                <input class="form-control" type="number" x-ng-model="ui.count" x-ng-disabled="!ui.canAdd()" x-ng-if="ui.canAdd()" style="width:50px; display: inline;">
                <button class="btn btn-default pull-right" x-ng-click="ui.view='edit'">
                    <i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'config.cancel' | i18n}}</span>
                </button>
            </span>
            <span x-ng-switch-default="">
                <button class="btn btn-success" x-ng-disabled="!ui.canAdd()" x-ng-show="ui.canAdd()" x-ng-click="ui.add()">
                    <i class="fa fa-plus"></i>&nbsp;<span>{{'config.add' | i18n}}</span>
                </button>
                <button class="btn btn-default" x-ng-click="ui.view='edit'">
                    <i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'config.cancel' | i18n}}</span>
                </button>
            </span>
        </span>

        <span x-ng-if="ui.options" x-ng-repeat="option in ui.options">
            <input class="form-control" type="number" x-ng-model="option.count" x-ng-disabled="!option.enabled" x-ng-if="option.input" style="width:50px; display: inline;">
            <button class="btn"  x-ng-click="option.click(option.count)" x-ng-disabled="!option.enabled" x-ng-class="option.css">
                <i class="fa" x-ng-class="option.icon"></i>&nbsp;<span>{{option.label | i18n}}</span>
            </button>
        </span>
    </div>
</div>

<section x-ng-if="!item.external">
    <div class="alert alert-danger clearfix" x-ng-if="errors.hasGlobalErrors('')">
        <div x-ng-repeat="e in errors.getGlobalErrors('')">
            <h4 style="margin: 0; text-align: center;">{{e.message | i18n:e.arguments}}</h4>
        </div>
    </div>
</section>

    <div class="alert clearfix" x-ng-if="ui.message" x-ng-class="ui.message.css">
        <h4 style="margin: 0; text-align: center;">{{ui.message.text | i18n}}</h4>
    </div>

<section x-ng-if="!item.external">
    <div class="alert alert-info clearfix">
        <div class="row">
            <div class="col-sm-6">
                <div>
                    <span>{{'license.usage.tokens' | i18n}}</span>
                    <div class="progress">
                        <div class="progress-bar"
                             role="progressbar"
                             aria-valuenow="{{usage.tokens.total}}"
                             aria-valuemin="0"
                             aria-valuemax="{{usage.tokens.max}}"
                             style="min-width: 60px"
                             x-ng-style="{'width': (100 * usage.tokens.total / usage.tokens.max) + '%'}">
                            {{usage.tokens.total}}&nbsp;/&nbsp;{{usage.tokens.max}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="pull-right">
                    <label class="checkbox-inline" style="margin-right: 10px;" x-ng-if="ui.advanceView">
                        <input type="checkbox" x-ng-model="ui.liveValidation">
                        {{::'config.liveValidation' | i18n}}
                    </label>
                    <button class="btn btn-lg btn-primary" x-ng-click="ui.save()" x-ng-disabled="ui.processing || ui.optimisticLock">
                        <i class="fa fa-save"></i>&nbsp;<span>{{'config.save' | i18n}}</span>
                    </button>
                    <button class="btn btn-lg btn-default" x-ng-click="ui.cancel()" x-ng-disabled="ui.processing">
                        <i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'config.cancel' | i18n}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>

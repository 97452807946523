<div class="custom-time-picker">
    <div class="form-group">
        <label class="form-group-label" x-label-help="{{label}}"></label>
        <div class="form-inline">
            <div class="form-group">
                <div class="with-addon">
                    </select>
                    <button type="button" class="btn input-group-addon" x-ng-click="ui.toggleDir()">
                        <i ng-class="ui.getIcon()"></i>
                    </button>
                    <div class="input-group">
                        <input type="text" class="form-control" x-ng-model="value.shift" x-datetimepicker="{{format}}" ng-disabled="value.dir === 'none'">
                        <span class="input-group-addon flex-icon">
                            <i class="{{icon}}"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
	<div class="panel panel-default" x-ng-if="!config.hideReportId">
		<div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
		<div class="panel-body">
			<form class="form form-horizontal">
				<div class="form-group">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.report"></label>
					<select class="form-control" style="min-width: 200px" x-ng-model="ui.report" name="widgetsConfigReport" x-ng-options="report as report.label for report in reports"
							x-ng-change="ui.updateReport()" x-required=""/>
				</div>
				<div class="form-group">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.serieMode"></label>
					<select class="form-control" style="min-width: 200px"
							x-ng-model="config.visibleColumn"
							x-ng-options="serie.value as serie.label for serie in ui.serieModes"
							x-ng-change="ui.updateReport()" x-required=""/>
				</div>
			</form>
		</div>
	</div>

	<div class="panel panel-default">
		<div class="panel-heading">{{'report.table.visibleMeters' | i18n}}</div>
		<div class="panel-body">
			<form class="form form-horizontal">
				<div class="form-group">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.energyReportChartMode"></label>
					<select class="form-control" style="min-width: 200px" x-ng-model="config.mode" name="widgetsConfigReport" x-ng-options="mode.value as mode.label for mode in ui.modes"
							x-ng-change="ui.updateReport()" x-required="">
					</select>
				</div>
				<div class="form-group" ng-if="config.mode===0">
					<label class="form-group-label" x-label-help="dashboard.widgets.config.columns"></label>
					<input type="number" class="form-control" name="widgetsConfigColumns" placeholder="{{'dashboard.widgets.config.columns' | i18n }}"
						   data-ng-model="config.columns" x-min="1" x-required="" />
				</div>
			</form>

			<hr class="divider" style="margin-top: 10px;"></hr>
			<div class="row">
				<div class="col-xs-12">
					<ul class="list-group">
						<li class="list-group-item" x-ng-repeat="serie in config.series" style="padding: 5px 20px;">
							<form class="form form-inline">
								<div class="checkbox" ng-if="config.mode===1">
									<label>
<!--										<select class="form-control" style="min-width: 100px"-->
<!--												x-ng-model="serie.display"-->
<!--												x-ng-options="serie.value as serie.label for serie in ui.visibilites"-->
<!--												x-ng-change="ui.updateReport()" x-required=""/>-->
										<input type="checkbox" data-ng-checked="serie.display" data-ng-click="serie.display=!serie.display" style="margin-left:3px; margin-top:5px;">
										&nbsp;{{serie.device}} {{serie.param}} - {{serie.label||serie.name}}
									</label>
								</div>
								<div class="radio" ng-if="config.mode===0">
									<label>
										<input type="radio" name="radioMeter" data-ng-checked="serie.display" data-ng-click="ui.serieToggle(serie)" style="margin-left:3px; margin-top:5px;">
										&nbsp;&nbsp;{{serie.device}} {{serie.param}} - {{serie.label||serie.name}}
									</label>
								</div>
							</form>
						</li>
					</ul>
				</div>

			</div>
		</div>
	</div>
</div>


var module = angular.module('meternet.event.services', []);

module.service('eventService', function ($rootScope, $q, $http, contextPath, dataService) {

    var buffer = {
        events: [],
        indicator: [],
        level: null,
        limit: 100,
        add: function (event) {
            if (this.level == null || event.level >= this.level) {
                this.events.unshift(event);
                while (this.events.length > this.limit) {
                    this.events.pop();
                }
            }
            this.indicator.unshift(event);
        },
        filter: function (level) {
            if (this.level !== level) {
                this.level = level;
                this.events.length = 0;
                this.load();
            }
        },
        load: function () {
            var self = this;
            $http({
                method: 'GET',
                url: contextPath + '/event',
                params: {
                    level: self.level,
                    limit: self.limit
                }
            }).then(function (response) {
                if (response.data) {
                    for (var i = 0; i < response.data.length; ++i) {
                        self.events.push(new Event(response.data[i]));
                    }

                    self.events.sort(function (a, b) {
                        return b.timestamp.getTime() - a.timestamp.getTime();
                    });
                    while (self.events.length > self.limit) {
                        self.events.pop();
                    }
                }
            });
        }
    };

    function Event(data) {
        this.update(data);
    }

    Event.prototype.update = function (data) {
//console.log(data);
        if (data instanceof Array) {
            this.sourceId = data[0];
            this.timestamp = new Date(data[1]);
            this.type = data[2];
            this.level = data[3];
            this.data = data[4];
        } else {
            this.sourceId = data.sourceId;
            this.timestamp = new Date(data.timestamp);
            this.type = data.type;
            this.level = data.level || 0;
            this.data = data.data;
        }
    };

    Event.prototype.levelCssClass = function () {
        switch (this.level) {
            case 0:
                return "info";
            case 1:
                return "warning";
            case 2:
                return "danger";
            default:
                return "active";
        }
    };

    var unsubscribeFn = dataService.subscribe("/user/topic/event", function (data) {
        buffer.add(new Event(data));
    });

    $rootScope.$on('$destroy', unsubscribeFn);

    buffer.load();

    return {
        currentEvents: function () {
            return buffer.events;
        },
        indicatorEvents: function () {
            return buffer.indicator;
        },
        currentLevel: function (level) {
            if (!arguments.length) {
                return buffer.level;
            } else {
                buffer.filter(level);
            }
        },
        findEvents: function (to, level, offset, limit) {
            var deferred = $q.defer();
            if (limit == null) {
                limit = buffer.limit;
            }
            if (offset == null) {
                offset = 0;
            }
            $http({
                method: 'GET',
                url: contextPath + '/event',
                params: {
                    to: to ? to.toISOString() : null,
                    level: level,
                    offset: offset,
                    limit: limit
                }
            }).then(function (response) {
                var i, e = [];
                if (response.data) {
                    for (i = 0; i < response.data.length; ++i) {
                        e.push(new Event(response.data[i]));
                    }
                }
                deferred.resolve(e);
            });
            return deferred.promise;
        }
    };
});
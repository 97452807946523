<div class="row" style="padding-bottom: 15px">
	<div class="col-sm-5">
		<label>{{'readout.oneShoot'| i18n}}:</label>
		<div class="btn-group" role="group" aria-label="...">
			<button type="button" class="btn btn-primary" x-ng-click="ui.oneShoot()" x-ng-disabled="ui.isRunning || ui.isOneShoot || ui.isStarting">
				<i class="fa fa-spinner fa-pulse" aria-hidden="true" x-ng-if="ui.isOneShoot"></i>
				<i class="fa fa-hand-o-down" aria-hidden="true" x-ng-if="!ui.isOneShoot"></i>
				&nbsp;{{'readout.start'| i18n}}
			</button>
		</div>
	</div>
	<div class="col-sm-5">
		<label>{{'readout.cyclical'| i18n}}:</label>
		<div class="btn-group " role="group" aria-label="...">
			<button type="button" class="btn btn-success" x-ng-click="ui.start()" x-ng-disabled="ui.isRunning || ui.isOneShoot || ui.isStarting">
				<i class="fa fa-spinner fa-pulse" aria-hidden="true" x-ng-if="ui.isStarting"></i>
				<i class="fa fa-play" aria-hidden="true" x-ng-if="!ui.isStarting"></i>&nbsp;{{'readout.start'| i18n}}
			</button>
			<button type="button" class="btn btn-danger" x-ng-click="ui.stop()" x-ng-disabled="!ui.isRunning || ui.isOneShoot || ui.isStarting">
				<i class="fa fa-stop" aria-hidden="true"></i>&nbsp;{{'readout.stop'| i18n}}
			</button>
		</div>
	</div>
	<div class="col-sm-2 ">
		<div class="btn-group pull-right">
			<button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				{{::'report.files.download' | i18n}} <span class="caret"></span>
			</button>
			<ul class="dropdown-menu">
				<li><a href="#" x-ng-click="downloadXlsx()">{{::'report.chart.xlsx' | i18n}}</a></li>
				<li><a href="#" x-ng-click="downloadCsv()">{{::'report.chart.csv' | i18n}}</a></li>
			</ul>
		</div>
	</div>
</div>

<form role="form" class="form-inline" style="padding-bottom: 15px">
	<div class="form-group">
		<label class="sr-only">{{::'report.table.search' | i18n}}</label>
		<div class="input-group">
			<input x-ng-model="search" type="text" class="form-control" placeholder="{{'report.table.search' | i18n}}" />
			<span class="input-group-addon">
				<i class="fa fa-search"></i>
			</span>
		</div>
	</div>
	<div class="form-group">
		<label>{{::'report.table.paramTypes' | i18n}}:</label>
		<div class="input-group">
			<div x-isteven-multi-select="" x-input-model="ui.quantities" x-output-model="quantities" x-max-labels="4"
				x-button-label="label" x-item-label="label" x-tick-property="selected"
				x-translation="ui.quantityTranslations" x-helper-elements="all none"></div>
		</div>
	</div>
	<div class="form-group">
		<label>{{::'report.table.visibleColumns' | i18n}}:</label>
		<div class="input-group">
			<div x-isteven-multi-select="" x-input-model="ui.columns" x-output-model="columns" x-max-labels="4"
				x-button-label="label" x-item-label="label" x-tick-property="selected"
				x-translation="ui.columnTranslations" x-helper-elements="all none"></div>
		</div>
	</div>
	<div class="form-group pull-right">
		<uib-pagination style="margin: 0; display: block;"
						x-boundary-links="true" x-direction-links="true"
						x-previous-text="&lsaquo;" x-next-text="&rsaquo;" x-first-text="&laquo;" x-last-text="&raquo;"
						x-total-items="table.rows.length"
						x-items-per-page="limit"
						x-ng-model="page"
						x-ng-show="table.rows.length"></uib-pagination>
	</div>
</form>

<table id="sjs-table" class="report-table table table-hover table-bordered table-condensed">
	<thead>
		<tr></tr>
	</thead>
	<tbody>
	</tbody>
</table>

<form role="form" class="form-inline">
	<div class="form-group pull-right">
		<uib-pagination style="margin: 0; display: block;"
						x-boundary-links="true" x-direction-links="true"
						x-previous-text="&lsaquo;" x-next-text="&rsaquo;" x-first-text="&laquo;" x-last-text="&raquo;"
						x-total-items="table.rows.length"
						x-items-per-page="limit"
						x-ng-model="page"
						x-ng-show="table.rows.length"></uib-pagination>
	</div>
</form>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
	<div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
				<!--<h4 class="modal-title" id="myModalLabel">Modal title</h4>-->
				<div class="btn-group" data-toggle="buttons">
					<label class="btn btn-default btn-xs" ng-class="{'btn-primary': ui.historyTime==1}" x-ng-click="ui.changeHistoryTime(1)">
						<input type="radio" name="options" id="option0" autocomplete="off">1h
					</label>
					<label class="btn btn-default btn-xs" ng-class="{'btn-primary': ui.historyTime==3}" x-ng-click="ui.changeHistoryTime(3)">
						<input type="radio" name="options" id="option1" autocomplete="off">3h
					</label>
					<label class="btn btn-default btn-xs" ng-class="{'btn-primary': ui.historyTime==6}" x-ng-click="ui.changeHistoryTime(6)">
						<input type="radio" name="options" id="option2" autocomplete="off">6h
					</label>
					<label class="btn btn-default btn-xs" ng-class="{'btn-primary': ui.historyTime==12}" x-ng-click="ui.changeHistoryTime(12)">
						<input type="radio" name="options" id="option3" autocomplete="off">12h
					</label>
					<label class="btn btn-default btn-xs" ng-class="{'btn-primary': ui.historyTime==24}" x-ng-click="ui.changeHistoryTime(24)">
						<input type="radio" name="options" id="option4" autocomplete="off">24h
					</label>
				</div>
			</div>
			<div class="modal-body">
				<div table-line-chart-widget="" ng-if="ui.showModal" row="ui.row" history-time="ui.historyTime" style="height:500px"></div>
				<div ng-if="!ui.showModal" style="height:500px; padding: 200px 250px;">
					<i class="fa fa-spinner fa-pulse fa-5x fa-fw"></i>
				</div>
			</div>
		</div>
	</div>
</div>
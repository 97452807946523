<div class="bar-graph-container" style="overflow-y: auto;">

    <div style="padding: 5px;">
        <div class="pull-right" x-ng-if="ui.chartData.length>=0">
            <table>
                <tr>
                    <td>
                        <span class="alert alert-danger" style="padding: 5px;" x-ng-if="ui.updatingData">{{'ui.loading.data' | i18n}}</span>
                    </td>
                    <td>
                        <div class="input-group">
                            <input type="text" class="form-control" x-ng-model="date" x-ng-change="ui.stepWidthDate(date, 0)" x-datetimepicker="{format:'YYYY-MM-DD HH:mm'}" x-ng-disabled="count<all"/>
                            <span class="input-group-addon">
                                        <i class="fa fa-calendar"></i>
                                    </span>
                        </div>
                    </td>
                    <td>
                        <div class="input-group" style="width: 170px;">
                            <div class="input-group-btn">
                                <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(-config.columns+1) || ui.updatingData" x-ng-click="ui.stateModalParam.reload=true; ui.step(-config.columns+1)">
                                    <i class="fa fa-lg fa-caret-left"></i><i class="fa fa-lg fa-caret-left"></i>
                                </button>
                                <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(-1) || ui.updatingData" x-ng-click="ui.step(-1)">
                                    <i class="fa fa-lg fa-caret-left"></i>
                                </button>
                            </div>
                            <input type="number" class="form-control" ng-model="config.columns" x-ng-change="ui.step(0)" style="width: 50px;" aria-label="...">
                            <div class="input-group-btn">
                                <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(1) || ui.updatingData" x-ng-click="ui.step(1)">
                                    <i class="fa fa-lg fa-caret-right"></i>
                                </button>
                                <button style="margin:0px" class="btn btn-primary" x-ng-disabled="!ui.canStep(config.columns-1) || ui.updatingData" x-ng-click="ui.stateModalParam.reload=true; ui.step(config.columns-1)">
                                    <i class="fa fa-lg fa-caret-right"></i><i class="fa fa-lg fa-caret-right"></i>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="pull-left" x-ng-if="ui.chartData.length>=0">
            <div class="input-group" style="width: 170px;">
                <div class="input-group-btn">
                    <button style="margin:0px" class="btn btn-primary" x-ng-disabled="ui.updatingData || config.retain===15" x-ng-click="ui.retain(15)">
                        15min
                    </button>
                    <button style="margin:0px" class="btn btn-primary" x-ng-disabled=" ui.updatingData || config.retain===60" x-ng-click="ui.retain(60)">
                        1h
                    </button>
                    <button style="margin:0px" class="btn btn-primary" x-ng-disabled=" ui.updatingData || config.retain===1440" x-ng-click="ui.retain(1440)">
                        1d
                    </button>
                    <button style="margin:0px" class="btn btn-primary" x-ng-disabled=" ui.updatingData || config.retain===10080" x-ng-click="ui.retain(10080)">
                        1w
                    </button>
                    <button style="margin:0px" class="btn btn-primary" x-ng-disabled=" ui.updatingData || config.retain===43200" x-ng-click="ui.retain(43200)">
                        1m
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div energy-report-chart-widget="" x-ng-if="ui.chartData.length>=0" data="ui.chartData" unit="ui.chartUnit" title="config.series[0].name" small="true" style="height: 100%; width:100%;"></div>
    <div x-ng-if="ui.chartData.length===0">
        <div class="row" style="height: 100%;">
            <div class="col-sm-6 col-sm-offset-3" style="padding-top: 20px;">
                <button class="btn btn-info btn-lg" x-ng-disable=" !$parent.$parent.editMode" x-ng-class="{'disabled' : !$parent.$parent.$parent.editMode}"
                        style="width : 100%;" x-ng-click="$parent.$parent.$parent.editMode ? $parent.$parent.$parent.edit() : null"
                        title="{{$parent.$parent.editMode ? ('widget.configure' | i18n) : ('widget.enable.editMode' | i18n)}}">
                    {{'widget.configure'| i18n}}<i class="fa fa-cog"></i></button>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="series in config.series">
                <form class="form form-inline" name="seriesConfigForm-{{$index}}">
                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <div style="width: 15px; cursor: pointer;">
                            <i class="fa fa-lg" x-ng-click="series._details = !series._details"
                               x-ng-class="series._details ? 'fa-minus' : 'fa-plus'"></i>
                        </div>
                    </div>

                    <div class="form-group" style="width: 130px; min-width: 130px;">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
                        <ui-select ng-model="series._device" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam(series)" style="width: 130px; min-width: 130px" >
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                                {{$select.selected.label || $select.selected.name}}
                            </ui-select-match>
                            <ui-select-choices repeat="device in devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}" style="width: 300px; min-width: 300px;">
                                <div ng-bind-html="((device.label||device.name)) | highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>


                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
                        <ui-select ng-model="series._param" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam(series)" style="width: 130px; min-width: 130px;" >
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
                                {{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
                            </ui-select-match>
                            <ui-select-choices repeat="param in series._device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}" style="width: 300px; min-width: 300px;">
                                <div ng-bind-html="((param.label||param.name) + ' [' + param.unit + ']'  )| highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.name"></label>
                        <div class="input-group" style="width: 145px">
                            <input type="text" class="form-control"  aria-label="..."
                                   x-ng-model="series.name"
                                   x-ng-change="ui.updateAxisIndices()"
                                   name="widgetsConfigSeriesName-{{$index}}">
                            <div class="input-group-btn">
                                <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="caret"></span></button>
                                <ul class="dropdown-menu dropdown-menu-right" x-ng-if="series._device" style="width: 300px; min-width: 300px;">
                                    <li><a href="#" x-ng-click="series.name = (series._device.label||series._device.name)+'.'+(series._param.label||series._param.name)+$index">{{series._device.label||series._device.name}}.{{series._param.label||series._param.name}}</a></li>
                                    <li role="separator" class="divider"></li>
                                    <li><a href="#" x-ng-click="series.name = (series._param.label||series._param.name)">{{series._param.label||series._param.name}}</a></li>
                                    <li><a href="#" x-ng-click="series.name = ('quantity.'+series._param.quantity|i18n)+' '+$index">{{'quantity.'+series._param.quantity|i18n}} {{$index}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.derivative"></label>
                        <select class="form-control" style="width: 64px;" x-ng-model="series.derivative"
                                x-ng-options="d.value as d.label for d in ui.derivatives"
                                x-ng-change="ui.updateSeriesParam(series)">
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <button class="btn btn-danger" x-ng-click="ui.removeSerie($index);"
                                data-ng-class="ui.isSingleSerie()"
                                title="{{'ui.delete' | i18n}}">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </button>
                    </div>

                    <div x-ng-show="series._details" style="margin-left: 18px;">
                        <!-- Scales -->
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.scale"></label>
                            <select class="form-control"
                                    name="widgetsConfigScale-{{$index}}"
                                    style="width: 120px;"
                                    x-ng-model="series.scale"
                                    x-ng-options="s.value as s.label for s in ui.scales">
                            </select>
                        </div>
                        <!-- Unit -->
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.unit"></label>
                            <input type="text" class="form-control" name="widgetsConfigUnit-{{$index}}"
                                   placeholder="{{'dashboard.widgets.config.unit' | i18n}}"
                                   data-ng-model="series.unit" x-ng-minlength="1" x-ng-maxlength="5"
                                   style="width: 100px"/>
                        </div>
                        <!-- Precision -->
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.precision"></label>
                            <select class="form-control" style="width: 120px;" x-ng-model="series.precision"
                                x-ng-options="p for p in ui.precisions">
                            </select>
                        </div>
                        <!-- Average	 -->
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.average"></label>
                            <input type="number" class="form-control" name="widgetsConfigAverage-{{$index}}" placeholder="{{'dashboard.widgets.config.unit' | i18n }}"
                                data-ng-model="series.average" x-min="1" x-required="" style="width: 140px"/>
                        </div>
                        <!-- Ticks -->
                        <div class="form-group" x-ng-if="config.type!='digital-gauge'">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.ticks"></label>
                            <div class="input-group" style="width: 120px">
                                <input class="form-control" name="widgetsConfigTickMajor-{{$index}}" placeholder="{{'dashboard.widgets.config.tick.major' | i18n }}"
                                       data-ng-model="series.majorTicks" x-min="0" x-max="11" x-required=""
                                       type="number"/>
                                <span class="input-group-addon">{{'dashboard.widgets.config.tick.major' | i18n }}</span>
                            </div>
                        </div>

                        <div class="form-group" x-ng-if="config.type!='digital-gauge'">
                            <label class="form-group-label">&nbsp;</label>
                            <div class="input-group" style="width: 120px">
                                <input class="form-control" name="widgetsConfigTickMinor-{{$index}}" placeholder="{{'dashboard.widgets.config.tick.minor' | i18n }}"
                                       data-ng-model="series.minorTicks" x-min="0" x-max="11" x-required=""
                                       type="number"/>
                                <span class="input-group-addon">{{'dashboard.widgets.config.tick.minor' | i18n }}</span>
                            </div>
                        </div>
                        <!-- min range -->
                        <div class="form-group" x-ng-if="config.type!='digital-gauge'">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.range.min"></label>
                            <div class="input-group" style="width: 120px;">
                                <input class="form-control"
                                       name="widgetsConfigRangeMin-{{$index}}"
                                       placeholder="{{'dashboard.widgets.config.range.min' | i18n}}"
                                       data-ng-model="series.min"
                                       data-scale-formatter=""
                                       data-format-scale="series.scale"
                                       data-format-precision="series.precision"
                                       x-required=""
                                       data-validate="{'dashboard.widgets.config.error.minMax': series.min > series.max}"/>
                                <data-unit-addon data-unit-addon-scale="series.scale" data-unit-addon-unit = series.unit></data-unit-addon>
                            </div>
                        </div>
                        <!-- max range -->
                        <div class="form-group" x-ng-if="config.type!='digital-gauge'">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.range.max"></label>
                            <div class="input-group" style="width: 120px;">
                                <input class="form-control"
                                       name="widgetsConfigRangeMax-{{$index}}" placeholder="{{'dashboard.widgets.config.range.max' | i18n }}"
                                       data-ng-model="series.max"
                                       data-scale-formatter=""
                                       data-format-scale="series.scale"
                                       data-format-precision="series.precision"
                                       x-required=""
                                       data-validate="{'dashboard.widgets.config.error.maxMin': series.min > series.max}"/>
                                <data-unit-addon data-unit-addon-scale="series.scale" data-unit-addon-unit = series.unit></data-unit-addon>
                            </div>
                        </div>
                        <br/>

                        <hr class="divider" style="margin-top: 10px;">
                        </hr>
                        <label class="form-group-label" x-label-help="dashboard.widgets.gauge.colorConfig"></label>
                        <div class="row" x-ng-repeat="zone in series.zones" ng-init="serieIndex = $parent.$index">
                            <div class="form-group col-sm-4">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.min"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.min' | i18n }}</label>
                                <div class="input-group">
                                    <input class="form-control" name="widgetsConfigZoneMin-{{serieIndex}}-{{$index}}"
                                           placeholder="{{'dashboard.widgets.config.zone.min' | i18n }}"
                                           x-ng-model="zone.from"
                                           x-required=""
                                           data-scale-formatter=""
                                           data-format-precision="series.precision"
                                           data-format-scale="series.scale"
                                           x-ng-change="ui.minmaxChange(serieIndex)"
                                           data-validate="{'dashboard.widgets.config.error.minMax': zone.from > zone.to}"/>
                                    <data-unit-addon data-unit-addon-scale="series.scale" data-unit-addon-unit = series.unit></data-unit-addon>
                                </div>
                            </div>

                            <div class="form-group col-sm-4">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.max"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.max' | i18n }}</label>
                                <div class="input-group">
                                    <input class="form-control" name="widgetsConfigZoneMax-{{serieIndex}}-{{$index}}"
                                           placeholder="{{'dashboard.widgets.config.zone.max' | i18n }}"
                                           x-ng-model="zone.to"
                                           data-scale-formatter=""
                                           data-format-scale="series.scale"
                                           data-format-precision="series.precision"
                                           x-required=""
                                           x-ng-change="ui.minmaxChange(serieIndex)"
                                           data-validate="{'dashboard.widgets.config.error.maxMin': zone.from > zone.to}"/>
                                    <data-unit-addon data-unit-addon-scale="series.scale" data-unit-addon-unit = series.unit></data-unit-addon>
                                </div>
                            </div>

                            <div class="form-group col-sm-2">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.color"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.color' | i18n }}</label>
                                <spectrum-colorpicker format="hex" x-ng-model="zone.color"
                                                      options="ui.colorPickerOpts || config.unit || {showInput : true} "/>
                            </div>

                            <div class="form-group col-sm-1">
                                <label class="form-group-label">&nbsp;</label>
                                <button class="btn btn-danger" x-ng-click="ui.removeZone(serieIndex, $index);"
                                        title="{{'ui.delete' | i18n}}">
                                    <i class="fa fa-trash-o fa-lg"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-1 col-sm-offset-10">
                                <label class="form-group-label">&nbsp;</label>
                                <button class="btn btn-success" x-ng-click="ui.addZone($index);">
                                    <i class="fa fa-plus fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </li>
            <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                <div class="pull-right">
                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                        {{'ui.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="clearfix"></div>
            </li>
        </ul>
    </div>
</div>

<div class="dashboard-container col-sm-12" x-dashboard-extension="" x-fullscreen="ui.isFullscreen" x-user="" x-ng-class="{'fullscreen': ui.isFullscreen}">
    <div x-widgetlimit="" x-dashboard-widget-count="" x-ng-if="!model.onlyDashboard">
        <h2 class="col-sm-4 col-xs-4 dashboard-title">{{model.title}}</h2>

        <div class="col-lg-4 col-md-3 col-sm-8 col-xs-8">
            <div class="alert alert-info" role="alert" ng-if="editMode &amp;&amp; !model.hideLicenseStatus"
                 style="padding: 5px; margin:0px; height: 29px">
                <!--    			<p style="font-size: 12px">{{'config.message.licenseCount' | i18n}}</p>	 -->
                <div class="progress">
                    <div class="progress-bar" role="progressbar"
                         x-ng-class="{'progress-bar-danger': widgetCount>widgetMax}"
                         aria-valuenow="60" aria-valuemin="0" aria-valuemax="{{ui.license.maxCredits}}"
                         style="width: {{widgetCount*100/widgetMax}}%;">
                        {{widgetCount}}/{{widgetMax}} {{'config.license.widgets' | i18n}}
                    </div>
                </div>
                <div class="error-container" x-ng-show="ui.license.alert">
                    <small class="error">
                        {{'config.license.overload' | i18n}}
                    </small>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12" style="padding-bottom: 3px">
            <span style="font-size: 16px" class="pull-right">
                <button class="btn btn-primary" x-ng-if="!editMode &amp;&amp; !model.lockEditingDashboard" onClick="javascript:history.go(-1);">
                    <i class="fa fa-step-backward fa-fw fa-lg"></i>&nbsp;
                    <!--{{'dashboard.edit.editDashboard' | i18n}}-->
                </button>



                <button class="btn btn-primary" x-ng-if="editMode &amp;&amp; !model.lockAddingWidgets" x-ng-click="addWidgetDialog()" x-ng-disabled="widgetCount>=widgetMax">
                    <i class="fa fa-plus-circle fa-fw fa-lg"></i>&nbsp;{{'dashboard.edit.addWidget' | i18n}}
                </button>
                <button class="btn btn-primary" x-ng-if="editMode &amp;&amp; !model.lockEditingDashboard" x-ng-click="editDashboardDialog()">
                    <i class="fa fa-cog fa-fw fa-lg"></i>&nbsp;{{'dashboard.edit.editDashboard' | i18n}}
                </button>
                <button class="btn btn-danger" x-ng-if="editMode" x-ng-click="cancelEditMode()">
                    <i class="fa fa-undo fa-fw fa-lg"></i>&nbsp;{{'dashboard.edit.refresh' | i18n}}
                </button>
                <button class="btn btn-success" x-ng-if="editMode" ng-click="config.dirty=true; toggleEditMode()">
                    <i class="fa fa-save fa-fw fa-lg"></i>&nbsp;{{'dashboard.edit.disableEdit'| i18n}}
                </button>
                <button class="btn btn-danger" x-ng-if="editMode" x-ng-click="removeDashboard()">
                    <i class="fa fa-trash fa-fw fa-lg"></i>
                </button>
                <button class="btn btn-primary" x-ng-if="!editMode &amp;&amp; !ui.isFullscreen &amp;&amp; !model.lockEditing" x-ng-disabled="editable=='false'" x-ng-click="toggleEditMode()">
                    <i class="fa fa-edit fa-lg fa-fw"></i>&nbsp;{{'dashboard.edit.enableEdit' | i18n}}
                </button>
                <button class="btn btn-default" ng-if="!(editMode || ui.isFullscreen)" x-ng-click="ui.isFullscreen = !ui.isFullscreen" title="{{'fullscreen.mode.enable' | i18n}}">
                    <i class="fa fa-expand fa-lg"></i>
                </button>
                <button class="btn btn-default" ng-if="ui.isFullscreen" ng-click="ui.isFullscreen = !ui.isFullscreen"
                        title="{{'fullscreen.mode.disable' | i18n}}">
                    <i class="fa fa-compress fa-lg"></i>
                </button>
                <button class="btn btn-default" target="_blank" data-ng-click="openInNewWindow()" x-open-in-new-window=""
                        title="{{'dashboard.open.external' | i18n}}">
                    <i class="fa fa-fw fa-external-link"></i>
                </button>
            </span>
        </div>
    </div>
    <div class="dashboard col-sm-12" x-ng-class="{'edit' : editMode}" x-ng-if="!model.height" x-expand-to-bottom="">
        <adf-dashboard-row
             row="row"
             x-adf-model="model"
             collapsible="collapsible"
             x-ng-repeat="row in model.rows"
             edit-mode="editMode"
             x-ng-class="row.styleClass"
             x-ng-attr-style="height: {{100 / model.rows.length}}%" />
    </div>
    <div class="dashboard col-sm-12" x-ng-class="{'edit' : editMode}" x-ng-if="model.height" x-ng-style="{'overflow-y' : 'auto', 'flex' : '1 1 auto'}">
        <adf-dashboard-row
                row="row"
                x-adf-model="model"
                collapsible="collapsible"
                x-ng-repeat="row in model.rows"
                edit-mode="editMode"
                x-ng-class="row.styleClass"
                x-ng-attr-style="height: {{100 / model.rows.length}}%" />
    </div>
    <!--x-ng-style="model.height"-->
</div>


var module = angular.module('meternet.energyReport.services', []);
module.service('energyReportService', function($q, configService, csvService, $filter, i18nFilter, unitFilter) {
    return {
        getEnergyReportMenuLink : function() {

            var deferred = $q.defer();
            configService.get().then(function(meternetConfig) {

                var reportEnergyLinks = [];

                _.each(meternetConfig.engine.moduleInputs, function(module) {
                    if(module.type === "energy-report"){
                        _.each(module.devices, function(report){
                            reportEnergyLinks.push({
                                target: 'main.reportEnergy' + '({ reportEnergyId: "' + report.id + '"})',
                                title: report.label || report.name,
                                icon: 'fa-plug'
                            });
                        });
                    }
                });
                deferred.resolve(reportEnergyLinks);

            });
            return deferred.promise;
        },
        get : function(id) {
            var deferred = $q.defer();
            configService.get().then(function(meternetConfig) {

                _.each(meternetConfig.engine.moduleInputs, function(module) {
                    if(module.type === "energy-report"){
                        var report = _.find(module.devices, function(report){return report.id == id});
                        deferred.resolve(report);
                    }
                });


            });
            return deferred.promise;
        }

    };
});

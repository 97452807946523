<table class="table table-bordered table-condensed" style="margin-bottom: 0px;">
    <thead x-ng-if="hide">
    <tr>
        <th style="text-align: center; vertical-align: middle;" x-ng-if="param.selectAll" class="col-xs-1"
            x-ng-click="selectAllFor(false); param.selectAll=false">
            <i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
            {{rule.outputName}}
        </th>
        <th style="text-align: center; vertical-align: middle;" x-ng-if="!param.selectAll" class="col-xs-1"
            x-ng-click="selectAllFor(true); param.selectAll=true">
            <i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
            {{rule.outputName}}
        </th>
        <th>{{'config.momentRules.paramName' | i18n}}</th>
        <th>{{'config.momentRules.writable' | i18n}}</th>
        <th>{{'config.momentRules.outputName' | i18n}}</th>
        <th>{{'config.momentRules.deadband' | i18n}}</th>
        <th>{{'config.momentRules.force' | i18n}}</th>
    </tr>
    </thead>
    <tbody>
    <tr x-ng-repeat="rule in simpleRulesStorage | orderBy : 'outputName'">
        <td style="text-align: center; vertical-align: middle;" x-ng-if="param.select && $index==0 && param!=null"
            rowspan="{{simpleRulesStorage.length}}" class="col-xs-1" x-ng-click="param.select=false">
            <i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
        </td>
        <td style="text-align: center; vertical-align: middle;" x-ng-if="!param.select && $index==0 && param!=null"
            rowspan="{{simpleRulesStorage.length}}" class="col-xs-1" x-ng-click="param.select=true">
            <i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
        </td>
        <td style="vertical-align: middle;" class="col-xs-2" x-ng-if='$index==0 && param!=null'
            rowspan="{{simpleRulesStorage.length}}">
            {{param.label? param.label : param.name}}
            <!--{{param}}-->
        </td>
        <td style="text-align: center; vertical-align: middle;" x-ng-if="rule.ruleId" class="col-xs-1"
            x-ng-click="removeMomentRule(rule.ruleId)">
            <i class="fa fa-check-square-o fa-lg text-success" aria-hidden="true"></i>
        </td>
        <td style="text-align: center; vertical-align: middle;" x-ng-if="!rule.ruleId" class="col-xs-1"
            x-ng-click="addMomentRule(rule.outputId)">
            <i class="fa fa-square-o fa-lg text-danger" aria-hidden="true"></i>
        </td>
        <td style="vertical-align: middle;" class="col-xs-2">{{rule.outputName}}</td>
        <td class="col-xs-1">
            <input class="form-control" x-ng-model="rule.deadBand[0]" x-ng-change="updateDeadBand(rule, $index)"/>
        </td>
        <td class="col-xs-1">
            <input class="form-control" x-ng-model="rule.deadBand[1]" x-ng-change="updateDeadBand(rule, $index)"/>
        </td>
    </tr>
    </tbody>
</table>


<div x-ng-if="ruleNotShow>0 && param==null">
    {{'config.momentRules.notShow' | i18n}} : {{ruleNotShow}}
</div>
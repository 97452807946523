var module = angular.module('meternet.chart.directives.mbsatGauge', ['meternet.charts.services']);

module.directive('mbsatGauge', function ($q, $http, $timeout, $rootScope, UnitScales, unitFilter, configService, dataService, i18nFilter, leafletData, leafletBoundsHelpers, paramService, Errors) {
  return {
    scope: {
      options: '=',
      data: '='
    },
    templateUrl: 'dashboard/mbsat-gauge.html',
    controller: function ($scope, $element, $location, $compile) {
      $scope.markersWithinBounds = [];
      $scope.allMarkers = [];
      $scope.checkedGroups = [];
      $scope.dirty = false;
      $scope.onlyMarkersWithinBounds = false;
      $scope.params = [];

      $scope.ui = {
        config: null,
        clickedMark: null,
        errors: new Errors(),
        templates: [],
        template: {"id": null, "name": "", "data": []},
        bounds: leafletBoundsHelpers.createBoundsFromArray([
          [0, 0], [0, 0]
        ]),
        markers: [],
        updateCheckedGroups: function () {
          var checked = $scope.ui.markers.filter(function (marker) {
            return marker.checked;
          }).map(function (marker) {
            return marker.device
          });
          $scope.checkedGroups = checked
        },
        goToSettings: function (setId) {
          var lightSet = _.findWhere($scope.lightingSettings, {id: setId});
          var schedulerId;
          if (lightSet && lightSet.schedulers && lightSet.schedulers.length > 0) {
            schedulerId = lightSet.schedulers[tools.getActiveSchedulerForCurrent(lightSet.schedulers)].id;
          }
          schedulerId ? $location.hash('lightingSetting=' + setId + '&scheduler=' + schedulerId) : $location.hash('lightingSetting=' + setId)
          $scope.menu = 2;
        },
        showMessage: function (text, css) {
          this.message = {
            text: text,
            css: css
          };
          $timeout.cancel(this.messagePromise);
          this.messagePromise = $timeout(function () {
            $scope.ui.message = null;
          }, 5000);
        }
      }

      $scope.invisibleLayers = new Set();
      $scope.lightingSettings = [];

      $scope.$on('goToCalendar', function (event, setId) {
        $scope.ui.goToSettings(setId);
      });
      $scope.$on('changeView', function (event) {
        $scope.menu = $scope.menu === 1 ? 0 : 1;
      });
      $scope.$on('backToMap', function (event) {
        if (!$scope.dirty) {
          $location.hash('');
          $scope.menu = 0;
        } else {
          $('#configModal').modal('show');
        }
      });
      $scope.$on('clickMarkerOnMap', function (event, checked) {
        $scope.ui.clickedMark.checked = checked
        buildPopUp();
      });
      $scope.$on('selectMarker', function (event, marker) {
        checkMarker(marker);
        $scope.ui.updateCheckedGroups()
      });
      $scope.$on('selectAllMarkers', function () {
        checkAllMarkers();
      });
      $scope.$on('overMarker', function (event, marker) {
        var latlng = {
          lat: marker.lat,
          lng: marker.lng
        };
        mouseMove(latlng, 'mouseover')
      });

      $scope.$on('leaveMarker', function (event, marker) {
        var latlng = {
          lat: marker.lat,
          lng: marker.lng
        };
        mouseMove(latlng, 'mouseout')
      });

      $scope.$on('search', function (event, phrase) {
        checkPhrases(phrase);
        getVisibleMarkers();
      });

      $scope.$on('saveConfig', function () {
        configService.save($scope.ui.config)
            .then(function (res) {
              $scope.ui.config = res.config;
              prepareData();
              getVisibleMarkers(true);
              $scope.ui.showMessage('config.save.success', 'alert-success');
            })
            .catch(function (err) {
              $scope.ui.errors = new Errors(err.errors);
              $scope.ui.showMessage('config.save.failure', 'alert-danger');
            })
      })

      angular.extend($scope, {
        mapZoom: {
          lat: 51.7485005,
          lng: 19.4632418,
          zoom: 10
        },
        defaults: {
          scrollWheelZoom: true,
          trackResize: true
        },
        events: {
          map: {
            enable: ['zoomstart', 'drag', 'click', 'mousemove'],
            logic: 'emit'
          }
        },
        layers: {
          baselayers: {
            openStreetMap: {
              name: 'OpenStreetMap',
              type: 'xyz',
              url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            }
          },
          overlays: {
            on: {
              type: 'markercluster',
              name: 'Lights on',
              id: 'on',
              visible: true
            },

            off: {
              type: 'markercluster',
              name: 'Lights off',
              id: 'off',
              visible: true
            }
          }
        }
      });

      $scope.$watch('ui.bounds', function () {
        getVisibleMarkers();
      });
      $scope.$watch(function () {
        return $location.hash()
      }, function (newVal) {
        $scope.menu = newVal.startsWith('lightingSetting=') ? 2 : 0;
        $scope.fitMap()
      });

      $scope.fitMap = function () {
        leafletData.getMap().then(function (map) {
          $timeout(function () {
            map.invalidateSize();
          });
          if ($scope.ui.markers.length === 0) {
            return
          }
          var bounds = L.latLngBounds($scope.ui.markers);
          map.fitBounds(bounds, {padding: [5, 5]});
        })
      }

      function prepareData() {
        configService.get().then(function (meternetConfig) {
          $scope.ui.config = meternetConfig;
          $scope.lightingSettings = meternetConfig.engine.lightingSettings;
          _.forEach($scope.options.series, function (serie) {
            _.forEach(meternetConfig.engine.measurementInputs, function (input) {
              var device = _.findWhere(input.devices, {id: serie.id});
              if (device) {
                serie.name = device.name;
                serie.label = device.label;
                serie.groups = device.groups;
                serie.lat = device.lat;
                serie.lon = device.lon;
                serie.fixedCost = device.fixedCost;
                serie.variableCost = device.variableCost;
                return false
              }
            })
          });

          $scope.ui.markers.length = 0;

          _.forEach($scope.options.series, function (device) {
            _.forEach(device.groups, function (group) {
              var position = tools.getEffectiveGeolocation(group, device);
              if (!position) {
                // (ak) nie wyświetlać markerów bez geolokalizacji (?)
                console.warn('Group without geolocation!', group)
                return;
              }

              $scope.ui.markers.push({
                lat: parseFloat(position[0]),
                lng: parseFloat(position[1]),
                focus: false,
                draggable: false,
                riseOnHover: true,
                interface: device,
                device: group,
                layer: isOn(group) ? "on" : "off",
                icon: isOn(group) ? {
                  iconUrl: '../resources/images/marker-icon-on.png',
                  shadowUrl: '../resources/images/marker-shadow.png',
                  iconAnchor: [12, 41]
                } : {
                  iconUrl: '../resources/images/marker-icon-off.png',
                  shadowUrl: '../resources/images/marker-shadow.png',
                  iconAnchor: [12, 41]
                }
              });
            });
          });
          $scope.allMarkers = JSON.parse(JSON.stringify($scope.ui.markers));
          $scope.fitMap()
        });
      }
      prepareData();

      $scope.$on('leafletDirectiveMap.overlayadd', function (event, locationEvent) {
        var layer = _.findWhere(locationEvent.model.layers.overlays, {name: locationEvent.leafletEvent.name});
        if (layer && $scope.invisibleLayers.has(layer.id)) {
          $scope.invisibleLayers.delete(layer.id);
          getVisibleMarkers();
        }
      });
      $scope.$on('leafletDirectiveMap.overlayremove', function (event, locationEvent) {
        var layer = _.findWhere(locationEvent.model.layers.overlays, {name: locationEvent.leafletEvent.name});
        if (layer &&  !$scope.invisibleLayers.has(layer.id)) {
          $scope.invisibleLayers.add(layer.id);
          getVisibleMarkers();
        }
      });
      $scope.$on('leafletDirectiveMarker.click', function (event, locationEvent) {
        $scope.ui.clickedMark = locationEvent.model;
        $scope.$broadcast('clickMarker', locationEvent.model);
      });

      $scope.$on('leafletDirectiveMarker.mouseover', function (event, args) {
        colorTable(args.model.device.id, 'mouseover');
        mouseMove(args.leafletEvent.latlng, 'mouseover');
      });
      $scope.$on('leafletDirectiveMarker.mouseout', function (event, args) {
        colorTable(args.model.device.id, 'mouseout');
        mouseMove(args.leafletEvent.latlng, 'mouseout');
      });

      function getIcon(marker) {
        var smallIconOn = {
          iconUrl: '../resources/images/marker-icon-on.png',
          shadowUrl: '../resources/images/marker-shadow.png',
          iconAnchor: [12, 41]
        };
        var bigIconOn = {
          iconUrl: '../resources/images/marker-icon-on-big.png',
          shadowUrl: '../resources/images/marker-shadow-big.png',
          iconAnchor: [15, 49]
        };
        var smallIconOnChecked = {
          iconUrl: '../resources/images/marker-icon-on-checked.png',
          shadowUrl: '../resources/images/marker-shadow.png',
          iconAnchor: [12, 41]
        };
        var bigIconOnChecked = {
          iconUrl: '../resources/images/marker-icon-on-big-checked.png',
          shadowUrl: '../resources/images/marker-shadow-big.png',
          iconAnchor: [15, 49]
        };
        var smallIconOff = {
          iconUrl: '../resources/images/marker-icon-off.png',
          shadowUrl: '../resources/images/marker-shadow.png',
          iconAnchor: [12, 41]
        };
        var bigIconOff = {
          iconUrl: '../resources/images/marker-icon-off-big.png',
          shadowUrl: '../resources/images/marker-shadow-big.png',
          iconAnchor: [15, 49]
        };
        var smallIconOffChecked = {
          iconUrl: '../resources/images/marker-icon-off-checked.png',
          shadowUrl: '../resources/images/marker-shadow.png',
          iconAnchor: [12, 41]
        };
        var bigIconOffChecked = {
          iconUrl: '../resources/images/marker-icon-off-big-checked.png',
          shadowUrl: '../resources/images/marker-shadow-big.png',
          iconAnchor: [15, 49]
        };
        if (marker.isBig) {
          return marker.checked ? isOn(marker.device) ? bigIconOnChecked : bigIconOffChecked : isOn(marker.device) ? bigIconOn : bigIconOff
        } else {
          return marker.checked ? isOn(marker.device) ? smallIconOnChecked : smallIconOffChecked : isOn(marker.device) ? smallIconOn : smallIconOff
        }
      }

      function mouseMove(latlng, action) {
        _.forEach($scope.ui.markers, function (marker) {
          if (marker.lat === latlng.lat && marker.lng === latlng.lng) {
            if (action === 'mouseover') {
              marker.isBig = true;
            } else if (action === 'mouseout') {
              marker.isBig = false;
            }
            marker.icon = getIcon(marker);
          }
        });
      }

      function checkMarker(marker) {
        if ($scope.ui.clickedMark && marker.device.id === $scope.ui.clickedMark.device.id) {
          $scope.ui.clickedMark.checked = !$scope.ui.clickedMark.checked;
        }
        marker.icon = getIcon(marker);
      };

      function checkAllMarkers() {
        _.forEach($scope.ui.markers, function (marker) {
          checkMarker(marker);
        });
      };

      function colorTable(id, action) {
        var rowId = "#" + id;
        var row = $element.find(rowId);
        if (action === 'mouseover') {
          row.addClass("hover");
        } else {
          row.removeClass("hover");
        }
      }

      function getVisibleMarkers(closePopUp) {
        leafletData.getMap().then(function (map) {
          var markers = $scope.ui.markers.filter(function (marker) {
            return map.getBounds().contains(L.latLng(marker.lat, marker.lng));
          });
          $scope.markersWithinBounds = _.reject(markers, function (marker) {
            return $scope.invisibleLayers.has(marker.layer);
          });
          if (closePopUp) {
            map.closePopup();
          }
        })
      }

      function buildPopUp() {
        var directive = '<div marker-popup=""></div>';
        var compiledHtml = $compile(directive)($scope);
        var popup = L.popup({offset: L.point(0, -41), minWidth: 200})
          .setLatLng([$scope.ui.clickedMark.lat, $scope.ui.clickedMark.lng])
          .setContent(compiledHtml[0]);
        leafletData.getMap().then(function (map) {
          popup.openOn(map);
        });
      }

      function checkPhrases(phrase) {
        $scope.ui.markers.length = 0;
        var i, s, found;
        var columns = ['label', 'title', 'desc', 'desc2', 'desc3'];
        _.forEach($scope.allMarkers, function (row) {
          found = false;
          for (i = 0; i < columns.length; i++ ) {
            s = row.device[columns[i]];
            if (s && s.toLowerCase().indexOf(phrase.toLowerCase()) >= 0) {
              found = true;
              break;
            }
          }
          if (found) {
            $scope.ui.markers.push(row);
          }
        });
      }

      $scope.$on("setDirty", function (event, args) {
            $scope.dirty = args.dirty;
            var info = $scope.dirty ? "calendar.dirty" : args.withBroadcast ? "calendar.dirty.cancelled" : null;
            if (info) {
              $rootScope.$broadcast(info);
            }
      });

      $scope.$on("$locationChangeStart", warnIfUnsavedChanges);

      $scope.$watchCollection('markersWithinBounds', function (newVal) {
        $scope.params = paramService.prepareParamsForMarkers($scope.markersWithinBounds);
        paramService.unsubscribeUnchecked($scope.params);
        paramService.subscribeParams($scope.params);
      });

      $scope.$watch('params', function (newVal) {
        _.forEach($scope.markersWithinBounds, function (marker, index) {
          marker.icon = getIcon(marker);
        });
      }, true);

      $scope.$on('$destroy', function() {
        paramService.unsubscribeUnchecked([]);
      });

      function warnIfUnsavedChanges(event, to, from) {
        if (from.includes("/dashboard/") && from.includes("lightingSetting=") && $scope.dirty) {
          $('#configModal').modal('show');
          event.preventDefault();
          var word = 'lightingSetting=';
          var id = from.slice(from.indexOf(word) + word.length);
          var idxOf = id.indexOf('&');
          if (idxOf > -1) {
            var setId = id.substr(0, idxOf);
            word = 'scheduler=';
            var schedulerId = id.slice(id.indexOf(word) + word.length);
            $scope.$broadcast('restore settings', {setId: setId, schedulerId: schedulerId});
          } else {
            $scope.$broadcast('restore settings', {setId: id});
          }
        }
      }

      function isOn(group) {
        var currVal;
        _.forEach(group.params, function (param) {
          if (param.quantity === 'status' && param.moment && param.moment.current) {
            currVal = !!param.moment.current.value;
            return false;
          }
        });
        return currVal;
      }
    }
  }
});

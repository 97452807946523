/**
 * Created by Karol on 2015-12-15.
 */
var module = angular.module('meternet.dashboard.controllers.barGraphWidget', [ 'adf.provider', 'i18n',
    'meternet.dashboard.constants' ]);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl : 'dashboard/bar-graph.html',
        title : messages['dashboard.widgets.bar-graph.title'],
        description : messages['dashboard.widgets.bar-graph.title'],
        controller : 'barGraphWidgetCtrl',
        resolve : {
        },
		config: {
			type: "value",
			series : [{}],
			columns: 4,
			mode: 1,
		},
		edit : {
			controller : 'barGraphWidgetEditCtrl',
			templateUrl: 'dashboard/bar-graph-edit.html'
		}
    };

    dashboardProvider.widget('bar-graph', widget);

});

function barGraphWidgetEditCtrl($scope, $filter, i18nFilter, contextPath, dataService, config, configService, UnitScales,
        seriesService, TimeDerivativeScales, ColorPickerOpts) {
    $scope.ui = {
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                    paramId : null,
                    zones : [ {
                        "color" : "#5cb85c",
                        from : 0,
                        to : 50
                    }, {
                        "color" : "#f0ad4e",
                        from : 50,
                        to : 75
                    }, {
                        "color" : "#d9534f",
                        from : 75,
                        to : 100
                    } ]
                };
                $scope.config.series.push(series);
            }
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        isSingleSerie : function() {
            if ($scope.config.series.length > 1) {
                return "enabled";
            }
            return "disabled";
        },
        addZone : function(serieIndex) {
            var serie = config.series[serieIndex];
            var zonesLength = serie.zones.length;

            var min = zonesLength > 0 ? serie.zones[zonesLength - 1].to : serie.min;
            serie.zones.push({
                "color" : "#ffffff",
                "from" : min,
                "to" : min + 10
            });
        },
        removeZone : function(serieIndex, index) {
            config.series[serieIndex].zones.splice(index, 1);
        },
        minmaxChange : function(serieIndex){
            var serie = config.series[serieIndex];
            var max=-999999999999999999999;
            var min=999999999999999999999;
            _.forEach(serie.zones, function(zone){
                zone.from<min ? min = zone.from : min = min;
                zone.to>max ? max = zone.to : max= max;
            })
            serie.min = min;
            serie.max = max;
        },
        canAddSeries : function() {
            return $scope.config.series.length < 1;
        },
        boolToStr : function(arg) {
            return arg ? $filter('i18n')("universal.on") : $filter('i18n')("universal.off");
        },
        updateSeriesParam : function(series) {
            if (series.deviceId !== series._device.id) {
                series._param = series._device.params[0];
            }
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.scale = series._param.scale || 0;
                series.precision = series._param.precision || 0;

                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                if (!series.derivative) {
                    series.unit=$filter('quantityUnit')(series._param.quantity);
                } else {
                    series.unit = seriesService.getTimeDerivativeUnit($filter('quantityUnit')(series._param.quantity), series.derivative);
                }
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
        },
        derivatives : TimeDerivativeScales,
        types : [{
                value: "value",
                label: i18nFilter("dashboard.widgets.config.bargraph.types.value")
            },{
                value: "min15",
                label: i18nFilter("dashboard.widgets.config.bargraph.types.min15")
            },{
                value: "max15",
                label: i18nFilter("dashboard.widgets.config.bargraph.types.max15")
            },{
                value: "inc15",
                label: i18nFilter("dashboard.widgets.config.bargraph.types.inc15")
            },{
                value: "avg15",
                label: i18nFilter("dashboard.widgets.config.bargraph.types.avg15")
            }],
        retains : [{
                value: 15,
                label: "15min"
            },{
                value: 60,
                label: "1h"
            },{
                value: 1440,
                label: "1d"
            },{
                value: 10080,
                label: "1w"
            },{
                value: 43200,
                label: "1m"
            }],
        scales : UnitScales,
        colorPickerOpts : ColorPickerOpts,
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ]
    };

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        // $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        // $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        // $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));
        // $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));

		var models = ['prepaid', 'alarm', 'control'];

		$scope.devices = _.filter(devices, function (d) {
			return models.indexOf(d.model) === -1
		});

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });

        var checkParam = function(param) {
            return this.paramId === param.id;
        };

        var checkDevice = function(device) {
            return this.deviceId === device.id;
        };

        for (var i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            series._device = _.find($scope.devices, checkDevice, series);
            if (series._device) {
                series._param = _.find(series._device.params, checkParam, series);
            }
        }

    });

    $scope.colors = [ '#865BB7','#337ab7','#5bc0de','#f0ad4e','#d9534f','#5cb85c'];
}

function barGraphWidgetCtrl($scope, $filter, $q, $timeout, contextPath, dataService, config, configService, DashboardEvents, seriesService, unitFilter, dateFilter) {
    if(!config.retain){
        config.retain = 15;
    }
    $scope.dates=[];
    $scope.ui = {

        chartData : [],
        stateModalParam:{
            seriesIndex:null,
            serie: null,
            col:null,
            table:null
        },
        setModalParam : function() {
            var seriesIndex = 0;
            if($scope.table.data[seriesIndex]){
                var table = $scope.table;
                var col = $scope.table.data[seriesIndex].length-1;
                var serie = $scope.report.series;
                var rawData = table.data[seriesIndex];

                var length = rawData.length;
                $scope.ui.chartData = [];
                $scope.ui.chartUnit = rawData[col].unit;
                $scope.ui.chartTitle = serie.device + " " + serie.param + " (" + serie.name +") - " + $filter('i18n')(table.subcols[col].label).toUpperCase() ;
                for(var i=0; i<length; i++){
                    $scope.ui.chartData.push({
                        timestamp:moment(new Date(rawData[i].timestamp)).format("YYYY-MM-DD HH:mm"),
                        value:rawData[i].value,
                        error:0,
                        valueStart: rawData[i].value1,
                        valueEnd: rawData[i].value2,
                        dateStart: rawData[i].timestamp,
                        dateEnd: rawData[i].timestamp2,
                        showBuble: table.subcols[col].label==='energyReport.increase'
                    })
                }
            }
        },
        setModalParamFromState: function(){
            $scope.ui.setModalParam($scope.ui.stateModalParam.seriesIndex, $scope.ui.stateModalParam.serie, $scope.ui.stateModalParam.col, $scope.table)
        },
        getStepDate : function(inc) {
            var date = null;
            if (inc < 0) {
                date = _.max(_.filter($scope.dates, function(d) {
                    return d.getTime() < $scope.date.getTime();
                }), function(d) {
                    return d.getTime();
                });
            } else {
                date = _.min(_.filter($scope.dates, function(d) {
                    return d.getTime() > $scope.date.getTime();
                }), function(d) {
                    return d.getTime();
                });
            }
            if (date === Infinity || date === -Infinity) {
                date = null;
            }
            return date;
        },
        setStepDate : function(date) {
            if (date) {
                updateData(date);
            }
        },
        canStep : function(inc) {
            return this.getStepDate(inc) != null;
        },
        step : function(inc) {
            var diff = $scope.dates[0]-$scope.dates[1];
            var date = $scope.date.getTime()-inc*diff
            if (date) {
                setTimeout(function() {  updateData(date); }, 1000);
                $scope.ui.stateModalParam.reload = true;
            }
        },
        stepWidthDate : function(date, inc) {
            $scope.date = date
            var diff = $scope.dates[0]-$scope.dates[1];
            var date = $scope.date.getTime()-inc*diff
            if (date) {
                setTimeout(function() {  updateData(date); }, 1000);
                $scope.ui.stateModalParam.reload = true;
            }
        },
        retain : function(r){
            $scope.config.retain = r;
            $scope.date = new Date();
            var date = $scope.date.getTime();
            if (date) {
                updateData(date);
                $scope.ui.stateModalParam.reload = true;
            }
        }
    }

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        $scope.devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
    });

    configService.get().then(function(meternetConfig){
        var devices = _.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices'));
        var report = _.find(devices, function(device){
           return device.id === config.reportId;
        });
        $scope.report = angular.extend({}, report, config);

        updateData();
    });


    function updateData(date) {
        $scope.ui.updatingData = true;
        if (date == null) {
            date = new Date();
        }
        $scope.ui.date = date;
        if (config.series && config.series.length > 0) {
            dataService.getBarGraphData(config.series[0].paramId, new Date(date), $scope.config.retain, $scope.config.columns+1).then(function(resp) {
                var i, d;
                if (resp.data) {
                    resp.data.reverse();
                } else {
                    resp.data = [];
                }

                $scope.date = new Date(resp.data[0].scheduledTimestamp);
                var checkDate = function(date) {
                    //TK this - date passed as underscore context
                    return date.getTime() === this.getTime();
                };
                for (i = 0; i < resp.data.length; ++i) {
                    d = new Date(resp.data[i].scheduledTimestamp);
                    if (_.find($scope.dates, checkDate, d) === undefined) {
                        $scope.dates.push(d);
                    }
                }
                $scope.dates.sort();
                $scope.data = resp;

                updateTable(resp);
                $scope.ui.updatingData = false;
                if($scope.ui.stateModalParam.reload){
                    $scope.ui.setModalParamFromState()
                }
            });
        }
    }

    function updateTable(resp) {
        var i, j;

        var table = {
            cols: [],
            subcols: [],
            data: []
        };
        _.forEach(resp.data, function(d){
            d.values = _.chain(d.values).reverse().value();
        })

        for (i = 1; i < $scope.config.columns+1; ++i) {
            var colwidth = 70 / $scope.config.columns;
            var stime1 = (resp.data.length > i) ? new Date(resp.data[i].scheduledTimestamp) : null;
            var stime2 = (resp.data.length > i - 1) ? new Date(resp.data[i-1].scheduledTimestamp) : null;
            var sublabels = ["energyReport.increase", "energyReport.power", "energyReport.cost"];
            var colNum = 0

            for(var k=0; k<sublabels.length; k++){
                colNum ++;
            }
            table.colNum = colNum;
            for (var j = 0; j < sublabels.length; j++) {
                table.subcols.push({
                    label: "testowanie",
                    style: {
                        "width": (colwidth / table.colNum) + "%",
                        "text-align": "center"
                    }
                });
            }
        }
        table.cols.reverse();

        var checkParamId = function (v) {
            return v.paramId === this;
        };
        for (i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            var params = _.flatten(_.pluck($scope.devices, 'params'), true);

            var param = _.find(params, function (param) {
                return param.id === series.paramId;
            });

            if(param){
                var device = _.find($scope.devices, function (device) {
                    return _.find(device.params, function(p){
                        return p.id == param.id;
                    });
                });
                series.name = series.name ? series.name : device.label ? device.label : device.name;
                series.device = device.label ? device.label : device.name;
                series.param = param.label ? param.label : param.name;
                series.precision = param.precision;
                series.scale = param.scale;
                series.quantity = param.quantity;

                var d = [];
                for (j = 0; j < $scope.config.columns; j++) {
                    var s1;
                    var s2;

                    if (resp.data.length > j) {
                        s1 = _.find(resp.data[j].values, checkParamId, series.paramId) || {};
                    }
                    if (resp.data.length > j + 1) {
                        s2 = _.find(resp.data[j + 1].values, checkParamId, series.paramId) || {};
                    }
                    var type = config.type ? config.type : "value";
                    d.push({
                        label: s1.value != null  ? unitFilter(s1[type] , series.precision, $filter('quantityUnit')(series.quantity), series.scale) : "-",
                        value: s1.value != null  ? s1[type] :0,
                        timestamp: s1.timestamp,
                        timestamp2: s2.timestamp,
                        unit: {
                            quantity: $filter('quantityUnit')(series.quantity),
                            precision: series.precision,
                            scale: series.scale
                        },
                    });
                }
                d.reverse();
                table.data.push(d);
            }else{
                series.display = false;
            }
            $scope.table = table;
            $scope.ui.setModalParam();
        }
    }

    $scope.subscribe = true;

    var updateDataFactory = function(index) {
        var updateData = function(resp, concat) {
            if(resp.constructor.name !== 'Array'){
                var i, d;
                if (resp.data) {
                    resp.data.reverse();
                } else {
                    resp.data = [];
                }

                $scope.date = new Date(resp.data[0].scheduledTimestamp);
                var checkDate = function(date) {
                    //TK this - date passed as underscore context
                    return date.getTime() === this.getTime();
                };
                for (i = 0; i < resp.data.length; i++) {
                    d = new Date(resp.data[i].scheduledTimestamp);
                    if (_.find($scope.dates, checkDate, d) === undefined) {
                        $scope.dates.push(d);
                    }
                }
                $scope.dates.sort();
                $scope.data = resp;
                updateTable(resp);
            }else{
                var newScheduledTimestamp = new Date(new Date($scope.data.data[0].scheduledTimestamp).getTime() + (new Date($scope.data.data[0].scheduledTimestamp).getTime() - new Date($scope.data.data[1].scheduledTimestamp)))

                if(new Date($scope.data.data[0].scheduledTimestamp) !== newScheduledTimestamp && (Math.abs(new Date().getTime() - newScheduledTimestamp) > 30000)){
                    var newData = {
                        scheduledTimestamp: newScheduledTimestamp,
                        previousScheduledTimestamp: $scope.data.data[0].scheduledTimestamp,
                        values: []
                    };
                    $scope.data.data.unshift(newData);
                    $scope.data.data.pop();
                }
                $scope.data.data[0].values[index]=resp[0];
                var count = 0
                _.forEach($scope.data.data[0].values, function(v){
                    if(v){
                        count++;
                    }
                })
                if(count===3){
                    updateTable($scope.data);
                }
            }
        }
        return _.throttle(updateData, 500);
    }


    $scope.unsubscribeFns = {};
    $scope.$on('$destroy', function() {
        _.each($scope.unsubscribeFns, function(fn) {
           fn();
        });
    });

    $scope.promises = [];
    var initData = function() {
        $scope.$broadcast(DashboardEvents.LOADING_DATA, true);
        _.each($scope.promises, function(p) {
            p.cancel();
        });
        $scope.promises = [];
        _.each(config.series, function(series, index) {
            var promise = dataService.getBarGraphData(series.paramId, new Date(), 15, $scope.config.columns)
            promise.deviceId = series.deviceId;
            promise.paramId = series.paramId;
            var updateDataFn = updateDataFactory(index);
            promise.then(function(measurements) {
                updateDataFn(measurements, false);
            }, angular.noop, function(measurements) {
                updateDataFn(measurements, false);
            });
            $scope.promises.push(promise);
        });

        $scope.$on('$destroy', function() {
            _.each($scope.promises, function(p) {
                p.cancel();
            });
        });
        $q.all($scope.promises).then(function(measurements) {
            if (!$scope.subscribe) {
                _.each($scope.unsubscribeFns, function(fn) {
                    fn();
                });
                $scope.unsubscribeFns = {};
            } else {
                _.forEach($scope.promises, function(p, i) {
                    if ($scope.subscribe && typeof ($scope.unsubscribeFns[p.paramId]) === 'undefined') {
                        var unsubscribeFn = dataService.subscribeForParametersMeasurements(p.paramId, function(moment) {
                           var data = [];
                           data.push(moment.current);
                           updateDataFactory(i)(data, true);
                        });
                        $scope.unsubscribeFns[p.paramId] = unsubscribeFn;
                    }

                });
            }
            $timeout(function() {
                $scope.$broadcast(DashboardEvents.LOADING_DATA, false);
            }, 0);
        });
    }
    initData();
}

module.controller('barGraphWidgetEditCtrl', barGraphWidgetEditCtrl);
module.controller('barGraphWidgetCtrl', barGraphWidgetCtrl);

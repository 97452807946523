var module = angular.module('meternet.dashboard.controllers.mbsatGaugeWidget', ['adf.provider']);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/mbsat-gauge-widget.html',
        title : messages['dashboard.widgets.mbsatGauge.title'],
        description : messages['dashboard.widgets.mbsatGauge.desc'],
        controller : 'mbsatWidgetCtrl',
        screenshot: false,
        config : {
            type : "mbsat",
            series : []
        },
        edit : {
            controller : 'mbsatWidgetEditCtrl',
            templateUrl: 'dashboard/mbsat-gauge-edit.html'
        }
    };
    dashboardProvider.widget('mbsat', widget);
});


function MbsatEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts) {

    $scope.ui = {
        addSeries : function() {
            $scope.config.series.push({});
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        getDevice: function (idx) {
            var devices = []
            var id = $scope.config.series[idx] ? $scope.config.series[idx].deviceId : null;
            if (id) {
                devices = _.findWhere($scope.ui.measurementInputs, {id: id}).devices
                _.forEach(devices, function(device){
                    if (!device.label){
                        device.label = device.name;
                    }
                });
            }
            return devices
        }
    };
    $scope.config = config;
    $scope.selectedInput = null;
    configService.get().then(function(meternetConfig){
        var inputs = _.filter(meternetConfig.engine.measurementInputs, function (m) {
            return m.type ==='mbsat';
        });
        $scope.ui.measurementInputs = inputs;
        _.forEach($scope.ui.measurementInputs, function(input){
            if (!input.label){
                input.label = input.name;
            }
        });
    });
}


function MbsatCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents, seriesService, $q) {
}

module.controller('mbsatWidgetEditCtrl', MbsatEditCtrl);
module.controller('mbsatWidgetCtrl', MbsatCtrl);

var module = angular.module('meternet.fileBrowser.directives', ['meternet.fileBrowser.services']);

module.directive('fileBrowser', FileBrowserDirective);
function FileBrowserDirective(fileBrowserService) {
    return {
        scope: {
            rootPath: '=fileBrowser',
            readOnly: '=',
            choosedDirectory: '='
        },
        templateUrl: 'file-browser/file-browser.html',
        link: function (scope) {
            scope.rootPath = scope.rootPath || '/';
            scope.currentDirectory = {
                path: ['/'],
                files: []
            };
            scope.selectedFiles = [];
            scope.fileToDelete = "testowanie"
            getFiles(rootPath());

            scope.setBackground = function(file){
                scope.backgroundUrl = file.name
            };

            scope.enterDirectory = function(directory) {
                var pathElements = scope.currentDirectory.path.slice(0);
                pathElements.push(directory.name);
                changeDirectory(pathElements);
            };

            scope.changeDirectory = function (pathElementIndex) {
                changeDirectory(scope.currentDirectory.path.slice(0, pathElementIndex + 1));
            };

            scope.renderPathElement = function(pathElement) {
              return pathElement !== '/' ? pathElement + ' / ' : ' / ';
            };

            scope.enableDeleteAll = function () {
                for (var i = 0; i < scope.selectedFiles.length; i++) {
                    if (scope.selectedFiles[i]) {
                        return true;
                    }
                }
                return false;
            };

            scope.deleteFile = function(file) {
                fileBrowserService.delete([scope.absoluteFilePath(file)]).then(function() {
                    getFiles(scope.currentDirectory.path);
                });
            };

            scope.selectFileToDelete = function(file) {
                scope.fileToDelete = file;
            };

            scope.deleteSelectedFiles = function() {
                var filesForDeletion = [];
                for (var i = 0; i < scope.selectedFiles.length; i++) {
                    if (scope.selectedFiles[i]) {
                        filesForDeletion.push(scope.absoluteFilePath(scope.currentDirectory.files[i]));
                    }
                }
                fileBrowserService.delete(filesForDeletion).then(function() {
                    getFiles(scope.currentDirectory.path);
                });
            };

            scope.absoluteFilePath = function (file) {
                return currentDirectoryAbsolutePath() + '/' + file.name;
            };

            function currentDirectoryAbsolutePath() {
                var pathElements = scope.currentDirectory.path.slice(0);
                if (pathElements.length == 1) {
                    return scope.rootPath;
                }

                var absolutePath = '';
                pathElements.forEach(function(pathElement) {
                    if (pathElement !== '/') {
                        absolutePath += '/' + pathElement;
                    }
                });

                return scope.rootPath === '/' ? absolutePath : scope.rootPath + absolutePath;
            }

            function changeDirectory(pathElements) {
                var currentDirectory = scope.root;
                var path = [];

                pathElements.forEach(function (pathElement) {
                    path.push(pathElement);
                    if (pathElement === '/') {
                        return;
                    }

                    currentDirectory = _.find(currentDirectory.files, function (file) {
                        return file.name === pathElement;
                    });
                });

                currentDirectory.files = _.filter(currentDirectory.files, function(file) {
                    return !scope.choosedDirectory || file.isDirectory;
                });

                scope.currentDirectory = {
                    path: path,
                    files: currentDirectory.files
                };
                scope.selectedFiles = [];

                if (scope.choosedDirectory) {
                    scope.choosedDirectory = currentDirectoryAbsolutePath();
                }
            }

            function getFiles(path) {
                fileBrowserService.list(scope.rootPath).then(function(root) {
                    scope.root = root;
                    changeDirectory(path);
                });
            }

            function rootPath() {
                if (!scope.choosedDirectory || scope.choosedDirectory === '/') {
                    return ['/'];
                }

                var rootPath = scope.choosedDirectory.split('/');
                rootPath[0] = '/';
                return rootPath;
            }
        }
    }
}

module.directive('directoryChooser', DirectoryChooserDirective);
function DirectoryChooserDirective($uibModal) {
    return {
        scope: {
            choosedDirectory: '=directoryChooser',
            modalTitle: '@'
        },
        templateUrl: 'file-browser/directory-chooser.html',
        link: function (scope) {
            scope.open = function() {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'file-browser/directory-chooser-modal.html',
                    controller: 'DirectoryChooserModalInstanceCtrl',
                    resolve: {
                        choosedDirectory: function () {
                            return scope.choosedDirectory;
                        },
                        modalTitle: function () {
                            return scope.modalTitle;
                        }
                    }
                });

                modalInstance.result.then(function (choosedDirectory) {
                    scope.choosedDirectory = choosedDirectory;
                });
            }
        }
    }
}

module.controller('DirectoryChooserModalInstanceCtrl', DirectoryChooserModalInstanceCtrl);
function DirectoryChooserModalInstanceCtrl($scope, $uibModalInstance, choosedDirectory, modalTitle) {
    $scope.choosedDirectory = choosedDirectory || '/';
    $scope.modalTitle = modalTitle;
    $scope.path = '/';

    $scope.close = function () {
        $uibModalInstance.close($scope.choosedDirectory);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };
}

<label class="control-label ng-binding">{{'config.restore.config.download.label'|i18n}}</label><br/>
<a href="/data/config.json" class="btn btn-default" download="meternet.backup" target="_blank">
    {{'config.restore.config.download'|i18n}}
</a>

<br/>
<br/>

<label class="control-label ng-binding">{{'config.restore.config.uplad'|i18n}}</label>
<form name="upload" class="form" data-ng-submit="addFile()">
    <label class="btn btn-default btn-file" x-ng-if="!files[0].name">
        {{'config.restore.drop.placeholder' | i18n}}
        <input style="display: none;" type="file" onchange="angular.element(this).scope().uploadedFile(this)"/>
    </label>
    <div x-ng-if="files[0].name">
        <button class="btn btn-primary" type="submit">{{'config.restore.drop.upload' | i18n}}</button>
        {{files[0].name}}
    </div>
</form>

/*global tools, angular */
var module = angular.module('meternet.chart.directives.gauge', []);

module.directive('gauge', function($window, $timeout, DashboardEvents, unitFilter, dateFilter, gaugeUtils, gaugeSerieFactory) {  

    function Gauge(svg, configuration) {
        this.svg = svg;
        this.data = {};
        this.series = {};
        this.config = {};
        

        this.render = function(configuration) {
            var i;
            
            if (configuration){
                if (!angular.equals(this.config.series, configuration.series)){
                    this.svg.selectAll('*').remove();
                    this.config._series = [];
                    for (i = 0; i< configuration.series.length; i++){
                        var serie = tools.copy(configuration.series[i]);
                        gaugeUtils.sanitizeSerie(serie, i);
                        this.config._series.push(serie);               
                    }
                }
                this.config = angular.extend(this.config, configuration);
                this.config.valueFormatter = configuration.valueFormatter;
                this.config.dateFormatter = configuration.dateFormatter;
               
            }
            if (!isFinite(this.config.width) || !isFinite(this.config.height)){
                return;
            }
            var serieConfig = {
                    size : this.config.size,
                    width : this.config.width,
                    height : this.config.height,
                    valueFormatter : this.config.valueFormatter,
                    dateFormatter : this.config.dateFormatter
                    
            };
            svg.attr("width", this.config.width).attr("height", this.config.height);
           
            for (i = 0; i<this.config._series.length; i++){
                var _serie = this.config._series[i];
                var gaugeSerie = this.series[_serie._id];
                if (typeof(gaugeSerie) === 'undefined'){     
                    var serieBody = this.svg.append("svg:g").attr("id", "serie-"+_serie._id);
                    gaugeSerie = gaugeSerieFactory.get(serieBody, _serie, this.config.type);
                    this.series[_serie._id] = gaugeSerie;
                } 
                gaugeSerie.render(serieConfig);
            }
       
        }; 
        
        this.update = function(serieIndex, data) {
            this.data[serieIndex] = data;  
            this.series[serieIndex].redraw(this.data[serieIndex], 0);

        };
        this.render(configuration);
    }

    return {
        restrict : 'A',
        scope : {
            config : '=',
            value : '='
        },
        link : function(scope, elem, attrs) {
            function redraw() {
                if (!scope.redrawPromise) {
                    scope.redrawPromise = $timeout(function() {
                        scope.gauge.update(0, scope.value);
                        delete scope.redrawPromise;
                    });
                }
            }

            scope.config.valueFormatter = unitFilter;
            scope.config.dateFormatter = dateFilter;
            var resize = function(){
                if (!scope.resizePromise) {
                    scope.resizePromise = $timeout(function() {
                        var p = elem.parent();
                        var w = p.innerWidth();
                        var h = p.innerHeight();
                        scope.config.size = Math.min(w, h);
                        scope.config.width = w;
                        scope.config.height = h;
                        scope.gauge.render(scope.config);
                        scope.gauge.update(0, scope.value);   
                        delete scope.resizePromise;
                    });
                }               
            };
            
            scope.gauge = new Gauge(d3.select(elem[0]).append('svg'), scope.config);
            resize();
            scope.$on(DashboardEvents.REDRAW, function(event, value) {
                if (typeof (value) !== 'undefined') {
                    scope.value = value;
                }
                //resize();
                redraw();
            });
            
            scope.$on(DashboardEvents.RESIZE, resize);
            
        }
    };
});

var module = angular.module('meternet.dashboard.directives.common', []);

module.directive('seriesParameterSelector', function () {
	return {
		scope: false,
		replace: true,
		templateUrl: 'dashboard/common/series-parameter-selector.html',
	};
});

module.directive('deviceSelector', function () {
	return {
		scope: false,
		replace: true,
		templateUrl: 'dashboard/common/device-selector.html',
		controller: function ($scope) {
			Object.assign($scope.ui,  {
				deviceLabel: function (device) {
					return device ? (device.label || device.name) : null;
				},
			});
		}
	};
});

module.directive('parameterSelector', function () {
	return {
		scope: false,
		replace: true,
		templateUrl: 'dashboard/common/parameter-selector.html',
		controller: function ($scope) {
			Object.assign($scope.ui,  {
				paramLabel: function (param) {
					if (param) {
						if (param.unit) {
							return (param.label || param.name) + ' [' + param.unit + ']';
						} else {
							return param.label || param.name;
						}
					} else {
						return null;
					}
				}
			});
		}
	};
});

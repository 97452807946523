var module = angular.module('meternet.chart.directives.mbsat2Gauge', ['meternet.charts.services']);

module.directive('mbsat2Gauge', function ($q, $http, $timeout, $rootScope, UnitScales, unitFilter, configService, dataService, i18nFilter, leafletData, leafletBoundsHelpers, paramService, Errors) {
    return {
        scope: {
            options: '=',
            widgetConfig: '=',
            data: '='
        },
        templateUrl: 'dashboard/mbsat2-gauge.html',
        controller: function ($scope, $element, $location, $compile, dashboardService) {
            $scope.markersWithinBounds = [];
            $scope.allMarkers = [];
            $scope.checkedGroups = [];
            $scope.dirty = false;
            $scope.params = [];
            $scope.series = [];
            dashboardService.get($scope.widgetConfig.dashboard).then(function(d){
                if(d){
                    var dash = JSON.parse(JSON.stringify(d))
                    dash.id = null;
                    dash.onlyDashboard = true;
                    var widgets = _.flatten(_.pluck(_.flatten(_.pluck(dash.rows, 'columns')), 'widgets'));
                    _.forEach(widgets, function(w){
                        _.forEach(w.config.series, function(s){
                            s.deviceId = null;
                            s.paramId = null;
                            s.name = "undefine";
                        })
                        w.config.seriesSrc = w.config.series
                    })
                    $scope.ui.template = dash;
                }
            });
            var intervalId;
            $scope.ui = {
                clusterLayer: true,
                onlyMarkersWithinBounds: false,
                config: null,
                clickedMark: null,
                errors: new Errors(),
                templates: [],
                template: {"id": null, "name": "", "data": []},
                bounds: leafletBoundsHelpers.createBoundsFromArray([
                    [0, 0], [0, 0]
                ]),
                markers: [],
                locations: [],
                updateCheckedGroups: function () {
                    var checked = $scope.ui.markers.filter(function (marker) {
                        return marker.checked;
                    }).map(function (marker) {
                        return marker.device
                    });
                    $scope.checkedGroups = checked
                },
                goToReport: function (marks) {
                    changeView(marks)
                },
                showMessage: function (text, css) {
                    this.message = {
                        text: text,
                        css: css
                    };
                    $timeout.cancel(this.messagePromise);
                    this.messagePromise = $timeout(function () {
                        $scope.ui.message = null;
                    }, 5000);
                },
                getCurrent: function (markerId, paramId) {
                    var marker = _.find($scope.ui.markers, function(m) { return m.deviceId ===markerId; });
                    var value;
                    if (marker) {
                        value = _.find(marker.statusParams, function(param) { return param.id === paramId; });
                        if (value && value.moment ) {
                            return value.moment.good
                        }
                    }
                    return value
                }
            }

            $scope.invisibleLayers = new Set();

            $scope.$on('goToCalendar', function (event, setId) {
                $scope.ui.goToSettings(setId);
            });

            function changeView (marks) {
                var widgets = _.flatten(_.pluck(_.flatten(_.pluck($scope.ui.template.rows, 'columns')), 'widgets'));
                _.forEach(widgets, function(w){
                    w.config.series = _.filter(_.clone(w.config.seriesSrc), function(s){
                        return true;
                    });
                    _.forEach(w.config.series, function(s){
                        s.deviceId = null;
                        s.paramId = null;
                        s.name = "undefine";
                    });
                })
                _.forEach(marks, function(mark){
                    var originalSerie = $scope.series.find(function (serie) { return serie.deviceId === mark.deviceId});
                    _.forEach(originalSerie.params,function(p){
                        var tags = _.filter(p.tags.toLowerCase().split("#"), function(t){
                            return t.indexOf("dr_")===0;
                        })
                        if(tags){
                            _.forEach(tags, function(tag){
                                var title = tag.split("_")[1];
                                var widget = _.find(widgets, function(w){
                                    return w.title.toLowerCase() === title;
                                })
                                if(widget){
                                    var serie = _.find(widget.config.series, function(s){
                                        return !s.paramId;
                                    });
                                    if(serie){
                                        serie.paramId = p.id;
                                        serie.deviceId =  mark.deviceId;
                                        serie.name =  (mark.label || mark.name) + "." + (p.label || p.name);
                                    }
    //                            widget.config.series.push(serie);
                                }
                            });
                        }
                    });
                });
                _.forEach(widgets, function(w){
                    w.config.series = _.filter(w.config.series, function(s,i){
                        return s.paramId;
                    });
                    if(w.config.series.length===0){
                        w.config.hide=true;
                        // w.config.series.push({
                        //
                        // })
                    }else{
                        w.config.hide=false;
                    }
                    w.withoutCountingHeight = true
                });
                leafletData.getMap().then(function (map) {
                    map.closePopup();
                });
                setTimeout(function(){
                    $scope.showReport = marks;
                    if(marks){
                        $scope.menu = 1;
                    }else{
                        $scope.menu = 0;
                    }
                    $scope.$emit('meternetConfigUpdate', null);
                }, 200);
            }

            $scope.$on('changeView', function (event, marks) {
                changeView(marks);
            });

            $scope.$on('backToMap', function (event) {
                $scope.backToMap()
            });
            $scope.$on('hideMap', function (event) {
                if($scope.menu !==3){
                    $scope.menu = 3;
                }else{
                    $scope.menu = 0;
                    $scope.fitMap()

                }
            });
            $scope.$on('clickMarkerOnMap', function (event, checked) {
                $scope.ui.clickedMark.checked = checked
                buildPopUp();
            });
            $scope.$on('selectMarker', function (event, marker) {
                checkMarker(marker);
                $scope.ui.updateCheckedGroups()
            });
            $scope.$on('selectAllMarkers', function () {
                checkAllMarkers();
            });
            $scope.$on('overMarker', function (event, marker) {
                var latlng = {
                    lat: marker.lat,
                    lng: marker.lng
                };
                mouseMove(latlng, 'mouseover')
            });

            $scope.$on('leaveMarker', function (event, marker) {
                var latlng = {
                    lat: marker.lat,
                    lng: marker.lng
                };
                mouseMove(latlng, 'mouseout')
            });

            $scope.$on('search', function (event, phrase) {
                checkPhrases(phrase);
                getVisibleMarkers();
            });

            $scope.$on('saveConfig', function () {
                configService.save($scope.ui.config)
                    .then(function (res) {
                        $scope.ui.config = res.config;
                        prepareData();
                        getVisibleMarkers(true);
                        $scope.ui.showMessage('config.save.success', 'alert-success');
                    })
                    .catch(function (err) {
                        $scope.ui.errors = new Errors(err.errors);
                        $scope.ui.showMessage('config.save.failure', 'alert-danger');
                    })
            })

            angular.extend($scope, {
                mapZoom: {
                    lat: 51.7485005,
                    lng: 19.4632418,
                    zoom: 10
                },
                defaults: {
                    scrollWheelZoom: true,
                    trackResize: true
                },
                events: {
                    map: {
                        enable: ['zoomstart', 'drag', 'click', 'mousemove', 'resize'],
                        logic: 'emit'
                    }
                },
                layers: {
                    baselayers: {
                        openStreetMap: {
                            name: 'OpenStreetMap',
                            type: 'xyz',
                            url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        }
                    },
                    overlays: {
                        clusterlayer: {
                            type: 'markercluster',
                            name: 'All clusters',
                            id: 'clusterlayer',
                            visible: true,
                            layerParams: {
                                showOnSelector: false
                            }
                        },
                        markerslayer: {
                            type: 'group',
                            name: 'All markers',
                            id: 'markerslayer',
                            visible: true,
                            layerParams: {
                                showOnSelector: false
                            }
                        }
                        // on: {
                        //   type: 'markercluster',
                        //   name: 'Lights on',
                        //   id: 'on',
                        //   visible: true
                        // },
                        // off: {
                        //   type: 'markercluster',
                        //   name: 'Lights off',
                        //   id: 'off',
                        //   visible: true
                        // }
                    }
                }
            });

            $scope.$watch('ui.bounds', function () {
                getVisibleMarkers();
            });
            $scope.$watch(function () {
                return $location.hash()
            }, function (newVal) {
                $scope.menu = newVal.startsWith('lightingSetting=') ? 2 : 0;
                $scope.fitMap()
            });

            $scope.fitMap = function () {
                leafletData.getMap().then(function (map) {
                    $timeout(function () {
                        map.invalidateSize();
                    });
                    if ($scope.ui.markers.length === 0) {
                        return
                    }
                    var bounds = L.latLngBounds($scope.ui.markers);
                    map.fitBounds(bounds, {padding: [5, 5]});
                })
            }

            $scope.toggleLayerType = function () {
                _.forEach($scope.ui.locations, function (marker) {
                    marker.layer = $scope.ui.clusterLayer ? "clusterlayer" : "markerslayer"
                });
            }

            $scope.$watch('ui.clusterLayer', function () {
                $scope.toggleLayerType();
            });
            $scope.backToMap = function () {
                if (!$scope.dirty) {
                    $location.hash('');
                    $scope.menu = 0;
                    $scope.fitMap();
                } else {
                    $('#configModal').modal('show');
                }
            }

            function prepareLocations(sourceData) {
                var locationsList = []
                locationsList = _.uniq(sourceData.map(function(marker, index){
                    return {
                        localisation: marker.localisation,
                        markers:[],
                        popUpMarkers: [],
                        layer: $scope.ui.clusterLayer ? "clusterlayer" : "markerslayer",
                        icon: {
                            iconUrl: '../resources/images/marker-icon.png',
                            shadowUrl: '../resources/images/marker-shadow.png',
                            iconAnchor: [12, 41]
                        },
                        lat: marker.lat,
                        lng: marker.lng,
                        focus: false,
                        draggable: false,
                        riseOnHover: true,
                        id: marker.localisation.toLowerCase().replace(/\s/g, '').replace(/\./g,'') + index
                    }
                }),"localisation");
                _.forEach(sourceData, function(marker){
                    var localisation = _.find(locationsList ,{localisation: marker.localisation});
                    localisation.markers.push(marker);
                })
                return locationsList;
            }

            function prepareData() {
                configService.get().then(function (meternetConfig) {
                    $scope.ui.config = meternetConfig;
                    $scope.series.length = 0;
                    _.forEach($scope.options.series, function (optionsSerie) {
                        _.forEach(meternetConfig.engine.measurementInputs, function (input) {
                            var device = _.findWhere(input.devices, {id: optionsSerie.deviceId});
                            if (device) {
                                $scope.series.push({
                                    deviceId: device.id,
                                    name: device.name,
                                    label: device.label,
                                    lat: device.lat,
                                    lon: device.lon,
                                    localisation: device.localisation,
                                    fixedCost: device.fixedCost,
                                    variableCost: device.variableCost,
                                    params: device.params,
                                    device: device,
                                    interface: input
                                });
                                return false;
                            }
                        })
                    });
                    $scope.ui.markers.length = 0;
                    _.forEach($scope.series, function (serie) {
                        var position = tools.getEffectiveGeolocation(serie, serie.interface);
                        if (!position) {
                            // (ak) nie wyświetlać markerów bez geolokalizacji (?)
                            console.warn('Device without geolocation!', serie)
                            return;
                        }
                        var statusParams = serie.params.filter(function (param) {
                            // return param.quantity === 'status' || param.tags.indexOf("main")>=0
                            return param.tags.indexOf("main")>=0
                        })
                        $scope.ui.markers.push({
                            lat: parseFloat(position[0]),
                            lng: parseFloat(position[1]),
                            label: serie.label,
                            desc: serie.device.desc,
                            desc2: serie.device.desc2,
                            desc3: serie.device.desc3,
                            deviceId: serie.deviceId,
                            localisation: serie.localisation,
                            statusParams: statusParams
                        });
                    });
                    $scope.allMarkers = $scope.ui.markers.slice();
                    refreshLocations();
                    $scope.fitMap();
                });
            }

            prepareData();

            $scope.$on('leafletDirectiveMap.overlayadd', function (event, locationEvent) {
                var layer = _.findWhere(locationEvent.model.layers.overlays, {name: locationEvent.leafletEvent.name});
                if (layer && $scope.invisibleLayers.has(layer.id)) {
                    $scope.invisibleLayers.delete(layer.id);
                    getVisibleMarkers();
                }
            });
            $scope.$on('leafletDirectiveMap.overlayremove', function (event, locationEvent) {
                var layer = _.findWhere(locationEvent.model.layers.overlays, {name: locationEvent.leafletEvent.name});
                if (layer &&  !$scope.invisibleLayers.has(layer.id)) {
                    $scope.invisibleLayers.add(layer.id);
                    getVisibleMarkers();
                }
            });
            $scope.$on('leafletDirectiveMarker.click', function (event, locationEvent) {
                $scope.ui.clickedMark = locationEvent.model;
                $scope.$broadcast('clickMarker', locationEvent.model);
            });

            $scope.$on('leafletDirectiveMap.resize', function (event, locationEvent) {
                $scope.fitMap()
            });

            $scope.$on('leafletDirectiveMarker.mouseover', function (event, args) {
                // (ak) doesn't work for cluster
                colorTable(args.model.id, 'mouseover');
                mouseMove(args.leafletEvent.latlng, 'mouseover');
            });
            $scope.$on('leafletDirectiveMarker.mouseout', function (event, args) {
                // (ak) doesn't work for cluster
                colorTable(args.model.id, 'mouseout');
                mouseMove(args.leafletEvent.latlng, 'mouseout');
            });

            function getIcon(marker) {
                var smallIconOn = {
                    iconUrl: '../resources/images/marker-icon-on.png',
                    shadowUrl: '../resources/images/marker-shadow.png',
                    iconAnchor: [12, 41]
                };
                var bigIconOn = {
                    iconUrl: '../resources/images/marker-icon-on-big.png',
                    shadowUrl: '../resources/images/marker-shadow-big.png',
                    iconAnchor: [15, 49]
                };
                var smallIconOnChecked = {
                    iconUrl: '../resources/images/marker-icon-on-checked.png',
                    shadowUrl: '../resources/images/marker-shadow.png',
                    iconAnchor: [12, 41]
                };
                var bigIconOnChecked = {
                    iconUrl: '../resources/images/marker-icon-on-big-checked.png',
                    shadowUrl: '../resources/images/marker-shadow-big.png',
                    iconAnchor: [15, 49]
                };
                var smallIconOff = {
                    iconUrl: '../resources/images/marker-icon-off.png',
                    shadowUrl: '../resources/images/marker-shadow.png',
                    iconAnchor: [12, 41]
                };
                var bigIconOff = {
                    iconUrl: '../resources/images/marker-icon-off-big.png',
                    shadowUrl: '../resources/images/marker-shadow-big.png',
                    iconAnchor: [15, 49]
                };
                var smallIconOffChecked = {
                    iconUrl: '../resources/images/marker-icon-off-checked.png',
                    shadowUrl: '../resources/images/marker-shadow.png',
                    iconAnchor: [12, 41]
                };
                var bigIconOffChecked = {
                    iconUrl: '../resources/images/marker-icon-off-big-checked.png',
                    shadowUrl: '../resources/images/marker-shadow-big.png',
                    iconAnchor: [15, 49]
                };
                var smallIconOk = {
                    iconUrl: '../resources/images/marker-icon.png',
                    shadowUrl: '../resources/images/marker-shadow.png',
                    iconAnchor: [12, 41]
                }
                var bigIconOk = {
                    iconUrl: '../resources/images/marker-icon-big.png',
                    shadowUrl: '../resources/images/marker-shadow-big.png',
                    iconAnchor: [15, 49]
                }
                var smallIconError = {
                    iconUrl: '../resources/images/marker-icon-hover.png',
                    shadowUrl: '../resources/images/marker-shadow.png',
                    iconAnchor: [12, 41]
                }
                var bigIconError = {
                    iconUrl: '../resources/images/marker-icon-hover-big.png',
                    shadowUrl: '../resources/images/marker-shadow-big.png',
                    iconAnchor: [15, 49]
                }
                // if (marker.isBig) {
                //     return marker.checked ? isOn(marker.markers) ? bigIconOnChecked : bigIconOffChecked : isOn(marker.markers) ? bigIconOn : bigIconOff
                // } else {
                //     return marker.checked ? isOn(marker.markers) ? smallIconOnChecked : smallIconOffChecked : isOn(marker.markers) ? smallIconOn : smallIconOff
                // }
                if (marker.isBig) {
                    return isOn(marker.markers) ? bigIconError : bigIconOk
                } else {
                    return isOn(marker.markers) ? smallIconError : smallIconOk
                }
            }

            function mouseMove(latlng, action) {
                _.forEach($scope.ui.locations, function (marker) {
                    if (marker.lat === latlng.lat && marker.lng === latlng.lng) {
                        if (action === 'mouseover') {
                            marker.isBig = true;
                        } else if (action === 'mouseout') {
                            marker.isBig = false;
                        }
                        marker.icon = getIcon(marker);
                    }
                });
            }

            function checkMarker(marker) {
                if ($scope.ui.clickedMark && marker.deviceId === $scope.ui.clickedMark.deviceId) {
                    $scope.ui.clickedMark.checked = !$scope.ui.clickedMark.checked;
                }
                marker.icon = getIcon(marker);
            };

            function checkAllMarkers() {
                _.forEach($scope.ui.markers, function (marker) {
                    checkMarker(marker);
                });
            };

            function colorTable(id, action) {
                var rowId = "#" + id;
                var row = $element.find(rowId);
                if (action === 'mouseover') {
                    row.addClass("hover");
                } else {
                    row.removeClass("hover");
                }
            }

            function getVisibleMarkers(closePopUp) {
                leafletData.getMap().then(function (map) {
                    var markers = $scope.ui.markers.filter(function (marker) {
                        return map.getBounds().contains(L.latLng(marker.lat, marker.lng));
                    });
                    $scope.markersWithinBounds = _.reject(markers, function (marker) {
                        return $scope.invisibleLayers.has(marker.layer);
                    });
                    $scope.locationWithinBounds = prepareLocations($scope.markersWithinBounds)
                    if (closePopUp) {
                        map.closePopup();
                    }
                })
            }
            function refreshLocations() {
                $scope.ui.locations = prepareLocations($scope.ui.markers);
            }

            function buildPopUp() {
                var directive = '<div mbsat2-marker-popup=""></div>';
                var compiledHtml = $compile(directive)($scope);
                var popup = L.popup({offset: L.point(0, -41), minWidth: 200})
                    .setLatLng([$scope.ui.clickedMark.lat, $scope.ui.clickedMark.lng])
                    .setContent(compiledHtml[0]);
                leafletData.getMap().then(function (map) {
                    popup.openOn(map);
                });
            }

            function checkPhrases(phrase) {
                $scope.ui.markers.length = 0;
                var i, s, found;
                var columns = ['label', 'title', 'desc', 'desc2', 'desc3'];
                _.forEach($scope.allMarkers, function (marker) {
                    found = false;
                    for (i = 0; i < columns.length; i++ ) {
                        s = marker[columns[i]];
                        if (s && s.toLowerCase().indexOf(phrase.toLowerCase()) >= 0) {
                            found = true;
                            break;
                        }
                    }
                    if (found) {
                        $scope.ui.markers.push(marker);
                    }
                });
                refreshLocations()
            }

            $scope.$on("setDirty", function (event, args) {
                $scope.dirty = args.dirty;
                var info = $scope.dirty ? "calendar.dirty" : args.withBroadcast ? "calendar.dirty.cancelled" : null;
                if (info) {
                    $rootScope.$broadcast(info);
                }
            });

            $scope.$on("$locationChangeStart", warnIfUnsavedChanges);

            $scope.$watchCollection('markersWithinBounds', function (newVal) {
                $scope.params = paramService.prepareParamsForMarkers($scope.markersWithinBounds);
                paramService.unsubscribeUnchecked($scope.params);
                paramService.subscribeParams($scope.params);
            });

            $scope.$watch('params', function (newVal) {
                _.forEach($scope.ui.locations, function (marker) {
                    marker.icon = getIcon(marker);
                });
            }, true);

            $scope.$on('$destroy', function() {
                paramService.unsubscribeUnchecked([]);
                clearInterval(intervalId);
            });

            function warnIfUnsavedChanges(event, to, from) {
                if (from.includes("/dashboard/") && from.includes("lightingSetting=") && $scope.dirty) {
                    $('#configModal').modal('show');
                    event.preventDefault();
                    var word = 'lightingSetting=';
                    var id = from.slice(from.indexOf(word) + word.length);
                    var idxOf = id.indexOf('&');
                    if (idxOf > -1) {
                        var setId = id.substr(0, idxOf);
                        word = 'scheduler=';
                        var schedulerId = id.slice(id.indexOf(word) + word.length);
                        $scope.$broadcast('restore settings', {setId: setId, schedulerId: schedulerId});
                    } else {
                        $scope.$broadcast('restore settings', {setId: id});
                    }
                }
            }

            function isOn(group) {
                var currVal;
                _.forEach(group, function (marker) {
                    _.forEach(marker.statusParams, function (param) {
                        // if (param.quantity === 'status' && param.moment && param.moment.current) {
                        //   currVal = !!param.moment.current.value;
                        //   return false;
                        // }
                        // FIXME (ak) isOn (red) when value (f.e CPU) > 0.13
                        if(param.tags.indexOf("main")>=0 && param.moment && param.moment.current) {
                            currVal = param.moment.current.value > 0.13;
                            if (currVal) {
                                return false
                            }
                        }
                    });
                    if (currVal) {
                        return false
                    }
                });
                return currVal;
            }
        }
    }
});

<div x-expand-to-parent-bottom="" style="overflow-y: auto;">
    <center>
        <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax({{minWidth_short}}, 1fr)); grid-gap: 10px; padding: 10px;" x-ng-if="options.layout==false">
            <div x-ng-class="{'error': serie.error}" x-ng-repeat="serie in options.series" style="border-width: 1px; border-style: solid; border-color: #ccc; border-radius: 5px;">
                <table style="min-width:{{minWidth_short}}; margin: 15px 0px;">
                    <tbody>
                        <tr>
                            <td colspan="2" style="text-align: center; padding: 5px;">
                                <h3 style="margin-bottom:2px; margin-top:0px">{{serie.name}}</h3>
                                <font style="color:#ccc">{{serie.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</font>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-left:{{minWidth_padding}}">
                                <!--{{serie.wait}}-->
                                <!--<div x-ng-if="serie.wait>0">-->
                                    <!--<div class="progress progress-striped active">-->
                                        <!--<div class="progress-bar"  role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>-->
                                    <!--</div>-->
                                <!--</div>-->
                                <div class="can-toggle">
                                    <input id="{{serie.uuid}}" type="checkbox"  x-ng-click="changeOutput(serie)" x-ng-checked="serie.value" x-ng-disabled="serie.error || serie.wait>0">
                                    <label for="{{serie.uuid}}">
                                        <div class="can-toggle__switch" data-checked="{{serie.onName}}" data-unchecked="{{serie.offName}}"></div>
                                    </label>
                                </div>
                                <div class="progress progress-striped active" x-ng-if="serie.wait>0" style="width:134px; height:6px">
                                    <div class="progress-bar"  role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax({{minWidth_long}}, 1fr)); grid-gap: 10px; padding: 10px;" x-ng-if="options.layout==true">
            <div x-ng-class="{'error': serie.error}" x-ng-repeat="serie in options.series" style="border-width: 1px; border-style: solid; border-color: #ccc; border-radius: 5px;">
                <table style=" margin: 15px 0px;">
                    <tbody>
                    <tr>
                        <td colspan="2" style="text-align: center; padding: 0px; width:{{minWidth_short}}">
                            <h3 style="margin-bottom:2px; margin-top:0px">{{serie.name}}</h3>
                            <font style="color:#ccc">{{serie.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</font>
                        </td>
                        <td style="text-align: right; width:140px">
                            <div class="can-toggle">
                                <input id="{{serie.uuid}}" type="checkbox"  x-ng-click="changeOutput(serie)" x-ng-checked="serie.value"  x-ng-disabled="serie.error">
                                <label for="{{serie.uuid}}">
                                    <div class="can-toggle__switch" data-checked="{{serie.onName}}" data-unchecked="{{serie.offName}}"></div>
                                </label>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </center>
</div>
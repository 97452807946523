<form class="form-horizontal row" x-errors="item.errors" x-errors-root="item.config" x-ng-switch="item.config.type">
    <div class="col-sm-8">
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.id' | i18n}}</label>
            <div class="col-sm-8">
                <input class="form-control" x-ng-model="item.config.id" x-field-errors="" x-ng-readonly="true"/>
            </div>
        </div>
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'dashboard.widgets.config.series.lightingSetting' | i18n}}</label>
            <div class="col-sm-8">
                <input class="form-control" x-ng-model="item.config.lightingSettingId" x-ng-disabled="true"
                       x-field-errors=""/>
            </div>
        </div>
        <div class="form-group" x-ng-if="advanceView.value">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-errors=""
                           x-field-help="config.parameter.name.help"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-errors=""
                           x-field-default-value="item.config.name" x-field-help="config.param.label.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc1' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-errors=""
                           x-field-help="config.param.desc.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc2' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc2" x-field-errors=""
                           x-field-help="config.param.desc.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc3' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc3" x-field-errors=""
                           x-field-help="config.param.desc.help"/>
                </div>
            </div>
        </div>
        <div x-ng-if="item.config.type === 'mbsat'">
            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.device.lat' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" type="number" x-field-help="config.device.lat.help"
                               x-ng-model="item.config.lat" x-field-errors=""/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.device.lon' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" type="number" x-field-help="config.device.lon.help"
                               x-ng-model="item.config.lon" x-field-errors=""/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-sm-3">{{'config.device.load' | i18n}}</label>
                <div class="col-sm-8">
                    <div class="input-group">
                        <input class="form-control" type="number" x-field-help="config.device.load.help"
                               x-ng-model="item.config.load" x-field-errors=""/>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type == 'energy-monitor'">
            <label class="control-label col-sm-3">{{'config.powerContracted' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.powerContracted" x-field-errors=""
                           x-field-help="config.device.powerContracted.help"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type == 'energy-monitor'">
            <label class="control-label col-sm-3">{{'config.powerConnected' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.powerConnected" x-field-errors=""
                           x-field-help="config.device.powerContracted.help"/>
                </div>
            </div>
        </div>
    </div>
</form>

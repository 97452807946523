<form class="form-horizontal" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
		<div x-ng-repeat="m in item.config.costs track by $index" class="form-inline">
			<hr x-ng-if="$index > 0" />
			<div class="row">
				<div class="form-group">
					<label class="form-group-label">{{'config.media.medium' | i18n}}</label>
					<input class="form-control" style="width: 100px;" x-ng-model="m.medium" x-field-errors="'costs[' + $index + '].medium'"/>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.media.unit' | i18n}}</label>
					<select class="form-control"
							style="width: 50px;"
							x-ng-model="m.quantity"
							x-ng-options="q.type as q.unit for q in ui.quantities"
							x-field-errors="'costs[' + $index + '].quantity'">
					</select>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.media.scale' | i18n}}</label>
					<select class="form-control"
							style="width: 50px;"
							x-ng-model="m.scale"
							x-ng-options="s.value as s.label for s in ui.scales"
							x-field-errors="'costs[' + $index + '].scale'">
					</select>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.media.cost' | i18n}}</label>
					<div class="input-group">
						<input class="form-control" type="number" step="0.01" style="width: 50px;" x-ng-model="m.cost" x-field-errors="'costs[' + $index + '].cost'" />
						<span class="input-group-addon">{{ui.unit(m)}}</span>
					</div>
				</div>
				<div class="form-group">
					<label class="form-group-label">{{'config.media.currency' | i18n}}</label>
					<input class="form-control" style="width: 50px;" x-ng-model="m.currency" x-field-errors="'costs[' + $index + '].currency'" />
				</div>
				<div class="form-group">
					<label class="form-group-label">&nbsp;</label>
					<button class="btn btn-danger" x-ng-click="ui.remove(m)">
						<i class="fa fa-minus"></i>
					</button>
				</div>
			</div>
			<div class="row">
				<div class="form-group">
					<label class="form-group-label">{{'config.media.tag' | i18n}}</label>
					<input class="form-control" style="width: 300px;" x-ng-model="m.tag" x-field-errors="'costs[' + $index + '].tag'" />
				</div>
			</div>
		</div>
		<div class="form-group">
			<label class="form-group-label">&nbsp;</label>
			<button class="btn btn-primary" x-ng-click="ui.add()">
				<i class="fa fa-plus"></i>&nbsp;<span>{{'config.add' | i18n}}</span>
			</button>
		</div>
    </div>
    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" x-ng-src="{{ui.type.iconUrl}}" />
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.media.costs' | i18n}}
                </span>
				<a class="btn btn-default" href="#" target="_blank"
				   x-ng-href="{{ui.type.datasheetUrl}}"
				   x-ng-show="ui.type.datasheetUrl">
					{{'config.device.datasheet' | i18n}}&nbsp;<i class="fa fa-external-link"></i>
				</a>
            </div>
        </div>
    </div>
</form>

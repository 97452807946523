var module = angular.module('meternet.eventindicator.directives', [
    'meternet.services', 'ngAudio'
]);

module.directive('eventIndicator', function ($http, $sce, $timeout, $location, i18nFilter, eventService, readoutService, configService, Objects, ngAudio, localStorageService) {
    return {
        restrict : 'A',
        scope : {
            config : '='
        },
        templateUrl: 'status/event-indicator.html',
        controller: function ($scope, $rootScope) {

            var config = localStorageService.get('ui.notifications');

            var templates = {};
            eventService.currentLevel(null);
            $scope.events = eventService.indicatorEvents();
            $scope.notification = {};
            $scope.notificationTimeout={};
            $scope.reading = false;
            $scope.readout = false;
            $scope.path = $location.$$path;
            $scope.sound = ngAudio.load("resources/sounds/tick.mp3"); // returns NgAudioObject


            $http({
                method: 'GET',
                url: '/status'
            }).then(function successCallback(data) {
                $scope.databaseErr = !data.data.errSpace;
                $scope.databaseErrMOs = [];
            }, function errorCallback(response) {
            });

            function updateSources() {
                configService.get().then(function (config) {
                    $scope.sources = {};
                    _.each(config.engine.eventInputs, function (input) {
                        _.each(input.sources, function (source) {
                            var s = {
                                config: source
                            };
                            $scope.sources[source.sourceId] = s;
                        });
                    });
                    _.each($scope.sources, function (source) {
                        source.path = "";
                        _.each(source.config.path, function (id) {
                            var s = $scope.sources[id];
                            source.path += s.config.label || s.config.name;
                            if (id !== source.config.sourceId) {
                                source.path += " / ";
                            }
                        });
                    });
                });
            }

            $scope.source = function (event) {
                    var info = "";
                    var source = $scope.sources[event.sourceId];
                    if (source) {
                        info = i18nFilter('config.componentType.' + source.config.sourceType) + ' <b>' + source.path + '</b>';
                    } else {
                        info = "???";
                    }
                    return info;
                },
            $scope.message = function (event) {
                    var template = templates[event.type];
                    if (!template) {
                        var msg = i18nFilter('event.' + event.type);
                        template = _.template(msg);
                        templates[event.type] = template;
                    }
                    return template(event.data);
                },
            $scope.display = function (event) {
                if (!event._msg) {
                    event._msg = $sce.trustAsHtml($scope.source(event) + ': ' + $scope.message(event));
                }
                return event._msg;
            }

            readoutService.isRunning().then(function(status) {
                $scope.reading=status;
            });

            updateSources();

            $scope.$watch('events', function () {
                while ($scope.events.length > 0) {
                    if($scope.events[0].type == "engine_stop"){
                        $scope.reading = false;
                        $rootScope.$broadcast('engine-readings');
                    }else if($scope.events[0].type == "engine_started"){
                        $scope.reading = true;
                        $rootScope.$broadcast('engine-started');
                    }else if($scope.events[0].type == "engine_oneshootstart"){
                        $scope.readout = true;
                        $rootScope.$broadcast('engine-oneshootstart');
                    }else if($scope.events[0].type == "engine_oneshootfinish"){
                        $scope.readout = false;
                        $rootScope.$broadcast('engine-oneshootfinish');
                    }else if($scope.events[0].type == "output_timeout" || $scope.events[0].type == "database_init_error"){
                        $scope.databaseErr = true;
                        $scope.databaseErrMsg = i18nFilter('config.storage.error')
                        var moName = $scope.events[0].data.measurementoutput.label;
                        if($scope.databaseErrMOs.indexOf(moName)===-1){
                            $scope.databaseErrMOs.push(moName)
                        }
                        $scope.databaseErrMsg = $scope.databaseErrMsg + ": " + $scope.databaseErrMOs.join(", ")
                    }

                    if($scope.events[0] && $scope.events[0].level>=2){
                        if($location.$$path!='/' && $scope.config.onoff){
                            $scope.notification=$scope.events[0];
                            if($scope.notificationTimeout){
                                $timeout.cancel($scope.notificationTimeout);
                            }
                            $scope.notificationTimeout = $timeout(function() {
                                $scope.notification={};
                            },5000);
                        }
                        if($scope.config.sound){
                            $scope.sound.play();
                        }
                    }
                    $scope.events.pop();
                }
            }, true);

        }
    };
});

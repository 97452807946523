<div class="row">

    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.heatmap.config.settings' | i18n}}</div>
        <div class="panel-body">
            <form class="form form-inline">

                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.background.size.x"></label>
                    <div class="input-group" style="width: 100px">
                        <input class="form-control" data-num-format="" name="widgetsConfigBackgroundSizeX"
                               placeholder="{{'dashboard.widgets.config.background.size.x' | i18n }}"
                               data-ng-model="config.backgroundWidth" x-ng-min="1" type="number"/>
                        <span class="input-group-addon">px</span>
                    </div>
                </div>

                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.background.size.y"></label>
                    <div class="input-group" style="width: 100px">
                        <input class="form-control" data-num-format="" name="widgetsConfigBackgroundSizeY"
                               placeholder="{{'dashboard.widgets.config.background.size.y' | i18n }}"
                               data-ng-model="config.backgroundHeight" x-ng-min="1" type="number"/>
                        <span class="input-group-addon">px</span>
                    </div>
                </div>

                <div x-flow-init="{target: '/upload', singleFile: true}" x-flow-files-submitted="$flow.files[0].name=ui.uuid+'_'+$flow.files[0].name; $flow.upload()"
                     x-flow-file-success="ui.setBackground($flow.files[0])" >
                    <div class="file-drop" x-flow-drop="" x-flow-btn="" x-flow-attrs="{accept:'image/*'}"
                         x-ng-show="!$flow.isUploading()" x-flow-drag-enter="cssClass = 'active'"
                         x-flow-drag-leave="cssClass = null"
                         x-ng-class="cssClass">
						<span class="file-drop-placeholder" x-ng-show="config.backgroundUrl==null">
							{{'dashboard.widgets.heatmap.config.background.placeholder' | i18n}} </span>
                        <span class="file-drop-preview"
                              x-ng-show="config.backgroundUrl!=null"> <img x-ng-src="{{config.backgroundUrl}}"/><br/>
							<button class="btn btn-danger" x-ng-show="config.backgroundUrl!=null"
                                    x-ng-click="ui.removeBackground($event)">
                                {{'dashboard.widgets.heatmap.config.background.delete'| i18n}}
                            </button>
						</span>
                    </div>
                    <div x-flow-transfers="" x-ng-if="$flow.isUploading()">
                        <div x-ng-repeat="file in transfers">
                            <div>{{file.name}}</div>
                            <div x-uib-progressbar="" x-value="file.progress()" x-max="1">{{file.size * file.progress() |
                                fileSize}}/{{file.size | fileSize}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.heatmap.gradient.settings' | i18n}}</div>
        <div class="panel-body">
            <form class="form form-inline">
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.range.min"></label>
                    <input class="form-control"
                           data-num-format="" name="widgetsConfigRangeMin"
                           placeholder="{{'dashboard.widgets.config.range.min' | i18n }}"
                           data-ng-model="config.rangeMin"
                           style="width: 95px"
                           data-validate="{'dashboard.widgets.config.error.minMax': config.rangeMin > config.rangeMax}"/>
                </div>

                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.range.max"></label>
                    <input class="form-control"
                           data-num-format="" name="widgetsConfigRangeMax" placeholder="{{'dashboard.widgets.config.range.max' | i18n }}"
                           data-ng-model="config.rangeMax"
                           style="width: 95px"
                           data-validate="{'dashboard.widgets.config.error.maxMin': config.rangeMin > config.rangeMax}"/>
                </div>

                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.grid.size.x"></label>
                    <input class="form-control" data-num-format="" name="widgetsConfigGridSizeX" placeholder="{{'dashboard.widgets.config.grid.size.x' | i18n }}"
                           data-ng-model="config.gridSizeX" x-required="" style="width: 70px" x-ng-min="1" type="number"/>
                </div>

                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.grid.size.y"></label>
                    <input class="form-control" data-num-format="" name="widgetsConfigGridSizeY" placeholder="{{'dashboard.widgets.config.grid.size.y' | i18n }}"
                           data-ng-model="config.gridSizeY" x-required="" x-ng-min="1" type="number" style="width: 70px"/>
                </div>
            </form>
        </div>
    </div>

    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="series in config.series">
                <form class="form form-inline">
                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <div style="width: 15px; cursor: pointer;">
                            <i class="fa fa-lg" x-ng-click="series._details = !series._details"
                               x-ng-class="series._details ? 'fa-minus' : 'fa-plus'"></i>
                        </div>
                    </div>

                    <div class="form-group" style="width: 130px; min-width: 130px;">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
                        <ui-select ng-model="series._device" theme="bootstrap" ng-disabled="ctrl.disabled" style="width: 110px; min-width: 110px;" x-ng-change="ui.updateSeriesParam(series)">
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 110px; min-width: 110px;">
                                {{$select.selected.label || $select.selected.name}}
                            </ui-select-match>
                            <ui-select-choices repeat="device in devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}" style="width: 110px; min-width: 110px;">
                                <div ng-bind-html="((device.label||device.name)) | highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
                                <div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>


                    <div class="form-group" style="width: 130px; min-width: 130px;">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
                        <ui-select ng-model="series._param" theme="bootstrap" ng-disabled="ctrl.disabled" style="width: 110px; min-width: 110px" x-ng-change="ui.updateSeriesParam(series)">
                            <ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 110px; min-width: 110px;">
                                {{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
                            </ui-select-match>
                            <ui-select-choices repeat="param in series._device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}" style="width: 110px; min-width: 110px;">
                                <div ng-bind-html="((param.label||param.name) + ' [' + param.unit + ']'  )| highlight: $select.search"></div>
                                <div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
                            </ui-select-choices>
                        </ui-select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.position.x"></label>
                        <div class="input-group" style="width: 70px">
                            <input class="form-control" x-ng-model="series.posX" name="widgetsConfigSeriesPositionX-{{$index}}" x-required=""
                                   x-ng-min="0"
                                   x-ng-max="100" type="number"/>
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.position.y"></label>
                        <div class="input-group" style="width: 70px">
                            <input class="form-control" x-ng-model="series.posY" x-required="" x-ng-min="0"
                                   name="widgetsConfigSeriesPositionY-{{$index}}"
                                   type="number" x-ng-max="100"/>
                            <span class="input-group-addon">%</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.derivative"></label>
                        <select class="form-control" style="width: 40px;" x-ng-model="series.derivative"
                                x-ng-options="d.value as d.label for d in ui.derivatives"
                                x-ng-change="ui.updateSeriesParam(series)">
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.tempImpact"></label>
                        <select class="form-control" style="width: 40px;" x-ng-model="series.tempImpact"
                                x-ng-options="d.value as d.label for d in ui.tempImpact"
                                x-ng-change="ui.updateSeriesParam(series)">
                        </select>
                    </div>

                    <div class="form-group pull-right">
                        <label class="form-group-label">&nbsp;</label>
                        <button class="btn btn-danger pull-right" style="padding:6px" x-ng-click="ui.removeSeries(series)">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </button>
                    </div>

                    <div x-ng-show="series._details" style="margin-left: 18px;">

                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.showValue"></label>
                            <select class="form-control" style="width: 140px;" x-ng-model="series.displayKind" name="widgetsConfigSeriesDevice-{{$index}}">
                                <option value="box">{{'dashboard.widgets.config.show'|i18n}}</option>
                                <option value="status">{{'dashboard.widgets.config.hide'|i18n}}</option>
                            </select>
                        </div>

                        <!-- Precision -->
                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.precision"></label>
                            <select class="form-control" style="width: 110px;" x-ng-model="series.precision" x-ng-disabled="series.displayKind==='status'"
                                    x-ng-options="p for p in ui.precisions">
                            </select>
                        </div>
                        <!-- Unit -->
                        <div class="form-group" >
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.unit"></label>
                            <input type="text" class="form-control" name="widgetsConfigUnit-{{$index}}"
                                   placeholder="{{'dashboard.widgets.config.unit' | i18n}}"
                                   data-ng-model="series.unit" x-ng-minlength="1" x-ng-maxlength="5"
                                   style="width: 100px" x-ng-disabled="series.displayKind==='status'"/>
                        </div>

                        <br/>

                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.config.fontSize"></label>
                            <select class="form-control" style="width: 140px;" x-ng-model="series.fontSize" name="widgetsConfigSeriesDevice-{{$index}}"
                                    x-ng-options="f.value as f.name for f in fontSizes">
                            </select>
                        </div>

                        <div class="form-group">
                            <label class="form-group-label" x-label-help="dashboard.widgets.linkWidget.chooseDashboard"></label>
                            <select class="form-control" data-ng-model="series.dashboardId" data-ng-options="dashboard.id as dashboard.title for dashboard in dashboards"></select>
                        </div>

                        <br/>

                        <label class="form-group-label" x-label-help="dashboard.widgets.gauge.rangeConfig"></label>
                        <div class="row" x-ng-repeat="zone in series.zones" ng-init="serieIndex = $parent.$index">

                            <div class="form-group col-sm-3">
                                <label class="form-group-label" x-label-help="dashboard.widgets.config.zone.label"></label>
                                <div class="input-group">
                                    <input type="text" class="form-control" name="widgetsConfigLabel-{{$index}}"
                                           placeholder="{{'dashboard.widgets.config.zone.label' | i18n}}"
                                           data-ng-model="zone.label" style="border-radius: 3px;"/>
                                </div>
                            </div>

                            <div class="form-group col-sm-3">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.min"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.min' | i18n }}</label>
                                <div class="input-group">
                                    <input class="form-control" name="widgetsConfigZoneMin-{{serieIndex}}-{{$index}}"
                                           placeholder="{{'dashboard.widgets.config.zone.min' | i18n }}"
                                           x-ng-model="zone.from"
                                           x-required=""
                                           data-scale-formatter=""
                                           data-format-precision="series.precision"
                                           data-format-scale="series.scale"
                                           data-validate="{'dashboard.widgets.config.error.minMax': zone.from > zone.to}"/>
                                    <data-unit-addon data-unit-addon-scale="series.scale" data-unit-addon-unit = series.unit></data-unit-addon>
                                </div>
                            </div>

                            <div class="form-group col-sm-3">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.max"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.max' | i18n }}</label>
                                <div class="input-group">
                                    <input class="form-control" name="widgetsConfigZoneMax-{{serieIndex}}-{{$index}}"
                                           placeholder="{{'dashboard.widgets.config.zone.max' | i18n }}"
                                           x-ng-model="zone.to"
                                           data-scale-formatter=""
                                           data-format-scale="series.scale"
                                           data-format-precision="series.precision"
                                           x-required=""
                                           data-validate="{'dashboard.widgets.config.error.maxMin': zone.from > zone.to}"/>
                                    <data-unit-addon data-unit-addon-scale="series.scale" data-unit-addon-unit = series.unit></data-unit-addon>
                                </div>
                            </div>

                            <div class="form-group col-sm-1">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.color"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.color' | i18n }}</label>
                                <spectrum-colorpicker format="hex" x-ng-model="zone.color"
                                                      options="ui.colorPickerOpts || config.unit || {showInput : true} "/>
                            </div>
                            <div class="form-group col-sm-1">
                                <!--<label class="form-group-label" x-label-help="dashboard.widgets.config.zone.color"></label>-->
                                <label class="form-group-label">{{'dashboard.widgets.config.zone.color' | i18n }}</label>
                                <spectrum-colorpicker format="hex" x-ng-model="zone.fontColor"
                                                      options="ui.colorPickerOpts || config.unit || {showInput : true} "/>
                            </div>

                            <div class="form-group col-sm-1">
                                <label class="form-group-label">&nbsp;</label>
                                <button class="btn btn-danger" x-ng-click="ui.removeZone(serieIndex, $index);"
                                        title="{{'ui.delete' | i18n}}">
                                    <i class="fa fa-trash-o fa-lg"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-sm-1 col-sm-offset-10">
                                <label class="form-group-label">&nbsp;</label>
                                <button class="btn btn-success" x-ng-click="ui.addZone($index);">
                                    <i class="fa fa-plus fa-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </li>
            <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                <div class="pull-right">
                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                        {{'dashboard.widgets.config.series.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="clearfix"></div>
            </li>
        </ul>
    </div>

</div>


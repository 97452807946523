var module = angular.module('meternet.dashboard.controllers.tableGaugeWidget', []);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/table-gauge-widget.html',
        title : messages['dashboard.widgets.tableGauge.title'],
        description : messages['dashboard.widgets.tableGauge.desc'],
//        controller : 'gaugeWidgetCtrl',
        controller : 'tableWidgetCtrl',
        screenshot: false,
        csv: true,
        xlsx: true,
        config : {
            type : "table-gauge",
            columns: [true, true, false, false, false, false, false, false, true, true, true, true],
            series : [ {
                paramId : null
            } ]
        },
        edit : {
            controller : 'tableWidgetEditCtrl',
            templateUrl: 'dashboard/table-widget-edit.html',
            resolve : {
                maxSeries : [ 'licenseService', function(licenseService) {
                    var license = licenseService.getLicense();
                    return 100;
                } ]
            }
        }
    };
    dashboardProvider.widget('table-gauge', widget);
});

function TableEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts, maxSeries) {

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "prepaid"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "alarm"}));
        $scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model : "control"}));

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });

        var checkParam = function(param) {
            return this.paramId === param.id;
        };

        var checkDevice = function(device) {
            return this.deviceId === device.id;
        };

        for (var i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            series._device = _.find($scope.devices, checkDevice, series);
            if (series._device) {
                series._param = _.find(series._device.params, checkParam, series);
            }
        }

    });

    $scope.ui={
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                     paramId : null,
                     onName : "ON",
                     offName : "OFF",
                };
                $scope.config.series.push(series);
            }
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        canAddSeries : function() {
            return $scope.config.series.length < maxSeries;
        },
        updateSeriesParam : function(series) {
            if (series.deviceId !== series._device.id) {
//                series._param = series._device.params[0];
            }
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.scale = series._param.scale || 0;
                series.precision = series._param.precision || 0;

                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                if (!series.derivative) {
                    series.unit=$filter('quantityUnit')(series._param.quantity);
                } else {
                    series.unit = seriesService.getTimeDerivativeUnit($filter('quantityUnit')(series._param.quantity), series.derivative);
                }
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
        },
        isParamOK: function(param, serieParam){
            if(param){
                var count = _.where($scope.config.series, {paramId: param.id}).length;
                if(param === serieParam) { count-- };
                return count;
            }else{
                return 0;
            }
        },
        isDeviceOK: function(device, serieDevice){
            if(device !== serieDevice){
                var count = device.params.length;
                _.forEach(device.params, function(param){
                    if($scope.ui.isParamOK(param, null)>0){
                        count--;
                    }
                });
                return count==0;
            }else{
                return false;
            }
        }
    }
}

function TableCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
        $scope.ui = {};
}

module.controller('tableWidgetEditCtrl', TableEditCtrl);
module.controller('tableWidgetCtrl', TableCtrl);

/**
 * Created by Karol on 2015-04-29.
 */
/* globals tools */
var module = angular.module('meternet.chart.directives.termoGauge', []);

module.directive('termoGauge', function(DashboardEvents, $timeout, UnitScales, unitFilter) {
    function TermoGauge(svg, opt) {
        var options = {
            margin : {
                top : 30,
                right : 10,
                bottom : 80,
                left : 30
            },
            barWidth : 20,
            alertWidth : 60
        };

        var width = 0;
        var height = 0;
        var groupWidth = 0;
        var barWidth = options.barWidth;
        var alertWidth = options.alertWidth;

        this.options = function(o) {
            if (!arguments.length) {
                return tools.copy(options);
            } else {
                tools.copy(o, options);
                return this;
            }
        };

        this.setWidth = function(width) {
            options.width = width;
        };

        this.setHeight = function(height) {
            options.height = height;
        };

        this.update = function(data) {
            if (data) {
                var series = options.series;
                series[data.index].value = data.value;
                series[data.index].timestamp = data.timestamp;
            }
            this.render();

        };


        var getTickValues = function(tickCount, min, max, series) {
            var result = [];
            var tickValue = (max - min) / (tickCount - 1);
            for ( var i = 0; i < tickCount; ++i) {
                var val = unitFilter(min + i * tickValue,series.precision, '', series.scale);
                result.push(min + i * tickValue);
            }
            return result;
        };

        this.render = function() {
            height = options.height - options.margin.bottom;
            width = options.width - (options.margin.left + options.margin.right);
            var series = options.series;
            var axes = [];
            var y = [];
            for ( var i = 0; i < series.length; ++i) {
                var idx = i;
                var minValue = series[i].min;
                var maxValue = series[i].max;
                var scale = d3.scale.linear().domain([ minValue, maxValue ]).range([ height, 0 ]);
                y.push({
                    scale : scale,
                    max : maxValue,
                    min : minValue
                });
                var tickValues = getTickValues(series[i].majorTicks, minValue, maxValue, series[i]);
                var yAxis = d3.svg.axis().scale(scale).orient("left").tickValues(tickValues).tickFormat(function(d) {
                    return unitFilter(d,series[idx].precision, '', series[idx].scale);
                });
                axes.push(yAxis);
            }

            var seriesCount = series.length;

            svg.attr({
                width : options.width,
                height : options.height
            });

            var serie = svg.selectAll("g.serie").data(series);
            var seriee = serie.enter().append("g").attr("class", "serie");
            seriee.append("text").attr("class", "bar-label");

            serie.attr('transform', function(d, i) {
                return 'translate(' + (options.margin.left + (width / (seriesCount * 2)) * (2 * i + 1)) + ',0)';
            });
            var zoneGroups = serie.selectAll("g.zone").data(function(d, i) {
                for ( var z = 0; z < d.zones.length; ++z) {
                    d.zones[z]._index = i;
                }
                return d.zones;
            });
            var zoneGroupsEnter = zoneGroups.enter().append("g").attr("class", "zone");
            zoneGroupsEnter.append("rect").attr("class", "serie-bar");

            var gradientEnter = zoneGroupsEnter.append("svg:defs").append("linearGradient").attr("class",
                    "linearGradient");
            gradientEnter.append("svg:stop").attr("class", "svg-stop-from").attr("offset", "50%").attr("stop-opacity",
                    1);
            gradientEnter.append("svg:stop").attr("offset", "100%").attr("stop-opacity", 1)
                    .attr("class", "svg-stop-to");
            var gradient = zoneGroups.select(".linearGradient");
            gradient.attr({
                "id" : function(d) {
                    return _.uniqueId('zone');
                }
            }).attr({
                "x" : function(d, i) {
                    return -alertWidth / 2;
                },
                "y" : function(d, i) {
                    var to = d.to > y[d._index].max ? y[d._index].max : d.to;
                    return y[d._index].scale(to) + options.margin.top;
                }
            });
            gradient.select(".svg-stop-from").attr("stop-color", function(d) {
                return d3.rgb(d.color).brighter(-1);
            });
            gradient.select(".svg-stop-to").attr("stop-color", function(d) {

                return d3.rgb(d.color);
            });

            zoneGroups.select("rect.serie-bar").attr({
                "x" : function(d, i) {
                    return -alertWidth / 2;
                },
                "y" : function(d, i) {
                    var to = d.to > y[d._index].max ? y[d._index].max : d.to;
                    return y[d._index].scale(to) + options.margin.top;
                },
                "height" : function(d, i) {
                    var from = d.from < y[d._index].min ? y[d._index].min : d.from;
                    var to = d.to > y[d._index].max ? y[d._index].max : d.to;
                    return Math.max(0, y[d._index].scale((y[d._index].max - (to - from))));
                },
                "width" : alertWidth,
                "fill" : function(d) {
                    return d.color;
                }
            });
            zoneGroups.each(function(d) {
                var zoneGroup = d3.select(this);
                zoneGroup.select("rect.serie-bar").style("fill",
                        "url(#" + zoneGroup.select(".linearGradient").attr("id") + ")");
            });

            serie.select("text.bar-label").attr({
                "x" : function(d) {
                    return 0;
                },
                "y" : function(d) {
                    return height + options.margin.bottom - 10;
                },
                "text-anchor" : "middle",
                "fill" : "black"
            }).text(function(d) {
                return d.name;
            });

            seriee.append("g").attr({
                "class" : "y axis",
                "transform" : "translate(" + (-alertWidth / 2) + "," + options.margin.top + ")"
            });

            serie.select("g.y").each(function(elem, i) {
                d3.select(this).call(axes[i]);
            });

//            seriee.append("rect").attr("class", "bar");
//            var bar = serie.select("rect.bar");
//            bar.attr({
//                "x" : function(d, i) {
//                    return -alertWidth / 6;
//                },
//                "y" : function(d, i) {
//                    if (d.value) {
//                        var tempValue = d.value <= y[i].min ? 0 : d.value;
//                        if (y[i].max < tempValue) {
//                            return y[i].scale(y[i].max) + options.margin.top;
//                        }
//                        return y[i].scale(Math.abs(tempValue)) + options.margin.top;
//                    } else {
//                        return y[i].scale(y[i].max) + options.margin.top;
//                    }
//                },
//                "height" : function(d, i) {
//                    if (d.value) {
//                        var tempValue = d.value <= y[i].min ? y[i].min : d.value;
//                        if ((y[i].max - y[i].min) - tempValue < 0) {
//                            return y[i].scale(y[i].min);
//                        }
//                        var mid = Math.abs(y[i].max - (tempValue - y[i].min));
//                        var out = Math.max(0, y[i].scale(mid));
//                        return out;
//                    } else {
//                        return Math.max(y[i].scale(y[i].max), 0);
//                    }
//                },
//                "width" : barWidth,
//                "fill" : "black"
//            }).style("opacity", "0.7");


            seriee.append("line").attr("class", "arrow");
            var arrow = serie.select('line.arrow');
            arrow.attr({
                "x1": function(d,i){return 6;},
                "x2": function(d,i){return 5;},
                "y1": function(d, i) {
                    if (d.value) {
                        var tempValue = d.value <= y[i].min ? 0 : d.value;
                        if (y[i].max < tempValue) {
                            return y[i].scale(y[i].max) + options.margin.top;
                        }
                        return y[i].scale(Math.abs(tempValue)) + options.margin.top;
                    } else {
                        return y[i].scale(y[i].max) + options.margin.top;
                    }
                },
                "y2": function(d, i) {
                     if (d.value) {
                         var tempValue = d.value <= y[i].min ? 0 : d.value;
                         if (y[i].max < tempValue) {
                             return y[i].scale(y[i].max) + options.margin.top;
                         }
                         return y[i].scale(Math.abs(tempValue)) + options.margin.top;
                     } else {
                         return y[i].scale(y[i].max) + options.margin.top;
                     }
                },
                "marker-end": "url(#arrow)",
                "stroke": '#f00',
                "stroke-width": 5
            });

            var defs = serie.select("defs");


            		defs.append("marker")
            				.attr("id","arrow")
            					.attr("viewBox","0 -5 10 10")
            					.attr("refX",5)
            					.attr("refY",0)
            					.attr("markerWidth",10)
            					.attr("markerHeight",80)
            					.attr("orient","auto")

            				.append("path")
            					.attr("d", "M0,-5L10,0L0,5")
            					.attr("class","arrowHead");


            seriee.append("text").attr("class", "bar-value");
            var valuesTexts = serie.select("text.bar-value").attr({
                "x" : function(d) {
                    return 0;
                },
                "y" : function(d) {
                    return 15;
                },
                "text-anchor" : "middle",
                "fill" : "black"
            }).text(function(d) {
                if (d.value) {
                    return unitFilter(d.value, d.precision, d.unit, d.scale);
                }
            }).style("font-size", "15px");

            seriee.append("text").attr("class", "bar-timestamp");
            var timestamp = serie.select("text.bar-timestamp").attr({
                "x" : function(d) {
                    return 0;
                },
                "y" : function(d) {
                    return height + options.margin.bottom - 25;
                },
                "text-anchor" : "middle",
                "fill" : "black"
            }).text(function(d) {
                if (d.timestamp) {
                    return moment(d.timestamp).format("YYYY-MM-DD HH:mm:ss");
                }
            });

        };

    }

    return {
        restrict : 'A',
        scope : {
            options : '=',
            data : '='
        },
        link : function(scope, elem, attr, ctrl) {
            function redraw(data) {
                scope.chart.setWidth(elem.parent().innerWidth());
                scope.chart.setHeight(elem.parent().innerHeight());

                scope.chart.update(data);
            }

            elem.addClass("shift-bar-graph");

            scope.chart = new TermoGauge(d3.select(elem[0]).append('svg'), {
                width : elem.parent().innerWidth(),
                height : elem.parent().innerHeight()
            });
            scope.chart.options(scope.options);

            scope.$on(DashboardEvents.REDRAW, function(event, data) {
                redraw(data);
            });

            scope.$on(DashboardEvents.RESIZE, function(event) {
                redraw();
            });

            redraw();

        }
    };
});

<div class="col-sm-4 col-xs-12 height-max" >
    <!-- state panel -->
    <div class="panel panel-default" style="margin-bottom: 0; height: 100%;">
        <div class="panel-heading">{{ 'status.statePanel' | i18n }}</div>
        <div class="panel-body" style="overflow-y: auto;">
            <div data-ng-show="status.clear" class="center-block text-info text-center well well-lg">
                <i class="fa fa-cog fa-spin fa-1x"></i>{{'status.loading' | i18n}}
            </div>

            <ul data-ng-hide="status.clear" class="list-group">
                <li class="list-group-item" style="font-weight: bold; font-size: 13px; padding: 15px 10px;">
                    {{'status.serial' | i18n}}
                    <div class="pull-right">{{status.serial=="disabled"? ('status.disabled' | i18n)
                        :status.serial}}
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="pull-left col-md-6">{{'status.diskSpace' | i18n}}</div>
                        <div class="col-md-6"  x-ng-if="status.errSpace">
                            <uib-progressbar class="progress-bar-margin0" max="100"
                                         value="status.procentSpace"> <span>{{status.usableSpace
                                | number:0}}MB / {{status.totalSpace | number:0}}MB</span></uib-progressbar>
                        </div>
                        <div class="col-md-6" x-ng-if="!status.errSpace">
                            <uib-progressbar class="progress-bar-margin0" max="100" type="danger" value="100">
                                <span>{{'status.ErrSpace' | i18n}}</span></uib-progressbar>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="pull-left col-md-6">{{'status.ramMemory' | i18n}}</div>
                        <div class="col-md-6">
                            <uib-progressbar class="progress-bar-margin0" max="100"
                                         value="status.procentMemory"> <span>{{status.totalMemory
                                | number:0}}MB / {{status.maxMemory | number:0}}MB</span></uib-progressbar>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="pull-left col-md-6">{{'status.temperature' | i18n}}</div>
                        <div class="col-md-6">
                            <uib-progressbar class="progress-bar-margin0" max="100" value="status.procTemperature"
                                         type="{{70>status.temperature? 'primary' : 'danger'}}"> <span>{{status.temperature |
                                number:1}} &deg;C</span></uib-progressbar>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">
                    <div class="row">
                        <div class="pull-left col-md-6">{{'status.averageLoad' | i18n}}</div>
                        <div class="col-md-6">
                            <uib-progressbar class="progress-bar-margin0" max="100" value="status.procLoad"
                                         type="{{90>status.procLoad? 'primary' : 'danger'}}"> <span>{{status.procLoad | number:1}}
                                %</span></uib-progressbar>
                        </div>
                    </div>
                </li>
                <li class="list-group-item" x-ng-if="status.waitingOnIo || status.waitingOnIo === 0">
                    <div class="row">
                        <div class="pull-left col-md-6">{{'status.waitingOnIo' | i18n}}</div>
                        <div class="col-md-6">
                            <uib-progressbar class="progress-bar-margin0" max="100" value="status.waitingOnIo"
                                         type="{{80>status.waitingOnIo? 'primary' : 'danger'}}"> <span>{{status.waitingOnIo | number:1}}
                                %</span></uib-progressbar>
                        </div>
                    </div>
                </li>
                <li class="list-group-item">{{'status.upTime' | i18n}}
                    <div class="pull-right">{{status.uptime}}</div>
                </li>
                <li class="list-group-item">{{'status.serwerTime' | i18n}}
                    <div class="pull-right">{{status.serwertime}}</div>
                </li>
                <li class="list-group-item">{{'status.sessions' | i18n}}
                    <div class="pull-right">{{status.sessions}}</div>
                </li>
                <li class="list-group-item">{{'status.mac' | i18n}}
                    <div class="pull-right">{{status.mac}}</div>
                </li>
                <li class="list-group-item">{{'status.license' | i18n}}

                    <div class="pull-right">
                        {{license.usage.tokens.total}}/{{license.usage.tokens.max}} {{'config.license.credits' | i18n}}
                    </div>
                </li>
                <ul data-ng-if="license.demo" class="list-group"
                    data-ng-init="groupItemClass = license.licenseErrors.globals.length > 0 ? 'danger' : 'info'">
                    <li class="list-group-item list-group-item-{{groupItemClass}}"><strong>{{'status.license.demo.'+groupItemClass
                        | i18n}}</strong></li>
                    <li data-ng-repeat="err in license.licenseErrors.globals track by $index"
                        class="list-group-item list-group-item-danger">{{err.message | i18n}}
                    </li>
                    <li data-ng-repeat="err in license.licenseErrors.fields track by $index"
                        class="list-group-item list-group-item-danger">{{err.message | i18n}}
                    </li>
                </ul>
            </ul>
        </div>
    </div>
</div>

<div class="col-sm-8 col-xs-12" style="height: 100%" x-event-panel="">
</div>
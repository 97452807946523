<div x-ng-form="widgetConfigForm" x-widget-edit-form-lock="" class="widget-edit">
    <div class="modal-header">
        <button type="button" class="close" ng-click="closeDialog()" aria-hidden="true">&times;</button>
        <h2 class="modal-title">{{widget.title}}</h2>
    </div>
    <div class="modal-body" style="padding-bottom: 0;">
		<div class="form-group">
			<label class="form-group-label" x-label-help="dashboard.widgets.config.title"></label>
			<input type="text" class="form-control" data-ng-model="definition.title" style="width: 100%"/>
		</div>

		<form class="form form-inline" name="configForm">
			<adf-widget-content x-ng-if="widget.edit" model="definition" content="widget.edit"/>
		</form>
    </div>
    <div class="modal-footer">
        <div class="pull-left alert alert-danger" x-ng-if="invalid"><strong>{{'widget.edit.error' | i18n }}</strong></div>
        <button type="button" class="btn btn-lg btn-danger" ng-click="closeDialog()">{{"ui.cancel" | i18n}}</button>
        <button type="button" class="btn btn-lg btn-success" ng-click="saveDialog()" x-ng-disabled="!configForm.$valid">{{"widget.edit.save" | i18n}}</button>
    </div>
</div>

angular.module('meternet.chart.directives.mbsat2-marker-popup', [])
  .directive('mbsat2MarkerPopup', function() {
    return {
      templateUrl: 'dashboard/mbsat2/marker-popup.html',
      controller: 'MBsat2MarkerPopUpController'
    };
  })
  .controller('MBsat2MarkerPopUpController', ['$scope', function($scope) {
    $scope.deviceList = [];
    $scope.checkBoxClick = function () {
      $scope.$broadcast('checkMarker', $scope.ui.clickedMark);
    };
    function prepareParams() {
        var paramsToShow = []
        _.forEach($scope.ui.clickedMark.markers, function (marker){
            _.forEach(marker.statusParams, function(param){
                if (param.tags.includes("#dr_mapvalue")) {
                    paramsToShow.push({
                        deviceId: marker.deviceId,
                        name: marker.label || marker.name,
                        paramName: param.label || param.name,
                        paramId: param.id
                    })
                }
            })
        })
        $scope.deviceList = _.uniq(paramsToShow.map(function(param){
            return {
                deviceId: param.deviceId,
                name: param.name,
                params:[]
            }
        }),"deviceId");
        _.forEach(paramsToShow, function(param){
            var device = _.find($scope.deviceList ,{deviceId: param.deviceId});
            device.params.push({
                paramName: param.paramName,
                paramId: param.paramId
            });
        })
    }
    prepareParams();
  }]);

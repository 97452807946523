<form class="form form-inline form-control-static">
	<span class="alert alert-info" style="padding: 5px;" x-ng-if="ui.loading">{{'ui.loading.data' | i18n}}</span>

	<div class="form-group">
		<button class="btn btn-primary"
				style="width: 42px;"
				x-ng-disabled="ui.loading"
				x-ng-click="ui.step(-config.columns)">
			<i class="fa fa-lg fa-caret-left"></i><i class="fa fa-lg fa-caret-left"></i>
		</button>
	</div>
	<div class="form-group">
		<button class="btn btn-primary"
				style="width: 32px;"
				x-ng-disabled="ui.loading"
				x-ng-click="ui.step(-1)">
			<i class="fa fa-lg fa-caret-left"></i>
		</button>
	</div>

	<div class="form-group">
		<div class='input-group date'>
			<input type='text'
				   class="form-control"
				   x-ng-model="ui.date"
				   x-ng-model-options="{ debounce: 1000 }"
				   x-ng-disabled="ui.loading"
				   x-datetimepicker=""
				   style="width: 110px;"/>
			<span class="input-group-addon">
				<i class="fa fa-calendar"></i>
			</span>
		</div>
	</div>

	<div class="form-group">
		<input type="number"
			   class="form-control"
			   x-ng-model="config.columns"
			   x-ng-model-options="{ debounce: 1000 }"
			   x-ng-disabled="ui.loading"
			   style="width: 50px;"/>
	</div>

	<div class="form-group">
		<button class="btn btn-primary"
				style="width: 32px;"
				x-ng-disabled="ui.loading"
				x-ng-click="ui.step(1)">
			<i class="fa fa-lg fa-caret-right"></i>
		</button>
	</div>
	<div class="form-group">
		<button class="btn btn-primary"
				style="width: 42px;"
				x-ng-disabled="ui.loading"
				x-ng-click="ui.step(config.columns)">
			<i class="fa fa-lg fa-caret-right"></i><i class="fa fa-lg fa-caret-right"></i>
		</button>
	</div>
</form>

var module = angular.module('meternet.about.directives', [ 'meternet.inline-js', 'meternet.services' ]);

module.directive('aboutDisplay', function(contextPath, $state, app, dataServiceCache, $timeout, license) {
    return {
        templateUrl : 'about/about-display.html',
        scope : {
            accordionGroup : '='
        },
        controller : function($scope) {
        },
        link : function(scope, elem, attrs) {
            var t = app.name + " v" + app.revision;
            if (app.timestamp) {
                t += moment(new Date(app.timestamp)).format(" (YYYY-MM-DD HH:mm:ss)");
            }
            scope.version = t;

                scope.license = license;
                if(_.indexOf(license.enabledModules,"ETI_BASIC")>=0){
                    scope.mainLicense = "ETI";
                }else if(_.indexOf(license.enabledModules,"FINN_BASIC")>=0){
                     $scope.mainLicense = "FINN";
                }else if(_.indexOf(license.enabledModules,"EFEN_BASIC")>=0){
                     scope.mainLicense = "EFEN";
                }else if(_.indexOf(license.enabledModules,"BSH_BASIC")>=0){
                    scope.mainLicense = "BSH";
                }else if(_.indexOf(license.enabledModules,"FINN_BASIC")>=0){
                    scope.mainLicense = "FINN";
                }else{
                    scope.mainLicense = "FIF";
                }
        }
    };
});

<div style="overflow-y: auto;">
    <div energy-report-chart-widget="" x-ng-if="ui.chartData.length>0" data="ui.chartData" unit="ui.chartUnit" style="height: 100%; width:100%;"></div>
    <div x-ng-if="ui.chartData.length===0">
        <div class="row" style="height: 100%;">
            <div class="col-sm-6 col-sm-offset-3" style="padding-top: 20px;">
                <button class="btn btn-info btn-lg" x-ng-disable=" !$parent.$parent.editMode" x-ng-class="{'disabled' : !$parent.$parent.$parent.editMode}"
                        style="width : 100%;" x-ng-click="$parent.$parent.$parent.editMode ? $parent.$parent.$parent.edit() : null"
                        title="{{$parent.$parent.editMode ? ('widget.configure' | i18n) : ('widget.enable.editMode' | i18n)}}">
                    {{'widget.configure'| i18n}}<i class="fa fa-cog"></i></button>
            </div>
        </div>
    </div>
</div>
 
<form class="form-horizontal clearfix" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-help="config.eventInput.name.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label"
                           x-field-help="config.eventInput.label.help" x-field-errors=""
                           x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.eventInput.desc.help"
                           x-field-errors=""></input>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.bufferedOutputsWarningLevel"
                           x-field-help="config.eventInput.name.help" x-field-errors=""/>
                </div>
            </div>
        </div>

        <div x-ng-switch="item.config.type">
            <div x-ng-switch-when="db">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.database' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select class="form-control" x-ng-model="item.config.databaseId" x-field-errors=""
                                    x-field-help="config.eventInput.database.help"
                                    x-ng-options="d.id as d.name for d in config.engine.databases"></select>
                        </div>
                    </div>
                </div>
            </div>

            <div x-ng-switch-when="remote">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.host' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.url"
                                   x-field-help="config.eventInput.host.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.username"
                                   x-field-help="config.eventInput.username.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input type="password" class="form-control" x-ng-model="item.config.password"
                                   x-field-help="config.eventInput.pass.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.input' | i18n}}</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input class="form-control" x-ng-model="item.config.inputName"
                                   x-field-help="config.eventInput.inputname.help" x-field-errors=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.eventOutput.type.' + ui.type.type | i18n}}</span>
                </span>
            </div>
        </div>
    </div>
</form>

<form class="form-inline clearfix">
    <div class="panel panel-default">
        <div class="panel-heading">
            <div class="panel-title"><i class="fa fa-fw fa-bell-o"></i>&nbsp;{{'config.eventOutput.sources' | i18n}}
            </div>
        </div>
        <table class="table table-hover">
            <thead>
            <tr>
                <th>{{'config.eventOutput.source.name' | i18n}}</th>
                <th>{{'config.eventOutput.rule' | i18n}}</th>
            </tr>
            </thead>
            <tbody>
            <tr x-ng-repeat="link in links" x-ng-click="ui.selectLink(link)" style="cursor: pointer;">
                <td style="width: 30%;">
                    <span>{{link.source.name}}</span>
                </td>
                <td>
                    <div>
                        <i class="fa fa-fw fa-check-circle"></i>
                        <span>{{link.rule.expr}}</span>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</form>
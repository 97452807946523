<!--<div class="row" x-errors="item.errors" x-errors-root="item.config">-->
<!--    <div class="col-sm-4">-->
<!--        <div class="form-group">-->
<!--            <label class="form-group-label">{{ 'config.name' | i18n }}</label>-->
<!--            <input class="form-control" x-ng-model="item.config.name" x-field-errors="" z/>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--            <label class="form-group-label">{{ 'config.desc' | i18n }}</label>-->
<!--            <input class="form-control" x-ng-model="item.config.desc" x-field-errors=""/>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--            <label class="form-group-label">{{ 'config.type' | i18n }}</label>-->
<!--            <select class="form-control"-->
<!--                    x-ng-model="item.config.eventType"-->
<!--                    x-ng-options="t as ('config.event.type.' + t | i18n) for t in sourceConfig.eventTypes"-->
<!--                    x-field-errors=""></select>-->
<!--        </div>-->
<!--    </div>-->

<!--    <div class="col-sm-8">-->
<!--        <div class="form-group">-->
<!--            <label class="form-group-label">{{ 'config.condition' | i18n }}</label>-->
<!--            <input class="form-control" x-ng-model="item.config.expr"/>-->
<!--        </div>-->
<!--        <div class="form-group">-->
<!--            <label class="form-group-label">{{ 'config.actions' | i18n }}</label>-->
<!--            <div class="config-rule-actions" x-ng-repeat="action in item.config.actions track by $index">-->
<!--                <div class="form-group" style="width: 150px;">-->
<!--                    <select class="form-control"-->
<!--                            x-ng-model="action.type"-->
<!--                            x-ng-options="t.type as ('config.eventAction.type.' + t.type | i18n) for t in ui.actionTypes"-->
<!--                            x-field-errors="'item.config.actions[' + $index + '].type'"></select>-->
<!--                </div>-->
<!--                <div class="form-group" style="width: 250px;" x-ng-if="action.type === 'output'">-->
<!--                    <select class="form-control"-->
<!--                            x-ng-model="action.outputId"-->
<!--                            x-ng-options="o.id as o.name for o in engineConfig.eventOutputs"-->
<!--                            x-field-errors="'item.config.actions[' + $index + '].outputId'"></select>-->
<!--                </div>-->
<!--                <div style="width: 250px;" x-ng-if="action.type === 'notifier'">-->
<!--                    <div class="form-group">-->
<!--                        <select class="form-control"-->
<!--                                x-ng-model="action.notifierId"-->
<!--                                x-ng-options="n.id as n.name for n in engineConfig.notifiers"-->
<!--                                x-field-errors="'item.config.actions[' + $index + '].notifierId'"></select>-->
<!--                    </div>-->

<!--                    <div class="form-group">-->
<!--                        <label class="form-group-label">{{'config.momentRule.notifier.to' | i18n}}</label>-->
<!--                        <input class="form-control"-->
<!--                               x-ng-model="action.to"-->
<!--                               x-field-errors="'item.config.actions[' + $index + '].to'"/>-->
<!--                    </div>-->

<!--                    <div class="form-group">-->
<!--                        <label class="form-group-label">{{'config.momentRule.notifier.subject' | i18n}}</label>-->
<!--                        <input class="form-control"-->
<!--                               x-ng-model="action.subject"-->
<!--                               x-field-errors="'item.config.actions[' + $index + '].subject'"/>-->
<!--                    </div>-->

<!--                    <div class="form-group">-->
<!--                        <label class="form-group-label">{{'config.momentRule.notifier.message' | i18n}}</label>-->
<!--                        <textarea class="form-control"-->
<!--                                  x-ng-model="action.message"-->
<!--                                  x-field-errors="'item.config.actions[' + $index + '].message'"></textarea>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div style="width: 250px;" x-ng-if="action.type === 'parameter-write'">-->
<!--                    <div class="form-group">-->
<!--                        <select class="form-control"-->
<!--                                x-ng-model="action.paramId"-->
<!--                                x-ng-options="p.id as p.name for p in ui.getWritableParams()"-->
<!--                                x-field-errors="'item.config.actions[' + $index + '].paramId'"></select>-->
<!--                    </div>-->

<!--                    <div class="form-group">-->
<!--                        <label class="form-group-label">{{'config.momentRule.parameter.paramValue' | i18n}}</label>-->
<!--                        <input class="form-control"-->
<!--                               x-ng-model="action.paramValue"-->
<!--                               x-field-errors="'item.config.actions[' + $index + '].paramValue'"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <button class="btn btn-danger btn-sm" x-ng-click="ui.removeAction(action)"><i class="fa fa-trash"></i>-->
<!--                </button>-->
<!--            </div>-->
<!--            <div class="pull-right">-->
<!--                <button class="btn btn-success" x-ng-click="ui.addAction()"><i class="fa fa-plus"></i>&nbsp;{{'config.add'-->
<!--                    | i18n}}-->
<!--                </button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="row" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-4">
        <div class="form-group">
            <label class="form-group-label">{{ 'config.name' | i18n }}</label>
            <div class="input-group">
                <input class="form-control" x-ng-model="item.config.name" x-field-errors=""
                       x-field-help="config.rule.name.help"/>
            </div>
        </div>
        <div class="form-group">
            <label class="form-group-label">{{ 'config.desc' | i18n }}</label>
            <div class="input-group">
                <input class="form-control" x-ng-model="item.config.desc" x-field-errors=""
                       x-field-help="config.rule.desc.help"/>
            </div>
        </div>
        <div class="form-group">
            <label class="form-group-label">{{ 'config.type' | i18n }}</label>
            <div class="input-group">
                <select class="form-control"
                        x-ng-model="item.config.eventType"
                        x-ng-options="t as ('config.event.type.' + t | i18n) for t in sourceConfig.eventTypes"
                        x-field-help="config.rule.eventType.help"
                        x-field-errors=""></select>
            </div>
        </div>
    </div>

    <div class="col-sm-8">
        <div class="form-group">
            <label class="form-group-label">{{ 'config.condition' | i18n }}</label>
            <div class="input-group">
                <input class="form-control" x-ng-model="item.config.expr" x-field-help="config.condition.rule.help"/>
            </div>
        </div>
        <div class="form-group">
            <label class="form-group-label">{{ 'config.actions' | i18n }}</label>
            <div class="config-rule-actions" x-ng-repeat="action in item.config.actions track by $index">
                <div class="form-group" style="width: 150px;">
                    <div class="input-group">
                        <select class="form-control"
                                x-ng-model="action.type"
                                x-ng-options="t.type as ('config.eventAction.type.' + t.type | i18n) for t in ui.actionTypes"
                                x-field-help="config.rule.actions.help"
                                x-field-errors="'item.config.actions[' + $index + '].type'"></select>
                    </div>
                </div>
                <div class="form-group" style="width: 250px;" x-ng-if="action.type === 'output'">
                    <div class="input-group">
                        <select class="form-control"
                                x-ng-model="action.outputId"
                                x-ng-options="o.id as o.name for o in engineConfig.eventOutputs"
                                x-field-help="config.rule.eventOutputs.help"
                                x-field-errors="'item.config.actions[' + $index + '].outputId'"></select>
                    </div>
                </div>
                <div style="width: 250px;" x-ng-if="action.type === 'notifier'">
                    <div class="form-group">
                        <div class="input-group">
                            <select class="form-control"
                                    x-ng-model="action.notifierId"
                                    x-ng-options="n.id as n.name for n in engineConfig.notifiers"
                                    x-field-help="config.rule.notifiers.help"
                                    x-field-errors="'item.config.actions[' + $index + '].notifierId'"></select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">{{'config.momentRule.notifier.to' | i18n}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   x-ng-model="action.to"
                                   x-field-help="config.rule.notifier.to.help"
                                   x-field-errors="'item.config.actions[' + $index + '].to'"/>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">{{'config.momentRule.notifier.subject' | i18n}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   x-ng-model="action.subject"
                                   x-field-help="config.rule.notifier.subject.help"
                                   x-field-errors="'item.config.actions[' + $index + '].subject'"/>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">{{'config.momentRule.notifier.message' | i18n}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   x-ng-model="action.message"
                                   x-field-help="config.rule.notifier.msg.help"
                                   x-field-errors="'item.config.actions[' + $index + '].message'"></input>
                        </div>
                    </div>
                </div>
                <div style="width: 250px;" x-ng-if="action.type === 'parameter-write'">
                    <div class="form-group">
                        <div class="input-group">
                            <select class="form-control"
                                    x-ng-model="action.paramId"
                                    x-ng-options="p.id as p.name for p in ui.getWritableParams()"
                                    x-field-help="config.rule.writableParams.help"
                                    x-field-errors="'item.config.actions[' + $index + '].paramId'"></select>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label">{{'config.momentRule.parameter.paramValue' | i18n}}</label>
                        <div class="input-group">
                            <input class="form-control"
                                   x-ng-model="action.paramValue"
                                   x-field-help="config.rule.writableParam.value.help"
                                   x-field-errors="'item.config.actions[' + $index + '].paramValue'"/>
                        </div>
                    </div>
                </div>
                <button class="btn btn-danger btn-sm" x-ng-click="ui.removeAction(action)"><i class="fa fa-trash"></i>
                </button>
            </div>
            <div class="pull-right">
                <button class="btn btn-success" x-ng-click="ui.addAction()"><i class="fa fa-plus"></i>&nbsp;{{'config.add'
                    | i18n}}
                </button>
            </div>
        </div>
    </div>
</div>

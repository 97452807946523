(function() {
	"use strict";

	var module = angular.module("meternet.report.energy.EnergyReportSelectorDirective", [
	]);

    module.directive("energyReportSelector", [ "$uibModal", "$filter", "contextPath", "configService",
        function ($uibModal, $filter, contextPath, configService) {
        return {
            scope : true,
            templateUrl: 'report/energy/energy-report-selector.html',
            link : function(scope, elem, attr, ctrl) {
                function updateConfig(config, index) {
                    if (index == null || index < 0) {
                        index = 0;
                    }
                    scope.config = angular.copy(config);
                    if (!scope.config.energyReports) {
                        scope.config.energyReports = [];
                    }
                    if (scope.config.energyReports.length > index) {
                        scope.report = scope.config.energyReports[index];
                    } else if (scope.config.energyReports.length > 0) {
                        scope.report = scope.config.energyReports[0];
                    } else {
                        scope.report = null;
                    }
                }

                configService.get().then(function (config) {
                    updateConfig(config, 0);
                });

                scope.ui = {
                    edited: false,
                    canAdd: function () {
                        return !this.edited;
                    },
                    canRemove: function () {
                        return !this.edited && scope.report != null;
                    },
                    canEdit: function () {
                        return !this.edited && scope.report != null;
                    },
                    add: function () {
                        if (scope.config) {
                            var report = {
                                id: scope.config.getNextId(),
                                name: $filter('i18n')('energyReport.newReportName'),
                                cronExpression: "0 0 0 1 * ?",
                                columns: 4,
                                series: []
                            };

                            scope.config.energyReports.push(report);
                            scope.report = report;
                            this.edit(report);
                        }
                    },
                    remove: function () {
                        if (scope.config && scope.report) {
                            scope.config.energyReports.splice(scope.config.energyReports.indexOf(scope.report), 1);
                            configService.update(scope.config).then(function (config) {
                                updateConfig(config, 0);
                            });
                        }
                    },
                    edit: function (report) {
                        var modal = $uibModal.open({
                            templateUrl: 'report/energy/energy-report-config-modal.html',
                            controller: 'energyReportConfigCtrl',
                            resolve: {
                                config: function () {
                                    return scope.report;
                                }
                            }
                        });
                        modal.result.then(function () {
                            var i = scope.config.energyReports.indexOf(scope.report);
                            configService.update(scope.config).then(function (config) {
                                updateConfig(config, i);
                            });
                        }, function () {
                            var i = scope.config.energyReports.indexOf(scope.report);
                            configService.get().then(function (config) {
                                updateConfig(config, i);
                            });
                        });
                    }
                };
            }
        };
    }]);

})();
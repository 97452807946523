/**
 * Created by Karol on 2015-12-01.
 */
var module = angular.module('meternet.dashboard.controllers.statusWidget', [ 'adf.provider', 'i18n',
    'meternet.dashboard.constants' ]);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl : 'dashboard/status-widget.html',
        title : messages['dashboard.widgets.statusWidget.title'],
        description : messages['dashboard.widgets.statusWidget.title'],
        controller : 'StatusWidgetCtrl',
        resolve : {
        }
    };

    dashboardProvider.widget('status-widget', widget);

});

function StatusWidgetEditCtrl($scope,configService) {

}

function StatusWidgetCtrl($scope, statusService, configService, licenseService){
    configService.get().then(function(config) {
        $scope.config = config;
    });

    $scope.license = licenseService.getLicense();

    $scope.status = {
        clear : true
    };

    $scope.$on('statusUpdate', function($event, data) {

        tools.copy(data, $scope.status);
        $scope.status.clear = false;
    });

    $scope.ui = {
        start : function() {
            statusService.start().then(function(status) {
                tools.copy(status, $scope.status);
                $scope.status.clear = false;
            });
        },
        stop : function() {
            statusService.stop().then(function(status) {
                tools.copy(status, $scope.status);
                $scope.status.clear = false;
            });
        },
        restart : function() {
            var self = this;
            statusService.stop().then(function(status) {
                tools.copy(status, $scope.status);
                $scope.status.clear = false;
                self.start();
            });
        }
    };
}

module.controller('StatusWidgetEditCtrl', StatusWidgetEditCtrl);
module.controller('StatusWidgetCtrl', StatusWidgetCtrl);

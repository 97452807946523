var module = angular.module('meternet.dashboard.controllers.lineChartWidget', [ 'adf.provider', 'i18n',
        'meternet.dashboard.constants' ]);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl : 'dashboard/line-chart-widget.html',
        title : messages['dashboard.widgets.lineChart.title'],
        description : messages['dashboard.widgets.lineChart.desc'],
        controller : 'lineChartWidgetCtrl',
        resolve : {
        // /* @ngInject */
        // configured : function($q, config) {
        // var deferred = $q.defer();
        // if (config.series.length > 0) {
        // deferred.resolve(true);
        // }
        //
        // return deferred.promise;
        // }
        },
        csv : true,
        xlsx: true,
        screenshot: true,
        config : {
            csv : true,
            series : [],
            type : 'line-chart',
            historyTime : 3,
            enableScroll : true,
            timeUnit : 60,
            mainGridColor : "#c0c0c0",
            subGridColor : "#d3d3d3",
            mode : 'current'
        },
        edit : {
            controller : 'LineChartWidgetEditCtrl',
            templateUrl : 'dashboard/line-chart-widget-edit.html',
            resolve : {
                /* @ngInject */
                maxSeries : function(licenseService) {
                    var license = licenseService.getLicense();
                    return license.properties.MAX_SERIES_REPORT || 1;
                }

            }
        }
    };

    dashboardProvider.widget('line-chart', widget);

});

function LineChartWidgetEditCtrl($scope, $filter, $timeout, contextPath, dataService, seriesService, configService,
        config, maxSeries, UnitScales, unitFilter, TimeDerivativeScales, TimeUnits, ColorPickerOpts, linechartModes) {
    var i18n = $filter('i18n');
    var colors = ColorPickerOpts.palette[0];
    var modalDialog = $('.modal')[0];
    var indices;

    $scope.linechartModes = linechartModes;

    $scope.ui = {
        nully : null,
        getColor : function() {
            var order = [0,2,4,6,8,10,1,3,5,7,9];
            for(var c in order){
                var color = colors[order[c]];
                if (!_.findWhere($scope.config.series, {
                    lineColor : color
                })) {
                    return color;
                }
            }
            return null;
        },
        lineWidths : [ 0, 1, 2, 3, 4, 5 ],
        markerSizes : [ 0, 3, 5, 7, 9 ],
        scales : UnitScales,
        derivatives : TimeDerivativeScales,
        precisions : [ 0, 1, 2, 3, 4, 5, 6 ],
        grids : [ {
            value : 0,
            label : i18n("dashboard.widgets.config.grid.none")
        }, {
            value : 1,
            label : i18n("dashboard.widgets.config.grid.main")
        }, {
            value : 2,
            label : i18n("dashboard.widgets.config.grid.more")
        } ],
        axisRanges : [ {
            value : true,
            label : i18n("dashboard.widgets.config.series.axis.range.options.auto")
        }, {
            value : false,
            label : i18n("dashboard.widgets.config.series.axis.range.options.manual")
        } ],
        markOnOff : [ {
            value : true,
            label : i18n("universal.on")
        }, {
            value : false,
            label : i18n("universal.off")
        } ],
        markColor : ['#343489'],
        markStyle : [
            null,
            "1 1",
            "3 3",
            "3 10",
            "10 3",
            "10 10"
        ],
        getAxisIndices : function (series, i) {
            if(series){
                if (!indices)
                {   indices = []
                    for (var p = 0; p < $scope.config.series.length; ++p) {
                         var s = $scope.config.series[p];
                         indices.push({
                             value : p,
                             label : s.name || '?',
                             disabled: []//!(s !== series && s.axisIndex === null)
                         });
                    }
                    indices.push({
                        value: null,
                        label: $filter('i18n')("dashboard.widgets.config.series.axis.index.own"),
                        disabled: []
                    })
                }else{
                    for (var p = 0; p < $scope.config.series.length; ++p) {
                        var s = $scope.config.series[p];
                        indices[p].value = p,
                        indices[p].label = s.name || '?',
                        indices[p].disabled[i]= (s === series || (!isNaN(parseFloat(s.axisIndex)) && isFinite(s.axisIndex)))
                    }
                }
            }
            return indices;
        },
        canAddSeries : function() {
            return $scope.config.series.length < maxSeries;
        },
        addSeries : function() {
            indices = null;
            if (this.canAddSeries()) {
                var color = this.getColor();
                var series = {
                    paramId : null,
                    derivative : 0,
                    lineColor : color,
                    lineWidth : 1,
                    markerColor : color,
                    markerSize : 0,
                    average : 1,
                    axisIndex : null,
                    markName : [],
                    mark : [],
                    markColor : [],
                    markStyle : [],
                    axis : {
                        scale : 0,
                        precision : 0,
                        rangeAuto : true,
                        labelColor : color,
                        axisColor : color,
                        grid : 1,
                        mainGridColor : "#c0c0c0",
                        subGridColor : "#d3d3d3"
                    }
                };
                $scope.config.series.push(series);
                $timeout(function() {
                    $scope.ui.updateSeriesIcon(series);
                });
                this.updateAxisIndices();
            }
        },
        isSingleSerie : function() {
            if ($scope.config.series.length > 1) {
                return "enabled";
            }
            return "disabled";
        },
        removeSeries : function(series) {
            indices = null;
            if (series.length < 2) {
                return;
            }
            var index = $scope.config.series.indexOf(series);
            _.each($scope.config.series, function(s, i) {
                if (s.axisIndex === index) {
                    s.axisIndex = null;
                } else if (s.axisIndex > index) {
                    s.axisIndex -= 1;
                }
            });
            if (isFinite($scope.config.gridSerieIndex) && (index <= $scope.config.gridSerieIndex)) {
                $scope.config.gridSerieIndex = Math.max($scope.config.gridSerieIndex - 1, 0);
            }
            $scope.config.series.splice(index, 1);
            this.updateAxisIndices();
        },
        addMark : function(series){
            if(!series.markName){
                series.markName = [];
            }
            series.markName.push("Line " + series.markName.length);
            if(!series.mark){
                series.mark = [];
            }
            series.mark.push(0);
            if(!series.markColor){
                series.markColor = [];
            }
            if(!series.markStyle){
                series.markStyle = [];
            }
            series.markStyle.push("3 3");
        },
        removeMark : function(series, i){
            series.markName.splice(i, 1);
            series.mark.splice(i, 1);
            series.markColor.splice(i, 1);
            series.markStyle.splice(i, 1);
        },
        updateSeriesParam : function(series) {
            if (series.deviceId !== series._device.id) {
                series._param = series._device.params[0];
            }
            if (series._device && series._param) {
                series.deviceId = series._device.id;
                series.paramId = series._param.id;
                series.axis.scale = series._param.scale || 0;
                series.axis.precision = series._param.precision || 0;

                var deviceName = (series._device.label||series._device.name);
                var paramName = (series._param.label||series._param.name);
                series.name = deviceName + '.' + paramName;

                if (!series.derivative) {
                    series.unit = series._param.unit?series._param.unit:$filter('quantityUnit')(series._param.quantity);
                } else {
                    series.unit = seriesService.getTimeDerivativeUnit(unitFilter(1, 0, series._param.unit, 0).trim().substring(1), series.derivative);
                    series.unit = series.unit.trim();
                }
            } else {
                series.deviceId = null;
                series.paramId = null;
                series.name = null;
                series.unit = null;
            }
            if (series.axisIndex != null) {
                if ($scope.config.series[series.axisIndex].unit !== series.unit) {
                    series.axisIndex = null;
                }
            }
            this.updateAxisIndices();
        },
        updateAxisIndices : function() {
            _.each($scope.config.series, function(s, i) {
                if (!s.axis) {
                    s.axis = {
                        scale : 0,
                        precision : 0,
                        rangeAuto : true,
                        labelColor : s.lineColor,
                        axisColor : s.lineColor,
                        grid : 1,
                        mainGridColor : "#c0c0c0",
                        subGridColor : "#d3d3d3"
                    };
                }
            });
            _.forEach($scope.config.series, function(serie, i){
                serie.used=false;
            });
            _.forEach($scope.config.series, function(serie, i){
                if(serie.axisIndex!=null){
                    $scope.config.series[serie.axisIndex].used=true;
                }
            });
        },
        setGridSerie : function() {
            if (!isFinite($scope.config.gridSerieIndex)) {
                delete $scope.gridSerie;
            } else {
                $scope.gridSerie = $scope.config.series[$scope.config.gridSerieIndex];
            }

        },
        updateSeriesIcon : function(series) {
            var index = $scope.config.series.indexOf(series);
            var svg = d3.select($("svg.series-icon")[index]);
            svg.select("path").style({
                "stroke" : series.lineColor,
                "stroke-width" : series.lineWidth
            });
            svg.select("circle").attr({
                "r" : series.markerSize
            }).style({
                "fill" : series.markerColor
            });
        },
        boolToStr : function(arg) {
            return arg ? $filter('i18n')("universal.on") : $filter('i18n')("universal.off");
        },
        setTimeUnit : function(tu) {
            $scope.timeUnit = tu;
            $scope.config.timeUnit = tu.value / 60000;
        },
        colorPickerOpts : ColorPickerOpts,
//        colorPickerOpts : angular.extend({
//            appendTo : modalDialog
//        }, ColorPickerOpts)
    };

    $scope.ui.setGridSerie();

    $scope.ui.axisIndices = [];

    for (var p = 0; p < $scope.config.series.length; ++p) {
        var s = $scope.config.series[p];
        $scope.ui.axisIndices.push({
            value : p,
            label : s.name
        });
    }

    $scope.timeUnits = _.filter(TimeUnits, function(tu) {
        return tu.value > 1000;
    });

    $scope.timeUnit = _.find(TimeUnits, function(tu) {
        return tu.value === $scope.config.timeUnit * 60000;
    }) || $scope.timeUnits[0];

    if (!$scope.config.series) {
        $scope.config.series = [];
    }
    if (isFinite($scope.config.gridSerieIndex)) {
        $scope.gridSerie = $scope.config.series[$scope.config.gridSerieIndex];
    }

    if ($scope.config.timeFrom) {
        $scope.config.timeFrom = new Date($scope.config.timeFrom);
    }
    if ($scope.config.timeTo) {
        $scope.config.timeTo = new Date($scope.config.timeTo);
    }

    configService.get().then(function(meternetConfig) {
        var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
        $scope.devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
//        $scope.devices = _.without(devices, _.findWhere(devices, {model : "energy_report"}));

        $scope.devices = _.sortBy($scope.devices, function(d){
            return d.label||d.name;
        });

        var paramCond = function (p) {
            return p.id === series.paramId;
        };

        for ( var i = 0; i < $scope.config.series.length; ++i) {
            var series = $scope.config.series[i];
            for ( var d in $scope.devices) {
                var device = $scope.devices[d];
                var param = _.find(device.params, paramCond);
                if (param) {
                    series._device = device;
                    series._param = param;
                    series.deviceId = device.id;
                    series.index = i;
//                    series.unit=$filter('quantityUnit')(param.quantity);
//                    series.unit = seriesService.getTimeDerivativeUnit(unitFilter(1, series.axis.precision, series._param.unit, series.axis.scale).trim(), series.derivative);
                    break;
                }
            }
        }
    });

    $timeout(function() {
        _.each($scope.config.series, function(series) {
            $scope.ui.updateSeriesIcon(series);
        });
    }, 0);
}

function LineChartWidgetCtrl($scope, $filter, contextPath, dataService, configService, config, seriesService,
        DashboardEvents, $q, linechartModes, $timeout, licenseService) {
    var license = licenseService.getLicense();
    config.maxHistoryTime = license.properties['MAX_HISTORY_TIME'];
    $scope.data = [];
    $scope.dataRaw = [];

    var historyLength = (config.timeUnit || 60) * (config.historyTime || 24) * 60 * 1000;

    var currentTimeTo = function() {
        var timeTo = new Date();
        timeTo.setMilliseconds(0);
        timeTo.setSeconds(0);
        return timeTo;
    };

    var currentTimeFrom = function() {
        return new Date(currentTimeTo().getTime() - historyLength);
    };

    if (config.timeFrom) {
        config.timeFrom = new Date(config.timeFrom);
    }
    if (config.timeTo) {
        config.timeTo = new Date(config.timeTo);
    }

    var setScopeTimeSpan = function(timeFrom, timeTo) {
        var tt, tf;
        if (timeFrom && timeTo) {
            if ($scope.timeFrom <= timeFrom && $scope.timeTo >= timeTo) {
                return false;
            }
            var diff = timeTo.getTime() - timeFrom.getTime();
            tf = new Date(timeFrom.getTime() - 0.25 * diff);
            tt = new Date(timeTo.getTime() + 0.25 * diff);
            var now = new Date();
            $scope.timeFrom = (!$scope.timeFrom) || $scope.timeFrom > tf ? tf : $scope.timeFrom;
            if ($scope.timeFrom > now) {
                $scope.timeFrom = now;
            }
            $scope.timeTo = (!$scope.timeTo) || ($scope.timeTo < tt) ? tt : $scope.timeTo;
            if ($scope.timeTo > now) {
                $scope.timeTo = now;
            }
            return true;
        }
        return false;
    };
    if (config.mode === 'historical') {
        setScopeTimeSpan(config.timeFrom, config.timeTo);
        $scope.subscribe = false;
        config.enableScroll = false;
    } else {
        $scope.timeTo = currentTimeTo();
        $scope.timeFrom = currentTimeFrom();
        $scope.subscribe = true;
        config.enableScroll = true;
        config.timeTo = $scope.timeTo;
        config.timeFrom = $scope.timeFrom;
    }

    var broadcastRedraw = _.throttle(function() {
        $scope.$broadcast(DashboardEvents.REDRAW, $scope.data);
    }, 1200);

    var updateDataFactory = function(index) {

        var updateData = function(data, concat) {
            if (index < config.series.length) {

                if (typeof (data) !== 'undefined') {
                    if (concat && data.length > 0) {
                        var i;
                        for (i = $scope.dataRaw[index].length - 1; i >= 0; i--) {
                            if ($scope.dataRaw[index][i].timestamp < data[0].timestamp) {
                                break;
                            }
                        }
                        Array.prototype.splice.apply($scope.dataRaw[index], [i+1, 0].concat(data));
                    } else {
                        $scope.dataRaw[index] = data;
                    }
                }
                if (typeof ($scope.dataRaw[index]) !== 'undefined') {
                    $scope.data[index] = seriesService
                            .processData($scope.dataRaw[index], $scope.timeFrom, $scope.timeTo || currentTimeTo(),
                                config.series[index].derivative, config.series[index].average);
                }
                broadcastRedraw();
            }
        };
        return _.throttle(updateData, 500);
    };
    $scope.dataRaw = [];
    $scope.data = [];

    $scope.unsubscribeFns = {};
    $scope.$on('$destroy', function() {
        _.each($scope.unsubscribeFns, function(fn) {
           fn();
        });
    });

    $scope.promises = [];
    var initData = function() {
        $scope.$broadcast(DashboardEvents.LOADING_DATA, true);
        _.each($scope.promises, function(p) {
            p.cancel();
        });
        $scope.promises = [];

        _.each(config.series, function(series, index) {
            var request = {};
            $scope.dataRaw[index] = [];
            // $scope.data[index] = [];
            request.deviceId = series.deviceId;
            request.paramId = series.paramId;
            request.timeTo = $scope.timeTo || currentTimeTo();
            request.timeFrom = $scope.timeFrom || currentTimeFrom();

            var promise = dataService.requestParamData(series.paramId, request);
            promise.deviceId = series.deviceId;
            promise.paramId = series.paramId;
            var updateDataFn = updateDataFactory(index);
            promise.then(function(measurements) {
                updateDataFn(measurements, false);
            }, angular.noop, function(measurements) {
                updateDataFn(measurements, false);
            });
            $scope.promises.push(promise);
        });
        $scope.$on('$destroy', function() {
            _.each($scope.promises, function(p) {
                p.cancel();
            });
        });
        $q.all($scope.promises).then(function(measurements) {
            if (!$scope.subscribe) {
                _.each($scope.unsubscribeFns, function(fn) {
                    fn();
                });
                $scope.unsubscribeFns = {};

            } else {
                _.forEach($scope.promises, function(p, i) {
                    if ($scope.subscribe && typeof ($scope.unsubscribeFns[p.paramId]) === 'undefined') {
                        var unsubscribeFn = dataService.subscribeForParametersMeasurements(p.paramId, function(moment) {
                           var data = [];
                           data.push(moment.current);
                           updateDataFactory(i)(data, true);
                        });
                        $scope.unsubscribeFns[p.paramId] = unsubscribeFn;
                    }

                });
            }
            $timeout(function() {
                $scope.$broadcast(DashboardEvents.LOADING_DATA, false);
            }, 0);
        });
    };
    initData();
    $scope.$on(DashboardEvents.SET_DATA_TIMESPAN, function($event, timeFrom, timeTo) {
        if (config.mode !== 'current') {
            $scope.subscribe = false;
        }
        if (setScopeTimeSpan(timeFrom, timeTo)) {
            initData();
        }
    });
}
module.controller('lineChartWidgetCtrl', LineChartWidgetCtrl);
module.controller('LineChartWidgetEditCtrl', LineChartWidgetEditCtrl);
//module.directive('historyTimeValidate', function(licenseService) {
//    return {
//        restrict : 'A',
//        require : '^form',
//        link : function(scope, elem, attr, formCtrl) {
//            var license = licenseService.getLicense();
//            var maxHistoryTime = license.properties['MAX_HISTORY_TIME'];
//            if (isNaN(maxHistoryTime)) {
//                return;
//            }
//            var validateProvider = function(counter) {
//                return function($modelValue, $viewValue) {
//                    var historyTime = formCtrl[attr.historyTimeInput].$viewValue;
//                    var historyTimeUnit = formCtrl[attr.timeUnitInput].$viewValue;
//
//                    if ($viewValue !== historyTime) {
//                        formCtrl[attr.historyTimeInput].$validate();
//                        if (counter !== 0) {
//                            scope.$emit('check-validity');
//                        }
//                        counter++;
//                    }
//                    if (!isFinite(parseInt(historyTime))) {
//                        return false;
//                    }
//                    if ((historyTime * historyTimeUnit) > maxHistoryTime) {
//                        return false;
//                    }
//                    return true;
//                };
//            };
//            formCtrl[attr.historyTimeInput].$validators.historyTimeValidate = validateProvider(0);
//            formCtrl[attr.timeUnitInput].$validators.historyTimeValidate = validateProvider(0);
//        }
//    };
//
//});

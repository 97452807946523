<div class="modal-header">
    <button type="button" class="close" ng-click="closeDialog()" aria-hidden="true">&times;</button>
    <h4 class="modal-title">{{"dashboard.edit.formTitle" | i18n}}</h4>

</div>
<div class="modal-body">
    <form role="form" name="forma">
        <div class="form-group">
            <label for="dashboardTitle">{{"dashboard.edit.title" | i18n}}</label>
            <input type="text" class="form-control" id="dashboardTitle" ng-model="copy.title" required=""/>
        </div>
        <div class="form-group">
            <label for="dashboardHeight">{{"dashboard.edit.height" | i18n}}</label>
            <input type="number" class="form-control" id="dashboardHeight" ng-model="model.height" required=""/>
        </div>
        <div class="form-group">
            <label>{{"dashboard.edit.structure" | i18n}}: {{model.structure}}</label>

            <div class="row">
                <div class="col-xs-6 col-md-3" ng-repeat="(key, structure) in structures"
                     x-ng-click="model.structure = key; changeStructure(key, structure)" title="{{key}}">
                    <div class="configDashboard" x-ng-class="{'active' : model.structure == key}">
                        <div class="row configDashboardRow" ng-repeat="row in structure.rows" ng-class="row.styleClass"
                             x-ng-attr-style="height:{{100/structure.rows.length}}%">
                            <div ng-repeat="column in row.columns" class="column {{column.styleClass | mdTOxs}}"
                                 style="height: 100%">
                                <div class="widgets configDashboardWidget" style="height: 100%"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" ng-click="closeDialog()">{{"dashboard.edit.close" | i18n}}</button>
</div>
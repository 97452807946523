var module = angular.module('meternet.directives',[
	    'meternet.menu',
	    'meternet.footer',
	    'meternet.status.directives',
	    'meternet.config.directives',
	    'meternet.about.directives',
	    'meternet.menu',
	    'meternet.footer',
	    'meternet.charts.directives',
	    'meternet.dashboard.directives',
	    'meternet.user.directives',
	    'meternet.license.directives',
	    'meternet.report.directives',
	    'meternet.reportHistory.directives',
	    'meternet.reportTable.directives',
	    'meternet.reportChart.directives',
	    'meternet.fileManager.directives',
	    'meternet.measurements.directives',
	    'meternet.utils.ui.directives',
		'meternet.fileBrowser.directives',
        'meternet.timetable.controllers',
        'meternet.timetable.directives',
        'meternet.scores.directives',
        'meternet.scores.controllers',
        'meternet.eventindicator.directives'
]);


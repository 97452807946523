<div class="lighting-settings" style="height:100%; min-height:300px; width:100%">
  <div class="row flex-row" style="height:100%">
    <div class="dual-list list-left col-xs-8"  >
      <div class="table-responsive"style="overflow-y:hidden; height:100%">
        <div class="row markers-search">
          <div class="col-sm-3 col-xs-6">
            <div class="form-group">
              <label class="">{{::'report.table.search' | i18n}}</label>
              <div class="input-group">
                <input x-ng-model="searchDevice" type="text" class="form-control" placeholder="{{'report.table.search' | i18n}}"/>
                <span class="input-group-addon">
                  <i class="fa fa-search"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="col-sm-3 col-xs-6 markers-checkbox">
            <div class="checkbox">
              <label>
				  <input type="checkbox" ng-model="clusterLayer" ng-disabled="noMap"> {{::'markers.table.clustering' | i18n}}
              </label>
            </div>
            <div class="checkbox">
              <label>
				  <input type="checkbox" ng-model="onlyMapMarkers" ng-disabled="noMap"> {{::'markers.table.show.checkbox' | i18n}}
              </label>
            </div>
          </div>
          <div class="col-sm-6 col-xs-6">
            <button type="button" class="btn btn-sm btn-primary pull-right" style="margin-top:20px" x-ng-click="hideMap()">
              <span class="fa-stack">
                <i class="fa fa-map fa-stack-1x"></i>
                <i ng-if="!noMap" class="fa fa-ban fa-stack-2x text-danger"></i>
              </span>
            </button>
          </div>
        </div>
        <div class="mbsat2-pre-scrollable">
          <div data-ng-repeat="location in locationWithinBounds">
            <table class="table table-condensed table-markers table-hover">
              <tr class="info" ng-attr-id="{{location.id}}" ng-mouseover="ui.overMarker(location)" ng-mouseleave="ui.leaveMarker(location)">
                <td  class="col-sm-1">
                  <button type="button" class="btn btn-link" x-ng-click="location.show=!location.show">
                    <i x-ng-if="!location.show" class="fa fa-angle-right"></i>
                    <i x-ng-if="location.show" class="fa fa-angle-down"></i>
                  </button>
                </td>
                <td class="col-sm-2">{{location.localisation || 'udefined'}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="col-sm-1" style="text-align: right;">
                  <button type="button" class="btn btn-primary" x-ng-click="changeView(location.markers)">
                    <i class="fa fa-bar-chart"></i>
                  </button>
                </td>
              </tr>
              <tr x-ng-if="location.show" >
                <th class="col-sm-2" ng-click='sortColumn("device.label", "devicesColumn", "devicesReverse")'><a>{{'config.label' | i18n}}<i class="{{sortIcon('device.label', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
                <th class="col-sm-2" ng-click='sortColumn("device.desc", "devicesColumn", "devicesReverse")'><a>{{'config.desc1' | i18n}}<i class="{{sortIcon('device.desc', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
                <th class="col-sm-2" ng-click='sortColumn("device.desc2", "devicesColumn", "devicesReverse")'><a>{{'config.desc2' | i18n}}<i class="{{sortIcon('device.desc2', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
                <th class="col-sm-2" ng-click='sortColumn("device.desc3", "devicesColumn", "devicesReverse")'><a>{{'config.desc3' | i18n}}<i class="{{sortIcon('device.desc3', 'devicesColumn', 'devicesReverse')}}"></i></a></th>
                <th class="col-sm-1"><span>{{'config.device.param' | i18n}}</span></th>
                <th class="col-sm-1"><span>{{'config.device.value' | i18n}}</span></th>
                <th class="col-sm-1"></th>
              </tr>
              <tr x-ng-if="location.show" data-ng-repeat="mark in location.markers | orderBy:devicesColumn:devicesReverse" ng-attr-id="{{mark.device.id}}" ng-mouseover="ui.overMarker(mark)" ng-mouseleave="ui.leaveMarker(mark)">
                <td>{{mark.label}}</td>
                <td>{{mark.desc}}</td>
                <td>{{mark.desc2}}</td>
                <td>{{mark.desc3}}</td>
                <td>{{ui.getCurrent(mark).label || ui.getCurrent(mark).name}}</td>
                <td>{{ui.getCurrent(mark).moment.good | measurement}}</td>
                <td style="text-align: right;">
                  <button type="button" class="btn btn-primary" x-ng-click="changeView([mark])">
                    <i class="fa fa-bar-chart"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="dual-list list-right col-xs-4">-->
<!--      <div x-events-ack="" style="height: 100%;"/>-->
<!--  </div>-->
<!--  <div class="modal fade" id="lightingSettingAdd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">-->
<!--    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">-->
<!--      <div class="modal-content">-->
<!--        <div class="modal-header">-->
<!--          <h2 class="modal-title">{{::'dashboard.widgets.config.series.lightingSetting' | i18n}}</h2>-->
<!--        </div>-->
<!--        <div class="modal-body">-->
<!--          <form role="form form-inline">-->
<!--            <div class="form-group" style="width: 100%;     padding-bottom: 10px;" >-->
<!--              <label class="form-group-label">{{::'mbsat.modal.settings.name' | i18n}}:</label>-->
<!--              <input type="text" class="form-control" data-ng-model="ui.newLightingSetting.name" style="width: 100%"/>-->
<!--            </div>-->
<!--          </form>-->
<!--        </div>-->
<!--        <div class="modal-footer">-->
<!--          <button type="button" class="btn btn-primary" data-dismiss="modal" x-ng-click="saveNewLightingSetting()">-->
<!--            <i class="fa fa-save"></i>{{'btn.save' | i18n}}-->
<!--          </button>-->
<!--          <button type="button" class="btn btn-secondary" data-dismiss="modal" x-ng-click="resetLightingSetting()">-->
<!--            <i class="fa fa-long-arrow-left"></i>{{'ui.cancel' | i18n}}-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>

<div class="modal-header"><i class="fa fa-fw" x-ng-class="ui.mode"></i>&nbsp;{{ ui.modalTitle | i18n }}
    <button type="button" class="close" x-ng-click="cancel()" aria-hidden="true">×</button>
</div>
<div class="modal-body">
    <div class="form-horizontal" x-errors="model.errors" x-errors-root="model">
        <div class="form-group">
            <label class="control-label col-sm-4">{{'parameterScore.selectDevice' | i18n}}:</label>
            <div class="col-sm-6" style="margin-left:3px;">
                <select data-ng-model="selectedDevice" data-ng-options="device.name for device in devices" class="form-control" x-field-errors="">
                </select>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-4">{{'parameterScore.selectParameter' | i18n}}:</label>
            <div class="col-sm-6" style="margin-left:3px;">
                <select data-ng-model="model.paramId" data-ng-options="param.id as param.name for param in selectedDevice.params" class="form-control" x-field-errors="">
                </select>

            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-4" style="margin-bottom: -15px;">{{'parameterScore.range' | i18n}}: </label>
            <div data-ng-repeat="range in model.ranges" class="col-sm-8 col-sm-offset-4">
                <div class="form-inline">
                    <div class="form-group col-sm-2 diff-width">
                        <label class="form-group-label">Min:</label>
                        <input class="form-control col-sm-1 form-width" data-ng-model="range.min" x-field-errors="'model.ranges[' + $index + '].min'"/>
                    </div>
                    <div class="form-group col-sm-2 diff-width">
                        <label class="form-group-label">Max:</label>
                        <input class="form-control col-sm-1 form-width" data-ng-model="range.max"  x-field-errors="'model.ranges[' + $index + '].max'"/>
                    </div>
                    <div class="form-group col-sm-2 diff-width">
                        <label class="form-group-label" >{{'parameterScore.factory' | i18n}}:</label>
                        <input class="form-control col-sm-1 form-width" data-ng-model="range.factory"   x-field-errors="'model.ranges[' + $index + '].factory'"/>
                    </div>

                    <div class="form-group col-sm-2 diff-width">
                        <label class="form-group-label" >{{'parameterScore.offset' | i18n}}:</label>
                        <input class="form-control col-sm-1 form-width" data-ng-model="range.offset"   x-field-errors="'model.ranges[' + $index + '].offset'"/>
                    </div>
                    <div class="form-group pull-right">
                        <label class="form-group-label">&nbsp</label>
                        <button class="btn btn-danger" data-ng-click="removeRange($index)" title="{{'ui.delete' | i18n}}"><i class="fa fa-trash"></i></button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="form-group" style="width:100%;margin:0;">
                <button class="btn btn-success pull-right" data-ng-click="addRange()" title="{{'ui.add' | i18n}}">
                    <i class="fa fa-plus"></i>
                </button>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-4">{{'parameterScore.interval' | i18n}}:</label>
            <div class="col-sm-2" style="margin-left:3px;">
                <select data-ng-options="interval as interval for interval in intervals" data-ng-model="model.interval" class="form-control" x-field-errors="">

                </select>
            </div>
        </div>


    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" data-ng-click="ok()" data-ng-disabled="ui.saving"><i data-ng-class = "ui.getSaveClass()" class="fa fa-save fa-lg"></i>&nbsp;&nbsp;{{'parameterScore.save' | i18n}}</button>
    <button class="btn btn-danger" data-ng-click="cancel()"><i class="fa fa-undo fa-lg"></i>&nbsp;&nbsp;{{'parameterScore.cancel' | i18n}}</button>
</div>
var module = angular.module('meternet.status.services', []);

module.service('statusService', function ($q, $http, $log, contextPath, $rootScope, $timeout, dataService) {
	var deviceTopic = "/topic/status";

	var unsubscribeFn = dataService.subscribe(deviceTopic, function (data) {
		$rootScope.$broadcast('statusUpdate', data);
	});

	$rootScope.$on("$destroy", unsubscribeFn);

	var statusUrl = contextPath + '/status';

	(function getStatus() {
		$http({
			method: 'GET',
			url: statusUrl
		}).success(function (data, status, headers, config) {
			$rootScope.$broadcast('statusUpdate', data);
			$timeout(getStatus, 30000);
		}).error(function () {
			$timeout(getStatus, 30000);
		});
	})();

	// API
	return {
		start: function () {
			var deferred = $q.defer();
			var startUrl = contextPath + '/engines/start';
			$http({
				method: 'GET',
				url: startUrl
			}).success(function (data, status, headers, config) {
				deferred.resolve(data);
			});
			return deferred.promise;
		},
		stop: function () {
			var deferred = $q.defer();
			var stopUrl = contextPath + '/engines/stop';
			$http({
				method: 'GET',
				url: stopUrl
			}).success(function (data, status, headers, config) {
				deferred.resolve(data);
			});
			return deferred.promise;
		}
	};
});


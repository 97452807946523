var module = angular.module('meternet.chart.directives.lightingSettings', ['meternet.charts.services']);

module.directive('lightingSettings', function ($q, $http, $stateParams, dashboardService, paramService) {
  return {
    scope: {
      menuView: '=',
      markersWithinBounds: '=',
      onlyMapMarkers: '=',
      config: '='
    },
    templateUrl: 'dashboard/calendar/lighting-settings.html',
    controller: function ($scope) {
      $scope.selectedDevices = [];
      $scope.data = {selectedLightingSetting: null};
      $scope.lightingSettings = null;
      $scope.devicesColumn = 'device.label';
      $scope.devicesReverse = false;
      $scope.lightingSettingsColumn = 'name';
      $scope.lightingSettingsReverse = false;
      $scope.searchDevice = null;
      $scope.params = [];

      $scope.ui = {
        processing: false,
        newLightingSetting: {},
        allSelected: false,
        selectAll: function () {
          if (!$scope.ui.allSelected) {
            $scope.selectedDevices.length = 0;
            $scope.markersWithinBounds.forEach(function (val) {
              val.checked = true;
              $scope.selectedDevices.push(val.device);
            })
          } else {
            $scope.markersWithinBounds.forEach(function (val) {
              val.checked = false;
            })
            $scope.selectedDevices.length = 0;
          }
          $scope.$emit('selectAllMarkers');
        },
        checked: function (device) {
          return _.findIndex($scope.selectedDevices, function (o) {
            return o.id === device.id;
          }) > -1
        },
        checkboxClick: function (marker) {
          if (!this.checked(marker.device)) {
            $scope.selectedDevices.push(marker.device);
            marker.checked = true;
          } else {
            var idx = _.findIndex($scope.selectedDevices, function (o) {
              return o.id === marker.device.id;
            });
            $scope.selectedDevices.splice(idx, 1);
            marker.checked = false;
          }
          $scope.$emit('selectMarker', marker);
        },
        getLightSettingName: function (lightSetId) {
          var idx = _.findIndex($scope.lightingSettings, function (o) {
            return o.id === lightSetId
          });
          return idx > -1 ? $scope.lightingSettings[idx].name : ""
        },
        overMarker: function (marker) {
          $scope.$emit('overMarker', marker);
        },
        leaveMarker: function (marker) {
          $scope.$emit('leaveMarker', marker);
        },
        getCurrent: function (marker) {
          var value;
          _.forEach(marker.params, function (param) {
            if (param.quantity === 'status' && param.moment && param.moment.current) {
              value = param.moment.current.value;
              return false;
            }
          });
          return value ? "ON" : "OFF";
        }
      };

      dashboardService.isDashboardEditable($stateParams.dashboardName).then(function (editable) {
        $scope.editable = editable;
      });

      $scope.sortColumn = function (col, colName, revName) {
        $scope[colName] = col;
        $scope[revName] = !$scope[revName];
      };
      $scope.sortIcon = function (col, colName, revName) {
        if ($scope[colName] === col) {
          return $scope[revName] ? 'fa fa-caret-down' : 'fa fa-caret-up';
        } else {
          return '';
        }
      }

      $scope.addLightingSetting = function () {
        $('#lightingSettingAdd').modal('show');
      };

      $scope.editLightingSetting = function (setId) {
        $scope.$emit('goToCalendar', setId);
      };

      $scope.saveNewLightingSetting = function () {
        $scope.ui.newLightingSetting.id = uuid.v4();
        $scope.ui.newLightingSetting.schedulers = [];
        $scope.ui.newLightingSetting.holidays = [];
        $scope.lightingSettings.push($scope.ui.newLightingSetting);
        $scope.resetLightingSetting();
        $scope.saveLightingSetting();
      };

      $scope.resetLightingSetting = function () {
        $scope.ui.newLightingSetting = {};
      };

      $scope.linkLightingSetting = function () {
        _.forEach($scope.selectedDevices, function (device) {
          device.lightingSettingId = $scope.data.selectedLightingSetting
        });
        $scope.saveLightingSetting();
      };

      $scope.unlinkLightingSetting = function () {
        _.forEach($scope.selectedDevices, function (device) {
          device.lightingSettingId = null;
        });
        $scope.saveLightingSetting();
      };

      $scope.changeView = function () {
        $scope.$emit('changeView');
      };

      $scope.copyLightingSetting = function () {
        var newLightingSetting = JSON.parse(JSON.stringify(_.findWhere($scope.lightingSettings, {id: $scope.data.selectedLightingSetting})));
        newLightingSetting.id = uuid.v4();
        newLightingSetting.name += '_copy';
        _.forEach(newLightingSetting.schedulers, function (scheduler, index) {
          scheduler.id = uuid.v4();
        });
        $scope.lightingSettings.push(newLightingSetting);
        $scope.saveLightingSetting();
      };

      $scope.saveLightingSetting = function () {
        $scope.$emit('saveConfig')
      };

      $scope.removeLightingSetting = function () {
        var idx = _.findIndex($scope.lightingSettings, function (o) {
          return o.id === $scope.data.selectedLightingSetting;
        });
        $scope.lightingSettings.splice(idx, 1);
        $scope.data.selectedLightingSetting = null;
        $scope.saveLightingSetting()
      };

      $scope.$on('clickMarker', function (event, marker) {
        var mark = _.findWhere($scope.markersWithinBounds, {lat: marker.lat, lng: marker.lng});
        if (mark) {
          $scope.$emit('clickMarkerOnMap', $scope.ui.checked(mark.device));
        }
      });
      $scope.$on('checkMarker', function (event, marker) {
        var mark = _.findWhere($scope.markersWithinBounds, {lat: marker.lat, lng: marker.lng});
        $scope.ui.checkboxClick(mark);
      });

      $scope.$watchCollection('selectedDevices', function (newVal) {
        if (newVal) {
          $scope.ui.allSelected = ($scope.markersWithinBounds.length === $scope.selectedDevices.length) && $scope.markersWithinBounds.length !== 0;
        }
      });

      $scope.$watch('searchDevice', function (newVal) {
        if (typeof newVal !== 'undefined' && newVal !== null) {
          $scope.$emit('search', newVal);
        }
      });

      $scope.$watch('config', function (config) {
        if (!config) {
          return
        }
        $scope.selectedDevices.length = 0;
        config.engine.lightingSettings = config.engine.lightingSettings || [];
        $scope.lightingSettings = config.engine.lightingSettings;
        if ($scope.lightingSettings.length) {
          $scope.data.selectedLightingSetting = $scope.lightingSettings[0].id;
          $scope.ui.processing = false;
        }
      });

      $scope.$watchCollection('markersWithinBounds', function (newVal) {
        $scope.params = paramService.prepareParamsForMarkers($scope.markersWithinBounds);
        paramService.unsubscribeUnchecked($scope.params);
        paramService.subscribeParams($scope.params);
      });
      $scope.$on('$destroy', function() {
        paramService.unsubscribeUnchecked([]);
      });
    }
  }
});

/* globals _,moment,pdfMake */
var module = angular.module('meternet.dashboard.controllers.campingWidget', []);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/media/camping-widget.html',
        title : messages['dashboard.widgets.campingGauge.title'],
        description : messages['dashboard.widgets.campingGauge.desc'],
        controller : 'campingWidgetCtrl',
        screenshot: false,
        config: {
            series: []
        },
        edit: {
            controller : 'campingWidgetEditCtrl',
            templateUrl: 'dashboard/media/camping-edit.html',
            resolve : {
                maxSeries : [ 'licenseService', function(licenseService) {
                    var license = licenseService.getLicense();
                    return license.properties.MAX_CAMPING_SERIES || 5;
                } ]
            }
        }
    };
    dashboardProvider.widget('camping', widget);
});

module.controller('campingWidgetEditCtrl', function ($scope, $filter, contextPath, dataService,
													 config, configService,
													 UnitScales) {
	$scope.ui = {
		addSeries: function () {
			if (this.canAddSeries()) {
				var series = {
					paramId: null,
					sync: true,
				};
				$scope.config.series.push(series);
			}
		},
		removeSerie: function (index) {
			$scope.config.series.splice(index, 1);
		},
		canAddSeries: function () {
			return true;
		},
		updateSeriesParam: function (series) {
			if (series._device && series.deviceId !== series._device.id) {
				series._param = series._device.params[0];
			}
			if (series._param && series.paramId !== series._param.id) {
				series.cost = null;
				series.currency = null;
				series.sync = true;
			}
			if (series._device && series._param) {
				series.deviceId = series._device.id;
				series.paramId = series._param.id;
				series.scale = series._param.scale || 0;
				series.precision = series._param.precision || 0;

				var deviceName = (series._device.label || series._device.name);
				var paramName = (series._param.label || series._param.name);
				series.name = deviceName + '.' + paramName;

				series.unit = $filter('quantityUnit')(series._param.quantity);

				this.updateSeriesMediaCost(series);
			}
		},
		updateSeriesMediaCost: function (series) {
			if ($scope.mediaCosts && series.sync) {
				for (var i = 0; i < $scope.mediaCosts.length; ++i) {
					var mc = $scope.mediaCosts[i];
					var mtags = _.map(mc.tag ? mc.tag.split(/\s+|\s*[,;]\s*/) : [], function (tag) { return tag.trim().toLowerCase(); });
					var ptags = _.map(series._param.tags ? series._param.tags.split(/\s+|\s*[,;]\s*/) : [], function (tag) { return tag.trim().toLowerCase(); });
					if (_.intersection(mtags, ptags).length > 0) {
						series.cost = mc.cost;
						series.currency = mc.currency;
						if (series._param.scale !== mc.scale) {
							series.cost *= Math.pow(10, mc.scale - series._param.scale);
						}
						break;
					}
				}
			}
		},
		unitCost: function (series) {
			var u = '';
			if (series.currency) {
				u += series.currency;
			}
			if (series.unit) {
				u += '/';
				if (series.scale) {
					var s = _.find(UnitScales, function (s) { return s.value === series.scale; });
					u += s ? s.label : '';
				}
				u += series.unit;
			}
			return u;
		},
	};

	configService.get().then(function (meternetConfig) {
		var devices = _.flatten(_.pluck(meternetConfig.engine.measurementInputs, 'devices', true));
		devices = devices.concat(_.flatten(_.pluck(meternetConfig.engine.moduleInputs, 'devices', true)));
		$scope.devices = _.without(devices, _.findWhere(devices, {model: "energy_report"}));
		$scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model: "prepaid"}));
		$scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model: "alarm"}));
		$scope.devices = _.without($scope.devices, _.findWhere($scope.devices, {model: "control"}));

		$scope.devices = _.sortBy($scope.devices, function (d) {
			return d.label || d.name;
		});

		$scope.mediaCosts = meternetConfig.engine.mediaCosts.costs;

		var checkParam = function (param) {
			return this.paramId === param.id;
		};

		var checkDevice = function (device) {
			return this.deviceId === device.id;
		};

		for (var i = 0; i < $scope.config.series.length; ++i) {
			var series = $scope.config.series[i];
            series._device = _.find($scope.devices, checkDevice, series);
            if (series._device) {
                series._param = _.find(series._device.params, checkParam, series);
            }
            $scope.ui.updateSeriesParam(series);
		}
	});
});

module.controller('campingWidgetCtrl', function($scope, $timeout, $q,
												config, unitFilter, dataService) {
	$scope.ui = {
		loading: false,
		states: {},
		loadReport: function () {
			this.loading = true;
			var req = {
				statePath: config.statePath,
				series: _.map(config.series, function (s) {
					return {
						deviceId: s.deviceId,
						paramId: s.paramId,
						name: s.name,
						sync: s.sync,
						cost: s.cost,
						currency: s.currency,
						state: $scope.ui.states[s.paramId],
					};
				})
			};

			return dataService.getCampingData(req).then(function (report) {
				$scope.report = report;
				$scope.ui.states = {};
				$scope.ui.loading = false;
			});
		},
		start: function (row) {
			this.states[row.paramId] = 'start';
			this.loadReport().then(function () {
				$scope.ui.getStartPdf(row);
			});
		},
		stop: function (row) {
			this.states[row.paramId] = 'stop';
			this.loadReport().then(function () {
				$scope.ui.getStopPdf(row);
			});
		},
		getStartPdf: function (row) {
			var docDefinition = {
				content: [
					{ text: moment(new Date()).format("YYYY-MM-DD HH:mm"), alignment: 'right' },
					{ text: config.header || '', style: 'header' },
					' ',
					' ',
					{ text: config.title || '', style: 'title' },
					{ text: 'Raport początkowy', style: 'subtitle' },
					'Nazwa licznika: ' + row.name,
					'Stawka: ' + unitFilter(row.unitCost, 2, row.currency),
					{
						style: 'tableExample',
						table: {
							headerRows: 1,
							// keepWithHeaderRows: 1,
							// dontBreakRows: true,
							widths: ['*', '*'],
							body: [
								[
									{ text: 'Data pomiaru', style: 'tableHeader' },
									{ text: 'Odczyt', style: 'tableHeader' }
								],
								[
									moment(row.timestampStart).format("YYYY-MM-DD HH:mm:ss"),
									{
										text: unitFilter(row.valueStart, row.precision, row.unit, row.scale),
										alignment: 'right'
									}
								]
							]
						}
					},
					' ',
					' ',
					' ',
					' ',
					' ',
					' ',
					' ',
					{
						columns: [
							{
								stack: [
									'podpis wystawiającego',
									' ',
									' ',
									'...........................',
								],
								fontSize: 15,
								alignment: 'center'
							},
							{
								stack: [
									'podpis klienta',
									' ',
									' ',
									'...........................',
								],
								fontSize: 15,
								alignment: 'center'
							}
						]
					}
				],
				styles: {
					header: {
						fontSize: 12
					},
					title: {
						fontSize: 18,
						bold: true,
						margin: [0, 0, 0, 10],
						alignment: 'center'
					},
					subtitle: {
						fontSize: 16,
						bold: true,
						margin: [0, 10, 0, 5],
						alignment: 'center'
					},
					tableExample: {
						margin: [0, 5, 0, 15]
					},
					tableHeader: {
						bold: true,
						fontSize: 13,
						color: 'black'
					}
				},
				defaultStyle: {
					// alignment: 'justify'
				}
			};
			pdfMake.createPdf(docDefinition).download(row.name + '.pdf');
		},
		getStopPdf: function (row) {
			var docDefinition = {
				content: [
					{ text: moment(new Date()).format("YYYY-MM-DD HH:mm"), alignment: 'right' },
					{ text: config.header || '', style: 'header' },
					' ',
					' ',
					{ text: config.title || '', style: 'title' },
					{ text: 'Raport końcowy', style: 'subtitle' },
					'Nazwa licznika: ' + row.name,
					'Stawka: ' + unitFilter(row.unitCost, 2, row.currency),
					{
						style: 'tableExample',
						table: {
							headerRows: 1,
							// keepWithHeaderRows: 1,
							// dontBreakRows: true,
							widths: ['*', '*'],
							body: [
								[
									{ text: 'Data pomiaru', style: 'tableHeader' },
									{ text: 'Odczyt', style: 'tableHeader'}
								],
								[
									moment(row.timestampStart).format("YYYY-MM-DD HH:mm:ss"),
									{ text: unitFilter(row.valueStart, row.precision, row.unit, row.scale), alignment: 'right' }
								],
								[
									moment(row.timestampEnd).format("YYYY-MM-DD HH:mm:ss"),
									{ text: unitFilter(row.valueEnd, row.precision, row.unit, row.scale), alignment: 'right' }
								],
								[
									{ text: 'Zużycie:', alignment: 'right' },
									{ text: unitFilter(row.usage, row.precision, row.unit, row.scale), alignment: 'right' }
								]
							]
						}
					},
					'Rozliczenie: ' + unitFilter(row.unitCost, 2, row.currency) + ' * ' + unitFilter(row.usage, row.precision, row.unit, row.scale) + ' = ' + unitFilter(row.cost, 2, row.currency),
					{ text: 'Do zapłaty: ' + unitFilter(row.cost, 2, row.currency), alignment: 'right' },
					' ',
					' ',
					' ',
					' ',
					' ',
					' ',
					{
						columns: [
							{
								stack: [
									'podpis wystawiającego',
									' ',
									' ',
									'...........................',
								],
								fontSize: 15,
								alignment: 'center'
							},
							{
								stack: [
									'podpis klienta',
									' ',
									' ',
									'...........................',
								],
								fontSize: 15,
								alignment: 'center'
							}
						]
					}
				],
				styles: {
					header: {
						fontSize: 12
					},
					title: {
						fontSize: 18,
						bold: true,
						margin: [0, 0, 0, 10],
						alignment:'center'
					},
					subtitle: {
						fontSize: 16,
						bold: true,
						margin: [0, 10, 0, 5],
						alignment:'center'
					},
					tableExample: {
						margin: [0, 5, 0, 15]
					},
					tableHeader: {
						bold: true,
						fontSize: 13,
						color: 'black'
					}
				},
				defaultStyle: {
					// alignment: 'justify'
				}
			};
			pdfMake.createPdf(docDefinition).download(row.name + '.pdf');
		},
	};

	$scope.report = {
		rows: _.map(config.series, function (s) {
			return {
				name: s.name,
			};
		})
	};

	$scope.ui.loadReport();

	_.each(config.series, function (s, i) {
		if (s.paramId) {
			var unsub = dataService.subscribeForParametersMeasurements(s.paramId, function (moment) {
				var row;
				if ($scope.report && moment.current.value != null && isFinite(moment.current.value)) {
					row = $scope.report.rows[i];
					if (row.started) {
						row.valueEnd = moment.current.value;
						row.timestampEnd = moment.current.timestamp;
						row.usage = row.valueEnd - row.valueStart;
						row.cost = row.unitCost * row.usage / Math.pow(10, row.scale);
					} else {
						row.valueStart = moment.current.value;
						row.timestampStart = moment.current.timestamp;
					}
				}
			});
			$scope.$on('$destroy', unsub);
		}
	});
});

<div class="container">
    <div class="row">
        <div class="panel panel-default">
            <div class="panel-heading"><i class="fa fa-fw fa-calendar"></i>&nbsp;{{'timetable.title' | i18n}}</div>
            <div class="panel-body">

                <div x-errors="timetable.errors" x-errors-root="timetable">
                    <div class="form-inline" style="margin-top:10px;">
                        <div class="form-group">
                            <button class="btn btn-default" data-ui-sref="main.timetable"><i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'timetable.view.back' | i18n}}</span></button>
                            <button class="btn btn-success" data-ng-click="ui.save()" data-ng-disabled="ui.saving"><i data-ng-class = "ui.getSaveClass()" class="fa fa-save"></i>&nbsp;<span>{{'timetable.view.save' | i18n}}</span></button>

                        </div>
                        <div class="form-group">
                            <button class="btn btn-success" data-ng-click="ui.copy()"><i class="fa fa-copy"></i> <span>{{'timetable.copy' | i18n}}</span> </button>
                        </div>
                        <div class="form-group">
                            <div
                                    isteven-multi-select
                                    input-model="ui.inputParams"
                                    output-model="ui.outputParams"
                                    button-label="name"
                                    item-label="name"
                                    tick-property="ticked"
                                    x-translation="typeMultiselectTranslations"
                                    >
                            </div>

                        </div>
                        <div data-ng-if="timetable.errors" class="form-group">
                            {{'timetable.error.parameters.nonUnique' | i18n}}
                        </div>

                        <div class="text-center">
                            <div class="btn-group">
                                <button class="btn btn-primary" data-ng-click="ui.previousWeek()"><i class="fa fa-long-arrow-left"></i>&nbsp;<span>{{'timetable.view.previousWeek' | i18n}}</span></button>
                                <button class="btn btn-primary" data-ng-click="ui.nextWeek()"><span>{{'timetable.view.nextWeek' | i18n}}</span>&nbsp;<i class="fa fa-long-arrow-right"></i></button>
                            </div>
                        </div>


                    </div>


                    <table class="table table-condensed table-stripped table-bordered" style="margin-top:10px;">
                        <thead>
                        <tr>
                            <th>
                                {{'timetable.view.hour' | i18n}}
                            </th>
                            <th data-ng-repeat="day in days" style="text-align: center">
                                {{day.format('YYYY-MM-DD (dd)')}}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr data-ng-repeat="hour in hours">
                            <td  style="width: 30px;">{{hour}}:00</td>
                            <td id="{{day.format('YYYY-MM-DD')+'-' + hour}}" data-ng-repeat="day in days" data-ng-click="ui.addFrame(day, hour)"></td>
                        </tr>
                        </tbody>
                    </table>

                    <div data-ng-repeat="frame in framesForView | framesFilter:dateFrom:dateTo">
                        <timetable-frame frame="frame" timetable="timetable" view-date-from="{{dateFrom}}" view-date-to="{{dateTo}}"></timetable-frame>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

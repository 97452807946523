<div class="accordion cost-report">
  <div x-ng-repeat="group in groups track by group.id">
    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
      <div class="panel panel-default">
        <div class="panel-heading" data-toggle="collapse"
             data-target="#{{group.id}}"
             aria-expanded="false"
             aria-controls="{{group.id}}">
          <h4 class="panel-title">
            {{group.label || group.name}}
          </h4>
        </div>
        <div id="{{group.id}}" class="panel-collapse collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="panel-body">
              <div class="col-xs-12 col-sm-6">
                <h5 class="header">{{'config.data.group' | i18n}}</h5>
                <hr/>
                <div class="device-data">
                  <div><span>{{'config.name' | i18n}}: </span><span>{{group.id}}</span></div>
                  <div><span>{{'config.label' | i18n}}: </span><span>{{group.label || group.name}}</span></div>
                  <div><span>{{'config.device' | i18n}}: </span><span>{{groupDevices[$index].label || groupDevices[$index].name}}</span></div>
                  <div><span>{{'config.device.geolocation' | i18n}}: </span><span>{{group.lat + ", " + group.lon}}</span></div>
                  <div><span>{{'config.device.load' | i18n}}: </span><span>{{group.load}}</span></div>
                </div>
            </div>
              <div class="col-xs-12 col-sm-6">
                <h5 class="header">{{'config.electricity.cost' | i18n}}</h5>
                <hr/>
                <div class="device-data">
                  <div><span>{{'config.device.fixedCost' | i18n}}, {{'config.device.variableCost' | i18n}}: </span><span>{{groupDevices[$index].fixedCost || '-'}}, {{groupDevices[$index].variableCost || '-'}}</span></div>
                  <div><span>{{'mbsat.week.load' | i18n}}: </span><span>{{ui.getTotalWeek(group) | load }} kWh</span></div>
                  <div><span>{{'mbsat.month.load' | i18n}} {{monthName}}: </span><span>{{ui.getTotalMonth(group) | load }} kWh</span></div>
                  <div><span>{{'mbsat.week.total.cost' | i18n}}: </span><span>{{groupDevices[$index].fixedCost + groupDevices[$index].variableCost * ui.getTotalWeek(group) | load}}</span></div>
                  <div><span>{{'mbsat.month.total.cost' | i18n}} {{monthName}}: </span><span>{{groupDevices[$index].fixedCost + groupDevices[$index].variableCost * ui.getTotalMonth(group) | load}}</span></div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

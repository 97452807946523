angular.module('meternet.chart.directives.marker-popup', [])
  .directive('markerPopup', function() {
    return {
      templateUrl: 'dashboard/calendar/marker-popup.html',
      controller: 'MarkerPopUpController'
    };
  })
  .controller('MarkerPopUpController', ['$scope', function($scope) {
    $scope.checkBoxClick = function () {
      $scope.$broadcast('checkMarker', $scope.ui.clickedMark);
    };
  }]);

var module = angular.module('meternet.chart.directives.mbsat2Chart', [ 'meternet.charts.services',
                                                                        'meternet.filters',
                                                                        'meternet.dashboard.directives']);

module.directive('mbsat2Chart', function (paramService) {
    return {
        scope: {
            options: '=',
            device: '=',
            param: '=',
            title: '='
        },
        templateUrl: 'dashboard/mbsat2/mbsat2-chart.html',
        controller: function ($scope, $interval, $filter, $q, $timeout, dataService, configService, DashboardEvents, unitFilter) {

            $scope.ui={
                row: {
                    device: $scope.device,
                    param: $scope.param
                },
                historyTime: 1,
                title: $scope.title
            }
        }
    }
});

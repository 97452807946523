var module = angular.module('meternet.dashboard.controllers.fileManager', [
	'adf.provider',
	'i18n'
]);

module.config(function(dashboardProvider, contextPath, messages) {
	var widget = {
		templateUrl: 'dashboard/file-manager-widget.html',
		title : messages['dashboard.widgets.fileManager.title'],
		description : messages['dashboard.widgets.fileManager.desc'],
		controller : 'fileManagerWidgetCtrl',
		csv: true,
		config: {
			type: "file-manager",
			rootPath: '',
			readOnly: '',
			series : []
		},
		edit : {
			controller : 'fileManagerWidgetEditCtrl',
			templateUrl: 'dashboard/file-manager-widget-edit.html',
		}
	};
	dashboardProvider.widget('file-manager', widget);
});

function FileManagerEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts) {
    $scope.ui = {
		path: '/'
    };
}

function FileManagerCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
    $scope.ui = {};
}

module.controller('fileManagerWidgetEditCtrl', FileManagerEditCtrl);
module.controller('fileManagerWidgetCtrl', FileManagerCtrl);

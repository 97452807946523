<div class="panel panel-default">
    <div class="panel-heading">{{::'menu.report.history' | i18n}}</div>
    <div class="panel-body">
        <form role="form" class="row">
            <div class="col-xs-3">
                <div class="form-group">
                    <label class="">{{::'report.table.search' | i18n}}</label>
                    <div class="input-group">
                        <input x-ng-model="search" type="text" class="form-control" placeholder="{{'report.table.search' | i18n}}" x-ng-disabled="count<all"/>
                        <span class="input-group-addon">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="form-group">
                    <label>{{::'report.table.paramTypes' | i18n}}:</label>
                    <div class="input-group" style="width:100%">
                        <div x-isteven-multi-select="" x-input-model="ui.quantities" x-output-model="quantities" x-max-labels="4"
                             x-button-label="label" x-item-label="label" x-tick-property="selected"
                             x-translation="ui.quantityTranslations" x-helper-elements="all none" disable-property="disabled" is-disabled="count<all"></div>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{::'report.table.visibleColumns' | i18n}}:</label>
                    <div class="input-group" style="width:100%">
                        <div x-isteven-multi-select="" x-input-model="ui.columns" x-output-model="columns" x-max-labels="4"
                             x-button-label="label" x-item-label="label" x-tick-property="selected"
                             x-translation="ui.columnTranslations" x-helper-elements="all none" disable-property="disabled" is-disabled="count<all"></div>
                    </div>
                </div>
            </div>
            <div class="col-xs-3">
                <div class="form-group">
                    <label>{{::'report.table.timestamp' | i18n}}:</label>
                    <div class="input-group">
                        <input type="text" class="form-control" x-ng-model="timestamp" x-datetimepicker="{format:'YYYY-MM-DD HH:mm'}" x-ng-disabled="count<all"/>
                        <span class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{::'report.table.toleration' | i18n}}:</label>
                    <select class="form-control" x-ng-model="tolerance" x-ng-options="t.value as t.label for t in ui.tolerances" x-ng-disabled="count<all"></select>
                </div>
            </div>
            <div class="col-xs-3" style="padding-top: 20px;">
                <span class="form-group" x-ng-if="count>=all">
                    <button class="btn btn-primary" type="button" x-ng-click="refresh()">
                        {{::'ui.show' | i18n}}&nbsp;<i class="fa fa-refresh" ></i>
                    </button>
                </span>
                <span class="form-group" x-ng-if="count>=all">
                    <div class="btn-group">
                        <button type="button" class="btn btn-success dropdown-toggle pull-right" x-ng-disabled="!enableCsv" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{::'report.files.download' | i18n}} <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu">
                            <li><a href="#" x-ng-click="downloadXlsx()">{{::'report.chart.xlsx' | i18n}}</a></li>
                            <li><a href="#" x-ng-click="downloadCsv()">{{::'report.chart.csv' | i18n}}</a></li>
                        </ul>
                    </div>
                </span>
                <div class="form-group" x-ng-if="count<all">
                    <uib-progressbar type="primary" value="((count / all) * 100)">{{count}} / {{all}}</uib-progressbar>
                </div>



                <div class="form-group pull-right" style="margin-top: 35px;">
                    <uib-pagination style="margin: 0; display: block;"
                                    x-boundary-links="true" x-direction-links="true"
                                    x-previous-text="&lsaquo;" x-next-text="&rsaquo;" x-first-text="&laquo;" x-last-text="&raquo;"
                                    x-total-items="table.rows.length"
                                    x-items-per-page="limit"
                                    x-ng-model="page"
                                    x-ng-show="table.rows.length"></uib-pagination>
                </div>
            </div>

        </form>
    </div>
    <table class="report-table table table-hover table-bordered table-condensed">
        <thead>
        <tr></tr>
        </thead>
        <tbody>
        </tbody>
    </table>
    <div class="panel-body">
        <form role="form" class="form-inline">
            <div class="form-group pull-right">
                <uib-pagination style="margin: 0; display: block;"
                                x-boundary-links="true" x-direction-links="true"
                                x-previous-text="&lsaquo;" x-next-text="&rsaquo;" x-first-text="&laquo;" x-last-text="&raquo;"
                                x-total-items="table.rows.length"
                                x-items-per-page="limit"
                                x-ng-model="page"
                                x-ng-show="table.rows.length"></uib-pagination>
            </div>
        </form>
    </div>
</div>

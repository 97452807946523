<form class="form-horizontal" x-errors="item.errors" x-errors-rooot="item.config">
    <div class="col-sm-8">
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-errors=""
                           x-field-help="config.database.name.help"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label" x-field-errors=""
                           x-field-help="config.database.label.help" x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-errors=""
                           x-field-help="config.database.desc.help"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.host' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.host" x-field-errors=""
                           x-field-help="config.database.host.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.port' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.port" x-field-errors=""
                           x-field-help="config.database.port.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.username" x-field-errors=""
                           x-field-help="config.database.username.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.password" x-field-errors=""
                           x-field-help="config.database.password.help"/>
                </div>
            </div>
        </div>
        <div class="form-group" x-ng-if="item.config.type==='mssql'">
            <label class="control-label col-sm-3">{{'config.database.instance' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.instance" x-field-errors=""
                           x-field-help="config.database.instance.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.database.database' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.database" x-field-errors=""
                           x-field-help="config.database.database.help"/>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.database.poolSize' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.poolSize" x-field-errors=""
                           x-field-help="config.database.poolSize.help"/>
                </div>
            </div>
        </div>

        <div class="form-group" x-ng-if="item.config.type==='postgres'">
            <label class="control-label col-sm-3">{{'config.database.ssl' | i18n}}</label>
            <div class="col-sm-7">
                <div class="input-group">
                    <select class="form-control" style="width: 50px;"
                            x-ng-model="item.config.ssl"
                            x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
                            x-field-errors=""></select>
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" uib-popover="{{'config.database.ssl.help' | i18n}}" popover-trigger="mouseenter"
                        popover-placement="right" class="btn btn-primary">?
                </button>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.database.repository' | i18n}}</label>
            <div class="col-sm-7">
                <div class="input-group">
                    <select class="form-control" style="width: 50px;"
                            x-ng-model="item.config.repository"
                            x-ng-options="o.value as (o.label | i18n) for o in ui.booleans"
                            x-field-errors=""></select>
                </div>
            </div>
            <div class="col-sm-1">
                <button type="button" uib-popover="{{'config.database.repository.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
                </button>
            </div>
        </div>
    </div>

    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.database.type.' + ui.type.type | i18n}}</span>
                </span>
            </div>
        </div>
    </div>
</form>

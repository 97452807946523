/*global tools */
var module = angular.module('meternet.chart.directives.heatmap', [
    'meternet.dashboard.constants'
]);

module.directive('heatmap', function($window, $timeout, $location, $filter, DashboardEvents, unitFilter, configService) {

    function Heatmap(svg, opt) {

        var margin = {
            top: 5,
            right: 5,
            bottom: 5,
            left: 5
        };

        var options = {
            redrawDelay: 1000,
            gridSizeX: 50,
            gridSizeY: 50
        };

        var width;
        var height;

        this.options = function (o) {
            if (!arguments.length) {
                return tools.copy(options);
            } else {
                var delay = options.redrawDelay;
                tools.copy(o, options);
                if (options.redrawDelay != delay || !this.renderDebounced) {
                    this.renderDebounced = _.debounce(this.render, options.redrawDelay, true);
                }
                return this;
            }
        };

        this.update = function (data, immediate) {
            var anyData = undefined !== _.find(data, function(d){
                return d && d.value !== undefined;
            });
            this.data = data;
            if (!anyData || immediate){
                this.render();
                this.render();
            } else {
                this.renderDebounced();
                this.renderDebounced();
            }
        };

        this.render = function () {
            svg.selectAll('.tooltipRect').data([]).exit().remove();
            svg.selectAll(".tooltipText1").data([]).exit().remove();
            svg.selectAll(".tooltipText2").data([]).exit().remove();
            //var tic = new Date().getTime();

            var chart = this;
            var i;

            width = options.width - (margin.right + margin.left);
            height = options.height - (margin.top + margin.bottom);

            if (width <= 0 || height <= 0) {
                svg.selectAll("g").remove();
                return;
            }

            var data = this.data;

            svg.attr({
                width: options.width,
                height: options.height
            });

            var canvas = svg.select("g.heatmap-canvas");
            if (canvas.empty()) {
                canvas = svg.append("g").attr("class", "heatmap-canvas");
            }
            canvas.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            var bx = 0;
            var by = 0;
            var bwidth = width;
            var bheight = height;

            if (options.backgroundWidth > 0 && options.backgroundHeight > 0) {
                var ar1 = width / height;
                var ar2 = options.backgroundWidth / options.backgroundHeight;
                if (ar1 >= ar2) {
                    bwidth = options.backgroundWidth * height / options.backgroundHeight;
                    bx = (width - bwidth) / 2;
                } else {
                    bheight = options.backgroundHeight * width / options.backgroundWidth;
                    by = (height - bheight) / 2;
                }
            }

            var area = canvas.select("g.heatmap-area");
            if (area.empty()) {
                area = canvas.append("g").attr("class", "heatmap-area");
            }

            area.attr({
                "transform": "translate(" + bx + "," + by + ")"
            });

            var bg = area.select("image.heatmap-background");
            if (options.backgroundUrl) {
                if (bg.empty()) {
                    bg = area.append("image").attr({
                        "class": "heatmap-background",
                        "xlink:xlink:href": options.backgroundUrl,
                        "preserveAspectRatio": "none",
                        "x": 0,
                        "y": 0
                    });
                }

                bg.attr({
                    "width": bwidth,
                    "height": bheight
                });
            } else {
                bg.remove();
            }

            var mg = area.select("g.heatmap-data");
            if (options.showHeatmap !== false) {
                if (mg.empty()) {
                    mg = area.append("g").attr("class", "heatmap-data");
                }

                var wr = options.gridSizeX || 50;
                var hr = options.gridSizeY || 50;

                //var dyst = wr * 40 / 100; // odleglosc punktow ktore na siebie wplywaja
                //var poteg = 4; //potega z jaka faworyzowane sa bliskie punkty
                var min = options.rangeMin != null ? options.rangeMin : d3.min(data, function (d) {
                    return d ? d.value : 999999999;
                });
                var max = options.rangeMax != null ? options.rangeMax : d3.max(data, function (d) {
                    return d ? d.value : -999999999;
                });

                var ww = bwidth / wr;
                var hh = bheight / hr;

                var rdata = [];

                if (options.series.length && min < max) {
                    //Obszar mapy ma rozdzielczosc 100 na 100 i dla kazdego punktu kolor jest wyliczany odzielnie
                    for (var px = 0; px < wr; ++px) {
                        for (var py = 0; py < hr; ++py) {
                            var w;
                            var sum;
                            var temp;
                            w = sum = temp = 0;
                            for (i = 0; i < options.series.length; i++) {
                                if (options.series[i].tempImpact && data[i] && data[i].value != null && isFinite(data[i].value)) {
                                    var posx = wr * (options.series[i].posX || 0) / 100;
                                    var posy = hr * (options.series[i].posY || 0) / 100;
                                    w = Math.pow(px - posx, 2) + Math.pow(py - posy, 2);
                                    if (w < 1) {
                                        w = 1;
                                    } else {
                                        w = 1 / Math.pow(w, 2.5);
                                    }
                                    sum += w;
                                    temp += w * data[i].value;
                                }
                            }
                            if (sum) {
                                temp /= sum;
                            }
                            var col = Math.round(colors.length * (temp - min) / (max - min)); //Obliczenie koloru punktu
                            if (col < 0) {
                                col = 0;
                            } else if (col >= colors.length) {
                                col = colors.length - 1;
                            }
                            var x = Math.round(px * ww);
                            var y = Math.round(py * hh);
                            var weight = Math.round((px + 1) * ww) - x;
                            var height = Math.round((py + 1) * hh) - y;
                            rdata.push({
                                x: x,
                                y: y,
                                width: weight,
                                height: height,
                                color: colors[col].toString()
                            });
                        }
                    }
                }

                var rect = mg.selectAll("rect").data(rdata);

                rect.enter().append("rect");

                rect.attr({
                    "x": function (d, i) {
                        return d.x;
                    },
                    "y": function (d, i) {
                        return d.y;
                    },
                    "width": function (d, i) {
                        return d.width;
                    },
                    "height": function (d, i) {
                        return d.height;
                    }
                }).style({
                    "fill": function (d, i) {
                        return d.color;
                    }
                });

                rect.exit().remove();
            } else {
                mg.remove();
            }

            var ldata = [];
            for (i = 0; i < options.series.length; ++i) {
                var s = options.series[i];
                if(!data[i]){
                    data[i]={}
                }
                ldata.push({
                    x: Math.round(bwidth * (s.posX || 0) / 100),
                    y: Math.round(bheight * (s.posY || 0) / 100),
                    index: i+1,
                    timestamp: data[i].timestamp,
                    value: data[i].value,
                    kind: s.displayKind || 'box',
                    ranges: s.zones || [],
                    unit: s.unit || '',
                    scale: s.scale || 0,
                    precision: s.precision || 0,
                    minValue: s.minValue,
                    maxValue: s.maxValue,
                    minColor: s.minColor,
                    maxColor: s.maxColor,
                    positive : s.positive || "on",
                    negative : s.negative || "off",
                    dashboardId: s.dashboardId,
                    fontSize: s.fontSize,
                    label: s.label || s.name
                });

            }
            var label = area.selectAll("g.heatmap-label").data(ldata);

            var labele = label.enter().append("g").attr("class", "heatmap-label");

            var le = labele.filter(function (d, i) {
                return d.kind === 'box' || d.kind === 'status';
            });

            le.append("rect").attr({
                "class": "heatmap-label-shape",
                "x": -32,
                "y": -15,
                "rx": "2px",
                "ry": "2px",
                "width": 64,
                "height": 20,
                "fill": "#ddd",
                "cursor": "pointer"
            });
            le.append("text").attr({
                "class": "heatmap-label-value",
                "x": -29,
                "y": -2,
                "cursor": "pointer"
            });

            label.attr({
                "transform": function (d, i) {
                    return "translate(" + d.x + "," + d.y + ")";
                }
            });
            label.select("text.heatmap-label-value")
            .text(function (d, i) {
                var label = "";
                if (d.ranges.length && isFinite(d.value)) {
                    for (var k = 0; k < d.ranges.length; ++k) {
                        var r = d.ranges[k];
                        if (d.value >= r.from && d.value <= r.to) {
                            if(r.label){
                                label = r.label;
                            }
                        }
                    }
                }
                if(d.kind ==='box'){
                    label += " ";
                    label += !((d.value) == null) ? unitFilter(d.value, d.precision, d.unit, d.scale) : "---";
                }
                return label;
            });
            label.select("text.heatmap-label-value").style({
                "stroke-width": "0.5px",
                "fill": function (d, i) {
                    d.width = this.getBBox().width;
                    d.height = this.getBBox().height;
                    var k, r;
                    if (d.ranges.length && isFinite(d.value)) {
                        for (k = 0; k < d.ranges.length; ++k) {
                            r = d.ranges[k];
                            if (d.value >= r.from && d.value <= r.to) {
                                d.fontColor= r.fontColor;
                                return r.fontColor;
                            }
                        }
                    }
                    return null;
                },
                "font-size": function(d,i){
                    return (bheight*d.fontSize)/500 + "px"
                }
            });

            label.on("mouseover", function(d) {
                var tooltipRect = svg.selectAll(".tooltipRect")
                    .attr("x", d.x )
                    .attr("y", d.y+40 )
                svg.selectAll(".tooltipText1")
                    .attr("x", d.x+10 )
                    .attr("y", d.y+55 )
                    .text(d.index + " " + d.label)
                svg.selectAll(".tooltipText2")
                    .attr("x", d.x+10 )
                    .attr("y", d.y+70 )
//                    .text(d.index + " " + d.label)
                    .text($filter('i18n')("timestamp") + ": " + $filter('date')(d.timestamp, "yyyy-MM-dd HH:mm"))
            })
            label.on("mouseout",function(d) {
                svg.selectAll(".tooltipRect").attr("y", "99999999px")
                svg.selectAll(".tooltipText1").attr("y", "99999999px")
                svg.selectAll(".tooltipText2").attr("y", "99999999px")
            })

            var toooltipRect = svg.selectAll(".tooltipRect");
            var toooltipRect = toooltipRect.data(data).enter().append('rect').attr('class', 'tooltipRect');
                toooltipRect
                    .style("fill", "#333")
                    .style("opacity", '0.7')
                    .attr("x", function(d) { return "0px"})
                    .attr("rx", 6)
                    .attr("width", "220px")
                    .attr("y", "99999999px")
                    .attr("ry", 6)
                    .attr("stroke","black")
                    .attr("stroke-width","2")
                    .attr("height", "40px")
            var toooltipText1 = svg.selectAll(".tooltipText1");
            var toooltipText1 = toooltipText1.data(data).enter().append('text').attr('class', 'tooltipText1');
                toooltipText1
                    .attr("x", function(d) { return "0px" })
                    .attr("width", "200px")
                    .attr("y", "99999999px")
                    .attr("height", "50px")
                    .style("text-anchor", "start")
                    .style("fill", "#ddd");
            var toooltipText2 = svg.selectAll(".tooltipText2");
            var toooltipText2 = toooltipText2.data(data).enter().append('text').attr('class', 'tooltipText2');
                toooltipText2
                    .attr("x", function(d) { return "0px" })
                    .attr("width", "200px")
                    .attr("y", "99999999px")
                    .attr("height", "50px")
                    .style("text-anchor", "start")
                    .style("fill", "#ddd");

            label.select("text.heatmap-label-value").attr("x", function(d){ return +2-(d.width/2);});
            label.select("text.heatmap-label-value").attr("y", function(d){ return -2+(d.height/2);});
            label.select(".heatmap-label-shape").attr("x", function(d){ return -((4+d.width)/2);});
            label.select(".heatmap-label-shape").attr("y", function(d){ return -((4+d.height)/2);});
            label.select(".heatmap-label-shape").attr("width", function(d) { return d.width+5; });
            label.select(".heatmap-label-shape").attr("height", function(d) { return d.height+5; });
            label.select(".heatmap-label-shape").style({
                "stroke-width": "0.5px",
                "fill": function (d, i) {
                    var k, r;
                    if (d.ranges.length && isFinite(d.value)) {
                        for (k = 0; k < d.ranges.length; ++k) {
                        r = d.ranges[k];
                            if (d.value >= r.from && d.value <= r.to) {
                                d.fillColor = r.color;
                                return r.color;
                            }
                        }
                    }
                    return null;
                },
                "stroke": function(d) {
                    if(d.fillColor){
                        return tools.darkerColor(d.fillColor, 30);
                    }
                    return null;
                }
            });

            label.select("text.heatmap-label-value").on("click", function(d, i){
                if (d.dashboardId) {
                    configService.get().then(function(config) {
                        var gui = config.gui;
                        var dashboard = _.find(config.gui.dashboards, function(dashboard) {
                            return dashboard.id === d.dashboardId;
                        });
                        $location.path('/dashboard/' + dashboard.name);
                    })
                }
            });
            label.select(".heatmap-label-shape").on("click", function(d, i){
                if (d.dashboardId) {
                    configService.get().then(function(config) {
                        var gui = config.gui;
                        var dashboard = _.find(config.gui.dashboards, function(dashboard) {
                            return dashboard.id === d.dashboardId;
                        });
                        $location.path('/dashboard/' + dashboard.name);
                    })
                }
            });

            label.exit().remove();
        };

        // initialization
        this.options(opt);

        //this.redraws = 0;

        var chart = this;

        var chartId = _.uniqueId('heatmap-');

        var mainDiv = d3.select(svg.node().parentNode);

        var defs = svg.append('defs').attr('class', 'heatmap-defs');

        var areaClip = defs.append("clipPath").attr("id", chartId + "-area-clip");
        areaClip.append("rect")
            .attr("x", 0)
            .attr("y", 0);

        var colors = [];
        for (var i = 0; i <= 100; i++) {
            colors.push(d3.rgb(Math.round(2.55 * i), 0x00, Math.round(255 - (2.55 * i))));
        }

    }

    return {
        restrict : 'A',
        scope : {
            options : '=',
            data : '='
        },
        link : function(scope, elem, attrs) {
            function redraw(immediate) {
                if (!scope.redrawPromise) {
                    scope.redrawPromise = $timeout(function () {
                        scope.chart.options({
                            width: elem.parent().innerWidth(),
                            height: elem.parent().innerHeight()
                        });
                        scope.chart.update(scope.data, immediate);
                        delete scope.redrawPromise;
                    });
                }
            }

            elem.addClass("heatmap");

            scope.chart = new Heatmap(d3.select(elem[0]).select('svg'), scope.options);

            scope.$on(DashboardEvents.REDRAW, function (event, data) {
                if (data){
                    scope.data = data;
                }
                redraw();
            });

            scope.$on(DashboardEvents.RESIZE, function () {
                redraw(true);
            });

            redraw(true);
        }
    };
});

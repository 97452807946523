/**
 * Created by Karol on 2015-12-01.
 */
var module = angular.module('meternet.dashboard.controllers.linkWidget', [ 'adf.provider', 'i18n',
    'meternet.dashboard.constants' ]);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl : 'dashboard/link-widget.html',
        title : messages['dashboard.widgets.linkWidget.title'],
        description : messages['dashboard.widgets.linkWidget.title'],
        controller : 'LinkWidgetCtrl',
        resolve : {
        },
        config : {
            dashboardId: null
        },
        edit : {
            controller : 'LinkWidgetEditCtrl',
            templateUrl : 'dashboard/link-widget-edit.html'
        }
    };

    dashboardProvider.widget('link-widget', widget);

});

function LinkWidgetEditCtrl($scope,configService) {

    configService.get().then(function(config) {
       var gui = config.gui;
        $scope.dashboards = gui.dashboards;
    });




}

function LinkWidgetCtrl($scope, configService, $location){

    if ($scope.config.dashboardId) {
        configService.get().then(function(config) {
            var gui = config.gui;
            var dashboard = _.find(config.gui.dashboards, function(dashboard) {
                return dashboard.id === $scope.config.dashboardId;
            });

            $scope.dashboard = dashboard;


        })
    }


    $scope.ui = {
        redirect: function(){
            if ($scope.dashboard) {
                $location.path('/dashboard/' + $scope.dashboard.name);
            }
        }
    }
}

module.controller('LinkWidgetEditCtrl', LinkWidgetEditCtrl);
module.controller('LinkWidgetCtrl', LinkWidgetCtrl);
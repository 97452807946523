var filters = angular.module('meternet.filters', [
    'meternet.config.services'
]);

filters.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];
    if (angular.isArray(items)) {
      var keys = Object.keys(props);

      items.forEach(function(item) {
        var itemMatches = false;

        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      out = items;
    }
    return out;
  };
});

filters.filter('propsFilterOnlyEnergy', function() {
  return function(items, props) {
    var out = [];
    if (angular.isArray(items)) {
      var keys = Object.keys(props);

      items.forEach(function(item) {
        var itemMatches = false;
        if(item.quantity.toLowerCase().indexOf('energy') !== -1){
            for (var i = 0; i < keys.length; i++) {
              var prop = keys[i];
              var text = props[prop].toLowerCase();
              if (item[prop] && item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                itemMatches = true;
                break;
              }
            }
        }
        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      out = items;
    }
    return out;
  };
});

filters.filter('byType', function() {
    return function(input, filter) {
        if (input) {
            var output = [];
            input.forEach(function(param) {
                filter.forEach(function(type) {
                    if (param.quantity == type.type) {
                        output.push(param);
                    }
                });
            });
            return output;
        } else {
            return null;
        }
    };
});

filters.filter('onlyTicked', function() {
    return function(input, filter) {
        if (input) {
            var output = [];
            input.forEach(function(param) {
                filter.forEach(function(type) {
                    if (param.quantity == type.type && type.ticked === true) {
                        output.push(param);
                    }
                });
            });
            return output;
        } else {
            return null;
        }
    };
});

filters.filter('findDeviceDefaultReadRateFromInterface', function() {
    return function(args) {
        var myInterf = null;
        var config = args[0];
        var deviceConfig = args[1];
        _.forEach(config.interfaces, function(interf) {
            _.forEach(interf.deviceConfigs, function(device) {
                if (device.id === deviceConfig.id) {
                    myInterf = interf;
                }
            });
        });
        return myInterf.defaultReadRate;
    };
});

filters.filter('unit', function (numberFilter) {
    var symbols = [ 'p', 'n', 'µ', 'm', '', 'k', 'M', 'G', 'T' ];
    return function(value, fract, unit, scale) {

        //wszyskie energie i moce maja byc wyswietlane domyslnie z przedrostkiem kilo, a byte z Mega.
        //if((scale === undefined || scale === null) && _.contains(["Wh", "W", "varh", "var", "VAh", "VA"], unit) ){
        //     scale=3;
        //}
        //if((scale === undefined || scale === null) && _.contains(["B"], unit) ){
        //     scale=6;
        //}

        value = parseFloat(value);

        var u;
        if (!isFinite(value)) {
            return '---';
        } else if (value == null) {
            return '';
        } else if (value === 0 && scale === null) {
            scale = 0;
        }

        if (scale !== null) {
            scale = Math.min(12, Math.max(-12, 3 * Math.floor(parseInt(scale) / 3))) || 0;
        } else {
            scale = Math.min(12, Math.max(-12, 3 * Math.floor(Math.log(Math.abs(value)) / (3 * Math.LN10))));
        }

        u = unit ? ' ' + symbols[scale / 3 + 4] + unit : '';
        if (fract >= 0) {
            fract = (fract < 7) ? fract : 0;
            return numberFilter(value / Math.pow(10, scale), fract) + u;
        } else {
            return u.trim();
        }
    };
});

filters.filter('measurement', function ($rootScope, configService, unitFilter) {
    var params = {};

    function updateParams() {
        configService.get().then(function (config) {
            params = {};
            _.each(config.engine.measurementInputs, function (input) {
                _.each(input.devices, function (device) {
                  if (device.type === "mbsat" && device.hasOwnProperty("groups")) {
                    _.each(device.groups, function (group) {
                      _.each(group.params, function (param) {
                        params[param.id] = param;
                      });
                    });
                  }
                  _.each(device.params, function (param) {
                      params[param.id] = param;
                  });
                });
            });
            _.each(config.engine.moduleInputs, function (input) {
                _.each(input.devices, function (device) {
                    _.each(device.params, function (param) {
                        params[param.id] = param;
                    });
                });
            });
        });
    }

    updateParams();

    $rootScope.$on('meternetConfigUpdate', updateParams);

    return function (measurement, precision, scale, unit) {
        var p;
        if (measurement) {
            p = params[measurement.paramId] || {
                    precision: 0,
                    scale: null,
                    unit: ""
                };
            if (precision === undefined) {
                precision = p.precision;
            }
            if (scale === undefined) {
                scale = p.scale;
            }
            if (unit === undefined) {
                unit = p.unit;
            }
            return unitFilter(measurement.value, precision, unit, scale);
        } else {
            return unitFilter(null);
        }
    }
});

filters.filter('mdTOxs', function() {
    return function(value) {
        return value.replace("md", "xs");
    };
});

filters.filter('startFrom', function() {
    return function(input, start) {
        start = +start; // parse to int
        return input.slice(start);
    };
});

filters.filter('range', function() {
    return function(input, total) {
        total = parseInt(total);
        for ( var i = 0; i < total; i++) {
            input.push(i);
        }
        return input;
    };
});

filters.filter('percent', function($filter) {
    return function(value) {
        return isNaN(value) ? '- %' : $filter('number')(value * 100, 0) + ' %';
    };
});
filters.filter('fileSize', function() {
    return function formatFileSize(value) {
        if (value > 1024 * 1024) {
            return Math.round(value / (1024 * 1024)) + " MB";
        } else if (value > 1024) {
            return Math.round(value / 1024) + " kB";
        } else {
            return Math.round(value) + " B";
        }
    };
});

filters.filter('quantityUnit', function (Quantities) {
	return function (quantity) {
		var param = (_.where(Quantities, {type: quantity}))[0];
		if (param) {
			return param.unit;
		} else {
			return "";
		}
	};
});


filters.filter('orderObjectBy', function() {
  return function(items, field, reverse) {
    var filtered = [];
    angular.forEach(items, function(item) {
      filtered.push(item);
    });
    filtered.sort(function (a, b) {
      return (a[field] > b[field] ? 1 : -1);
    });
    if(reverse) filtered.reverse();
    return filtered;
  };
});

filters.filter("htmlSafe", ['$sce', function($sce) {
    return function(htmlCode){
        return $sce.trustAsHtml(htmlCode);
    };
}]);

filters.filter('date2number', function() {
    return function(date) {
        return new Date(date).getTime();
    };
});

filters.filter('latLng', function() {
  return function (value) {
    return value.lat.toFixed(5) + ", " + value.lng.toFixed(5);
};
});

filters.filter('load', function() {
  return function (value) {
    return Math.round(value) / 1000
  };
});

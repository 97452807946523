(function (tools) {
    'use strict';

    tools.query = function (data) {
        var p, v, query = '';
        if (angular.isObject(data)) {
            for (p in data) {
                if (data.hasOwnProperty(p)) {
                    v = data[p];
                    if (v != null) {
                        query += '&' + p + '=' + v.toString();
                    }
                }
            }
        }
        return query.length > 0 ? '?' + query.substr(1) : query;
    };


    tools.List = function (opt, list) {
        opt = opt || {};
        this._list = list || [];
        this._comparator = opt.compare;
        if (this._comparator){
        	this.reorder();
        }
    };

    tools.List.prototype.size = function () {
        return this._list.length;
    };

    tools.List.prototype.getAt = function (index) {
        return this._list[index];
    };

    tools.List.prototype.indexOf = function (elem) {
        return this._list.indexOf(elem);
    };

    tools.List.prototype.each = function (iterator) {
        _.each(this._list, iterator);
    };

    tools.List.prototype.filter = function (predicate) {
        return _.filter(this._list, predicate);
    };

    tools.List.prototype.find = function (predicate) {
        return _.find(this._list, predicate);
    };

    tools.List.prototype.where = function (properties) {
        return _.where(this._list, properties);
    };

    tools.List.prototype.findWhere = function (properties) {
        return _.findWhere(this._list, properties);
    };

    tools.List.prototype.add = function (elem) {
        this._list.push(elem);
        if (this._comparator) {
            this._list.sort(this._comparator);
        }
    };

    tools.List.prototype.reorder = function () {
        if (this._comparator) {
            this._list.sort(this._comparator);
        }
    };

    tools.List.prototype.remove = function (elem) {
        for (var i = 0; i < this._list.length; ++i) {
            if (this._list[i] === elem) {
                this._list.splice(i, 1);
            }
        }
    };

    tools.List.prototype.removeAt = function (index) {
        this._list.splice(index, 1);
    };

    tools.List.prototype.array = function () {
        return this._list;
    };

    tools.List.prototype.toJSON = function () {
        return this._list;
    };


    tools.Entity = function () {
    };

    tools.Entity.update = function (data) {
        copy(data, this);
    };

    tools.Entity.hash = function () {
        return typeof(this) + "@" + (this.id ? this.id : "?");
    };


    tools.Cache = function (prefix) {
        var cache = {};

        function hash(id) {
            return prefix + id;
        }

        this.get = function (id) {
            return cache[hash(id)];
        };

        this.put = function (id, data) {
            cache[hash(id)] = data;
        };

        this.clear = function (ids) {
            if (ids === undefined) {
                cache = {};
            } else {
                for (var id in ids) {
                    delete cache[hash(ids[id])];
                }
            }
        };
    };


    function copy(src, dst) {
        var p;

        function copyElem(p, src, dst) {
            var value = src[p];
            if (value != null) {
                if (value instanceof Date || (typeof(p) === "string" && (p === "date" || p.substr(-4) === "Date"))) {
                    dst[p] = new Date(value);
                } else if (value instanceof Money || (typeof(p) === "string" && (p === "money" || p.substr(-5) === "Money"))) {
                    dst[p] = new Money(value);
                } else if (typeof(value) === 'string' || typeof(value) === 'number' || typeof(value) === 'boolean') {
                    dst[p] = value;
                } else if (value instanceof Array) {
                    dst[p] = [];
                    copy(value, dst[p]);
                } else {
                    dst[p] = {};
                    copy(value, dst[p]);
                }
            }
        }

        if (src == null) {
            return dst;
        }

        if (src instanceof Array) {
            if (!dst) {
                dst = [];
            }
            dst.length = 0;
            for (var i = 0; i < src.length; i++) {
                copyElem(i, src, dst);
            }
            return dst;
        }

        if (!dst) {
            dst = {};
        }
        for (p in src) {
            if (p[0] !== '$' && p[0] !== '_' && src.hasOwnProperty(p)) {
                copyElem(p, src, dst);
            }
        }
        return dst;
    }

    tools.copy = copy;

    tools.round = function (n, k) {
        var factor = Math.pow(10, k);
        return Math.round(n*factor)/factor;
    };

    tools.roundAmount = function(float){
        return this.round(float,2);
    };

    tools.toDate = function(dateString){
        if(!dateString){
            return undefined;
        }
        var parts = dateString.split('-');
        if(parts.length===2){
            return new Date(parts[0], parts[1]-1);
        }else{
            return  new Date(parts[0], parts[1]-1,parts[2]);
        }
    };
    tools.monthDiff = function(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months += d2.getMonth() - d1.getMonth();
        return months <= 0 ? 0 : months;
    };

    tools.darkerColor = function(color, procent) {
        var num = parseInt(color.substr(1),16),
        amt = Math.round(2.55 * -procent),
        R = (num >> 16) + amt,
        B = (num >> 8 & 0x00FF) + amt,
        G = (num & 0x0000FF) + amt;
        return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    }

    tools.timezones = [{"name":"Asia/Kabul", "utc":"+04:30"},
                                   {"name":"Europe/Mariehamn", "utc":"+03:00"},
                                   {"name":"Europe/Tirane", "utc":"+02:00"},
                                   {"name":"Africa/Algiers", "utc":"+01:00"},
                                   {"name":"Pacific/Pago_Pago", "utc":"-11:00"},
                                   {"name":"Europe/Andorra", "utc":"+02:00"},
                                   {"name":"Africa/Luanda", "utc":"+01:00"},
                                   {"name":"America/Anguilla", "utc":"-04:00"},
                                   {"name":"Antarctica/Casey", "utc":"+08:00"},
                                   {"name":"Antarctica/Davis", "utc":"+07:00"},
                                   {"name":"Antarctica/DumontDUrville", "utc":"+10:00"},
                                   {"name":"Antarctica/Mawson", "utc":"+05:00"},
                                   {"name":"Antarctica/McMurdo", "utc":"+12:00"},
                                   {"name":"Antarctica/Palmer", "utc":"-03:00"},
                                   {"name":"Antarctica/Rothera", "utc":"-03:00"},
                                   {"name":"Antarctica/Syowa", "utc":"+03:00"},
                                   {"name":"Antarctica/Troll", "utc":"+02:00"},
                                   {"name":"Antarctica/Vostok", "utc":"+06:00"},
                                   {"name":"America/Antigua", "utc":"-04:00"},
                                   {"name":"America/Argentina/Buenos_Aires", "utc":"-03:00"},
                                   {"name":"America/Argentina/Catamarca", "utc":"-03:00"},
                                   {"name":"America/Argentina/Cordoba", "utc":"-03:00"},
                                   {"name":"America/Argentina/Jujuy", "utc":"-03:00"},
                                   {"name":"America/Argentina/La_Rioja", "utc":"-03:00"},
                                   {"name":"America/Argentina/Mendoza", "utc":"-03:00"},
                                   {"name":"America/Argentina/Rio_Gallegos", "utc":"-03:00"},
                                   {"name":"America/Argentina/Salta", "utc":"-03:00"},
                                   {"name":"America/Argentina/San_Juan", "utc":"-03:00"},
                                   {"name":"America/Argentina/San_Luis", "utc":"-03:00"},
                                   {"name":"America/Argentina/Tucuman", "utc":"-03:00"},
                                   {"name":"America/Argentina/Ushuaia", "utc":"-03:00"},
                                   {"name":"Asia/Yerevan", "utc":"+04:00"},
                                   {"name":"America/Aruba", "utc":"-04:00"},
                                   {"name":"Antarctica/Macquarie", "utc":"+11:00"},
                                   {"name":"Australia/Adelaide", "utc":"+09:30"},
                                   {"name":"Australia/Brisbane", "utc":"+10:00"},
                                   {"name":"Australia/Broken_Hill", "utc":"+09:30"},
                                   {"name":"Australia/Currie", "utc":"+10:00"},
                                   {"name":"Australia/Darwin", "utc":"+09:30"},
                                   {"name":"Australia/Eucla", "utc":"+08:45"},
                                   {"name":"Australia/Hobart", "utc":"+10:00"},
                                   {"name":"Australia/Lindeman", "utc":"+10:00"},
                                   {"name":"Australia/Lord_Howe", "utc":"+10:30"},
                                   {"name":"Australia/Melbourne", "utc":"+10:00"},
                                   {"name":"Australia/Perth", "utc":"+08:00"},
                                   {"name":"Australia/Sydney", "utc":"+10:00"},
                                   {"name":"Europe/Vienna", "utc":"+02:00"},
                                   {"name":"Asia/Baku", "utc":"+04:00"},
                                   {"name":"America/Nassau", "utc":"-04:00"},
                                   {"name":"Asia/Bahrain", "utc":"+03:00"},
                                   {"name":"Asia/Dhaka", "utc":"+06:00"},
                                   {"name":"America/Barbados", "utc":"-04:00"},
                                   {"name":"Europe/Minsk", "utc":"+03:00"},
                                   {"name":"Europe/Brussels", "utc":"+02:00"},
                                   {"name":"America/Belize", "utc":"-06:00"},
                                   {"name":"Africa/Porto-Novo", "utc":"+01:00"},
                                   {"name":"Atlantic/Bermuda", "utc":"-03:00"},
                                   {"name":"Asia/Thimphu", "utc":"+06:00"},
                                   {"name":"America/La_Paz", "utc":"-04:00"},
                                   {"name":"America/Kralendijk", "utc":"-04:00"},
                                   {"name":"Europe/Sarajevo", "utc":"+02:00"},
                                   {"name":"Africa/Gaborone", "utc":"+02:00"},
                                   {"name":"America/Araguaina", "utc":"-03:00"},
                                   {"name":"America/Bahia", "utc":"-03:00"},
                                   {"name":"America/Belem", "utc":"-03:00"},
                                   {"name":"America/Boa_Vista", "utc":"-04:00"},
                                   {"name":"America/Campo_Grande", "utc":"-04:00"},
                                   {"name":"America/Cuiaba", "utc":"-04:00"},
                                   {"name":"America/Eirunepe", "utc":"-05:00"},
                                   {"name":"America/Fortaleza", "utc":"-03:00"},
                                   {"name":"America/Maceio", "utc":"-03:00"},
                                   {"name":"America/Manaus", "utc":"-04:00"},
                                   {"name":"America/Noronha", "utc":"-02:00"},
                                   {"name":"America/Porto_Velho", "utc":"-04:00"},
                                   {"name":"America/Recife", "utc":"-03:00"},
                                   {"name":"America/Rio_Branco", "utc":"-05:00"},
                                   {"name":"America/Santarem", "utc":"-03:00"},
                                   {"name":"America/Sao_Paulo", "utc":"-03:00"},
                                   {"name":"Indian/Chagos", "utc":"+06:00"},
                                   {"name":"America/Tortola", "utc":"-04:00"},
                                   {"name":"Asia/Brunei", "utc":"+08:00"},
                                   {"name":"Europe/Sofia", "utc":"+03:00"},
                                   {"name":"Africa/Ouagadougou	UTC"},
                                   {"name":"Africa/Bujumbura", "utc":"+02:00"},
                                   {"name":"Asia/Phnom_Penh", "utc":"+07:00"},
                                   {"name":"Africa/Douala", "utc":"+01:00"},
                                   {"name":"America/Atikokan", "utc":"-05:00"},
                                   {"name":"America/Blanc-Sablon", "utc":"-04:00"},
                                   {"name":"America/Cambridge_Bay", "utc":"-06:00"},
                                   {"name":"America/Creston", "utc":"-07:00"},
                                   {"name":"America/Dawson", "utc":"-07:00"},
                                   {"name":"America/Dawson_Creek", "utc":"-07:00"},
                                   {"name":"America/Edmonton", "utc":"-06:00"},
                                   {"name":"America/Fort_Nelson", "utc":"-07:00"},
                                   {"name":"America/Glace_Bay", "utc":"-03:00"},
                                   {"name":"America/Goose_Bay", "utc":"-03:00"},
                                   {"name":"America/Halifax", "utc":"-03:00"},
                                   {"name":"America/Inuvik", "utc":"-06:00"},
                                   {"name":"America/Iqaluit", "utc":"-04:00"},
                                   {"name":"America/Moncton", "utc":"-03:00"},
                                   {"name":"America/Nipigon", "utc":"-04:00"},
                                   {"name":"America/Pangnirtung", "utc":"-04:00"},
                                   {"name":"America/Rainy_River", "utc":"-05:00"},
                                   {"name":"America/Rankin_Inlet", "utc":"-05:00"},
                                   {"name":"America/Regina", "utc":"-06:00"},
                                   {"name":"America/Resolute", "utc":"-05:00"},
                                   {"name":"America/St_Johns", "utc":"-02:30"},
                                   {"name":"America/Swift_Current", "utc":"-06:00"},
                                   {"name":"America/Thunder_Bay", "utc":"-04:00"},
                                   {"name":"America/Toronto", "utc":"-04:00"},
                                   {"name":"America/Vancouver", "utc":"-07:00"},
                                   {"name":"America/Whitehorse", "utc":"-07:00"},
                                   {"name":"America/Winnipeg", "utc":"-05:00"},
                                   {"name":"America/Yellowknife", "utc":"-06:00"},
                                   {"name":"Atlantic/Cape_Verde", "utc":"-01:00"},
                                   {"name":"America/Cayman", "utc":"-05:00"},
                                   {"name":"Africa/Bangui", "utc":"+01:00"},
                                   {"name":"Africa/Ndjamena", "utc":"+01:00"},
                                   {"name":"America/Punta_Arenas", "utc":"-03:00"},
                                   {"name":"America/Santiago", "utc":"-04:00"},
                                   {"name":"Pacific/Easter", "utc":"-06:00"},
                                   {"name":"Asia/Shanghai", "utc":"+08:00"},
                                   {"name":"Asia/Urumqi", "utc":"+06:00"},
                                   {"name":"Indian/Christmas", "utc":"+07:00"},
                                   {"name":"Indian/Cocos", "utc":"+06:30"},
                                   {"name":"America/Bogota", "utc":"-05:00"},
                                   {"name":"Indian/Comoro", "utc":"+03:00"},
                                   {"name":"Pacific/Rarotonga", "utc":"-10:00"},
                                   {"name":"America/Costa_Rica", "utc":"-06:00"},
                                   {"name":"Europe/Zagreb", "utc":"+02:00"},
                                   {"name":"America/Havana", "utc":"-04:00"},
                                   {"name":"America/Curacao", "utc":"-04:00"},
                                   {"name":"Asia/Famagusta", "utc":"+03:00"},
                                   {"name":"Asia/Nicosia", "utc":"+03:00"},
                                   {"name":"Europe/Prague", "utc":"+02:00"},
                                   {"name":"Africa/Kinshasa", "utc":"+01:00"},
                                   {"name":"Africa/Lubumbashi", "utc":"+02:00"},
                                   {"name":"Europe/Copenhagen", "utc":"+02:00"},
                                   {"name":"Africa/Djibouti", "utc":"+03:00"},
                                   {"name":"America/Dominica", "utc":"-04:00"},
                                   {"name":"America/Santo_Domingo", "utc":"-04:00"},
                                   {"name":"Asia/Dili", "utc":"+09:00"},
                                   {"name":"America/Guayaquil", "utc":"-05:00"},
                                   {"name":"Pacific/Galapagos", "utc":"-06:00"},
                                   {"name":"Africa/Cairo", "utc":"+02:00"},
                                   {"name":"America/El_Salvador", "utc":"-06:00"},
                                   {"name":"Africa/Malabo", "utc":"+01:00"},
                                   {"name":"Africa/Asmara", "utc":"+03:00"},
                                   {"name":"Europe/Tallinn", "utc":"+03:00"},
                                   {"name":"Africa/Addis_Ababa", "utc":"+03:00"},
                                   {"name":"Atlantic/Stanley", "utc":"-03:00"},
                                   {"name":"Atlantic/Faroe", "utc":"+01:00"},
                                   {"name":"Pacific/Fiji", "utc":"+12:00"},
                                   {"name":"Europe/Helsinki", "utc":"+03:00"},
                                   {"name":"Europe/Paris", "utc":"+02:00"},
                                   {"name":"America/Cayenne", "utc":"-03:00"},
                                   {"name":"Pacific/Gambier", "utc":"-09:00"},
                                   {"name":"Pacific/Marquesas", "utc":"-09:30"},
                                   {"name":"Pacific/Tahiti", "utc":"-10:00"},
                                   {"name":"Indian/Kerguelen", "utc":"+05:00"},
                                   {"name":"Africa/Libreville", "utc":"+01:00"},
                                   {"name":"Africa/Banjul	UTC"},
                                   {"name":"Asia/Tbilisi", "utc":"+04:00"},
                                   {"name":"Europe/Berlin", "utc":"+02:00"},
                                   {"name":"Europe/Busingen", "utc":"+02:00"},
                                   {"name":"Africa/Accra	UTC"},
                                   {"name":"Europe/Gibraltar", "utc":"+02:00"},
                                   {"name":"Europe/Athens", "utc":"+03:00"},
                                   {"name":"America/Danmarkshavn	UTC"},
                                   {"name":"America/Godthab", "utc":"-02:00"},
                                   {"name":"America/Scoresbysund	UTC"},
                                   {"name":"America/Thule", "utc":"-03:00"},
                                   {"name":"America/Grenada", "utc":"-04:00"},
                                   {"name":"America/Guadeloupe", "utc":"-04:00"},
                                   {"name":"Pacific/Guam", "utc":"+10:00"},
                                   {"name":"America/Guatemala", "utc":"-06:00"},
                                   {"name":"Europe/Guernsey", "utc":"+01:00"},
                                   {"name":"Africa/Conakry	UTC"},
                                   {"name":"Africa/Bissau	UTC"},
                                   {"name":"America/Guyana", "utc":"-04:00"},
                                   {"name":"America/Port-au-Prince", "utc":"-04:00"},
                                   {"name":"America/Tegucigalpa", "utc":"-06:00"},
                                   {"name":"Asia/Hong_Kong", "utc":"+08:00"},
                                   {"name":"Europe/Budapest", "utc":"+02:00"},
                                   {"name":"Atlantic/Reykjavik	UTC"},
                                   {"name":"Asia/Kolkata", "utc":"+05:30"},
                                   {"name":"Asia/Jakarta", "utc":"+07:00"},
                                   {"name":"Asia/Jayapura", "utc":"+09:00"},
                                   {"name":"Asia/Makassar", "utc":"+08:00"},
                                   {"name":"Asia/Pontianak", "utc":"+07:00"},
                                   {"name":"Asia/Tehran", "utc":"+04:30"},
                                   {"name":"Asia/Baghdad", "utc":"+03:00"},
                                   {"name":"Europe/Dublin", "utc":"+01:00"},
                                   {"name":"Europe/Isle_of_Man", "utc":"+01:00"},
                                   {"name":"Asia/Jerusalem", "utc":"+03:00"},
                                   {"name":"Europe/Rome", "utc":"+02:00"},
                                   {"name":"Africa/Abidjan	UTC"},
                                   {"name":"America/Jamaica", "utc":"-05:00"},
                                   {"name":"Asia/Tokyo", "utc":"+09:00"},
                                   {"name":"Europe/Jersey", "utc":"+01:00"},
                                   {"name":"Asia/Amman", "utc":"+03:00"},
                                   {"name":"Asia/Almaty", "utc":"+06:00"},
                                   {"name":"Asia/Aqtau", "utc":"+05:00"},
                                   {"name":"Asia/Aqtobe", "utc":"+05:00"},
                                   {"name":"Asia/Atyrau", "utc":"+05:00"},
                                   {"name":"Asia/Oral", "utc":"+05:00"},
                                   {"name":"Asia/Qyzylorda", "utc":"+06:00"},
                                   {"name":"Africa/Nairobi", "utc":"+03:00"},
                                   {"name":"Pacific/Enderbury", "utc":"+13:00"},
                                   {"name":"Pacific/Kiritimati", "utc":"+14:00"},
                                   {"name":"Pacific/Tarawa", "utc":"+12:00"},
                                   {"name":"Asia/Kuwait", "utc":"+03:00"},
                                   {"name":"Asia/Bishkek", "utc":"+06:00"},
                                   {"name":"Asia/Vientiane", "utc":"+07:00"},
                                   {"name":"Europe/Riga", "utc":"+03:00"},
                                   {"name":"Asia/Beirut", "utc":"+03:00"},
                                   {"name":"Africa/Maseru", "utc":"+02:00"},
                                   {"name":"Africa/Monrovia	UTC"},
                                   {"name":"Africa/Tripoli", "utc":"+02:00"},
                                   {"name":"Europe/Vaduz", "utc":"+02:00"},
                                   {"name":"Europe/Vilnius", "utc":"+03:00"},
                                   {"name":"Europe/Luxembourg", "utc":"+02:00"},
                                   {"name":"Asia/Macau", "utc":"+08:00"},
                                   {"name":"Europe/Skopje", "utc":"+02:00"},
                                   {"name":"Indian/Antananarivo", "utc":"+03:00"},
                                   {"name":"Africa/Blantyre", "utc":"+02:00"},
                                   {"name":"Asia/Kuala_Lumpur", "utc":"+08:00"},
                                   {"name":"Asia/Kuching", "utc":"+08:00"},
                                   {"name":"Indian/Maldives", "utc":"+05:00"},
                                   {"name":"Africa/Bamako	UTC"},
                                   {"name":"Europe/Malta", "utc":"+02:00"},
                                   {"name":"Pacific/Kwajalein", "utc":"+12:00"},
                                   {"name":"Pacific/Majuro", "utc":"+12:00"},
                                   {"name":"America/Martinique", "utc":"-04:00"},
                                   {"name":"Africa/Nouakchott	UTC"},
                                   {"name":"Indian/Mauritius", "utc":"+04:00"},
                                   {"name":"Indian/Mayotte", "utc":"+03:00"},
                                   {"name":"America/Bahia_Banderas", "utc":"-05:00"},
                                   {"name":"America/Cancun", "utc":"-05:00"},
                                   {"name":"America/Chihuahua", "utc":"-06:00"},
                                   {"name":"America/Hermosillo", "utc":"-07:00"},
                                   {"name":"America/Matamoros", "utc":"-05:00"},
                                   {"name":"America/Mazatlan", "utc":"-06:00"},
                                   {"name":"America/Merida", "utc":"-05:00"},
                                   {"name":"America/Mexico_City", "utc":"-05:00"},
                                   {"name":"America/Monterrey", "utc":"-05:00"},
                                   {"name":"America/Ojinaga", "utc":"-06:00"},
                                   {"name":"America/Tijuana", "utc":"-07:00"},
                                   {"name":"Pacific/Chuuk", "utc":"+10:00"},
                                   {"name":"Pacific/Kosrae", "utc":"+11:00"},
                                   {"name":"Pacific/Pohnpei", "utc":"+11:00"},
                                   {"name":"Europe/Chisinau", "utc":"+03:00"},
                                   {"name":"Europe/Monaco", "utc":"+02:00"},
                                   {"name":"Asia/Choibalsan", "utc":"+08:00"},
                                   {"name":"Asia/Hovd", "utc":"+07:00"},
                                   {"name":"Asia/Ulaanbaatar", "utc":"+08:00"},
                                   {"name":"Europe/Podgorica", "utc":"+02:00"},
                                   {"name":"America/Montserrat", "utc":"-04:00"},
                                   {"name":"Africa/Casablanca	UTC"},
                                   {"name":"Africa/Maputo", "utc":"+02:00"},
                                   {"name":"Asia/Yangon", "utc":"+06:30"},
                                   {"name":"Africa/Windhoek", "utc":"+02:00"},
                                   {"name":"Pacific/Nauru", "utc":"+12:00"},
                                   {"name":"Asia/Kathmandu", "utc":"+05:45"},
                                   {"name":"Europe/Amsterdam", "utc":"+02:00"},
                                   {"name":"Pacific/Noumea", "utc":"+11:00"},
                                   {"name":"Pacific/Auckland", "utc":"+12:00"},
                                   {"name":"Pacific/Chatham", "utc":"+12:45"},
                                   {"name":"America/Managua", "utc":"-06:00"},
                                   {"name":"Africa/Niamey", "utc":"+01:00"},
                                   {"name":"Africa/Lagos", "utc":"+01:00"},
                                   {"name":"Pacific/Niue", "utc":"-11:00"},
                                   {"name":"Pacific/Norfolk", "utc":"+11:00"},
                                   {"name":"Asia/Pyongyang", "utc":"+09:00"},
                                   {"name":"Pacific/Saipan", "utc":"+10:00"},
                                   {"name":"Europe/Oslo", "utc":"+02:00"},
                                   {"name":"Asia/Muscat", "utc":"+04:00"},
                                   {"name":"Asia/Karachi", "utc":"+05:00"},
                                   {"name":"Pacific/Palau", "utc":"+09:00"},
                                   {"name":"Asia/Gaza", "utc":"+03:00"},
                                   {"name":"Asia/Hebron", "utc":"+03:00"},
                                   {"name":"America/Panama", "utc":"-05:00"},
                                   {"name":"Pacific/Bougainville", "utc":"+11:00"},
                                   {"name":"Pacific/Port_Moresby", "utc":"+10:00"},
                                   {"name":"America/Asuncion", "utc":"-04:00"},
                                   {"name":"America/Lima", "utc":"-05:00"},
                                   {"name":"Asia/Manila", "utc":"+08:00"},
                                   {"name":"Pacific/Pitcairn", "utc":"-08:00"},
                                   {"name":"Europe/Warsaw", "utc":"+02:00"},
                                   {"name":"Atlantic/Azores	UTC"},
                                   {"name":"Atlantic/Madeira", "utc":"+01:00"},
                                   {"name":"Europe/Lisbon", "utc":"+01:00"},
                                   {"name":"America/Puerto_Rico", "utc":"-04:00"},
                                   {"name":"Asia/Qatar", "utc":"+03:00"},
                                   {"name":"Africa/Brazzaville", "utc":"+01:00"},
                                   {"name":"Indian/Reunion", "utc":"+04:00"},
                                   {"name":"Europe/Bucharest", "utc":"+03:00"},
                                   {"name":"Asia/Anadyr", "utc":"+12:00"},
                                   {"name":"Asia/Barnaul", "utc":"+07:00"},
                                   {"name":"Asia/Chita", "utc":"+09:00"},
                                   {"name":"Asia/Irkutsk", "utc":"+08:00"},
                                   {"name":"Asia/Kamchatka", "utc":"+12:00"},
                                   {"name":"Asia/Khandyga", "utc":"+09:00"},
                                   {"name":"Asia/Krasnoyarsk", "utc":"+07:00"},
                                   {"name":"Asia/Magadan", "utc":"+11:00"},
                                   {"name":"Asia/Novokuznetsk", "utc":"+07:00"},
                                   {"name":"Asia/Novosibirsk", "utc":"+07:00"},
                                   {"name":"Asia/Omsk", "utc":"+06:00"},
                                   {"name":"Asia/Sakhalin", "utc":"+11:00"},
                                   {"name":"Asia/Srednekolymsk", "utc":"+11:00"},
                                   {"name":"Asia/Tomsk", "utc":"+07:00"},
                                   {"name":"Asia/Ust-Nera", "utc":"+10:00"},
                                   {"name":"Asia/Vladivostok", "utc":"+10:00"},
                                   {"name":"Asia/Yakutsk", "utc":"+09:00"},
                                   {"name":"Asia/Yekaterinburg", "utc":"+05:00"},
                                   {"name":"Europe/Astrakhan", "utc":"+04:00"},
                                   {"name":"Europe/Kaliningrad", "utc":"+02:00"},
                                   {"name":"Europe/Kirov", "utc":"+03:00"},
                                   {"name":"Europe/Moscow", "utc":"+03:00"},
                                   {"name":"Europe/Samara", "utc":"+04:00"},
                                   {"name":"Europe/Saratov", "utc":"+04:00"},
                                   {"name":"Europe/Simferopol", "utc":"+03:00"},
                                   {"name":"Europe/Ulyanovsk", "utc":"+04:00"},
                                   {"name":"Europe/Volgograd", "utc":"+03:00"},
                                   {"name":"Africa/Kigali", "utc":"+02:00"},
                                   {"name":"America/St_Barthelemy", "utc":"-04:00"},
                                   {"name":"Atlantic/St_Helena	UTC"},
                                   {"name":"America/St_Kitts", "utc":"-04:00"},
                                   {"name":"America/St_Lucia", "utc":"-04:00"},
                                   {"name":"America/Marigot", "utc":"-04:00"},
                                   {"name":"America/Miquelon", "utc":"-02:00"},
                                   {"name":"America/St_Vincent", "utc":"-04:00"},
                                   {"name":"Pacific/Apia", "utc":"+13:00"},
                                   {"name":"Europe/San_Marino", "utc":"+02:00"},
                                   {"name":"Africa/Sao_Tome", "utc":"+01:00"},
                                   {"name":"Asia/Riyadh", "utc":"+03:00"},
                                   {"name":"Africa/Dakar	UTC"},
                                   {"name":"Europe/Belgrade", "utc":"+02:00"},
                                   {"name":"Indian/Mahe", "utc":"+04:00"},
                                   {"name":"Africa/Freetown	UTC"},
                                   {"name":"Asia/Singapore", "utc":"+08:00"},
                                   {"name":"America/Lower_Princes", "utc":"-04:00"},
                                   {"name":"Europe/Bratislava", "utc":"+02:00"},
                                   {"name":"Europe/Ljubljana", "utc":"+02:00"},
                                   {"name":"Pacific/Guadalcanal", "utc":"+11:00"},
                                   {"name":"Africa/Mogadishu", "utc":"+03:00"},
                                   {"name":"Africa/Johannesburg", "utc":"+02:00"},
                                   {"name":"Atlantic/South_Georgia", "utc":"-02:00"},
                                   {"name":"Asia/Seoul", "utc":"+09:00"},
                                   {"name":"Africa/Juba", "utc":"+03:00"},
                                   {"name":"Africa/Ceuta", "utc":"+02:00"},
                                   {"name":"Atlantic/Canary", "utc":"+01:00"},
                                   {"name":"Europe/Madrid", "utc":"+02:00"},
                                   {"name":"Asia/Colombo", "utc":"+05:30"},
                                   {"name":"Africa/Khartoum", "utc":"+02:00"},
                                   {"name":"America/Paramaribo", "utc":"-03:00"},
                                   {"name":"Arctic/Longyearbyen", "utc":"+02:00"},
                                   {"name":"Africa/Mbabane", "utc":"+02:00"},
                                   {"name":"Europe/Stockholm", "utc":"+02:00"},
                                   {"name":"Europe/Zurich", "utc":"+02:00"},
                                   {"name":"Asia/Damascus", "utc":"+03:00"},
                                   {"name":"Asia/Taipei", "utc":"+08:00"},
                                   {"name":"Asia/Dushanbe", "utc":"+05:00"},
                                   {"name":"Africa/Dar_es_Salaam", "utc":"+03:00"},
                                   {"name":"Asia/Bangkok", "utc":"+07:00"},
                                   {"name":"Africa/Lome	UTC"},
                                   {"name":"Pacific/Fakaofo", "utc":"+13:00"},
                                   {"name":"Pacific/Tongatapu", "utc":"+13:00"},
                                   {"name":"America/Port_of_Spain", "utc":"-04:00"},
                                   {"name":"Africa/Tunis", "utc":"+01:00"},
                                   {"name":"Europe/Istanbul", "utc":"+03:00"},
                                   {"name":"Asia/Ashgabat", "utc":"+05:00"},
                                   {"name":"America/Grand_Turk", "utc":"-04:00"},
                                   {"name":"Pacific/Funafuti", "utc":"+12:00"},
                                   {"name":"America/St_Thomas", "utc":"-04:00"},
                                   {"name":"Africa/Kampala", "utc":"+03:00"},
                                   {"name":"Europe/Kiev", "utc":"+03:00"},
                                   {"name":"Europe/Uzhgorod", "utc":"+03:00"},
                                   {"name":"Europe/Zaporozhye", "utc":"+03:00"},
                                   {"name":"Asia/Dubai", "utc":"+04:00"},
                                   {"name":"Europe/London", "utc":"+01:00"},
                                   {"name":"America/Adak", "utc":"-09:00"},
                                   {"name":"America/Anchorage", "utc":"-08:00"},
                                   {"name":"America/Boise", "utc":"-06:00"},
                                   {"name":"America/Chicago", "utc":"-05:00"},
                                   {"name":"America/Denver", "utc":"-06:00"},
                                   {"name":"America/Detroit", "utc":"-04:00"},
                                   {"name":"America/Indiana/Indianapolis", "utc":"-04:00"},
                                   {"name":"America/Indiana/Knox", "utc":"-05:00"},
                                   {"name":"America/Indiana/Marengo", "utc":"-04:00"},
                                   {"name":"America/Indiana/Petersburg", "utc":"-04:00"},
                                   {"name":"America/Indiana/Tell_City", "utc":"-05:00"},
                                   {"name":"America/Indiana/Vevay", "utc":"-04:00"},
                                   {"name":"America/Indiana/Vincennes", "utc":"-04:00"},
                                   {"name":"America/Indiana/Winamac", "utc":"-04:00"},
                                   {"name":"America/Juneau", "utc":"-08:00"},
                                   {"name":"America/Kentucky/Louisville", "utc":"-04:00"},
                                   {"name":"America/Kentucky/Monticello", "utc":"-04:00"},
                                   {"name":"America/Los_Angeles", "utc":"-07:00"},
                                   {"name":"America/Menominee", "utc":"-05:00"},
                                   {"name":"America/Metlakatla", "utc":"-08:00"},
                                   {"name":"America/New_York", "utc":"-04:00"},
                                   {"name":"America/Nome", "utc":"-08:00"},
                                   {"name":"America/North_Dakota/Beulah", "utc":"-05:00"},
                                   {"name":"America/North_Dakota/Center", "utc":"-05:00"},
                                   {"name":"America/North_Dakota/New_Salem", "utc":"-05:00"},
                                   {"name":"America/Phoenix", "utc":"-07:00"},
                                   {"name":"America/Sitka", "utc":"-08:00"},
                                   {"name":"America/Yakutat", "utc":"-08:00"},
                                   {"name":"Pacific/Honolulu", "utc":"-10:00"},
                                   {"name":"Pacific/Midway", "utc":"-11:00"},
                                   {"name":"Pacific/Wake", "utc":"+12:00"},
                                   {"name":"America/Montevideo", "utc":"-03:00"},
                                   {"name":"Asia/Samarkand", "utc":"+05:00"},
                                   {"name":"Asia/Tashkent", "utc":"+05:00"},
                                   {"name":"Pacific/Efate", "utc":"+11:00"},
                                   {"name":"Europe/Vatican", "utc":"+02:00"},
                                   {"name":"America/Caracas", "utc":"-04:00"},
                                   {"name":"Asia/Ho_Chi_Minh", "utc":"+07:00"},
                                   {"name":"Pacific/Wallis", "utc":"+12:00"},
                                   {"name":"Africa/El_Aaiun	UTC"},
                                   {"name":"Asia/Aden", "utc":"+03:00"},
                                   {"name":"Africa/Lusaka", "utc":"+02:00"},
                                   {"name":"Africa/Harare", "utc":"+02:00"}];


    function Money(data) {
        var currency;
        var amount;
        if (data != null) {
            if (typeof(data) === "string" && data.length >= 4) {
                currency = data.substr(0, 3);
                amount = parseFloat(data.substr(4));
            } else if (typeof(data) === "number") {
                amount = data;
            } else if (typeof(data) === "object") {
                amount = parseFloat(data.amount);
                currency = data.currency;
            }
        }

        function isInfinity(n) {
            return n === n/0;
        }
        this.currency = typeof(currency) === "string" && currency.length === 3 ? currency : "PLN";
        this.amount = typeof(amount) === "number" && !isNaN(amount) && !isInfinity(amount) ? amount : null;
    }

	Money.prototype.toJSON = function () {
		if (this.amount == null) {
			return null;
		} else {
			return this.currency + ' ' + this.amount;
		}
	};

    tools.Money = Money;
    /**
     * Function creating `Date` from time specified as
     *  ```
     *  {
     *      type: [string],
     *      dir: [string],
     *      time: [string]
     *  }
     *  ```
     *  and object returned from SunCalc.getTimes()
     */
    tools.convertTimes = function convertTimes(time, times) {
        var newTime = {};
        Object.assign(newTime, time);
        if (time.type === 'HOURS') {
            if (typeof newTime.time == "string"){
                newTime.time = moment(newTime.time, 'HH:mm:ss').toDate();
            }
            return newTime
        }
        var shift = moment.duration(time.time);
        var tmpTime;
        if (time.type === 'DAWN_SHIFT') {
            tmpTime = moment(times.dawn);
        } else if (time.type === 'DUSK_SHIFT') {
            tmpTime = moment(times.dusk);
        } else {
            throw new Error('Unsupported schedule type!')
        }
        if (time.dir === '+') {
            tmpTime.add(shift);
        } else if (time.dir === '-') {
            tmpTime.subtract(shift);
        } else {
            throw new Error('Unsupported direction!')
        }
        newTime.time = tmpTime.toDate();
        return newTime;
    }

    tools.calculateSunTimes = function (time, lat, lon) {
        // workaround for https://github.com/mourner/suncalc/issues/11
        var day = new Date(time.getFullYear(), time.getMonth(), time.getDate(), 12, 0, 0, 0, 0);
        var times = SunCalc.getTimes(day, lat, lon);
        return times
    }

    tools.getTimesByKind = function (times, kind) {
        var dawn;
        var dusk;
        if (kind === 'civil') {
            dawn = times.dawn;
            dusk = times.dusk;
        } else if (kind === 'nautical') {
            dawn = times.nauticalDawn;
            dusk = times.nauticalDusk;
        } else if (kind === 'astronomical') {
            dawn = times.nightEnd;
            dusk = times.night;
        } else {
            throw new Error('Unknown dawn/dusk kind: ' + kind)
        }
        return {
            dawn: dawn,
            dusk: dusk
        }
    }
    tools.getActiveScheduler = function (date, schedulers) {
        date = moment(date);
        schedulers = schedulers || [];
        if (schedulers.length === 1) {
            // Special case. When there is only one scheduler allow empty from/to
            var scheduler = schedulers[0];
            if (!scheduler.from && !scheduler.to){
                return scheduler
            }
            if (!scheduler.from) {
                var to = tools.parseDateTime(scheduler.to);
                if (date.isBefore(to)) {
                    return scheduler
                }
            }
            if (!scheduler.to) {
                var from = tools.parseDateTime(scheduler.from);
                if (date.isAfter(from)){
                    return scheduler
                }
            }
            return null
        }

        // assume schedulers not overlap
        var foundScheduler;
        schedulers.forEach(function (scheduler) {
            var from = tools.parseDateTime(scheduler.from);
            var to = tools.parseDateTime(scheduler.to);
            if (date.isBetween(from, to)) {
                foundScheduler = scheduler
            }
        });
        return foundScheduler;
    };

    tools.getActiveSchedulerForCurrent = function (schedulers) {
      // TODO ws execute only on save?
      schedulers.sort(function (a, b) {
        return moment(a.from).diff(b.from);
      });
      var current = new Date();
      var foundIndex = 0;
      for (var i = 0; i < schedulers.length; i++) {
        if (current > new Date(schedulers[i].from) && current < new Date(schedulers[i].to)) {
          foundIndex = i;
          break;
        }
      }
      return foundIndex;
    }

    tools.TIME_FORMAT = "HH:mm:ss";
    tools.parseTime = function (time) {
      return moment(time, this.TIME_FORMAT).toDate()
    };
    tools.formatTime = function (time) {
      return moment(time).format(this.TIME_FORMAT)
    };

    tools.parseDateTime = function (dateTime) {
      return moment(dateTime).toDate()
    };
    tools.formatDateTime = function (dateTime) {
      return moment(dateTime).toISOString()
    };

    tools.parseGeolocation = function (device) {
        var pos = [ device.lat, device.lon]
        return pos
    }

    tools.getEffectiveGeolocation = function(group, device) {
        var g = tools.parseGeolocation(group);
        var d = tools.parseGeolocation(device);

        var lat = g[0] || d[0];
        var lon = g[1] || d[1];

        if (!lat || !lon){
            return null
        }
        return [lat, lon]
    }

})(window.tools = window.tools || {});

<form class="form-horizontal clearfix" x-errors="item.errors" x-errors-root="item.config">
    <div class="col-sm-8">
        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.name' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.name" x-field-help="config.eventInput.name.help"
                           x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.label' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.label"
                           x-field-help="config.eventInput.label.help" x-field-errors=""
                           x-field-default-value="item.config.name"/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.desc' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.desc" x-field-help="config.eventInput.desc.help"
                           x-field-errors=""></input>
                </div>
            </div>
        </div>

        <div x-ng-switch="item.config.type">

            <div x-ng-switch-when="remote">
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.host' | i18n}}</label>
                    <div class="col-sm-8">
                        <input class="form-control" x-ng-model="item.config.url" x-field-errors=""/>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.username' | i18n}}</label>
                    <div class="col-sm-8">
                        <input class="form-control" x-ng-model="item.config.username" x-field-errors=""/>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.password' | i18n}}</label>
                    <div class="col-sm-8">
                        <input type="password" class="form-control" x-ng-model="item.config.password"
                               x-field-errors=""/>
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label col-sm-3">{{'config.output' | i18n}}</label>
                    <div class="col-sm-8">
                        <input class="form-control" x-ng-model="item.config.outputName" x-field-errors=""/>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.enqueuedMeasurementsWarningLevel' | i18n}}</label>
            <div class="col-sm-8">
                <div class="input-group">
                    <input class="form-control" x-ng-model="item.config.enqueuedEventsWarningLevel"
                           x-field-help="config.eventInput.enqueuedMeasurementsWarningLevel.help" x-field-errors=""/>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label class="control-label col-sm-3">{{'config.eventOutputs' | i18n}}</label>
            <div class="col-sm-5">
                <select class="form-control"
                        x-ng-model="item.config.outputIds"
                        x-ng-options="o.id as o.name for o in config.engine.eventOutputs"
                        x-field-errors=""
                        x-multi-select=""
                        multiple="multiple"></select>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-default" style="width: 100%;" x-ng-click="ui.restoreDefaultRules()">
                    <i class="fa fa-chain"></i>&nbsp;{{'config.eventOutputs.set' | i18n}}
                </button>
            </div>
            <div class="col-sm-1">
                <button type="button" uib-popover="{{'config.eventInput.measurementOutputs.help' | i18n}}"
                        popover-trigger="mouseenter" popover-placement="right" class="btn btn-primary">?
                </button>
            </div>
        </div>
    </div>

    <div class="col-sm-4">
        <div class="thumbnail">
            <img alt="" src="" x-ng-src="{{ui.type.iconUrl}}"/>
            <div class="caption">
                <span class="caption-label selected">
                    {{'config.eventInput.type.' + ui.type.type | i18n}}</span>
                </span>
            </div>
        </div>
    </div>
</form>

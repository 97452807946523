<div style="text-align: center;">

	<div class="col-xs-12">
		<div class="form-group" style="width: 130px; min-width: 130px;">
			<label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
			<ui-select ng-model="device" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesDevice($select.selected)" style="width: 130px; min-width: 130px" >
				<ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
					{{$select.selected.label || $select.selected.name}}
				</ui-select-match>
				<ui-select-choices repeat="d in devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}" style="width: 300px; min-width: 300px;">
					<div ng-bind-html="((d.label||d.name)) | highlight: $select.search"></div>
					<div class="ui-select-choices-descr" x-ng-if="d.desc"><b>1:</b> {{d.desc}}</div>
					<div class="ui-select-choices-descr" x-ng-if="d.desc2"><b>2:</b> {{d.desc2}}</div>
					<div class="ui-select-choices-descr" x-ng-if="d.desc3"><b>3:</b> {{d.desc3}}</div>
				</ui-select-choices>
			</ui-select>
		</div>
	</div>

	<div class="col-xs-12">
		<div class="form-group" style="width: 130px; min-width: 130px;">
			<label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
			<ui-select ng-model="param" theme="bootstrap" ng-disabled="ctrl.disabled" x-ng-change="ui.updateSeriesParam($select.selected)" style="width: 130px; min-width: 130px;" >
				<ui-select-match placeholder="&#xF002; {{'config.search'|i18n}}" style="font-family: FontAwesome, Arial; width: 130px; min-width: 130px;">
					{{$select.selected.label || $select.selected.name}} [{{$select.selected.unit}}]
				</ui-select-match>
				<ui-select-choices repeat="p in device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}" style="width: 300px; min-width: 300px;">
					<div ng-bind-html="((p.label||p.name) + ' [' + p.unit + ']'  )| highlight: $select.search"></div>
					<div class="ui-select-choices-descr" x-ng-if="param.desc">{{p.desc}}</div>
				</ui-select-choices>
			</ui-select>
		</div>
	</div>


    <div class="col-xs-12 col-sm-6">
        <div class="form-group">
            <label>{{::'dashboard.widgets.diffWidget.timestampFrom' | i18n}}:</label>
            <div class="input-group">
                <input type="text" class="form-control" x-ng-model="timestampFrom" x-ng-change="ui.hideValueFun()" x-datetimepicker="{format:'YYYY-MM-DD HH:mm'}" x-ng-disabled="count<all"/>
                <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                            </span>
            </div>
        </div>
    </div>
    <div class="col-xs-12  col-sm-6">
        <div class="form-group">
            <label>{{::'dashboard.widgets.diffWidget.timestampTo' | i18n}}:</label>
            <div class="input-group">
                <input type="text" class="form-control" x-ng-model="timestampTo" x-ng-change="ui.hideValueFun()" x-datetimepicker="{format:'YYYY-MM-DD HH:mm'}" x-ng-disabled="count<all"/>
                <span class="input-group-addon">
                                <i class="fa fa-calendar"></i>
                            </span>
            </div>
        </div>
    </div>
    <div class="col-xs-12">
        <hr/>
    </div>
    <div class="col-xs-12">
        <br/>
        <div class="col-xs-6">
            <button style="position:relative; top:50%; transform:translateY(-50%);" class="btn btn-primary" x-ng-disabled="!device || !param" data-ng-click="ui.submit()">{{'dashboard.widgets.diffWidget.submit' | i18n}} {{dashboard.model.title}}</button>
        </div>
        <div class="col-xs-6">
            <button style="position:relative; top:50%; transform:translateY(-50%);" class="btn btn-primary" x-ng-disabled="!device || !param || !(measurementFrom && measurementTo && !hideValue)" data-ng-click="ui.getStartPDF()">{{'dashboard.widgets.diffWidget.download' | i18n}} {{dashboard.model.title}}</button>
        </div>
    </div>
    <div class="col-xs-12">
        <div x-ng-if="measurementFrom && measurementTo && !hideValue">
            <hr/>
            <table class="table" style="max-width:400px; margin-left: auto; margin-right: auto;">
                <thead>
                    <tr>
                        <td>{{'dashboard.widgets.diffWidget.timestamp'|i18n}}</td>
                        <td>{{'dashboard.widgets.diffWidget.value'|i18n}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align:right">{{measurementFrom.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</td>
                        <td style="text-align:left">{{ui.unitFilterFun(measurementFrom.value, param)}}</td>
                    </tr>
                    <tr>
                        <td style="text-align:right">{{measurementTo.timestamp| date : "yyyy-MM-dd HH:mm:ss"}}</td>
                        <td style="text-align:left">{{ui.unitFilterFun(measurementTo.value, param)}}</td>
                    </tr>
                    <tr>
                        <td style="text-align:right">{{'dashboard.widgets.diffWidget.diff' | i18n}}</td>
                        <td style="text-align:left">{{ui.unitFilterFun(measurementTo.value - measurementFrom.value, param)}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div x-ng-if="(!measurementFrom || !measurementTo) && !hideValue" style="color:red">
            {{'dashboard.widgets.diffWidget.error' | i18n}}
        </div>
        <br/>
    </div>
</div>

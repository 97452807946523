<fieldset>
    <div class="form-group">
        <label for="password" class="col-sm-2 col-xs-12 control-label" style="padding-right:20px;" title="{{'validation.password.pattern' | i18n}}">{{'config.user.password'| i18n}}*
        </label>

        <div class="col-sm-9 col-xs-12">
            <input id="password" name="password" class="form-control" type="password" x-ng-model="user.password1" x-ng-minlength="6"
                   x-required=""/>
        </div>
    </div>
    <div class="form-group">
        <label for="confirmPassword" class="col-sm-2 control-label" style="padding-right:20px;margin-top:-7px;"
               title="{{'validation.passwords.must.match' | i18n}}">{{'config.user.confirmPassword'| i18n}}*
        </label>

        <div class="col-sm-9 col-xs-12">
            <input id="confirmPassword" name="confirmPassword" class="form-control" type="password" x-ng-model="user.password2"
                   x-ng-minlength="6" x-required=""/>
        </div>
    </div>
</fieldset>



<div class="container">
    <div data-ng-if="currentUser.admin" class="row">
        <div class="panel panel-default">
            <div class="panel-heading"><i class="fa fa-fw fa-users"></i>&nbsp;{{'config.group.title' | i18n}}</div>
            <table class="table table-hover table-vcenter">
                <thead>
                <tr>
                    <th class="col-sm-1">{{'lp' | i18n}}</th>
                    <th class="col-sm-9">{{'config.group.groupname' | i18n}}</th>
                    <th class="col-sm-2"></th>
                </tr>
                </thead>
                <tbody>
                <tr x-ng-repeat="group in groups">
                    <td>{{$index+1}}</td>
                    <td>{{group.name}}</td>
                    <td>
                        <div class="pull-right">
                            <button class="btn btn-sm btn-info" x-ng-click="ui.editGroup(group)" title="{{'ui.edit' | i18n}}">
                                <i class="fa fa-fw fa-lg fa-edit"></i>
                            </button>
                            <button class="btn btn-sm btn-danger" x-ng-click="ui.deleteGroup(group)"
                                    x-ng-disabled="user.username == principal.username"
                                    title="{{'ui.delete' | i18n}}">
                                <i class="fa fa-fw fa-lg fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="panel-footer clearfix">
                <div class="pull-right">
                    <button class="btn btn-success" x-ng-click="ui.addGroup()" title="{{'ui.new' | i18n}}">
                        <i class="fa fa-fw fa-lg fa-plus"></i>&nbsp;{{'ui.new' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="panel panel-default">
            <div class="panel-heading"><i class="fa fa-fw fa-user"></i>&nbsp;{{'config.user.title' | i18n}}</div>
            <table class="table table-hover table-vcenter">
                <thead>
                <tr>
                    <th class="col-sm-1">{{'lp' | i18n}}</th>
                    <th class="col-sm-1">{{'config.user.username' | i18n}}</th>
                    <th class="col-sm-1">{{'config.user.enabled' | i18n}}</th>
                    <th class="col-sm-3">{{'config.user.roles' | i18n}}</th>
                    <th class="col-sm-3">{{'config.group.title' | i18n}}</th>
                    <th class="col-sm-2"></th>
                </tr>
                </thead>
                <tbody>
                <tr x-ng-repeat="user in users | usersFilter:this">
                    <td>{{$index+1}}</td>
                    <td>{{user.username}}</td>
                    <td>{{user.enabled ? ('ui.yes' | i18n) : ('ui.no' | i18n) }}</td>
                    <td><span x-ng-repeat="role in user.authorities">{{'config.user.role.' + role | i18n}} </span>
                    </td>
                    <td><span x-ng-repeat="group in user.groupsWithDetails">{{group.name+';'}}</span></td>
                    <td>
                        <div class="pull-right clearfix">
                            <button data-ng-if="currentUser.admin" class="btn btn-sm btn-info" x-ng-click="ui.edit(user)" title="{{'ui.edit' | i18n}}">
                                <i class="fa fa-fw fa-lg fa-edit"></i>
                            </button>
                            <button class="btn btn-sm btn-warning" x-ng-click="ui.setPassword(user)"
                                    title="{{'config.user.setPassword' | i18n}}">
                                <i class="fa fa-fw fa-lg fa-lock"></i>
                            </button>
                            <button data-ng-if="currentUser.admin" class="btn btn-sm btn-danger" x-ng-click="ui.deleteUser(user)"
                                    x-ng-disabled="user.username == principal.username"
                                    title="{{'ui.delete' | i18n}}">
                                <i class="fa fa-fw fa-lg fa-trash-o"></i>
                            </button>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div data-ng-if="currentUser.admin" class="panel-footer clearfix">
                <div class="pull-right">
                    <button class="btn btn-success" x-ng-click="ui.add()" title="{{'ui.new' | i18n}}">
                        <i class="fa fa-fw fa-lg fa-plus"></i>&nbsp;{{'ui.new' | i18n}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
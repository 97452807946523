/* global tools */
var module = angular.module('meternet.reportHistory.directives', [
    'meternet.services',
    'meternet.config.controllers',
    'meternet.filters'
]);

module.directive('reportHistory', function ($timeout, $parse, i18nFilter, dateFilter, numberFilter, unitFilter, configService,
                                            dataService, csvService, localStorageService, Quantities) {
    return {
        scope: {
            config: '='
        },
        templateUrl: 'report/history/report-history.html',
        controller: function ($scope, $element) {
            var lsKey = 'ui.report.history';


            $scope.index = 0;
            $scope.count = 0;
            $scope.all = 0;
            $scope.timestamp = new Date();
            $scope.timestamp.setSeconds(0);

            $scope.refresh = function() {
                if (!$scope.timestamp) {
                    $scope.timestamp = new Date();
                    $scope.timestamp.setSeconds(0);
                }
                if ($scope.table) {
                    _.each($scope.table.rows, function (row) {
                        row.measurement = null;
                        row.loading = true;
                        row.update();
                    });
                }

                $scope.enableCsv = false;

                update();
                renderTable();

            };

            function checkPhrases(phrases, row, columns) {
                var i, j, s, found;
                for (i = 0; i < phrases.length; ++i) {
                    found = false;
                    for (j = 0; j < columns.length; ++j) {
                        if (columns[j].searchable) {
                            s = row.values[j];
                            if (s && s.toLowerCase().indexOf(phrases[i]) >= 0) {
                                found = true;
                                break;
                            }
                        }
                    }
                    if (!found) {
                        return false;
                    }
                }
                return true;
            }

            function eachParam(config, handler) {
                $scope.index = 0;
                function eachDevice(device, input, handler) {
                    _.each(device.params, function (param) {
                        handler(param, null, device, input);
                    });
                    if (device.groups) {
                        _.each(device.groups, function (group) {
                            _.each(group.params, function (param) {
                                handler(param, group, device, input);
                            });
                        });
                    }
                }

                _.each(config.engine.measurementInputs, function (input) {
                    _.each(input.devices, function (device) {
                        eachDevice(device, input, handler);
                    });
                });
                _.each(config.engine.moduleInputs, function (input) {
                    _.each(input.devices, function (device) {
                        if (device.type !== "energy-report"
                            && device.type !== "prepaid"
                            && device.type !== "alarm"
                            && device.type !== "control") {
                            eachDevice(device, input, handler);
                        }
                    });
                });
            }

            function buildTable(config, phrase, quantities, columns) {
                $scope.index = 0;
                var table = {
                    rows: [],
                    map: {}
                };

                var phrases = phrase ? phrase.toLowerCase().match(/\S+/g) : null;

                eachParam(config, function (param, group, device, input) {
                    var q = _.find(quantities, function (q) {
                        return q.type === param.quantity;
                    });

                    if (!q) {
                        return;
                    }

                    $scope.index++
                    var row = {
                        index: $scope.index,
                        columns: columns,
                        device: device,
                        group: group,
                        param: param,
                        measurement: null,
                        loading: false,
                        values: null,
                        raw: null,
                        update: function () {
                            this.values = [];
                            this.raw = [];
                            if (this.columns) {
                                for (var i = 0; i < this.columns.length; ++i) {
                                    var col = this.columns[i];
                                    var value = col.getter(this);
                                    this.values.push(value != null ? value : '');
                                    var raw = col.getterRaw(this);
                                    this.raw.push(raw != null ? raw : '');
                                }
                            }
                        },
                        cssClass: function () {
                            if (this.loading) {
                                return "info";
                            } else if (this.measurement && isNaN(this.measurement.value)) {
                                return this.measurement.timestamp ? "warning" : null;
                            } else {
                                return null;
                            }
                        }
                    };

                    row.update();

                    if (phrases && !checkPhrases(phrases, row, columns)) {
                        return;
                    }

                    table.rows.push(row);
                    table.map[row.param.id] = row;
                });

                return table;
            }

            function update() {
                $scope.count = 0;
                $scope.all = 0;
                var table = buildTable($scope.config, $scope.search, $scope.quantities, $scope.columns);

                var timestamp = $scope.timestamp;
                var tolerance = $scope.tolerance;

                if (timestamp) {
                    var timestampMin = new Date(timestamp.getTime() - tolerance);
                    var timestampMax = new Date(timestamp.getTime() + tolerance);
                    var req = {};
                    req.timeFrom = timestampMin
                    req.timeTo = timestampMax
                    _.each(table.map, function (row, id) {
                        $scope.all++;
                        var p = $scope.table ? $scope.table.map[id] : null;
                        if (p && !p.loading) {
                            row.measurement = p.measurement;
                            row.loading = false;
                            row.error = p.error;
                            row.update();
                        } else {
                            row.loading = true;
                            row.error = false;

                            req.deviceId = row.device.id;
                            req.paramId = row.param.id;

                            dataService.requestParamData(id, req).then(function (measurements) {
                                $scope.count++;
                                var i, m;
                                if (measurements && measurements.length && measurements.length>0) {
                                    for (i = 0; i < measurements.length; ++i) {
                                        m = measurements[i];
                                        if ( m.value != null && isFinite(m.value) ) {
                                            if ( !row.measurement || Math.abs(m.timestamp-timestamp) < Math.abs(row.measurement.timestamp-timestamp) ){
                                                row.measurement = m;
                                            }
                                        }
                                    }
                                    if (!row.measurement) {
                                        for (i = 0; i < measurements.length; ++i) {
                                            m = measurements[i];
                                            if ( !row.measurement || Math.abs(m.timestamp-timestamp) < Math.abs(row.measurement.timestamp-timestamp) ){
                                                row.measurement = m;
                                            }
                                        }
                                    }
                                }else if (measurements && measurements.paramId){
                                    m = measurements;
                                    if (m.value != null && isFinite(m.value) && m.timestamp >= timestampMin && m.timestamp <= timestampMax) {
                                        row.measurement = m;
                                    }
                                }else{
                                }
                                if (!row.measurement) {
                                    row.measurement = {
                                        value: NaN
                                    }
                                }
                                row.loading = false;
                                row.update();
                                renderDelayed();
                                $scope.enableCsv = true;
                            }, function () {
                            }, function () {
                                row.loading = false;
                                row.measurement = {
                                    value: NaN
                                };
                                row.update();
                                renderDelayed();
                                $scope.enableCsv = true;
                            });
                        }
                    });
                }
                $scope.table = table;
                renderTable();
            }

            function reset() {
                var table = buildTable($scope.config, $scope.search, $scope.quantities, $scope.columns);
                $scope.table = table;
                renderTable();
            }


            function renderTable() {
                var i;
                var rows = [];

                var sort = Math.abs($scope.order) - 1;
                var dir = $scope.order > 0 ? 1 : -1;
                if ($scope.table){
                    $scope.table.rows.sort(function (r1, r2) {
                        var v1 = r1.raw[sort];
                        var v2 = r2.raw[sort];
                        if (v1 === v2) {
                            return r2.index - r1.index;
                        }else if (r1.columns[sort].numerical && !(!isNaN(parseFloat(v1)) && isFinite(v1))){
                            return dir;
                        }else if (r1.columns[sort].numerical &&!(!isNaN(parseFloat(v2)) && isFinite(v2))){
                            return -dir;
                        }else if (v1 < v2) {
                            return -dir;
                        } else {
                            return dir;
                        }
                    });

                    for (i = ($scope.page - 1) * $scope.limit; i < Math.min($scope.table.rows.length, $scope.page * $scope.limit); ++i) {
                        rows.push($scope.table.rows[i]);
                    }
                }

                var wsum = 0;
                for (i = 0; i < $scope.columns.length; ++i) {
                    wsum += $scope.columns[i].width;
                }

                var head = d3.select($element.find('table.report-table thead tr')[0]);
                var th = head.selectAll('th').data($scope.columns);

                th.enter().append('th').on('click.order', function (d, i) {
                    if ($scope.order === i + 1) {
                        $scope.order = -(i + 1);
                    } else {
                        $scope.order = i + 1;
                    }
                    $scope.page = 1;
                    $scope.$apply();
                    renderTable();
                }).append('a');

                th.exit().remove();
                th.order();

                th.style({
                    'width': function (d) {
                        return (100 * d.width / wsum) + '%';
                    }
                }).select('a').html(function (d, i) {
                    if (i === sort) {
                        return d.label + (dir > 0 ? '&nbsp;<i class="fa fa-caret-down"></i>' : '&nbsp;<i class="fa fa-caret-up"></i>');
                    } else {
                        return d.label;
                    }
                });

                var body = d3.select($element.find('table.report-table tbody')[0]);
                var tr = body.selectAll('tr').data(rows, function (d) {
                    return d.param.id;
                });

                tr.enter().append('tr');
                tr.exit().remove();

                tr.attr("class", function (d) {
                    return d.cssClass();
                });
                tr.order();

                var td = tr.selectAll('td').data(function (d) {
                    return d.values;
                });

                td.enter().append('td');
                td.exit().remove();

                td.text(function (d) {
                    return d;
                }).style({
                    'text-align': function (d, i) {
                        return $scope.columns[i].numerical ? 'right' : null;
                    },
                    'white-space': function (d, i) {
                        return $scope.columns[i].numerical ? 'nowrap' : null;
                    },
                    'font-weight': function (d, i) {
                        return $scope.columns[i].bold ? 'bold' : null;
                    }
                }).classed({
                    'bold': function (d, i) {
                        return $scope.columns[i].bold ? true : null;
                    }
                });
            }

            function init() {
                configService.get().then(function (config) {
                    var used = {};
                    eachParam(config, function (param) {
                        used[param.quantity] = null;
                    });

                    $scope.ui.quantities = _.clone(_.filter(Quantities, function (quantity) {
                        return used.hasOwnProperty(quantity.type);
                    }));

                    _.each($scope.ui.quantities, function (q) {
                        q.label = i18nFilter('quantity.' + q.type);
                        q.selected = true;
                    });
                    $scope.ui.quantities = _.sortBy($scope.ui.quantities, 'label');

                    $scope.config = config;
                    load();
//                    update();
                });
            }

            function store() {
                var i, c;
                var opt = {
                    quantities: [],
                    columns: [],
                    search: $scope.search
                };
                var quantities2 = [];
                _.forEach($scope.ui.quantities, function(q){
                    var qq = _.find($scope.quantities, function (q2) {
                        return q.type === q2.type;
                    });
                    if(!qq){
                        quantities2.push(q);
                    }
                })
                for (i = 0; i < quantities2.length; ++i) {
                    opt.quantities.push(quantities2[i].type);
                }
                for (i = 0; i < $scope.ui.columns.length; ++i) {
                    c = $scope.ui.columns[i];
                    if (c.selected) {
                        opt.columns.push(i);
                    }
                }
                localStorageService.set(lsKey, opt);
            }

            function load() {
                var i, a, b;
                var opt = localStorageService.get(lsKey);
                if (opt) {
                    $scope.search = opt.search;
                    for (i = 0; i < $scope.ui.quantities.length; ++i) {
                        $scope.ui.quantities[i].selected = true;
                    }
                    for (i = 0; i < opt.quantities.length; ++i) {
                        a = opt.quantities[i];
                        b = _.find($scope.ui.quantities, function (q) {
                            return q.type === a;
                        });
                        if (b) {
                            b.selected = false;
                        }
                    }
                    for (i = 0; i < $scope.ui.columns.length; ++i) {
                        $scope.ui.columns[i].selected = opt.columns.indexOf(i) >= 0;
                    }
                }
            }

            $scope.ui = {
                quantities: null,
                columns: [{
                    label: i18nFilter('lp'),
                    getter: $parse('index'),
                    getterRaw: $parse('index'),
                    searchable: false,
                    selected: false,
                    numerical: true,
                    width: 2
                }, {
                    label: i18nFilter('report.table.deviceName'),
                    getter: $parse('device.label || device.name'),
                    getterRaw: $parse('device.label || device.name'),
                    searchable: true,
                    selected: true,
                    bold: true,
                    width: 10
//                }, {
//                    label: i18nFilter('report.table.groupName'),
//                    getter: $parse('group.label || group.name'),
//                    getterRaw: $parse('group.label || group.name'),
//                    searchable: true,
//                    selected: true,
//                    bold: true,
//                    width: 10
                }, {
                    label: i18nFilter('config.desc1'),
                    getter: $parse('device.desc'),
                    getterRaw: $parse('device.desc'),
                    searchable: true,
                    selected: false,
                    width: 10
                }, {
                    label: i18nFilter('config.desc2'),
                    getter: $parse('device.desc2'),
                    getterRaw: $parse('device.desc2'),
                    searchable: true,
                    selected: false,
                    width: 10
                }, {
                    label: i18nFilter('config.desc3'),
                    getter: $parse('device.desc3'),
                    getterRaw: $parse('device.desc3'),
                    searchable: true,
                    selected: false,
                    width: 10
                }, {
                    label: i18nFilter('report.table.paramName'),
                    getter: $parse('param.label || param.name'),
                    getterRaw: $parse('param.label || param.name'),
                    searchable: true,
                    selected: true,
                    bold: true,
                    width: 10
                }, {
                    label: i18nFilter('report.table.paramDesc'),
                    getter: $parse('param.desc'),
                    getterRaw: $parse('param.desc'),
                    searchable: true,
                    selected: false,
                    width: 10
                }, {
                    label: i18nFilter('report.table.value'),
                    getter: $parse('measurement | measurement'),
                    getterRaw: $parse('measurement.value'),
                    searchable: false,
                    selected: true,
                    numerical: true,
                    bold: true,
                    width: 5
                }, {
                    label: i18nFilter('report.table.timestamp'),
                    getter: $parse('measurement.timestamp | date:\'yyyy-MM-dd HH:mm:ss\''),
                    getterRaw: $parse('measurement.timestamp | date:\'yyyy-MM-dd HH:mm:ss\''),
                    searchable: false,
                    selected: true,
                    numerical: true,
                    width: 5
                }],
                quantityTranslations: {
                    selectAll: i18nFilter('ui.tick.all'),
                    selectNone: i18nFilter('ui.tick.none'),
                    reset: i18nFilter('ui.reset'),
                    search: i18nFilter('ui.search'),
                    nothingSelected: i18nFilter('report.table.select.type')
                },
                columnTranslations: {
                    selectAll: i18nFilter('ui.tick.all'),
                    selectNone: i18nFilter('ui.tick.none'),
                    reset: i18nFilter('ui.reset'),
                    search: i18nFilter('ui.search'),
                    nothingSelected: i18nFilter('report.table.select.column')
                },
                tolerances: [{
//                    value: 15000,
//                    label: '- 15 sec'
//                }, {
                    value: 60000,
                    label: '- 1 min'
                }, {
                    value: 300000,
                    label: '- 5 min'
                }, {
                    value: 900000,
                    label: '- 15 min'
                }, {
                    value: 3600000,
                    label: '- 60 min'
                }, {
                    value: 86400000,
                    label: '- 1 ' + i18nFilter('day')
                }]
            };

            $scope.tolerance = 60000;
            $scope.columns = [];
            $scope.quantities = [];
            $scope.order = 1;
            $scope.page = 1;
            $scope.limit = 100;

            init();

            var resetDelayed = _.debounce(reset, 100);
            var updateDelayed = _.debounce(update, 100);
            var renderDelayed = _.debounce(renderTable, 100);

            $scope.$watch('columns', function (nval, oval) {
                if (nval !== oval) {
                    resetDelayed();
                }
            });
            $scope.$watch('quantities', function (nval, oval) {
                if (nval !== oval) {
                    $scope.page = 1;
                    resetDelayed();
                }
            });
            $scope.$watch('search', function (nval, oval) {
                if (nval !== oval) {
                    $scope.page = 1;
                    resetDelayed();
                }
            });
            $scope.$watch('page', function (nval, oval) {
                if (nval !== oval) {
                    renderDelayed();
                }
            });

            var generateCsv = function (rows) {
                var csv = csvService.csv();
                if($scope.ui.columns[0].selected){
                    csv.field(i18nFilter('lp'));
                }
                if($scope.ui.columns[1].selected){
                    csv.field(i18nFilter('report.table.deviceName'));
                }
//                if($scope.ui.columns[2].selected){
//                    csv.field(i18nFilter('report.table.groupName'));
//                }
                if($scope.ui.columns[2].selected){
                    csv.field(i18nFilter('config.desc1'));
                }
                if($scope.ui.columns[3].selected){
                    csv.field(i18nFilter('config.desc2'));
                }
                if($scope.ui.columns[4].selected){
                    csv.field(i18nFilter('config.desc3'));
                }
                if($scope.ui.columns[5].selected){
                    csv.field(i18nFilter('report.table.paramName'));
                }
                if($scope.ui.columns[6].selected){
                    csv.field(i18nFilter('report.table.paramDesc'));
                }
                if($scope.ui.columns[7].selected){
                    csv.field(i18nFilter('report.table.value'));
                    csv.field(i18nFilter('report.table.unit'));
                }
                if($scope.ui.columns[8].selected){
                    csv.field(i18nFilter('report.table.timestamp'));
                }

                csv.endLine();

                for (var r = 0; r < rows.length; ++r) {
                    var row = rows[r];

                    if($scope.ui.columns[0].selected){
                        csv.field(r + 1);
                    }
                    if($scope.ui.columns[1].selected){
                        csv.field(row.device.label || row.device.name);
                    }
//                    if($scope.ui.columns[2].selected){
//                        csv.field(row.group ? (row.group.label || row.group.name) : "---");
//                    }
                    if($scope.ui.columns[2].selected){
                        csv.field(row.device.desc);
                    }
                    if($scope.ui.columns[3].selected){
                        csv.field(row.device.desc2);
                    }
                    if($scope.ui.columns[4].selected){
                        csv.field(row.device.desc3);
                    }
                    if($scope.ui.columns[5].selected){
                        csv.field(row.param.label || row.param.name);
                    }
                    if($scope.ui.columns[6].selected){
                        csv.field(row.param.desc);
                    }
                    if($scope.ui.columns[7].selected){
                        if (!row.measurement) {
                            csv.field(i18nFilter('report.table.nodata'));
                            csv.field('');
                        } else if (isNaN(row.measurement.value)) {
                            csv.field(i18nFilter('report.table.error'));
                            csv.field('');
                        } else {
                            var value = unitFilter(row.measurement.value, row.param.precision, row.param.unit, row.param.scale);
                            value = value.split(" ");

                            if(value.length == 1){
                                csv.field(value.shift());
                                csv.field('');
                            }else if(value.length == 2){
                                csv.field(value.shift());
                                csv.field(value.shift());
                            }else if(value.length == 3){
                                csv.field('' + value.shift() + value.shift());
                                csv.field(value.shift());
                            }else if(value.length == 4){
                                csv.field('' + value.shift() + value.shift()+ value.shift());
                                csv.field(value.shift());
                            }else if(value.length == 5){
                                csv.field('' + value.shift() + value.shift()+ value.shift()+ value.shift());
                                csv.field(value.shift());
                            }else if(value.length == 6){
                                 csv.field('' + value.shift() + value.shift()+ value.shift()+ value.shift()+ value.shift());
                                 csv.field(value.shift());
                             }
                        }
                    }
                    if($scope.ui.columns[8].selected){
                        csv.field(row.measurement && row.measurement.timestamp ? dateFilter(row.measurement.timestamp, 'yyyy-MM-dd HH:mm:ss') : '');
                    }
                    csv.endLine();
                }
                return csv;
            };

            $scope.downloadCsv = function () {
                if ($scope.enableCsv) {
                    var filename = i18nFilter('report.history.csvFileName') + '-' + dateFilter($scope.timestamp, 'yyyy-MM-dd') + ".csv";
                    var csv = generateCsv($scope.table.rows);
                    csv.download(filename);
                }
            };

                    $scope.generateXlsx = function(rows){
                        var data = [];
                        for (var r = 0; r < rows.length; ++r) {
                            var row = rows[r];
                            var ro = {};
                            var valueNum = 0;
                            if($scope.ui.columns[0].selected){
                                ro[i18nFilter('lp')] = r + 1;
                                valueNum++;
                            }
                            if($scope.ui.columns[1].selected){
                                ro[i18nFilter('report.table.deviceName')] = row.device.label || row.device.name;
                                valueNum++;
                            }
//                            if($scope.ui.columns[2].selected){
//                                ro[i18nFilter('report.table.groupName')] = row.group ? (row.group.label || row.group.name) : "---";
//                                valueNum++;
//                            }
                            if($scope.ui.columns[2].selected){
                                ro[i18nFilter('config.desc1')] = row.device.desc;
                                valueNum++;
                            }
                            if($scope.ui.columns[3].selected){
                                ro[i18nFilter('config.desc2')] = row.device.desc2;
                                valueNum++;
                            }
                            if($scope.ui.columns[4].selected){
                                ro[i18nFilter('config.desc3')] = row.device.desc3;
                                valueNum++;
                            }
                            if($scope.ui.columns[5].selected){
                                ro[i18nFilter('report.table.paramName')] = row.param.label || row.param.name;
                                valueNum++;
                            }
                            if($scope.ui.columns[6].selected){
                                ro[i18nFilter('report.table.paramDesc')] = row.param.desc;
                                valueNum++;
                            }
                            if($scope.ui.columns[7].selected){

                                if (!row.measurement) {
                                            ro[i18nFilter('report.table.value')] = i18nFilter('report.table.nodata');
                                            ro[i18nFilter('report.table.unit')] = '';
                                } else if (isNaN(row.measurement.value)) {
                                    ro[i18nFilter('report.table.value')] = i18nFilter('report.table.error');
                                    ro[i18nFilter('report.table.unit')] = '';
                                } else {
                                    var value = row.values[valueNum];//unitFilter(row.value, row.param.precision, row.param.unit, row.param.scale);
                                    value = value.split(" ");
                                    if(value.length == 1){
                                        ro[i18nFilter('report.table.value')] = value.shift();
                                        ro[i18nFilter('report.table.unit')] = '';
                                    }else if(value.length == 2){
                                        ro[i18nFilter('report.table.value')] = value.shift();
                                        ro[i18nFilter('report.table.unit')] = value.shift();
                                    }else if(value.length == 3){
                                        ro[i18nFilter('report.table.value')] = '' + value.shift() + value.shift();
                                        ro[i18nFilter('report.table.unit')] = value.shift();
                                    }else if(value.length == 4){
                                        ro[i18nFilter('report.table.value')] = '' + value.shift() + value.shift()+ value.shift();
                                        ro[i18nFilter('report.table.unit')] = value.shift();
                                    }else if(value.length == 5){
                                        ro[i18nFilter('report.table.value')] = '' + value.shift() + value.shift()+ value.shift()+ value.shift();
                                        ro[i18nFilter('report.table.unit')] = value.shift();
                                    }else if(value.length == 6){
                                        ro[i18nFilter('report.table.value')] = '' + value.shift() + value.shift()+ value.shift()+ value.shift()+ value.shift();
                                        ro[i18nFilter('report.table.unit')] = value.shift();
                                     }
                                }
                                valueNum++;
                            }
                            if($scope.ui.columns[8].selected){
                                ro[i18nFilter('report.table.timestamp')] = row.values[valueNum];
                                valueNum++;
                            }
                            data.push(ro)
                        }
                        return data;
                    }

                    $scope.downloadXlsx = function () {
                        if ($scope.enableCsv) {
                            var filename = i18nFilter('report.history.csvFileName') + '-' + dateFilter($scope.timestamp, 'yyyy-MM-dd') + ".xlsx";
                            var data= $scope.generateXlsx($scope.table.rows);
                            var ws = XLSX.utils.json_to_sheet(data);
                            var wb = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(wb, ws, dateFilter($scope.timestamp, 'yyyy-MM-dd'));
                            XLSX.writeFile(wb, filename);
                        }
                    };




        }
    };
});

function HistoryReportCtrl($scope, $filter, configService, contextPath,
			dataService, csvService, UnitScales) {


		$scope.timeDate	= new Date();
		$scope.toleration	= 60000;
		$scope.unitScales = UnitScales;
		$scope.reverse=true;
		$scope.availableParamTypes	= [];
        $scope.typeMultiselectTranslations = {
            selectAll       : i18nFilter('ui.tick.all'),
            selectNone      : i18nFilter('ui.tick.none'),
            reset           : i18nFilter('ui.reset'),
            search          : i18nFilter('ui.search')
        };
        $scope.columnMultiselectTranslations = tools.copy($scope.typeMultiselectTranslations);
        $scope.typeMultiselectTranslations.nothingSelected = i18nFilter('report.table.select.type');
        $scope.columnMultiselectTranslations.nothingSelected = i18nFilter('report.table.select.column');

        var viewName = 'historyReportTable';

        var columns = [{
            bundle : 'report.table.deviceName',
            predicate : ['device.name', 'name']
//        },{
//            bundle : 'report.table.groupName',
//            predicate : ['group.name', 'name']
        },{
            bundle : 'config.device.descr1',
            predicate: 'device.descr1'
        },{
            bundle : 'config.device.descr2',
            predicate: 'device.descr2'
        },{
            bundle : 'config.device.descr3',
            predicate: 'device.descr3'
        },{
            bundle : 'report.table.paramName',
            predicate: 'name'
        }, {
            bundle : 'report.table.timestamp',
            predicate: 'timestamp',
            filter: "date:YYYY-MM-DD HH:mm:ss"
        }
        ];

        var initUi = function(){
            var defaultUi = {
                predicate : 'quantity',
                reverse: true,
                params : null,
                paramsScales: {}
            };

            $scope.ui = reportUiService.getUiData(defaultUi, viewName);
            $scope.availableColumns = reportUiService.initColumns($scope.ui.visibleColumns, columns);

            //FIXME TK: watch performance!
            $scope.$watch('ui', function(newValue){
                reportUiService.saveUiData(newValue, viewName, $scope.allParamsTypes);
            }, true);
        };
    $scope.customOrderBy = function(predicate) {
        return function(item) {
            if (Object.keys(predicate).length === 0) {
                return eval('item.device.name');
            }
            if (Array.isArray(predicate)) {
                var result = [];
                for(var i = 0; i <predicate.length; ++i) {
                    result.push(i18nFilter(eval('item.' + predicate[i])));
                }
                return result;
            }
            return i18nFilter(eval('item.' + predicate));
        }
    };

    $scope.value = function(param, column) {
        var predicate = column.predicate;
        if (column.bundle.indexOf('timestamp') > -1) {
            if (!param.timestamp) {
                return "";
            }
        }
        var result = null;
        if (Array.isArray(predicate)) {
            result = i18nFilter(eval('param.' + predicate[0]))
        } else {
            result = i18nFilter(eval('param.' + predicate))
        }
        if (column.filter) {
            var filterName;
            var arg;
            var idx = column.filter.indexOf(':');
            if (idx > -1) {
                filterName = column.filter.substring(0, idx );

                arg = column.filter.substring(idx+1, column.filter.length);
                if (filterName === 'date') {
                    if (!result) {
                        return "";
                    }
                    result = moment(new Date(result)).format(arg);
                }
            } else {
                filterName = column.filter;
            }
            var filterFn = $filter(filterName);
            result = arg ? filterFn(result, arg) : filterFn(result);
        }
        return result;
    };


        $scope.setScale = function(param, scale){
			$scope.ui.paramsScales[param.id] = scale.value;
		};

		configService
				.get()
				.then(
						function(meternetConfig) {

							var interfaces = tools.copy(meternetConfig.engine.measurementInputs);

							var allHistoryDevices = _.pluck(interfaces, 'devices');
							$scope.allHistoryDevices = $filter('flatten')(allHistoryDevices, true);

							var allHistParams = _.pluck($scope.allHistoryDevices, 'params');
							$scope.allHistParams	=  _.flatten(allHistParams, true);

							var allParamsTypes = _.pluck($scope.allHistParams,'quantity');
							$scope.allParamsTypes = _.uniq(
								_.flatten(allParamsTypes, true));

                            initUi();
                            _.forEach($scope.allParamsTypes, function(param){
                                var element = _.find($scope.ui.params, function(p){
                                    return p.type === param;
                                });
                                if (element){
                                    $scope.availableParamTypes.push({name: i18nFilter("quantity." + param), type: param, unit: param.unit, ticked: element.ticked});
                                } else {
                                    $scope.availableParamTypes.push({name: i18nFilter("quantity." + param), type: param, unit: param.unit, ticked: true});
                                }

                            });



							$scope.allHistoryDevices.forEach(function(device) {
								for ( var i = 0; i < device.params.length; i++) {
									device.params[i].device = tools.copy(device);
									delete device.params[i].device.params;
									device.params[i].value = '?';
								}
							});
						});

		$scope.fetchedAnyData = false;

}


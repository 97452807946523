/*global tools */
var module = angular.module('meternet.user.controllers', ['meternet.services']);
module.filter('usersFilter', function() {
    return function(users, scope) {
        if (users) {
            if (scope.currentUser.admin) {
                return users;
            }
            var result = [];
            for (var i = 0; i < users.length; ++i) {
                if (users[i].username === scope.currentUser.username) {
                    result.push(users[i]);
                }
            }
            return result;
        }
        return [];
    }
});

function UsersTableCtrl($scope, $http, $uibModal, configService, dataService, userService, contextPath, principal) {

    $scope.principal = principal;
    $scope.currentUser = userService.getUser();
    $scope.contextPath = contextPath;
    var reloadData = function(){
        userService.load().then(function(dto) {
            $scope.users = dto.users;
            $scope.groups = dto.groups;
        });
    };
    $scope.ui={
        add : function(){
            var user = {
                enabled: true,
                authorities : ['user']
            };
            var modal = $uibModal.open({
                templateUrl: 'user/user-modal.html',
                controller: 'userAddCtrl',
                resolve: {
                    user: function () {
                        return user;
                    },
                    groups: function () {
                        return $scope.groups;
                    }
                },
                size:'lg'
            });
            modal.result.then(function (user) {
                reloadData();
            }, function () {
            });
        },
        deleteUser : function(user){
            //TK: should we confirm?
            var modal = $uibModal.open({
                templateUrl: 'user/user-modal.html',
                controller: 'userDeleteCtrl',
                resolve: {
                    user: function () {
                        return user;
                    }
                }
            });
            modal.result.then(function () {
                var deletedIdx = _.findIndex($scope.users, function(u){
                    return u.username === user.username;
                });
                $scope.users.splice(deletedIdx, 1);
            }, function () {

            });
        },
        edit : function(user){
            var userCopy = tools.copy(user, {});

            var modal = $uibModal.open({
                templateUrl: 'user/user-modal.html',
                controller: 'userEditCtrl',
                resolve: {
                    user: function () {
                        return user;
                    },
                    groups: function () {
                        return $scope.groups;
                    }
                },
                size:'lg'
            });
            modal.result.then(function (user) {
                reloadData();
            }, function () {
                reloadData();
                user.email=null;
                user.authorities = null;
                user.enabled = null;
                user.update(userCopy);
            });
        },
        setPassword : function(user){
            var userCopy = tools.copy(user, {});
            var modal = $uibModal.open({
                templateUrl: 'user/user-modal.html',
                controller: 'userSetPasswordCtrl',
                resolve: {
                    user: function () {
                        return user;
                    }
                }
            });
            modal.result.then(function (user) {
            }, function () {
                user.update(userCopy);
            });
        },
        addGroup: function() {
            var emptyGroup = {
                "id": uuid.v1(),
                "name":"",
                "permissions": {
                    "dashboardsAllowRead":[],
                    "dashboardsDenyRead":[],
                    "dashboardsAllowWrite":[],
                    "dashboardsDenyWrite":[]
                }
            };

            var modal = $uibModal.open({
                templateUrl: 'user/add-group.html',
                controller: 'groupAddCtrl',
                resolve: {
                    group: function () {
                        return emptyGroup;
                    }
                },
                size:'lg'
            });
            modal.result.then(function (group) {
                reloadData();
            });
        },
        deleteGroup: function(group) {
            var result = _.find($scope.users, function(user) {
                return _.find(user.groups, function(innerGroup) {
                    if (innerGroup.id===group.id) {
                        return true;
                    }
                    return false;
                })
            });
            if (!result){
                userService.deleteGroup(group).then(function(response){
                    reloadData();
                });
            }
        },
        editGroup: function(group) {
            var modal = $uibModal.open({
                templateUrl: 'user/add-group.html',
                controller: 'groupEditCtrl',
                resolve: {
                    group: function () {
                        return group;
                    }
                },
                size:'lg'
            });
        }
    };

    reloadData();
}

function GroupAddCtrl($scope, userService, group, $uibModalInstance){
    $scope.group = group;
    $scope.ui = {
        modalTitle:'config.group.addModal.title',
        editMode: false,
        save: function() {
            userService.addGroup($scope.group).then(function(response){
                $uibModalInstance.close(response.data);
            })
        },
        cancel: function () {
            $uibModalInstance.dismiss();
        }

    }
}

function GroupEditCtrl($scope, group, $uibModalInstance, userService){
    $scope.group = group;
    $scope.ui = {
        modalTitle:'config.group.editModal.title',
        editMode: true,
        save: function() {
            userService.updateGroup($scope.group).then(function(response){
                $uibModalInstance.close(response.data);
            })
        },
        cancel: function () {
            $uibModalInstance.dismiss();
        }

    }

}

function UserSetPasswordCtrl($scope, $uibModalInstance, $timeout, user, userService, contextPath) {
    $scope.user = user;

    $scope.ui = {
        modalTitle: 'config.user.setPassword',
        mode: 'fa-key',
        successMsg: 'config.user.setPassword.success',
        formTemplate: 'user/user-set-password-form.html',
        save: function () {
            $scope.ui.errors = [];
            $scope.ui.locked= true;
            userService.changePassword($scope.user).then(function(response){
                $scope.ui.locked = false;
                $scope.ui.success= true;
                $timeout(function(){
                    $scope.user.password = null;
                    $scope.user.confirmPassword = null;
                    $uibModalInstance.close();
                }, 1000);
            }, function(response){
                $scope.ui.locked = false;
                $scope.user.password = null;
                $scope.user.confirmPassword = null;
                if (response.status === 406 && response.data.errors){
                    for (var i in response.data.errors){
                        $scope.ui.errors.push(response.data.errors[i].defaultMessage);
                    }
                }
            });
        },
        cancel: function () {
            $uibModalInstance.dismiss();
        }
    };
}

function UserEditCtrl($scope, $uibModalInstance, $timeout, user, userService, contextPath, availableRoles, groups) {
    $scope.user = user;
    $scope.groups = groups;

    $scope.user.admin = $scope.user.authorities.indexOf('admin') > -1;

    $scope.$watch('user.admin', function(newValue){
        var adminIdx = $scope.user.authorities.indexOf('admin');
        if (newValue && adminIdx > -1){
            return;
        }
        if (newValue){
            $scope.user.authorities.push('admin');
            return;
        }
        if ( adminIdx > -1){
            //TK: splice (-1, 1) removes elements from array (at least in chrome) :}
            $scope.user.authorities.splice(adminIdx, 1);
        }
    });

    $scope.ui = {
        modalTitle: 'config.user.editModal.title',
        mode: 'fa-gears',
        successMsg: 'config.user.editModal.success',
        formTemplate: 'user/user-edit-form.html',
        save: function () {
            $scope.ui.errors = [];
            $scope.ui.locked= true;
            userService.update($scope.user).then(function(response){
                $scope.ui.locked = false;
                $scope.ui.success= true;
                $timeout(function(){
                    $scope.user.password = null;
                    $scope.user.confirmPassword = null;
                    $uibModalInstance.close(response);
                }, 1000);
            }, function(response){
                $scope.ui.locked = false;
                $scope.user.password = null;
                $scope.user.confirmPassword = null;
                if (response.status === 406 && response.data.errors){
                    for (var i in response.data.errors){
                        $scope.ui.errors.push(response.data.errors[i].defaultMessage);
                    }
                }
            });
        },
        cancel: function () {
            $uibModalInstance.dismiss();
        }
    };
}

function UserAddCtrl($scope, $uibModalInstance, $timeout, user, userService, contextPath, availableRoles, groups) {
    $scope.user = user;
    $scope.groups = groups;
    $scope.user.admin = $scope.user.authorities.indexOf('ADMIN') > -1;

    $scope.$watch('user.admin', function(newValue){
        var adminIdx = $scope.user.authorities.indexOf('ADMIN');
        if (newValue && adminIdx > -1){
            return;
        }
        if (newValue){
            $scope.user.authorities.push('ADMIN');
            return;
        }
        if ( adminIdx > -1){
            //TK: splice (-1, 1) removes elements from array (at least in chrome) :}
            $scope.user.authorities.splice(adminIdx, 1);
        }
    });

    $scope.ui = {
        modalTitle: 'config.user.addModal.title',
        mode: 'fa-user-plus',
        successMsg: 'config.user.addModal.success',
        formTemplate: 'user/user-create-form.html',
        save: function () {

            console.log($scope.user);
            $scope.ui.errors = [];
            $scope.ui.locked= true;
            userService.create($scope.user).then(function(response){
                $scope.ui.locked = false;
                $scope.ui.success= true;
                $timeout(function(){
                    $scope.user.password = null;
                    $scope.user.confirmPassword = null;
                    $uibModalInstance.close();
                }, 1000);
            }, function(response){
                $scope.ui.locked = false;
                $scope.user.password = null;
                $scope.user.confirmPassword = null;
                if (response.status === 406 && response.data.errors){
                    for (var i in response.data.errors){
                        $scope.ui.errors.push(response.data.errors[i].defaultMessage);
                    }
                }
            });
        },
        cancel: function () {
            $uibModalInstance.dismiss();
        }
    };
}

function UserDeleteCtrl($scope, $uibModalInstance, $timeout, user, userService, contextPath) {
    $scope.user = user;

    $scope.ui = {
        modalTitle: 'config.user.deleteModal.title',
        successMsg: 'config.user.deleteModal.success',
        formTemplate: 'user/user-delete-form.html',
        save: function () {
            $scope.ui.errors = [];
            $scope.ui.locked= true;
            userService['delete']($scope.user).then(function(response){
                $scope.ui.locked = false;
                $scope.ui.success= true;
                $timeout(function(){
                    $uibModalInstance.close();
                }, 1000);
            }, function(response){
                if (response.status === 406 && response.data.errors){
                    for (var i in response.data.errors){
                        $scope.ui.errors.push(response.data.errors[i].defaultMessage);
                    }
                }
            });
        },
        cancel: function () {
            $uibModalInstance.dismiss();
        }
    };
}

module.controller('usersTableCtrl', UsersTableCtrl);
module.controller('userDeleteCtrl', UserDeleteCtrl);
module.controller('userSetPasswordCtrl', UserSetPasswordCtrl);
module.controller('userEditCtrl', UserEditCtrl);
module.controller('userAddCtrl', UserAddCtrl);
module.controller('groupAddCtrl', GroupAddCtrl);
module.controller('groupEditCtrl', GroupEditCtrl);
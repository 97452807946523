/*global exports */
var out$ = typeof(exports) !== 'undefined' && exports || window;

var unescape = out$.unescape;

var doctype = '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';

function isExternal(url) {
    return url && url.lastIndexOf('http', 0) === 0 && url.lastIndexOf(window.location.host) === -1;
}

function inlineImages(el, callback) {
    var images = el.querySelectorAll('image');
    var left = images.length;
    if (left === 0) {
        callback();
    }
    var inlineImage = function (image) {
        var href = image.getAttribute('xlink:href');
        if (href) {
            if (isExternal(href.value)) {
                return;
            }
        }
        var canvas = window.document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var img = new Image();
        href = href || image.getAttribute('href');
        img.src = href;
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            image.setAttribute("x-href", image.getAttribute("xlink:href"));
            image.setAttribute('xlink:href', canvas.toDataURL('image/png'));
            left--;
            if (left === 0) {
                callback();
            }
        };
        img.onerror = function () {
            left--;
            if (left === 0) {
                callback();
            }
        };
    };
    for (var i = 0; i < images.length; i++) {
    	inlineImage(images[i]);
    }
}

function restoreImages(el) {
    var images = el.querySelectorAll('image');
    for (var i = 0; i < images.length; i++) {
        (function (image) {
            var href = image.getAttribute('x-href');
            if (href) {
                image.setAttribute('xlink:href', href);
                image.removeAttribute('x-href');
            }
        })(images[i]);
    }
}

function styles(el, selectorRemap) {
    var css = "";
    var sheets = window.document.styleSheets;
    for (var i = 0; i < sheets.length; i++) {
        if (isExternal(sheets[i].href)) {
            continue;
        }
        if(!sheets[i].href || sheets[i].href.indexOf("theme") == -1){
            var rules = sheets[i].cssRules;
            if (rules != null) {
                for (var j = 0; j < rules.length; j++) {
                    var rule = rules[j];
                    if (typeof(rule.style) !== "undefined") {
                        var match = null;
                        try {
                            //FIXME (kb) hack
                            match = true;//el.querySelector(rule.selectorText);
                        } catch (err) {
                            //console.warn('Invalid CSS selector "' + rule.selectorText + '"', err);
                        }
                        if (match) {
                            var selector = selectorRemap ? selectorRemap(rule.selectorText) : rule.selectorText;
                            css += selector + " { " + rule.style.cssText + " }\n";
                        } else if (rule.cssText.match(/^@font-face/)) {
                            css += rule.cssText + '\n';
                        }
                    }
                }
            }
        }
    }
    return css;
}

out$.svgAsDataUri = function (el, options, cb) {
    options = options || {};
    options.scale = options.scale || 1;
    var xmlns = "http://www.w3.org/2000/xmlns/";

    inlineImages(el, function () {
        var outer = window.document.createElement("div");
        var clone = el.cloneNode(true);
        $(clone).find('.interactive').remove();
        var width, height;
        if (el.tagName === 'svg') {
            width = parseInt(clone.getAttribute('width') || clone.style.width || out$.getComputedStyle(el).getPropertyValue('width'));
            height = parseInt(clone.getAttribute('height') || clone.style.height || out$.getComputedStyle(el).getPropertyValue('height'));
        } else {
            var box = el.getBBox();
            width = box.x + box.width;
            height = box.y + box.height;
            clone.setAttribute('transform', clone.getAttribute('transform').replace(/translate\(.*?\)/, ''));

            var svg = window.document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            svg.appendChild(clone);
            clone = svg;
        }

        clone.setAttribute("version", "1.1");
        clone.setAttributeNS(xmlns, "xmlns", "http://www.w3.org/2000/svg");
        clone.setAttributeNS(xmlns, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        clone.setAttribute("width", width * options.scale);
        clone.setAttribute("height", height * options.scale);
        clone.setAttribute("viewBox", "0 0 " + width + " " + height);
        outer.appendChild(clone);

        var css = styles(el, options.selectorRemap);
        var s = window.document.createElement('style');
        s.setAttribute('type', 'text/css');
        s.innerHTML = "<![CDATA[\n" + css + "\n]]>";
        var defs = window.document.createElement('defs');
        defs.appendChild(s);
        clone.insertBefore(defs, clone.firstChild);

        var finalSvg = doctype + outer.innerHTML;
        var uri = 'data:image/svg+xml;base64,' + window.window.btoa(unescape(encodeURIComponent(finalSvg)));
        if (cb) {
            cb(uri);
        }
    });
};

out$.saveSvgAsPng = function (el, name, options) {
    options = options || {};
    out$.svgAsDataUri(el, options, function (uri) {
        var image = new Image();
        image.src = uri;
        image.onload = function () {
            var canvas = window.document.createElement('canvas');
            canvas.width = image.width;
            canvas.height = image.height;
            var context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            var a = window.document.createElement('a');
            a.download = name;
            a.href = canvas.toDataURL('image/png');

            window.document.body.appendChild(a);
            a.click();

            restoreImages(el);
        };
    });
};

var module = angular.module('meternet.dashboard.services', []);

module.service('seriesService', function(TimeDerivativeScales) {
    function SeriesService() {

        function timestampComparator(a, b) {
            return a.timestamp.getTime() - b.timestamp.getTime();
        }

        this.getTimeDerivativeUnit = function(unit, derivative) {
            if (unit) {
                // FIXME (jc) this algorithm is very naive...
                if (!derivative) {
                    return unit;
                } else {
                    var derivativeScale = _.findWhere(TimeDerivativeScales, {value : derivative});
                    if (!derivativeScale){
                        return unit;
                    }
                    if (unit.substring(unit.length - derivativeScale.label.length, unit.length) ===  derivativeScale.label){
                        return unit.substring(0, unit.length - 1);
                    } else {
                        return unit + "/" + derivativeScale.label;
                    }
                }
            }
            return null;
        };

        this.processData = function(data, timeFrom, timeTo, derivative, average) {
//console.log(data);
//console.log(derivative);
            var i, j, s, sj, d, v1, v2, t1, t2;
            var step = 1;

            if (data && data instanceof Array && data.length) {
                //data.sort(timestampComparator);
                if (timeFrom) {
                    t1 = timeFrom.getTime();
                    for (i = 0; i < data.length && data[i].timestamp.getTime() < t1; ++i){

                    }
                    if (derivative){
                        i--;
                    }
                    if (i > 0) {
                        data.splice(0, i);
                    }
                }
                var err;

                if (!derivative && average <= 1){
                    return data.slice(0);
                }

                if (derivative) {
                    step = Math.min(step, data.length);
                    d = [];
                    v1 = data[0].value;
                    t1 = data[0].timestamp.getTime();

                    for (i = step-1; i < data.length; i += step, v1 = v2, t1 = t2, err = false) {
                        s = data[i];
                        v2 = s.value;
                        t2 = s.timestamp.getTime();
                        for (j = i-step+2; j < i; j++){
                            sj = data[j];
                            v2 = s.value;
                            t2 = s.timestamp.getTime();
                            if (sj.value === null || !isFinite(sj.value)){
                                d.push({
                                    timestamp : s.timestamp,
                                    value : NaN
                                });
                                err = true;
                                break;
                            }
                        }
                        if (t1 < t2 && !err) {
                            if (v1 != null && v2 != null && isFinite(v1) && isFinite(v2)) {
                                d.push({
                                    timestamp : s.timestamp,
                                    value : derivative * (v2 - v1) / (t2 - t1),
                                    paramId:data[0].paramId
                                });
                            } else {
                                d.push({
                                    timestamp : s.timestamp,
                                    value : NaN,
                                    paramId:data[0].paramId
                                });
                            }
                        }

                    }
                    data = d;
                }
                if (average > 1){
                    step = average;
                    d = [];
                    for (i = data.length-1; i >=0; i--, err=false ) {
                        v1 = 0;
                        t1 = 0;
                        for (j = i; j > i - step; j--){
                            if (j < 0){
                                break;
                            }
                            sj = data[j];
                            if (sj.value === null || !isFinite(sj.value)){
                                d.push({
                                    timestamp : data[i].timestamp,
                                    value : NaN
                                });
                                err = true;
                                break;

                            } else {
                                v1 += sj.value;
                                t1 ++;
                            }
                        }
                        if (!err){
                            d.push({
                                timestamp : data[i].timestamp,
                                value : v1 / t1
                            });
                        }
                    }
                    d.reverse();
                    data = d;
                }
                return data;
            }
            return data.slice(0);
        };
    }

    return new SeriesService();
});

module.service('dashboardService', function($q, configService, csvService, userService, $filter, i18nFilter, unitFilter) {
    return {
        get: function (dashboardName) {
            var deferred = $q.defer();
            configService.get().then(function (meternetConfig) {
                var dashboard = _.findWhere(meternetConfig.gui.dashboards, {
                    name: dashboardName
                });
                if (dashboard) {
                    deferred.resolve(dashboard);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        add: function() {
            var deferred = $q.defer();
            configService.get().then(function (meternetConfig) {
                var c = meternetConfig.gui.dashboards.length + 1;
                var dashboard = {
                    "id": uuid.v1(),
                    "name": "dashboard" + shortid.gen(),
                    "structure": "6-6",
                    "title": i18nFilter('dashboard.title') + ' ' + c,
                    "rows": [{
                        "columns": [{
                            "width": 6,
                            "widgets": []
                        }, {
                            "width": 6,
                            "widgets": []
                        }]
                    }]
                };
                meternetConfig.gui.dashboards.push(dashboard);
                configService.save(meternetConfig).then(function () {
                    deferred.resolve(dashboard);
                }, function () {
                    deferred.reject();
                });
            }, function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        remove: function (id) {
            var deferred = $q.defer();
            configService.get().then(function (meternetConfig) {
                var idx = _.findIndex(meternetConfig.gui.dashboards, function (d) {
                    return d.id === id;
                });
                if (idx >= 0) {
                    meternetConfig.gui.dashboards.splice(idx, 1);
                    configService.save(meternetConfig).then(function () {
                        deferred.resolve();
                    }, function () {
                        deferred.reject();
                    });
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        getDashboardMenuLink : function() {
            var deferred = $q.defer();
            configService.get().then(function(meternetConfig) {
                var dashboardLink = {
                    target : 'main.dashboard',
                    title : 'menu.dashboard',
                    icon : 'fa-bars',
                    children : []
                };
                _.each(meternetConfig.gui.dashboards, function(dashboard) {
                    dashboardLink.children.push({
                        target: 'main.dashboard({dashboardName:\"' + dashboard.name + '\"})',
                        name: dashboard.title,
                        icon: 'fa-dashboard'
                    });
                });

                deferred.resolve(dashboardLink);
            });
            return deferred.promise;
        },
        getDashboards : function() {

            var deferred = $q.defer();
            configService.get().then(function(meternetConfig) {

                var mainDashboard = 'main.dashboard';

                var dashboardLink = [];
                _.each(meternetConfig.gui.dashboards, function(dashboard,i) {
                    dashboardLink.push({
                        id: i,
                        target: dashboard.name,
                        name: dashboard.title
                    });
                });

                deferred.resolve(dashboardLink);
            });
            return deferred.promise;
        },
        getDashboardsCount: function() {
            var deferred = $q.defer();
            configService.get().then(function(meternetConfig) {
                deferred.resolve(meternetConfig.gui.dashboards.length);
            });
            return deferred.promise;
        },
        isDashboardEditable: function(dashboardName) {
            return this.get(dashboardName).then(function (dashboard) {
               return userService.canEditDashboard(dashboard.id);
            });
        },
        //FIXME move to linechart
        generateCsv: function(data, series) {
            function comparator(a, b) {
                return (a.timestamp.getTime() - b.timestamp.getTime())* 100 + (a.column - b.column);
            }
            configService.get().then(function(config) {
                var csv = csvService.csv();
                csv.field($filter('i18n')('dashboard.widgets.lineChart.csv.timestamp'));
                var allData = [];
                var maxIndex = 0;
                var i,j;
                var devices = _.flatten(_.pluck(config.engine.measurementInputs, 'devices', true));
                var allDevices = [];

                for (i=0; i < data.length; ++i){
                    csv.field(series[i].name);

                    for (j=0; j< devices.length; j++){//wyszukuje uzytych liczników
                        if(devices[j].id === series[i].deviceId){
                            allDevices.push(devices[j])
                        }
                    }

                    for (j = 0; j < data[i].length; ++j){
                        allData.push(angular.extend({column : i}, data[i][j]));
                    }
                }

                //dodaje opisy licznika
                csv.endLine();
                csv.field('');
                for (i=0; i < allDevices.length; i++){
                    csv.field(allDevices[i].desc);
                }
                csv.endLine();
                csv.field('');
                for (i=0; i < allDevices.length; i++){
                    csv.field(allDevices[i].desc2);
                }
                csv.endLine();
                csv.field('');
                for (i=0; i < allDevices.length; i++){
                    csv.field(allDevices[i].desc3);
                }

                maxIndex = i;
                allData.sort(comparator);
                var timestamp;
                var currentIndex = -1;
                for (i = 0; i< allData.length; ++i) {

                    var ser = _.find(series, function(serie) {
                        return serie.paramId === allData[i].paramId;
                    });

                    if(ser && ser.axis){
                        var value = unitFilter(allData[i].value, ser.axis.precision, "", ser.axis.scale);
                    }else{
                        var value = unitFilter(allData[i].value, 0, "", 0);
                    }

                    if (timestamp && timestamp.diff(moment(allData[i].timestamp), 'seconds') === 0){
                        for (j=currentIndex+1; j < allData[i].column; j++){
                            csv.field('');
                        }
                        if (currentIndex < allData[i].column){
                            currentIndex=allData[i].column;
                            //unitFilter(allData[i].value, scope.precision, "", scope.scale)
                            csv.field(value);
                        }
                    } else {
                        timestamp = moment(allData[i].timestamp);
                        for (j = allData[i].column; j < maxIndex; j++){
                            csv.field('');
                        }
                        csv.endLine();
                        csv.field(moment(allData[i].timestamp).format("YYYY-MM-DD HH:mm:ss"));
                        currentIndex = allData[i].column;
                        for (j= 0; j < allData[i].column; j++){
                           csv.field('');
                        }
                        csv.field(value);

                    }
                }
                csv.endLine();
                csv.download($filter('i18n')('dashboard.widgets.lineChart.title') + ' '+ moment().format("YYYY-MM-DD HH:mm:ss") +'.csv');
            });
        },
        //FIXME move to linechart
        generateXlsx: function(data, series) {
            function comparator(a, b) {
                return (a.timestamp.getTime() - b.timestamp.getTime())* 100 + (a.column - b.column);
            }
            configService.get().then(function(config) {
                var ro = {};
                var allData = [];
                var dataXlsx = []
                var devices = _.flatten(_.pluck(config.engine.measurementInputs, 'devices', true));
                var allDevices = [];

                for (var i=0; i < data.length; ++i){
                    for (var j=0; j< devices.length; j++){//wyszukuje uzytych liczników
                        if(devices[j].id === series[i].deviceId){
                            allDevices.push(devices[j])
                        }
                    }
                    for (var j = 0; j < data[i].length; ++j){
                        allData.push(angular.extend({column : i}, data[i][j]));
                    }
                }
                ro[$filter('i18n')('dashboard.widgets.lineChart.csv.timestamp')]=""
                for (var i=0; i < data.length; ++i){
                    for (j=0; j< devices.length; j++){//wyszukuje uzytych liczników
                        if(devices[j].id === series[i].deviceId){
                            series[i].name2 = series[i].name + " [" + unitFilter(1, -1, series[i].unit, series[i].axis.scale) + " ]";
                            ro[series[i].name2]=allDevices[i].desc;
                        }
                    }
                }
                dataXlsx.push(ro);
                ro = {}
                ro[$filter('i18n')('dashboard.widgets.lineChart.csv.timestamp')]=""
                for (var i=0; i < data.length; ++i){
                    for (var j=0; j< devices.length; j++){//wyszukuje uzytych liczników
                        if(devices[j].id === series[i].deviceId){
                            ro[series[i].name2]=allDevices[i].desc2
                        }
                    }
                }
                dataXlsx.push(ro);
                ro = {}
                ro[$filter('i18n')('dashboard.widgets.lineChart.csv.timestamp')]=""
                for (var i=0; i < data.length; ++i){
                    for (var j=0; j< devices.length; j++){//wyszukuje uzytych liczników
                        if(devices[j].id === series[i].deviceId){
                            ro[series[i].name2]=allDevices[i].desc3
                        }
                    }
                }
                dataXlsx.push(ro);
                ro = {}
                allData.sort(comparator);
                var timestamp;
                var currentIndex = -1;
                var maxIndex = 0;

                for (var i = 0; i< allData.length; ++i) {

                    var ser = _.find(series, function(serie) {
                        return serie.paramId === allData[i].paramId;
                    });

                    if(allData[i].value){
                        var value = allData[i].value / Math.pow(10, ser.axis.scale);
                    }else{
                        var value = "---"
                    }

                    if (timestamp && timestamp.diff(moment(allData[i].timestamp), 'seconds') === 0){
                        for (var j=currentIndex+1; j < allData[i].column; j++){
                            ro[series[j].name2]=''
                        }
                        if (currentIndex < allData[i].column){
                            currentIndex=allData[i].column;
                            ro[series[currentIndex].name2]=value
                        }
                    } else {
                        timestamp = moment(allData[i].timestamp);
                        for (j = allData[i].column; j < maxIndex; j++){
                            ro[series[j].name2]=''
                        }
                        dataXlsx.push(ro);
                        ro = {}
                        ro[$filter('i18n')('dashboard.widgets.lineChart.csv.timestamp')]=moment(allData[i].timestamp).format("YYYY-MM-DD HH:mm:ss")
                        currentIndex = allData[i].column;
                        for (j= 0; j < allData[i].column; j++){
                            ro[series[j].name2]=''
                        }
                        ro[series[currentIndex].name2]=value

                    }
                }
                dataXlsx.push(ro);
                ro = {}

                var filename = $filter('i18n')('dashboard.widgets.lineChart.title') + '-' + moment().format("YYYY-MM-DD HH:mm:ss") + ".xlsx";
                var ws = XLSX.utils.json_to_sheet(dataXlsx);
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "data");
                XLSX.writeFile(wb, filename);
            });
        }
    };
});

/* global uuid */

var module = angular.module('meternet.config.directives', [
    'angular-md5',
	'meternet.services',
    'meternet.filters',
	'meternet.config.controllers',
    'meternet.config.form.directives',
    'meternet.utils.form'
]);

module.directive('configPanel', function ($q, $timeout, $compile, $templateCache, i18nFilter, configService, Errors, Objects) {

    function TreeItem(data) {
        data = data || {};
        this.name = null;
        this.config = null;
        this.visible = true;
        this.addable = false;
        this.removable = false;
        this.openable = true;
        this.opened = false;
        this.active = false;
        this.editable = true;
        this.external = false;
        this.kind = data.kind;
        this.path = data.path;
        this.parent = data.parent || null;
        this.level = data.parent ? data.parent.level + 1 : 0;
        this.number = 0;
        this.children = [];
        if (this.parent) {
            this.parent.addChild(this);
        }
        this.basic = data.basic;
        this.update(data);
    }

    TreeItem.prototype.update = function (data) {
        data = data || {};
        if (data.path != null) {
            this.path = data.path;
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.config != null) {
            this.config = data.config;
        }
        if (data.removable != null) {
            this.removable = data.removable === true;
        }
        if (data.addable != null) {
            this.addable = data.addable === true;
        }
        if (data.editable != null) {
            this.editable = data.editable === true;
        }
        if (data.openable != null) {
            this.openable = data.openable === true;
        }
        if (data.opened != null) {
            this.opened = data.opened === true;
        }
        if (data.active != null) {
            this.active = data.active === true;
        }
        if (data.visible != null) {
            this.visible = data.visible === true;
        }
        if (data.external != null) {
            this.external = data.external === true;
        }
		if (data.errorsAdditive != null) {
			this.errorsAdditive = data.errorsAdditive === true;
		}
    };

    TreeItem.prototype.getPaddingLeft = function () {
//        if(this.parent.path=="engine.measurementInputs[0]"){
//            return (2 * 16 - 6 ) + 'px';
//        }
//        else if(this.parent.path=="engine.measurementInputs[0].devices[0]"){
//            return (3 * 16 - 6 ) + 'px';
//        }
        return (this.level * 16 - 6 ) + 'px';
    };

    TreeItem.prototype.addChild = function (item) {
        item.number = this.children.length;
        this.children.push(item);
    };

    TreeItem.prototype.removeChild = function (item) {
        var i = this.children.indexOf(item);
        if (i >= 0) {
            this.children.splice(i, 1);
            for (i = 0; i < this.children.length; ++i) {
                this.children[i].number = i;
            }
        }
    };

    TreeItem.prototype.toggle = function (opened) {
        if (!arguments.length) {
            opened = this.openable && !this.opened && this.children.length > 0;
        } else {
            opened = this.openable && opened && this.children.length > 0;
        }
        this.opened = opened;
        for (var i = 0; i < this.children.length; ++i) {
            this.children[i].show(opened);
        }
    };

    TreeItem.prototype.getToggleIcon = function () {
        if (this.openable && this.children.length > 0) {
            return this.opened ? 'fa-angle-down' : 'fa-angle-right';
        } else {
            return null;
        }
    };

    TreeItem.prototype.getRoot = function () {
        if (this.parent) {
            return this.parent.getRoot();
        } else {
            return this;
        }
    };

    TreeItem.prototype.select = function () {
        function clear(item) {
            item.active = false;
//            item.opened = false;
            if (item.level > 1) {//Po zmianie na 1 pamieta wszystkie stany i tylko roota zamyka samo
                item.visible = false;
            }else{
                item.opened = false;
            }

            for (var i = 0; i < item.children.length; ++i) {
                clear(item.children[i]);
            }
        }

        if (!this.active) {
            clear(this.getRoot());
            this.active = true;
            for (var p = this; p.parent; p = p.parent) {
                p.toggle(true);
            }
        }
    };

    TreeItem.prototype.show = function (visible) {
		if (!arguments.length) {
			visible = true;
		} else {
			visible = visible === true;
		}
		if (this.visible !== visible) {
			this.visible = visible;
			for (var i = 0; i < this.children.length; ++i) {
				this.children[i].show(this.opened && visible);
			}
		}
		if (!!this.config.master) {
			this.visible = false;
		}
	};

    TreeItem.prototype.icon = function (kind) {
        kind = kind || this.kind;

        switch (kind) {
            case 'measurementInputs':
            case 'eventInputs':
                return 'fa-sign-in';

            case 'moduleInputs':
                return 'fa-puzzle-piece';

            case 'measurementInput':
            case 'moduleInput':
                if (this.config.type === 'energy-report') {
                    return 'fa-line-chart';
                }
                else if (this.config.type === 'energy-monitor') {
                    return 'fa-tv';
                }
                else if (this.config.type === 'math') {
                    return 'fa-calculator';
                }
                else if (this.config.type === 'alarm') {
                    return 'fa-bell';
                }
                else if (this.config.type === 'control') {
                    return 'fa-gamepad';
                }
                else if (this.config.type === 'camping') {
                    return 'fa-home';
                }
                else if (this.config.type === 'prepaid') {
                    return 'fa-usd';
                }
                return 'fa-sitemap';

			case 'mediaCosts':
				return 'fa-usd';

            case 'eventInput':
                if (this.config.type === 'remote') {
                    return 'fa-cloud-download';
                }
                return 'fa-sitemap';

            case 'device':
                return 'fa-cube';

            case 'group':
                return 'fa-cubes';

            case 'parameter':
                return 'fa-tachometer';

            case 'databases':
            case 'database':
                return 'fa-database';

            case 'app':
                return 'fa-tv';

            case 'notifiers':
            case 'notifier':
                return 'fa-envelope';

            case 'measurementOutputs':
            case 'eventOutputs':
                return 'fa-sign-out';

            case 'measurementOutput':
            case 'eventOutput':
                if (this.config.type === 'db') {
                    return 'fa-database';
                } else if (this.config.type === 'remote') {
                    return 'fa-cloud-upload';
                }
                return 'fa-sign-out';

            case 'eventSource':
                if (this.config.sourceType === null) {
                    return null;
                }
                return this.icon(this.config.sourceType);

            case 'options':
                return 'fa-cog';
            case 'network':
                return 'fa-wifi';

            case 'ntp':
                return 'fa-clock-o';
            case 'time':
                return 'fa-clock-o';

            case 'license':
                return 'fa-unlock-alt';

            case 'backup':
                return 'fa-undo';

            case 'firmware':
                return 'fa-flash';

            case 'storage':
                return 'fa-hdd-o';

            case 'support':
                return 'fa-medkit';

            default:
                return null;
        }
    };

    TreeItem.prototype.items = function () {
        if (!this._items) {
            var flatten = function (item, items) {
                _.each(item.children, function (item) {
                    items.push(item);
                    if (item.level > 0) {
                        flatten(item, items);
                    }
                });
            };

            this._items = [];
            if (this.level <= 1) {
                flatten(this, this._items);
            }
        }
        return this._items;
    };

    TreeItem.prototype.each = function (cb) {
        function each(item, cb) {
            cb(item);
            for (var i = 0; i < item.children.length; ++i) {
                each(item.children[i], cb);
            }
        }
        each(this, cb);
    };

    TreeItem.prototype.find = function (cb) {
        var e = null;
        function each(item, cb) {

            if (cb(item)) {
                e = item;
                return true;
            } else {
                for (var i = 0; i < item.children.length; ++i) {
                    if (each(item.children[i], cb)) {
                        return true;
                    }
                }
                return false;
            }
        }
        each(this, cb);
        return e;
    };

    TreeItem.prototype.treePath = function () {
        if (!this._treePath) {
            var p = [];
            for (var i = this; i.parent; i = i.parent) {
                p.unshift(i.number + 1);
            }
            this._treePath = p.join(",");
        }
        return this._treePath;
    };

    TreeItem.prototype.treeFind = function (path) {
        var item = this.getRoot();
        var p = path.split(",");
        for (var i = 0; i < p.length; ++i) {
            var n = parseInt(p[i]) - 1;
            if (n < item.children.length) {
                item = item.children[n];
            } else {
                return item;
            }
        }
        return item;
    };

    return {
        scope: {},
        templateUrl: 'config/config-panel.html',
        controller: function ($scope, $location, licenseService, localStorageService, advanceView, $rootScope) {
            var checkPromise = null;
            var checkCount = 0;

            function checkConfig(now) {
                var count = ++checkCount;
                if (checkPromise) {
                    $timeout.cancel(checkPromise);
                }
                checkPromise = $timeout(function () {
                    configService.check($scope.config).then(function (result) {
                        if (count === checkCount && result.config && result.errors && result.usage) {
                            $scope.config = result.config;
                            $scope.errors = new Errors(result.errors);
                            $scope.usage = result.usage;
                            $scope.ui.dirty = true;
                            $scope.ui.added = false;
                        }
                    });
                    checkPromise = null;
                }, now ? 0 : 500);
            }

            $scope.license = licenseService.getLicense();
            $scope.usage = $scope.license.usage;
            $scope.ui = {
                liveValidation: localStorageService.get('config.liveValidation') || false,
                processing: false,
                advanceView: advanceView.value,
                fluidView: false,
                dirty: false,
                view: 'edit',
                advanceViewControl: function(){
                    advanceView.toggle();
                    $scope.ui.advanceView=advanceView.value;
                },
                fluidViewControl: function(){
                    $scope.ui.fluidView=!$scope.ui.fluidView;
                },
                refresh: function () {
                    var root = this.root(true);
                    var item = $scope.item;
                    if (this._itemId) {
                        var id = this._itemId;
                        this._itemId = null;
                        item = root.find(function (i) {
                            return i.config.id === id;
                        });
                    }
                    var p = null;
                    if (item) {
                        p = root.find(function (i) {
                            return i.path === item.parent.path;
                        });
                    }
                    if (p) {
                        if (p.children.length) {
                            p = p.children[p.children.length > item.number ? item.number : p.children.length - 1];
                        }
                    } else {
                        p = root.treeFind($location.hash());
                        if (p === root) {
                            p = root.items()[0];
                        }
                    }
                    this.select(p);
                },
                select: function (item) {
                    if (item !== $scope.item) {
                        item.select();
                        $scope.item = item;
                        this.view = item.editable ? 'edit' : 'add';
                        this.options = null;
                        $location.hash(item.treePath());
                    }
                },
                remove: function (item) {
					if (item.kind === "measurementOutput") {
						for (var k = 0; k < $scope.config.engine.measurementInputs.length; k++) {
							var m = $scope.config.engine.measurementInputs[k]
							if (m.outputIds) {
								var index = m.outputIds.indexOf(item.config.id)
								if (index > -1) {
									m.outputIds.splice(index, 1);
								}
							}
							for (var i = 0; i < m.devices.length; i++) {
								var d = m.devices[i];
								if (d.outputIds) {
									var index = d.outputIds.indexOf(item.config.id)
									if (index > -1) {
										d.outputIds.splice(index, 1);
									}
								}
								for (var j = 0; j < d.params.length; j++) {
									var p = d.params[j];
									var mr = _.find(p.momentRules, function (m) {
										return m.rule && m.rule.actions && m.rule.actions[0] && m.rule.actions[0].outputId === item.config.id;
									});
									p.momentRules = _.without(p.momentRules, mr);
								}
							}
						}
						for (var k = 0; k < $scope.config.engine.moduleInputs.length; k++) {
							var m = $scope.config.engine.moduleInputs[k]
							if (m.outputIds) {
								var index = m.outputIds.indexOf(item.config.id)
								if (index > -1) {
									m.outputIds.splice(index, 1);
								}
							}
							for (var i = 0; i < m.devices.length; i++) {
								var d = m.devices[i];
								if (d.outputIds) {
									var index = d.outputIds.indexOf(item.config.id)
									if (index > -1) {
										d.outputIds.splice(index, 1);
									}
								}
								for (var j = 0; j < d.params.length; j++) {
									var p = d.params[j];
									var mr = _.find(p.momentRules, function (m) {
										return m.rule && m.rule.actions && m.rule.actions[0] && m.rule.actions[0].outputId === item.config.id;
									});
									p.momentRules = _.without(p.momentRules, mr);
								}
							}
						}
					}

					if (item && item.removable && item.config && item.config.slave) {
						var slaveToRemove = _.findWhere(item.parent.config.params, {id: item.config.slave});
						Objects.remove($scope.config, slaveToRemove);
					}

					if (item && item.removable && item.config) {
//                        $rootScope.$emit('config.removeOutput', item);
//                        $rootScope.$broadcast('config.removeOutput', item);
						Objects.remove($scope.config, item.config);
						checkConfig(true);
					}
				},
                root: function (update) {
                    var p, q;
                    var objectPaths = {
                        items: [],
                        paths: []
                    };

                    function path(item) {
                        var i = objectPaths.items.indexOf(item);
                        return (i >= 0) ? objectPaths.paths[i] : '';
                    }

                    function buildObjectPaths() {
                        objectPaths.items = [];
                        objectPaths.paths = [];
                        Objects.traverse($scope.config, function (prop, item) {
                            if (item && typeof(item) === 'object') {
                                var p = path(this);
                                if (this instanceof Array) {
                                    p += '[' + prop + ']';
                                } else {
                                    p = p.length ? p + '.' + prop : prop;
                                }
                                objectPaths.items.push(item);
                                objectPaths.paths.push(p);
                            }
                        });
                    }

                    function createItem(kind, parent, config, opened) {
                        return new TreeItem({
                            path: path(config),
                            kind: kind,
                            config: config,
                            parent: parent,
                            name: config.label || config.name,
                            opened: opened,
                            removable: config.removable,
                            basic: config.basic
                        });
                    }

                    function syncFrom(from, item, elements, kind, repath) {
                        var i, c;
                        for (i = 0; i < elements.length; ++i) {
                            c = elements[i];
                            if (item.children[from + i]) {
                                item.children[from + i].update({
                                    path: repath ? path(c) : undefined,
                                    kind: kind,
                                    config: c,
                                    name: c.label || c.name,
                                    removable: c.removable,
                                    basic: c.basic
                                });
                            } else {
                                item.children[from + i] = createItem(kind, item, c, false);
                            }
                        }
                        if (item.children.length > elements.length + from) {
                            item.children.splice(elements.length + from, item.children.length - elements.length);
                        }
                        item._items = null;
                    }

                    function sync(item, elements, kind, repath) {
                        syncFrom(0, item, elements, kind, repath);
                    }

                    if ($scope.config) {
                        if (!$scope.root) {
                            $scope.root = new TreeItem({
                                config: $scope.config.engine,
                                path: 'engine',
                                removable: false
                            });

                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.measurementInputs',
                                kind: 'measurementInputs',
                                config: $scope.config.engine,
                                name: i18nFilter('config.measurementInputs'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: true
                            });

                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.moduleInputs',
                                kind: 'moduleInputs',
                                config: $scope.config.engine,
                                name: i18nFilter('config.moduleInputs'),
                                removable: false,
                                addable: false,
                                editable: false,
                                basic: true,
								//errorsAdditive: true,
                            });

                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.measurementOutputs',
                                kind: 'measurementOutputs',
                                config: $scope.config.engine,
                                name: i18nFilter('config.measurementOutputs'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: true
                            });

                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.eventInputs',
                                kind: 'eventInputs',
                                config: $scope.config.engine,
                                name: i18nFilter('config.eventInputs'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: true,
                                visible: $scope.license.properties.AUDIT_ENABLED != null
                            });

                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.eventOutputs',
                                kind: 'eventOutputs',
                                config: $scope.config.engine,
                                name: i18nFilter('config.eventOutputs'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: true,
                                visible: $scope.license.properties.AUDIT_ENABLED != null
                            });

                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.databases',
                                kind: 'databases',
                                config: $scope.config.engine,
                                name: i18nFilter('config.databases'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: false,
                                visible: false
                            });


                            new TreeItem({
                                parent: $scope.root,
                                path: 'engine.notifiers',
                                kind: 'notifiers',
                                config: $scope.config.engine,
                                name: i18nFilter('config.notifiers'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: true,
                                visible: false
//                                visible: $scope.license.properties.NOTIFICATION_ENABLED != null
                            });

                            p = new TreeItem({
                                parent: $scope.root,
                                path: null,
                                kind: 'options',
//                                config: $scope.config.options.title,
                                config: $scope.config,
                                name: i18nFilter('config.options'),
                                removable: false,
                                external: true,
                                basic: true
                            });

                            new TreeItem({
                                parent: p,
                                path: 'engine',
                                kind: 'app',
                                config: $scope.config.engine,
                                name: i18nFilter('config.app'),
                                removable: false,
                                addable: false,
                                editable: true,
                                basic: true,
                            });

                            new TreeItem({
                                parent: p,
                                path: 'engine.notifiers',
                                kind: 'notifiers',
                                config: $scope.config.engine,
                                name: i18nFilter('config.notifiers'),
                                removable: false,
                                addable: true,
                                editable: false,
                                basic: true,
                                visible: $scope.license.properties.NOTIFICATION_ENABLED != null
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'network',
//                                config: $scope.config.options.network,
                                config: $scope.config,
                                name: i18nFilter('config.network'),
                                removable: false,
                                //visible: $scope.config.options.network != null,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'ntp',
                                config: $scope.config,
                                name: i18nFilter('config.ntp'),
                                removable: false,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'time',
                                config: $scope.config,
                                name: i18nFilter('config.time'),
                                removable: false,
                                //visible: $scope.config.options.time != null,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'license',
                                config: $scope.config,
                                name: i18nFilter('config.license'),
                                removable: false,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'backup',
                                config: $scope.config,
                                name: i18nFilter('config.restore'),
                                removable: false,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'firmware',
                                config: $scope.config,
                                name: i18nFilter('config.firmware'),
                                removable: false,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'storage',
                                config: $scope.config,
                                name: i18nFilter('config.storage'),
                                removable: false,
                                external: true
                            });

                            new TreeItem({
                                parent: p,
                                path: null,
                                kind: 'support',
                                config: $scope.config,
                                name: i18nFilter('config.support'),
                                removable: false,
                                external: true
                            });

                            update = true;
                        }

                        if (update) {
                            $scope.root.config = $scope.config.engine;
                            $scope.root._items = null;

                            buildObjectPaths();

                            //Measurement inputs
                            p = $scope.root.children[0];
                            p.config = $scope.config.engine;
                            sync(p, $scope.config.engine.measurementInputs, 'measurementInput');
                            _.each(p.children, function (item) {
                                sync(item, item.config.devices, 'device');
                                _.each(item.children, function (item) {
                                    var groupCount = item.config.groups ? item.config.groups.length : 0;
                                    if (groupCount) {
                                        sync(item, item.config.groups, 'group');
                                        _.each(item.children, function (item) {
                                            sync(item, item.config.params, 'parameter');
                                            _.each(item.children, function (item) {
                                                sync(item, item.config.momentRules, 'momentRule');
                                                item.openable = false;
                                                item.toggle(false);
                                            });
                                        });
                                    }
                                    syncFrom(groupCount, item, item.config.params, 'parameter');
                                    _.each(item.children, function (item, index) {
                                        if (index >= groupCount) {
                                            sync(item, item.config.momentRules, 'momentRule');
                                            item.openable = false;
                                            item.toggle(false);
                                        }
                                    });
                                });
                            });

                            //Module inputs
                            p = $scope.root.children[1];
                            p.config = $scope.config.engine;
							q = p.children.length ? p.children[p.children.length - 1] : null;
                            sync(p, $scope.config.engine.moduleInputs, 'moduleInput');
                            _.each(p.children, function (item) {
                                sync(item, item.config.devices, 'device');
                                _.each(item.children, function (item) {
									var groupCount = item.config.groups ? item.config.groups.length : 0;
									if (groupCount) {
										sync(item, item.config.groups, 'group');
										_.each(item.children, function (item) {
											sync(item, item.config.params, 'parameter');
											_.each(item.children, function (item) {
												sync(item, item.config.momentRules, 'momentRule');
												item.openable = false;
												item.toggle(false);
											});
										});
									}
									syncFrom(groupCount, item, item.config.params, 'parameter');
									_.each(item.children, function (item, index) {
										if (index >= groupCount) {
											sync(item, item.config.momentRules, 'momentRule');
											item.openable = false;
											item.toggle(false);
										}
									});
                                });
                            });
							if (q && q.kind === 'mediaCosts') {
								q.config.costs = $scope.config.engine.mediaCosts.costs;
								p.children.push(q);
							} else {
								new TreeItem({
									parent: p,
									path: 'engine.mediaCosts',
									kind: 'mediaCosts',
									config: $scope.config.engine.mediaCosts,
									name: i18nFilter('config.media.costs'),
									removable: false,
									external: false
								});
							}

                            //Measurement outputs
                            p = $scope.root.children[2];
                            p.config = $scope.config.engine;
                            sync(p, $scope.config.engine.measurementOutputs, 'measurementOutput');

                            //Event inputs
                            p = $scope.root.children[3];
                            p.config = $scope.config.engine;
                            sync(p, $scope.config.engine.eventInputs, 'eventInput');
                            //FIXME (jc)
                            _.each(p.children, function (item) {
                                function getSources(path) {
                                    var sources = [];

                                    if (path) {
                                        var rpath = path.join("/");
                                        for (var i = 0; i < item.config.sources.length; ++i) {
                                            var source = item.config.sources[i];
                                            if (source.path.length === path.length + 1 && source.path.join("/").indexOf(rpath) === 0) {
                                                sources.push(source);
                                            }
                                        }
                                    } else {
                                        for (var i = 0; i < item.config.sources.length; ++i) {
                                            var source = item.config.sources[i];
                                            if (source.path.length === 1) {
                                                sources.push(source);
                                            }
                                        }
                                    }
                                    return sources;
                                }

                                function syncSources(item, path) {
                                    var sources = getSources(path);
                                    if (sources) {
                                        sync(item, sources, 'eventSource', true);
                                        _.each(item.children, function (item) {
                                            syncSources(item, item.config.path);
                                            item.errorsAdditive = true;
                                        });
                                    }
                                }

                                syncSources(item, null);
                            });

                            //Event outputs
                            p = $scope.root.children[4];
                            p.config = $scope.config.engine;
                            sync(p, $scope.config.engine.eventOutputs, 'eventOutput');

                            //Databases
                            p = $scope.root.children[5];
                            p.config = $scope.config.engine;
                            sync(p, $scope.config.engine.databases, 'database');

                            //Notifications
                            p = $scope.root.children[6];
                            p.config = $scope.config.engine;
                            sync(p, $scope.config.engine.notifiers, 'notifier');
                        }
                    }
                    return $scope.root;
                },
                updateErrors: function () {
                    var root = this.root(), e = $scope.errors;
                    if (root && e) {
                        root.each(function (item) {
                            item.errors = e ? e.getNestedErrors(item.path) : new Errors();
                        });
                    }
                },
                hasErrors: function (item) {
                    return this.errorCount(item) > 0;
                },
                errorCount: function (item) {
                    var i, e;
                    if (item.errorsAdditive) {
                        e = item.errors ? item.errors.count() : 0;
                        for (i = 0; i < item.children.length; ++i) {
                            e += this.errorCount(item.children[i]);
                        }
                        return e;
                    } else if(item.kind === "measurementOutputs" && (item.errors || item.parent.children[5].errors)){
                        e = item.errors.count();
                        e += item.parent.children[5].errors.count();
                        return e;
                    } else if(item.kind === "measurementOutput"){
                        e = item.errors.count();
                        _.forEach(item.parent.parent.children[5].children, function(db){
                            if(db.config.id == item.config.databaseId){
                                e += db.errors.count();
                            }
                        })
                        return e;
                    } else if (item.errors) {
                        return item.errors.count();
                    } else {
                        return 0;
                    }
                },
                destroyStatus: function () {
                    if (this.statusPromise) {
                        $timeout.cancel(this.statusPromise);
                        this.statusPromise = null;
                    }
                    if (this.statusScope) {
                        this.statusScope.$destroy();
                        this.statusScope = null;
                    }
                    $('#status-tooltip').remove();
                },
                showStatus: function (item, event) {
                    this.destroyStatus();
                    this.statusScope = $scope.$new();
                    this.statusScope.item = item;
                    var parent = $(event.target).closest('.list-group-item');
                    var tooltip = $($compile($templateCache.get('config/config-status-content.html'))(this.statusScope));
                    var $body = $('body');
                    $body.append(tooltip);
                    //$body.on('click.status-tooltip', function () {
                    //    $scope.ui.hideStatus();
                    //    $body.off('click.status-tooltip');
                    //});
                    $timeout(function () {
                        tooltip.css('left', Math.round(parent.offset().left + parent.outerWidth() + 5) + 'px');
                        tooltip.css('top', Math.round(parent.offset().top + parent.outerHeight() / 2 - 39) + 'px');
                        tooltip.css('display', 'block');
                    });
                },
                hideStatus: function () {
                    if (!this.statusPromise) {
                        this.statusPromise = $timeout(function () {
                            $scope.ui.destroyStatus();
                        }, 1000);
                    }
                },
                count:1,
                canAdd: function () {
                    return $scope.addui.valid && $scope.addui.valid();
                },
                canAddMultiple: function () {
                    return this.canAdd() && !($scope.item.kind === 'measurementInput' && $scope.item.config.type === 'mbsat');
                },
                add: function () {
                    if (!this.added && this.canAdd()) {
                        var elem = $scope.addui.element();
                        if (!elem.id) {
                            elem.id = uuid.v4();
                        }
                        if (elem.name) {
                            elem.name = elem.name.trim();
                        }
                        $scope.addui.append(elem, $scope.item.config, this.count);
                        this._itemId = elem.id;
                        this.added = true;
                        this.count = 1;
                        checkConfig(true);
                    }
                },
                addDevice: function () {
                    if (!this.added && this.canAdd()) {
                        var elem = $scope.addui.element();
                        $scope.addui.append(elem, $scope.item.config);
                        this._itemId = elem.id;
                        $scope.ui.refresh();
                    }
                },
                canDetect: function () {
                    return !this.dirty;
                },
                detect: function () {
                    if (this.canDetect()) {
                        $scope.$broadcast('autodetect');
                    }
                },
                save: function () {
                    if (this.processing) { // lock preventing double submit
                        return;
                    }
                    this.processing = true;
                    configService.save($scope.config).then(function (result) {
                        $scope.ui.saveSuccess(result);
                    }, function (result) {
                        $scope.ui.saveError(result);
                    });
                },
                saveSuccess: function(result){
                    $scope.config = result.config;
                    $scope.errors = new Errors();
                    $scope.usage = result.usage;
                    $scope.ui.showMessage('config.save.success', 'alert-success');
                    $scope.ui.dirty = false;
                    $scope.ui.processing = false;
                },
                saveError: function(result){
                    if (result && result.config && result.errors && result.usage) {
                        $scope.config = result.config;
                        $scope.errors = new Errors(result.errors);
                        if ($scope.errors.globals.length > 0) {
                            for (var i = 0; i < $scope.errors.globals.length; ++i) {
                                if ($scope.errors.globals[i].message === 'error.optimistic.lock') {
                                    $scope.ui.optimisticLock = true;
                                }
                            }
                        }
                        $scope.usage = result.usage;
                    }
                    if ($scope.ui.optimisticLock) {
                        $scope.ui.showMessage('config.save.failure.refresh', 'alert-danger');
                    } else if ($scope.errors.count() > 0) {
                        $scope.ui.showMessage('config.save.failure.errors', 'alert-danger');
                    } else {
                        $scope.ui.showMessage('config.save.failure', 'alert-danger');
                    }
                    $scope.ui.processing = false;
                },
                cancel: function () {
                    if (this.processing) { // lock preventing double submit
                        return;
                    }
                    this.processing = true;
                    configService.reset().then(function (config) {
                        $scope.config = config;
                        $scope.errors = new Errors();
                        $scope.license = licenseService.getLicense();
                        $scope.usage = $scope.license.usage;
                        $scope.item = null;
                        $scope.root = null;
                        $scope.ui.dirty = false;
                        $scope.ui.processing = false;
                    });
                },
                showMessage: function (text, css) {
                    this.message = {
                        css: css,
                        text: text
                    };
                    $timeout.cancel(this.messagePromise);
                    this.messagePromise = $timeout(function () {
                        $scope.ui.message = null;
                    }, 5000);
                },
				enable: function (item) {
					$timeout(function () {
						item.config.enabled = !item.config.enabled;
					});
				},
                optimisticLock: false
            };

            $scope.addui = {};

            $scope.$watch('config', function (nval, oval) {
                if (nval !== oval) {
                    $scope.ui.refresh();
                    $scope.ui.updateErrors();
                }
            });

            $scope.$watch(function () {
                return $location.hash();
            }, function (nval, oval) {
                if (nval !== oval) {
                    var root = $scope.ui.root();
                    $scope.ui.select(root.treeFind(nval));
                }
            });

            $scope.$on('modelErrors.update', function (event, force) {
                if ($scope.ui.liveValidation || force) {
                    checkConfig(false);
                } else if (configService.isDirty()) {
                    $scope.ui.dirty = true
                }
            });

            $scope.$on('options', function (event, options) {
                $scope.ui.options = options;
            });

            $scope.$on('message', function (event, text, css) {
                $scope.ui.showMessage(text, css);
            });

            $scope.$on('config.saveSuccess', function (event, result){
                $scope.ui.saveSuccess(result);
            });

            $scope.$on('config.saveError', function (event, result){
                $scope.ui.saveError(result);
            });

            $scope.$watch('ui.dirty', function (nval, oval) {
                if (nval !== oval) {
                    $scope.$emit('config.dirty', nval);
                }
            });

            $scope.$watch('ui.liveValidation', function (nval, oval) {
                if (nval !== oval) {
                    localStorageService.set('config.liveValidation', nval);
                }
            });

            configService.get().then(function (config) {
                $scope.config = config;
                $scope.errors = new Errors();
                $scope.ui.dirty = false;
                $scope.ui.refresh();
                $scope.ui.updateErrors();
            });

            angular.element('body').css('overflow-y', 'scroll');
            $scope.$on('$destroy', function () {
                angular.element('body').css('overflow-y', '');
            });
        }
    };
});

module.directive('configTreePanel', function () {
    return {
        scope: false,
        templateUrl: 'config/config-tree-panel.html',
        link: function (scope, elem, attr, ctrl) {
        }
    };
});


module.directive('configTree', function ($window) {
    return {
        scope: true,
        template: "<svg width='30' height='30'></svg>",
    };
});

module.directive('configFormPanel', function ($window) {
    return {
        scope: false,
        templateUrl: 'config/config-form-panel.html',
        link: function (scope, elem, attr, ctrl) {
            var $w = $($window);
            var offset = elem.offset();
            var el=$("div.col-sm-8");
            var menu=$("div.col-sm-4");
            var $body = $("body");
            var menu2 = $(".height");
            var whole = $("div.main-view");
            var home = $("#navigationMenu");
            var navbar=$("#navbar");

            menu2.height($w.height()-50);
            elem.height($w.height()-60);

            $(document).ready(function(e){
                $(window).resize(function(e){
                    menu2.height($w.height()-50);
                    elem.height($w.height()-60);
                });
            });

            scope.$on('$destroy', function () {
                $w.off('scroll.config-form');
            });
        }
    };
});

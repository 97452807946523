/*globals tools*/

var module = angular.module('meternet.charts.gauge.services.digital-gauge-serie', []);

module.service('DigitalGaugeSerie', function(gaugeUtils){
     function DigitalGaugeSerie(body, serie) {
        this.body = body;
        var self = this; // for internal d3 functions
        this.serie = tools.copy(serie);
        gaugeUtils.sanitizeSerie(this.serie);
        var _currentArc2 = 0;
        var _indicator = d3.svg.arc().startAngle(0 * (Math.PI / 180)).endAngle(0); // just

        this.render = function(config) {
            if (config) {
                this.config = config;
                this.config.size = Math.min(this.config.width *0.5, this.config.height * 1);
                this.config.cx = this.config.width / 2;
                this.config.cy = this.config.height / 2;

                this.config.gradient = config.gradient || -3;
                this.config.transitionDuration = config.transitionDuration || 500;
            }
            // this.body.selectAll('*').remove();
            this.body.attr("class", "gauge").attr("width", this.config.width).attr("height", this.config.height);

            var serie = this.serie;
            var fontSize = Math.round(this.config.size / 10);

            //nazwa licznika
            if (undefined !== serie.name) {
                fontSize = Math.round(this.config.size / 10);
                var labelText = this.body.select(".gauge-label");
                if (labelText.empty()) {
                    labelText = this.body.append("svg:text").attr("class", "gauge-label");
                }
                labelText.attr("x", this.config.cx)
                        .attr("y", this.config.cy - fontSize * 3)
                        .attr("dy", fontSize / 10)
                        .attr("text-anchor", "middle")
                        .text(serie.name)
                        .style("font-size", fontSize  + "px")
                        .style("fill", "#999")
                        .style("stroke-width", "0px");
            }

            var midValue = serie.min + (serie.max - serie.min) / 1000;

            //kontener na wskaz
            var pointerContainer = this.body.select("g.pointerContainer");
            if (pointerContainer.empty()) {
                pointerContainer = this.body.append("svg:g").attr("class", "pointerContainer");
            }
            //wskaz
            var gaugeValue = pointerContainer.selectAll("text.gauge-value").data([ midValue ]);
            gaugeValue.enter().append("svg:text").attr("class", "gauge-value");
            gaugeValue.attr("x", this.config.cx)
                    .attr("y", this.config.cy)
//                    .attr("dy", fontSize * 5 )
                    .attr("text-anchor", "middle")
                    .style("font-size", (fontSize * 2)  + "px")
                    .style("fill", "#000").style("stroke-width", "0px");
            //timestamp
            var gaugeTimestamp = pointerContainer.selectAll("text.gauge-timestamp").data([ midValue ]);
            gaugeTimestamp.enter().append("svg:text").attr("class", "gauge-timestamp");
            gaugeTimestamp.attr("x", this.config.cx)
                    .attr("y",this.config.cy + fontSize * 3)
                    .attr("dy", fontSize / 10)
                    .attr("text-anchor", "middle")
                    .style("font-size", fontSize + "px").style("fill", "#333").style(
                    "stroke-width", "0px");

        };

        // =====================redraw=============================
        this.redraw = function(measurement, transitionDuration) {
            var serie = this.serie;
            var pointerContainer = this.body.select(".pointerContainer");

            if (!measurement) {
                pointerContainer.selectAll("text.gauge-value").text('?');
                pointerContainer.selectAll("text.gauge-timestamp").text('');
                return;
            }
            var timestamp = this.config.dateFormatter(measurement.timestamp, 'yyyy-MM-dd HH:mm:ss');
            var value = measurement.value;

            var color = "#000000";
            var minZone;
            var maxZone;

            this.serie.zones.forEach( function(zone){
                if(minZone === undefined || minZone.from>zone.from){
                    minZone =zone
                }
                if(maxZone === undefined || maxZone.to<zone.to){
                    maxZone =zone
                }
            })
//            if(minZone.from>value){
//                color = minZone.color;
//            }else if(maxZone.to<value){
//                color = maxZone.color;
//            }else{
                this.serie.zones.forEach( function(zone){
                    if(zone.from<value && zone.to> value){
                        color= zone.color
                    }
                })
//            }

            pointerContainer.selectAll("text.gauge-value").text(
                    this.config.valueFormatter(value, serie.precision, serie.unit, serie.scale))
                    .style("fill", color);
            pointerContainer.selectAll("text.gauge-timestamp").text(timestamp);

        };

    }
     return DigitalGaugeSerie;
});

var module = angular.module("meternet.report.energy.directives", [
        "meternet.report.energy.EnergyReportSelectorDirective",
        "meternet.report.energy.EnergyReportConfigDirective",
        "meternet.report.energy.EnergyReportTableDirective"
]);

module.controller('reportEnergyCtrl', function ($scope, $stateParams, $timeout, contextPath, dashboardService, configService, dataService,
        $state, userService, localStorageService, i18nFilter, DashboardEvents, energyReportService) {

    var lsKey = 'ui.report.energy';
    var DEFAULT_DASHBOARD = {
        "id": null,
        "name": "main",
        "structure": "12",
        "lockAddingWidgets": true,
        "lockEditingDashboard": true,
        "lockEditing": true,
        "lockRemovingWidgets": true,
        "enableClearingModel": true,
        "editLink": "/chuj", //FIXME (jc) ???
        "hideLicenseStatus": true,
        "title": "",
        "rows": [{
            "columns": [{
                "width": 12,
                "widgets": [{
                    "type": "energy-report",
                    "locked": true,
                    "title": "",
                    "config": {
                        "series": [],
                        "reportId": null,
                        "name": null,
                        "columns": 4,
                        "mode":1,
                        "dateFrom": null
                    }
                }]
            }]
        }]
    };

    DEFAULT_DASHBOARD.id = $stateParams.reportEnergyId
    $scope.dashboard = tools.copy(DEFAULT_DASHBOARD);
    if ($stateParams.reportEnergyId) {
        energyReportService.get($stateParams.reportEnergyId).then(function (config) {
            $scope.dashboard.title = config.label || config.name;
            $scope.dashboard.rows[0].columns[0].widgets[0].config.reportId = config.id;
            $scope.dashboard.rows[0].columns[0].widgets[0].config.columns = config.columns;
            $scope.dashboard.rows[0].columns[0].widgets[0].config.mode = config.mode || 0;
            $scope.dashboard.rows[0].columns[0].widgets[0].config.visibleColumns = config.visibleColumns;// ["energyReport.date","energyReport.value","energyReport.increase"];
            $scope.dashboard.rows[0].columns[0].widgets[0].config.series = [];
            $scope.dashboard.rows[0].columns[0].widgets[0].config.hideReportId = true;
            _.each(config.params, function(param){
                $scope.dashboard.rows[0].columns[0].widgets[0].config.series.push({
                    enabled:param.enabled,
                    display:param.display,
                    paramId: param.id,
                    name: param.label,
                    cost: param.cost,
                    costUnit: param.CostUnit,
                    zones: param.zones,
                    zonesPower: param.zonesPower,
                    zonesCost: param.zonesCost
                });
            });
        });
    }

    $scope.$on('dashboard.dirty', function($event, newElement) {
        $scope.dirty = true;
    });

    $scope.$on('dashboard.clear', function($event, newElement) {
        $scope.dashboard = tools.copy(DEFAULT_DASHBOARD);
    });

    $scope.$on('meternetConfigUpdate', function($event, config) {
        $scope.$broadcast(DashboardEvents.RESIZE);
    });

    $scope.$on('adfDashboardChanged', function(event, name, model) {
        var key = lsKey + $scope.dashboard.rows[0].columns[0].widgets[0].config.reportId
        localStorageService.set(key, $scope.dashboard.rows[0].columns[0].widgets[0].config);
    });
});

<div class="accordion" id="accordionExample">
    <div class="card">
        <div class="card-header" >
            <div class="row" class="mb-0" style="background-color: rgb(219, 233, 246); --darkreader-inline-bgcolor:#1d2f3e; font-weight: bold; padding: 5px 10px;">
                <div class="col-xs-4">{{'report.table.paramName' | i18n}}</div>
                <div class="col-xs-3">{{'report.table.timestamp' | i18n}}</div>
                <div class="col-xs-3">{{'report.table.value' | i18n}}</div>
                <div class="col-xs-2"></div>
            </div>
        </div>
    </div>
    <div class="card" x-ng-repeat="param in params track by param.id">
        <div class="card-header">
            <div class="row" class="mb-0" style="padding: 5px 10px;">
                <div class="col-xs-4">{{param.label || param.name}}</div>
                <div class="col-xs-3">{{param.moment.current.timestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
                <div class="col-xs-3">{{param.moment.current | measurement}}</div>
                <div class="col-xs-2">
                    <button class="btn btn-link collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#{{param.id}}"
                            aria-expanded="false"
                            aria-controls="{{param.id}}">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
        <div id="{{param.id}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
                <div bar-graph="" options="param" style="height:300px"></div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="panel panel-default">
        <div class="panel-heading">{{'dashboard.widgets.config.data.source' | i18n}}</div>
        <div class="panel-body">
            <div class="form from-inline">
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.series.device"></label>
                    <select class="form-control" style="width: 252px;"
                            x-ng-options="device.id as (device.label||device.name) for device in devices"  x-ng-model="config.deviceId"
                            data-ng-change="ui.deviceSelected()">
                    </select>
                </div>
            </div>
        </div>

        <ul class="list-group">
            <li class="list-group-item" x-ng-repeat="series in config.series">
                <form class="form form-inline">
                    <div class="form-group">
                        <label class="form-group-label">&nbsp;</label>
                        <div style="width: 15px; cursor: pointer;">
                            <i class="fa fa-lg" x-ng-click="series._details = !series._details"
                               x-ng-class="series._details ? 'fa-minus' : 'fa-plus'"></i>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.param"></label>
                        <select class="form-control" style="width: 252px;" x-ng-model="series.paramId"
                                x-ng-options="param.id as (param.label||param.name) for param in selectedDevice.params"
                                data-validate="{'dashboard.widgets.shiftBar.paramNotNull': series.paramId === null}"
                                >
                        </select>
                    </div>

                    <div class="form-group" style="margin-left: 4px;">
                        <label class="form-group-label">&nbsp;</label>
                        <button class="btn btn-danger" x-ng-click="ui.removeSeries(series)">
                            <i class="fa fa-trash-o fa-lg"></i>
                        </button>
                    </div>

                    <div data-ng-repeat="barColor in ui.getBarColors(series)">
                        <div class="form-group" data-ng-show="series._details">
                            <div class="form-group" style="width: 90px;">
                                <label class="form-group-label" x-label-help="dashboard.widgets.config.series.line.color"></label>
                                <spectrum-colorpicker format="hex" x-ng-model="series.barColors[$index].color" name="widgetsConfigSeriesLineColor-{{$index}}"
                                                      options="ui.colorPickerOpts || {showInput : true}">
                                </spectrum-colorpicker>
                            </div>

                            <div  class="form-group">
                                <label class="form-group-label" x-label-help="dashboard.widgets.config.series.name"></label>
                                <input class="form-control" data-ng-model="series.barColors[$index].name">
                            </div>
                        </div>
                    </div>
                </form>
            </li>
            <li class="list-group-item" x-ng-if="ui.canAddSeries()">
                <div class="pull-right">
                    <button class="btn btn-success pull-right" x-ng-click="ui.addSeries()">
                        {{'dashboard.widgets.config.series.add' | i18n}}&nbsp;&nbsp;<i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>
                <div class="clearfix"></div>
            </li>
        </ul>
    </div>

    <div class="panel panel-default panel-widget-config">
        <div class="panel-heading">{{'dashboard.widgets.config.data.formatting' | i18n}}</div>
        <div class="panel-body">
            <form class="form form-inline">
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.shiftBar.barSize"></label>
                    <input class="form-control" type="text" data-ng-model="config.barSize" style="width: 180px">
                </div>
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.shiftBar.barSpacing"></label>
                    <input class="form-control" type="text" data-ng-model="config.barSpacing" style="width: 180px">
                </div>
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.shiftBar.drawTicksEveryXTicks"></label>
                    <input class="form-control" type="text" data-ng-model="config.tickLabelEveryXTicks" style="width: 180px">
                </div>

                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.scale"></label>
                    <select class="form-control" style="width: 134px;" x-ng-model="config.axis.scale"
                            x-ng-options="s.value as s.label for s in ui.scales">
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.precision"></label>
                    <select class="form-control" style="width: 134px;" x-ng-model="config.axis.precision"
                            x-ng-options="p for p in ui.precisions" style="width: 138px">
                    </select>
                </div>
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.shiftBar.unit"></label>
                    <input class="form-control" type="text" data-ng-model="config.unit" style="width: 134px">
                </div>
                <div class="form-group">
                    <label class="form-group-label" x-label-help="dashboard.widgets.shiftBar.hideLegend"></label>
                    <!--<div class="col-md-7 pull-right">-->
                    <center>
                        <input class="form-control" type="checkbox" data-ng-model="config.hiddenLegend">
                    </center>
                    <!--</div>-->
                </div>

                <div class="form-group row">
                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.range.auto"></label>
                        <select class="form-control" style="width: 180px;" x-ng-model="config.axis.rangeAuto"
                                x-ng-options="r.value as r.label for r in ui.axisRanges">
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.min"></label>
                        <div class="input-group" style="width: 180px;">
                            <input type="text" class="form-control" x-ng-model="config.axis.rangeMin"
                                   x-ng-disabled="config.axis.rangeAuto"
                                   data-scale-formatter=""
                                   data-format-scale="config.axis.scale"
                                   data-format-precision="config.axis.precision"
                                   data-validate="{'dashboard.widgets.config.error.minMax': config.axis.rangeMin > config.axis.rangeMax}"/>
                            <data-unit-addon data-unit-addon-scale="config.axis.scale" data-unit-addon-unit = config.unit></data-unit-addon>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-group-label" x-label-help="dashboard.widgets.config.series.axis.max"></label>
                        <div class="input-group" style="width: 180px;">
                            <input type="text" class="form-control" x-ng-model="config.axis.rangeMax"
                                   x-ng-disabled="config.axis.rangeAuto"
                                   data-scale-formatter=""
                                   data-format-scale="config.axis.scale"
                                   data-format-precision="data.axis.precision"
                                   data-validate="{'dashboard.widgets.config.error.minMax': config.axis.rangeMin > config.axis.rangeMax}" />
                            <data-unit-addon data-unit-addon-scale="config.axis.scale" data-unit-addon-unit = config.unit></data-unit-addon>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="container-fluid" x-errors="ui.errors" x-errors-root="ui.scheduler">
    <div class="row flex-row">
        <div class="col-xs-12 col-sm-4">
            <div class="form-group">
                <label class="form-group-label" x-label-help="mbsat.menu.scheduler"></label>
                <div class="input-group">
                    <!-- cannot use  'track by scheduler.id' in ng-options because it changes scheduler instance -->
                    <select class="form-control" x-ng-model="ui.scheduler" name="widgetsConfigSeriesDevice-{{$index}}"
                            x-ng-options="scheduler as scheduler.name for scheduler in lightingSetting.schedulers" x-ng-change="updateUrl()"
                            x-required="">
                    </select>
                    <span class="input-group-btn">
                        <button x-ng-if="editable" type="button" class="btn btn-success pull-right" x-ng-click="addScheduler()" style="border-radius: 0;">
                            <i class="fa fa-plus"></i>
                        </button>
                    </span>
                    <span class="input-group-btn">
                        <button x-ng-if="editable" type="button" class="btn btn-danger pull-right" x-ng-click="removeScheduler()">
                            <i class="fa fa-trash"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <hr style="margin-top: 0">
    <div class="row" ng-if="ui.scheduler">
        <div class="col-xs-12 edit-row">
            <button class="btn btn-primary" x-ng-disabled="ui.isFirst()" x-ng-click="previousNextScheduler('previous')">
                <i class="fa fa-step-backward fa-fw"></i>&nbsp;
            </button>
            <div class='form-group'>
                <label class="form-group-label" x-label-help="mbsat.menu.scheduler.dateFrom"></label>
                <div class="input-group date">
                    <input type='text' class="form-control" x-ng-model="ui.scheduler.from" x-datetimepicker="{format:'YYYY-MM-DD'}"  x-field-errors=""/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
            <div class="form-group">
                <label class="form-group-label">{{::'mbsat.modal.settings.name' | i18n}}:</label>
                <div class="input-group">
                    <input type="text" class="form-control" x-ng-model="ui.scheduler.name" x-field-errors=""/>
                </div>
            </div>
            <div class='form-group'>
                <label class="form-group-label" x-label-help="mbsat.menu.scheduler.twilightType"></label>
                <div class="input-group" style="width: 100%;">
                    <select class="form-control" data-ng-model="ui.scheduler.twilightType" data-ng-options="kind.value as kind.name for kind in twilightTypes" x-field-errors=""></select>
                </div>
            </div>
            <div class='form-group'>
                <label class="form-group-label" x-label-help="mbsat.menu.scheduler.dateTo"></label>
                <div class="input-group date">
                    <input type='text' class="form-control" x-ng-model="ui.scheduler.to" x-datetimepicker="{format:'YYYY-MM-DD'}"  x-field-errors=""/>
                    <span class="input-group-addon">
                        <span class="fa fa-calendar"></span>
                    </span>
                </div>
            </div>
            <button class="btn btn-primary" x-ng-disabled="ui.isLast()" x-ng-click="previousNextScheduler('next')">
                <i class="fa fa-step-forward fa-fw"></i>&nbsp;
            </button>
        </div>
    </div>
    <div ng-if="ui.scheduler">
        <div ui-calendar config="uiConfig.calendar" ng-model="eventSources" calendar-obj="ui.calendar"></div>
    </div>
</div>

<div class="modal fade" id="schedulerAdd" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{::'dashboard.widgets.config.series.lightingSetting' | i18n}}</h2>
            </div>
            <div class="modal-body">
                <form class="form-inline">
                    <div class="row" style="margin-bottom: 10px;">
                        <div class="form-group">
                            <label class="form-group-label">{{::'mbsat.modal.settings.name' | i18n}}:</label>
                            <input type="text" class="form-control" data-ng-model="ui.newScheduler.name" style="width: 100%"/>
                        </div>
                        <div class='form-group'>
                            <label class="form-group-label" x-label-help="mbsat.menu.scheduler.twilightType"></label>
                            <div class="input-group" style="width: 100%;">
                                <select class="form-control" data-ng-model="ui.newScheduler.twilightType" data-ng-options="kind.value as kind.name for kind in twilightTypes"></select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label class="form-group-label">{{::'mbsat.menu.scheduler.dateFrom' | i18n}}:</label>
                            <div class="input-group date">
                                <input type='text' class="form-control" x-ng-model="ui.newScheduler.from" x-datetimepicker=""/>
                                <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-group-label">{{::'mbsat.menu.scheduler.dateTo' | i18n}}:</label>
                            <div class="input-group date">
                                <input type='text' class="form-control" x-ng-model="ui.newScheduler.to" x-datetimepicker=""/>
                                <span class="input-group-addon">
                                <span class="fa fa-calendar"></span>
                            </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" x-ng-click="saveNewScheduler()">
                    <i class="fa fa-check"></i>{{'btn.OK' | i18n}}
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" x-ng-click="resetNewScheduler()">
                    <i class="fa fa-long-arrow-left"></i>{{'btn.cancel' | i18n}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="dawnDuskSetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{selectedResource.title}}</h2>
            </div>
            <div class="modal-body">
                <div ng-if="selectedResource" class="row">
                    <div class="col-xs-11">
                        <div class="col-xs-6">
                            <div x-custom-time-picker ng-model="ui.selectedDayShifts.dawn" x-label="'widget.mbsat.event.offsetDawn'"></div>
                        </div>
                        <div class="col-xs-6">
                            <div x-custom-time-picker ng-model="ui.selectedDayShifts.dusk" x-label="'widget.mbsat.event.offsetDusk'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" x-ng-click="saveShifts()">
                    <i class="fa fa-check"></i>{{'btn.OK' | i18n}}
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" x-ng-click="resetShifts()">
                    <i class="fa fa-long-arrow-left"></i>{{'btn.cancel' | i18n}}
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="eventSetModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" style="width: 90%; min-width: 500px;">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title">{{selectedResource.title}}</h2>
            </div>
            <div class="modal-body">
                <div class="row" x-ng-repeat="event in ui.newEvents track by $index">
                    <div class="flex-row">
                        <div class="col-xs-11 width-fix">
                            <div class="col-xs-6">
                                <label class="form-group-label">{{'widget.mbsat.morningEvent' | i18n}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" x-ng-model="event.start" x-datetimepicker="{format:'HH:mm:ss'}"/>
                                    <span class="input-group-addon">
                                        <i class="fa fa-clock-o"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col-xs-6">
                                <label class="form-group-label">{{'widget.mbsat.eveningEvent' | i18n}}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" x-ng-model="event.end" x-datetimepicker="{format:'HH:mm:ss'}"/>
                                    <span class="input-group-addon">
                                        <i class="fa fa-clock-o"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-1">
                            <button type="button" class="btn btn-danger pull-right" x-ng-click="removeEvent($index)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <hr/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <button type="button" class="btn btn-success pull-right" x-ng-click="addEvent()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal" x-ng-click="saveDay()">
                    <i class="fa fa-check"></i>{{'btn.OK' | i18n}}
                </button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal" x-ng-click="resetDay()">
                    <i class="fa fa-long-arrow-left"></i>{{'btn.cancel' | i18n}}
                </button>
            </div>
        </div>
    </div>
</div>

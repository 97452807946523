var module = angular.module('meternet.dashboard.controllers.controlGaugeWidget', ['adf.provider']);

module.config(function(dashboardProvider, contextPath, messages) {
    var widget = {
        templateUrl: 'dashboard/control-gauge-widget.html',
        title : messages['dashboard.widgets.controlGauge.title'],
        description : messages['dashboard.widgets.controlGauge.desc'],
        controller : 'controlWidgetCtrl',
        screenshot: true,
        config : {
            type : "control",
            layout : true,
            series : [ {
                paramId : null,
                onName : "ON",
                offName : "OFF",
            } ]
        },
        edit : {
            controller : 'controlWidgetEditCtrl',
            templateUrl: 'dashboard/control-gauge-edit.html',
            resolve : {
                maxSeries : [ 'licenseService', function(licenseService) {
                    var license = licenseService.getLicense();
                    return license.properties.MAX_SERIES || 5;
                } ]
            }
        }
    };
    dashboardProvider.widget('control', widget);
});


function ControlEditCtrl($scope, $filter, contextPath, dataService, config, configService, UnitScales,
         TimeDerivativeScales, ColorPickerOpts, maxSeries) {

    $scope.writableParams=[]

    configService.get().then(function(meternetConfig){
        for (var i = 0; i < meternetConfig.engine.measurementInputs.length; ++i) {
            var input = meternetConfig.engine.measurementInputs[i];
            for (var d = 0; d < input.devices.length; ++d) {
                var device = input.devices[d];
                for (var p = 0; p < device.params.length; ++p) {
                    var param = device.params[p];
                    if (param.writable) {
                        $scope.writableParams.push({
                            id: param.id,
                            name: (device.label?device.label:device.name) + ': ' + (param.label?param.label:param.name)
                        });
                    }
                }
            }
        }
    });

    $scope.updateAxisIndices = function(serie){
        var name = _.findWhere($scope.writableParams, {id:serie.paramId}).name;
        serie.name=name;
    }

    $scope.ui={
        addSeries : function() {
            if (this.canAddSeries()) {
                var series = {
                     paramId : null,
                     onName : "ON",
                     offName : "OFF",
                };
                $scope.config.series.push(series);
            }
        },
        removeSerie : function(index) {
            $scope.config.series.splice(index, 1);
        },
        canAddSeries : function() {
            return $scope.config.series.length < maxSeries;
        },
    }
}

function ControlCtrl($scope, $filter, contextPath, dataService, config, configService, DashboardEvents,
        seriesService, $q) {
        $scope.ui = {};
}

module.controller('controlWidgetEditCtrl', ControlEditCtrl);
module.controller('controlWidgetCtrl', ControlCtrl);
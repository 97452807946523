/*global tools */
var module = angular.module('meternet.menu', [
    'meternet.inline-js'
]);

module.directive('menu', function($state, $stateParams, $rootScope, $timeout, contextPath, app, dashboardService,
                                  dataService, userService, licenseService, localStorageService, energyReportService, dataServiceCache, Locale) {
    return {
        scope : {},
        controller : function ($scope) {
            var user = userService.getUser();
            $scope.user = user;

            $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var link = (_.findWhere($scope.menuLinks, {
                    target : toState.name
                }));
                if (link) {
                    $scope.activeLink = link.target;
                } else {
                    _.each($scope.menuLinks, function(link) {
                        var mark = link.target.lastIndexOf(".");
                        var val = link.target.substring(4, mark);
                        if (toState.name.indexOf(val) > -1 && val.length > 0) {
                            $scope.activeLink = link.target;
                        }
                    });
                }
            });

            $rootScope.$on('changeMenuLayout', function(){
                dashboardService.getDashboardMenuLink().then(function (dashboardMenuLink) {
                    $scope.menuLinks[$scope.dashboardIdx] = dashboardMenuLink;

                    if(dashboardMenuLink.children.length>0){
                        var	target	= dashboardMenuLink.children[0].target;
                        var dashboardName=target.substring(target.indexOf("\"")+1,target.lastIndexOf("\""));
                        $stateParams.dashboardName=dashboardName;
                        $state.go('main.dashboard');
                    }else{
                        $state.go('main.home');
                    }
                });
            });

            $rootScope.$on('changeMenuLayoutEnergyReport', function(){
                if (license.properties['ENERGY_REPORT_ENABLED'] === true) {
                    reportLink.children.length=2;
                    energyReportService.getEnergyReportMenuLink().then(function(energyReportMenuLinks) {
                        _.each(energyReportMenuLinks, function(energyReportMenuLink) {
                            reportLink.children.push(energyReportMenuLink);
                        });
                    });
                }
            });

            $rootScope.$on('config.dirty', function (event, dirty) {
                var configLink = _.find($scope.menuLinks, function (link) {
                    return link.title === 'menu.config';
                });
                if (configLink) {
                    configLink.notification = dirty ? {
                        icon: 'fa-warning',
                        info: 'config.dirty'
                    } : null;
                }
            });

            $scope.$on('calendar.dirty', function (event) {
                var dashboardLink = _.find($scope.menuLinks, function (link) {
                    return link.title === 'menu.dashboard';
                });
                if (dashboardLink) {
                    $scope.dashboardLink.notification = {
                        icon: 'fa-warning',
                        info: 'config.dirty'
                    };
                    dashboardLink.notification = {
                        icon: 'fa-warning',
                        info: 'config.dirty'
                    };
                }
            });
            $scope.$on('calendar.dirty.cancelled', function (event) {
                var dashboardLink = _.find($scope.menuLinks, function (link) {
                    return link.title === 'menu.dashboard';
                });
                if (dashboardLink) {
                    $scope.dashboardLink.notification =  null;
                    dashboardLink.notification = null;
                }
            });

            $scope.$on('adfIsEditMode', function() {
                var dashboardLink = _.find($scope.menuLinks, function (link) {
                    return link.title === 'menu.dashboard';
                });
                if (dashboardLink) {
                    $scope.dashboardLink.notification = {
                        icon: 'fa-warning',
                        info: 'config.dirty'
                    };
                    dashboardLink.notification = {
                        icon: 'fa-warning',
                        info: 'config.dirty'
                    };
                }
            });

            $scope.$on('adfDashboardEditsCancelled', function() {
               var dashboardLink = _.find($scope.menuLinks, function (link) {
                   return link.title === 'menu.dashboard';
               });
               if (dashboardLink) {
                   $scope.dashboardLink.notification =  null;
                   dashboardLink.notification = null;
               }
            });

            $scope.$on('adfDashboardChanged', function (event, name, model) {
               var dashboardLink = _.find($scope.menuLinks, function (link) {
                   return link.title === 'menu.dashboard';
               });
               if (dashboardLink) {
                   $scope.dashboardLink.notification =  null;
                   dashboardLink.notification = null;
               }
            });


            var statusLink = {
                target : 'main.home',
                title : 'menu.home',
                icon : 'fa-home'
            };
            var readoutLink = {
                target : 'main.readout',
                title : 'menu.readout',
                icon : 'fa-eye'
            };
            var dashboardLink = {
                target : 'main.dashboard',
                title : 'menu.dashboard',
                icon : 'fa-th-large',
                disabled : true
            };
            $scope.dashboardLink = dashboardLink;
            var filaManagerLink = {
                target : 'main.fileManager',
                title : 'main.fileManager',
                icon : 'fa-folder'
            };


            var energyReportLink = {
                target : 'main.reportEnergy',
                title : 'menu.report.energy',
                icon : 'fa-th-large',
                disabled : true
            };

            var reportLink = {
                target : 'main.report.table',
                title : 'menu.report',
                icon : 'fa-bar-chart-o',
                children : [ {
                    target : 'main.reportHistory',
                    title : 'menu.report.history',
                    icon : 'fa-history'
                }, {
                    target : 'main.reportChart',
                    title : 'menu.report.chart',
                    icon : 'fa-bar-chart-o'
                }]
            };

            $scope.activeLink = null;

            $scope.setActive = function(link) {
                var configOK = true;
                if ($scope.activeLink === "main.config" ){
                    if($scope.config.notification){
                        configOK = false;
                    }
                }
                if ($scope.activeLink === "main.dashboard" ){
                    if ($scope.dashboardLink.notification) {
                        configOK = false;
                    }
                }
                if (configOK) {
                    if(!link.children) {
                        $scope.activeLink = link.target;
                    }
                }
            };

            var helpLinks=[{
                target : 'main.about',
                title : 'menu.help.about',
                icon : 'fa-info-circle'
            }]
            var license = licenseService.getLicense();
            if(_.indexOf(license.enabledModules,"F&F_BASIC")>=0){
                helpLinks.push({
                    target : contextPath+'/resources/pdf/MeternetPRO_' + Locale + '.pdf',
                    title : 'menu.help.pdf',
                    icon : 'fa-book',
                    resource: true
                }, {
                    target : contextPath+'/resources/pdf/MeternetPROshort_' + Locale + '.pdf',
                    title : 'menu.help.pdf.short',
                    icon : 'fa-book',
                    resource: true
                });
            }


            helpLinks.push({
                target : contextPath+'/',
                title : 'clear.cache',
                icon : 'fa-low-vision',
                resource: false ,
                click : function(){
                    dataServiceCache.clear().then(function(){
                    angular.element('#cacheModalShower').trigger('click');
                    });
                }
            });

            $scope.menuLinks = [
                dashboardLink,
                {
                    target : 'main.users',
                    title : 'menu.users',
                    icon : 'fa-user'
                },{
                    target : 'main.help',
                    title : 'menu.help',
                    icon : 'fa-life-ring ',
                    children : [ {
                        target : 'main.about',
                        title : 'menu.help.about',
                        icon : 'fa-info-circle'
                    }, {
                        target : contextPath+'/resources/pdf/MeternetPRO_' + Locale + '.pdf',
                        title : 'menu.help.pdf',
                        icon : 'fa-book',
                        resource: true
                    }, {
                        target : contextPath+'/resources/pdf/MeternetPROshort_' + Locale + '.pdf',
                        title : 'menu.help.pdf.short',
                        icon : 'fa-book',
                        resource: true
                    },{
                        target : contextPath+'/',
                        title : 'clear.cache',
                        icon : 'fa-low-vision',
                        resource: false ,
                        click : function(){
                            dataServiceCache.clear().then(function(){
                                angular.element('#cacheModalShower').trigger('click');
                            });
                        }
                    }
                    ]
                }];

            var adminMenuLinks = [ {
                target : 'main.config',
                title : 'menu.config',
                icon : 'fa-cog'
                }
            ];

             var gamificationMenuLinks = [ {
                target : 'main.timetable',
                title : 'menu.timetable',
                icon : 'fa-calendar',
                children: [
                    {
                        target : 'main.timetable',
                        title : 'menu.timetable',
                        icon : 'fa-calendar'
                    }, {
                        target : 'main.parameterScoresList',
                        title : 'menu.parameterScores',
                        icon : 'fa-edit'
                    }, {
                        target: 'main.userScoresReport',
                        title: 'menu.userScoresReport',
                        icon: 'fa-pie-chart'
                    }

                ]
            }];

            if (user.isAdmin()){
                $scope.menuLinks.splice(1, 0, adminMenuLinks[0]);
            }
            if(!_.find(user.permissions.otherDenyRead, function(link){ return link =="fileManager" })){
                $scope.menuLinks.splice(2, 0, filaManagerLink);
            }
            if(!_.find(user.permissions.otherDenyRead, function(link){ return link =="report" })){
                $scope.menuLinks.splice(0, 0, reportLink);
            }
            if(!_.find(user.permissions.otherDenyRead, function(link){ return link =="readout" })){
                $scope.menuLinks.splice(0, 0, readoutLink);
            }
            if(!_.find(user.permissions.otherDenyRead, function(link){ return link =="status" })){
                $scope.menuLinks.splice(0, 0, statusLink);
            }

            $scope.isActive = function(menuLink) {
                return $scope.activeLink === menuLink.target;
            };

            $scope.authenticated = false;

            dashboardService.getDashboardMenuLink().then(function(dashboardMenuLink) {
                dashboardMenuLink.children = _.sortBy(dashboardMenuLink.children,  function(o) {
                    return o.name;
                });
                $scope.dashboardIdx = _.indexOf($scope.menuLinks, dashboardLink);
                $scope.menuLinks[$scope.dashboardIdx] = dashboardMenuLink;
            });

            $scope.status = {
                clear : true
            };

            var notificationsConf = localStorageService.get('ui.notifications') || {};
            $scope.notificationsConf = {
                onoff: notificationsConf.onoff || false,
                sound: notificationsConf.sound || false,
                setOnoff: function(){
                    $scope.notificationsConf.onoff = !$scope.notificationsConf.onoff;
                    localStorageService.set('ui.notifications', $scope.notificationsConf);
                },
                setSound: function(){
                    $scope.notificationsConf.sound = !$scope.notificationsConf.sound;
                    localStorageService.set('ui.notifications', $scope.notificationsConf);
                }

            }

            $scope.ui = {
                connectionStatus : false,
                canAddDashboard: false,
                addNewDashboard: function () {
                    if (this.canAddDashboard) {
                        dashboardService.add().then(function () {
                            dashboardService.getDashboardMenuLink().then(function (dashboardMenuLink) {
                                $scope.menuLinks[$scope.dashboardIdx] = dashboardMenuLink;
                                var target = dashboardMenuLink.children[dashboardMenuLink.children.length - 1].target;
                                var dashboardName = target.substring(target.indexOf("\"") + 1, target.lastIndexOf("\""));
                                $state.go('main.dashboard', {
									dashboardName: dashboardName
								});
                                setCanAddDashboard();
                            });
                        });
                    }
                }
            };

            var setCanAddDashboard = function(){
                licenseService.get().then(function(license) {
                    var maxDashboards = license.features.maxDashboards;
                    dashboardService.getDashboardsCount().then(function(dashboardsCount) {
                        $scope.ui.canAddDashboard = maxDashboards > dashboardsCount;
                    });
                });
            };
            setCanAddDashboard();

            var statusTopic = "/topic/status";
            dataService.subscribe(statusTopic, function($event, data) {
                tools.copy(data, $scope.status);
                $scope.status.clear = false;
            });
            $scope.connectionStatus = dataService.onDataConnectionStatusChange($scope, function(event, status) {
                $scope.ui.connectionStatus = status;
            });

            var energyReportLink = {
                target : 'main.reportEnergy',
                title : 'menu.report.energy',
                icon : 'fa-plug',
                children : []
            };

            var license = licenseService.getLicense();

            if (license.properties['ENERGY_REPORT_ENABLED'] === true) {
                energyReportService.getEnergyReportMenuLink().then(function(energyReportMenuLinks) {
                    _.each(energyReportMenuLinks, function(energyReportMenuLink) {
                        reportLink.children.push(energyReportMenuLink);
                    });
                });
            }

            if(user.isAdmin() && license.properties['GAMIFICATION_REPORT_ENABLED'] === true){
                $scope.menuLinks.splice(5, 0, gamificationMenuLinks[0]);
            }

            if(_.indexOf(license.enabledModules,"ETI_BASIC")>=0){
                $scope.ui.mainLicense = "ETI";
            }else if(_.indexOf(license.enabledModules,"FINN_BASIC")>=0){
                $scope.ui.mainLicense = "FINN";
            }else if(_.indexOf(license.enabledModules,"EFEN_BASIC")>=0){
                $scope.ui.mainLicense = "EFEN";
            }else if(_.indexOf(license.enabledModules,"BSH_BASIC")>=0){
                 $scope.ui.mainLicense = "BSH";
            }else if(_.indexOf(license.enabledModules,"FINN_BASIC")>=0){
                $scope.ui.mainLicense = "FINN";
            }else{
                $scope.ui.mainLicense = "FIF";
            }

            $scope.config = _.findWhere($scope.menuLinks, {target: "main.config"});
        },
        templateUrl: 'navigation/menu.html',
        link : function(scope, elem, attrs) {
            var t = app.name + " v" + app.version + " rev. " + app.revision;
            if (app.timestamp) {
                t += moment(new Date(app.timestamp)).format(" (YYYY-MM-DD HH:mm:ss)");
            }
            elem.find("a.navbar-brand").attr("title", t);
        }
    };

});

<form class="form-horizontal">
    <!--<label class="control-label">{{'config.name' | i18n}}</label>-->
    <!--<input class="form-control" x-ng-model="output.name" />-->

    <label class="control-label">{{'config.choose' | i18n}}</label>
    <div class="row">
        <div class="col-sm-3" x-ng-repeat="o in ui.outputs| filter: {type:'internal'}">
            <div class="thumbnail" style="opacity: 0.4; filter: alpha(opacity=40);">
                <!--<div class="thumbnail-price"><i class="fa fa-shopping-cart"></i> {{o.cost}}</div>-->
                <img alt="" src="" x-ng-src="{{o.iconUrl}}"/>
                <div class="caption">
                    <span class="caption-label">{{'config.eventOutput.type.' + o.type | i18n}}</span>
                </div>
            </div>
        </div>
        <div class="col-sm-3" x-ng-repeat="o in ui.outputs| filter: {type:'!internal'}">
            <div class="thumbnail" x-ng-class="ui.active(o)" x-ng-click="ui.select(o)">
                <!--<div class="thumbnail-price"><i class="fa fa-shopping-cart"></i> {{o.cost}}</div>-->
                <img alt="" src="" x-ng-src="{{o.iconUrl}}"/>
                <div class="caption">
                    <span class="caption-label">{{'config.eventOutput.type.' + o.type | i18n}}</span>
                </div>
            </div>
        </div>
    </div>
</form>

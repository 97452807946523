/*global tools */
var module = angular.module('meternet.measurements.services', []);

function MeasurementsService($q, $http, contextPath) {
    function LampSerie(data) {
        this.update(data);
    }

    LampSerie.prototype.update = function(data) {
        tools.copy(data, this);
        for ( var i in this.lamps) {
            this.lamps[i] = new Lamp(this.lamps[i]);
        }
    };

    LampSerie.prototype.getClassName = function() {
        return "LampSerie";
    };

    LampSerie.prototype.addLamp = function(data) {
        var lamp = new Lamp(data);
        this.lamps.push(lamp);
        return lamp;
    };

    function Lamp(data) {
        this.update(data);
    }

    Lamp.prototype.update = function(data) {
        tools.copy(data, this);
    };

    Lamp.prototype.getClassName = function() {
        return "Lamp";
    };

    function Report(data) {
        this.update(data);
    }

    Report.prototype.update = function(data) {
        tools.copy(data, this);
        this.realFilename = this.filePath.split("/")[2];
    };

    // API
    return {
        createSerie : function(data) {
            return new LampSerie(data);
        },
        getSeries : function() {
            var deferred = $q.defer();
            var url = contextPath + '/series';
            $http({
                method : 'GET',
                url : url
            }).success(function(data, status, headers) {
                var series = [];
                for ( var i = 0; i < data.length; i++) {
                    series.push(new LampSerie(data[i]));
                }
                deferred.resolve(series);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },

        getReports : function(lamp) {
            var deferred = $q.defer();
            var url = contextPath + '/reportFiles?itemId=' + lamp.itemId;
            $http({
                method : 'GET',
                url : url
            }).success(function(data, status, headers) {
                var reports = [];
                for ( var i = 0; i < data.length; i++) {
                    reports.push(new Report(data[i]));
                }
                deferred.resolve(reports);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        getReport : function(id) {
            var deferred = $q.defer();
            var url = contextPath + "/reportFile?reportId=" + id;
            $http({
                method : 'GET',
                url : url
            }).success(function(data, status, headers) {
                deferred.resolve(new Report(data));
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        deleteReport : function(report) {
            var deferred = $q.defer();
            $http({
                method : 'DELETE',
                url : contextPath + "/reportFile?reportId=" + report.id,
                data : report
            }).success(function(data, status, headers) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        deleteSerie : function(serie) {
            var deferred = $q.defer();
            $http({
                method : 'DELETE',
                url : contextPath + "/series?serieId=" + serie.modelId
            }).success(function(data, status, headers) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        deleteLamp : function(lamp) {
            var deferred = $q.defer();
            $http({
                method : 'DELETE',
                url : contextPath + "/lamps?lampId=" + lamp.itemId
            }).success(function(data, status, headers) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        getMeasuredValues : function(lampId) {
            var deferred = $q.defer();
            var url = contextPath + "/lamps/values?lampItemId=" + lampId;
            $http({
                method : 'GET',
                url : url
            }).success(function(data, status, headers) {
                deferred.resolve(data);
            }).error(function() {
                deferred.reject();
            });
            return deferred.promise;
        },
        saveLamp : function(data) {
            var deferred = $q.defer();
            var url = contextPath + '/lamps';
            if (!angular.isDefined(data.itemId)) {
                $http({
                    method : 'POST',
                    url : url,
                    data : data
                }).success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
                return deferred.promise;
            } else {
                $http({
                    method : 'PUT',
                    url : url,
                    data : data
                }).success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
                return deferred.promise;
            }
        },
        saveSerie : function(data) {
            var deferred = $q.defer();
            var url = contextPath + '/series';
            if (!angular.isDefined(data.modelId)) {
                $http({
                    method : 'POST',
                    url : url,
                    data : data
                }).success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
                return deferred.promise;
            } else {
                $http({
                    method : 'PUT',
                    url : url,
                    data : data
                }).success(function(data, status, headers) {
                    deferred.resolve(data);
                }).error(function() {
                    deferred.reject();
                });
                return deferred.promise;
            }
        }
    };
}
module.service('measurementsService', MeasurementsService);
<form class="form-horizontal">

    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.ntp.ntp' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="ntp.ntp" name="ntp"
                       x-field-help="config.ntp.ntp.help">
            </div>
        </div>
    </div>

    <div class="form-group">
        <label class="control-label col-sm-3">{{'config.ntp.fallbackntp' | i18n}}</label>
        <div class="col-sm-8">
            <div class="input-group">
                <input type="text" class="form-control" data-ng-model="ntp.fallbackntp" name="fallbackntp"
                       x-field-help="config.ntp.fallbackntp.help">
            </div>
        </div>
    </div>

</form>
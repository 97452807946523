<div style="display: flex; gap: 5px;">
	<ui-select ng-model="_chooser.device"
			   theme="bootstrap"
			   ng-disabled="ctrl.disabled"
			   style="width: 100px; flex-grow: 1;">
		<ui-select-match
			placeholder="&#xF002; {{'config.search' | i18n}}"
			popover-trigger="mouseenter" popover-placement="right"
			uib-popover="{{ui.deviceLabel($select.selected)}}">
			{{ui.deviceLabel($select.selected)}}
		</ui-select-match>
		<ui-select-choices
				repeat="device in _chooser.devices | propsFilter: {name: $select.search, label: $select.search, desc: $select.search, desc2: $select.search, desc3: $select.search}"
				style="min-width: 300px;">
			<div ng-bind-html="ui.deviceLabel(device) | highlight: $select.search"></div>
			<div class="ui-select-choices-descr" x-ng-if="device.desc"><b>1:</b> {{device.desc}}</div>
			<div class="ui-select-choices-descr" x-ng-if="device.desc2"><b>2:</b> {{device.desc2}}</div>
			<div class="ui-select-choices-descr" x-ng-if="device.desc3"><b>3:</b> {{device.desc3}}</div>
		</ui-select-choices>
	</ui-select>

	<ui-select ng-model="_chooser.param"
			   theme="bootstrap"
			   ng-disabled="ctrl.disabled"
			   style="width: 100px; flex-grow: 1;">
		<ui-select-match placeholder="&#xF002; {{'config.search' | i18n}}"
						 popover-trigger="mouseenter"
						 popover-placement="right"
						 uib-popover="{{ui.paramLabel($select.selected)}}">
			{{ui.paramLabel($select.selected)}}
		</ui-select-match>
		<ui-select-choices
				repeat="param in _chooser.device.params | propsFilter: {name: $select.search, label: $select.search, desc: $select.search}"
				style="min-width: 200px;">
			<div ng-bind-html="ui.paramLabel(param) | highlight: $select.search"></div>
			<div class="ui-select-choices-descr" x-ng-if="param.desc">{{param.desc}}</div>
		</ui-select-choices>
	</ui-select>

	<button x-ng-if="ui.showHelp"
			type="button"
			uib-popover="{{'config.device.param.choice.help' | i18n}}"
			popover-trigger="mouseenter"
			popover-placement="right"
			class="btn btn-primary">?
	</button>
</div>


(function() {
	'use strict';
	var module = angular.module('meternet.footer', [ 'meternet.inline-js' ]);
	module.directive('footer',
			[
					'contextPath',
					'$state',
					function(contextPath, $state) {

						return {
							scope : {},
							controller : [ '$scope', '$http', 'contextPath', FooterCtrl ],
							templateUrl: 'navigation/footer.html',
							link : function($scope, $element) {
								
							}
						};
					} ]);

	function FooterCtrl($scope, $http, contextPath) {
	}
}());
var module = angular.module('meternet.chart.directives.deviceCalendar', ['meternet.charts.services', 'meternet.utils.custom-time-picker']);

function setZeroTime(day) {
    var zeroTime = new Date(day.getTime());
    zeroTime.setHours(0);
    zeroTime.setMinutes(0);
    zeroTime.setSeconds(0);
    return zeroTime;
}

function setMidnightTime(day) {
    var midnightTime = new Date(day.getTime());
    midnightTime.setHours(23);
    midnightTime.setMinutes(59);
    midnightTime.setSeconds(59);
    return midnightTime;
}

module.directive('deviceCalendar', function ($q, $http, $stateParams, $timeout, dashboardService, configService, Errors) {
    return {
        scope: {
            options: '='
        },
        templateUrl: 'dashboard/calendar/device-calendar.html',
        controller: function ($scope, $location) {
            $scope.errors = new Errors();
            $scope.indicators = [];
            $scope.exceptions = [];
            $scope.dawnDuskEvents = [];
            $scope.holidays = [];
            $scope.eventSources = [$scope.indicators, $scope.exceptions, $scope.dawnDuskEvents, $scope.holidays];
            $scope.devices = [];
            $scope.lightingSettings = [];
            $scope.ui = {
                view: 'calendar-schedule',
                css: {
                    class: null,
                    icon: null
                },
                newExceptions: [],
                someExceptions: [],
                someDevices: [],
                activeScheduler: null,
                processing: false,
                message: null,
                wasLoadedFromConfig: false,
                onTotalWeekTime: 0,
                onTotalMonthTime: 0,
                monthName: "",
                dirty: false,
                backToMap: function () {
                    $scope.$emit('backToMap');
                },
                showMessage: function (text, css) {
                    this.message = {
                        text: text
                    };
                    this.css = css
                    $timeout.cancel(this.messagePromise);
                    this.messagePromise = $timeout(function () {
                        $scope.ui.message = null;
                    }, 5000);
                },
            };
            $scope.uiConfig = {
                calendar: {
                    plugins: ['dayGrid', 'timeGrid', 'interaction'],
                    defaultView: 'timeGridWeek',
                    themeSystem: 'bootstrap3',
                    header: {
                        left: '',
                        center: 'title',
                        right: 'today prev,next'
                    },
                    height: 'auto',
                    slotDuration: '01:00',
                    allDaySlot: false,
                    slotEventOverlap: false,
                    // wskaznik wlaczenia zawsze po lewej stronie, wskaźnik switu/zmierzchu po prawej
                    eventOrder: 'indicator,start,-duration,allDay,title,dawnDusk',
                    eventRender: function (info, element) {
                        if (info.event.extendedProps.indicator) {
                            info.el.classList.add('fc-event-indicator');
                            $(info.el).popover({
                                animation:true,
                                delay: 300,
                                content: info.event.end ? info.event.start.toLocaleString() + " - " + info.event.end.toLocaleString() : info.event.start.toLocaleString(),
                                trigger: 'hover',
                                html: true
                            });
                        }
                        if (info.event.extendedProps.dawnDusk) {
                            info.el.classList.add('fc-dawn-dusk')
                        }
                    },
                    datesRender: function (info) {
                        $scope.renderIndicators(info);
                    },
                    slotLabelFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                        // omitZeroMinute: true,
                        hour12: false
                    },
                    eventTimeFormat: {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    },
                    selectable: true,
                    select: function (selectionInfo) {
                        if ($scope.editable) {
                            findSameDayExceptions(selectionInfo);
                            $scope.ui.newExceptions.push({
                                start: selectionInfo.start,
                                end: selectionInfo.end,
                                type: 'on'
                            });
                            $scope.ui.newExceptions = _.sortBy($scope.ui.newExceptions, function (e) {
                                return e.start;
                            });
                            $('#exceptionSetModal').modal('show');
                        }
                    },
                    eventClick: function (eventClickInfo) {
                        if ($scope.editable) {
                            $scope.eventOnClick(eventClickInfo);
                        }
                    },
                }
            };

            dashboardService.isDashboardEditable($stateParams.dashboardName).then(function (editable) {
                $scope.editable = editable;
            });

            configService.get().then(function (config) {
                prepareLightingSettings(config, false);
            });

            $scope.getHashId = function () {
                var hash = $location.hash().split('&');
                return hash[0].replace('lightingSetting=', '');
            }

            $scope.addIndicatorEvent = function (start, end, type) {
                $scope.indicators.push({
                    start: start,
                    end: end,
                    color: type === 'on' ? '#f2bd33' : '#8c8c8c',
                    indicator: true
                });
            }

            $scope.addDawnDuskIndicatorEvent = function (start, color) {
                var end = new Date(start.getTime());
                end.setMinutes(end.getMinutes() + 1)
                $scope.dawnDuskEvents.push({
                    start: start,
                    end: end,
                    dawnDusk: true,
                    color: color
                });
            }

            $scope.addHolidayEvent = function (event) {
                var evt = {
                    color: '#c8cdea',
                    allDay: true,
                    rendering: 'background',
                }
                Object.assign(evt, event)
                $scope.holidays.push(evt);
            };

            $scope.addExceptionEvent = function (exception) {
                var color = exception.type === 'off' ? '#546E7A' : '#F4511E';

                var evt = {
                    color: color,
                    start: tools.parseDateTime(exception.start),
                    end: tools.parseDateTime(exception.end),
                    extendedProps: {
                        exception: exception
                    },
                    title: exception.description || "",
                    classNames: $scope.editable ? ['editable'] : []
                };
                $scope.exceptions.push(evt);
            };

            $scope.renderIndicators = function (info) {
                var daysOnView = 7;

                var view = info.view;
                var currStart = view.currentStart;

                // calculate particular days
                var days = [];
                var tmp;
                for (var i = 0; i < daysOnView; i++) {
                    tmp = new Date(currStart.valueOf());
                    tmp.setDate(currStart.getDate() + i);
                    days.push(tmp);
                }

                // calculate monthly on time
                var dayInMonth = [];
                var temp;
                var daysInMonth = new Date(currStart.getYear(), currStart.getMonth(), 0).getDate();
                for (var i = 1; i <= daysInMonth; i++) {
                    temp = new Date(currStart.valueOf());
                    temp.setDate(i);
                    dayInMonth.push(temp);
                }
                var month = currStart.getMonth() + 1
                if (month < 10) {
                    $scope.ui.monthName = "0" + month + "/" + currStart.getFullYear();
                } else {
                    $scope.ui.monthName = month + "/" + currStart.getFullYear();
                }
                $scope.dawnDuskEvents.length = 0;
                $scope.indicators.length = 0;
                $scope.ui.onTotalWeekTime = 0;
                $scope.ui.onTotalMonthTime = 0;
                days.forEach(function (day) {
                    var times = getDawnDuskTimes(day);
                    var dawn = times.dawn;
                    var dusk = times.dusk;
                    $scope.addDawnDuskIndicatorEvent(dawn, '#e68a0f');
                    $scope.addDawnDuskIndicatorEvent(dusk, '#224f77');
                    $scope.addSwitchingTimes(day, true, "onTotalWeekTime");
                })
                dayInMonth.forEach(function (day) {
                    $scope.addSwitchingTimes(day, false, "onTotalMonthTime");
                })
            };

            $scope.addSwitchingTimes = function (day, draw, period) {
                var switchingArray = [];
                $scope.addOnOffFromException(day, switchingArray);
                var activeScheduler = tools.getActiveScheduler(day, $scope.selectedLightingSetting.schedulers);
                if (activeScheduler) {
                    $scope.addOnOffFromScheduler(day, activeScheduler, switchingArray);
                } else {
                    var times = getDawnDuskTimes(day);
                    var dawn = times.dawn;
                    var dusk = times.dusk;
                    $scope.addOnOffFromDawnDusk(dawn, dusk, switchingArray);
                }
                // (ak) prepare switchingArray with on only
                switchingArray = _.sortBy(switchingArray, function (e) {
                    return e.start;
                });
                var on = _.filter(switchingArray, function (t) {
                    return t.type === 'on';
                });
                var off = _.filter(switchingArray, function (t) {
                    return t.type === 'off';
                });
                prepareSwitchingArray(on);
                prepareSwitchingArray(off);
                switchingArray = mergeOnOffTimes(on, off);
                // (ak) add offTimes
                var offArray = [];
                if (switchingArray.length === 0) {
                    offArray.push({start: setZeroTime(day), end: setMidnightTime(day)});
                }
                _.forEach(switchingArray, function (time, idx) {
                    if (idx === 0) {
                        if (time.start.getHours() !== 0 || (time.start.getHours() === 0 && time.start.getMinutes() !== 0)) {
                            var zeroTime = setZeroTime(day);
                            offArray.push({start: zeroTime, end: time.start});
                        }
                        if (switchingArray.length !== 1 || (switchingArray.length === 1 && (time.end.getHours() !== 23 || (time.end.getHours() === 23 && time.end.getMinutes() !== 59)))) {
                            offArray.push({start: time.end});
                        }
                    } else if (idx === switchingArray.length - 1) {
                        offArray[offArray.length - 1].end = time.start;
                        if (time.end.getHours() !== 23 || (time.end.getHours() === 23 && time.end.getMinutes() !== 59)) {
                            var midnightTime = setMidnightTime(day);
                            offArray.push({start: time.end, end: midnightTime});
                        }
                    } else {
                        offArray[offArray.length - 1].end = time.start;
                        offArray.push({start: time.end});
                    }
                    if (switchingArray.length === 1 && (time.end.getHours() !== 23 || (time.end.getHours() === 23 && time.end.getMinutes() !== 59))) {
                        offArray[offArray.length - 1].end = setMidnightTime(day);
                    }
                });

                // (ak) draw indicators
                _.forEach(switchingArray, function (time) {
                    if (draw) {
                        $scope.addIndicatorEvent(time.start, time.end, time.type);
                    }
                    sumOnTimes(time, period);
                });
                if (draw) {
                    _.forEach(offArray, function (time) {
                        $scope.addIndicatorEvent(time.start, time.end, 'off');
                    })
                }
            };

            $scope.addOnOffFromException = function (day, switchingArray) {
                _.forEach($scope.selectedLightingSetting.exceptions, function (exception) {
                    var startDate = new Date(exception.start);
                    var endDate = new Date(exception.end);
                    if (sameDay(startDate, day) && sameDay(endDate, day)) {
                        switchingArray.push({
                            start: tools.parseDateTime(exception.start),
                            end: tools.parseDateTime(exception.end),
                            type: exception.type
                        });
                    } else if (sameDay(startDate, day)) {
                        var midnightTime = setMidnightTime(day);
                        switchingArray.push({
                            start: tools.parseDateTime(exception.start),
                            end: midnightTime,
                            type: exception.type
                        });
                    } else if (sameDay(endDate, day)) {
                        var zeroTime = setZeroTime(day);
                        switchingArray.push({
                            start: zeroTime,
                            end: tools.parseDateTime(exception.end),
                            type: exception.type
                        });
                    } else if (moment(day).isBetween(startDate, endDate)) {
                        var zeroTime = setZeroTime(day);
                        var midnightTime = setMidnightTime(day);
                        switchingArray.push({
                            start: zeroTime,
                            end: midnightTime,
                            type: exception.type
                        });
                    }
                });
            };

            $scope.addOnOffFromScheduler = function (day, activeScheduler, switchingArray) {
                var dayId = getDayId(day);
                // add indicators based on dawn/dusk from scheduler
                var times = getDawnDuskTimes(day);
                var dawn = times.dawn;
                var dusk = times.dusk;

                if (!activeScheduler[dayId].dawnShift || (activeScheduler[dayId].dawnShift && activeScheduler[dayId].dawnShift.dir !== 'none')) {
                    dawn = setShift(activeScheduler, dayId, "dawnShift", dawn);
                } else {
                    dawn = null;
                }
                if (!activeScheduler[dayId].duskShift || (activeScheduler[dayId].duskShift && activeScheduler[dayId].duskShift.dir !== 'none')) {
                    dusk = setShift(activeScheduler, dayId, "duskShift", dusk);
                } else {
                    dusk = null;
                }
                $scope.addOnOffFromDawnDusk(dawn, dusk, switchingArray);

                if (activeScheduler[dayId].switchingTimes && activeScheduler[dayId].switchingTimes.length > 0) {
                    _.forEach(activeScheduler[dayId].switchingTimes, function (event) {
                        var start = tools.parseTime(event.start);
                        var end = tools.parseTime(event.end);
                        setDateAfterDate(start, day);
                        setDateAfterDate(end, day);
                        // (ak) set proper date (next day cause problems)
                        if (end.getHours() === 0 && end.getMinutes() === 0 && end.getSeconds() === 0) {
                            end.setHours(23);
                            end.setMinutes(59);
                            end.setSeconds(59);
                        }
                        switchingArray.push({
                            start: start,
                            end: end,
                            type: 'on'
                        });
                    });
                }
            };

            $scope.addOnOffFromDawnDusk = function (dawn, dusk, switchingArray) {
                // (ak) can dusk/dawn be at 00:00:00? see 232
                if (dawn) {
                    if (dawn.getHours() !== 0 || (dawn.getHours() === 0 && dawn.getMinutes() !== 0)) {
                        var beforeStart = setZeroTime(dawn);
                        switchingArray.push({start: beforeStart, end: dawn, type: 'on'});
                    }
                }
                if (dusk) {
                    if (dusk.getHours() !== 23 || (dusk.getHours() === 23 && dusk.getMinutes() !== 59)) {
                        var afterEnd = setMidnightTime(dusk);
                        switchingArray.push({start: dusk, end: afterEnd, type: 'on'});
                    }
                }
            };

            $scope.updateLightingSettings = function () {
                if ($scope.ui.processing) { // lock preventing double submit
                    return;
                }
                $scope.ui.processing = true
                configService.save($scope.config)
                    .then(function (res) {
                        $scope.errors = new Errors(res.errors);
                        $scope.ui.processing = false;
                        $scope.ui.wasLoadedFromConfig = true;
                        prepareLightingSettings(res.config);
                        $scope.$emit('setDirty', {dirty: false, withBroadcast: true});
                        $scope.ui.showMessage('config.save.success', {class: 'alert-success', icon: 'fa-check'});
                    })
                    .catch(function (err) {
                        $scope.errors = new Errors(err.errors);
                        $scope.ui.showMessage('config.save.failure', {
                            class: 'alert-danger',
                            icon: 'fa-exclamation-triangle'
                        });
                        $scope.ui.processing = false
                    })
            };

            $scope.updateUrl = function () {
                var foundIndex = tools.getActiveSchedulerForCurrent($scope.selectedLightingSetting.schedulers);
                if ($scope.selectedLightingSetting.schedulers.length > 0) {
                    $location.hash('lightingSetting=' + $scope.selectedLightingSetting.id + '&scheduler=' + $scope.selectedLightingSetting.schedulers[foundIndex].id);
                } else {
                    $location.hash('lightingSetting=' + $scope.selectedLightingSetting.id);
                }
            };

            $scope.cancel = function () {
                if ($scope.ui.processing) { // lock preventing double submit
                    return;
                }
                $scope.ui.processing = true;
                configService.reset().then(function (config) {
                    $scope.ui.wasLoadedFromConfig = true;
                    prepareLightingSettings(config, true);
                    $scope.ui.processing = false;
                    $scope.errors = new Errors();
                });
            }

            $scope.eventOnClick = function (eventClickInfo) {
                var edited = eventClickInfo.event.extendedProps;
                // do not edit dawn/dusk indicators
                if (edited.dawnDusk || edited.indicator) {
                    return
                }
                var clickedException = {};
                clickedException.start = tools.parseDateTime(edited.exception.start);
                clickedException.end = tools.parseDateTime(edited.exception.end);
                findSameDayExceptions(clickedException);
                $scope.ui.newExceptions = _.sortBy($scope.ui.newExceptions, function (e) {
                    return e.start;
                });
                $('#exceptionSetModal').modal('show');
            }

            $scope.saveException = function () {
                $scope.selectedLightingSetting.exceptions = [];
                _.forEach($scope.ui.newExceptions, function (exception) {
                    if (exception.end < exception.start) {
                        var tomorrow = new Date(exception.start);
                        tomorrow.setDate(tomorrow.getDate()+1);
                        tomorrow.setHours(exception.end.getHours(), exception.end.getMinutes(), exception.end.getSeconds());
                        exception.end = tomorrow;
                    }
                    exception.start = tools.formatDateTime(exception.start);
                    exception.end = tools.formatDateTime(exception.end);
                    delete exception.exception;
                    $scope.selectedLightingSetting.exceptions.push(exception);
                });
                $scope.selectedLightingSetting.exceptions = $scope.selectedLightingSetting.exceptions.concat($scope.ui.someExceptions);
                $scope.resetException();
            }

            $scope.addException = function () {
                $scope.ui.newExceptions.push({
                    start: null,
                    end: null,
                    type: 'on'
                });
            }

            $scope.resetException = function () {
                $scope.ui.newExceptions = [];
                $scope.ui.someExceptions = [];
            }

            $scope.removeException = function (index) {
                $scope.ui.newExceptions.splice(index, 1);
            }

            $scope.$watch('config', function (newVal, oldVal) {
                if (newVal && oldVal) {
                    if ($scope.ui.wasLoadedFromConfig) {
                        $scope.ui.wasLoadedFromConfig = false;
                    } else {
                        $scope.$emit('setDirty', {dirty: true});
                        $scope.ui.dirty = true;
                    }
                }
            }, true);

            $scope.calculateLinkedDevices = function(options) {
                var opts = options || $scope.options;
                $scope.devices.length = 0;
                _.forEach(opts.series, function (serie) {
                    if (serie) {
                        // Naming inconsistency. $scope.devices should be actually called $scope.groups
                        var devs = _.filter(serie.groups, {
                            type: "mbsat",
                            lightingSettingId: $scope.getHashId()
                        });
                        $scope.devices.push.apply($scope.devices, devs)
                    }
                });
                $scope.first_device_geolocation = {};
                if (opts.series.length && $scope.devices.length) {
                    var pos = tools.getEffectiveGeolocation($scope.devices[0], opts.series[0]);
                    $scope.first_device_geolocation.lat = pos[0];
                    $scope.first_device_geolocation.lon = pos[1];
                }

            };
            $scope.$watch('options', function (newVal) {
                // necessary when reloading page
                if (newVal) {
                    $scope.calculateLinkedDevices(newVal)
                }
            }, true);

            $scope.$watch('selectedLightingSetting', function (newVal) {
                $scope.holidays.length = 0;
                if (newVal) {
                    _.forEach($scope.selectedLightingSetting.holidays, function (event) {
                        $scope.addHolidayEvent(event)
                    })
                    $scope.exceptions.length = 0;
                    _.forEach($scope.selectedLightingSetting.exceptions, function (exception) {
                        $scope.addExceptionEvent(exception);
                    })
                    $scope.calculateLinkedDevices();
                    $scope.ui.someDevices = _.filter($scope.devices, {lightingSettingId: $scope.selectedLightingSetting.id})
                }
            }, true);

            $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) {
                var id = $scope.getHashId();
                if ($scope.selectedLightingSetting && id !== $scope.selectedLightingSetting.id) {
                    $scope.selectedLightingSetting = _.findWhere($scope.lightingSettings, {id: id});
                }
            });

            $scope.$on('restore settings', function (e, args) {
                $scope.selectedLightingSetting = _.findWhere($scope.lightingSettings, {id: args.setId});
            })

            $('#exceptionSetModal').on('hide.bs.modal', function (e) {
                $scope.resetException();
            });

            function sumOnTimes(time, period) {
                $scope.ui[period] += Math.abs(time.end - time.start) / 36e5;
            }

            function prepareLightingSettings(config, withBroadcast) {
                $scope.config = config;
                $scope.lightingSettings = config.engine.lightingSettings;
                var id = $scope.selectedLightingSetting ? $scope.selectedLightingSetting.id : $scope.getHashId();
                $scope.selectedLightingSetting = _.findWhere($scope.lightingSettings, {id: id});
                $scope.$emit('setDirty', {dirty: false, withBroadcast: withBroadcast});
                $scope.ui.dirty = false;
            }

            function getDawnDuskTimes(day) {
                var lat = $scope.first_device_geolocation.lat || 51.7592;
                var lon = $scope.first_device_geolocation.lon || 19.4560;

                var times = tools.calculateSunTimes(day, lat, lon);
                var activeScheduler = tools.getActiveScheduler(day, $scope.selectedLightingSetting.schedulers);
                // TODO ws get default kind from config?
                return tools.getTimesByKind(times, activeScheduler ? activeScheduler.twilightType : 'civil');
            }

            function addExceptionToEventList(exception) {
                $scope.ui.newExceptions.push({
                    start: tools.parseDateTime(exception.start),
                    end: tools.parseDateTime(exception.end),
                    description: exception.description,
                    type: exception.type,
                    exception: exception
                })
            }

            function findSameDayExceptions(selectionInfo) {
                _.forEach($scope.selectedLightingSetting.exceptions, function (exception) {
                    var startDate = new Date(exception.start);
                    var endDate = new Date(exception.end);
                    if (sameDay(startDate, selectionInfo.start) || sameDay(endDate, selectionInfo.start) || sameDay(startDate, selectionInfo.end)) {
                        addExceptionToEventList(exception);
                    } else {
                        $scope.ui.someExceptions.push(exception);
                    }
                });
            }

            function sameDay(d1, d2) {
                return d1.getDate() === d2.getDate() &&
                    d1.getMonth() === d2.getMonth() &&
                    d1.getFullYear() === d2.getFullYear();
            }

            function toSeconds(d) {
                return d.getTime() / 1000 | 0;
            }

            function setDateAfterDate (newDate, oldDate) {
                newDate.setFullYear(oldDate.getFullYear());
                newDate.setMonth(oldDate.getMonth());
                newDate.setDate(oldDate.getDate());
            }

            function getDayId(day) {
                var isHoliday = false;
                _.forEach($scope.selectedLightingSetting.holidays, function (holiday) {
                    if (sameDay(day, new Date(holiday.start))) {
                        isHoliday = true;
                        return false;
                    }
                });
                if (isHoliday) {
                    return 'hol';
                }
                switch (day.getDay()) {
                    case 0:
                        return 'sun';
                    case 1:
                        return 'mon';
                    case 2:
                        return 'tue';
                    case 3:
                        return 'wed';
                    case 4:
                        return 'thu';
                    case 5:
                        return 'fri';
                    case 6:
                        return 'sat';
                }
            }

            function setShift(activeScheduler, dayId, name, time) {
                if (activeScheduler[dayId][name]) {
                    var shift = tools.parseTime(activeScheduler[dayId][name].shift);
                    var dir = activeScheduler[dayId][name].dir;
                    if (dir === 'add') {
                        // (ak) if shift is so big that dawn/dusk is next/previous day, dawn/dusk starts at midnight
                        if ((time.getHours() + shift.getHours() > 23) || (time.getHours() + shift.getHours() === 23
                            && time.getMinutes() + shift.getMinutes() <= 59)) {
                            time = setMidnightTime(time);
                        } else {
                            time.setHours(time.getHours() + shift.getHours());
                            time.setMinutes(time.getMinutes() + shift.getMinutes());
                            time.setSeconds(time.getSeconds() + shift.getSeconds());
                        }
                    } else if (dir === 'sub') {
                        if ((time.getHours() - shift.getHours() < 0) || (time.getHours() - shift.getHours() === 0
                            && time.getMinutes() - shift.getMinutes() <= 0)) {
                            time = setZeroTime(time);
                        } else {
                            time.setHours(time.getHours() - shift.getHours());
                            time.setMinutes(time.getMinutes() - shift.getMinutes());
                            time.setSeconds(time.getSeconds() - shift.getSeconds());
                        }
                    }
                }
                return time;
            }

            function prepareSwitchingArray(switchingArray) {
                if (switchingArray.length > 0) {
                    var changed = false;
                    var i = switchingArray.length - 1;
                    while (i--) {
                        if (toSeconds(switchingArray[i + 1].start) <= toSeconds(switchingArray[i].end)) {
                            if (toSeconds(switchingArray[i + 1].end) > toSeconds(switchingArray[i].end)) {
                                switchingArray[i].end = switchingArray[i + 1].end;
                            }
                            switchingArray.splice(i + 1, 1);
                            changed = true;
                        }
                    }
                    if (changed) {
                        prepareSwitchingArray(switchingArray);
                    }
                }
            }

            function mergeOnOffTimes(ons, offs) {
                var results = [];
                while (ons.length > 0) {
                    var on = ons.shift();
                    var addFull = true;
                    for (var i = 0; i < offs.length; i++) {
                        if (toSeconds(offs[i].start) >= toSeconds(on.end)) {
                            // ignore
                        } else if (toSeconds(offs[i].end) <= toSeconds(on.start)) {
                            // ignore
                        } else if ((toSeconds(offs[i].start) <= toSeconds(on.start)) && (toSeconds(offs[i].end) >= toSeconds(on.end))) {
                            addFull = false;
                            break;
                        } else if ((toSeconds(offs[i].start) > toSeconds(on.start)) && (toSeconds(offs[i].end) < toSeconds(on.end))) {
                            ons.push({
                                start: on.start,
                                end: offs[i].start,
                                type: 'on'
                            });
                            ons.push({
                                start: offs[i].end,
                                end: on.end,
                                type: 'on'
                            });
                            addFull = false;
                            break;
                        } else if ((toSeconds(offs[i].start) <= toSeconds(on.start)) && (toSeconds(offs[i].end) <= toSeconds(on.end))) {
                            ons.push({
                                start: offs[i].end,
                                end: on.end,
                                type: 'on'
                            })
                            addFull = false;
                            break;
                        } else if ((toSeconds(offs[i].start) >= toSeconds(on.start)) && (toSeconds(offs[i].end) >= toSeconds(on.end))) {
                            ons.push({
                                start: on.start,
                                end: offs[i].start,
                                type: 'on'
                            })
                            addFull = false;
                            break;
                        }
                    }
                    if (addFull) {
                        results.push(on);
                    }
                }
                results = _.sortBy(results, function (e) {
                    return e.start;
                });
                prepareSwitchingArray(results);
                return results;
            }
        }
    }
});

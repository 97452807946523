<div class="row" style="margin-bottom: 30px;">
    <ul class="list-group">
        <li class="list-group-item">
            <form class="form form-inline">
                <div class="form-group" style="width: 75%;">
                    <label class="form-group-label">{{'dashboard.widgets.config.fileManager.root.path' | i18n }}</label>
                    <input type="text" class="form-control" name="rootPath" placeholder="{{'dashboard.widgets.config.fileManager.root.path' | i18n }}"
                           data-ng-model="config.rootPath" x-required="" disabled="disabled" style="width: 100%" />
                </div>

                <div class="form-group"><label class="form-group-label">{{'dashboard.widgets.config.fileManager.read.only' | i18n }}</label>
                    <input type="checkbox" class="form-control" name="readOnly" placeholder="{{'dashboard.widgets.config.fileManager.read.only' | i18n }}"
                           data-ng-model="config.readOnly"/>
                </div>
            </form>
        </li>
    </ul>
    <form class="form form-horizontal">
        <div x-file-browser="ui.path" x-read-only="true" x-choosed-directory="config.rootPath" style="height: 350px"></div>
    </form>
</div>


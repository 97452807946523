var module = angular.module('meternet.chart.directives.indicatorGauge', ['meternet.charts.services']);

module.directive('indicatorGauge', function($q, $http, UnitScales, unitFilter, configService, dataService, i18nFilter) {
return {
    scope: {
         options : '=',
          data : '='
    },
    templateUrl: 'dashboard/indicator-gauge.html',
    controller: function ($scope) {

    var uuidv4 = function() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    var length = 14;
    _.each($scope.options.series, function(serie) {
        serie.uuid = uuidv4()
        if(serie.name.length> length){
            length = serie.name.length;
        }
    });
    $scope.minWidth_short = (length *10) + "px";
    $scope.minWidth_long = (length *10+140) + "px";
    $scope.minWidth_padding = (length *5-67) + "px";

    var promises = [];
    _.each($scope.options.series, function(serie) {
        var request = {};
        if (serie.paramId) {
            request.count = Math.max(serie.average, 1);

            var promise = dataService.requestParamLastData(serie.paramId);
            promise.paramId = serie.paramId;
            promises.push(promise);
        }
    });
    $q.all(promises).then(function(moments) {
        _.forEach(promises, function(p, i) {
            var d = [];

            if (moments[i].good){
                d.push(moments[i].good);
            }
            d.push(moments[i].current);
            updateValue(i, d, true);

            var unsubscribeFn = dataService.subscribeForParametersMeasurements(promises[i].paramId, function(moment) {
                var data = [];
                data.push(moment.current);
                updateValue(i, data, moment.current.timestamp.getTime()===moment.good.timestamp.getTime());
            });
            $scope.$on('$destroy', unsubscribeFn);
        });
    });

    var updateValue = function(i,d,c){
        if(d){
            $scope.options.series[i].error = !c;
            $scope.options.series[i].timestamp = d[0].timestamp;
            if(c){
                $scope.options.series[i].value = d[0].value;
            }
        }
    }

    $scope.changeOutput = function(param, value){
        var value = param.value;
        if (value == 1){
            value=0;
        }else{
            value=1;
        }
        $http({
            method: 'POST',
            url: '/changeOutput/',
            params: {
                param: param.paramId,
                value: value
            }
        }).then(function successCallback(response) {
//console.log(response);
        }, function errorCallback(response) {
//console.log(response);
        });
    }
}
}
});

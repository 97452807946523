<div class="modal-header">
    <button type="button" class="close" x-ng-click="ui.cancel()" aria-hidden="true">×</button>
    <h4 class="modal-title">{{'energyReport.config.title' | i18n }}</h4>
</div>
<div class="modal-body">
    <div x-energy-report-config="config"></div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" x-ng-click="ui.save()"><i class="fa fa-save fa-lg"></i>&nbsp;&nbsp;{{'ui.save'| i18n
        }}
    </button>
    <button class="btn btn-danger" x-ng-click="ui.cancel()"><i class="fa fa-undo fa-lg"></i>&nbsp;&nbsp;{{'ui.cancel'|
        i18n }}
    </button>
</div>
<div class="marker-popup">
  <p><b>{{ui.clickedMark.localisation}}</b></p>
<!--  <p><b>{{'config.desc1' | i18n}}: </b><span>{{ui.clickedMark.desc}}</span></p>-->
<!--  <p><b>{{'config.desc2' | i18n}}: </b><span>{{ui.clickedMark.desc2}}</span></p>-->
<!--  <p><b>{{'config.desc3' | i18n}}: </b><span>{{ui.clickedMark.desc3}}</span></p>-->
<!--  <p><b>{{'config.device.geolocation' | i18n}}: </b><span>{{ui.clickedMark.lat + ", " + ui.clickedMark.lng}}</span></p>-->
  <div data-ng-repeat="device in deviceList" style="margin-bottom: 10px;">
    <p><b>{{device.name}}</b></p>
    <div data-ng-repeat="param in device.params">
      <p>{{param.paramName}}: <span>{{(ui.getCurrent(device.deviceId, param.paramId) | measurement) || '-'}}</span></p>
    </div>
  </div>
  <a x-ng-if="ui.clickedMark" href="" x-ng-click="ui.goToReport(ui.clickedMark.markers)">
    <i class="fa fa-bar-chart fa-lg"></i>
  </a>
</div>
<div class="modal-header"><i class="fa fa-fw" x-ng-class="ui.mode"></i>&nbsp;{{ ui.modalTitle | i18n }}{{' ' + username.username}}
    <button type="button" class="close" x-ng-click="ui.cancel()" aria-hidden="true">×</button>
</div>
<div class="modal-body">

    <div class="alert alert-danger" role="alert" x-ng-if="ui.errors.length > 0">
        <!--{{  ui.errors }}-->
        <ul>
            <li x-ng-repeat="error in ui.errors track by $index">{{ error | i18n }}</li>
        </ul>
    </div>
    <div class="alert alert-success" role="alert" x-ng-if="ui.success">
        <span> {{ ui.successMsg | i18n }} </span>
    </div>
    <form x-ng-include="ui.formTemplate" class="form form-horizontal" name="userForm" x-role="form"
          x-ng-hide="ui.success"/>
</div>
<div class="modal-footer" x-ng-hide="ui.success">
    <button class="btn btn-success" x-ng-click="ui.save()" x-ng-disable="ui.locked">
        <i class="fa fa-save fa-lg"></i>&nbsp;&nbsp;{{'ui.save'| i18n }}
    </button>
    <button class="btn btn-danger" x-ng-click="ui.cancel()">
        <i class="fa fa-undo fa-lg"></i>&nbsp;&nbsp;{{'ui.cancel'| i18n }}
    </button>
</div>
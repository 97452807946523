/* global tools */
var module = angular.module('meternet.calendar.services', []);

module.service('paramService', function (dataService) {

  var unsubscribes = {}

  return {
    unsubscribeUnchecked: function (params) {
      var toRemove = [];
      _.forEach(unsubscribes, function (unsub, id) {
        var found = _.find(params, {id: id})
        if (!found) {
          toRemove.push(id)
        }
      });
      _.forEach(toRemove, function (id) {
        unsubscribes[id]();
        delete unsubscribes[id]
      })
    },
    subscribeParams: function (params) {
      _.each(params, function (row) {
        dataService.requestParamLastData(row.id).then(function (moment) {
          row.moment = moment;
        });
        if (!unsubscribes[row.id]) {
          unsubscribes[row.id] = dataService.subscribeForParametersMeasurements(row.id, function (moment) {
            row.updated = true;
            row.moment = moment;
          });
        }
      });
    },
    prepareParamsForMarkers: function (markers) {
      var params = [];
      _.forEach(markers, function (marker) {
        _.forEach(marker.statusParams, function (param) {
          // if (param.quantity === 'status' || param.tags.indexOf("main")>=0) {
          if (param.tags.indexOf("main")>=0) {
            params.push(param);
          }
        });
      });
      return params;
    }
  };
});
